
import React from 'react';
import { Chip, IconButton, Typography } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
export default function FilterChipsMain({ chipList }) {
	return (
		<div>
			{chipList.map(({ value, label, onRemove }) => {
				return (
					<Chip
						sx={{
							margin: "2.5px",
						}}
						label={
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									flexDirection: "row",
								}}
							>
								<Typography
									sx={{
										marginRight: "10px",
									}}
								>
									{label}
								</Typography>
								<IconButton
									size="medium"
									sx={{ padding: "0px" }}
									onClick={() => {
										if (onRemove) {
											onRemove(value, label);
										}
									}}
								>
									<HighlightOffIcon
										sx={{ fontSize: "20px", zIndex: 0 }}
									/>
								</IconButton>
							</div>
						}
					/>
				);
			})}
		</div>
	);
}
