
import React from "react";
import RowOfBoxNumbers from "./RowOfBoxNumber";
export default function AirplaneBoxMain(props) {
	const {
		data,
		title,
		x = 0,
		y = 0,
		minWidth = 150,
		onClick,
		setClosePopup,
		setPopup,
	} = props;
	let biggestArrayLength = 0;
	Object.keys(data).map((key, index) => {
		const arrLng = data[key].data.length;
		if (arrLng > biggestArrayLength) {
			biggestArrayLength = arrLng;
		}
	});
	const calculatedWidth = 80 + biggestArrayLength * 24.6;
	const boxWidth = minWidth > calculatedWidth ? minWidth : calculatedWidth;
	const isNoItem = Object.keys(data).length < 1;
	return (
		<g
			// transform(x,y) x=0 left , y=0 top
			transform={`translate(${x},${y})`}
		>
			<rect
				x="-7"
				y="-37"
				width={boxWidth}
				height={
					isNoItem ? "60" : `${30 + 30 * Object.keys(data).length}`
				}
				rx="8"
				stroke="#E8E8E8"
				fill="white"
			/>
			{isNoItem && (
				<g>
					<text
						x={5}
						y="10"
						fontSize={"10px"}
						fill="#838383"
						// fontWeight='600'
						fontStyle="italic"
					>
						No equipment assigned
					</text>
					<path
						id="lineAB"
						d={`M -7  -7 l ${boxWidth} 0`}
						stroke="#E8E8E8"
					/>
				</g>
			)}
			{Object.keys(data).map((key, index) => {
				return (
					<g>
						<RowOfBoxNumbers
							y={index * 30}
							text={key}
							Icon={data[key].Icon}
							numbers={data[key].data}
							onClick={onClick}
							setClosePopup={setClosePopup}
							setPopup={(boxX, inFleetLocation) => {
								if (setPopup) {
									setPopup(boxX, index * 30, inFleetLocation);
								}
							}}
						/>
						<path
							id="lineAB"
							d={`M -7 ${index * 30 - 7} l ${boxWidth} 0`}
							stroke="#E8E8E8"
						/>
					</g>
				);
			})}
			<text x={5} y="-17" fontSize={"12px"} fill="black" fontWeight="600">
				{title}
			</text>
		</g>
	);
}
