import React from 'react';
import { Store } from "react-notifications-component";
import IconFailed from "common/images/icons/Icon-Failed.svg";
import IconTick from "common/images/icons/Icon-Tick.svg";
import IconClose from "common/images/icons/Close-Icon.svg";
import { curry } from "lodash/fp";
import { Grid } from "@mui/material";

const ShowIconFailed = () => {
  return <img src={IconFailed} style={{ marginRight: 9, width: "24px" }} alt="" />;
};
const ShowIconTick = () => {
  return <img src={IconTick} style={{ marginRight: 9, width: "24px" }} alt="" />;
};
const ShowIconClose = () => {
  return (
    <img
      src={IconClose}
      style={{
        marginLeft: 9,
        width: "14px",
        filter: "invert(99%) sepia(0%) saturate(733%) hue-rotate(105deg) brightness(120%) contrast(97%)",
      }}
      alt=""
    />
  );
};

export const showNotificationElevade = curry((type, title, contents) => {
  const message = contents.map((content, index) => {
    return (
      <span key={"content-" + index} style={{ fontFamily: "Gotham-Book" }}>
        {content}
        <br />
      </span>
    );
  });

  function NotificationContent() {
    // let showBorderBottom = "9px solid #6AB47B";
    // if (type === "danger") showBorderBottom = "9px solid #FC3F3F";

    return (
      <div
        className="noti-elevade small-font"
        style={{
          display: "flex",
          padding: "16px",
          width: "420px",
          backgroundColor: "#333333",
          boxShadow: "0px 3px 15px #39445833",
          zIndex: "999999",
          justifyContent: "center",
          fontFamily: "Gotham-book",
          color: "#ffffff",
          borderRadius: "12px",
        }}
      >
        <Grid container columns={5}>
          <Grid item xs={0.5} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            {type === "danger" ? <ShowIconFailed /> : <ShowIconTick />}
          </Grid>
          <Grid item xs={4} sx={{ paddingTop: contents[0] === "" ? "4px" : "0px" }}>
            <span className="noti-message" style={{ marginTop: "2px", fontFamily: "Gotham-Medium", fontSize: "14px" }}>
              {title}
            </span>
            {contents[0] !== "" && (
              <>
                <br />
                <span
                  className="noti-message"
                  style={{ marginTop: "2px", fontFamily: "Gotham-Book", fontSize: "10px" }}
                >
                  {message}
                </span>
              </>
            )}
          </Grid>
          <Grid item xs={0.5} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <ShowIconClose />
          </Grid>
        </Grid>
      </div>
    );
  }

  Store.addNotification({
    // title: title,
    // message: <NotificationContent contents={contents} />,
    content: NotificationContent,
    type: type,
    insert: "top",
    isMobile: true,
    container: "top-center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 3000,
      onScreen: false,
    },
    width: 420,
  });
});

export const notificationSuccess = showNotificationElevade("success", "Success");
export const notificationDanger = showNotificationElevade("danger", "Error");
