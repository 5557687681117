import React from "react";
import { Grid } from "@mui/material";
import styles from "../Dasr.module.css";
import IconArrowLeft from "common/images/icons/elevade/Icon-ArrowLeft.svg";
import IconArrowRight from "common/images/icons/elevade/Icon-ArrowRight.svg";
import IconArrowRightDisabled from "common/images/icons/elevade/Icon-ArrowRightDisabled.svg";
import { useState, useEffect } from "react";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const DateToggle = ({ date, setDate, today, setToday }) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(new Date(date));
  const isToday = new Date();

  function formattedDate(type, date) {
    const currentDate = date ? new Date(date) : new Date();
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const monthsOfYear = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const dayOfWeek = daysOfWeek[currentDate.getDay()];
    const month = monthsOfYear[currentDate.getMonth()];
    const day = currentDate.getDate();
    const year = currentDate.getFullYear();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();

    return type
      ? `${dayOfWeek}, ${month} ${day}, ${year} ${hours}:${minutes}`
      : `${dayOfWeek}, ${month} ${day}, ${year}`;
  }

  const handleOpenCalendar = () => {
    setOpen(!open);
  };

  // A function to check if the selected date is today
  function compareDate(date) {
    if (new Date(date).toLocaleDateString() === new Date().toLocaleDateString()) return true;
    else return false;
  }

  function SetTheDate(direction, givenDate) {
    var currentDate = new Date(givenDate); // Create a new date object based on the given date

    if (isNaN(currentDate.getTime())) {
      return "Invalid date"; // Return an error message for an invalid date
    }

    if (direction === "next") {
      currentDate.setDate(currentDate.getDate() + 1); // Get the next date
    } else if (direction === "previous") {
      currentDate.setDate(currentDate.getDate() - 1); // Get the previous date
    } else {
      return "Invalid direction"; // Return an error message for an invalid direction
    }

    var options = { month: "long", day: "2-digit", year: "numeric" };
    setDate(currentDate.toLocaleDateString("en-US", options));
    setValue(currentDate.toLocaleDateString("en-US", options));
  }

  function setInitialDate() {
    var currentDate = new Date();
    var options = { month: "long", day: "2-digit", year: "numeric" };
    var formattedDate = currentDate.toLocaleDateString("en-US", options);
    setDate(formattedDate);
    setToday(formattedDate);
    setValue(currentDate);
  }

  useEffect(() => {
    const date_ = new Date(date);
    const today_ = new Date(today);
    var options = { month: "long", day: "2-digit", year: "numeric" };
    var formattedDate = date_.toLocaleDateString("en-US", options);
    var formattedToday = today_.toLocaleDateString("en-US", options);
    setDate(formattedDate);
    setToday(formattedToday);
    // eslint-disable-next-line
  }, [date, today]);

  const InputFieldCalendar = ({ ...props }) => {
    function formatDateToISOString(givenDate) {
      // Get current time
      const currentTime = new Date(givenDate);

      const year = currentTime.getFullYear();
      const month = String(currentTime.getMonth() + 1).padStart(2, "0");
      const day = String(currentTime.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    }

    return (
      <>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            placeholder={false}
            format="LL"
            value={dayjs(value)}
            maxDate={dayjs()}
            onChange={(newValue) => {
              setValue(newValue);
              setDate(formatDateToISOString(newValue));
            }}
            slotProps={{
              textField: {
                variant: "standard",
                InputProps: {
                  disableUnderline: true,
                  style: {
                    fontSize: "14px",
                  },
                },
                style: {
                  border: "none",
                  fontSize: "14px",
                  fontFamily: "Gotham-Book",
                  width: "20px",
                  marginRight: "10px",
                  marginTop: "-5px",
                },
              },
            }}
            sx={{ maxWidth: "fit-content" }}
            slots={{
              openPickerIcon: () => (
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6 8L10 12L14 8"
                    stroke="#333333"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ),
            }}
            {...props}
          />
        </LocalizationProvider>
        {props.error && <span style={{ color: "#E95048", fontSize: "14px" }}>This field is mandatory!</span>}
      </>
    );
  };

  return (
    <>
      <span style={{ cursor: "pointer" }} onClick={handleOpenCalendar}>
        {formattedDate("", date)}
      </span>
      <InputFieldCalendar />
      <div className={styles.divDateToggle}>
        <Grid container columns={3}>
          <Grid item xs={0.5} className={styles.gridJustifyStart}>
            <img
              src={IconArrowLeft}
              alt="icon-left"
              className={styles.dateArrow}
              onClick={() => SetTheDate("previous", date)}
            />
          </Grid>
          <Grid
            item
            xs={2}
            className={`${styles.gridJustifyCenter} ${styles.today} ${
              compareDate(date, isToday) ? styles.disabled : ""
            }`}
            onClick={() => setInitialDate()}
          >
            Today
          </Grid>
          <Grid item xs={0.5} className={styles.gridJustifyEnd}>
            <img
              src={compareDate(date, isToday) ? IconArrowRightDisabled : IconArrowRight}
              alt="icon-right"
              className={`${styles.dateArrow} ${compareDate(date, isToday) ? styles.noAction : ""}`}
              onClick={() => SetTheDate("next", date)}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default DateToggle;
