
import React from 'react';
import {
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Pagination,
	MenuItem,
	Select,
	Typography,
	Tabs,
	Tab,
} from "@mui/material";
import { Box } from "@mui/system";
import { Fragment, useEffect, useState } from "react";
import useGetGQL from "../../../models/useGetGQL";
import SearchAdv from "./SearchAdv";
import { Active, Expired, Missing, NearExpiry, Error } from "./Icons";
import UpdateEquipment from "./UpdateEquipment";
import AddEquipment from "./AddEquipment";
import { gothamBold } from "../../../constants/fonts";
import RightDrawer from "../../components/modals/RightDrawer";
import TabPanel from "./TabPanel";
import HistoryTab from "./HistoryTab";
import ProductLoading from "./Loading";
// import axios from "axios";
import { paragraphSpacing, tableRowFontSize } from "../../../constants/styles";
import { getCabinMonitDateFormat } from "../../utils/dates";
import { useGetFilteredInventory } from "../../../models/custom/inventory-model";

const GQLNODESLIMIT = 300;
export default function InventoryListAll({ showAdd, setShowAdd }) {
	const [itemToUpdate, setItemToUpdate] = useState(undefined);

	// searching & filters
	const [search, setSearch] = useState(undefined);
	const [filterRegnos, setFilterRegnos] = useState([]);
	const [filterAcModels, setFilterAcModels] = useState([]);
	const [filterStatusIds, setFilterStatusIds] = useState([]);
	const [filterEqTypeIds, setFilterEqTypeIds] = useState([]);
	const [filterLocationExist, setFilterLocationExist] = useState(true);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [curPage, setCurPage] = useState(1);
	const [clickedPageNumber, setClickedPageNumber] = useState(false);
	const [fetchCount, setFetchCount] = useState(1);
	const offset = GQLNODESLIMIT * (fetchCount - 1);
	const upperThreshold = (GQLNODESLIMIT / itemsPerPage) * fetchCount;
	const lowerThreshold = (GQLNODESLIMIT / itemsPerPage) * (fetchCount - 1);
	const upperIndex =
		curPage * itemsPerPage - GQLNODESLIMIT * (fetchCount - 1);
	const lowerIndex =
		(curPage - 1) * itemsPerPage - GQLNODESLIMIT * (fetchCount - 1);
	const [tabPost, setTabPost] = useState(0);
	const [tabEquPost, setTabEquPost] = useState(0);
	const handleChange = (event, newValue) => {
		setTabPost(newValue);
	};
	const handleTabChange = (event, newValue) => {
		setTabEquPost(newValue);
		// Update the filterLocationExist state based on the selected tab value
		setFilterLocationExist(newValue === 0); // If the second tab is selected, set filterLocationExist to false
	};
	// const tabStyles = {
	//     fontWeight: 'bold',
	//     color: 'black',
	// };
	const allInventoryLists = useGetFilteredInventory({
		offset,
		search,
		filterRegnos,
		filterAcModels,
		filterStatusIds,
		filterEqTypeIds,
		filterLocationExist,
	});

	useEffect(() => {
		// reset page back to 1 if data changed
		if (!clickedPageNumber) {
			setCurPage(1);
			setFetchCount(1);
		}
		setClickedPageNumber(false);
	}, [allInventoryLists.data]);
	const numOfPages = allInventoryLists.totalCount
		? Math.ceil(allInventoryLists.totalCount / itemsPerPage)
		: 1;
	return (
		<Box
			sx={{
				height: "100%",
			}}
		>
			<RightDrawer
				open={showAdd}
				setClose={() => {
					setShowAdd(false);
				}}
				title={
					<Typography
						sx={{
							paddingBottom: "1em",
							paddingLeft: "1em",
							paddingTop: "1em",
							fontFamily: gothamBold,
						}}
					>
						Add New Equipment
					</Typography>
				}
			>
				{showAdd && ( // force it to rerender to < Typography sx={{ whiteSpace: 'nowrap', marginLeft:'10px' }} >Clear all</Typography> inputs
					<AddEquipment
						handleClose={() => {
							setShowAdd(false);
						}}
						refetch={allInventoryLists.refetch}
					/>
				)}
			</RightDrawer>
			<RightDrawer
				open={itemToUpdate === undefined ? false : true}
				setClose={() => {
					setItemToUpdate(undefined);
				}}
				title={
					<Typography
						sx={{
							paddingBottom: "1em",
							paddingLeft: "1em",
							paddingTop: "1em",
							fontFamily: gothamBold,
						}}
					>
						Update Equipment
					</Typography>
				}
			>
				<Box
					sx={{
						height: "94%",
						paddingTop: "0px",
					}}
				>
					<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
						<Tabs value={tabPost} onChange={handleChange}>
							<Tab label="Equipment Details" />
							<Tab label="History" />
						</Tabs>
					</Box>
					<TabPanel value={tabPost} index={0}>
						{itemToUpdate && (
							<UpdateEquipment
								node={itemToUpdate}
								refetch={allInventoryLists.refetch}
								onClose={() => {
									setItemToUpdate(undefined);
								}}
							/>
						)}
					</TabPanel>
					<TabPanel value={tabPost} index={1}>
						<HistoryTab node={itemToUpdate} />
					</TabPanel>
				</Box>
			</RightDrawer>
			<Box sx={{ marginBottom: "1em" }}>
				<Tabs
					TabIndicatorProps={{
						style: {
							backgroundColor: "#088FD1",
						},
					}}
					value={tabEquPost}
					onChange={handleTabChange}
				>
					<Tab
						label="Utilised Equipment"
						sx={{
							color: "#333333",
							"&.Mui-selected": {
								color: "#333333",
								fontWeight: "bold",
							},
							textTransform: "None",
							// fontWeight: 500
						}}
					/>
					<Tab
						label="Unutilised Equipment"
						sx={{
							color: "#333333",
							"&.Mui-selected": {
								color: "#333333",
								fontWeight: "bold",
							},
							textTransform: "None",
						}}
					/>
				</Tabs>
			</Box>
			<SearchAdv
				filterEqTypeIds={filterEqTypeIds}
				setFilterEqTypeIds={setFilterEqTypeIds}
				search={search}
				filterRegnos={filterRegnos}
				filterAcModels={filterAcModels}
				filterStatusIds={filterStatusIds}
				filterLocationExist={filterLocationExist}
				setFilterRegnos={setFilterRegnos}
				setFilterAcModels={setFilterAcModels}
				setFilterStatusIds={setFilterStatusIds}
				setSearch={(item) => {
					setSearch(item);
					setCurPage(1);
					setFetchCount(1);
				}}
				setFilterLocationExist={setFilterLocationExist}
			/>
			{allInventoryLists.isLoading ? (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
						height: "100%",
					}}
				>
					<Box
						sx={{
							maxWidth: "600px",
							maxHeight: "600px",
						}}
					>
						<ProductLoading />
					</Box>
				</Box>
			) : (
				// <Fragment>
				//     {Array.apply(null, { length: itemsPerPage } as any).map(Number.call, Number).map((item) => {
				//         return (
				//             <TableRow>
				//                 <TableCell sx={{
				//                     paddingTop: "16px",
				//                     paddingBottom: "16px",
				//                 }} colSpan={9} component="th" scope="row">
				//                     <Skeleton sx={{ height: "20px" }} animation="wave" variant="text" />
				//                 </TableCell>
				//             </TableRow>
				//         )
				//     })}
				// </Fragment>
				<Fragment>
					<TableContainer
						component={Paper}
						sx={{
							boxShadow: "none",
							borderRadius: "12px",
							border: "1px solid #E8E8E8",
							"& .MuiTableRow-root:hover": {
								backgroundColor: "#F7F7F7",
							},
						}}
					>
						<Table
							sx={
								{
									/* minWidth: 650 */
								}
							}
							aria-label="simple table"
						>
							<TableHead>
								<TableRow
									sx={{
										backgroundColor: "#F2F7FF",
									}}
								>
									<TableCell
										sx={{
											fontSize: tableRowFontSize,
											lineHeight: paragraphSpacing,
										}}
									>
										Tag ID
									</TableCell>
									<TableCell
										sx={{
											fontSize: tableRowFontSize,
											lineHeight: paragraphSpacing,
										}}
									>
										Serial No.
									</TableCell>
									<TableCell
										sx={{
											fontSize: tableRowFontSize,
											lineHeight: paragraphSpacing,
										}}
									>
										Eq. Type
									</TableCell>
									<TableCell
										sx={{
											fontSize: tableRowFontSize,
											lineHeight: paragraphSpacing,
										}}
										align="center"
									>
										Status
									</TableCell>
									<TableCell
										sx={{
											fontSize: tableRowFontSize,
											lineHeight: paragraphSpacing,
										}}
									>
										Expiry Date
									</TableCell>
									<TableCell
										sx={{
											fontSize: tableRowFontSize,
											lineHeight: paragraphSpacing,
										}}
									>
										MFG Date
									</TableCell>
									<TableCell
										sx={{
											fontSize: tableRowFontSize,
											lineHeight: paragraphSpacing,
										}}
									>
										A/C Model
									</TableCell>
									<TableCell
										sx={{
											fontSize: tableRowFontSize,
											lineHeight: paragraphSpacing,
										}}
									>
										A/C Reg
									</TableCell>
									<TableCell
										sx={{
											fontSize: tableRowFontSize,
											lineHeight: paragraphSpacing,
										}}
									>
										Location
									</TableCell>
									{/* <TableCell align="center">Remind</TableCell> */}
								</TableRow>
							</TableHead>
							<TableBody>
								{allInventoryLists.data &&
									allInventoryLists.data.length < 1 &&
									!allInventoryLists.isLoading && (
										<TableRow>
											<TableCell
												colSpan={9}
												component="th"
												scope="row"
											>
												No record(s) found.
											</TableCell>
										</TableRow>
									)}
								{allInventoryLists.data &&
									!allInventoryLists.isLoading &&
									allInventoryLists.data.map(
										({ node }, index) => {
											const {
												id,
												tag,
												serialNo,
												expiryDate,
												manufactureDate,
												// updatedBy,
												// createdBy,
											} = node;
											const statuscode =
												node?.status?.code ?? "";
											const eqType =
												node?.item?.code ?? "";
											const model =
												node?.locationlistSet?.edges[0]
													?.node?.fleetId?.model ??
												"";
											const reg_no =
												node?.locationlistSet?.edges[0]
													?.node?.fleetId?.regNo ??
												"";
											const location =
												node?.locationlistSet?.edges[0]
													?.node?.locationId
													?.seatNo ?? "";
											let StatusHolder =
												getStatus(statuscode);
											// if (index >= lowerIndex && index <= upperIndex) {
											if (
												index > lowerIndex - 1 &&
												index <= upperIndex - 1
											) {
												return (
													<TableRow
														sx={{
															cursor: "pointer",
															"&:last-child td, &:last-child th":
																{
																	border: 0,
																},
															"&:hover": {
																background:
																	"#D1D1D1",
															},
															// '&:hover .buttonmore': {
															//     display: "inline-block",
															// },
															fontSize:
																tableRowFontSize,
															lineHeight:
																paragraphSpacing,
														}}
														onClick={() => {
															setItemToUpdate(
																node
															);
														}}
													>
														<TableCell
															component="th"
															scope="row"
															sx={{
																fontSize:
																	tableRowFontSize,
																lineHeight:
																	paragraphSpacing,
																maxWidth:
																	"150px",
																minWidth:
																	"150px",
																wordBreak:
																	"break-all",
																position:
																	"relative",
															}}
														>
															{/* ({index}) -- */}
															{tag.slice(-12)}
														</TableCell>
														<TableCell
															sx={{
																fontSize:
																	tableRowFontSize,
																lineHeight:
																	paragraphSpacing,
															}}
															component="th"
															scope="row"
														>
															{serialNo}
														</TableCell>
														<TableCell
															sx={{
																fontSize:
																	tableRowFontSize,
																lineHeight:
																	paragraphSpacing,
															}}
															component="th"
															scope="row"
															align="center"
														>
															{eqType}
														</TableCell>
														<TableCell
															sx={{
																fontSize:
																	tableRowFontSize,
																lineHeight:
																	paragraphSpacing,
															}}
															component="th"
															scope="row"
															align="center"
														>
															<StatusHolder />
														</TableCell>
														<TableCell
															sx={{
																fontSize:
																	tableRowFontSize,
																lineHeight:
																	paragraphSpacing,
															}}
															component="th"
															scope="row"
														>
															{expiryDate
																? getCabinMonitDateFormat(
																		expiryDate
																  )
																: "N/A"}
														</TableCell>
														<TableCell
															sx={{
																fontSize:
																	tableRowFontSize,
																lineHeight:
																	paragraphSpacing,
															}}
															component="th"
															scope="row"
														>
															{manufactureDate
																? getCabinMonitDateFormat(
																		manufactureDate
																  )
																: "N/A"}
														</TableCell>
														<TableCell
															sx={{
																fontSize:
																	tableRowFontSize,
																lineHeight:
																	paragraphSpacing,
															}}
															component="th"
															scope="row"
														>
															{model ?? "N/A"}
														</TableCell>
														<TableCell
															sx={{
																fontSize:
																	tableRowFontSize,
																lineHeight:
																	paragraphSpacing,
															}}
															component="th"
															scope="row"
														>
															{reg_no ?? "N/A"}
														</TableCell>
														<TableCell
															sx={{
																fontSize:
																	tableRowFontSize,
																lineHeight:
																	paragraphSpacing,
															}}
															component="th"
															scope="row"
														>
															{location ?? "N/A"}
														</TableCell>
													</TableRow>
												);
											} else {
												return;
											}
										}
									)}
							</TableBody>
						</Table>
					</TableContainer>

					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "right",
							marginTop: "1em",
						}}
					>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								marginRight: "1em",
							}}
						>
							Rows Per Page
						</Box>
						<Select
							sx={{
								padding: "0px",
								"& .MuiSelect-select": {
									padding: "0px",
									paddingLeft: "1em",
								},
								marginRight: "1em",
							}}
							value={itemsPerPage}
							onChange={(e) => {
								setItemsPerPage(e.target.value);
								setCurPage(1);
								setFetchCount(1);
							}}
						>
							{["10", "20", "50", "100"].map((pageNumber) => (
								<MenuItem
									sx={{
										padding: "0px",
									}}
									key={pageNumber}
									value={pageNumber}
								>
									{pageNumber}
								</MenuItem>
							))}
						</Select>
						<Pagination
							page={curPage}
							count={numOfPages}
							onChange={(e, value) => {
								setCurPage(value);
								setClickedPageNumber(true);
								if (value > upperThreshold) {
									setFetchCount(
										Math.ceil(
											(value * itemsPerPage) /
												GQLNODESLIMIT
										)
									);
								} else if (value <= lowerThreshold) {
									setFetchCount(
										Math.ceil(
											(value * itemsPerPage) /
												GQLNODESLIMIT
										)
									);
								}
							}}
							variant="outlined"
							shape="rounded"
						/>
					</Box>
				</Fragment>
			)}
			<Box sx={{ width: "100%", height: "2em" }} />
		</Box>
	);
}
function getStatus(statuscode) {
	switch (statuscode) {
		case "A":
			return Active;
		case "L":
			return Missing;
		case "T":
			return Error;
		case "E":
			return Expired;
		case "R":
			return NearExpiry;
		case "D":
			return () => (
				<Typography
					style={{
						fontWeight: "bold",
						border: "1px solid black",
						borderRadius: "20px",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						fontSize: "14px",
						height: "28px",
						verticalAlign: "middle",
						textAlign: "center",
						padding: "auto",
						minWidth: "108px",
					}}
				>
					Defects
				</Typography>
			);
		default:
			return Active;
	}
}
