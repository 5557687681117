
import React from 'react';

import { Box } from "@mui/system";
import { Fragment, useEffect, useState } from "react";
import PageTemplate from "../../components/templates/page-template";
import WidgetItemsStatus from "../../components/common-component/WidgetItemsStatus";
import FleetInventoryAdv from "./FleetInventoryAdv";
import { IconButton, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { gothamMedium } from "../../../constants/fonts";
import ListOfFleets from "./ListOfFleet";
import SearchAdv from "./SearchAdv";
import ListTitle from "../../components/titles/ListTitle";
import { useAppSelector } from "../../../app/hooks";
export const storedLopaKey = "LOPA_KEY_STORED_AL_AM";

export default function OverviewPage() {
	const { companyProfile } = useAppSelector((state) => state.userEntity);
	const code = companyProfile?.code;
	const companyName = companyProfile?.name;

	const [filterRegNos, setFilterRegNos] = useState([]);
	const [filterModels, setFilterModels] = useState([]);
	const [filterEnType, setFilterEnType] = useState([]);
	const [showLopaFleet, setShowLopaFleet] = useState();
	const [search, setSearch] = useState(undefined);
	const getStoredLopa = window.localStorage.getItem(storedLopaKey);
	useEffect(() => {
		if (getStoredLopa && getStoredLopa !== null) {
			setShowLopaFleet(JSON.parse(getStoredLopa));
		}
	}, [getStoredLopa]);
	return (
		<Fragment>
			<PageTemplate title={`Overview: ${companyName} (${code})`}>
				<Fragment>
					{showLopaFleet && (
						<Typography
							variant="h5"
							sx={{
								fontFamily: gothamMedium,
								// fontWeight: "bold",
								flexGrow: 1,
								display: "flex",
								flexDirection: "row",
								// justifyContent:'center',
								alignItems: "center",
								fontSize: "18px",
								marginBottom: "28px",
							}}
						>
							<IconButton
								sx={{
									padding: "0px",
									marginRight: "8px",
								}}
								onClick={() => {
									setShowLopaFleet(undefined);
									window.localStorage.removeItem(
										storedLopaKey
									);
								}}
							>
								<KeyboardArrowLeftIcon />
							</IconButton>
							Seat Map
						</Typography>
					)}

					{showLopaFleet ? (
						<FleetInventoryAdv
							fleetNode={{
								...showLopaFleet,
								id: showLopaFleet["id"],
								model: showLopaFleet["model"],
							}}
						/>
					) : (
						<Fragment>
							<Box
								sx={{
									marginBottom: "1em",
								}}
							>
								<WidgetItemsStatus />
							</Box>
							<Box
								sx={{
									display: "flex",
									justifyContent: "left",
									width: "100%",
									marginTop: "24px",
									marginBottom: "24px",
								}}
							>
								<ListTitle title={"Aircraft List"} />
							</Box>
							<SearchAdv
								filterRegNos={filterRegNos}
								setFilterRegNos={setFilterRegNos}
								filterModels={filterModels}
								setFilterModels={setFilterModels}
								setSearch={setSearch}
								search={search}
								filterEnType={filterEnType}
								setFilterEnType={setFilterEnType}
							/>
							<ListOfFleets
								filterEnType={filterEnType}
								setFilterEnType={setFilterEnType}
								setSearch={setSearch}
								search={search}
								filterModels={filterModels}
								setFilterModels={setFilterModels}
								filterRegNos={filterRegNos}
								setFilterRegNos={setFilterRegNos}
								setShowLopaFleet={(node) => {
									setShowLopaFleet(node);
									if (node) {
										window.localStorage.setItem(
											storedLopaKey,
											JSON.stringify(node)
										);
									}
								}}
							/>
						</Fragment>
					)}
				</Fragment>
			</PageTemplate>
		</Fragment>
	);
}
