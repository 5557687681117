// all
export const RESET_CONTEXT = "RESET_CONTEXT";

//User State
export const SET_LOADING = "SET_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_CURRENT_PROFILE = "SET_CURRENT_PROFILE";

//Search State
export const SET_SEARCH_PARAMS = "SET_SEARCH_PARAMS";
export const SET_SEARCH_RESULT = "SET_SEARCH_RESULT";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_CHAPTERS = "SET_CHAPTERS";
export const SET_CATEGORY_COUNT = "SET_CATEGORY_COUNT";
export const SET_CHAPTER_COUNT = "SET_CHAPTER_COUNT";
export const SET_HITS ="SET_HITS"

//Cart State
export const SET_CART_ITEMS = "SET_CART_ITEMS";
export const SET_CHECKOUT_ITEMS = "SET_CHECKOUT_ITEMS";

//Checkout State
export const SET_CHECKOUT_SHIPPING = "SET_CHECKOUT_SHIPPING";
export const SET_CHECKOUT_UPDATE = "SET_CHECKOUT_UPDATE";
export const SET_CHECKOUT_BILLING = "SET_CHECKOUT_BILLING";
export const SET_CHECKOUT_SUMMARY = "SET_CHECKOUT_SUMMARY";
export const SET_CHECKOUT_PAYMENTOPTION = "SET_CHECKOUT_PAYMENTOPTION";
export const SET_CHECKOUT_SHIPMENTOPTION = 'SET_CHECKOUT_SHIPMENTOPTION';
export const SET_CHECKOUT_EMAIL = "SET_CHECKOUT_EMAIL";
export const SET_CHECKOUT_PHONE = "SET_CHECKOUT_PHONE";
export const SET_CHECKOUT_SHIPPINGCOMPANYNAME = "SET_CHECKOUT_SHIPPINGCOMPANYNAME";
export const SET_CHECKOUT_BILLINGCOMPANYNAME = "SET_CHECKOUT_BILLINGCOMPANYNAME";
export const SET_CHECKOUT_ATTTOSHIPPING = "SET_CHECKOUT_ATTTOSHIPPING";
export const SET_CHECKOUT_ATTTOBILLING = "SET_CHECKOUT_ATTTOBILLING";
export const SET_CHECKOUT_INTERNALPO = "SET_CHECKOUT_INTERNALPO";
export const SET_CHECKOUT_CREATEDBY = "SET_CHECKOUT_CREATEDBY";
export const SET_ORDER_URGENCY = 'SET_ORDER_URGENCY';
export const SET_ARRANGED_LOGISTIC_PARTNER = 'SET_ARRANGED_LOGISTIC_PARTNER';
export const SET_ARRANGED_LOGISTIC_ERR = 'SET_ARRANGED_LOGISTIC_ERR';

//Dashboard State
export const SET_MISC = "SET_MISC";
export const SET_TABS = "SET_TABS";
export const SET_SUBTAB = "SET_SUBTAB";

//Sidenav State
export const SET_AEROTRADE_OPEN = "SET_ACCORDION_OPEN"
export const SET_CASCADE_OPEN = "SET_CASCADE_OPEN"
export const SET_ELEVADE_OPEN = "SET_ELEVADE_OPEN"

//Order State
export const SET_PACKAGE_STATUS = "SET_PACKAGE_STATUS"
export const SET_PACKAGE_ORDER = "SET_PACKAGE_ORDER"

export const SET_MODAL_OPEN = "SET_MODAL_OPEN"
export const SET_FORM_DEFAULTS = "SET_FORM_DEFAULTS"

// company state
export const SET_COMPANY_INFO = "SET_COMPANY_INFO"
export const SET_LOGISTIC_LIST = 'SET_LOGISTIC_LIST';