const { createSlice } = require("@reduxjs/toolkit")

const authInitialState = {
  sidebarToggle: false
}

const sidebarToggle = createSlice({
  name: "sidebarToggle",
  initialState: authInitialState,
  reducers: {
    setSidebarToggle: (state, { payload }) => {
      state.sidebarToggle = payload
    }
  }
})

export const { setSidebarToggle } = sidebarToggle.actions;

export default sidebarToggle.reducer