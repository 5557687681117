
import React from 'react';
import { getPopupColorBasedOnStatusCode } from "../../Popup";
import AirplaneBox from "../AirplaneBox";
import { getBoxDataFromInFleetLocations } from "../AirplaneBox/utils";
export default function BoxBehind2lAttnStnAdv({ invs, onClick, setClosePopup, setPopup }) {
    const data = getBoxDataFromInFleetLocations(invs);
    const boxX = 1070;
    const boxY = 510;
    const popupXOffset = -503;
    const popupYOffset = -500;
    return (<g className="display-box">
            <Pointer />
            <AirplaneBox title="Behind 2L ATT. STN." x={boxX} y={boxY} data={data} onClick={onClick} minWidth={140} setClosePopup={setClosePopup} setPopup={(rowX, rowY, data) => {
            setPopup({
                status: data?.inventory?.status,
                data: data,
                x: rowX + boxX + popupXOffset,
                y: rowY + boxY + popupYOffset,
                // row: 'row',
                col: data.locationId.seatNo,
                color: getPopupColorBasedOnStatusCode(data?.inventory?.status?.code),
            });
        }}/>
        </g>);
}
function Pointer() {
    return (<g>
            <path className="path" d="M 1223 420 V 477 C 1223 481 1220 484 1216 484 H 1196" stroke="#202225" stroke-width="0.5" stroke-linecap="round" stroke-dasharray="2 2"/>
            <circle className="pointer-1st" cx="1223" cy="419" r="3" fill="#74C4E8"/>
            <circle className="pointer-2nd" opacity="0.4" cx="1223" cy="419" r="6" fill="#74C4E8"/>
        </g>);
}
