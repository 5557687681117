
import React from 'react';
import { Typography } from "@mui/material";
import { gothamMedium } from "../../../constants/fonts";
export default function ListTitle({ title, style }) {
	return (
		<Typography
			fontFamily={gothamMedium}
			sx={{
				...style,
				color: "#394458",
				fontSize: "16px",
			}}
		>
			{title}
		</Typography>
	);
}
