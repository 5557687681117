
import React from 'react';
import { Typography } from "@mui/material";
import { CircleIcon } from "../../icons/statusIcons";
export function ACExtraDetails({ inactive, }) {
    return (<table style={{ width: '100%' }}>
            <DetailRow Icon={() => <CircleIcon />} label={"Inactive"} value={0}/>
        </table>);
}
function DetailRow({ Icon, label, value }) {
    return (<tr style={{ height: '25px' }}>
            <td style={{
            maxWidth: '5px',
            minWidth: '20px'
        }}><Icon /></td>
            <td align="left" style={{ minWidth: '195px' }}>
                <Typography>
                    {label}
                </Typography>
            </td>
            <td align="right" style={{ minWidth: '20px' }}>
                <Typography>
                    {typeof (value) === 'number' ? value.toLocaleString() : value}
                </Typography>
            </td>
        </tr>);
}
