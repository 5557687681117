
import React from "react";

export function BCFIcon() {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10.5 15H6.5C6.36739 15 6.24021 14.9473 6.14645 14.8536C6.05268 14.7598 6 14.6326 6 14.5V6.5C6 5.83696 6.26339 5.20107 6.73223 4.73223C7.20107 4.26339 7.83696 4 8.5 4C9.16304 4 9.79893 4.26339 10.2678 4.73223C10.7366 5.20107 11 5.83696 11 6.5V14.5C11 14.6326 10.9473 14.7598 10.8536 14.8536C10.7598 14.9473 10.6326 15 10.5 15Z"
				stroke="#333333"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M4 10.5H11"
				stroke="#333333"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M8.5 4V2"
				stroke="#333333"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M11.5 0.5L8.5 2L13.5 3.5"
				stroke="#333333"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M4 13V6.5C4 5.30653 4.47411 4.16193 5.31802 3.31802C6.16193 2.47411 7.30653 2 8.5 2"
				stroke="#333333"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
export function CLVIcon() {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7.72533 13.8668V10.6359C7.72533 9.78094 8.18958 8.98672 8.94585 8.58788C9.77797 8.14901 10.3452 7.27532 10.3453 6.26917C10.3454 4.82337 9.17093 3.64897 7.72516 3.64905C6.27828 3.64914 5.10539 4.82209 5.10539 6.269C5.10539 7.29022 5.54469 8.175 6.41422 8.60724C7.21264 9.0041 7.72533 9.81017 7.72533 10.7018V13.8668ZM7.72533 13.8668C7.72533 14.5099 7.20399 15.0312 6.56091 15.0312H3.66442C3.02134 15.0312 2.5 14.5099 2.5 13.8668V6.22533C2.5 3.33946 4.83946 1 7.72533 1C10.6112 1 12.9507 3.33946 12.9507 6.22533V13.8668C12.9507 14.5099 12.4293 15.0312 11.7862 15.0312H8.88975C8.24667 15.0312 7.72533 14.5099 7.72533 13.8668Z"
				stroke="#333333"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M10.3447 6.26953H12.9647"
				stroke="#333333"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M5.10548 6.26953H2.51465"
				stroke="#333333"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M2.52051 12.3828H12.9513"
				stroke="#333333"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M4.23145 10.6342V9.32422"
				stroke="#333333"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M11.2188 10.6342V9.32422"
				stroke="#333333"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
export function ILVIcon() {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7.72533 13.8668V10.6359C7.72533 9.78094 8.18958 8.98672 8.94585 8.58788C9.77797 8.14901 10.3452 7.27532 10.3453 6.26917C10.3454 4.82337 9.17093 3.64897 7.72516 3.64905C6.27828 3.64914 5.10539 4.82209 5.10539 6.269C5.10539 7.29022 5.54469 8.175 6.41422 8.60724C7.21264 9.0041 7.72533 9.81017 7.72533 10.7018V13.8668ZM7.72533 13.8668C7.72533 14.5099 7.20399 15.0312 6.56091 15.0312H3.66442C3.02134 15.0312 2.5 14.5099 2.5 13.8668V6.22533C2.5 3.33946 4.83946 1 7.72533 1C10.6112 1 12.9507 3.33946 12.9507 6.22533V13.8668C12.9507 14.5099 12.4293 15.0312 11.7862 15.0312H8.88975C8.24667 15.0312 7.72533 14.5099 7.72533 13.8668Z"
				stroke="#333333"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M10.3447 6.26953H12.9647"
				stroke="#333333"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M5.10548 6.26953H2.51465"
				stroke="#333333"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M2.52051 12.3828H12.9513"
				stroke="#333333"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M4.23145 10.6342V9.32422"
				stroke="#333333"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M11.2188 10.6342V9.32422"
				stroke="#333333"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
export function PBEIcon() {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M14 12.9437C14 12.6636 13.8887 12.3949 13.6906 12.1969L13.092 11.5982C12.7169 11.2232 12.5062 10.7145 12.5062 10.184V4.98755C12.5062 3.33758 11.1686 2 9.51867 2H6.48133C4.83135 2 3.49378 3.33758 3.49378 4.98755V10.184C3.49378 10.7145 3.28306 11.2232 2.90799 11.5982L2.30937 12.1969C2.11128 12.3949 2 12.6636 2 12.9437V12.9437C2 13.5271 2.4729 14 3.05626 14H12.9437C13.5271 14 14 13.5271 14 12.9437V12.9437Z"
				stroke="#333333"
				stroke-miterlimit="10"
			/>
			<path
				d="M6.5124 10C5.67713 10 5 9.51875 5 8.92511V8C5 6.89543 5.89543 6 7 6H9C10.1046 6 11 6.89543 11 8V8.92511C11 9.51875 10.3229 10 9.4876 10"
				stroke="#333333"
				stroke-miterlimit="10"
			/>
			<path
				d="M7.99964 12.1321C8.82463 12.1321 9.49341 11.4633 9.49341 10.6383C9.49341 9.81332 8.82463 9.14453 7.99964 9.14453C7.17465 9.14453 6.50586 9.81332 6.50586 10.6383C6.50586 11.4633 7.17465 12.1321 7.99964 12.1321Z"
				stroke="#333333"
				stroke-miterlimit="10"
			/>
			<path d="M8 12.1328V14" stroke="#333333" stroke-miterlimit="10" />
		</svg>
	);
}
export function AEDIcon() {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8 4.22641L7.26125 3.48828C6.78925 3.01637 6.18792 2.69501 5.5333 2.56483C4.87868 2.43464 4.20015 2.50147 3.58351 2.75688C2.96686 3.01229 2.4398 3.4448 2.06894 3.99973C1.69808 4.55466 1.50009 5.20709 1.5 5.87453C1.5 9.99953 8 13.4995 8 13.4995C8 13.4995 14.5 9.99953 14.5 5.87453C14.4999 5.20709 14.3019 4.55466 13.9311 3.99973C13.5602 3.4448 13.0331 3.01229 12.4165 2.75688C11.7998 2.50147 11.1213 2.43464 10.4667 2.56483C9.81208 2.69501 9.21075 3.01637 8.73875 3.48828L7 5.22641L8.88625 7.11328L7.5 8.49953"
				stroke="#333333"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
export function EMKIcon() {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13.5 4.5H2.5C2.22386 4.5 2 4.72386 2 5V13C2 13.2761 2.22386 13.5 2.5 13.5H13.5C13.7761 13.5 14 13.2761 14 13V5C14 4.72386 13.7761 4.5 13.5 4.5Z"
				stroke="#333333"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M10.5 4.5V3.5C10.5 3.23478 10.3946 2.98043 10.2071 2.79289C10.0196 2.60536 9.76522 2.5 9.5 2.5H6.5C6.23478 2.5 5.98043 2.60536 5.79289 2.79289C5.60536 2.98043 5.5 3.23478 5.5 3.5V4.5"
				stroke="#333333"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M8 7.5V10.5"
				stroke="#333333"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M9.5 9H6.5"
				stroke="#333333"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
export function MFAKIcon() {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M6 10H2.5C2.36739 10 2.24021 9.94732 2.14645 9.85355C2.05268 9.75979 2 9.63261 2 9.5V6.5C2 6.36739 2.05268 6.24021 2.14645 6.14645C2.24021 6.05268 2.36739 6 2.5 6H6V2.5C6 2.36739 6.05268 2.24021 6.14645 2.14645C6.24021 2.05268 6.36739 2 6.5 2H9.5C9.63261 2 9.75979 2.05268 9.85355 2.14645C9.94732 2.24021 10 2.36739 10 2.5V6H13.5C13.6326 6 13.7598 6.05268 13.8536 6.14645C13.9473 6.24021 14 6.36739 14 6.5V9.5C14 9.63261 13.9473 9.75979 13.8536 9.85355C13.7598 9.94732 13.6326 10 13.5 10H10V13.5C10 13.6326 9.94732 13.7598 9.85355 13.8536C9.75979 13.9473 9.63261 14 9.5 14H6.5C6.36739 14 6.24021 13.9473 6.14645 13.8536C6.05268 13.7598 6 13.6326 6 13.5V10Z"
				stroke="#333333"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
export function O2Icon() {
	return (
		<svg
			width="16"
			height="18"
			viewBox="0 0 16 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8.8125 16.5312C9.91707 16.5312 10.8125 15.6358 10.8125 14.5312V7.5625C10.8125 6.26809 9.76316 5.21875 8.46875 5.21875H6.59375C5.29934 5.21875 4.25 6.26809 4.25 7.5625V14.5312C4.25 15.6358 5.14543 16.5312 6.25 16.5312H8.8125Z"
				stroke="#333333"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M4.25 13.1562H10.8125"
				stroke="#333333"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M4.25 9.03125H10.8125"
				stroke="#333333"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M11.4751 1.96875V4.78125"
				stroke="#333333"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M11.4751 3.375H9.6001"
				stroke="#333333"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M7.53125 5.21875C8.56678 5.21875 9.40625 4.37928 9.40625 3.34375C9.40625 2.30822 8.56678 1.46875 7.53125 1.46875C6.49572 1.46875 5.65625 2.30822 5.65625 3.34375C5.65625 4.37928 6.49572 5.21875 7.53125 5.21875Z"
				stroke="#333333"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
export function UPKIcon() {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13.5 4H2.5C2.22386 4 2 4.22386 2 4.5V12.5C2 12.7761 2.22386 13 2.5 13H13.5C13.7761 13 14 12.7761 14 12.5V4.5C14 4.22386 13.7761 4 13.5 4Z"
				stroke="#333333"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M2 10.5H14"
				stroke="#333333"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M2 6.5H14"
				stroke="#333333"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
export default function boxRowIcons({ iconFor }) {
	switch (iconFor) {
		case "BCF":
			return BCFIcon;
		case "CLV":
			return CLVIcon;
		case "PBE":
			return PBEIcon;
		case "AED":
			return AEDIcon;
		case "EMK":
			return EMKIcon;
		case "ILV":
			return ILVIcon;
		case "MFAK":
			return MFAKIcon;
		case "O2":
			return O2Icon;
		case "UPK":
			return UPKIcon;
		default:
			return CLVIcon;
	}
}
