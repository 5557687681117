import React from 'react';
import { Grid, Checkbox, styled } from "@mui/material";
import EleSearchBox from "./EleSearchBox.js";
import Airplane from "../../../common/images/icons/elevade/Airplane.svg";
import AirplaneActive from "../../../common/images/icons/elevade/Airplane-active.svg";
import FilterOperator from "../../../common/images/icons/elevade/Filter-operator.svg";
import FilterOperatorActive from "../../../common/images/icons/elevade/Filter-operator-active.svg";
import FilterFault from "../../../common/images/icons/elevade/Filter-fault.svg";
import FilterFaultActive from "../../../common/images/icons/elevade/Filter-fault-active.svg";
import FilterDeparture from "../../../common/images/icons/elevade/Filter-departure.svg";
import FilterDepartureActive from "../../../common/images/icons/elevade/Filter-departure-active.svg";
import FilterArrival from "../../../common/images/icons/elevade/Filter-arrival.svg";
import FilterArrivalActive from "../../../common/images/icons/elevade/Filter-arrival-active.svg";
import FilterSort from "../../../common/images/icons/elevade/Filter-sort.svg";
import FilterSortAscActive from "../../../common/images/icons/elevade/Filter-sort-asc-active.svg";
import FilterSortDescActive from "../../../common/images/icons/elevade/Filter-sort-desc-active.svg";
// import FilterAdvance from "../../../common/images/icons/elevade/Filter-advance.svg";
// import FilterAdvanceActive from "../../../common/images/icons/elevade/Filter-advance-active.svg";
import FleetCardHigh from "common/images/icons/elevade/Fleet-Card-High.svg";
import FleetCardMed from "common/images/icons/elevade/Fleet-Card-Med.svg";
import FleetCardLow from "common/images/icons/elevade/Fleet-Card-Low.svg";
import FleetCardOk from "common/images/icons/elevade/Fleet-Card-Ok.svg";
import FleetCardUndefined from "common/images/icons/elevade/Fleet-Card-Undefined.svg";
import FleetCardNone from "common/images/icons/elevade/Fleet-Card-None.svg";
import FleetCardNuisance from "common/images/icons/elevade/Fleet-Card-Nuisance.svg";
import styles from "./SearchBar.module.css";
import { isMac, uniqueList } from "./Helpers";
import { useEffect, useRef, useState } from "react";
import Switch from "react-switch";
// import useMediaQuery from "@mui/material/useMediaQuery";

const SearchBar = ({
  isSearchInput,
  setIsSearchInput,
  data,
  isAircraftType,
  setIsAircraftType,
  isOperator,
  setIsOperator,
  isFaultPriority,
  setIsFaultPriority,
  isDepartureAirport,
  setIsDepartureAirport,
  isArrivalAirport,
  setIsArrivalAirport,
  isSortBy,
  setIsSortBy,
  isSorting,
  setIsSorting,
  countAoc,
  airportList,
  isAdvanceFilter,
  setIsAdvanceFilter,
}) => {
  const refAircraftTypes = useRef(null);
  const refOperator = useRef(null);
  const refFault = useRef(null);
  const refDeparture = useRef(null);
  const refArrival = useRef(null);
  const refSort = useRef(null);
  const [openAircraftType, setOpenAircraftType] = useState(false);
  const [openOperator, setOpenOperator] = useState(false);
  const [openFault, setOpenFault] = useState(false);
  const [openDeparture, setOpenDeparture] = useState(false);
  const [openArrival, setOpenArrival] = useState(false);
  const [openSort, setOpenSort] = useState(false);
  const [checked, setChecked] = useState(false);
  // const [sortByAsc, setSortAsc] = useState("'ASC.'");
  // const [sortByDesc, setSortByDesc] = useState("");
  const [isSortByVal, setIsSortByVal] = useState([]);
  const [isDepartureAirportName, setIsDepartureAirportName] = useState([]);
  const [isArrivalAirportName, setIsArrivalAirportName] = useState([]);
  // const isWeb = useMediaQuery("(min-width:900px)");
  // const isTablet = useMediaQuery("(min-width:760px)");
  // const isMobile = useMediaQuery("(max-width:480px)");

  useEffect(() => {
    if (isDepartureAirport.length === 0) setIsDepartureAirportName([]);
    if (isArrivalAirport.length === 0) setIsArrivalAirportName([]);
  }, [isDepartureAirport, isArrivalAirport]);

  useEffect(() => {
    if (isSortBy.length === 0 || isSortBy === "") {
      setIsSortByVal([]);
      setIsSorting("asc");
    }
    // eslint-disable-next-line
  }, [isSortBy]);

  // Function to detect mouse click outside a component
  function useOutsideClicker(ref, component) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (component === "aircraftTypes") setOpenAircraftType(false);
          else if (component === "operator") setOpenOperator(false);
          else if (component === "fault") setOpenFault(false);
          else if (component === "departure") setOpenDeparture(false);
          else if (component === "arrival") setOpenArrival(false);
          else if (component === "sort") setOpenSort(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, component]);
  }

  useOutsideClicker(refAircraftTypes, "aircraftTypes");
  useOutsideClicker(refOperator, "operator");
  useOutsideClicker(refFault, "fault");
  useOutsideClicker(refDeparture, "departure");
  useOutsideClicker(refArrival, "arrival");
  useOutsideClicker(refSort, "sort");

  const StyledCheckbox = styled(Checkbox)(() => ({
    [`.MuiCheckbox-root`]: {
      marginTop: "6px",
      border: "1px solid blue",
    },
    marginTop: "6px",
    color: "#94A3B8",
  }));

  const priorityIcon = (priority) => {
    switch (priority) {
      case "High":
        return <img src={FleetCardHigh} alt="High" className={styles.fleetIcon} />;
      case "Medium":
        return <img src={FleetCardMed} alt="Medium" className={styles.fleetIcon} />;
      case "Low":
        return <img src={FleetCardLow} alt="Low" className={styles.fleetIcon} />;
      case "OK":
        return <img src={FleetCardOk} alt="Ok" className={styles.fleetIcon} />;
      case "NO WARNING OR FAULT MESSAGE":
        return <img src={FleetCardOk} alt="Ok" className={styles.fleetIcon} />;
      case "Undefined":
        return <img src={FleetCardUndefined} alt="Undefined" className={styles.fleetIcon} />;
      case "None":
        return <img src={FleetCardNone} alt="None" className={styles.fleetIcon} />;
      case "Nuisance":
        return <img src={FleetCardNuisance} alt="Nuisance" className={styles.fleetIcon} />;
      default:
        return <img src={FleetCardUndefined} alt="None" className={styles.fleetIcon} />;
    }
  };

  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
    if (nextChecked) {
      // setSortAsc("");
      // setSortByDesc("'DESC.'");
      setChecked(true);
      setIsSorting("desc");
    } else {
      // setSortAsc("'ASC.'");
      // setSortByDesc("");
      setChecked(false);
      setIsSorting("asc");
    }
  };

  const Sorting = () => {
    return (
      <>
        <span style={{ marginRight: "10px", marginTop: "2px" }}>Sorting</span>{" "}
        <Switch
          onChange={handleChange}
          checked={checked}
          className="react-switch"
          height={22}
          width={64}
          offColor="#088FD1"
          onColor="#394458"
          handleDiameter={16}
          uncheckedIcon={
            <div
              style={{
                color: "#ffffff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                marginRight: "8px",
              }}
            >
              ASC.
            </div>
          }
          checkedIcon={
            <div
              style={{
                color: "#ffffff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                marginLeft: "15px",
              }}
            >
              DESC.
            </div>
          }
        />
      </>
    );
  };

  const getSortValue = (item) => {
    let itemSort = "";
    if (item === "Aircraft Registration") itemSort = "AC_REG";
    else if (item === "Arrival Airport") itemSort = "destination_airport_format";
    else if (item === "Departure Airport") itemSort = "departure_airport_format";
    else if (item === "Fault Priority") itemSort = "priority_level";
    else if (item === "Operator") itemSort = "Operator";

    return itemSort;
  };

  const handleOperatorChecked = (e) => {
    setIsOperator([...isOperator, e]);
  };

  const handleOperatorUnchecked = (e) => {
    setIsOperator(isOperator?.filter((item) => item !== e));
  };

  const getFilterLabel = (data, col) => {
    const dataLength = data.length;
    const dataLengthLabel = data.length - 1;
    let dataLabel = "";
    let additionalLabel = "";
    if (dataLength > 0) {
      dataLabel =
        col === "Departure Airport" || col === "Arrival Airport"
          ? GetIATA(data[0], airportList, "code")
          : col === "Sort By"
          ? data
          : col === "Fault Priority"
          ? data[0] === "Null" || data[0] === "Missing"
            ? "Undefined"
            : data[0]
          : data[0];
      if (dataLength > 1) additionalLabel = ` +${dataLengthLabel}`;
      return dataLabel + additionalLabel;
    }

    return col;
  };

  function GetIATA(val, airportList, col) {
    let filtered = [];
    val && val !== "" && airportList !== "" && (filtered = airportList?.filter((item) => item.label.includes(val)));
    if (filtered !== "" && filtered !== undefined) {
      if (col === "code") return filtered[0]?.label?.split(" - ")[0];
      else if (col === "name") return filtered[0]?.label?.split(" - ")[1];
      else return filtered[0]?.label;
    }
  }

  const FilterModal = ({ component, refs, title, col, rightEnd }) => {
    let initialList = uniqueList(data, col, airportList);
    if (col === "sort")
      initialList = ["Aircraft Registration", "Arrival Airport", "Departure Airport", "Fault Priority", "Operator"];
    if (col === "priority") {
      var index = initialList.indexOf("Missing");
      if (index !== -1) {
        initialList[index] = "Null";
      }
      initialList = [...new Set(initialList)];
      const priority = ["High", "Medium", "Low", "OK", "Nuisance", "None", "Undefined", "Null", "Missing"];
      const sorted = initialList.sort((a, b) => priority.indexOf(a) - priority.indexOf(b));
      if (sorted.length > 0) {
        let filteredFaultPriotiry = sorted.filter((item) => item !== "null" && item !== null);
        initialList = filteredFaultPriotiry;
      }
    }
    const [filteredList, setFilteredList] = useState(initialList);
    const [searchDepartureAirport, setSearchDepartureAirport] = useState();
    const [searchArrivalAirport, setSearchArrivalAirport] = useState();

    function filterList(e, col) {
      if (col === "departureAirport") setSearchDepartureAirport(e.target.value);
      if (col === "arrivalAirport") setSearchArrivalAirport(e.target.value);
      let filteredData = initialList;
      filteredData = filteredData.filter((item) => item?.toLowerCase().includes(e.target.value.toLowerCase()));
      setFilteredList(filteredData);
    }

    return (
      <>
        <div
          className={`${component ? styles.filterModalOpen__ : styles.filterModalClose} ${
            col === "sort"
              ? styles.filterModalOpenSort
              : col === "departure_airport_format" || col === "destination_airport_format"
              ? styles.filterModalOpenAirport
              : ""
          } ${styles.macScrollbar}
          ${rightEnd ? styles.filterModalOpenRightEnd : ""}`}
          ref={refs}
        >
          <span style={{ color: "#838383", marginBottom: "0px" }} key="">
            <Grid container columns={2}>
              <Grid item xs={col === "sort" ? 1 : 2} sx={{ padding: "10px 0 0 8px" }}>
                {title}
              </Grid>
              {col === "sort" && (
                <Grid
                  item
                  xs={1}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingTop: "8px",
                  }}
                >
                  <Sorting />
                </Grid>
              )}
              {col === "departure_airport_format" && (
                <Grid item xs={2}>
                  <input
                    type="text"
                    className={styles.textField}
                    placeholder="Search..."
                    value={searchDepartureAirport}
                    onChange={(e) => filterList(e, "departureAirport")}
                  />
                </Grid>
              )}
              {col === "destination_airport_format" && (
                <Grid item xs={2}>
                  <input
                    type="text"
                    className={styles.textField}
                    placeholder="Search..."
                    value={searchArrivalAirport}
                    onChange={(e) => filterList(e, "arrivalAirport")}
                  />
                </Grid>
              )}
            </Grid>
          </span>
          <ul className={`${styles.ul}`}>
            {filteredList?.map((item) => {
              return (
                <>
                  {(col === "Type" || col === "Operator" || col === "priority") &&
                    item !== "Missing" &&
                    // item !== "Null" &&
                    item !== "null" &&
                    item !== null &&
                    item !== "" && (
                      <>
                        <li className={`${styles.li}`}>
                          <Grid container columns={3}>
                            <Grid item xs={2} sx={{ paddingTop: "10%" }}>
                              {col === "priority" ? priorityIcon(item) : ""}
                              <span
                                style={{
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                }}
                              >
                                {(item === "Null" || item === "Missing") && col === "priority" ? "Undefined" : item}
                              </span>
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <StyledCheckbox
                                value={item}
                                checked={
                                  isAircraftType.filter((e) => e === item).length > 0
                                    ? true
                                    : isOperator?.filter((e) => e === item).length > 0
                                    ? true
                                    : isOperator?.filter((e) => e === "All").length > 0 && col === "Operator"
                                    ? true
                                    : isFaultPriority.filter((e) => e === item).length > 0
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    if (col === "Type") setIsAircraftType([...isAircraftType, e.target.value]);
                                    else if (col === "Operator") handleOperatorChecked(e.target.value);
                                    else if (col === "priority")
                                      setIsFaultPriority([...isFaultPriority, e.target.value]);
                                  } else {
                                    if (col === "Type")
                                      setIsAircraftType(isAircraftType.filter((item) => item !== e.target.value));
                                    else if (col === "Operator") handleOperatorUnchecked(e.target.value);
                                    else if (col === "priority")
                                      setIsFaultPriority(isFaultPriority.filter((item) => item !== e.target.value));
                                  }
                                }}
                              />
                            </Grid>
                          </Grid>
                        </li>
                      </>
                    )}
                  {(col === "departure_airport_format" || col === "destination_airport_format") && (
                    <>
                      <li className={`${styles.li}`}>
                        <Grid container columns={3}>
                          <Grid
                            item
                            xs={2.6}
                            sx={{
                              paddingTop: "5%",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {GetIATA(item, airportList)}
                          </Grid>
                          <Grid item xs={0.4} sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <StyledCheckbox
                              value={item}
                              checked={
                                isDepartureAirport.filter((e) =>
                                  e?.toLowerCase()?.includes(item?.split(" - ")[0].toLowerCase())
                                ).length > 0 && col === "departure_airport_format"
                                  ? true
                                  : isArrivalAirport.filter((e) => e === item?.split(" - ")[0]).length > 0 &&
                                    col === "destination_airport_format"
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  if (col === "departure_airport_format") {
                                    setIsDepartureAirport([...isDepartureAirport, e.target.value.split(" - ")[0]]);
                                    setIsDepartureAirportName([...isDepartureAirportName, e.target.value]);
                                  } else if (col === "destination_airport_format") {
                                    setIsArrivalAirport([...isArrivalAirport, e.target.value.split(" - ")[0]]);
                                    setIsArrivalAirportName([...isArrivalAirportName, e.target.value]);
                                  }
                                } else {
                                  if (col === "departure_airport_format") {
                                    setIsDepartureAirport(
                                      isDepartureAirport.filter((item) => item !== e.target.value.split(" - ")[0])
                                    );
                                    setIsDepartureAirportName(
                                      isDepartureAirportName.filter((item) => item !== e.target.value)
                                    );
                                  } else if (col === "destination_airport_format") {
                                    setIsArrivalAirport(
                                      isArrivalAirport.filter((item) => item !== e.target.value.split(" - ")[0])
                                    );
                                    setIsArrivalAirportName(
                                      isArrivalAirportName.filter((item) => item !== e.target.value)
                                    );
                                  }
                                }
                              }}
                            />
                          </Grid>
                        </Grid>
                      </li>
                    </>
                  )}
                  {col === "sort" && (
                    <>
                      <li
                        className={`${styles.sortingLabel} ${
                          isSortByVal.filter((e) => e?.toLowerCase()?.includes(item?.toLowerCase())).length > 0
                            ? styles.sortingLabelActive
                            : ""
                        }`}
                        onClick={() => {
                          setIsSortBy([getSortValue(item)]);
                          setIsSortByVal([item]);
                        }}
                      >
                        <Grid container columns={3}>
                          <Grid item xs={3} sx={{}}>
                            {item}
                          </Grid>
                        </Grid>
                      </li>
                    </>
                  )}
                </>
              );
            })}
          </ul>
        </div>
      </>
    );
  };

  return (
    <>
      <div className={`${styles.main}`}>
        <Grid container columns={12}>
          <Grid item xs={12} sm={12} md={6} lg={1.5} xl={2} className={`${styles.searchInput}`}>
            <EleSearchBox
              placeholder="Search..."
              isSearchInput={isSearchInput}
              setIsSearchInput={setIsSearchInput}
              isAircraftType={isAircraftType}
              setIsAircraftType={setIsAircraftType}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={10.5} xl={10} className={`${styles.filterCol_}`}>
            <div className={`${styles.filterDivPosition}`}>
              <div
                className={`${styles.filterDiv} ${openOperator || isOperator.length > 0 ? styles.active : ""}`}
                onClick={() => setOpenOperator(true)}
              >
                <img
                  src={isOperator.length > 0 ? FilterOperatorActive : FilterOperator}
                  height="20px"
                  alt="airplane"
                  style={{ marginRight: "8px" }}
                />
                <div style={{ marginTop: "2px" }}>{getFilterLabel(isOperator, "Operator")}</div>
              </div>
              <FilterModal component={openOperator} refs={refOperator} title="Operator" col="Operator" key="operator" />
            </div>
            <div className={`${styles.filterDivPosition}`}>
              <span
                className={`${styles.filterDiv} ${openAircraftType || isAircraftType.length > 0 ? styles.active : ""}`}
                onClick={() => setOpenAircraftType(true)}
              >
                <img
                  src={isAircraftType.length > 0 ? AirplaneActive : Airplane}
                  height="20px"
                  alt="airplane"
                  style={{ marginRight: "8px" }}
                />
                <div style={{ marginTop: "2px" }}>{getFilterLabel(isAircraftType, "Aircraft Types")}</div>
              </span>
              <FilterModal
                component={openAircraftType}
                refs={refAircraftTypes}
                title="Aircraft Types"
                col="Type"
                key="aircraft-type"
              />
            </div>
            <div className={`${styles.filterDivPosition}`}>
              <div
                className={`${styles.filterDiv} ${openFault || isFaultPriority.length > 0 ? styles.active : ""}`}
                onClick={() => setOpenFault(true)}
              >
                <img
                  src={isFaultPriority.length > 0 ? FilterFaultActive : FilterFault}
                  height="20px"
                  alt="fault"
                  style={{ marginRight: "8px" }}
                />
                <div style={{ marginTop: "2px" }}>{getFilterLabel(isFaultPriority, "Fault Priority")}</div>
              </div>
              <FilterModal component={openFault} refs={refFault} title="Fault Priority" col="priority" key="priority" />
            </div>
            <div className={`${styles.filterDivPosition}`}>
              <div
                className={`${styles.filterDiv} ${openDeparture || isDepartureAirport.length > 0 ? styles.active : ""}`}
                onClick={() => setOpenDeparture(true)}
              >
                <img
                  src={isDepartureAirport.length > 0 ? FilterDepartureActive : FilterDeparture}
                  height="20px"
                  alt="departure"
                  style={{ marginRight: "8px" }}
                />
                <div
                  style={{
                    marginTop: "2px",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {getFilterLabel(isDepartureAirport, "Departure Airport")}
                </div>
              </div>
              <FilterModal
                component={openDeparture}
                refs={refDeparture}
                title="Departure Airport"
                col="departure_airport_format"
                key="departure_airport"
              />
            </div>
            <div className={`${styles.filterDivPosition}`}>
              <div
                className={`${styles.filterDiv} ${openArrival || isArrivalAirport.length > 0 ? styles.active : ""}`}
                onClick={() => setOpenArrival(true)}
              >
                <img
                  src={isArrivalAirport.length > 0 ? FilterArrivalActive : FilterArrival}
                  height="20px"
                  alt="arrival"
                  style={{ marginRight: "8px" }}
                />
                <div style={{ marginTop: "2px" }}>{getFilterLabel(isArrivalAirport, "Arrival Airport")}</div>
              </div>
              <FilterModal
                component={openArrival}
                refs={refArrival}
                title="Arrival Airport"
                col="destination_airport_format"
                key="arrival_airport"
                rightEnd
              />
            </div>
            <div className={`${styles.filterDivPosition}`}>
              <div
                className={`${styles.filterDiv} ${openSort || isSortBy.length > 0 ? styles.active : ""}`}
                onClick={() => setOpenSort(true)}
              >
                <img
                  src={
                    isSortBy.length > 0
                      ? isSorting === "asc"
                        ? FilterSortAscActive
                        : FilterSortDescActive
                      : FilterSort
                  }
                  height="20px"
                  alt="sort"
                  style={{ marginRight: "8px" }}
                />
                <div style={{ marginTop: "2px" }}>Sort By</div>
              </div>
              <FilterModal component={openSort} refs={refSort} title="Sort by" col="sort" key="sort" rightEnd />
            </div>
            {/* <div className={`${styles.filterDivPosition}`}>
              <div className={`${styles.filterDiv} ${isAdvanceFilter ? styles.active : ""}`}>
                <img
                  src={isAdvanceFilter ? FilterAdvanceActive : FilterAdvance}
                  height="20px"
                  alt="airplane"
                  style={{ marginRight: "8px" }}
                />
                <div style={{ marginTop: "2px" }} onClick={() => setIsAdvanceFilter(!isAdvanceFilter)}>
                  Advanced Filter
                </div>
              </div>
            </div> */}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default SearchBar;
