import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";
import InactiveDot from "../assets/InactiveDot.json";

const Live = ({ style }) => {
  const anime = useRef(null);
  useEffect(() => {
    lottie.loadAnimation({
      container: anime.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: InactiveDot,
    });
    return () => lottie.stop();
  }, []);
  return <div ref={anime} style={style}></div>;
};

export default Live;
