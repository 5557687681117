import React from "react";
import { Box, Divider, Grow, Tooltip, Button } from "@mui/material";
import styles from "./Sidebar.module.css";
import { ModuleMenu } from "./SidebarIconsList";

const ElevadeSidebar = () => {
  return (
    <Box
      sx={{
        width: "32px",
        marginTop: "auto",
        marginBottom: "auto",
        backgroundColor: "#fff",
      }}
    >
      <ul
        style={{
          padding: "4px",
        }}
      >
        {ModuleMenu.map((item, index) => {
          if (item.isDivider) {
            return (
              <li key={index}>
                <Divider variant="middle" style={{ width: "100%" }} sx={{ my: 3, mx: 0 }} key={index} />
              </li>
            );
          } else {
            return (
              <li
                style={{
                  textAlign: "center",
                  marginBottom: "16px",
                }}
                key={index}
              >
                <Tooltip
                  title={item.label}
                  placement="right"
                  // arrow
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, 20],
                        },
                      },
                    ],
                    style: {
                      zIndex: 1999,
                      fontFamily: "Gotham-Book",
                      fontSize: 8
                    },
                  }}
                  TransitionComponent={Grow}
                  TransitionProps={{ timeout: 200 }}
                >
                  <a
                    href={item?.url}
                    onClick={(e) => {
                      if (item.label === "FLEET") {
                        e.preventDefault();
                      }
                    }}
                  >
                    <Button
                      className={`${styles.FrtLyrSideBarContainer}`}
                      style={{
                        minWidth: 0,
                        padding: 0,
                        pointer: item.label === "FLEET" ? "default" : "cursor",
                      }}
                    >
                      <Box
                        className={`${styles.FrtLyrSideBarBtn}`}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          background: "white",
                        }}
                      >
                        <img src={item?.icon} alt={item?.label} />
                      </Box>
                    </Button>
                  </a>
                </Tooltip>
              </li>
            );
          }
        })}
      </ul>
    </Box>
  );
};

export default ElevadeSidebar;
