import React from 'react';
import { ClickAwayListener } from "@mui/base";
import Drawer from "@mui/material/Drawer";

const ElevadeDrawer = ({ open, setOpen, data, Component, ...props }) => {
  return (
    <>
      <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={() => open && setOpen(true)}>
        <Drawer
          anchor={"right"}
          open={open}
          onEscapeKeyDown={() => {
            setOpen(false);
          }}
          PaperProps={{
            sx: {
              width: "540px",
              color: "black",
              height: props.height ?? "100%",
              top: props.top ?? "0%",
              boxShadow: "0px 8px 19px 0px rgba(0, 0, 0, 0.20)",
              backgroundColor: "#ffffff",
            },
          }}
          BackdropProps={{ invisible: true }}
          onClose={() => open && setOpen(false)}
          sx={{
            zIndex: 9,
          }}
        >
          <Component data={data} open={open} setOpen={setOpen} {...props} />
        </Drawer>
      </ClickAwayListener>
    </>
  );
};

export default ElevadeDrawer;
