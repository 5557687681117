// A function to format date before parsing to API for query
export function formatDate(date) {
  let dt = new Date(date),
    month = "" + (dt.getMonth() + 1),
    day = "" + dt.getDate(),
    year = dt.getFullYear(),
    hour = dt.getHours(),
    minute = dt.getMinutes(),
    second = dt.getSeconds();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  let date_ = [year, month, day].join(",");
  let time_ = [hour, minute, second].join(",");
  let dateTime_ = [date_, time_].join(",");

  if (date !== null) return dateTime_;
}
