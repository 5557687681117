import React from 'react';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import MuiCircularProgress from '@mui/material/CircularProgress';

const StyledBox = styled(Box)(() => ({
  width: '100%',
  height: '200px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const Spinner = () => {
  return (
    <StyledBox>
      <MuiCircularProgress size={50} color="primary" />
    </StyledBox>
  );
};

export default Spinner;