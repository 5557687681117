import { createSlice } from '@reduxjs/toolkit';
import { notificationSuccess, showNotification } from "common/helpers";
import { getUserDetailByEmail, clearUserDetail } from './thunks';

const userInitialState = {
  userProfile: {},
  companyProfile: {},
  userPermission: [],
  userId: '',
  userName: '',
  loading: true,
  userAOC: [],
  companySubElevade: false,
  companyAOC: [],
  userSubElevade: false,
}

const user = createSlice({
  name: 'userEntity',
  initialState: userInitialState,
  reducers: {
    setUser: (state, { payload }) => {
      state.userName = payload.userName;
      state.userId = payload.userId;
      state.userProfile = payload.userProfile;
      state.companyProfile = payload.companyProfile;
      state.roles = payload.roles;
      state.userPermission = payload.userPermission;
      state.userAOC = payload.userAOC;
      state.companySubElevade = payload.companySubElevade;
      state.companyAOC = payload.companyAOC;
      state.userSubElevade = payload.userSubElevade;
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(getUserDetailByEmail.pending, (state) => {
      state.loading = true
    })
    .addCase(getUserDetailByEmail.fulfilled, (state, { payload }) => {
      state.userId = payload.userId;
      state.userName = payload.userName;
      state.loading = false;
      state.userProfile = payload.userProfile;
      state.companyProfile = payload.companyProfile;
      state.roles = payload.roles;
      state.userPermission = payload.userPermission;
      state.userAOC = payload.userAOC
      state.companySubElevade = payload.companySubElevade;
      state.companyAOC = payload.companyAOC;
      state.userSubElevade = payload.userSubElevade;
    })
    .addCase(getUserDetailByEmail.rejected, (state, action) => {
      state.loading = false;
      if (action.error?.message) {
        showNotification("danger", "Error", [action.error.message]);
      } else {
        showNotification("danger", "Error", ['Failed to retrieve Company Detail!!!']);
      }
    })
    .addCase(clearUserDetail.fulfilled, (state) => {
      state.userProfile = {};
      state.companyProfile = {};
      state.roles = {};
      state.userPermission = [];
      state.userId = '';
      state.userName = '';
      state.userAOC = [];
      state.companySubElevade = false;;
      state.companyAOC = [];
      state.userSubElevade = false;
    });
  }
});

export const { setUser } = user.actions;

export default user.reducer;