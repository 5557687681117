import React from 'react';
import { Grid, Modal, Box } from "@mui/material";
import Button from "pages/elevade/component/Button";
import { useHistory } from "react-router-dom";

const DialogNotification = ({ open, setOpen, title, subTitle }) => {
  const history = useHistory();

  const handleClose = () => {
    setOpen(false);
  };

  const handleRedirect = (e) => {
    return history.push(e);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            border: "0px solid #000",
            boxShadow: 24,
            padding: "16px 0px",
            borderRadius: "8px",
          }}
        >
          <Grid container columns={1}>
            <Grid item xs={1} sx={{ padding: "32px 24px 16px 24px", display: "flex", justifyContent: "center" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
                <path
                  d="M30 40L36.6667 46.6667L50 33.3333M70 40C70 56.5685 56.5685 70 40 70C23.4315 70 10 56.5685 10 40C10 23.4315 23.4315 10 40 10C56.5685 10 70 23.4315 70 40Z"
                  stroke="#6AB47B"
                  stroke-width="6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Grid>
            <Grid item xs={1} sx={{ display: "flex", justifyContent: "center" }}>
              <div style={{ fontFamily: "Gotham-Medium" }}>{title}</div>
            </Grid>
            <Grid item xs={1}>
              <center>
                <div style={{ fontFamily: "Gotham-Book", color: "#838383", fontSize: "14px" }}>{subTitle}</div>
              </center>
            </Grid>
            <Grid
              item
              xs={1}
              sx={{ display: "flex", justifyContent: "center", paddingBottom: "16px", paddingTop: "44px" }}
            >
              <Button
                title="Go to Task Assignment"
                variant={"outlined"}
                onClick={() => handleRedirect("/fleet/dasr/task-assignment")}
              />
              <Button title="Close Dialog" variant={"fill"} onClick={handleClose} />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default DialogNotification;
