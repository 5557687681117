import React from "react";
import { Link, useParams } from "react-router-dom";
import styles from "./AircraftStatus.module.css";
import ArrowLeft from "common/images/icons/elevade/Arrow-Left.svg";
import ArrowLeftActive from "common/images/icons/elevade/Arrow-Left-Active.svg";

const PageUnavailable = () => {
  const id = useParams().id;
  const pathname = window.location.pathname;
  function getActivePath(path) {
    if (pathname.includes(path)) {
      return styles.active;
    } else {
      return "";
    }
  }

  const Header = () => {
    return (
      <div style={{ overflow: "hidden", padding: "0 29px" }}>
        <div className={`${styles.header}`}>
          <Link to="/fleet" className={`${styles.arrowLeft}`}>
            <img
              src={ArrowLeft}
              alt="arrow left"
              onMouseOver={(e) => (e.currentTarget.src = ArrowLeftActive)}
              onMouseOut={(e) => (e.currentTarget.src = ArrowLeft)}
            />
          </Link>
          <div className={`${styles.headerText} ${getActivePath("failure-messages")}`}>
            <Link to={`/fleet/${id}/failure-messages`} className={`${styles.link}`}>
              Failure & Warnings Messages
            </Link>
          </div>
          <div className={`${styles.headerText} ${getActivePath("schedule")}`}>
            <Link to={`/fleet/${id}/schedule`} className={`${styles.link}`}>
              Schedule
            </Link>
          </div>
          <div className={`${styles.headerText} ${getActivePath("uplink")}`}>
            <Link to={`/fleet/${id}/uplink`} className={`${styles.link}`}>
              Uplink
            </Link>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <Header />
      <div style={{ paddingLeft: "50px", paddingTop: "50px" }}>
        <h2>503: Page unavailable</h2>
      </div>
    </>
  );
};

export default PageUnavailable;
