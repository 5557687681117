import React from 'react';
import { Grid, Modal, Box } from "@mui/material";
import IconClose from "common/images/icons/elevade/Icon-Close.svg";
import Button from "pages/elevade/component/Button";
import { makeAPIRequestMDB } from "common/helpers/makeAPIRequestMDB";
import { showNotificationElevade } from "common/helpers";

const DialogDelete = ({ open, setOpen, data, setData, setTriggerApi }) => {
  const handleClose = () => {
    setOpen(false);
  };
  const isArray = {
    ids: data,
  };

  const handleConfirm = () => {
    // Call API for ddml delete
    const params = `api/ddml/bulk/delete`;
    const fetch = makeAPIRequestMDB(`put`, `${params}`, isArray);
    fetch.then((response) => {
      if (response.detail.includes("success")) {
        showNotificationElevade("success", "DDML successfully deleted!", [""]);
        setOpen(false);
        setTriggerApi(true);
        setData([]);
      } else {
        showNotificationElevade("danger", "An error occured!", ["Please contact your system administartor"]);
        setOpen(false);
      }
    });
  };
  return (
    <>
      <Modal
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            border: "0px solid #000",
            boxShadow: 24,
            padding: "16px 0px",
            borderRadius: "8px",
          }}
        >
          <Grid container columns={1}>
            <Grid item xs={0.8} sx={{ padding: "0px 24px 16px 24px", borderBottom: "1px solid #D1D1D1" }}>
              <span style={{ fontFamily: "Gotham-Medium" }}>Delete DDML</span>
            </Grid>
            <Grid
              item
              xs={0.2}
              sx={{
                padding: "0px 24px 16px 24px",
                borderBottom: "1px solid #D1D1D1",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <img
                src={IconClose}
                alt="icon-close"
                height={12}
                style={{ cursor: "pointer" }}
                onClick={() => handleClose()}
              />
            </Grid>
            <Grid item xs={1} sx={{ padding: "32px 64px" }}>
              <center>
                <div style={{ fontFamily: "Gotham-Book", color: "#838383", fontSize: "14px" }}>
                  This will remove the selected DDML from the database.
                </div>
                <div style={{ fontFamily: "Gotham-Book", color: "#838383", fontSize: "14px" }}>
                  Do you want to proceed?
                </div>
              </center>
            </Grid>
            <Grid item xs={1} sx={{ display: "flex", justifyContent: "center", paddingBottom: "16px" }}>
              <Button title="Cancel" variant="" onClick={() => handleClose()} />
              <Button title="Proceed" variant="outlined" onClick={handleConfirm} />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default DialogDelete;
