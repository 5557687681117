
import React from 'react';

import { useState } from "react";
import ACBody from "./components/ACBody";
import ACTip from "./components/ACTip";
import ColNumber from "./components/ColNumber";
import Doors from "./components/Doors";
import HeadABC from "./components/HeadABC";
import HeadDEF from "./components/HeadDEF";
import HeadLeftToilet from "./components/HeadToilet";
import LeftWing from "./components/LeftWing";
import RightWing from "./components/RightWing";
import TailABC from "./components/TailABC";
import TailDEF from "./components/TailDEF";
import TailLeftToilet from "./components/TailLeftToilet";
import TailLine from "./components/TailLine";
import Windshield from "./components/Windshield";
import SeatA1Popup from "./Popup";
import SeatA1 from "./components/SeatA1";
import seats from "./seats/seats";
import TailRightToilet from "./components/TailRightToilet";
import FilterDefs from "./components/FilterDefs";
// import './main.css';
import BoxFlightDeckAdv from "./components/AdvBoxes/BoxFlightDeckAdv";
import Box1lAttnStnAdv from "./components/AdvBoxes/Box1lAttnStnAdv";
import BoxFwdGalleyAdv from "./components/AdvBoxes/BoxFwdGalleyAdv";
import BoxOHBRowDEFAdv from "./components/AdvBoxes/BoxOHBRowDEFAdv";
import BoxBehindLastRowDEFAdv from "./components/AdvBoxes/BoxBehindLastRowDEFAdv";
import Box2rAttSwivelSeatAdv from "./components/AdvBoxes/Box2rAttSwivelSeatAdv";
import BoxAftGalleyAdv from "./components/AdvBoxes/BoxAftGalleyAdv";
import BoxOHBRowABCAdv from "./components/AdvBoxes/BoxOHBRowABCAdv";
import BoxBehindLastRowABCAdv from "./components/AdvBoxes/BoxBehindLastRowABCAdv";
import Box2lAttnStnAdv from "./components/AdvBoxes/Box2lAttnStnAdv";
import BoxBehind2lAttnStnAdv from "./components/AdvBoxes/BoxBehind2lAttnStnAdv";
// @TODO : keep only one data structure : (id, inventory, location)
// @TODO : saparate @ categorize boxes
export default function A320Main({ itemsData, onClickItem, }) {
    // notes : https://www.figma.com/file/rTMT72IKtPfUVTTRE8eJWw/Elevade-Web-Design?node-id=12924-129960&t=ITF6TOKjopH41pS6-0
    const [popUp, setPopUp] = useState(undefined);
    const [zoom, setZoom] = useState(0);
    const [center, setCenter] = useState({
        x: 0,
        y: 0,
    });
    // const [PSeatItems, setPSeatItems] = useState([]) // row-col > PASSENGER SEAT
    // const [FDItems, setFDItems] = useState([]) // Flight Deck > COCKPIT
    // const [FGItems, setFGItems] = useState([]) // FWD Galley > FWD GALLEY
    // const [OLItems, setOLItems] = useState([]) // 1L ATTN. STN. > 
    // const [OHBAbcItems, setOHBAbcItems] = useState([]) // OHB Row 01ABC > P1 SEAT
    // const [OHBDefItems, setOHBDefItems] = useState([]) // OHB Row 01DEF > OHB Row 01DEF
    // const [BLRAbcItems, setBLRAbcItems] = useState([]) // Behind Last Row 31ABC > AFT SPARE 
    // const [BLRDefItems, setBLRDefItems] = useState([]) // Behind Last Row 31DEF > Behind Last Row 31DEF
    // const [TRItems, setTRItems] = useState([]) // 2R ATT. Swivel Seat > 
    // const [TLItems, setTLItems] = useState([]) // 2L ATT. STN. >
    // const [AGItems, setAGItems] = useState([]) // AFT Galley >
    // const [BTLItems, setBTLItems] = useState([]) // Behind 2L ATT. STN. >
    function onScroll(e) {
        const delta = e.deltaY; //* -0.01;
        if (delta < 1) {
            setZoom(zoom + 100);
        }
        else if (delta > 1) {
            setZoom(zoom - 100);
        }
    }
    // <CursorPointerOxygenBottle/>
    const categorized = categorizeInventoryLocation(itemsData);
    const passengerSeats = categorized['PS'];
    const flightDeckInvs = categorized['FD'];
    const oneLInvs = categorized['1L'];
    const fWdInvs = categorized['FWD'];
    const aFtInvs = categorized['AFT'];
    const oHbAbcInvs = categorized['OHB-ABC'];
    const oHbDefInvs = categorized['OHB-DEF'];
    const bLrAbcInvs = categorized['BLR-ABC'];
    const bLrDefInvs = categorized['BLR-DEF'];
    const twoRInvs = categorized['2R'];
    const twoLInvs = categorized['2L'];
    const b2LInvs = categorized['B2L'];
    return (<div style={{
            // overflow: "hidden",
            width: "100%",
            height: "100%",
        }}>
            <svg height="100%" width="100%" 
    // onWheelCapture={onScroll}
    // onMouseMove={(e: any) => {
    //     var rect = e.target.getBoundingClientRect();
    //     var x = e.clientX - rect.left; //x position within the element.
    //     var y = e.clientY - rect.top;  //y position within the element.
    //     console.log("Left? : " + x + " ; Top? : " + y + ".");
    //     setCenter({
    //         x, y,
    //     })
    // }}
    // width={1100 + zoom} height={500 + zoom / 2}
    viewBox={`${0 - center.x} ${0 - center.y} 1363 631`} fill="none" xmlns="http://www.w3.org/2000/svg">
                <TailLine />
                <RightWing />
                <LeftWing />
                <ACBody />
                <Windshield />
                <ColNumber />
                <ACTip />
                <HeadDEF />
                <TailABC />
                <HeadABC />
                <TailDEF />
                <TailLeftToilet />
                <TailRightToilet />
                <HeadLeftToilet />
                <Doors />

                <BoxFlightDeckAdv 
    // {code: "FD", name: "Flight Deck" }
    invs={flightDeckInvs} onClick={(inFleetLocation) => {
            if (inFleetLocation && onClickItem) {
                onClickItem(inFleetLocation);
            }
        }} setPopup={(innerprops) => {
            setPopUp({
                ...innerprops,
                isItemAssingedHere: true,
            });
        }} setClosePopup={() => { setPopUp(undefined); }}/>
                <BoxFwdGalleyAdv 
    // {code: "FWD", name: "FWD Galley" } OR //{code:"ZODIAC", name: "ZODIAC" }
    invs={fWdInvs} onClick={(inFleetLocation) => {
            if (inFleetLocation && onClickItem) {
                onClickItem(inFleetLocation);
            }
        }} setPopup={(innerprops) => {
            setPopUp({
                ...innerprops,
                isItemAssingedHere: true,
            });
        }} setClosePopup={() => { setPopUp(undefined); }}/>
                <BoxOHBRowDEFAdv 
    // { code: "OHB-DEF", name: "OHB Row 01DEF" }
    invs={oHbDefInvs} onClick={(inFleetLocation) => {
            if (inFleetLocation && onClickItem) {
                onClickItem(inFleetLocation);
            }
        }} setPopup={(innerprops) => {
            setPopUp({
                ...innerprops,
                isItemAssingedHere: true,
            });
        }} setClosePopup={() => { setPopUp(undefined); }}/>
                <BoxBehindLastRowDEFAdv 
    // { code: "BLR-DEF", name: "Behind Last Row 31DEF" }
    invs={bLrDefInvs} onClick={(inFleetLocation) => {
            if (inFleetLocation && onClickItem) {
                onClickItem(inFleetLocation);
            }
        }} setPopup={(innerprops) => {
            setPopUp({
                ...innerprops,
                isItemAssingedHere: true,
            });
        }} setClosePopup={() => { setPopUp(undefined); }}/>
                <Box2rAttSwivelSeatAdv 
    // {code: "2R", name: "2R ATT.Swivel Seat" }
    invs={twoRInvs} onClick={(inFleetLocation) => {
            if (inFleetLocation && onClickItem) {
                onClickItem(inFleetLocation);
            }
        }} setPopup={(innerprops) => {
            setPopUp({
                ...innerprops,
                isItemAssingedHere: true,
            });
        }} setClosePopup={() => { setPopUp(undefined); }}/>
                <BoxAftGalleyAdv 
    // {code: "AFT", name: "AFT Galley" } OR //{code:"ZODIAC(AFT)", name: "ZODIAC(AFT)" } 
    invs={aFtInvs} onClick={(inFleetLocation) => {
            if (inFleetLocation && onClickItem) {
                onClickItem(inFleetLocation);
            }
        }} setPopup={(innerprops) => {
            setPopUp({
                ...innerprops,
                isItemAssingedHere: true,
            });
        }} setClosePopup={() => { setPopUp(undefined); }}/>
                <Box1lAttnStnAdv 
    // {code: "1L", name: "1L ATT.STN" }
    invs={oneLInvs} onClick={(inFleetLocation) => {
            if (inFleetLocation && onClickItem) {
                onClickItem(inFleetLocation);
            }
        }} setPopup={(innerprops) => {
            setPopUp({
                ...innerprops,
                isItemAssingedHere: true,
            });
        }} setClosePopup={() => { setPopUp(undefined); }}/>
                <BoxOHBRowABCAdv 
    // {code: "OHB-ABC", name: "OHB Row 01ABC" }
    invs={oHbAbcInvs} onClick={(inFleetLocation) => {
            if (inFleetLocation && onClickItem) {
                onClickItem(inFleetLocation);
            }
        }} setPopup={(innerprops) => {
            setPopUp({
                ...innerprops,
                isItemAssingedHere: true,
            });
        }} setClosePopup={() => { setPopUp(undefined); }}/>

                <BoxBehindLastRowABCAdv 
    // {code: "BLR-ABC", name: "Behind Last Row 31ABC" }
    invs={bLrAbcInvs} onClick={(inFleetLocation) => {
            if (inFleetLocation && onClickItem) {
                onClickItem(inFleetLocation);
            }
        }} setPopup={(innerprops) => {
            setPopUp({
                ...innerprops,
                isItemAssingedHere: true,
            });
        }} setClosePopup={() => { setPopUp(undefined); }}/>
                <Box2lAttnStnAdv 
    // {code: "2L", name: "2L ATT.STN" }
    invs={twoLInvs} onClick={(inFleetLocation) => {
            if (inFleetLocation && onClickItem) {
                onClickItem(inFleetLocation);
            }
        }} setPopup={(innerprops) => {
            setPopUp({
                ...innerprops,
                isItemAssingedHere: true,
            });
        }} setClosePopup={() => { setPopUp(undefined); }}/>
                <BoxBehind2lAttnStnAdv 
    // { code: "B2L", name: "Behind 2L ATT.STN" }
    invs={b2LInvs} onClick={(inFleetLocation) => {
            if (inFleetLocation && onClickItem) {
                onClickItem(inFleetLocation);
            }
        }} setPopup={(innerprops) => {
            setPopUp({
                ...innerprops,
                isItemAssingedHere: true,
            });
        }} setClosePopup={() => { setPopUp(undefined); }}/>


                {seats.map((seat) => {
            // {code: "PS", name: "Passenger Seat" }
            const { x, y, row, col } = seat;
            const foundSeat = passengerSeats?.find(({ locationId }) => {
                return `${col}${row}`?.toLowerCase() === locationId?.seatNo?.toLowerCase();
            }) ?? undefined;
            let fill = "white";
            fill = getColorFill(foundSeat?.inventory?.status?.code ?? "NA");
            return (<SeatA1 fill={fill} noLocation={foundSeat === undefined} x={x} y={y} setPopup={() => {
                    let xOffset = 0;
                    if (col === 30 || col === 31) {
                        xOffset = -280;
                    }
                    setPopUp({
                        status: foundSeat?.inventory?.status,
                        data: foundSeat,
                        x: x + xOffset,
                        y,
                        row: row,
                        col: col,
                        color: fill,
                        isItemAssingedHere: foundSeat?.inventory !== undefined && foundSeat?.inventory !== null
                    });
                }} setClosePopup={() => {
                    setPopUp(undefined);
                }} onClick={() => {
                    if (onClickItem) {
                        if (foundSeat) {
                            onClickItem(foundSeat);
                        }
                    }
                }}/>);
        })}


                <SeatA1Popup isOpen={popUp !== undefined} x={popUp?.x ?? 0} y={popUp?.y ?? 0} row={popUp?.row} col={popUp?.col ?? 0} data={popUp?.data} status={popUp?.status} fill={popUp?.color ?? "white"} isItemAssingedHere={popUp?.isItemAssingedHere}/>

                <FilterDefs />
                <filter id='boxshadow' color-interpolation-filters="sRGB">
                    <feDropShadow dx="-1.5" dy="1.5" stdDeviation="4" flood-opacity="0.1"/>
                </filter>
            </svg>
        </div>);
}
function getColorFill(statusCode) {
    let fill = 'white';
    switch (statusCode) {
        case "A":
            fill = 'white';
            break;
        case "L":
            fill = 'red';
            break;
        case "T":
            fill = 'orange';
            break;
        case "E":
            fill = 'blue';
            break;
        case "R":
            fill = 'grey';
            break;
        case "D":
            fill = 'dot';
            break;
        case "NA":
            fill = 'black';
            break;
        default:
            fill = 'white';
            break;
    }
    return fill;
}
function categorizeInventoryLocation(inventoryLocationsByApi) {
    /**
    query allinvoCode {
        allInventoryLocations {
            edges {
            node {
                code
                name
            }
            }
        }
    }
    */
    const categories = [
        {
            code: "OHB-DEF",
            name: "OHB Row 01DEF"
        },
        {
            code: "BLR-DEF",
            name: "Behind Last Row 31DEF"
        },
        {
            code: "ZODIAC",
            name: "ZODIAC"
        },
        {
            code: "ZODIAC(AFT)",
            name: "ZODIAC(AFT)"
        },
        {
            code: "FD",
            name: "Flight Deck"
        },
        {
            code: "FWD",
            name: "FWD Galley"
        },
        {
            code: "OHB-ABC",
            name: "OHB Row 01ABC"
        },
        {
            code: "BLR-ABC",
            name: "Behind Last Row 31ABC"
        },
        {
            code: "PS",
            name: "Passenger Seat"
        },
        {
            code: "AFT",
            name: "AFT Galley"
        },
        {
            code: "1L",
            name: "1L ATT.STN"
        },
        {
            code: "2L",
            name: "2L ATT.STN"
        },
        {
            code: "2R",
            name: "2R ATT.Swivel Seat"
        },
        {
            code: "B2L",
            name: "Behind 2L ATT.STN"
        }
    ];
    const categorized = {};
    categories.forEach(({ code }) => {
        const existed = Object.keys(categorized);
        if (!existed.includes(code)) {
            categorized[code] = [];
        }
    });
    inventoryLocationsByApi.map((item) => {
        const { locationId: { inventoryLocation: { code: innerInventoryName } } } = item;
        categorized[innerInventoryName].push(item);
    });
    return categorized;
}
