
import React from 'react';
import { getPopupColorBasedOnStatusCode } from "../../Popup";
import AirplaneBox from "../AirplaneBox";
import { getBoxDataFromInFleetLocations } from "../AirplaneBox/utils";
export default function Box1lAttnStnAdv({
	invs,
	onClick,
	setClosePopup,
	setPopup,
}) {
	const data = getBoxDataFromInFleetLocations(invs);
	const boxX = 110;
	const boxY = 520;
	const popupXOffset = -217;
	const popupYOffset = -500;
	return (
		<g className="display-box">
			<Pointer />
			<AirplaneBox
				title="1L ATT. STN."
				x={boxX}
				y={boxY}
				data={data}
				onClick={onClick}
				minWidth={140}
				setClosePopup={setClosePopup}
				setPopup={(rowX, rowY, data) => {
					setPopup({
						status: data?.inventory?.status,
						data: data,
						x: rowX + boxX + popupXOffset,
						y: rowY + boxY + popupYOffset,
						// row: 'row',
						col: data.locationId.seatNo,
						color: getPopupColorBasedOnStatusCode(
							data?.inventory?.status?.code
						),
					});
				}}
			/>
		</g>
	);
}
function Pointer() {
	return (
		<g>
			<path
				className="path"
				d="M231 427V493C231 496.866 227.866 500 224 500H178"
				stroke="#333333"
				stroke-width="0.5"
				stroke-linecap="round"
				stroke-dasharray="2 2"
			/>
			<circle
				className="pointer-1st"
				cx="231"
				cy="426"
				r="3"
				fill="#74C4E8"
			/>
			<circle
				className="pointer-2nd"
				opacity="0.4"
				cx="231"
				cy="426"
				r="6"
				fill="#74C4E8"
			/>
		</g>
	);
}
