
import React from 'react';
import { Typography } from "@mui/material";
import { isEmpty } from "../../utils/checks";
import A320 from "./A320";
export default function Lopa({ model, fleetId, itemsData, onClickItem }) {
	const foundModel = availableModels.find(
		({ model: innerModel }) => innerModel === model
	);
	if (isEmpty(foundModel)) {
		return (
			<Typography fontStyle="italic">
				No available preview for this model ({model})
			</Typography>
		);
	} else {
		const Model = foundModel?.lopa ?? ((args) => <div></div>);
		return (
			<Model
				fleetId={fleetId}
				itemsData={itemsData}
				onClickItem={onClickItem}
			/>
		);
	}
}
export const availableModels = [
	{
		model: "A320",
		lopa: A320,
	},
];
