import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Grid, styled, Tab, Avatar, Tooltip, tooltipClasses } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import styles from "../Dasr.module.css";
import IconBackArrow from "common/images/icons/elevade/Icon-BackArrow.svg";
import IconErrorAirBalloon from "common/images/icons/elevade/Icon-ErrorAirBalloon.svg";
import NewReport from "../component/NewReportButton";
import IconPlusCircleWhite from "common/images/icons/elevade/Icon-PlusCircleWhite.svg";
import DDML from "./DDML";
import SearchBar from "./SearchBar";
import ActiveFilters from "./ActiveFilters";
import {
  formatDateDasr,
  calculateBalanceDay,
  addingDate,
  calculateCalendarBalance,
  calculateCalendarExpiryDate,
} from "pages/elevade/component/Helpers";
import MUIPagination from "pages/elevade/component/Pagination";
import { makeStyles } from "@mui/styles";
import Overlay from "../../component/Overlay";
import IconAirplaneLimitation from "common/images/icons/elevade/Icon-AirplaneLimitation.svg";
import IconDustbin from "common/images/icons/elevade/Icon-Dustbin.svg";
import IconDustbinWhite from "common/images/icons/elevade/Icon-DustbinWhite.svg";
import IconDustbinDisabled from "common/images/icons/elevade/Icon-DustbinDisabled.svg";
import IconBriefcase from "common/images/icons/elevade/Icon-Briefcase.svg";
import IconBriefcaseWhite from "common/images/icons/elevade/Icon-BriefcaseWhite.svg";
import IconBriefcaseDisabled from "common/images/icons/elevade/Icon-BriefcaseDisabled.svg";
import IconReInspection from "common/images/icons/elevade/Icon-ReInspection.svg";
import { CheckBoxField, CheckBoxFieldMock } from "pages/elevade/component/InputForm";
import ElevadeDrawer from "../component/Drawer";
import Button from "pages/elevade/component/Button";
import DialogMoveAssignment from "./DialogMoveAssigment";
import DialogDelete from "./DialogDelete";
import { makeAPIRequestRun, makeAPIRequestMDB } from "common/helpers";
import AirplaneLoader from "pages/elevade/component/AirplaneLoader";
import Sorting from "../component/Sorting";

const AircraftReport = ({ aoc, isLoading, setIsLoading }) => {
  const { userPermission } = useSelector((state) => state.userEntity);
  const summaryPermission = userPermission.filter((perm) => perm.code === "DASR-004");
  const addDDMlPermission = userPermission.filter((perm) => perm.code === "DASR-003");
  const [subLoading, setSubLoading] = useState(true);
  const [type, setType] = useState("1");
  const [value, setValue] = useState("1");
  const [open, setOpen] = useState(false);
  const [openTitle, setOpenTitle] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState([]);
  const [data_, setData_] = useState([]);
  const [dataForm, setDataForm] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [index, setIndex] = useState();
  const [edit, setEdit] = useState(true);
  const [openNotification, setOpenNotification] = useState(false);
  const [newForm, setNewForm] = useState(false);
  const [triggerApi, setTriggerApi] = useState(true);
  const [activeIndex, setActiveIndex] = useState("");
  const [selectToMove, setSelectToMove] = useState([]);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [openDialogMoveToAssignment, setOpenDialogMoveToAssignment] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [isSearch, setIsSearch] = useState([]);
  const InitialFilters = {
    search: [],
    location: [],
    aircraftReg: [],
    assignedTo: [],
    raisedDate: [],
    expiryDate: [],
    summaryStatus: [],
  };
  const [isFilters, setIsFilters] = useState(InitialFilters);
  const [filteredData, setFilteredData] = useState([]);
  const [dataFHFC, setDataFHFC] = useState([]);
  const [sortingColumn, setSortingColumn] = useState("");
  const [sortingDirection, setSortingDirection] = useState("asc");
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const paramsId = urlParams.get("id");

  const today = new Date();
  const formattedDate = today.toISOString();

  let sliceStart = page > 0 ? perPage * (page - 1) : 0;
  let sliceEnd = parseInt(perPage) + sliceStart;

  useEffect(() => {
    setIsLoading(false);
    // eslint-disable-next-line
  }, []);

  // TODO: To check why drawer open twice
  useEffect(() => {
    if (paramsId !== null) {
      let data = filteredData.filter((e) => e._id === paramsId);
      if (data.length > 0) {
        let formType = data[0]?.formType;
        if (formType === "MEL CAT") setValue("1");
        else if (formType === "AMD") setValue("2");
        else if (formType === "STR/FC/FH") setValue("3");
        else if (formType === "Hydraulic Sampling") setValue("4");
        else if (formType === "Fuel Sampling") setValue("5");
        setDataForm(data[0]);
        setOpen(true);
        setEdit(false);
        setNewForm(false);
      }
    }
  }, [paramsId, filteredData]);

  useEffect(() => {
    let formType = "";
    if (type === "1") formType = "MEL CAT";
    else if (type === "2") formType = "AMD";
    else if (type === "3") formType = "STR/FC/FH";
    else if (type === "4") formType = "Hydraulic Sampling";
    else if (type === "5") formType = "Fuel Sampling";
    let totalRows = filteredData?.filter((e) => e.formType === formType && e.formStatus === "open")?.length;
    let totalPage = Math.floor(
      filteredData?.filter((e) => e.formType === formType && e.formStatus === "open")?.length / perPage
    );
    if (totalRows % perPage !== 0) totalPage = totalPage + 1;
    setData_(data?.filter((e) => e.formType === formType && e.formStatus === "open"));
    setTotalRows(totalRows);
    setTotalPage(totalPage);
  }, [data, type, perPage, filteredData]);

  useEffect(() => {
    setPage(1);
  }, [type]);

  useEffect(() => {
    fetch();
    setCheckAll(false);
    // eslint-disable-next-line
  }, [openNotification, triggerApi, aoc]);

  useEffect(() => {
    if (!open) setActiveIndex();
    setOpenModal(false);
  }, [open]);

  useEffect(() => {
    setSelectToMove([]);
    setCheckAll(false);
    setOpenModal(false);
  }, [value]);

  // Applying filters
  useEffect(() => {
    let data__ = [];
    let theData = data;
    setFilteredData(theData);

    // Search input fields
    if (isFilters.search.length !== 0) {
      data__ = theData.filter(
        (item) =>
          isFilters.search.some((searchTerm) => item.location?.toLowerCase()?.includes(searchTerm?.toLowerCase())) ||
          isFilters.search.some((searchTerm) => item.defectDesc?.toLowerCase()?.includes(searchTerm?.toLowerCase())) ||
          isFilters.search.some((searchTerm) => item.aircraftReg?.toLowerCase()?.includes(searchTerm?.toLowerCase())) ||
          isFilters.search.some((searchTerm) => item.ddmlNo?.toLowerCase()?.includes(searchTerm?.toLowerCase())) ||
          isFilters.search.some((searchTerm) =>
            item.limitationsDesc?.toLowerCase()?.includes(searchTerm?.toLowerCase())
          ) ||
          isFilters.search.some((searchTerm) => item.assignedTo?.toLowerCase()?.includes(searchTerm?.toLowerCase())) ||
          isFilters.search.some((searchTerm) => item.actionBy145?.toLowerCase()?.includes(searchTerm?.toLowerCase())) ||
          isFilters.search.some((searchTerm) => item.melRef?.toLowerCase()?.includes(searchTerm?.toLowerCase())) ||
          isFilters.search.some((searchTerm) =>
            item.statusLog?.updateLogDesc?.toLowerCase()?.includes(searchTerm?.toLowerCase())
          )
      );
      setFilteredData(data__);
    }

    // Station (location)
    if (isFilters.location.length > 0) setFilteredData(theData.filter((e) => isFilters.location.includes(e.location)));
    // Aircraft Registration
    if (isFilters.aircraftReg.length > 0)
      setFilteredData(theData.filter((e) => isFilters.aircraftReg.includes(e.aircraftReg)));
    // Assigned To (Department)
    if (isFilters.assignedTo.length > 0)
      setFilteredData(theData.filter((e) => isFilters.assignedTo.includes(e.assignedTo)));
    // Raised Date
    if (isFilters.raisedDate.length > 0)
      setFilteredData(theData.filter((e) => e.raisedDate.startsWith(isFilters.raisedDate)));
    // Expiry Date
    if (isFilters.expiryDate.length > 0)
      setFilteredData(theData.filter((e) => e.expiryDate.startsWith(isFilters.expiryDate)));
  }, [isFilters, data]);

  useEffect(() => {
    if (selectToMove.length === 0) setCheckAll(false);
  }, [selectToMove]);

  // Use effect function for sorting
  useEffect(() => {
    const sortColumns = [{ column: sortingColumn, order: sortingDirection }];
    setData(dynamicSort(filteredData, sortColumns));
  }, [sortingColumn, sortingDirection]);

  function dynamicSort(array, sortColumns) {
    return array.sort((a, b) => {
      for (let i = 0; i < sortColumns.length; i++) {
        const { column, order } = sortColumns[i]; // Extract column name and order for each sorting preference

        // Handle null or undefined comparison values gracefully
        if (a[column] == null) return order === "asc" ? -1 : 1;
        if (b[column] == null) return order === "asc" ? 1 : -1;

        // Compare the values based on the specified order
        if (a[column] < b[column]) return order === "asc" ? -1 : 1;
        if (a[column] > b[column]) return order === "asc" ? 1 : -1;
      }
      return 0; // If all specified sort columns are equal, keep original order
    });
  }

  async function fetch() {
    // setSubLoading(true);
    const fetch = makeAPIRequestMDB(`get`, `api/ddml?aoc=${aoc}&form_status=open`);
    fetch
      .then((response) => {
        setData(response.data);
        setFilteredData(response.data);
        setSubLoading(false);
        if (paramsId === null) setOpen(false);
        setOpenNotification(false);
        setTriggerApi(false);
      })
      .catch((error) => {
        console.log("error", error);
        setSubLoading(false);
      });
  }

  async function fetchFHFC() {
    // setSubLoading(true);
    const fetch = makeAPIRequestRun(`get`, `acarsdata/fleetgetfcfh/null`);
    fetch
      .then((response) => {
        setDataFHFC(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useEffect(() => {
    fetchFHFC();
  }, []);

  // Custom styles
  const useStyles = makeStyles(() => ({
    ul: {
      "& .Mui-selected": {
        color: "#fff",
        backgroundColor: "#088FD1",
        border: "1px solid #088FD1",
        "&:hover": {
          backgroundColor: "#088FD1",
        },
      },
    },
    gridItem: {
      // overflow: "hidden",
      // textOverflow: "ellipsis",
      // display: "-webkit-box",
      // WebkitBoxOrient: "vertical",
      // WebkitLineClamp: 2, // Number of lines to show
      // fontSize: "14px",
      display: "-webkit-box",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "normal",
    },
  }));

  const classes = useStyles();

  const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    // padding: 0,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    fontFamily: ["Gotham-Book"].join(","),
    "&:hover": {
      color: "#333333",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#333333",
      fontFamily: "Gotham-Medium",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  }));

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setType(newValue);
  };

  const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#FFFEE8",
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.08)",
        fontSize: 11,
      },
    })
  );

  const ErrorAirBallon = () => {
    return (
      <>
        <div className={styles.errorAirBallon}>
          <center>
            <img src={IconErrorAirBalloon} alt="error-air-ballon" />
            <div className={styles.errorTitle}>No content yet</div>
            <div className={styles.errorSubTitle}>
              Create or publish something
              <br />
              it shows up here
            </div>
          </center>
        </div>
      </>
    );
  };

  const Tips = ({ val, unread }) => {
    const total = val.split("|")[0];
    const color = val.split("|")[1];
    const bgColor = val.split("|")[2];
    return (
      <>
        <span
          style={{
            borderRadius: "30px",
            color: color,
            border: `1px solid ${bgColor}`,
            backgroundColor: bgColor,
            fontFamily: "Gotham-Book",
            fontSize: "12px",
            marginLeft: "8px",
            padding: "4px 8px 2px 8px",
          }}
        >
          {total}
        </span>
      </>
    );
  };

  const AircraftReportList = ({ type }) => {
    return (
      <>
        <Grid container columns={4} sx={{ marginTop: "0px" }}>
          {/* <Grid item xs={4} className={styles.gridTitle}>
            {type} List
          </Grid> */}
          {data_ !== undefined && data_ !== "" && data_?.length > 0 && (
            <>
              <Grid item xs={4} sx={{ paddingTop: "32px", paddingLeft: "16px" }}>
                <SearchBar
                  open={openModal}
                  setOpen={setOpenModal}
                  data={data_}
                  isSearch={isSearch}
                  setIsSearch={setIsSearch}
                  isFilters={isFilters}
                  setIsFilters={setIsFilters}
                  openTitle={openTitle}
                  setOpenTitle={setOpenTitle}
                />
                <ActiveFilters isFilters={isFilters} setIsFilters={setIsFilters} initialFilters={InitialFilters} />
              </Grid>
              <Grid item xs={4} sx={{ display: "flex", paddingTop: "32px" }} onClick={() => setOpenModal(false)}>
                <Button
                  title="Delete"
                  icon={IconDustbin}
                  iconWhite={IconDustbinWhite}
                  iconDisabled={IconDustbinDisabled}
                  variant={selectToMove.length > 0 ? "outlined" : "disabled"}
                  onClick={() => setOpenDialogDelete(true)}
                />
                <Button
                  title="Move to Assignment Draft List"
                  icon={IconBriefcase}
                  iconWhite={IconBriefcaseWhite}
                  iconDisabled={IconBriefcaseDisabled}
                  variant={selectToMove.length > 0 ? "outlined" : "disabled"}
                  onClick={() => setOpenDialogMoveToAssignment(true)}
                />
              </Grid>
              <Grid item xs={4} sx={{ paddingTop: "16px" }} onClick={() => setOpenModal(false)}>
                {subLoading ? <Overlay /> : <TableList type={type} />}
              </Grid>
            </>
          )}
          {(data?.length === 0 || data_?.length === 0) && !subLoading && (
            <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }} onClick={() => setOpenModal(false)}>
              <ErrorAirBallon />
            </Grid>
          )}
        </Grid>
      </>
    );
  };

  function handleClick(e, index) {
    setOpen(true);
    setIndex(index);
    setDataForm(e);
    setEdit(false);
    setActiveIndex(index);
    setNewForm(false);
    // if (e.moveToAssignment === false) setSelectToMove([e._id]);
  }

  function handleSelect() {
    if (checkAll) return <CheckBoxFieldMock isCheckedAll={true} setIsCheckedAll={setCheckAll} />;
    else if (selectToMove.length > 0 && selectToMove.length < totalRows) {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          onClick={() => setCheckAll(!checkAll)}
          style={{ cursor: "pointer" }}
        >
          <rect width="20" height="20" rx="4" fill="#088FD1" />
          <path d="M6 10L14 10" stroke="white" stroke-width="3" stroke-linecap="round" />
        </svg>
      );
    } else {
      return summaryPermission.some((perm) => perm.canUpdate === true) ? (
        <CheckBoxFieldMock
          isCheckedAll={selectToMove.length > 0 && totalRows > 0 && selectToMove.length === totalRows}
          setIsCheckedAll={setCheckAll}
        />
      ) : (
        <CheckBoxField id={""} selected={selectToMove} setSelected={setSelectToMove} disabled={true} />
      );
    }
  }

  useEffect(() => {
    if (checkAll) {
      handleCheckAll();
    } else handleUnCheckAll();
    // eslint-disable-next-line
  }, [checkAll]);

  useEffect(() => {
    if (selectToMove.length > 0 && totalRows > 0 && selectToMove.length === totalRows) setCheckAll(true);
  }, [selectToMove, totalRows]);

  function handleCheckAll() {
    let array = [];
    let formType =
      value === "1"
        ? "MEL CAT"
        : value === "2"
        ? "AMD"
        : value === "3"
        ? "STR/FC/FH"
        : value === "4"
        ? "Hydraulic Sampling"
        : value === "5"
        ? "Fuel Sampling"
        : undefined;

    data
      .filter(
        (e) =>
          e.formType === formType &&
          e.formStatus === "open" &&
          (e.moveToAssignment === null || e.moveToAssignment === false)
      )
      .map((e) => {
        return array.push(e._id);
      });
    setSelectToMove(array);
  }

  function handleUnCheckAll() {
    setSelectToMove([]);
  }

  function setToday() {
    // Create a new Date object
    const currentDate = new Date();

    // Get the individual components of the date
    const year = currentDate.getFullYear();
    // JavaScript months are 0-indexed, so we add 1 to get the correct month
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");

    // Create a string in the desired format
    const formattedDate = `${year}-${month}-${day}T00:00:00`;
    return formattedDate;
  }

  function calcBalanceFH(interval, raisedAt, val) {
    let expiry = parseInt(interval) + parseInt(raisedAt);
    let current = val;
    let balance = parseInt(expiry) - parseInt(current);
    return parseInt(balance).toLocaleString() || "-";
  }

  function calcBalanceFC(interval, raisedAt, val) {
    let expiry = parseInt(interval) + parseInt(raisedAt);
    let current = val;
    let balance = parseInt(expiry) - parseInt(current);
    return parseInt(balance).toLocaleString() || "-";
  }

  function calcBalanceCD(calendarDaysStartDate, calendarDaysInterval) {
    let startDate = calendarDaysStartDate;
    let interval = calendarDaysInterval || 0;
    let expiry = addingDate(startDate, interval);
    let today = setToday();
    let balance = calculateBalanceDay(today?.split("T")[0], expiry?.split("T")[0]);
    return balance;
  }

  const IconUnreadIndicator = ({ data }) => {
    let unreadLogs = data.unreadLogs;
    let count = unreadLogs?.materialTool.length + unreadLogs?.planningTech.length + unreadLogs?.rectification.length;

    return (
      count > 0 && (
        <div style={{ position: "absolute", right: "24px" }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
            <circle cx="5" cy="5" r="5" fill="#0ADDDD" />
          </svg>
        </div>
      )
    );
  };

  const TableList = ({ type }) => {
    return (
      <>
        <div
          style={{
            width: "100%",
            borderRadius: "12px",
            overflow: "hidden",
            border: "1px solid #E8E8E8",
          }}
        >
          <Grid container columns={13}>
            <Grid item xs={0.5} className={styles.tableHeader}>
              {handleSelect()}
            </Grid>
            <Grid item xs={0.3} className={styles.tableHeader}></Grid>
            <Grid item xs={0.5} className={styles.tableHeader}>
              <Sorting
                col="location"
                title="Station"
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={1} className={styles.tableHeader}>
              <Sorting
                col="aircraftReg"
                title="A/C Reg."
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={2.7} className={`${styles.tableHeader} ${styles.gridJustifyStart}`}>
              <Sorting
                col="defectDesc"
                title="Defect Description"
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
                align
              />
            </Grid>
            <Grid item xs={1} className={styles.tableHeader}>
              <Sorting
                col="ddmlNo"
                title="DDML No."
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={1} className={styles.tableHeader}>
              <Sorting
                col="melRef"
                title="Ref."
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={1} className={styles.tableHeader}>
              <Sorting
                col="raisedDate"
                title="Raised Date"
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={1} className={styles.tableHeader}>
              <Sorting
                col="expiry"
                title={type === "STR/FC/FH" ? "Balance" : "Expiry"}
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={1} className={styles.tableHeader}>
              <Sorting
                col="assignedTo"
                title="Department"
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={3} className={`${styles.tableHeader} ${styles.gridJustifyStart}`}>
              {isFilters.summaryStatus[0] || "Rectification Status"}
            </Grid>
            {filteredData &&
              filteredData
                .filter((e) => {
                  return e.formType === type && e.formStatus === "open";
                })
                .slice(sliceStart, sliceEnd)
                .map((e, index) => {
                  let calendarDaysInterval = e.calendarDaysInterval || 0;
                  let calendarDaysStartDate = e.calendarDaysStartDate || e.raisedDate;
                  let expiry = addingDate(calendarDaysStartDate, calendarDaysInterval);
                  let date1 = e.formType === "AMD" ? e.calendarDaysStartDate : formattedDate?.split("T")[0];
                  let date2 = expiry;
                  let balance = calculateBalanceDay(date1, date2);
                  let showInTable = e.showInTable;
                  let currentFlightHour = dataFHFC.filter((item) => item.AC_REG === e.aircraftReg)[0]?.flight_hour;
                  let currentFlightCycle = dataFHFC.filter((item) => item.AC_REG === e.aircraftReg)[0]?.flight_cycle;
                  if (e.calendarDays) balance = calcBalanceCD(calendarDaysStartDate, calendarDaysInterval);
                  if (showInTable === "fh" && e.flightHours && e.formType === "STR/FC/FH")
                    balance = calcBalanceFH(e.flightHoursInterval, e.flightHoursRaisedAt, currentFlightHour);
                  else if (showInTable === "fc" && e.flightCycle && e.formType === "STR/FC/FH")
                    balance = calcBalanceFC(e.flightCycleInterval, e.flightCycleRaisedAt, currentFlightCycle);
                  else if (showInTable === "" && e.formType === "STR/FC/FH")
                    balance = calcBalanceCD(calendarDaysStartDate, calendarDaysInterval);

                  let fcfhIndicator = " Days";
                  if (showInTable === "fh" && e.flightHours) fcfhIndicator = " (FH)";
                  else if (showInTable === "fc" && e.flightCycle) fcfhIndicator = " (FC)";

                  if (!e.flightCycle && !e.flightHours && !e.calendarDays && e.formType === "STR/FC/FH") balance = "-";
                  if (e.formType === "AMD")
                    balance = calculateCalendarBalance(e.calendarDaysStartDate, e.calendarDaysInterval);
                  if (e.formType === "Fuel Sampling" || e.formType === "Hydraulic Sampling")
                    balance = calculateCalendarBalance(e.calendarDaysStartDate, e.calendarDaysInterval);
                  let expiryDate_ = formatDateDasr(e.expiryDate);
                  if (e.formType !== "STR/FC/FH") {
                    if (e.formType === "MEL CAT" && e.flightDays)
                      expiryDate_ = calculateCalendarExpiryDate(e.flightDaysStartDate, e.flightDaysInterval);
                    else if (e.formType !== "MEL CAT")
                      expiryDate_ = calculateCalendarExpiryDate(e.calendarDaysStartDate, e.calendarDaysInterval);
                  }
                  // Calculate the date difference
                  var currentDate = new Date();
                  var timeDifferenceMs = new Date(e.expiryDate) - currentDate;
                  var remainingDays = Math.ceil(timeDifferenceMs / (1000 * 60 * 60 * 24));

                  // let balanceSTR = balance;
                  let balanceSTR = !isNaN(parseInt(balance)) && balance !== "-" ? balance + fcfhIndicator : "-";
                  if (e.flightHours && showInTable === "fh") balanceSTR = balance + " (FH)";
                  else if (e.flightCycle && showInTable === "fc") balanceSTR = balance + " (FC)";
                  else if (e.calendarDays && showInTable === "cd") balanceSTR = balance + " Days";
                  else balanceSTR = "-";

                  let color = "";

                  if (type === "STR/FC/FH" && (e.flightHours || e.flightHours) && parseInt(balance) <= 20)
                    color = "#E95048";
                  else if (type === "STR/FC/FH" && e.calendarDays && balance <= 3) color = "#E95048";
                  else if (type === "MEL CAT" && remainingDays <= 3) color = "#E95048";
                  else if (type !== "MEL CAT" && type !== "STR/FC/FH" && remainingDays <= 3) color = "#E95048";

                  return (
                    <>
                      <Grid item xs={13}>
                        <Grid
                          container
                          columns={13}
                          className={`${styles.lineItems} ${activeIndex === index ? styles.selectedIndex : ""}`}
                          sx={{
                            zIndex: 1,
                          }}
                        >
                          <Grid item xs={0.5} className={`${styles.tableBody}`} sx={{ zIndex: 3 }}>
                            <CheckBoxField
                              id={e._id}
                              selected={selectToMove}
                              setSelected={setSelectToMove}
                              disabled={
                                summaryPermission.some((perm) => perm.canUpdate === true) ? e.moveToAssignment : true
                              }
                              marginTop="-20px"
                            />
                          </Grid>
                          <Grid item xs={0.3} className={`${styles.tableBody}`} onClick={() => handleClick(e, index)}>
                            <LightTooltip
                              title={e.limitations ? "Limitation triggered" : ""}
                              followCursor
                              placement="bottom-start"
                            >
                              {e.limitations && (
                                <img
                                  src={IconAirplaneLimitation}
                                  alt={"icon-limitation"}
                                  height={20}
                                  style={{ marginRight: "4px" }}
                                />
                              )}
                            </LightTooltip>
                            <LightTooltip
                              title={e.reInspection ? "Reinspection" : ""}
                              followCursor
                              placement="bottom-start"
                            >
                              {e.reInspection && (
                                <img
                                  src={IconReInspection}
                                  alt={"icon-reinspection"}
                                  height={20}
                                  style={{ marginLeft: "4px" }}
                                />
                              )}
                            </LightTooltip>
                          </Grid>
                          <Grid
                            item
                            xs={0.5}
                            className={`${styles.tableBody} ${styles.toUpperCase}`}
                            onClick={() => handleClick(e, index)}
                          >
                            {e.location || "-"}
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            className={`${styles.tableBody} ${styles.toUpperCase}`}
                            onClick={() => handleClick(e, index)}
                          >
                            {e.aircraftReg || "-"}
                          </Grid>
                          <Grid
                            item
                            xs={2.7}
                            className={`${styles.tableBody} ${styles.toUpperCase} ${styles.flexStart}`}
                            onClick={() => handleClick(e, index)}
                          >
                            <LightTooltip title={e.defectDesc} followCursor placement="bottom-start">
                              <div className={classes.gridItem}>{e.defectDesc || "-"}</div>
                            </LightTooltip>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            className={`${styles.tableBody} ${styles.toUpperCase}`}
                            onClick={() => handleClick(e, index)}
                          >
                            {e.ddmlNo || "-"}
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            className={`${styles.tableBody} ${styles.toUpperCase}`}
                            onClick={() => handleClick(e, index)}
                          >
                            {e.melRef && (
                              <center>
                                {e.melRef}{" "}
                                {e.formType === "MEL CAT" && (
                                  <>
                                    <br /> {"(" + e.melCatType + ")"}
                                  </>
                                )}
                              </center>
                            )}
                            {!e.melRef && "-"}
                          </Grid>
                          <Grid item xs={1} className={`${styles.tableBody}`} onClick={() => handleClick(e, index)}>
                            <center>{formatDateDasr(e.raisedDate)}</center>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            className={`${styles.tableBody}`}
                            sx={{
                              color: color,
                            }}
                            onClick={() => handleClick(e, index)}
                          >
                            <center>
                              {type === "STR/FC/FH"
                                ? balanceSTR
                                : type === "MEL CAT"
                                ? formatDateDasr(e.expiryDate)
                                : calculateCalendarExpiryDate(e.calendarDaysStartDate, e.calendarDaysInterval)}
                            </center>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            className={`${styles.tableBody} ${styles.toUpperCase}`}
                            onClick={() => handleClick(e, index)}
                          >
                            {e.assignedTo !== "" ? <Avatar {...stringAvatar(e.assignedTo)} /> : "-"}
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            className={`${styles.tableBody} ${styles.toUpperCase}  ${styles.flexStart}`}
                            sx={{
                              position: "relative",
                              paddingRight: "34px",
                            }}
                            onClick={() => handleClick(e, index)}
                          >
                            <LightTooltip
                              title={getRectificationStatus(e) || e.actionBy145}
                              followCursor
                              placement="bottom-start"
                            >
                              <div className={classes.gridItem}>
                                {getRectificationStatus(e) || e.actionBy145 || "-"}
                              </div>
                            </LightTooltip>
                            <IconUnreadIndicator data={e} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  );
                })}
            <Grid item xs={13} className={`${styles.tableBodyWhite}`}>
              <MUIPagination
                page={parseInt(page)}
                setPage={setPage}
                setPerPage={setPerPage}
                totalPage={parseInt(totalPages)}
                classes={{ ul: classes.ul }}
                totalRows={parseInt(totalRows)}
                sliceStart={sliceStart}
                initialPerPage={perPage}
              />
            </Grid>
          </Grid>
        </div>
      </>
    );
  };

  function getRectificationStatus(item) {
    // Check for active Summary Status selection
    if (isFilters.summaryStatus.length > 0) {
      let status =
        isFilters.summaryStatus[0] === "Rectification Status"
          ? item.updateStatusLog.rectificationUpdate
          : isFilters.summaryStatus[0] === "Material Summary"
          ? item.updateStatusLog.materialUpdate
          : item.mocHighlight;
      return status;
    } else {
      if (item?.statusLog?.pushToSummary === "") return undefined;
      else {
        return item?.statusLog?.updateLogDesc;
      }
    }
  }

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return (
      name && {
        sx: {
          bgcolor: stringToColor(name),
          fontSize: "12px",
        },
        children: `${name.substring(0, 3)}`,
      }
    );
  }

  function calcTipsCount(type) {
    let data_ = filteredData?.filter((e) => e.formType === type && e.formStatus === "open");
    let sum = 0;
    let threshold = type === "MEL CAT" ? 3 : 10;
    // eslint-disable-next-line
    data_?.map((e) => {
      // Calculate the date difference
      var currentDate = new Date();
      var expiryDate = new Date(e.expiryDate);
      var timeDifferenceMs = expiryDate - currentDate;
      var remainingDays = Math.ceil(timeDifferenceMs / (1000 * 60 * 60 * 24));
      if (remainingDays <= threshold) sum++;
    });
    if (sum > 0) return sum + "|#ffffff|#E95048";
    return data_?.length + "|#838383|#F7F7F7";
  }

  return !isLoading && !subLoading ? (
    <>
      <div id="main" className={`${styles.container}`}>
        <>
          <Grid container columns={8.2} className={styles.gridContainer}>
            <Grid item xs={4.2} className={styles.gridTitle}>
              <Link to={"/fleet/dasr"}>
                <img src={IconBackArrow} alt="icon-back" style={{ marginRight: "14px", marginTop: "-4px" }} />
                {aoc} DDML Reports
              </Link>
            </Grid>
            <Grid item xs={4} className={styles.gridJustifyEnd} sx={{ fontSize: "14px", paddingBottom: "16px" }}>
              {/* <Export /> */}
              {/* <Summary /> */}
              <NewReport
                open={open}
                setOpen={setOpen}
                title={"Add DDML"}
                icon={IconPlusCircleWhite}
                onClick={() => {
                  if (addDDMlPermission.some((perm) => perm.canCreate === true)) {
                    setDataForm([]);
                    setOpen(!open);
                    setEdit(true);
                    setNewForm(true);
                  }
                }}
                disabled={addDDMlPermission.some((perm) => perm.canCreate === true) ? false : true}
              />
            </Grid>
            <Grid item xs={8.2} sx={{ paddingTop: "10px" }}>
              <TabContext value={value}>
                <TabList onChange={handleChange} aria-label="lab API tabs example" onClick={() => setOpenModal(false)}>
                  <AntTab
                    label="MEL CAT"
                    value="1"
                    icon={<Tips val={calcTipsCount("MEL CAT")} unread={calcTipsCount("MEL CAT") > 0 ? true : false} />}
                    iconPosition="end"
                  />
                  <AntTab
                    label="AMD"
                    value="2"
                    icon={<Tips val={calcTipsCount("AMD")} unread={calcTipsCount("AMD") > 0 ? true : false} />}
                    iconPosition="end"
                  />
                  <AntTab
                    label="STR/FC/FH"
                    value="3"
                    icon={
                      <Tips val={calcTipsCount("STR/FC/FH")} unread={calcTipsCount("STR/FC/FH") > 0 ? true : false} />
                    }
                    iconPosition="end"
                  />
                  <AntTab
                    label="Hydraulic Sampling"
                    value="4"
                    icon={
                      <Tips
                        val={calcTipsCount("Hydraulic Sampling")}
                        unread={calcTipsCount("Hydraulic Sampling") > 0 ? true : false}
                      />
                    }
                    iconPosition="end"
                  />
                  <AntTab
                    label="Fuel Sampling"
                    value="5"
                    icon={
                      <Tips
                        val={calcTipsCount("Fuel Sampling")}
                        unread={calcTipsCount("Fuel Sampling") > 0 ? true : false}
                      />
                    }
                    iconPosition="end"
                  />
                </TabList>
                <div
                  style={{
                    width: "100%",
                    height: "2px",
                    backgroundColor: "#d1d1d1",
                    marginTop: "-2px",
                  }}
                ></div>
                <div
                  style={{
                    marginLeft: "-20px",
                    marginRight: "-20px",
                    marginTop: "-24px",
                  }}
                >
                  <TabPanel value="1">
                    {data?.length > 0 ? <AircraftReportList type={"MEL CAT"} /> : <ErrorAirBallon />}
                  </TabPanel>
                  <TabPanel value="2">
                    {data?.length > 0 ? <AircraftReportList type={"AMD"} /> : <ErrorAirBallon />}
                  </TabPanel>
                  <TabPanel value="3">
                    {data?.length > 0 ? <AircraftReportList type={"STR/FC/FH"} /> : <ErrorAirBallon />}
                  </TabPanel>
                  <TabPanel value="4">
                    {data?.length > 0 ? <AircraftReportList type={"Hydraulic Sampling"} /> : <ErrorAirBallon />}
                  </TabPanel>
                  <TabPanel value="5">
                    {data?.length > 0 ? <AircraftReportList type={"Fuel Sampling"} /> : <ErrorAirBallon />}
                  </TabPanel>
                </div>
              </TabContext>
            </Grid>
          </Grid>
        </>
      </div>
      <ElevadeDrawer
        open={open}
        setOpen={setOpen}
        data={data}
        Component={DDML}
        aoc={aoc}
        type={type}
        index={index}
        dataForm={dataForm}
        setDataForm={setDataForm}
        edit={edit}
        setEdit={setEdit}
        openNotification={openNotification}
        setOpenNotification={setOpenNotification}
        newForm={newForm}
        setNewForm={setNewForm}
        triggerApi={triggerApi}
        setTriggerApi={setTriggerApi}
        setSelectToMove={setSelectToMove}
        setOpenDialogMoveToAssignment={setOpenDialogMoveToAssignment}
        date={today}
      />
      <DialogMoveAssignment
        open={openDialogMoveToAssignment}
        setOpen={setOpenDialogMoveToAssignment}
        data={selectToMove}
        setTriggerApi={setTriggerApi}
        setData={setSelectToMove}
      />
      <DialogDelete
        open={openDialogDelete}
        setOpen={setOpenDialogDelete}
        data={selectToMove}
        setTriggerApi={setTriggerApi}
        setData={setSelectToMove}
      />
    </>
  ) : (
    <AirplaneLoader />
  );
};

export default AircraftReport;
