import React from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styles from "./Analytics.module.css";
import AirplaneLoader from "../component/AirplaneLoader";
// import Iframe from "react-iframe";

const ScheduleTask = () => {
  const [isAdvanceFilter, setIsAdvanceFilter] = useState(false);
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const paramsString = location.search;
  const searchParams = new URLSearchParams(paramsString);
  let openLegend = "false";
  if (searchParams.get("open")) openLegend = searchParams.get("open");

  const IFrame_URL = process.env.REACT_APP_SCHEDULED_CHECK_URL;

  useEffect(() => {
    if (searchParams.get("open")) setIsAdvanceFilter(false);
    // eslint-disable-next-line
  }, [openLegend]);

  useEffect(() => {
    const iframe = document.getElementById("iframe");
    // TODO: to check back on this later
    // eslint-disable-next-line no-unused-expressions
    iframe?.addEventListener("load", function () {
      setLoading(false);
    });
  }, []);

  let paddingRight = "6px";
  if (isAdvanceFilter && openLegend === "false") paddingRight = "304px";

  return (
    <>
      <div
        style={{ overflow: "hidden", marginLeft: "17px", paddingRight: `${paddingRight}` }}
        id="main"
        className={`${styles.container}`}
      >
        {loading && <AirplaneLoader />}
        <iframe
          id="iframe"
          title="analytics"
          width="100%"
          height="1020px"
          src={IFrame_URL}
          style={{ border: "0px" }}
          allowFullScreen
        ></iframe>
      </div>
    </>
  );
};

export default ScheduleTask;
