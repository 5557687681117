import {
	TableBody,
	TableRow,
	TableCell,
	Skeleton,
	Typography,
	Box,
} from "@mui/material";
import React from 'react'
import  { Fragment } from "react";
import { isValidReactChild } from "../../../utils/checks";
// import './index.css';
import { tableHeaderBackgroundColor } from "../../../../constants/styles";
export default function CustomBody({
	itemsPerPage,
	data,
	isLoading,
	rawDatas,
	lowerIndex,
	upperIndex,
	actions,
	convertedToMaps,
	devMode = false,
	onRowClick,
}) {
	const maps = convertedToMaps;
	return (
		<TableBody sx={{}}>
			{data && data.length < 1 && !isLoading && (
				<TableRow>
					<TableCell colSpan={9} component="th" scope="row">
						No record(s) found.
					</TableCell>
				</TableRow>
			)}
			{isLoading && (
				<Fragment>
					<TableRow
						sx={{
							backgroundColor: tableHeaderBackgroundColor,
						}}
					>
						<TableCell
							sx={{
								paddingTop: "16px",
								paddingBottom: "16px",
							}}
							colSpan={100}
							component="th"
							scope="row"
						>
							<Skeleton
								sx={{ height: "20px" }}
								animation="wave"
								variant="text"
							/>
						</TableCell>
					</TableRow>
					{Array.apply(null, { length: itemsPerPage })
						?.map(Number.call, Number)
						?.map((item) => {
							return (
								<TableRow
									key={`pagedgqltablemain-tablerow-${item}`}
								>
									<TableCell
										sx={{
											paddingTop: "16px",
											paddingBottom: "16px",
										}}
										colSpan={100}
										component="th"
										scope="row"
									>
										<Skeleton
											sx={{ height: "20px" }}
											animation="wave"
											variant="text"
										/>
									</TableCell>
								</TableRow>
							);
						})}
				</Fragment>
			)}
			{data &&
				!isLoading &&
				maps?.map((item, index) => {
					const entries = Array.from(item.entries());
					const allCells = [];
					entries.forEach((entry, index) => {
						const [itemKey, value] = entry;
						if (isValidReactChild(value)) {
							allCells.push(
								<TableCell
									sx={{ border: "none" }}
									key={`pagedgqltablemain-tabelcell-value-${itemKey}-${index}`}
									component="th"
									scope="row"
								>
									<Typography fontSize="14px">
										{value}
									</Typography>
								</TableCell>
							);
						} else if (
							value === undefined ||
							value === null ||
							Number.isNaN(value)
						) {
							allCells.push(
								<TableCell
									sx={{ border: "none" }}
									key={`pagedgqltablemain-tabelcell-unabletorender-${itemKey}-${index}`}
									component="th"
									scope="row"
								>
									<Typography fontSize="14px">N/A</Typography>
								</TableCell>
							);
						} else {
							allCells.push(
								<TableCell
									sx={{ border: "none" }}
									key={`pagedgqltablemain-tabelcell-unabletorender-${itemKey}-${index}`}
									component="th"
									scope="row"
								>
									<Typography color="red" fontSize="14px">
										unable to render {itemKey}
										{devMode && (
											<Box>
												<pre>
													{JSON.stringify(
														value,
														null,
														4
													)}
												</pre>
											</Box>
										)}
									</Typography>
								</TableCell>
							);
						}
					});
					// if (index >= lowerIndex && index <= upperIndex) {
					if (index > lowerIndex - 1 && index <= upperIndex - 1) {
						return (
							<CustomTableRow
								onRowClick={onRowClick}
								index={index}
								allCells={allCells}
								actions={actions}
								rawDatas={rawDatas}
							/>
						);
					} else {
						return null;
					}
				})}
		</TableBody>
	);
}
export function CustomTableRow({
	index,
	allCells,
	actions,
	rawDatas,
	onRowClick,
}) {
	return (
		<TableRow
			id="container"
			key={`pagedgqltablemain-tbody-tablerow-${index}`}
			sx={{
				"&:last-child td, &:last-child th": {
					border: 0,
				},
				"&:hover": {
					background: "#F7F7F7",
				},
				borderBottom: "1px solid #E8E8E8",
				cursor: "pointer",
			}}
			onClick={() => {
				if (onRowClick) {
					onRowClick(rawDatas[index]);
				}
			}}
		>
			{/* {actions?.length > 0 && (
            <Box
                id='cube'
                sx={{
                    position: 'sticky',
                    left: '0px',
                    width: '50px',
                    height: '100%',
                    display: 'table-cell',
                    textAlign: 'center',
                    verticalAlign: 'middle',
                    background: 'white',
                    marginBottom: '2px',
                    // border: '1px solid red',
                }}
            >
                <Box
                    style={{
                        height: '100%',
                        overflow: 'hidden',
                        borderRadius: '2em',
                        paddingLeft: '5px',
                        paddingRight: '5px',
                        // border: '1px solid blue',
                    }}
                >
                    {actions?.map(({
                        triggerComp
                    }: TableAction, innerIndex: number) => {
                        return (
                            <Box
                                key={`pagedgqltablemain-action-fragment-${index}-${innerIndex}`}
                            >
                                {triggerComp(rawDatas[index])}
                            </Box>
                        )
                    })}
                </Box>
            </Box>
        )} */}
			{allCells}
		</TableRow>
	);
}
