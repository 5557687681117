
import React from 'react';
import { getPopupColorBasedOnStatusCode } from "../../Popup";
import AirplaneBox from "../AirplaneBox";
import { getBoxDataFromInFleetLocations } from "../AirplaneBox/utils";
export default function BoxBehindLastRowABCAdv({ invs, onClick, setClosePopup, setPopup }) {
    const data = getBoxDataFromInFleetLocations(invs);
    const boxX = 690;
    const boxY = 510;
    const popupXOffset = -503;
    const popupYOffset = -500;
    return (<g className="display-box">
            <Pointer />
            <AirplaneBox title="Behind Last Row 31ABC" x={boxX} y={boxY} data={data} onClick={onClick} minWidth={170} setClosePopup={setClosePopup} setPopup={(rowX, rowY, data) => {
            setPopup({
                status: data?.inventory?.status,
                data: data,
                x: rowX + boxX + popupXOffset,
                y: rowY + boxY + popupYOffset,
                // row: 'row',
                col: data.locationId.seatNo,
                color: getPopupColorBasedOnStatusCode(data?.inventory?.status?.code),
            });
        }}/>
        </g>);
}
function Pointer() {
    return (<g>
            <path className="path" d="M1172 397V442C1172 445.866 1168.87 449 1165 449H811C807.134 449 804 452.134 804 456V485.5" stroke="#202225" stroke-width="0.5" stroke-linecap="round" stroke-dasharray="2 2"/>
            <circle className="pointer-1st" cx="1172" cy="396" r="3" fill="#74C4E8"/>
            <circle className="pointer-2nd" opacity="0.4" cx="1172" cy="396" r="6" fill="#74C4E8"/>
        </g>);
}
