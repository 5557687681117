import React from 'react';
import { Grid } from "@mui/material";
import styles from "../Dasr.module.css";
import IconAirplaneTilt from "common/images/icons/elevade/Icon-AirplaneTilt.svg";

const StandbyAircraft = ({ data }) => {
  function formatACReg(e) {
    return <span className={`${styles.formatAcRegActive}`}>{e}</span>;
  }
  return (
    <>
      <div className={styles.divCard} style={{ marginBottom: "24px" }}>
        <Grid container columns={2}>
          <Grid item xs={1} className={styles.gridTitle} style={{ paddingBottom: "24px" }}>
            Standby Aircraft
          </Grid>
          <Grid item xs={1} className={`${styles.gridTitle} ${styles.gridJustifyEnd}`}>
            <img
              src={IconAirplaneTilt}
              alt="airplane-tilt"
              height={16}
              style={{ marginRight: "6px", marginTop: "2px" }}
            />
            {data?.filter((e) => e.status.status === "Standby" || e.status.status === "Utilised").length}
          </Grid>
          <Grid item xs={2}>
            <div
              style={{ maxHeight: "230px", minHeight: "115px", paddingTop: "10px" }}
              className={`${styles.hideScrollBar}  ${styles.macScrollbar}`}
            >
              {data
                ?.filter((item) => item.status.status === "Standby" || item.status.status === "Utilised")
                .map((e, index) => {
                  return (
                    <Grid container columns={2} sx={{ paddingBottom: "16px" }} key={index}>
                      <Grid item xs={1} sm={1} md={1} lg={0.6} xl={0.4}>
                        {formatACReg(e.aircraftReg)}
                      </Grid>
                      <Grid item xs={1} sm={1} md={1} lg={1.4} xl={1.6}>
                        <div className={`${styles.divCardRemarks} ${styles.divStandbyRemarks}`}>
                          {e.status.remarks || "-"}
                        </div>
                      </Grid>
                    </Grid>
                  );
                })}
              {(data?.filter((e) => e.status.status === "Standby" || e.status.status === "Utilised").length === 0 ||
                data === undefined) && (
                <Grid container columns={1} sx={{ paddingBottom: "16px" }}>
                  <Grid item xs={1} sx={{ fontSize: "12px", fontStyle: "italic", color: "#818181" }}>
                    No data found
                  </Grid>
                </Grid>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default StandbyAircraft;
