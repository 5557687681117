import * as React from "react";
import { withRouter } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Arrow from "common/images/icons/Breadcrumbs-Arrow.svg";
// import { useDispatch } from "react-redux";
// import { setCheckoutItems } from "common/store/reducers/cart";
import useMediaQuery from "@mui/material/useMediaQuery";

import styles from "./Breadcrumbs.module.css";
// import { ArrowBackIos } from "@mui/icons-material";

const BasicBreadcrumbs = (props) => {
  const isWeb = useMediaQuery("(min-width:900px)");

  const {
    history,
    location: { pathname },
  } = props;
  const filteredPathname = filterPath();

  const getProductId = (pathname) => {
    if (filteredPathname[2] === "product" && filteredPathname[3] === "detail") {
      pathname = pathname.replace("/aerotrade/marketplace/product/detail/", "");
      return pathname;
    }
    if (filteredPathname[2] === "catalog" && filteredPathname[3] === "detail") {
      pathname = pathname.replace("/aerotrade/marketplace/catalog/detail/", "");
      return pathname;
    }
  };

  const theme = createTheme({
    typography: {
      fontFamily: ["Gotham-Book"].join(","),
      marginRight: 4,
    },
  });

  const BreadcrumbsArrow = () => {
    // eslint-disable-next-line jsx-a11y/alt-text
    return <img src={Arrow} />;
  };

  function filterPath() {
    let maxLength = 17;
    const arr1 = pathname.split("/");
    //Breadcrumb for Company Management Page
    if (arr1[2] === "admin" && arr1[3] === "company-management") {
      maxLength = 99;
      arr1.splice(5, 1);
    }

    for (var i = 0; i < arr1.length; i++) {
      if (arr1[i].length > maxLength && !ReserveWords(arr1[i])) arr1[i] = null;
    }
    // if (arr1[arr1.length - 2] === "my-orders") arr1.push("order-details");
    if (arr1[arr1.length - 2] === "inventory") arr1.push("product-details");

    // eslint-disable-next-line array-callback-return
    var filtered = arr1.filter(function (el) {
      if (el !== null && el !== "") return el;
    });
    return filtered;
  }

  function ReserveWords(page) {
    const ReserveWords = [
      "terms-and-conditions",
      "customer-terms-and-conditions",
      "seller-terms-and-conditions",
      "seller-fair-trading-policy",
      "approvals-and-recognition",
      "predictive-maintenance",
      "attendance-overview",
      "APU-reliability-monitoring",
      "hydraulics-quality-monitoring",
      "new-parts-approval",
      "parts-update-approval",
      "add-catalog-product",
      "aircraft-monitoring",
      "workforce-management",
      "company-management",
      "manuals-collection",
      "warning-message-management",
      "email-alert-management",
    ];
    if (ReserveWords.indexOf(page) > -1) return true;
    else return false;
  }

  function ReservePage(page) {
    const ReserveCrumbs = [
      "CASCADE",
      "ELEVADE",
      "About Us",
      "Product",
      "Our Solutions",
      "V2",
      "Admin",
      "Workforce Management",
      "Task Overview",
      "Shift Management",
      "Account Settings",
      "Fleet Management",
      "Warning Message Management",
    ];
    if (ReserveCrumbs.indexOf(page) > -1) return true;
    else return false;
  }

  let marketplace = "/aerotrade/marketplace";
  let elevade = "/fleet";
  let cabinMonitoring = "/cabin-monitoring";
  let productId = getProductId(pathname);
  let aircraftId = pathname.replace("/fleet/", "");

  // Page specific cleanup
  if (filteredPathname[1] === "marketplace") {
    filteredPathname.splice(1, 1);
  }
  if (filteredPathname[filteredPathname.length - 1] === "checkout") {
    filteredPathname.splice(filteredPathname.length - 1, 0, "cart");
  }
  if (filteredPathname[filteredPathname.length - 1] === "order-placed") {
    filteredPathname.splice(1, 0, "cart");
    filteredPathname.splice(2, 0, "checkout");
  }

  return (
    <div style={{ paddingLeft: !isWeb ? "50px" : "" }}>
      {pathname !== marketplace &&
      // !pathname.includes(elevade) &&
      pathname !== "/callback" &&
      pathname !== elevade &&
      pathname !== cabinMonitoring &&
      pathname !== `${elevade}/${aircraftId}` &&
      pathname !== "/" &&
      pathname !== "/our-solutions/predictive-maintenance" &&
      pathname !== `/aerotrade/all-parts` &&
      pathname !== `${marketplace}/catalog/detail/${productId}` &&
      pathname !== `${marketplace}/product/detail/${productId}` ? (
        <div role="presentation" className={styles.breadcrumbs}>
          <ThemeProvider theme={theme}>
            <Breadcrumbs aria-label="breadcrumb" separator={<BreadcrumbsArrow />}>
              <Link
                onClick={() => history.push("/fleet")}
                underline="none"
                className={styles.breadcrumbsLink}
                style={{ marginRight: "8px" }}
              >
                Home
              </Link>
              {filteredPathname.map((name, index) => {
                let routeTo = `/${filteredPathname.slice(0, index + 1).join("/")}`;

                // Page specific cleanup
                if (name === "aerotrade") {
                  routeTo = "/aerotrade/marketplace";
                }
                if (name === "cart") {
                  routeTo = "/aerotrade/marketplace/cart";
                  name = "My Cart";
                }
                if (name === "checkout") {
                  routeTo = "/aerotrade/marketplace/checkout";
                }

                const space = name.replaceAll("-", " ");
                const arr = space.split(" ");
                let isLast = index === filteredPathname.length - 1;
                for (var i = 0; i < arr.length; i++) {
                  if (arr[i] === "aerotrade") arr[i] = arr[i].toUpperCase();
                  else if (arr[i] === "cascade") arr[i] = arr[i].toUpperCase();
                  else if (arr[i] === "elevade") arr[i] = arr[i].toUpperCase();
                  else if (arr[i] === "faq") arr[i] = arr[i].toUpperCase();
                  else if (arr[i] === "detail") arr[i] = "Product Details";
                  else arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
                }
                const pageName = arr.join(" ");
                return ReservePage(pageName) ? (
                  <Typography key={name} style={{ color: "#333333", margin: "0 8px" }}>
                    {pageName}
                  </Typography>
                ) : isLast ? (
                  <Typography
                    key={name}
                    style={{
                      textDecoration: "underline",
                      fontFamily: "Gotham-Medium",
                      color: "#333333",
                      margin: "0 8px",
                    }}
                  >
                    {pageName}
                  </Typography>
                ) : (
                  <Link
                    key={name}
                    onClick={() => {
                      history.push(routeTo);
                      if (pageName === "My Cart") {
                        // dispatch(setCheckoutItems({}));
                      }
                    }}
                    underline="none"
                    className={styles.breadcrumbsLink}
                    style={{ marginRight: "8px" }}
                  >
                    {pageName}
                  </Link>
                );
              })}
            </Breadcrumbs>
          </ThemeProvider>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default withRouter(BasicBreadcrumbs);
