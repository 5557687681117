import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

const StyledButton = styled(Button)(() => ({
  height: "40px",
  minWidth: "40px",
  padding: "0px 10px",
  margin: "0 2px",
  fontWeight: 400,
  textAlign: "center",
  borderRadius: "4px",
  lineHeight: "1.43",
  backgroundColor: "transparent",
  border: "1px solid #B1B1B1",
  cursor: "pointer",
  color: "#333333",
  "&&:focus": {
    outline: "none",
  },
  "&:disabled": {
    borderColor: "#D1D1D1",
    color: "#D1D1D1",
  },
}));

const PaginateButton = ({ onClick, children, ...props }) => {
  return (
    <StyledButton {...props} onClick={onClick}>
      {children}
    </StyledButton>
  );
};

export function AdePagination({
  currentPage = 1,
  pageSize = 20,
  handleNextPage,
  handlePrevPage,
  disabledPrev,
  disabledNext,
  ...props
}) {
  return (
    <Grid container direction="row" alignItems="center" columnSpacing={2}>
      <Grid item>
        <span>
          {(currentPage - 1) * pageSize + 1} - {currentPage * pageSize}
        </span>
      </Grid>

      <Grid item>
        <PaginateButton
          disabled={currentPage === 1 || disabledPrev}
          onClick={handlePrevPage}
        >
          <NavigateBefore />
        </PaginateButton>
        <PaginateButton disabled={disabledNext} onClick={handleNextPage}>
          <NavigateNext />
        </PaginateButton>
      </Grid>
    </Grid>
  );
}
