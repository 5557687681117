
import React from 'react';
import { getPopupColorBasedOnStatusCode } from "../../Popup";
import AirplaneBox from "../AirplaneBox";
import { getBoxDataFromInFleetLocations } from "../AirplaneBox/utils";
export default function BoxFlightDeckAdv({ invs, onClick, setClosePopup, setPopup }) {
    const data = getBoxDataFromInFleetLocations(invs);
    const boxX = 10;
    const boxY = 80;
    const popupXOffset = -217;
    const popupYOffset = -410;
    return (<g className="display-box">
            <Pointer />
            <AirplaneBox title="Flight Deck" x={boxX} y={boxY} data={data} onClick={onClick} minWidth={140} setClosePopup={setClosePopup} setPopup={(rowX, rowY, data) => {
            setPopup({
                status: data?.inventory?.status,
                data: data,
                x: rowX + boxX + popupXOffset,
                y: rowY + boxY + popupYOffset,
                // row: 'row',
                col: data.locationId.seatNo,
                color: getPopupColorBasedOnStatusCode(data?.inventory?.status?.code),
            });
        }}/>
        </g>);
}
function Pointer() {
    return (<g>
            <path className="path" d="M145.5 346H32C28.134 346 25 342.866 25 339V104.5" stroke="#333333" stroke-width="0.5" stroke-linecap="round" stroke-dasharray="2 2"/>
            <circle className="pointer-1st" cx="145" cy="346" r='3' fill="#74C4E8"/>
            <circle className="pointer-2nd" opacity="0.4" cx="145" cy="346" r='6' fill="#74C4E8"/>
        </g>);
}
