import { env_var_REACT_APP_API_URL } from "../../envVar/vars";
import useGetGQL, { axiosGraphqlRequest } from "../useGetGQL";
import useMutateGQL from "../useMutateGQL";
import { useAppSelector } from "../../app/hooks";

import { getMonth2D, getDate2D } from "../../views/utils/dates";

export function useGetAllInventories() {
	const { companyProfile } = useAppSelector((state) => state.userEntity);
	const code = companyProfile?.code;

	const data = useGetGQL({
		gqlquery: code
			? `
        query allitem {
          allInventories (aocCode: "${code}"){
            totalCount
          }
        }
        `
			: "",
		url: env_var_REACT_APP_API_URL ?? "",
		querySign: "allInventories",
		isEdges: false,
	});

	return data;
}

export function useGetAllInvStatus() {
	const { companyProfile } = useAppSelector((state) => state.userEntity);
	const code = companyProfile?.code;

	const data = useGetGQL({
		gqlquery: code
			? `
        query allItemStatus  {
            totalInventoryStatus(aocCode: "${code}") {
              totalActive
              totalLost
              totalTagError
              totalExpired
              totalRemind
              totalDefect
            }
          }
        `
			: "",
		url: env_var_REACT_APP_API_URL ?? "",
		querySign: "totalInventoryStatus",
		isEdges: false,
	});

	return data;
}

export function useGetAllInventoriesWithOffset({ offset }) {
	const allInventoryLists = useGetGQL({
		defaultIsLoading: true,
		gqlquery: `
        query allitem {
            allScanners 
			${offset ? `(offset:${offset})` : ""}
			{
            totalCount
            edges {
              node {
                id
                createdAt
                createdBy
                updatedAt
                deviceId
                name
                model {
                  id
                  code
                  name
                  manufacturer{
                    id
                    name
                    code
                  }
                }
                status {
                  id
                  code
                  name
                }
              }
            }
          }
        }
        `,
		querySign: "allScanners",
		url: env_var_REACT_APP_API_URL ?? "",
	});

	return allInventoryLists;
}

export function useGetFilteredInventory({
	offset,
	search,
	filterRegnos,
	filterAcModels,
	filterStatusIds,
	filterEqTypeIds,
	filterLocationExist,
}) {
	const { companyProfile } = useAppSelector((state) => state.userEntity);
	const code = companyProfile?.code;

	const allInventoryLists = useGetGQL({
		defaultIsLoading: true,
		gqlquery: `
        query allitem {
            allInventories (
                offset:${offset}
                ,aocCode: "${code}"
                ,haveLocation:${filterLocationExist}
                ${
					search && search !== "" && search !== null
						? `, searchInventory:"${search}"`
						: ""
				}
                ${
					filterRegnos && filterRegnos.length > 0
						? `, multipleAircraftRegno:${JSON.stringify(
								filterRegnos
						  )}`
						: ""
				}
                ${
					filterAcModels && filterAcModels.length > 0
						? `, multipleAircraftModel:${JSON.stringify(
								filterAcModels
						  )}`
						: ""
				}
                ${
					filterStatusIds && filterStatusIds.length > 0
						? `, multipleItemStatusId:${JSON.stringify(
								filterStatusIds
						  )}`
						: ""
				}
                ${
					filterEqTypeIds && filterEqTypeIds.length > 0
						? `, multipleEqTypeId:${JSON.stringify(
								filterEqTypeIds
						  )}`
						: ""
				}
            ) {
            totalCount
            edges {
              node {
                id
                createdAt
                createdBy
                updatedAt
                tag
                serialNo
                expiryDate
                manufactureDate
                updatedBy
                createdBy
                item {
                  id
                  code
                  name
                  description
                }
                status {
                  id
                  code
                  name
                }
                locationlistSet{
                    edges{
                      node{
                        id
                        fleetId{
                          id
                          regNo
                          model
                          aocCode
                        }
                        locationId{
                          id
                          seatNo
                          inventoryLocation{
                            id
                            name
                          }
                        }
                      }
                    }
                  }
              }
            }
          }
        }
        `,
		querySign: "allInventories",
		url: env_var_REACT_APP_API_URL ?? "",
	});

	return allInventoryLists;
}

export function useCreateInventory ({
	addEqStates,
	dateFormattedexpDate,
	dateFormattedmfgDate,
}) {

	const { companyProfile, userProfile } = useAppSelector((state) => state.userEntity);
	const code = companyProfile?.code;
	const fullName = userProfile?.fullName;
	const photo = userProfile?.photo;

	// { mutate: add }
	const data = useMutateGQL({
        gqlquery: `
        mutation addEQ{
            createInventories(input:{
              tag:"${addEqStates.tagId?.value}"
              ${addEqStates?.eqType?.value ? `item:"${addEqStates?.eqType?.value}"` : ''}
              ${addEqStates?.serialNo?.value ? `serialNo:"${addEqStates?.serialNo?.value}"` : ''}
              ${addEqStates?.status?.value ? `status:"${addEqStates?.status?.value}"` : ''}
              ${dateFormattedexpDate ? `expiryDate:"${dateFormattedexpDate}"` : ''}
              ${dateFormattedmfgDate ? `manufactureDate:"${dateFormattedmfgDate}"` : ''}
              ${addEqStates?.inputRegNoId?.value ? `fleetId:"${addEqStates?.inputRegNoId?.value}"` : ''}
              ${addEqStates?.locationDetailsId?.value ? `locationDetailsId:"${addEqStates?.locationDetailsId?.value}"` : ''}
              aocCode:"${code}"
              ${photo ? `userAvatar:"${photo}"` : ''}
              userName:"${fullName}"
            }){
              inventories{
                id
                serialNo
                status {
                  code
                  name
                }
              }
            }
          }
        `,
        url: env_var_REACT_APP_API_URL ?? '',
    });

	return data
}

export async function replaceLocation(tag, locationDetailsId, fleetId, fullname, photo) {
    const inventoryId = await getInventoriesByTag(tag);
    const url = env_var_REACT_APP_API_URL ?? '';
    const queryStr = `
    mutation updateInvs {
        updateInventories (input:{
          id:"${inventoryId}",
          locationDetailsId:"${locationDetailsId}",
          fleetId:"${fleetId}",
          userName:"${fullname}",
          ${photo ? `userAvatar:"${photo}"` : ''}
        }){
          inventories{
            id
          }
        }
      }
    `;
    const result = await axiosGraphqlRequest(url, queryStr);
    return result;
}

export async function getInventoriesByTag(tag) {
    const url = env_var_REACT_APP_API_URL ?? '';
    const queryStr = `
      query inventory {
        allInventories(tag:"${tag}"){
          edges{
            node{
                id
                tag
                serialNo
            }
          }
        }
      }
    `;
    const result = await axiosGraphqlRequest(url, queryStr);
    const inventoryId = result?.data?.data?.allInventories?.edges[0]?.node?.id ?? undefined;
    return inventoryId;
}

export function useUpdateInventory ({
	invId,
	serialNo,
	eqType,
	status,
	fleetId,
	inputLocNo,
	mfgDate,
	expDate, 
}) {

	const { userProfile } = useAppSelector((state) => state.userEntity);
	const fullName = userProfile?.fullName;
	const photo = userProfile?.photo;

	const data = useMutateGQL({
        gqlquery: () => {
            const dateFormattedmfgDate = mfgDate ? `${new Date(mfgDate).getFullYear()}-${getMonth2D(new Date(mfgDate))}-${getDate2D(new Date(mfgDate))}` : undefined;
            const dateFormattedexpDate = expDate ? `${new Date(expDate).getFullYear()}-${getMonth2D(new Date(expDate))}-${getDate2D(new Date(expDate))}` : undefined;
            return `
              mutation updateEQ {
                updateInventories (input:{
                  id:"${invId ?? ""}"
                  ${serialNo?.value ? `serialNo:"${serialNo?.value}"` : ''}
                  ${eqType?.value ? `item:"${eqType?.value}"` : ''}
                  ${status?.value ? `status:"${status?.value}"` : ''}
                  ${mfgDate ? `manufactureDate:"${dateFormattedmfgDate}"` : ''}
                  ${expDate ? `expiryDate:"${dateFormattedexpDate}"` : ''}
                  ${fleetId?.value ? `fleetId:"${fleetId?.value}"` : ''}
                  ${inputLocNo?.value ? `locationDetailsId:"${inputLocNo?.value}"` : ''}
                  ${photo ? `userAvatar:"${photo}"` : ''}
                  userName:"${fullName}"
                }){
                  inventories {
                    id
                    serialNo
                    item{
                      name
                    }
                    status {
                      name
                    }
                    manufactureDate
                    expiryDate
                  }
                }
              }
            `;
        },
        url: env_var_REACT_APP_API_URL ?? ""
    });

	return data
}

export function useDeleteInventory ({invId}) {

	// { mutate: mutateDelete }
	const data = useMutateGQL({
		gqlquery: `
		mutation deleteEQ {
			deleteInventories (input:{
			  id:"${invId ?? ""}"
			}){success}
		  }
		`,
		url: env_var_REACT_APP_API_URL ?? "",
	});

	return data
}

export function useGetAllInvByACRegNo ({ acRegNo }) {

	const data = useGetGQL({
		gqlquery: `
		query allitem {
			allInventories(multipleAircraftRegno:"${acRegNo}") {
				totalCount
				edges {
					node {
						id
						tag
						serialNo
						item {
							id
							code
							name
						}
						locationlistSet {
							edges {
								node {
									locationId {
										seatNo
									}
								}
							}
						}
						status {
							name
						}
					}
				}
		  	}
		}
		`,
		querySign: 'allInventories',
		url: env_var_REACT_APP_API_URL ?? "",
		isEdges: true,
	});

	return data
}

export function useGetInventory ({inventoryId}) {


    const data = useGetGQL({
        url: env_var_REACT_APP_API_URL ?? "",
        gqlquery: `
        query inventoryStat {
            inventories(id: "${inventoryId}") {
              id
              tag
              serialNo
              status {
                id
                code
                name
              }
            }
          }
        `,
        querySign: 'inventories',
        isEdges: false,
    });

	return data
}