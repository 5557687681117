
import React from 'react';
import CabinSwitchRoutedPages from "./CabinSwitchRoutedPages";
import NotificationInterface from "../views/components/notifications/UniversalNotification";
import DialogInterface from "../views/components/dialog-box/DialogInterface";
import ThemeInterface from "./ThemeInterface";
import "./cabin-app.css";

function CabinApp() {
	{
		/* <RolesAssigner /> */
	}
	return (
		<ThemeInterface>
			<DialogInterface>
				<NotificationInterface>
					<div
						className="App"
						style={{
							marginTop: "40px",
							height: "100%",
							width: "99.5%", // minus scroll bar width
						}}
					>
						<CabinSwitchRoutedPages />
					</div>
				</NotificationInterface>
			</DialogInterface>
		</ThemeInterface>
	);
}
export default CabinApp;
