import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import { Skeleton, Tooltip, tooltipClasses, useMediaQuery } from "@mui/material";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import styles from "./AircraftMonitoring.module.css";
import FleetCardHigh from "common/images/icons/elevade/Fleet-Card-High.svg";
import FleetCardMed from "common/images/icons/elevade/Fleet-Card-Med.svg";
import FleetCardLow from "common/images/icons/elevade/Fleet-Card-Low.svg";
import FleetCardOk from "common/images/icons/elevade/Fleet-Card-Ok.svg";
import FleetCardUndefined from "common/images/icons/elevade/Fleet-Card-Undefined.svg";
import FleetCardNone from "common/images/icons/elevade/Fleet-Card-None.svg";
import FleetCardNuisance from "common/images/icons/elevade/Fleet-Card-Nuisance.svg";
import OOOIOut from "common/images/icons/elevade/OOOI-OUT.svg";
import OOOIOff from "common/images/icons/elevade/OOOI-OFF.svg";
import OOOIOn from "common/images/icons/elevade/OOOI-ON.svg";
import OOOIIn from "common/images/icons/elevade/OOOI-IN.svg";
import OOOITng from "common/images/icons/elevade/OOOI-T&G.svg";
import OOOIDr from "common/images/icons/elevade/OOOI-DR.svg";
import OOOIMnt from "common/images/icons/elevade/OOOI-MNT.svg";
import ACMSLow from "common/images/icons/elevade/ACMS-Low.svg";
import ACMSHigh from "common/images/icons/elevade/ACMS-High.svg";
import ACMSUnavailable from "common/images/icons/elevade/ACMS-Unavailable.svg";
import PredictiveAvailable from "common/images/icons/elevade/Predictive-Available.svg";
import PredictiveUnavailable from "common/images/icons/elevade/Predictive-Unavailable.svg";
import DataUnavailable from "common/images/icons/elevade/Data-unavailable.svg";
import ArrowRight from "common/images/icons/elevade/ArrowRight.svg";
import { useHistory, useLocation } from "react-router-dom";
import { Grid } from "@mui/material";
import { makeAPIRequestRun } from "common/helpers";
import getTimezoneAbbreviation from "../component/TimeZoneAbbreviation";
import AircraftMonitoringSummary from "./AircraftMonitoringSummary";
import { useDispatch, useSelector } from "react-redux";
import {
  getQueryVariable,
  getTooltip,
  ConvertUTCDatetime,
  createOptions,
  calculatePercentage,
  LinearProgressBar,
  uniqueList,
  setAOCBasedOnPermission,
  formatDateDasr,
} from "../component/Helpers";
import SearchBar from "../component/SearchBar";
import ActiveFilters from "../component/ActiveFilters";
import IATA from "common/resources/IATA.json";
import Switch from "react-switch";
import AdvanceFilter from "../component/AdvanceFilter";
import { setFilters, setKeyword } from "common/store/reducers/elevade/filters";
import { elevadeActiveFilter, AOCList } from "common/constant";
import AirplaneLoader from "../component/AirplaneLoader";
import { makeAPIRequestMDB } from "common/helpers/makeAPIRequestMDB";
import IconAOG from "common/images/icons/elevade/Icon-AOGClear.svg";
import IconMaintenance from "common/images/icons/elevade/Icon-MaintenanceClear.svg";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#6AB47B" : "#D1D1D1",
  },
}));

const StyledTooltip = styled(({ className, ...props }) => <Tooltip {...props} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {},
}));

const AircraftMonitoring = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [showFilter, setShowFilter] = useState(styles.close);
  const [minHeight, setMinHeight] = useState("112px");
  const [localTime, setLocalTime] = useState(false);
  const [timeZone, setTimeZone] = useState();
  const [timeZoneAfter, setTimeZoneAfter] = useState("'UTC'");
  const [timeZoneBefore, setTimeZoneBefore] = useState("");
  const history = useHistory();
  const { companyAOC, userPermission } = useSelector((state) => state.userEntity);
  let AOC = companyAOC;
  AOC = setAOCBasedOnPermission(AOC, userPermission);
  // if (Array.isArray(AOC)) {
  //   const newAOC = cloneDeep(AOC);
  //   const index = newAOC?.indexOf("ADE");
  //   newAOC?.splice(index, 1);
  //   AOC = newAOC;
  // }
  const isMobile = useMediaQuery("(max-width:900px)");
  const [airportList, setAirportList] = useState();
  const [isAdvanceFilter, setIsAdvanceFilter] = useState(false);
  const [activeFilters, setActiveFilters] = useState({});
  const location = useLocation();
  const paramsString = location.search;
  const searchParams = new URLSearchParams(paramsString);
  let openLegend = "false";
  if (searchParams.get("open")) openLegend = searchParams.get("open");
  const priority = ["High", "Medium", "Low", "OK", "Nuisance", "None", "Undefined"];
  const monthAbv = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const [isAircraftStatus, setIsAircraftStatus] = useState([]);

  useEffect(() => {
    if (searchParams.get("open")) setIsAdvanceFilter(false);
    // eslint-disable-next-line
  }, [openLegend]);

  useEffect(() => {
    setTimeZone(getTimezoneAbbreviation(new Date()));
  }, [timeZoneAfter]);

  useEffect(() => {
    var result = [];
    IATA.forEach((station, num) => {
      var label = `${station?.iata} - ${station?.name}`;
      var id = num;
      result.push({ label, id });
    });
    setAirportList(result);
  }, []);

  // Custom styles
  const useStyles = makeStyles(() => ({
    // Styles for active tab
    ActiveTab: {
      fontSize: "16px",
      marginTop: "2px",
      paddingLeft: "24px",
      fontFamily: "Gotham-Medium",
      color: "#333333",
      cursor: "pointer",
      borderBottom: "3px solid #088FD1",
      paddingBottom: "6px",
    },
    // Styles for inactive tab
    InactiveTab: {
      fontSize: "16px",
      marginTop: "2px",
      paddingLeft: "24px",
      fontFamily: "Gotham-Book",
      color: "#838383",
      cursor: "pointer",
    },
    // Styles for the card view's tooltip
    cardViewTootltip: {
      backgroundColor: "rgba(0, 21, 60, 0.75)",
      backdropFilter: "blur(10px)",
      webkitBackdropFilter: "blur(10px)",
      borderRadius: 8,
      boxShadow: "0px 3px 6px #00000029",
      minWidth: 350,
      maxWidth: 380,
      marginTop: "-10px",
      zIndex: 999,
    },
  }));

  const classes = useStyles();

  // A function to manipulate the position of the sidebar panel & filter panel on scroll
  // This to make sure the sidebar panel & filter panel is always sticky to the header
  useEffect(() => {
    const onScroll = () => {
      const scrollHeight = window.scrollY;
      if (parseInt(scrollHeight) === 0) {
        setMinHeight("112px");
      } else if (parseInt(scrollHeight) >= 40) {
        setMinHeight("74px");
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    fetchAircraftStatus();
    // eslint-disable-next-line
  }, []);

  // Set the active tab to the one clicked on
  function handleActiveTab(tab) {
    setActiveTab(tab);
  }

  // Toggle the filter panel
  const handleShowFilter = () => {
    if (showFilter === styles.open) {
      setShowFilter(styles.close);
    } else {
      setShowFilter(styles.open);
    }
  };

  // Fetching Aircraft Daily Status from DASR Module
  function fetchAircraftStatus() {
    var currentDate = new Date();
    var options = { month: "long", day: "2-digit", year: "numeric" };
    var formattedDate = currentDate.toLocaleDateString("en-US", options);
    currentDate = new Date(formattedDate);
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const isToday = `${year}-${month}-${day}`;
    if (isToday !== "NaN-NaN-NaN") {
      async function FetchData() {
        const fetch = makeAPIRequestMDB(`get`, `api/aircraft/daily-status?date=${isToday}&aoc=${AOC.join(",")}`);
        fetch
          .then((response) => {
            setIsAircraftStatus(
              response.data.map((e) => {
                return {
                  aircraftReg: e.aircraftReg,
                  status: e.status.status,
                  tba: e.updated_status.tba,
                  ets: e.updated_status.ets,
                  etsTime: e.updated_status.etsTime,
                };
              })
            );
          })
          .catch((error) => {
            console.log("error", error);
          });
        // setIsLoading(false);
        // setIsLoadingSub(false);
      }
      FetchData();
    }
  }

  // List of functions to set the styles and icons for card views
  function getCardViewStatus(card) {
    switch (card) {
      case "High":
        return styles.high;
      case "Medium":
        return styles.med;
      case "Low":
        return styles.low;
      case "OK":
        return styles.ok;
      case "Undefined":
        return styles.undefined;
      case "None":
        return styles.none;
      case "Nuisance":
        return styles.nuisance;
      default:
        return styles.ok;
    }
  }

  function getFleetCard(priority, text) {
    let card = priority;
    if (priority === null) card = text;
    switch (card) {
      case "High":
        return <img src={FleetCardHigh} alt="High" className={styles.fleetIcon} />;
      case "Medium":
        return <img src={FleetCardMed} alt="Medium" className={styles.fleetIcon} />;
      case "Low":
        return <img src={FleetCardLow} alt="Low" className={styles.fleetIcon} />;
      case "OK":
        return <img src={FleetCardOk} alt="Ok" className={styles.fleetIcon} />;
      case "NO WARNING OR FAULT MESSAGE":
        return <img src={FleetCardOk} alt="Ok" className={styles.fleetIcon} />;
      case "Undefined":
        return <img src={FleetCardUndefined} alt="Undefined" className={styles.fleetIcon} />;
      case "None":
        return <img src={FleetCardNone} alt="None" className={styles.fleetIcon} />;
      case "Nuisance":
        return <img src={FleetCardNuisance} alt="Nuisance" className={styles.fleetIcon} />;
      default:
        return <img src={FleetCardUndefined} alt="None" className={styles.fleetIcon} />;
    }
  }

  function toolTipsPriorityIcon(priority, text) {
    let card = priority;
    if (priority === null) card = text;
    switch (card) {
      case "High":
        return <img src={FleetCardHigh} alt="High" className={styles.fleetIcon} style={{ width: "20px" }} />;
      case "Medium":
        return <img src={FleetCardMed} alt="Medium" className={styles.fleetIcon} style={{ width: "20px" }} />;
      case "Low":
        return <img src={FleetCardLow} alt="Low" className={styles.fleetIcon} style={{ width: "20px" }} />;
      case "OK":
        return <img src={FleetCardOk} alt="Ok" className={styles.fleetIcon} style={{ width: "20px" }} />;
      case "NO WARNING OR FAULT MESSAGE":
        return <img src={FleetCardOk} alt="Ok" className={styles.fleetIcon} style={{ width: "20px" }} />;
      case "Undefined":
        return <img src={FleetCardUndefined} alt="Undefined" className={styles.fleetIcon} style={{ width: "20px" }} />;
      case "None":
        return <img src={FleetCardNone} alt="None" className={styles.fleetIcon} style={{ width: "20px" }} />;
      case "Nuisance":
        return <img src={FleetCardNuisance} alt="Nuisance" className={styles.fleetIcon} style={{ width: "20px" }} />;
      default:
        return <img src={FleetCardUndefined} alt="None" className={styles.fleetIcon} style={{ width: "20px" }} />;
    }
  }

  function getOOOI(data) {
    let status = "";
    data.in_datetime !== null
      ? (status = <img src={OOOIIn} alt="In" className={styles.oooiIcon} />)
      : data.on_datetime !== null
      ? (status = <img src={OOOIOn} alt="On" className={styles.oooiIcon} />)
      : data.off_datetime !== null
      ? (status = <img src={OOOIOff} alt="Off" className={styles.oooiIcon} />)
      : data.out_datetime !== null
      ? (status = <img src={OOOIOut} alt="Out" className={styles.oooiIcon} />)
      : data.tng_datetime && data.tng_datetime !== null
      ? (status = <img src={OOOITng} alt="Touch and Go" className={styles.oooiIcon} />)
      : data.dr_datetime && data.dr_datetime !== null
      ? (status = <img src={OOOIDr} alt="Door" className={styles.oooiIcon} />)
      : data.mnt_datetime && data.mnt_datetime !== null
      ? (status = <img src={OOOIMnt} alt="Maintainance" className={styles.oooiIcon} />)
      : (status = "");

    return status;
  }

  function getOOOIStatus(data) {
    let status = data.flight_status;
    // data.in_datetime !== null
    //   ? (status = "IN")
    //   : data.on_datetime !== null
    //   ? (status = "ON")
    //   : data.off_datetime !== null
    //   ? (status = "OFF")
    //   : data.out_datetime !== null
    //   ? (status = "OUT")
    //   : data.tng_datetime && data.tng_datetime !== null
    //   ? (status = "T&G")
    //   : data.dr_datetime && data.dr_datetime !== null
    //   ? (status = "DR")
    //   : data.mnt_datetime && data.mnt_datetime !== null
    //   ? (status = "MNT")
    //   : (status = "N/A");

    return status;
  }

  function getACMS(status) {
    switch (status) {
      case "Low":
        return (
          <div className={`${styles.tooltip}`}>
            <img src={ACMSLow} alt="Low" className={styles.acmsIcon} />
            <span data-html="true" data-toggle="tooltip" className={`${styles.tooltiptext}`}>
              {getTooltip("acms", status)}
            </span>
          </div>
        );
      case "High":
        return (
          <div className={`${styles.tooltip}`}>
            <img src={ACMSHigh} alt="High" className={styles.acmsIcon} />
            <span data-html="true" className={`${styles.tooltiptext}`}>
              {getTooltip("acms", status)}
            </span>
          </div>
        );
      case "Unavailable":
        return (
          <div className={`${styles.tooltip}`}>
            <img src={ACMSUnavailable} alt="Unavailable" className={styles.acmsIcon} />
            <span data-html="true" className={`${styles.tooltiptext}`}>
              {getTooltip("acms", status)}
            </span>
          </div>
        );
      default:
        return (
          <div className={`${styles.tooltip}`}>
            <img src={ACMSUnavailable} alt="Unavailable" className={styles.acmsIcon} />
            <span data-html="true" className={`${styles.tooltiptext}`}>
              {getTooltip("acms", "ACMS Report data unavailable")}
            </span>
          </div>
        );
    }
  }

  // eslint-disable-next-line
  // function getPredictive(status) {
  //   switch (status) {
  //     case "0":
  //       return (
  //         <div className={`${styles.tooltip}`}>
  //           <img src={PredictiveUnavailable} alt="Unavailable" className={styles.predictiveIcon} />
  //           <span className={`${styles.tooltiptext}`}>{getTooltip("pm", status)}</span>
  //         </div>
  //       );
  //     case "1":
  //       return (
  //         <div className={`${styles.tooltip}`}>
  //           <img src={PredictiveAvailable} alt="Available" className={styles.predictiveIcon} />
  //           <span className={`${styles.tooltiptext}`}>{getTooltip("pm", status)}</span>
  //         </div>
  //       );
  //     default:
  //       return (
  //         <div className={`${styles.tooltip}`}>
  //           <img src={PredictiveUnavailable} alt="Unavailable" className={styles.predictiveIcon} />
  //           <span className={`${styles.tooltiptext}`}>{getTooltip("pm", "Predictive maintenance not available")}</span>
  //         </div>
  //       );
  //   }
  // }

  // function getUplink(status) {
  //   switch (status) {
  //     case "0":
  //       return (
  //         <div className={`${styles.tooltip}`}>
  //           <img src={UplinkUnavailable} alt="Unavailable" className={styles.uplinkIcon} />
  //           <span className={`${styles.tooltiptext}`}>{getTooltip("ul", status)}</span>
  //         </div>
  //       );
  //     case "1":
  //       return (
  //         <div className={`${styles.tooltip}`}>
  //           <img src={UplinkAvailable} alt="Available" className={styles.uplinkIcon} />
  //           <span className={`${styles.tooltiptext}`}>{getTooltip("ul", status)}</span>
  //         </div>
  //       );
  //     default:
  //       return (
  //         <div className={`${styles.tooltip}`}>
  //           <img src={UplinkUnavailable} alt="Unavailable" className={styles.uplinkIcon} />
  //           <span className={`${styles.tooltiptext}`}>{getTooltip("ul", "Uplink not available")}</span>
  //         </div>
  //       );
  //   }
  // }

  // Function to detect mouse click outside filter box to close the filter box
  function useOutsideClicker(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowFilter(styles.close);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideClicker(wrapperRef);

  const [initialAircratfData, setInitialAircraftData] = useState([]);
  const [initialInactiveAircratfData, setInitialInactiveAircraftData] = useState([]);
  const [inactiveAircraftData, setInactiveAircraftData] = useState([]);
  const [newAircraftData, setNewAircraftData] = useState([initialAircratfData]);
  const [aircraftData, setAircraftData] = useState(newAircraftData);
  const [aircraftDataInactive, setAircraftDataInactive] = useState(inactiveAircraftData);
  const [isSearchInput, setIsSearchInput] = useState([]);
  const [isOperator, setIsOperator] = useState([]);
  const [isFaultPriority, setIsFaultPriority] = useState([]);
  const [isDepartureAirport, setIsDepartureAirport] = useState([]);
  const [isArrivalAirport, setIsArrivalAirport] = useState([]);
  const [isAircraftType, setIsAircraftType] = useState([]);
  const [isAcms, setIsAcms] = useState("All");
  const [isSortBy, setIsSortBy] = useState([]);
  const [isSorting, setIsSorting] = useState("asc");
  const [isLoading, setIsLoading] = useState(true);
  // const [intervalTimer, setIntervalTimer] = useState(null);
  const [hours, setHours] = useState(72);
  const [isQuery, setIsQuery] = useState(false);
  const [countAoc, setCountAoc] = useState();
  const [checked, setChecked] = useState(false);
  const [initial, setInitial] = useState(false);
  const [checkedAOG, setCheckedAOG] = useState(false);
  const [initialAllFaultsMessages, setInitialAllFaultsMessages] = useState([]);
  const [lastModifiedDT, setLastModifiedDT] = useState();
  const dispatch = useDispatch();

  const queryAcTab = getQueryVariable("actab");

  // Calling the API
  useEffect(() => {
    async function getSettings() {
      // const getElevade = await makeAPIRequestRun("get", "settings/elevade");
      // setLocalTime(getElevade.value.localDatetime);
      // setIntervalTimer(getElevade.value.intervalTimer);
    }
    getSettings();
  }, []);

  useEffect(() => {
    setIsLoading(true);
  }, [hours]);

  useEffect(() => {
    async function getAircraftData() {
      const fetchData = makeAPIRequestRun("get", "acarsdata/list/" + hours + "/" + AOC);

      fetchData
        .then((response) => {
          setInitialAircraftData(response?.rows[0]);
          setNewAircraftData(response?.rows[0]);
          setAircraftData(response?.rows[0]);
          setLastModifiedDT(new Date(response?.last_modified_dt[0].last_modified_dt.value));
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    getAircraftData();
    setInterval(() => {
      getAircraftData();
      isSearchingAndFiltering();
      setIsLoading(true);
    }, 500000);
    // eslint-disable-next-line
  }, [hours]);

  useEffect(() => {
    const fetchInactiveData = makeAPIRequestRun("get", "acarsdata/inactive/" + hours + "/" + AOC);
    fetchInactiveData
      .then((response) => {
        setInactiveAircraftData(response);
        setInitialInactiveAircraftData(response);
        // setIsLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [hours]);

  useEffect(() => {
    async function getFaultMessages() {
      const fetchData = makeAPIRequestRun("get", "acarsdata/fleetAllWarningMessages");

      fetchData
        .then((response) => {
          setInitialAllFaultsMessages(response);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    getFaultMessages();
  }, [hours]);

  const isSearchingAndFiltering = () => {
    let filteredData = initialAircratfData;
    let filteredInactiveData = inactiveAircraftData;
    if (isSearchInput.length !== 0) {
      filteredData = filteredData.filter(
        (item) =>
          isSearchInput.some((searchTerm) => item.Operator?.includes(searchTerm)) ||
          isSearchInput.some((searchTerm) => item.flight_no?.includes(searchTerm)) ||
          isSearchInput.some((searchTerm) => item.departure_airport_format?.includes(searchTerm)) ||
          isSearchInput.some((searchTerm) => item.destination_airport_format?.includes(searchTerm)) ||
          isSearchInput.some((searchTerm) => item.AC_REG?.includes(searchTerm)) ||
          isSearchInput.some((searchTerm) => item.Type?.includes(searchTerm))
      );
      filteredInactiveData = filteredInactiveData.filter(
        (item) =>
          isSearchInput.some((searchTerm) => item.Operator?.includes(searchTerm)) ||
          isSearchInput.some((searchTerm) => item.flight_no?.includes(searchTerm)) ||
          isSearchInput.some((searchTerm) => item.departure_airport_format?.includes(searchTerm)) ||
          isSearchInput.some((searchTerm) => item.destination_airport_format?.includes(searchTerm)) ||
          isSearchInput.some((searchTerm) => item.AC_REG?.includes(searchTerm)) ||
          isSearchInput.some((searchTerm) => item.Type?.includes(searchTerm))
      );
    }
    if (isOperator && isOperator.length !== 0) {
      filteredData = filteredData.filter((item) => isOperator.includes(item["Operator"]));
      filteredInactiveData = filteredInactiveData.filter((item) => isOperator.includes(item["Operator"]));
    }
    if (isFaultPriority && isFaultPriority.length !== 0) {
      if (isFaultPriority.includes("Null")) {
        filteredData = filteredData.filter(
          (item) =>
            isFaultPriority.includes(item["priority"]) ||
            item["priority"] === "Missing" ||
            item["priority"] === "Undefined"
        );
        filteredInactiveData = filteredInactiveData.filter(
          (item) =>
            isFaultPriority.includes(item["priority"]) ||
            item["priority"] === "Missing" ||
            item["priority"] === "Undefined"
        );
      } else {
        filteredData = filteredData.filter((item) => isFaultPriority.includes(item["priority"]));
        filteredInactiveData = filteredInactiveData.filter((item) => isFaultPriority.includes(item["priority"]));
      }
    }
    if (isDepartureAirport && isDepartureAirport.length !== 0) {
      filteredData = filteredData.filter((item) => isDepartureAirport.includes(item["departure_airport_format"]));
      filteredInactiveData = filteredInactiveData.filter((item) =>
        isDepartureAirport.includes(item["departure_airport_format"])
      );
    }
    if (isArrivalAirport && isArrivalAirport.length !== 0) {
      filteredData = filteredData.filter((item) => isArrivalAirport.includes(item["destination_airport_format"]));
      filteredInactiveData = filteredInactiveData.filter((item) =>
        isArrivalAirport.includes(item["destination_airport_format"])
      );
    }
    if (isAircraftType && isAircraftType.length !== 0) {
      filteredData = filteredData.filter((item) => isAircraftType.includes(item["Type"]));
      filteredInactiveData = filteredInactiveData.filter((item) => isAircraftType.includes(item["Type"]));
    }
    setAircraftData(filteredData);
    setAircraftDataInactive(filteredInactiveData);
  };

  // Filtering
  useEffect(() => {
    isSearchingAndFiltering();
    //eslint-disable-next-line
  }, [
    initialAircratfData,
    isSearchInput,
    isOperator,
    isFaultPriority,
    isDepartureAirport,
    isArrivalAirport,
    isAircraftType,
    inactiveAircraftData,
    isLoading,
  ]);

  useEffect(() => {
    const querySearch = getQueryVariable("search");
    const queryAircraftType = getQueryVariable("at");
    const queryOperator = getQueryVariable("op");
    const queryFaultPriority = getQueryVariable("fp");
    const queryDepartureAirport = getQueryVariable("da");
    const queryArrivalAirport = getQueryVariable("aa");
    // const queryAcTab = getQueryVariable("actab");

    if (querySearch !== false) {
      setIsSearchInput(querySearch);
    }
    if (queryAircraftType !== false) {
      setIsAircraftType(queryAircraftType);
    }
    if (queryOperator !== false) {
      setIsOperator(queryOperator);
    }
    if (queryFaultPriority !== false) {
      setIsFaultPriority(queryFaultPriority);
    }
    if (queryDepartureAirport !== false) {
      setIsDepartureAirport(queryDepartureAirport);
    }
    if (queryArrivalAirport !== false) {
      setIsArrivalAirport(queryArrivalAirport);
    }
    setIsQuery(true);
    isSearchingAndFiltering();
    // eslint-disable-next-line
  }, [isQuery, initialAircratfData, inactiveAircraftData, isLoading]);

  useEffect(() => {
    const initialOperator = uniqueList(initialAircratfData, "Operator");
    setCountAoc(initialOperator.length);
  }, [initialAircratfData]);

  useEffect(() => {
    if (initial) {
      setAircraftData(sortByValue("priority_level", "desc"));
    }
    // eslint-disable-next-line
  }, [initial]);

  useEffect(() => {
    if (queryAcTab[0] === "2") setActiveTab(2);
    // eslint-disable-next-line
  }, [isLoading]);

  const handleCheck = (e) => {
    setTimeZoneAfter("");
    setTimeZoneBefore(`"${timeZone}"`);
  };

  const handleUncheck = (e) => {
    setTimeZoneAfter("'UTC'");
    setTimeZoneBefore("");
  };

  // const handleChangeTime = (event) => {
  //   setLocalTime(event.target.checked);
  //   event.target.checked ? handleCheck() : handleUncheck();
  // };

  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
    if (nextChecked) {
      handleCheck();
      setLocalTime(true);
    } else {
      handleUncheck();
      setLocalTime(false);
    }
  };

  const handleChangeAOG = (nextChecked) => {
    setCheckedAOG(nextChecked);
  };

  // Creating a skeleton loader
  const skeletonLoader = (numberOfSkeleton) => {
    let skeletonArray = [];
    for (let i = 0; i < numberOfSkeleton; i++) {
      skeletonArray.push(
        <Grid
          item
          sx={{
            height: "200px",
            width: "198px",
            margin: "10px 12px 10px 10px",
          }}
        >
          <Skeleton key={i} animation="wave" height={332} sx={{ marginTop: "-84px", borderRadius: "8px" }} />
        </Grid>
      );
    }
    return skeletonArray;
  };

  // Set sorting attribute
  // eslint-disable-next-line
  const handleSortBy = (e) => {
    const sortBy = e.target.value;
    setIsSortBy(sortBy);
    HandleSorting(sortBy);
  };

  // Set sorting order
  const sortByValue = (key, order) => {
    if (key.length === 0 || key === "") key = "priority_level";
    setAircraftData(
      aircraftData.sort((a, b) => {
        if (order === "asc") {
          return a[key] < b[key] ? 1 : -1;
        } else {
          return a[key] > b[key] ? 1 : -1;
        }
      })
    );
    setAircraftDataInactive(
      aircraftDataInactive.sort((a, b) => {
        if (order === "asc") {
          return a[key] < b[key] ? 1 : -1;
        } else {
          return a[key] > b[key] ? 1 : -1;
        }
      })
    );
  };

  // Handle sorting attribute and order
  const HandleSorting = (col) => {
    sortByValue(isSortBy, isSorting);
  };

  useEffect(() => {
    sortByValue(isSortBy, isSorting);
    if (isSortBy.length === 0) {
      setAircraftData(initialAircratfData);
      setAircraftDataInactive(initialInactiveAircratfData);
    }
    // eslint-disable-next-line
  }, [isSortBy, isSorting, initialAircratfData, initialInactiveAircratfData]);

  const handleClickCardView = (e) => {
    let query = "/query?";
    if (isSearchInput && isSearchInput !== "") query += "&search=" + isSearchInput;
    if (isAircraftType && isAircraftType !== "All") {
      query += "&at=" + isAircraftType;
      setActiveFilters({ ...activeFilters, isAircraftType: isAircraftType });
    }
    if (isOperator && isOperator !== "All") {
      query += "&op=" + isOperator;
      setActiveFilters({ ...activeFilters, isOperator: isOperator });
    }
    if (isFaultPriority && isFaultPriority !== "All") {
      query += "&fp=" + isFaultPriority;
      setActiveFilters({ ...activeFilters, isFaultPriority: isFaultPriority });
    }
    if (isDepartureAirport && isDepartureAirport !== "All") {
      query += "&da=" + isDepartureAirport;
      setActiveFilters({ ...activeFilters, isDepartureAirport: isDepartureAirport });
    }
    if (isArrivalAirport && isArrivalAirport !== "All") {
      query += "&aa=" + isArrivalAirport;
      setActiveFilters({ ...activeFilters, isArrivalAirport: isArrivalAirport });
    }
    dispatch(setFilters(activeFilters));
    dispatch(setKeyword(isSearchInput));
    history.push(`/fleet/aircraft-status/${e}/failure-messages` + query, { from: location.pathname });
  };

  // Function to get the card view contents
  function getStatusOverview(id, data) {
    let faultMessages = initialAllFaultsMessages?.filter((e) => e.AC_REG === id);
    faultMessages = faultMessages.sort((a, b) => priority.indexOf(a.priority) - priority.indexOf(b.priority));
    let highPriority = faultMessages.filter((e) => e.priority === "High");
    let mediumPriority = faultMessages.filter((e) => e.priority === "Medium");
    let lowPriority = faultMessages.filter((e) => e.priority === "Low");
    let otherPriority = faultMessages.filter(
      (e) => e.priority !== "High" && e.priority !== "Medium" && e.priority !== "Low"
    ).length;

    let status = chkCurrentStatus(id);

    return (
      <React.Fragment>
        <div style={{ padding: "14px", fontSize: "14px", fontFamily: "Gotham-Book" }}>
          <div style={{ fontFamily: "'Gotham-Medium'", marginBottom: "12px" }}>General Info</div>
          <Grid container columns={2} sx={{ marginBottom: "16px" }}>
            <Grid item xs={1.2}>
              <span style={{ fontFamily: "Gotham-Medium" }}>{id}</span> ({data.Type}-{data.Model})
            </Grid>
            <Grid item xs={0.8}>
              {data.MSN}
            </Grid>
          </Grid>
          {(status === "AOG" || status === "Maintenance") && (
            <Grid container columns={2} sx={{ marginBottom: "16px" }}>
              <Grid item xs={1.2} sx={{ paddingRight: "6px", fontFamily: "Gotham-Medium" }}>
                {status === "AOG" ? (
                  <span>Aircraft on Ground (AOG)</span>
                ) : status === "Maintenance" ? (
                  <span>Aircraft on Maintenance (MTC)</span>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={0.8} sx={{ fontFamily: "Gotham-Medium" }}>
                ETS: {chkEts(id)}
              </Grid>
            </Grid>
          )}
          <div className={styles.line}></div>
          <div style={{ fontFamily: "'Gotham-Medium'", marginBottom: "12px" }}>OOOI Message</div>
          <div style={{ fontFamily: "'Gotham-Medium'", width: "50px", float: "left", marginBottom: "8px" }}>OUT</div>
          <div style={{ fontFamily: "'Gotham-Book'", marginBottom: "8px" }}>
            {data.out_datetime_format !== null ? ConvertUTCDatetime(data.out_datetime_format, localTime) : "n/a"}
          </div>
          <div style={{ fontFamily: "'Gotham-Medium'", width: "50px", float: "left", marginBottom: "8px" }}>OFF</div>
          <div style={{ fontFamily: "'Gotham-Book'", marginBottom: "8px" }}>
            {data.off_datetime_format !== null ? ConvertUTCDatetime(data.off_datetime_format, localTime) : "n/a"}
          </div>
          <div style={{ fontFamily: "'Gotham-Medium'", width: "50px", float: "left", marginBottom: "8px" }}>ON</div>
          <div style={{ fontFamily: "'Gotham-Book'", marginBottom: "8px" }}>
            {data.on_datetime_format !== null ? ConvertUTCDatetime(data.on_datetime_format, localTime) : "n/a"}
          </div>
          <div style={{ fontFamily: "'Gotham-Medium'", width: "50px", float: "left" }}>IN</div>
          <div style={{ fontFamily: "'Gotham-Book'" }}>
            {data.in_datetime_format !== null ? ConvertUTCDatetime(data.in_datetime_format, localTime) : "n/a"}
          </div>
          <div
            style={{
              fontFamily: "'Gotham-Medium'",
              marginTop: "16px",
              marginBottom: "12px",
            }}
          >
            Fault Messages
          </div>
          {/* {faultMsg !== null && faultMsg?.length > 0 ? (
            faultMsg.map((item, index) => {
              return (
                <>
                  <Grid container spacing={1} columns={6}>
                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1} style={{ display: "flex", alignItems: "center" }}>
                      {getFleetCard(item.priority)}
                    </Grid>
                    <Grid item xs={5} sm={5} md={5} lg={5} xl={5} style={{ display: "flex", alignItems: "center" }}>
                      {item.text}
                    </Grid>
                  </Grid>
                </>
              );
            })
          ) : ( */}
          <div style={{ fontFamily: "'Gotham-Book'", fontSize: "14px", marginBottom: "16px" }}>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={5}>
                <p>
                  High:{" "}
                  <span className="gotham-medium" style={{ color: highPriority.length > 0 ? "#FC3F3F" : "" }}>
                    {highPriority.length}
                  </span>
                </p>
              </Grid>
              <Grid item xs={8} style={{ textAlign: "center" }}>
                <p>
                  Medium:{" "}
                  <span className="gotham-medium" style={{ color: mediumPriority.length > 0 ? "#FFBC40" : "" }}>
                    {mediumPriority.length}
                  </span>
                </p>
              </Grid>
              <Grid item xs={5} style={{ textAlign: "center" }}>
                <p>
                  Low:{" "}
                  <span className="gotham-medium" style={{ color: lowPriority.length > 0 ? "#F2F7FF" : "" }}>
                    {lowPriority.length}
                  </span>
                </p>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "center" }}>
                <p>
                  Others:{" "}
                  <span className="gotham-medium" style={{ color: mediumPriority.length > 0 ? "#FFFFFF" : "" }}>
                    {otherPriority}
                  </span>
                </p>
              </Grid>
            </Grid>
          </div>
          {faultMessages.length > 0 ? (
            <div style={{ fontFamily: "'Gotham-Book'", marginBottom: "16px", fontSize: "12px" }}>
              <Grid container spacing={1} columns={3}>
                {faultMessages.slice(0, 3).map((item, index) => {
                  return (
                    <>
                      <Grid
                        item
                        xs={0.3}
                        sm={0.3}
                        md={0.3}
                        lg={0.3}
                        xl={0.3}
                        style={{ display: "flex", alignItems: "center", marginBottom: "4px" }}
                      >
                        {toolTipsPriorityIcon(item.priority, item.text)}
                      </Grid>
                      <Grid
                        item
                        xs={2.7}
                        sm={2.7}
                        md={2.7}
                        lg={2.7}
                        xl={2.7}
                        style={{ display: "flex", alignItems: "center", marginBottom: "4px" }}
                      >
                        <span
                          style={{
                            width: "auto",
                            whiteSpace: "nowrap",
                            overflow: "hidden !important",
                            textOverflow: "ellipsis",
                            paddingTop: "5px",
                          }}
                        >
                          {item.text}
                        </span>
                      </Grid>
                    </>
                  );
                })}
              </Grid>
            </div>
          ) : (
            <></>
          )}
          {faultMessages.length > 0 ? (
            <div style={{ fontFamily: "'Gotham-Book'", marginBottom: "16px", fontSize: "12px" }}>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  handleClickCardView(data.AC_REG);
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                View {faultMessages.length > 3 ? faultMessages.length - 3 : ""} more messages&nbsp;&nbsp;
                <img src={ArrowRight} alt="ArrowRight" />
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* )} */}
          {/* <div style={{ fontFamily: "'Gotham-Book'", marginTop: "21px" }}>Data unavailable</div> */}
          <div style={{ fontFamily: "'Gotham-Book'" }}>
            {data.smi !== null
              ? ConvertUTCDatetime(data.telex_gen_datetime_format, localTime) +
                " / " +
                ConvertUTCDatetime(data.telex_sent_datetime_format, localTime)
              : ""}
          </div>
          <div
            style={{
              borderTop: "1px solid rgba(217, 217, 217, 0.5)",
              fontFamily: "Gotham-Medium",
              paddingTop: "16px",
              marginTop: "16px",
              display: "none",
            }}
          >
            <Grid container columns={2}>
              <Grid item xs={1}>
                AOG
              </Grid>
              <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Switch
                  onChange={handleChangeAOG}
                  checked={checkedAOG}
                  className="react-switch"
                  height={22}
                  width={40}
                  offColor="#D9D9D9"
                  onColor="#088FD1"
                  handleDiameter={16}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </React.Fragment>
    );
  }

  const NoFlightAvailable = () => {
    return (
      // <Grid container>
      //   <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", marginTop: "10%", textAlign: "center" }}>
      <>
        <div style={{ marginTop: "5%", marginBottom: "10%", textAlign: "center" }}>
          <div style={{ marginBottom: "16px" }}>
            <img src={DataUnavailable} alt="data-unavailable" />
          </div>
          <div style={{ fontFamily: "Gotham-Medium", fontSize: "14px" }}>Data Unavailable</div>
          <div style={{ fontFamily: "Gotham-Book", fontSize: "12px" }}>
            There is no data found. Please try a different search term.
          </div>
        </div>
      </>
      //   </Grid>
      // </Grid>
    );
  };

  function chkCurrentStatus(id) {
    let array = isAircraftStatus.filter((e) => e.aircraftReg === id);
    if (array.length > 0) {
      return array[0].status;
    }
  }

  function chkEts(id) {
    let array = isAircraftStatus.filter((e) => e.aircraftReg === id);

    if (array[0]?.tba || array[0]?.ets === null || array[0]?.ets === "") return "TBA";
    else return formatDateDasr(array[0]?.ets) + ", " + array[0]?.etsTime?.split("T")[1].substring(0, 5);
  }

  const CardView = () => {
    return !isLoading && aircraftData.length === 0 ? (
      <NoFlightAvailable />
    ) : (
      <div className={`row ${styles.cardViewDiv}`}>
        {aircraftData &&
          aircraftData.map((data, index) => {
            const options = {
              month: "short",
              day: "numeric",
            };
            const aircraft_reg_no = data.AC_REG;
            let EtaDateTime = data.eta_datetime_format_view;
            let EtaUTC = new Date(data.eta_datetime_format + "Z");
            if (data.on_datetime !== null) {
              EtaDateTime = data.on_datetime_format_view;
              EtaUTC = new Date(data.on_datetime_format + "Z");
            }
            if (localTime) {
              const DateUTC = EtaUTC.toLocaleString("en-US", options).split(",")[0];
              const TimeUTC = EtaUTC.toLocaleString().split(",")[1];
              if (EtaDateTime !== null) {
                EtaDateTime =
                  DateUTC?.split(" ")[0] +
                  ", " +
                  DateUTC?.split(" ")[1].padStart(2, "0") +
                  " " +
                  TimeUTC?.split(":")[0] +
                  ":" +
                  TimeUTC?.split(":")[1];
              }
            }

            let status = chkCurrentStatus(data.AC_REG);

            // Get timezone offset in hours
            // const offset = new Date().getTimezoneOffset() / 60;
            // const timeZoneOffset = offset < 0 ? "+" + Math.abs(offset) : "-" + offset;
            return (
              <div
                className={`${styles.cardView} ${getCardViewStatus(data.priority)}`}
                key={index}
                style={{ border: status === "AOG" ? "1px solid red" : "" }}
              >
                <StyledTooltip
                  title={getStatusOverview(aircraft_reg_no, data)}
                  placement="right-start"
                  enterDelay={500}
                  key={index}
                  followCursor={false}
                  classes={{ tooltip: classes.cardViewTootltip }}
                  // onClick={(e) => e.preventDefault()}
                >
                  <div
                    data-for="statusOverview"
                    onClick={(e) => {
                      e.preventDefault();
                      handleClickCardView(data.AC_REG);
                    }}
                  >
                    <div
                      className={`row ${
                        status === "AOG" ? styles.bgLineAOG : status === "Maintenance" ? styles.bgLineMaintenance : ""
                      }`}
                    >
                      <div className="col-12">
                        <div className="row" style={{ padding: "6px 8px 0px 8px" }}>
                          <div className="col-2">{getFleetCard(data.priority, data.text)}</div>
                          <div className="col-6" style={{ paddingLeft: "18px" }}>
                            <div className="row">
                              <div className={`col ${styles.textPrimary}`} style={{ marginLeft: "14px" }}>
                                {data.AC_REG}
                              </div>
                            </div>
                            <div className="row" style={{ marginTop: "-3px" }}>
                              <div className={`col ${styles.textSecondary}`}>{data.Type}</div>
                            </div>
                          </div>
                          <div className="col-4" style={{ marginTop: "-3px" }}>
                            <div className="row">&nbsp;</div>
                            <div className="row">
                              <div className={`col ${styles.textSecondary}`} style={{ textAlign: "right" }}>
                                {data.Operator === "AA" ? "MAA" : data.Operator}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ padding: "0px 8px" }}>
                      <div className={`${styles.borderBottom}`}></div>
                      <div className="d-flex" style={{ width: "100%", marginLeft: "0px" }}>
                        <div className={`mr-auto ${styles.textPrimary}`}>{data.flight_no}</div>
                        <div className={``}>{getOOOI(data)}</div>
                        <div className={`${styles.textPrimary}`}>{getOOOIStatus(data)}</div>
                      </div>
                      <div className="d-flex" style={{ width: "100%", marginLeft: "0px", marginTop: "0px" }}>
                        <div className={`mr-auto ${styles.textPrimaryBook}`}>{data.departure_airport_format}</div>
                        <div className={`${styles.textPrimaryBook}`}>{data.destination_airport_format}</div>
                      </div>
                      <BorderLinearProgress variant="determinate" value={calculatePercentage(data)} />
                      <div className={`${styles.etaBox} ${styles.textSecondary}  ${styles.etaBoxText}`}>
                        {data.on_datetime !== null ? "ARR:" : "ETA:"} {EtaDateTime || "--:--"}{" "}
                        {EtaDateTime ? (localTime ? "(" + timeZone + ")" : "(UTC)") : ""}
                      </div>
                    </div>
                  </div>
                </StyledTooltip>
                {/* original column: 6 */}
                <Grid container columns={5} sx={{ marginTop: "3px" }}>
                  <Grid item xs={5} sx={{ display: "flex", justifyContent: "flex-end", paddingRight: "8px" }}>
                    {status === "AOG" ? (
                      <img src={IconAOG} alt="icon-aog" height={20} style={{ marginTop: "2px" }} />
                    ) : status === "Maintenance" ? (
                      <img src={IconMaintenance} alt="icon-maintenance" height={20} style={{ marginTop: "2px" }} />
                    ) : (
                      ""
                    )}
                    {getACMS(data.acms_priority)}
                  </Grid>
                  {/* <Grid item xs={1} s={{ display: "flex", justifyContent: "center" }}>
                    {getPredictive(data.predictive)}
                  </Grid> */}
                </Grid>
                {/* <div className="d-flex justify-content-end" style={{ marginTop: "3px" }}>
                  <div>{getACMS(data.acms_priority)}</div>
                  <div>{getPredictive(data.predictive)}</div>
                  <div>{getUplink(data.uplink)}</div>
                </div> */}
              </div>
            );
          })}
      </div>
    );
  };

  const InactiveCardView = () => {
    return isLoading ? (
      <>
        <Grid container spacing={1}>
          {skeletonLoader(12)}
        </Grid>
      </>
    ) : aircraftDataInactive.length === 0 ? (
      <NoFlightAvailable />
    ) : (
      <div className={`row ${styles.cardViewDiv}`}>
        {aircraftDataInactive &&
          aircraftDataInactive.map((data, index) => {
            const options = {
              month: "short",
              day: "numeric",
            };
            let EtaDateTime = data.eta_datetime_format_view;
            if (localTime && EtaDateTime !== null && EtaDateTime !== undefined) {
              const EtaUTC = new Date(data.eta_datetime_format + "Z");
              const DateUTC = EtaUTC.toLocaleString("en-US", options).split(",")[0];
              const TimeUTC = EtaUTC.toLocaleString().split(",")[1];
              if (EtaDateTime !== null) {
                EtaDateTime =
                  DateUTC?.split(" ")[0] +
                  ", " +
                  DateUTC?.split(" ")[1].padStart(2, "0") +
                  " " +
                  TimeUTC?.split(":")[0] +
                  ":" +
                  TimeUTC?.split(":")[1];
              }
            }
            let status = chkCurrentStatus(data.AC_REG);

            return (
              <div
                className={`${styles.cardView} ${getCardViewStatus(data.priority)}`}
                key={index}
                style={{ border: status === "AOG" ? "1px solid red" : "" }}
              >
                <div
                  data-for="statusOverview"
                  onClick={(e) => {
                    e.preventDefault();
                    handleClickCardView(data.AC_REG);
                  }}
                >
                  <div
                    className={`row ${
                      status === "AOG" ? styles.bgLineAOG : status === "Maintenance" ? styles.bgLineMaintenance : ""
                    }`}
                  >
                    <div className="col-12">
                      <div className="row" style={{ padding: "6px 8px 0px 8px" }}>
                        <div className="col-2">{getFleetCard(data.priority, data.text)}</div>
                        <div className="col-6" style={{ paddingLeft: "18px" }}>
                          <div className="row">
                            <div className={`col ${styles.textPrimary}`} style={{ marginLeft: "14px" }}>
                              {data.AC_REG}
                            </div>
                          </div>
                          <div className="row" style={{ marginTop: "-3px" }}>
                            <div className={`col ${styles.textSecondary}`}>{data.Type}</div>
                          </div>
                        </div>
                        <div className="col-4" style={{ marginTop: "-3px" }}>
                          <div className="row">&nbsp;</div>
                          <div className="row">
                            <div className={`col ${styles.textSecondary}`} style={{ textAlign: "right" }}>
                              {data.Operator === "AA" ? "MAA" : data.Operator}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ padding: "0px 8px" }}>
                    <div className={`${styles.borderBottom}`}></div>
                    <div className="d-flex" style={{ width: "100%", marginLeft: "0px" }}>
                      <div className={`mr-auto ${styles.textPrimary}`}>{data.flight_no || "--"}</div>
                      <div className={``}>{getOOOI(data)}</div>
                      <div className={`${styles.textPrimary}`}>{getOOOIStatus(data) || "--"}</div>
                    </div>
                    <div className="d-flex" style={{ width: "100%", marginLeft: "0px", marginTop: "0px" }}>
                      <div className={`mr-auto ${styles.textPrimaryBook}`}>{data.departure_airport_format || "--"}</div>
                      <div className={`${styles.textPrimaryBook}`}>{data.destination_airport_format || "--"}</div>
                    </div>
                    <BorderLinearProgress variant="determinate" value={calculatePercentage(data)} />
                    <div className={`${styles.etaBox} ${styles.textSecondary}  ${styles.etaBoxText}`}>
                      {data.on_datetime !== null ? "ARR:" : "ETA:"} {EtaDateTime || "--:--"}{" "}
                      {EtaDateTime ? (localTime ? "(" + timeZone + ")" : "(UTC)") : ""}
                    </div>
                  </div>
                </div>
                <Grid container columns={5} sx={{ marginTop: "3px" }}>
                  <Grid item xs={5} sx={{ display: "flex", justifyContent: "flex-end", paddingRight: "8px" }}>
                    {status === "AOG" ? (
                      <img src={IconAOG} alt="icon-aog" height={20} style={{ marginTop: "2px" }} />
                    ) : status === "Maintenance" ? (
                      <img src={IconMaintenance} alt="icon-maintenance" height={20} style={{ marginTop: "2px" }} />
                    ) : (
                      ""
                    )}
                    {getACMS(data.acms_priority)}
                  </Grid>
                  {/* <Grid item xs={1} s={{ display: "flex", justifyContent: "center" }}>
                    {getPredictive(data.predictive)}
                  </Grid> */}
                </Grid>
              </div>
            );
          })}
      </div>
    );
  };

  const FilterBox = () => {
    return (
      <div className={`${styles.filterBox} ${showFilter}`} ref={wrapperRef}>
        <div style={{ backgroundColor: "#F7F7F7", minHeight: `${minHeight}` }}>&nbsp;</div>
        <div className="row">
          <div className="col-1">
            <i className={`fa ${styles.faFilterDark}`}></i>
          </div>
          <div className="col-1">
            <div style={{ fontFamily: "Gotham-Medium", fontSize: "18px", marginTop: "-3px" }}>Filter</div>
          </div>
          <div className="col-1 offset-8">
            <i className={`fa ${styles.faCloseIcon}`} onClick={() => handleShowFilter()}></i>
          </div>
        </div>
        <div className="row" style={{ marginTop: "14px" }}>
          <div className="col">
            <div className={`${styles.inputLabel}`}>Operator</div>
            <select
              className={`form-control ${styles.selectSearch} ${styles.selectSearchFilter}`}
              value={isOperator}
              onChange={(e) => setIsOperator(e.target.value)}
              multiple={true}
            >
              <option>All</option>
              {createOptions(activeTab === 1 ? initialAircratfData : inactiveAircraftData, "Operator")}
            </select>
          </div>
        </div>
        <div className="row" style={{ marginTop: "14px" }}>
          <div className="col">
            <div className={`${styles.inputLabel}`}>Fault Priority</div>
            <select
              className={`form-control ${styles.selectSearch} ${styles.selectSearchFilter}`}
              value={isFaultPriority}
              onChange={(e) => setIsFaultPriority(e.target.value)}
              multiple={true}
            >
              <option>All</option>
              {createOptions(activeTab === 1 ? initialAircratfData : inactiveAircraftData, "priority")}
            </select>
          </div>
        </div>
        <div className="row" style={{ marginTop: "14px" }}>
          <div className="col">
            <div className={`${styles.inputLabel}`}>Departure Airport</div>
            <select
              className={`form-control ${styles.selectSearch} ${styles.selectSearchFilter}`}
              value={isDepartureAirport}
              onChange={(e) => setIsDepartureAirport(e.target.value)}
              multiple={true}
            >
              <option>All</option>
              {createOptions(activeTab === 1 ? initialAircratfData : inactiveAircraftData, "departure_airport_format")}
            </select>
          </div>
        </div>
        <div className="row" style={{ marginTop: "14px" }}>
          <div className="col">
            <div className={`${styles.inputLabel}`}>Arrival Airport</div>
            <select
              className={`form-control ${styles.selectSearch} ${styles.selectSearchFilter}`}
              value={isArrivalAirport}
              onChange={(e) => setIsArrivalAirport(e.target.value)}
              multiple={true}
            >
              <option>All</option>
              {createOptions(
                activeTab === 1 ? initialAircratfData : inactiveAircraftData,
                "destination_airport_format"
              )}
            </select>
          </div>
        </div>
        <div className="row" style={{ marginTop: "14px" }}>
          <div className="col">
            <div className={`${styles.inputLabel}`}>Aircraft Type</div>
            <select
              className={`form-control ${styles.selectSearch} ${styles.selectSearchFilter}`}
              value={isAircraftType}
              onChange={(e) => setIsAircraftType(e.target.value)}
              multiple={true}
            >
              <option>All</option>
              {createOptions(activeTab === 1 ? initialAircratfData : inactiveAircraftData, "Type")}
            </select>
          </div>
        </div>
        <div className="row" style={{ marginTop: "14px" }}>
          <div className="col" key="col">
            <div className={`${styles.inputLabel}`}>ACMS Priority</div>
            <select
              className={`form-control ${styles.selectSearch} ${styles.selectSearchFilter}`}
              defaultValue={isAcms}
            >
              <option>All</option>
              {createOptions(activeTab === 1 ? initialAircratfData : inactiveAircraftData, "acms")}
            </select>
          </div>
        </div>
        <div className="row" style={{ marginTop: "14px" }}>
          <div className="col" key="col">
            <div className={`${styles.inputLabel}`}>OOOI Status</div>
            <select className={`form-control ${styles.selectSearch} ${styles.selectSearchFilter}`}>
              <option>All</option>
            </select>
          </div>
        </div>
        <div className="row" style={{ marginTop: "14px" }}>
          <div className="col" key="col">
            <div className={`${styles.inputLabel}`}>Last Active</div>
            <select className={`form-control ${styles.selectSearch} ${styles.selectSearchFilter}`}>
              <option>72 hours</option>
            </select>
          </div>
        </div>
      </div>
    );
  };

  const ViewBox = () => {
    return (
      <div
        style={{
          marginTop: "33px",
          marginLeft: isMobile ? "60px" : "29px",
          paddingBottom: "40px",
          width: "100%",
        }}
      >
        <Grid container columns={12} style={{ marginBottom: "23px" }}>
          <Grid item xs={8} sx={{ paddingLeft: "10px" }}>
            <div className={`${activeTab === 1 ? styles.activeAircraftTab : styles.inactiveAircraftTab}`}>
              <p onClick={(e) => handleActiveTab(1)}>
                Active Aircraft
                {aircraftData?.length > 0 && <span className={`${styles.countTooltip}`}>{aircraftData?.length}</span>}
              </p>
            </div>
            <div className={`${activeTab === 2 ? styles.activeAircraftTab : styles.inactiveAircraftTab}`}>
              <p onClick={(e) => handleActiveTab(2)}>
                Aircraft Without Leg Within {hours} Hours
                {aircraftDataInactive?.length > 0 && (
                  <span className={`${styles.countTooltip}`}>{aircraftDataInactive?.length}</span>
                )}
              </p>
            </div>
          </Grid>
          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            sx={{ display: isMobile ? "none" : "flex", justifyContent: "flex-end" }}
          >
            <span style={{ marginRight: "10px", marginTop: "0px" }}>Time Zone</span>{" "}
            <Switch
              onChange={handleChange}
              checked={checked}
              className="react-switch"
              height={22}
              width={64}
              offColor="#088FD1"
              onColor="#394458"
              handleDiameter={16}
              uncheckedIcon={
                <div
                  style={{
                    color: "#ffffff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    marginRight: "8px",
                    fontFamily: "Gotham-Book",
                    fontSize: "12px",
                  }}
                >
                  UTC
                </div>
              }
              checkedIcon={
                <div
                  style={{
                    color: "#ffffff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    marginLeft: "15px",
                    fontFamily: "Gotham-Book",
                    fontSize: "12px",
                  }}
                >
                  {timeZone}
                </div>
              }
            />
          </Grid>
        </Grid>
        {/* {isLoading && Loader()} */}
        {activeTab === 1 ? <CardView /> : <InactiveCardView />}
      </div>
    );
  };

  function addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }

  let paddingRight = "54px";
  if (isAdvanceFilter && openLegend === "false") paddingRight = "304px";

  return (
    <>
      {isLoading && <LinearProgressBar />}
      <div
        style={{ overflow: "hidden", marginLeft: "30px", paddingRight: `${paddingRight}`, backgroundColor: "#ffffff" }}
        id="main"
        className={`${styles.container}`}
      >
        {/* {SearchBox()} */}
        <SearchBar
          isSearchInput={isSearchInput}
          setIsSearchInput={setIsSearchInput}
          data={activeTab === 1 ? initialAircratfData : inactiveAircraftData}
          isAircraftType={isAircraftType}
          setIsAircraftType={setIsAircraftType}
          isOperator={isOperator}
          setIsOperator={setIsOperator}
          isFaultPriority={isFaultPriority}
          setIsFaultPriority={setIsFaultPriority}
          isDepartureAirport={isDepartureAirport}
          setIsDepartureAirport={setIsDepartureAirport}
          isArrivalAirport={isArrivalAirport}
          setIsArrivalAirport={setIsArrivalAirport}
          isSortBy={isSortBy}
          setIsSortBy={setIsSortBy}
          isSorting={isSorting}
          setIsSorting={setIsSorting}
          countAoc={countAoc}
          airportList={airportList}
          isAdvanceFilter={isAdvanceFilter}
          setIsAdvanceFilter={setIsAdvanceFilter}
        />
        {(isSearchInput?.length > 0 ||
          isAircraftType.length > 0 ||
          isOperator.length > 0 ||
          isFaultPriority.length > 0 ||
          isDepartureAirport.length > 0 ||
          isArrivalAirport.length > 0 ||
          isSortBy.length > 0) && (
          <ActiveFilters
            isSearchInput={isSearchInput}
            setIsSearchInput={setIsSearchInput}
            isAircraftType={isAircraftType}
            setIsAircraftType={setIsAircraftType}
            isOperator={isOperator}
            setIsOperator={setIsOperator}
            isFaultPriority={isFaultPriority}
            setIsFaultPriority={setIsFaultPriority}
            isDepartureAirport={isDepartureAirport}
            setIsDepartureAirport={setIsDepartureAirport}
            isArrivalAirport={isArrivalAirport}
            setIsArrivalAirport={setIsArrivalAirport}
            isSortBy={isSortBy}
            setIsSortBy={setIsSortBy}
            isSorting={isSorting}
            setIsSorting={setIsSorting}
            airportList={airportList}
            setInitial={setInitial}
            filter={elevadeActiveFilter.AIRCRAFT_MONITORING}
          />
        )}
        <AircraftMonitoringSummary
          data={activeTab === 1 ? aircraftData : aircraftDataInactive}
          opr={isOperator}
          AOC={AOC}
        />
        <ViewBox />
        <FilterBox />
        <AdvanceFilter
          data={activeTab === 1 ? initialAircratfData : inactiveAircraftData}
          airportList={airportList}
          hours={hours}
          setHours={setHours}
          isAdvanceFilter={isAdvanceFilter}
          setIsAdvanceFilter={setIsAdvanceFilter}
          isSearchInput={isSearchInput}
          setIsSearchInput={setIsSearchInput}
          isAircraftType={isAircraftType}
          setIsAircraftType={setIsAircraftType}
          isOperator={isOperator}
          setIsOperator={setIsOperator}
          isFaultPriority={isFaultPriority}
          setIsFaultPriority={setIsFaultPriority}
          isDepartureAirport={isDepartureAirport}
          setIsDepartureAirport={setIsDepartureAirport}
          isArrivalAirport={isArrivalAirport}
          setIsArrivalAirport={setIsArrivalAirport}
        />
        {isLoading && <AirplaneLoader />}
      </div>

      {lastModifiedDT ? (
        <div
          className="lastUpdated"
          style={{
            backgroundColor: "#FFFFFF",
            padding: !isMobile ? "8px 0 8px 32px" : "8px 0 8px 50px",
            position: "sticky",
            bottom: 0,
            fontStyle: "italic",
            fontSize: "12px",
            color: "#838383",
            overflow: "hidden",
            // width: "80%",
          }}
        >
          <span>&#42;Data displayed as of </span>
          {localTime ? (
            <>
              <span>
                {addZero(lastModifiedDT?.getHours()) +
                  ":" +
                  addZero(lastModifiedDT?.getMinutes()) +
                  ":" +
                  addZero(lastModifiedDT?.getSeconds())}{" "}
              </span>
              <span>
                {lastModifiedDT?.toLocaleString("default", { month: "short" }) +
                  " " +
                  addZero(lastModifiedDT?.getDate()) +
                  ", " +
                  lastModifiedDT?.getFullYear()}
              </span>
            </>
          ) : (
            <>
              <span>
                {addZero(lastModifiedDT?.getUTCHours()) +
                  ":" +
                  addZero(lastModifiedDT?.getUTCMinutes()) +
                  ":" +
                  addZero(lastModifiedDT?.getUTCSeconds())}{" "}
              </span>
              <span>
                {monthAbv[lastModifiedDT?.getUTCMonth()] +
                  " " +
                  addZero(lastModifiedDT?.getUTCDate()) +
                  ", " +
                  lastModifiedDT?.getUTCFullYear()}
              </span>
            </>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default AircraftMonitoring;
