import React from "react";
import IconAssignee from "common/images/icons/elevade/Filter-operator.svg";
import IconAssigneeActive from "common/images/icons/elevade/Filter-operator-active.svg";
import IconAtaChapter from "common/images/icons/elevade/BookOpen.svg";
import IconAtaChapterActive from "common/images/icons/elevade/BookOpen-active.svg";
import IconStatus from "common/images/icons/elevade/Icon-Status.svg";
import IconStatusActive from "common/images/icons/elevade/Icon-Status-active.svg";
import IconACType from "common/images/icons/elevade/AirplaneTilt.svg";
import IconACTypeActive from "common/images/icons/elevade/AirplaneTilt-active.svg";
import IconStation from "common/images/icons/elevade/Icon-Station.svg";
import IconStationActive from "common/images/icons/elevade/Icon-Station-active.svg";
import IconCalendarBlank from "common/images/icons/elevade/CalendarBlank.svg";
import IconCalendarBlankActive from "common/images/icons/elevade/CalendarBlank-active.svg";
import IconDepartment from "common/images/icons/elevade/Filter-operator.svg";
import IconDepartmentActive from "common/images/icons/elevade/Filter-operator-active.svg";
import IconSummaryStatus from "common/images/icons/elevade/Icon-SummaryStatus.svg";
import IconSummaryStatusActive from "common/images/icons/elevade/Icon-SummaryStatusActive.svg";
import styles from "./FilterBox.module.css";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import FilterModal from "./FilterModal";

const FilterBox = ({
  open,
  setOpen,
  data,
  title,
  isFilters,
  setIsFilters,
  isLast,
  openTitle,
  setOpenTitle,
  disableFuture,
}) => {
  const handleClickAway = () => {
    // if (!title.includes("Date")) setOpen(false);
  };

  let key = "";
  if (title === "Operator") key = "fleet";
  else if (title === "ATA Chapter") key = "ata_head";
  else if (title === "Aircraft Reg") key = "aircraftReg";
  else if (title === "Station") key = "location";
  else if (title === "Department") key = "assignedTo";
  else if (title === "Raised Date") key = "raisedDate";
  else if (title === "Expiry Date") key = "expiryDate";
  else if (title === "Summary Status") key = "summaryStatus";

  function getIcon(title, active) {
    if (title === "Operator") return active ? IconAssigneeActive : IconAssignee;
    else if (title === "ATA Chapter") return active ? IconAtaChapterActive : IconAtaChapter;
    else if (title === "Aircraft Reg") return active ? IconACTypeActive : IconACType;
    else if (title === "Status") return active ? IconStatusActive : IconStatus;
    else if (title === "Station") return active ? IconStationActive : IconStation;
    else if (title === "Raised Date") return active ? IconCalendarBlankActive : IconCalendarBlank;
    else if (title === "Expiry Date") return active ? IconCalendarBlankActive : IconCalendarBlank;
    else if (title === "Department") return active ? IconDepartmentActive : IconDepartment;
    else if (title === "Summary Status") return active ? IconSummaryStatusActive : IconSummaryStatus;
  }
  const getFilterLabel = (data, title) => {
    const dataLength = data[key]?.length;
    const dataLengthLabel = data[key]?.length - 1;
    let dataLabel = "";
    let additionalLabel = "";
    if (dataLength > 0) {
      dataLabel = data[key][0];
      if (dataLength > 1) additionalLabel = ` +${dataLengthLabel}`;
      return dataLabel + additionalLabel;
    }

    return title;
  };

  function getFilterList(title) {
    let col = "";
    if (title === "Operator") col = "fleet";
    if (title === "ATA Chapter") col = "ata_head";
    if (title === "Aircraft Reg") col = "aircraftReg";
    if (title === "Station") col = "location";
    if (title === "Department") col = "assignedTo";
    let options = [];
    let option = [
      ...new Set(
        data &&
          data?.map((item) => {
            return item[col];
          })
      ),
    ];
    let sorted = option.sort();
    sorted.forEach((item) => {
      if (item !== null && item !== "" && item !== " ") {
        options.push(item);
      }
    });
    return options;
  }

  function handleOpenFilter(title) {
    if (title !== undefined) setOpenTitle(title);
    setOpen(true);
  }

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={`${styles.filterDivPosition} ${isFilters[key]?.length > 0 ? styles.active : ""}`}>
          <span className={`${styles.filterDiv}`} onClick={() => handleOpenFilter(title)}>
            <img
              src={getIcon(title, isFilters[key]?.length > 0 ? true : false)}
              height="16px"
              alt="filter-icon"
              className={styles.filterIcon}
            />
            <div style={{ marginTop: "4px" }}>{getFilterLabel(isFilters, title)}</div>
          </span>
          <FilterModal
            open={open}
            setOpen={setOpen}
            openTitle={openTitle}
            title={title}
            list={getFilterList(title)}
            isFilters={isFilters}
            setIsFilters={setIsFilters}
            isLast={isLast}
            disableFuture={disableFuture}
          />
        </div>
      </ClickAwayListener>
    </>
  );
};

export default FilterBox;
