import React, { useState, useEffect } from "react";
import { ExpandMore, ErrorOutlineRounded } from "@mui/icons-material";
import {
  Box,
  Container,
  Paper,
  Grid,
  TableBody,
  TableHead,
  TableCell,
  tableCellClasses,
  TableRow,
  TableContainer,
  Table,
  Select,
  MenuItem,
  Skeleton,
  Tooltip,
} from "@mui/material";
import styles from "./warningManagement.module.css";
import { makeAPIRequestRun, showNotification } from "common/helpers";
import { setAOCBasedOnPermission } from "../component/Helpers";
import { isEmpty } from "lodash";
import { styled, ThemeProvider, createTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import MUIPagination from "../component/Pagination";
import { ConfirmChangeModal } from "../aircraftMonitoring/ChangePriorityStatus";
import { useSelector } from "react-redux";
import SortIconAscGray from "common/images/icons/elevade/SortIconAscGray.svg";
import SortIconDescGray from "common/images/icons/elevade/SortIconDescGray.svg";
import SortIconAscDescGray from "common/images/icons/elevade/SortIconAscDescGray.svg";
import { AOCList, dateFnsFormats } from "common/constant";
import { format } from "date-fns";
import SearchBar from "./SearchBar";
import ActiveFilters from "./ActiveFilters";
import cloneDeep from "lodash/cloneDeep";
import Overlay from "../component/Overlay";
import IconErrorAirBalloon from "common/images/icons/elevade/Icon-ErrorAirBalloon.svg";

const StyledTableCell = styled(TableCell)({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F2F7FF",
    color: "#333",
    fontSize: 16,
    fontFamily: "Gotham-Medium",
    position: "start",
    "&:hover": {
      cursor: "pointer",
    },
    minWidth: "150px",
    height: "12px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    overflow: "hidden",
    whiteSpace: "nowrap",
    minWidth: "150px",
    height: "12px",
  },
});

const StickyTableCell = styled(TableCell)({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F2F7FF",
    color: "#333",
    fontSize: 16,
    fontFamily: "Gotham-Medium",
    position: "sticky",
    "&:hover": {
      cursor: "pointer",
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    position: "sticky",
    overflow: "hidden",
    // whiteSpace: "nowrap",
    // textOverflow: "ellipsis",
  },
});

const CustomExpandMore = styled(ExpandMore)({
  cursor: "default",
  "&:hover": {
    cursor: "pointer",
  },
  position: "absolute",
  right: 0,
  top: "calc(50% - 12px)",
  pointerEvents: "none",
});

const useStyles = makeStyles(() => ({
  tableRow: {
    hover: {
      backgroundColor: "#fcfcfc",
    },
  },
  ul: {
    "& .Mui-selected": {
      color: "#fff",
      backgroundColor: "#088FD1",
      border: "1px solid #088FD1",
      "&:hover": {
        backgroundColor: "#088FD1",
      },
    },
  },
  sortIcon: {
    // "&.MuiSvgIcon-root": {
    "&.MuiTableSortLabel-icon": {
      fontSize: "10px",
      color: "#808080",
      position: "relative",
    },
    // display: "flex",
    // alignItems: "flex-end",
    // justifyContent: "flex-end",
    // position: "absolute",
    // marginLeft: "10px",
    // },

    // activeSortIcon: {
    //   opacity: 1,
    // },
    // inactiveSortIcon: {
    //   opacity: 0,
    // },
  },
}));

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "14px",
          color: "#333333 ",
          backgroundColor: "#FFFEE8",
          boxShadow: "0px 3px 6px #00000029",
          borderRadius: "4px",
          opacity: 1,
          fontFamily: '"Gotham-Book", sans-serif',
        },
      },
    },
  },
});

const WarningManagement = () => {
  const { userAOC, userPermission, companyAOC } = useSelector((state) => state.userEntity);
  const [activeTab, setActiveTab] = useState(1);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [isSearchInput, setIsSearchInput] = useState("");
  const [ataChapter, setAtaChapter] = useState();
  const [condition, setCondition] = useState();
  const [faultPriority, setFaultPriorityn] = useState();
  const [totalRows, setTotalRows] = useState(0);
  const [togglePriority, setTogglePriority] = useState(false);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const [dialog, setDialog] = useState(false);
  const [newPriority, setNewPriority] = useState("null");
  const [priority, setPriority] = useState("");
  const [eventProp, setEventProp] = useState("");
  const [ataChapterProp, setAtaChapterProp] = useState("");
  const [definedFaults, setDefined] = useState(0);
  const [undefinedFaults, setUndefined] = useState(0);
  const [mouseX, setMouseX] = useState();
  const [column, setColumn] = useState();
  const [eventType, setEventType] = useState();
  const [oldValue, setOldValue] = useState("");
  const [isSearch, setIsSearch] = useState("");
  const [isFilters, setIsFilters] = useState({
    search: [],
    // operator: [],
    ata_head: [],
    condition: [],
    priority: [],
  });
  let AOC = companyAOC;
  AOC = setAOCBasedOnPermission(AOC, userPermission);
  const [operator, setOperator] = useState(
    Array.isArray(AOC)
      ? AOC.filter((useraoc) => AOCList.some((AOC) => AOC.code === useraoc))[0]
      : AOCList.includes(AOC)
      ? AOC
      : ""
  );
  const [initialData, setInitialData] = useState([]);
  const [first, setFirst] = useState(true);

  const classes = useStyles();

  const permissionUpdateWarning = userPermission.find((permission) => permission.code === "SET-001");
  const acfamily = window.location.pathname.split("/")[window.location.pathname.split("/").length - 1];

  // Clone data to initialData
  useEffect(() => {
    const data_ = cloneDeep(data);
    setInitialData(data_);
    // eslint-disable-next-line
  }, [first]);

  useEffect(() => {
    if (isFilters.search?.length > 0) {
      setIsSearchInput(isFilters.search[0]);
    }
    const resultAtaChapter = isFilters.ata_head.map((item) => ({
      name: item,
      value: item,
    }));
    setAtaChapter(resultAtaChapter);
    const resultCondition = isFilters.condition.map((item) => ({
      name: item,
      value: item,
    }));
    setCondition(resultCondition);
    const resultPriority = isFilters.priority.map((item) => ({
      name: item,
      value: item,
    }));
    setFaultPriorityn(resultPriority);
  }, [isFilters]);

  const offsetValue = limit * (page - 1) > totalRows ? 0 : limit * (page - 1);
  const skeletonLoader = (numberOfSkeleton) => {
    let skeletonArray = [];
    for (let i = 0; i < numberOfSkeleton; i++) {
      skeletonArray.push(<Skeleton animation="wave" height={60} sx={{ borderRadius: "0px", marginTop: "-16px" }} />);
    }
    return skeletonArray;
  };

  function dateFormatter(prop) {
    const date = new Date(prop);
    return format(date, dateFnsFormats.DATE_TIME_2);
    // const date = formatDate(prop, "", "");
    // return date.split(" ")[0];
  }

  function getAtaChapter(ata, report_type) {
    if (ata && report_type === "FAULT")
      return ata.substring(0, 2) + "-" + ata.substring(2, 4) + "-" + ata.substring(4, 6);
    else if (ata && report_type === "WARNING") return ata.substring(0, 2) + "-" + ata.substring(2, 4);
    else if (ata) return ata.substring(0, 2) + "-" + ata.substring(2, 4) + "-" + ata.substring(4, 6);
  }

  async function fetchFilteredFaults(filters, search, sort, ata, condition, faultPriority) {
    // await checkOperator(operator);
    const _search = encodeURIComponent(search);
    try {
      setLoading(true);
      const getData = makeAPIRequestRun(
        "get",
        `acarsdata/warningmessages/${activeTab}/${limit}/${offsetValue}/${filters}/"${_search}"/${sort}/${ata}/${operator}/${acfamily}/${condition}/${faultPriority}`
      );

      getData
        .then((response) => {
          setData(response?.rows ?? "");
          setTotalRows(response?.count[0]?.f0_ ?? 0);
          setDefined(response?.countDefined ?? 0);
          setUndefined(response?.countUndefined ?? 0);
          if (sort !== "null") {
            document.getElementById("main-table").scrollLeft = mouseX;
          } else
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          setFirst(false);
          setLoading(false);
        })
        .catch((err) => {
          setTotalRows(0);
          // setLoading(false);
          console.log(err);
        });
    } catch (err) {
      throw showNotification("danger", "Error", [err]);
    }
  }

  useEffect(() => {
    if (newPriority === oldValue) setTogglePriority(!togglePriority);
    //eslint-disable-next-line
  }, [priority, newPriority, oldValue]);

  useEffect(() => {
    setPage(1);
    //eslint-disable-next-line
  }, [ataChapter, isSearchInput, togglePriority, order, isSearch]);

  useEffect(() => {
    let filters = "null";
    let search = "null";
    let sort = "null";
    let ata = "null";
    let cond = "null";
    let priority = "null";

    // if (!isEmpty(fleet)) {
    //   fleet.map((x, index) => {
    //     return (operator += `${x.value}${
    //       index + 1 === fleet.length ? "" : ","
    //     }`);
    //   });
    // }
    if (!isEmpty(ataChapter)) {
      ata = "";
      ataChapter.map((x, index) => {
        return (ata += `"${x.value}"${index + 1 === ataChapter.length ? "" : ","}`);
      });
    }
    if (!isEmpty(condition)) {
      cond = "";
      condition.map((x, index) => {
        return (cond += `"${x.value}"${index + 1 === condition.length ? "" : ","}`);
      });
    }
    if (!isEmpty(faultPriority)) {
      priority = "";
      faultPriority.map((x, index) => {
        return (priority += `"${x.value}"${index + 1 === faultPriority.length ? "" : ","}`);
      });
    }
    if (!isEmpty(isSearch)) {
      // search = `\`${isSearchInput}\``;
      // const regex = new RegExp(/^\d{2}(-\d{2})*$/gm);
      // search = regex.test(isSearchInput) ? console.log(isSearchInput.replace("-", "")) : isSearchInput.toLowerCase();
      // replace(/[^-*\s*]+/g, "")
      search = isSearch?.toLowerCase();
    }
    if (!isEmpty(order)) {
      sort = orderBy + " " + order;
    }
    fetchFilteredFaults(filters, search, sort, ata, cond, priority);
    //eslint-disable-next-line
  }, [page, limit, activeTab, ataChapter, isSearchInput, togglePriority, order, acfamily, isSearch, operator]);

  function handleActiveTab(tab) {
    setActiveTab(tab);
    setPage(1);
    setLimit(limit);
  }

  function handlePriorityChange(event, ata_chapter, newPriority) {
    setNewPriority(newPriority);
    setEventProp(event);
    setAtaChapterProp(ata_chapter);
    setDialog(true);
  }

  function handleSort(property) {
    if (property !== "addedBy") {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
      setMouseX(document.getElementById("main-table").scrollLeft);
    }
  }

  const headCells = [
    {
      id: "ata_chapter",
      label: "ATA Chapter",
    },
    {
      id: "event",
      label: "Event / Warning Message",
    },
    {
      id: "fleet",
      label: "Operator",
    },
    {
      id: "addedBy",
      label: "Added By",
    },
    {
      id: "updated_by",
      label: "Updated By",
    },
    {
      id: "updated_at",
      label: "Last Update",
    },
    {
      id: "condition",
      label: "Condition",
      position: "sticky", // Position sticky to the right
      right: "140px",
      top: "0",
      zIndex: 200,
    },
    {
      id: "priority",
      label: "Priority",
      position: "sticky", // Position sticky to the right
      right: "0",
      top: "0",
      zIndex: 200,
    },
  ];

  const title = (updatedBy, lastUpdate) => {
    return (
      <div>
        <p>Updated by: {updatedBy}</p>
        <p> Last updated at: {dateFormatter(lastUpdate)}</p>
      </div>
    );
  };

  const ViewBox = () => {
    return (
      <div
        style={{
          marginTop: "33px",
          paddingBottom: "40px",
        }}
      >
        <Grid container columns={12} sx={{ marginBottom: 2 }}>
          <Grid item xs={12}>
            <div className={`${activeTab === 1 ? styles.activeAircraftTab : styles.inactiveAircraftTab}`}>
              <p onClick={(e) => handleActiveTab(1)}>
                Warning Messages {<span className={styles.toolTip}>{definedFaults ? definedFaults[0]?.f0_ : 0}</span>}
              </p>
            </div>
            <div className={`${activeTab === 2 ? styles.activeAircraftTab : styles.inactiveAircraftTab}`}>
              <p onClick={(e) => handleActiveTab(2)}>
                Undefined Warning Messages{" "}
                <span className={styles.toolTip}>{undefinedFaults ? undefinedFaults[0]?.f0_ : 0}</span>
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sx={{ paddingTop: "32px" }}>
            <SearchBar
              data={initialData}
              isSearch={isSearch}
              setIsSearch={setIsSearch}
              isFilters={isFilters}
              setIsFilters={setIsFilters}
              operatorList={AOC}
              operator={operator}
              setOperator={setOperator}
            />
            <ActiveFilters isFilters={isFilters} setIsFilters={setIsFilters} />
          </Grid>
        </Grid>
        <DisplayDataHeader />
      </div>
    );
  };

  const TableHeads = () => {
    return (
      <TableHead>
        <TableRow style={{ backgroundColor: "#F2F7FF" }}>
          {headCells.map((head, index) => (
            <StyledTableCell
              key={index}
              style={{
                whiteSpace: "nowrap",
                position: head.position ?? "",
                right: head.right ?? "",
                zIndex: head.zIndex ?? "",
                top: head.top ?? "",
              }}
              onClick={(e) => {
                handleSort(head.id);
              }}
            >
              <Grid container columns={2}>
                <Grid item xs={1.8}>
                  {head.label}
                </Grid>
                <Grid item xs={0.2}>
                  {order === "asc" && orderBy === head.id ? (
                    <img src={SortIconAscGray} alt="" />
                  ) : order === "desc" && orderBy === head.id ? (
                    <img src={SortIconDescGray} alt="" />
                  ) : (
                    <img src={SortIconAscDescGray} alt="" />
                  )}
                </Grid>
              </Grid>
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  const DisplayDataHeader = () => {
    return (
      <TableContainer
        component={Paper}
        // sx={{ maxHeight: 1500 }}
        id="main-table"
      >
        {loading && (
          <>
            <Table>
              <TableHeads />
            </Table>
            <Box
              sx={{
                width: "100%",
                marginLeft: "17px",
                paddingTop: "10px",
                paddingRight: "34px",
              }}
            >
              {skeletonLoader(limit)}
            </Box>
          </>
        )}
        {!loading && isEmpty(data) && (
          <>
            <Table>
              <TableHeads />
            </Table>
            <Box
              sx={{
                width: "100%",
                marginLeft: "17px",
                paddingTop: "10px",
                paddingRight: "34px",
              }}
            >
              <ErrorAirBallon />
            </Box>
          </>
        )}
        {!isEmpty(data) && !loading && (
          <Table stickyHeader aria-label="sticky table">
            <TableHeads />
            <DisplayData />
          </Table>
        )}
      </TableContainer>
    );
  };

  const ErrorAirBallon = () => {
    return (
      <>
        <div className={styles.errorAirBallon}>
          <center>
            <img src={IconErrorAirBalloon} alt="error-air-ballon" />
            <div className={styles.errorTitle}>No data available</div>
          </center>
        </div>
      </>
    );
  };

  function displayCondition(condition) {
    if (condition) {
      if (condition === "GOIF") {
        return <>GO-IF</>;
      } else {
        return condition;
      }
    } else {
      return "";
    }
  }

  const DisplayData = () => {
    return data?.map((item, index) => {
      return (
        <>
          {isEmpty(item) ? (
            ""
          ) : (
            <TableBody>
              <TableRow classes={classes.tableRow} hover key={index}>
                {/* <StyledTableCell scope="row" disabled>
                  {item.ac_family}
                </StyledTableCell> */}
                <StyledTableCell scope="row" disabled>
                  {getAtaChapter(item.ata_chapter, item.event_type)}
                </StyledTableCell>
                <StyledTableCell scope="row" disabled>
                  {item.event}
                </StyledTableCell>
                <StyledTableCell scope="row" disabled>
                  {item.fleet}
                </StyledTableCell>
                <StyledTableCell scope="row" disabled>
                  {item?.addedBy ?? "System"}
                </StyledTableCell>
                <StyledTableCell scope="row" disabled>
                  {item?.latest_updated_at === item?.condition_updated_at
                    ? item?.condition_updated_by
                    : item?.priority_updated_by}
                </StyledTableCell>
                <StyledTableCell scope="row" disabled>
                  {!isEmpty(item.latest_updated_at) ? dateFormatter(item.latest_updated_at) : ""}
                </StyledTableCell>
                <StickyTableCell
                  scope="row"
                  style={{
                    borderRight: "1px solid #CBCBCB",
                    borderLeft: "1px solid #CBCBCB",
                    whiteSpace: "nowrap",
                    position: "sticky",
                    right: "140px",
                    background: "white",
                    zIndex: 100,
                  }}
                >
                  <Select
                    IconComponent={() => <CustomExpandMore fontSize="small" />}
                    sx={{
                      boxShadow: "none",
                      ".MuiOutlinedInput-notchedOutline": { border: "none" },
                    }}
                    displayEmpty
                    renderValue={() => {
                      return (
                        <>
                          {/* {isEmpty(item.condition) || item.condition === "null" ? "Not Applicable" : item.condition} */}
                          {/* {item.condition ?? ""} */}
                          {displayCondition(item.condition)}
                          {item.condition_updated_by && item.condition_updated_at && (
                            <ThemeProvider theme={theme}>
                              <Tooltip
                                title={title(item.condition_updated_by, item.condition_updated_at)}
                                placement="bottom-end"
                              >
                                <ErrorOutlineRounded
                                  fontSize="small"
                                  sx={{
                                    alignSelf: "end",
                                    position: "absolute",
                                    marginLeft: "8px",
                                  }}
                                />
                              </Tooltip>
                            </ThemeProvider>
                          )}
                        </>
                      );
                    }}
                    fullWidth
                    disableUnderline
                    onChange={(e) => {
                      handlePriorityChange(item.event, item.ata_chapter, e.target.value);
                      setPriority(item.priority);
                      setColumn("condition");
                      setEventType(item.event_type);
                      // setOldValue(isEmpty(item.condition) || item.condition === "null" ? "Not Applicable" : item.condition);
                      setOldValue(item.condition ?? "Null");
                    }}
                    disabled={!permissionUpdateWarning.canUpdate}
                  >
                    {item.condition !== "NOGO" && <MenuItem value={"NOGO"}>NOGO</MenuItem>}
                    {item.condition !== "GO" && <MenuItem value={"GO"}>GO</MenuItem>}
                    {item.condition !== "GOIF" && <MenuItem value={"GOIF"}>GO-IF</MenuItem>}
                  </Select>
                </StickyTableCell>
                <StickyTableCell
                  scope="row"
                  style={{
                    borderRight: "1px solid #CBCBCB",
                    borderLeft: "1px solid #CBCBCB",
                    whiteSpace: "nowrap",
                    position: "sticky",
                    right: "0",
                    background: "white",
                    zIndex: 100,
                  }}
                >
                  <Select
                    IconComponent={() => <CustomExpandMore fontSize="small" />}
                    sx={{
                      boxShadow: "none",
                      ".MuiOutlinedInput-notchedOutline": { border: "none" },
                    }}
                    displayEmpty
                    renderValue={() => {
                      return (
                        <>
                          {item.priority}{" "}
                          {item.priority_updated_at && item.priority_updated_by && (
                            <ThemeProvider theme={theme}>
                              <Tooltip
                                title={title(item.priority_updated_by, item.priority_updated_at)}
                                placement="bottom-end"
                              >
                                <ErrorOutlineRounded
                                  fontSize="small"
                                  sx={{
                                    alignSelf: "end",
                                    position: "absolute",
                                    marginLeft: "8px",
                                  }}
                                />
                              </Tooltip>
                            </ThemeProvider>
                          )}
                        </>
                      );
                    }}
                    fullWidth
                    disableUnderline
                    onChange={(e) => {
                      handlePriorityChange(item.event, item.ata_chapter, e.target.value);
                      setPriority(item.condition);
                      setColumn("priority");
                      setOldValue(item.priority);
                      setEventType(item.event_type);
                    }}
                    disabled={!permissionUpdateWarning.canUpdate}
                  >
                    {item.priority !== "High" && <MenuItem value={"High"}>High</MenuItem>}
                    {item.priority !== "Medium" && <MenuItem value={"Medium"}>Medium</MenuItem>}
                    {item.priority !== "Low" && <MenuItem value={"Low"}>Low</MenuItem>}
                    {item.priority !== "OK" && <MenuItem value={"OK"}>OK</MenuItem>}
                    {item.priority !== "Nuisance" && <MenuItem value={"Nuisance"}>Nuisance</MenuItem>}
                    {item.priority !== "None" && <MenuItem value={"None"}>None</MenuItem>}
                  </Select>
                </StickyTableCell>
              </TableRow>
            </TableBody>
          )}
        </>
      );
    });
  };

  return (
    <>
      {loading && <Overlay />}
      <div className={`${styles.textLarge}`} style={{ marginBottom: "15px", marginLeft: "32px", marginTop: "20px" }}>
        {acfamily} Family Warning Management
      </div>
      <div style={{ paddingRight: "50px", marginBottom: "32px" }}>
        <Container
          component={Paper}
          sx={{
            borderRadius: "12px",
            paddingTop: "24px",
            paddingBottom: "24px",
            marginLeft: "32px",
          }}
          elevation={3}
          maxWidth
        >
          <Box>
            {/* <div className={`${styles.textMedium}`} style={{ marginBottom: "30px" }}>
            Faults Code List
          </div> */}
            {/* {SearchBox()} */}
            <ViewBox />
            {data?.length !== 0 ? (
              <MUIPagination
                page={page}
                setPage={setPage}
                setPerPage={setLimit}
                totalPage={totalRows ? Math.floor(totalRows / limit) + 1 : 0}
                classes={{ ul: classes.ul }}
                totalRows={totalRows}
                sliceStart={offsetValue}
              />
            ) : (
              ""
            )}
            <ConfirmChangeModal
              setNewPriority={setNewPriority}
              newPriority={newPriority}
              priority={priority}
              open={dialog}
              setOpen={setDialog}
              eventMessage={eventProp}
              ataChapter={ataChapterProp}
              setPriority={setPriority}
              column={column}
              acfamily={acfamily}
              oldValue={oldValue}
              setOldValue={setOldValue}
              eventType={eventType}
              aoc={operator}
            />
          </Box>
        </Container>
      </div>
    </>
  );
};

export default WarningManagement;
