export function getMonth2D(date) {
    let prefix = '';
    if (date.getMonth && date.getMonth() + 1 < 10) {
        prefix = '0';
    }
    return `${prefix}${(date.getMonth() + 1).toString()}`;
}
export function getDate2D(date) {
    let prefix = '';
    if (date.getDate && date.getDate() < 10) {
        prefix = '0';
    }
    return `${prefix}${date.getDate().toString()}`;
}
export function getLocaleTime(dateInspection) {
    const localeTime = new Date(dateInspection).toLocaleTimeString('en-US', { hour12: false });
    return localeTime;
}
export function getLocaleDate(dateInspection) {
    const localeDate = new Date(dateInspection).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: '2-digit' });
    return localeDate;
}
export function getUtcOffset(date) {
    // ISO 8601 strings
    function pad(value) {
        return value < 10 ? '0' + value : value;
    }
    var sign = (date.getTimezoneOffset() > 0) ? "-" : "+";
    var offset = Math.abs(date.getTimezoneOffset());
    var hours = pad(Math.floor(offset / 60));
    var minutes = pad(offset % 60);
    return sign + hours + ":" + minutes;
}
export function getStrDateTimeUtcBased(date) {
    if (dateIsValid(date)) {
        const strDate = `${getLocaleDate(date)} ${getLocaleTime(date)} UTC${getUtcOffset(date)}`;
        return strDate;
    }
    else {
        return date.toISOString();
    }
}
export function getStringYYMMDD(date, saparator) {
    const dateStr = new Date(date).getDate();
    const monthStr = new Date(date).getMonth() + 1;
    const preFixMonth = monthStr < 10 ? '0' : '';
    const yearStr = new Date(date).getFullYear();
    const preFixDate = dateStr < 10 ? '0' : '';
    const newDate = `${yearStr}${saparator}${preFixMonth}${monthStr}${saparator}${preFixDate}${dateStr}`;
    return (newDate);
}
export function dateIsValid(date) {
    //@ts-ignore
    return date instanceof Date && !isNaN(date);
}
export function getCabinMonitDateFormatWithTime(date) {
    return `${getLocaleDate(date)} ${getLocaleTime(date)}`;
}
export function getCabinMonitDateFormat(date) {
    return `${getLocaleDate(date)}`;
}
