import React, { useEffect, useState } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import styles from "./Dasr.module.css";
import Dashboard from "./Dashboard";
import AircraftList from "./AircraftList";
import AircraftStatus from "./aircraftStatus/AircraftStatus";
import AircraftReport from "./aircraftReport/AircraftReport";
import { useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import Assignment from "./assignment/Assignment";
import TaskAssignment from "./assignment/TaskAssignment";
import AssignmentReport from "./assignment/AssignmentReport";
import ClosedAircraftReport from "./aircraftReport/ClosedAircraftReport";
import { setAOCBasedOnPermission } from "../component/Helpers";
import AirplaneLoader from "pages/elevade/component/AirplaneLoader";

const DASR = () => {
  const location = useLocation();
  const paramsString = location.search;
  const searchParams = new URLSearchParams(paramsString);
  const open = searchParams.get("open");
  const [isLoading, setIsLoading] = useState(false);
  const { companyAOC, userPermission } = useSelector((state) => state.userEntity);
  let AOC = companyAOC;
  const [aoc, setAoc] = useState(setAOCBasedOnPermission(AOC, userPermission));
  const { aocToggle } = useSelector((state) => state.aocToggle);
  const [aircraftData, setAircraftData] = useState([]);

  // Set AOC list on first load
  // The AOC list is came from user entity data
  useEffect(() => {
    if (Array.isArray(aoc)) {
      const newAOC = cloneDeep(aoc);
      const index = newAOC?.indexOf("ADE");
      // TODO: to check back on this later
      // eslint-disable-next-line no-unused-expressions
      if (index !== -1) newAOC?.splice(index, 1);
      setAoc(newAOC);
    }
  }, [companyAOC]);

  // This settings is used accomodate screen changes on sidebar open
  // Only applicable when sidebar is permanently open
  let paddingRight = "64px";
  if (open === "true") paddingRight = "50px";

  const DisableScrollToTop = ({ history }) => {
    useEffect(() => {
      if (history.action === "POP") return;
    });
    return null;
  };

  return (
    <>
      <div
        id="main"
        style={{
          paddingRight: `${paddingRight}`,
        }}
        className={`${styles.container}`}
      >
        {isLoading && <AirplaneLoader />}
        <Route component={DisableScrollToTop} />
        <Switch>
          <Route exact path="/fleet/dasr">
            <Dashboard
              aoc={!aocToggle ? aoc[0] : aocToggle.value}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              aircraftData={aircraftData}
              setAircraftData={setAircraftData}
            />
          </Route>
          <Route exact path="/fleet/dasr/aircraft-list">
            <AircraftList
              aoc={!aocToggle ? aoc[0] : aocToggle.value}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              aircraftData={aircraftData}
              setAircraftData={setAircraftData}
            />
          </Route>
          <Route exact path="/fleet/dasr/aircraft-status">
            <AircraftStatus aoc={!aocToggle ? aoc[0] : aocToggle.value} setIsLoading={setIsLoading} />
          </Route>
          <Route exact path="/fleet/dasr/aircraft-report">
            <AircraftReport
              aoc={!aocToggle ? aoc[0] : aocToggle.value}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </Route>
          <Route exact path="/fleet/dasr/aircraft-report-closed">
            <ClosedAircraftReport
              aoc={!aocToggle ? aoc[0] : aocToggle.value}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </Route>
          <Route exact path="/fleet/dasr/assignment">
            <Assignment aoc={!aocToggle ? aoc[0] : aocToggle.value} isLoading={isLoading} setIsLoading={setIsLoading} />
          </Route>
          <Route exact path="/fleet/dasr/task-assignment">
            <TaskAssignment
              aoc={!aocToggle ? aoc[0] : aocToggle.value}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </Route>
          <Route exact path="/fleet/dasr/assignment-report">
            <AssignmentReport
              aoc={!aocToggle ? aoc[0] : aocToggle.value}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </Route>
        </Switch>
      </div>
    </>
  );
};

export default DASR;
