export const seatsD = [
    // 1-31
    {
        x: 0,
        y: -102,
        row: 'd',
        col: 1,
    },
    {
        x: 28,
        y: -102,
        row: 'd',
        col: 2,
    },
    {
        x: 56,
        y: -102,
        row: 'd',
        col: 3,
    },
    {
        x: 84,
        y: -102,
        row: 'd',
        col: 4,
    },
    {
        x: 112,
        y: -102,
        row: 'd',
        col: 5,
    },
    {
        x: 140,
        y: -102,
        row: 'd',
        col: 6,
    },
    {
        x: 168,
        y: -102,
        row: 'd',
        col: 7,
    },
    {
        x: 196,
        y: -102,
        row: 'd',
        col: 8,
    },
    {
        x: 224,
        y: -102,
        row: 'd',
        col: 9,
    },
    {
        x: 252,
        y: -102,
        row: 'd',
        col: 10,
    },
    {
        x: 280,
        y: -102,
        row: 'd',
        col: 11,
    },
    {
        // divided by isle
        x: 324,
        y: -102,
        row: 'd',
        col: 12,
    },
    // row 13 is not there
    {
        // divided by isle
        x: 368,
        y: -102,
        row: 'd',
        col: 14,
    },
    {
        x: 396,
        y: -102,
        row: 'd',
        col: 15,
    },
    {
        x: 424,
        y: -102,
        row: 'd',
        col: 16,
    },
    {
        x: 452,
        y: -102,
        row: 'd',
        col: 17,
    },
    {
        x: 480,
        y: -102,
        row: 'd',
        col: 18,
    },
    {
        x: 508,
        y: -102,
        row: 'd',
        col: 19,
    },
    {
        x: 536,
        y: -102,
        row: 'd',
        col: 20,
    },
    {
        x: 564,
        y: -102,
        row: 'd',
        col: 21,
    },
    {
        x: 592,
        y: -102,
        row: 'd',
        col: 22,
    },
    {
        x: 620,
        y: -102,
        row: 'd',
        col: 23,
    },
    {
        x: 648,
        y: -102,
        row: 'd',
        col: 24,
    },
    {
        x: 676,
        y: -102,
        row: 'd',
        col: 25,
    },
    {
        x: 704,
        y: -102,
        row: 'd',
        col: 26,
    },
    {
        x: 732,
        y: -102,
        row: 'd',
        col: 27,
    },
    {
        x: 760,
        y: -102,
        row: 'd',
        col: 28,
    },
    {
        x: 788,
        y: -102,
        row: 'd',
        col: 29,
    },
    {
        x: 816,
        y: -102,
        row: 'd',
        col: 30,
    },
    {
        x: 844,
        y: -102,
        row: 'd',
        col: 31,
    },
];
