
import React from 'react';
import { IconButton, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useState, Fragment, useEffect } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CenteredModal from "../../components/modals/CenteredModal";
import DetailView from "./DetailView";
import SearchAdv from "./SearchAdv";
import { gothamMedium } from "../../../constants/fonts";
import { getLocaleDate, getLocaleTime } from "../../utils/dates";
import { paragraphSpacing, tableRowFontSize } from "../../../constants/styles";

import {
	useGetAllFleetInspections
} from "../../../models/custom/fleet-inspection-model";
import {
	getAllInspectionRecords
} from "../../../models/custom/inspection-record-model";

export default function InspectionListAdv() {
    const [filterRegNos, setFilterRegNos] = useState([]);
    const [filterModels, setFilterModels] = useState([]);
    const [filterEnType, setFilterEnType] = useState([]);
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState(undefined);
    const [createdAtFrom, setCreatedAtFrom] = useState();
    const [createdAtTo, setCreatedAtTo] = useState();
    const { data, errorMessage, isError, isLoading, refetch, } = useGetAllFleetInspections({
		search,
		filterRegNos,
		filterEnType,
		filterModels,
	});
    // ${createdAtFrom ? `"${}",` : ''}
    // ${createdAtTo ? `"${}",` : ''}
    // const queryTimeStart = `${createdAtFrom ? getStringYYMMDD(createdAtFrom, '-') : ''}T${createdAtFrom ? getLocaleTime(new Date(createdAtFrom)) : ''}+00:00`
    // const queryTimeEnd = `${createdAtTo ? getStringYYMMDD(createdAtTo, '-') : ''}T${createdAtTo ? getLocaleTime(new Date(createdAtTo)) : ''}+00:00`

    useEffect(() => {
        if (data) {
            setLoading(true);
            Promise.all(data.map(async ({ node }) => {
                const { fleetId } = node;
                const { data, isError, errorMessage } = await getAllInspectionRecords({
					search,
					regNo : fleetId?.regNo,
					createdAtFrom,
					createdAtTo,
				})
                return ({
                    ...node,
                    reports: data?.data?.allInspectionRecords?.edges?.map(({ node }) => { return { ...node }; }) ?? [],
                });
            })).then((res) => {
                const convRecs = res;
                setList(convRecs);
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [
        data,
        createdAtFrom,
        createdAtTo,
    ]);
    return (<Fragment>
            <SearchAdv createdAtFrom={createdAtFrom} setCreatedAtFrom={setCreatedAtFrom} createdAtTo={createdAtTo} setCreatedAtTo={setCreatedAtTo} filterModels={filterModels} setFilterModels={setFilterModels} filterEnType={filterEnType} setFilterEnType={setFilterEnType} filterRegNos={filterRegNos} search={search} setSearch={setSearch} setFilterRegNos={setFilterRegNos}/>
            <TableContainer sx={{
            "& .MuiTableRow-root:hover": {
                backgroundColor: "#F7F7F7"
            },
            border: '1px solid #E8E8E8',
            borderRadius: '8px',
        }}>
                <Table sx={{ border: 'none', borderCollapse: 'unset' }}>
                    <TableHead>
                        <TableRow>
                            <TableHeader>A/C Reg</TableHeader>
                            <TableHeader>A/C Model</TableHeader>
                            <TableHeader>Engine Type</TableHeader>
                            <TableHeader>Inspection Count</TableHeader>
                            <TableHeader>Latest Operation Date</TableHeader>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(isLoading || loading) && (<Fragment>
                                {Array.apply(null, { length: 2 }).map(Number.call, Number).map((item) => {
                return (<TableRow>
                                            <TableCell sx={{
                        paddingTop: "16px",
                        paddingBottom: "16px",
                    }} colSpan={9} component="th" scope="row">
                                                <Skeleton sx={{ height: "39px" }} animation="wave" variant="text"/>
                                            </TableCell>
                                        </TableRow>);
            })}
                            </Fragment>)}
                        {!(isLoading || loading) && list.map((item, index) => {
            const row = {
                aircraftRegNo: item?.fleetId?.regNo,
                aircraftModel: item?.fleetId?.model,
                aircraftEngType: item?.fleetId?.engineType,
                totalInspection: item?.reports?.length,
                lastInspectionDate: item?.fleetId?.latestSync ? `${getLocaleDate(item?.fleetId?.latestSync)} ${getLocaleTime(item?.fleetId?.latestSync)} ` : 'N/A',
                // lastInspectionDate: getStrDateTimeUtcBased(new Date(item?.dateInspection))
            };
            return (<HeaderRowExpandableTable key={`header-row-expandable-table-${index}`} ExTableRow={({ ExpandButton }) => {
                    return (<TableRow>
                                                {Object.keys(row).map((key, index) => {
                            if (index === 0) {
                                return (<StyledTableCell key={`header-row-expandable-table-StyledTableCell-${index}`}>
                                                                <ExpandButton />
                                                                {row[key]}
                                                            </StyledTableCell>);
                            }
                            else {
                                return (<StyledTableCell key={`header-row-expandable-table--StyledTableCell${index}`}>
                                                                {row[key]}
                                                            </StyledTableCell>);
                            }
                        })}
                                            </TableRow>);
                }} InnerTable={({ expand }) => {
                    return (<InnerTable items={item?.reports ?? []} expand={expand}/>);
                }}/>);
        })}

                    </TableBody>
                </Table>
            </TableContainer>
        </Fragment>);
}
function InnerTable({ items, expand }) {
    const usingItems = items ?? [];
    const [details, setDetails] = useState(null);
    return (<TableRow>
            <CenteredModal open={details !== null} handleClose={() => {
            setDetails(null);
        }} positioning="center" style={{
            overflow: "auto",
        }} containerStyle={{
            width: "90%",
            overflow: "auto",
        }}>
                <Fragment>
                    <DetailView obj={details}/>
                </Fragment>
            </CenteredModal>
            <TableCell colSpan={10} sx={{ padding: '0px' }}>
                <TableContainer sx={{
            border: 'none',
            // borderRadius: '8px',
            height: !expand ? "0px" : undefined,
            overflow: 'hidden',
            "&:hover": {
                backgroundColor: "white"
            },
            // "& .MuiTableRow-root:hover": {
            //     backgroundColor: "#F7F7F7"
            // },
        }}>
                    <Table sx={{ border: 'none', borderCollapse: 'unset' }}>
                        <TableHead>
                            <TableRow>
                                <InnerTableHeader> </InnerTableHeader>
                                <InnerTableHeader /* colSpan={2}  */ paddingLeft={true}>Scan Date/Time</InnerTableHeader>
                                <InnerTableHeader>Inspection  Location</InnerTableHeader>
                                <InnerTableHeader>Detected</InnerTableHeader>
                                <InnerTableHeader>Missing</InnerTableHeader>
                                <InnerTableHeader>PIC</InnerTableHeader>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {usingItems.map((item) => {
            const { 
            // aircraftRegNo,
            // batchNo,
            locationCode, dateInspection, totalActive, totalMissing, scannedBy, } = item;
            // const timezone = `UTC${getUtcOffset(new Date(dateInspection))}`
            // const dateInspectionUTC = `${getLocaleDate(dateInspection)} ${getLocaleTime(dateInspection)} ${timezone}`
            const dateInspectionUTC = dateInspection ? `${getLocaleDate(dateInspection)} ${getLocaleTime(dateInspection)} ` : 'N/A'; //`${getLocaleDate(dateInspection)} ${getLocaleTime(dateInspection)} ${timezone}`
            return (<TableRow onClick={() => {
                    setDetails(item);
                }} sx={{
                    cursor: "pointer",
                }}>
                                        <StyledInnerTableCell></StyledInnerTableCell>
                                        <StyledInnerTableCell>{dateInspection ? (`${dateInspectionUTC}`) : "N/A"}</StyledInnerTableCell>
                                        <StyledInnerTableCell>{locationCode ?? "N/A"}</StyledInnerTableCell>
                                        <StyledInnerTableCell>{totalActive ?? "N/A"}</StyledInnerTableCell>
                                        <StyledInnerTableCell>{totalMissing ?? "N/A"}</StyledInnerTableCell>
                                        <StyledInnerTableCell>{scannedBy ?? "N/A"}</StyledInnerTableCell>
                                    </TableRow>);
        })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </TableCell>
        </TableRow>);
}
const TableHeader = ({ children }) => {
    return (<TableCell align="left" sx={{
            background: '#F2F7FF',
            fontFamily: gothamMedium,
            fontSize: tableRowFontSize,
            lineHeight: paragraphSpacing,
        }}>
            {children}
        </TableCell>);
};
const HeaderRowExpandableTable = ({ ExTableRow, InnerTable }) => {
    const [expand, setExpand] = useState(false);
    function handleExpand() {
        setExpand(!expand);
    }
    ;
    return (<Fragment>
            <ExTableRow ExpandButton={() => (<IconButton sx={{ padding: '0px', marginRight: '10px', height: '14px', width: '14px' }} onClick={handleExpand}>
                        {expand ? (<ExpandLessIcon sx={{ padding: '0px' }}/>) : (<ExpandMoreIcon sx={{ padding: '0px' }}/>)}
                    </IconButton>)}/>
            <InnerTable expand={expand}/>
        </Fragment>);
};
const StyledTableCell = (props) => <TableCell {...props} sx={{
        borderBottom: 'none',
        borderTop: '1px solid #E8E8E8',
        fontSize: tableRowFontSize,
        lineHeight: paragraphSpacing,
        verticalAlign: 'center',
    }}/>;
const StyledInnerTableCell = (props) => <TableCell {...props} sx={{
        borderBottom: 'none',
        borderTop: '1px solid #E8E8E8',
        background: 'none',
        fontSize: tableRowFontSize,
        lineHeight: paragraphSpacing,
        verticalAlign: 'center',
    }}/>;
const InnerTableHeader = ({ children, colSpan, paddingLeft, }) => {
    return (<TableCell align="left" colSpan={colSpan} sx={{
            // @TODO paddingLeft: paddingLeft ? '24px' : undefined,
            background: '#F7F7F7',
            fontFamily: gothamMedium,
            borderTop: '1px solid #D1D1D1',
            fontSize: tableRowFontSize,
            lineHeight: paragraphSpacing,
            verticalAlign: 'center',
        }}>
            {children}
        </TableCell>);
};
