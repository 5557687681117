import React from "react";
import { Grid, Modal, Box, Radio, RadioGroup, FormControlLabel, FormControl } from "@mui/material";
import Button from "pages/elevade/component/Button";
import { makeAPIRequestMDB } from "common/helpers/makeAPIRequestMDB";
import { useState } from "react";
import { useSelector } from "react-redux";
import { showNotificationElevade } from "common/helpers";
import DialogNotification from "../component/DialogNotification";
import IconClose from "common/images/icons/elevade/Icon-Close.svg";

const DialogPush145 = ({ aoc, open, setOpen, data, setData, setCheckAll, setTriggerApi, tab }) => {
  const [value, setValue] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [title, setTitle] = useState("");
  const [subTitle, setSubtitle] = useState("");

  const { currentUser } = useSelector((state) => state.authEntity);

  const doubleQuotedArray = data.map((item) => `"${item}"`);
  const finalArray = `[${doubleQuotedArray.join(",")}]`;
  const isArray = {
    formIds: data,
    aoc: aoc,
    shiftType: value,
    formType: getFormType(),
  };

  function getFormType() {
    if (tab === "1") return "ddml";
    else if (tab === "2") return "rdd";
    else if (tab === "3") return "adhoc";
  }

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    // Call API to push assignment to 145
    const params = `api/assignment`;
    const fetch = makeAPIRequestMDB(`post`, `${params}`, isArray);
    fetch.then((response) => {
      if (response.detail.includes("success")) {
        setOpen(false);
        setOpenNotification(true);
        setTitle("DDML Moved to Task Assignment List");
        setSubtitle("The selected DDML has been moved to the Task Assignment List page.");
        showNotificationElevade("success", "Assignment successfully pushed to 145", [""]);
        setData([]);
        setCheckAll(false);
        setTriggerApi(true);
      } else {
        showNotificationElevade("danger", "An error occured. Please contact your system administrator", [""]);
        setOpenNotification(false);
        setOpen(false);
        setData([]);
        setCheckAll(false);
      }
    });
  };
  return (
    <>
      <Modal
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            border: "0px solid #000",
            boxShadow: 24,
            padding: "16px 0px",
            borderRadius: "8px",
          }}
        >
          <Grid container columns={1}>
            <Grid item xs={0.8} sx={{ padding: "0px 24px 16px 24px", borderBottom: "1px solid #D1D1D1" }}>
              <span style={{ fontFamily: "Gotham-Medium" }}>Task Assignment Shift Type</span>
            </Grid>
            <Grid
              item
              xs={0.2}
              sx={{
                padding: "0px 24px 16px 24px",
                borderBottom: "1px solid #D1D1D1",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <img
                src={IconClose}
                alt="icon-close"
                height={12}
                style={{ cursor: "pointer" }}
                onClick={() => handleClose()}
              />
            </Grid>
            <Grid item xs={1} sx={{ paddingTop: "32px" }}>
              <center>
                <div style={{ fontFamily: "Gotham-Book", color: "#838383", fontSize: "14px" }}>
                  Please select the shift type for the task assignment before pushing to 145
                </div>
              </center>
            </Grid>
            <Grid
              item
              xs={1}
              sx={{ display: "flex", justifyContent: "center", paddingBottom: "16px", paddingTop: "32px" }}
            >
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel value="day" control={<Radio />} label="Day Shift" sx={{ marginRight: "88px" }} />
                  <FormControlLabel value="night" control={<Radio />} label="Night Shift" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={1}
              sx={{ display: "flex", justifyContent: "center", paddingBottom: "16px", paddingTop: "20px" }}
            >
              <Button title="Cancel" variant={"outlined"} onClick={() => handleClose()} />
              <Button title="Proceed" variant={value === "" ? "disabled" : "fill"} onClick={handleConfirm} />
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <DialogNotification open={openNotification} setOpen={setOpenNotification} title={title} subTitle={subTitle} />
    </>
  );
};

export default DialogPush145;
