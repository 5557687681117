
import React from 'react';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import { Menu, Checkbox, Typography, TextField, Grid } from '@mui/material';
import { useState, useEffect, useRef, Fragment } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import zIndex from '../../../../../constants/viewIndex';
export default function DropDownCustom({ optList, onChange = () => { }, values, buttonProps, onSearch, }) {
    const [selecteds, setSelecteds] = useState(values ? values : []);
    useEffect(() => {
        onChange(selecteds);
    }, [selecteds]);
    useEffect(() => {
        if (values) {
            setSelecteds(values);
        }
    }, [values]);
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = (event) => {
        if (anchorRef.current &&
            anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
        // if (onSearch) { onSearch(undefined) }
    };
    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);
    return (<Stack direction="row" spacing={2}>
            <div>
                <Button ref={anchorRef} 
    // id="composition-button"
    // aria-controls={open ? 'composition-menu' : undefined}
    // aria-expanded={open ? 'true' : undefined}
    // aria-haspopup="true"
    onClick={handleToggle} {...buttonProps} sx={{
            ...buttonProps.sx,
            color: '#394458',
            fontSize: "12px"
        }}>
                    {buttonProps?.textStr}
                </Button>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-start" transition disablePortal>
                    {({ TransitionProps, placement }) => (<Grow {...TransitionProps} style={{
                transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}>
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <Fragment>
                                        <Menu 
        // prevent from select by keydown
        onKeyDown={(e) => e.stopPropagation()} anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }} transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }} anchorEl={anchorRef.current} open={open} onClose={handleClose} PaperProps={{
                style: {
                    maxHeight: 67 * 4,
                    width: 'fit-content',
                    borderRadius: '8px',
                    boxShadow: '0px 3px 6px 0px rgba(59, 68, 86, 0.20)',
                    paddingTop: '0px',
                },
            }}>
                                            {onSearch && (<TextField size='small' onKeyDown={(e) => e.stopPropagation()} onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }} placeholder='Search' InputProps={{
                    disableUnderline: true,
                    startAdornment: <SearchIcon sx={{
                            cursor: "pointer",
                            padding: "0.3em",
                            color: '#C3C3C3',
                            // '&:hover': {
                            //     background: "whitesmoke",
                            //     borderRadius: "1.5em",
                            // }
                        }}/>,
                }} onChange={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onSearch(e.target.value);
                }} sx={{
                    "& input::placeholder": {
                        fontSize: "12px"
                    },
                    background: 'white',
                    width: '100%',
                    zIndex: zIndex.header - 1,
                    // width: '100%',
                }}/>)}
                                            <Typography color='#838383' fontSize='12px' sx={{
                paddingLeft: '22px',
                paddingTop: '8px',
                marginRight: '22px',
                marginBottom: '8px',
            }}>
                                                {buttonProps?.textStr}
                                            </Typography>
                                            {optList.map((option) => {
                const value = typeof (option) === 'string' ? option : option?.value;
                const label = typeof (option) === 'string' ? option : option?.label;
                return (<CheckBoxMenuItem value={value} selecteds={selecteds} setSelecteds={setSelecteds} label={label}/>);
            })}
                                        </Menu>
                                    </Fragment>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>)}
                </Popper>
            </div>
        </Stack>);
}
function CheckBoxMenuItem({ value, selecteds, setSelecteds, label, }) {
    const [checked, setChecked] = useState(selecteds.includes(value));
    useEffect(() => {
        if (checked && !selecteds.includes(value)) {
            // selecteds.includes(e.target.c)
            const newSelecteds = [...selecteds];
            newSelecteds.push(value);
            setSelecteds(newSelecteds);
        }
        else if (!checked && selecteds.includes(value)) {
            const newSelecteds = [...selecteds];
            const foundIndex = newSelecteds.findIndex((item) => {
                return item === value;
            });
            if (foundIndex !== -1) {
                newSelecteds.splice(foundIndex, 1);
                setSelecteds(newSelecteds);
            }
        }
    }, [checked]);
    return (<MenuItem key={value} onClick={() => setChecked(!checked)}>
            <Grid container>
                <Grid item xs={9}>
                    <Typography fontSize='14px' paddingBottom='4px' paddingTop='4px'>
                        {label}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Checkbox checkedIcon={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="16" height="16" rx="4" fill="#088FD1"/>
                                <path d="M11.4667 4.80029L6.66667 9.6003L4 7.1003" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>} icon={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" fill="white" stroke="#94A3B8"/>
                            </svg>} checked={selecteds.includes(value)} sx={{
            padding: '0px',
            marginRight: '10px',
            marginLeft: '16px',
            color: '#94A3B8',
            '&.Mui-checked': {
                // color: '#94A3B8',
                color: '#088FD1'
            },
        }}/>
                </Grid>
            </Grid>
        </MenuItem>);
}
