
import React from 'react';

export default function ACBody() {
	return (
		<g filter="url(#filter2_d_4_45)">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M35 345.491L35.0107 345.426C35.1128 340.653 37.4568 333.248 43.396 325.198C49.3853 317.08 59.1291 308.157 74.3463 300.329C77.439 298.738 80.6197 297.058 83.9193 295.314C100.442 286.585 119.948 276.28 146.336 267.727C178.017 257.458 219.581 249.716 278.047 250.008H1095.3L1095.32 250.008C1113.7 250.3 1143.32 251.35 1172.5 253.552C1201.57 255.746 1230.43 259.098 1247.23 264.053L1292 274.401V416.599L1247.23 426.947C1230.43 431.902 1201.57 435.254 1172.5 437.448C1143.32 439.65 1113.7 440.7 1095.32 440.992L1095.3 440.992L278.052 440.992C219.584 441.284 178.018 433.542 146.336 423.273C119.948 414.72 100.442 404.415 83.9192 395.686C80.6196 393.942 77.439 392.262 74.3463 390.671C59.1291 382.843 49.3853 373.92 43.396 365.802C37.4568 357.752 35.1128 350.347 35.0107 345.574L35 345.491Z"
				fill="url(#paint2_linear_4_45)"
			/>
		</g>
	);
}
