
import React from 'react';

export default function HeadABC() {
	return (
		<g>
			<path
				d="M266.499 373.12C267.769 373.12 268.569 372.63 269.299 371.89L268.779 371.38C268.109 372.02 267.479 372.39 266.519 372.39C264.979 372.39 263.809 371.12 263.809 369.5V369.48C263.809 367.87 264.969 366.61 266.519 366.61C267.469 366.61 268.129 367.01 268.719 367.57L269.259 366.99C268.559 366.33 267.799 365.88 266.529 365.88C264.469 365.88 262.989 367.52 262.989 369.5V369.52C262.989 371.53 264.479 373.12 266.499 373.12Z"
				fill="#333333"
			/>
			<path
				d="M263.382 399H266.492C268.022 399 269.032 398.29 269.032 397.09V397.07C269.032 396.11 268.362 395.63 267.532 395.38C268.082 395.14 268.642 394.67 268.642 393.76V393.74C268.642 393.29 268.482 392.92 268.192 392.62C267.792 392.23 267.162 392 266.362 392H263.382V399ZM264.162 395.12V392.71H266.292C267.292 392.71 267.842 393.16 267.842 393.85V393.87C267.842 394.69 267.182 395.12 266.232 395.12H264.162ZM264.162 398.29V395.82H266.372C267.592 395.82 268.232 396.26 268.232 397.02V397.04C268.232 397.82 267.572 398.29 266.512 398.29H264.162Z"
				fill="#333333"
			/>
			<path
				d="M262.44 425H263.25L264.08 423.15H267.89L268.71 425H269.56L266.37 417.95H265.63L262.44 425ZM264.39 422.44L265.99 418.87L267.58 422.44H264.39Z"
				fill="#333333"
			/>
		</g>
	);
}
