
import React from 'react';
import { Box, Typography } from "@mui/material";
import { createContext, useEffect, useState } from "react";
import DetailAllAC from "./DetailAllAC";
import { getLocaleDate, getLocaleTime } from "../../../utils/dates";
import { useGetAllInvStatus, useGetAllInventories } from '../../../../models/custom/inventory-model'

export const CountContext = createContext({
	totalCount: undefined,
	active: undefined,
	missing: undefined,
	tagerror: undefined,
	expired: undefined,
	remind: undefined,
	defects: undefined,
	isLoading: undefined,
	totalIsLoading: undefined,
	showDetail: undefined,
});

export default function Main() {
	const [showAllDet, setShowAllDet] = useState(false);
	const [totalCount, setTotalCount] = useState(0);
	const [active, setactive] = useState(0);
	const [missing, setmissing] = useState(0);
	const [tagerror, settagerror] = useState(0);
	const [expired, setexpired] = useState(0);
	const [remind, setremind] = useState(0);
	const [defects, setdefects] = useState(0);

	const {
		isLoading,
		data,
		// isError, errorMessage, refetch
	} = useGetAllInvStatus();

	const {
		isLoading: totalIsLoading,
		data: totalData,
		// isError: totalIsError,
		// errorMessage: totalErrorMessage,
		// refetch: totalRefetch,
	} = useGetAllInventories();

	useEffect(() => {
		if (data) {
			const {
				totalActive,
				totalExpired,
				totalLost,
				totalRemind,
				totalTagError,
				totalDefect,
			} = data;
			setactive(totalActive);
			setmissing(totalLost);
			settagerror(totalTagError);
			setexpired(totalExpired);
			setremind(totalRemind);
			setdefects(totalDefect);
		}
	}, [data]);
	useEffect(() => {
		if (totalData) {
			const { totalCount } = totalData;
			setTotalCount(totalCount);
		}
	}, [totalData]);
	const now = new Date();
	return (
		<CountContext.Provider
			value={{
				totalCount,
				active,
				missing,
				tagerror,
				expired,
				remind,
				defects,
				isLoading,
				totalIsLoading,
				showDetail: showAllDet,
			}}
		>
			<Box
				sx={{
					background:'white',
					boxShadow:"0px 3px 10px rgba(57, 68, 88, 0.102)",
					width: "100%",
					gap: "1em",
					border: "1px solid #E8E8E8",
					borderRadius: "12px",
					paddingTop: "24px",
					paddingBottom: "24px",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						paddingLeft: "2em",
						paddingRight: "2em",
					}}
				>
					<Typography sx={{ display: "flex", alignItems: "center" }}>
						All Equipment
						{/* <ExpandMoreIcon fontSize="small" sx={{ marginLeft: '1em' }} /> */}
					</Typography>
					{/* <Box sx={{ flexGrow: '1', display: 'flex', justifyContent: 'right' }}>
            <Button>Export</Button>
            <IconButton
                onClick={() => {
                    setShowAllDet(!showAllDet)
                }}
            >
                {showAllDet ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
        </Box> */}
				</Box>
				<DetailAllAC />
				<Box
					sx={{
						width: "95%",
						display: "flex",
						justifyContent: "left",
						paddingLeft: "2em",
						textAlign: "left",
					}}
				>
					<Typography
						fontSize="12px"
						fontStyle="italic"
						sx={{ color: "#838383", width: "100%" }}
					>
						*as of {getLocaleTime(now)} {getLocaleDate(now)}
					</Typography>
				</Box>
			</Box>
		</CountContext.Provider>
	);
}
