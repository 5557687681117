import React, { useEffect, useRef, useState } from "react";
import { Grid, Checkbox, styled, Radio, Stack, Divider } from "@mui/material";
import { ClickAwayListener } from "@mui/base";
import EleSearchBox from "./EleSearchBox.js";
import Airplane from "../../../common/images/icons/elevade/Airplane.svg";
import AirplaneActive from "../../../common/images/icons/elevade/Airplane-active.svg";
import FilterOperator from "../../../common/images/icons/elevade/Filter-operator.svg";
import FilterOperatorActive from "../../../common/images/icons/elevade/Filter-operator-active.svg";
import BookOpen from "../../../common/images/icons/elevade/BookOpen.svg";
import BookOpenActive from "../../../common/images/icons/elevade/BookOpen-active.svg";
import FilterFault from "../../../common/images/icons/elevade/Filter-fault.svg";
import FilterFaultActive from "../../../common/images/icons/elevade/Filter-fault-active.svg";
import Calendar from "../../../common/images/icons/elevade/CalendarBlank.svg";
import CalendarActive from "../../../common/images/icons/elevade/CalendarBlank-active.svg";
import StackInactive from "../../../common/images/icons/elevade/Stack.svg";
import StackActive from "../../../common/images/icons/elevade/Stack-active.svg";
import AirplaneTilt from "../../../common/images/icons/elevade/AirplaneTilt.svg";
import AirplaneTiltActive from "../../../common/images/icons/elevade/AirplaneTilt-active.svg";
import Status from "../../../common/images/icons/elevade/Status.svg";
import StatusActive from "../../../common/images/icons/elevade/Status-active.svg";
import Phase from "../../../common/images/icons/elevade/Phase.svg";
import PhaseActive from "../../../common/images/icons/elevade/Phase-active.svg";
import styles from "./SearchBar.module.css";
import FleetCardHigh from "common/images/icons/elevade/Fleet-Card-High.svg";
import FleetCardMed from "common/images/icons/elevade/Fleet-Card-Med.svg";
import FleetCardLow from "common/images/icons/elevade/Fleet-Card-Low.svg";
import FleetCardOk from "common/images/icons/elevade/Fleet-Card-Ok.svg";
import FleetCardUndefined from "common/images/icons/elevade/Fleet-Card-Undefined.svg";
import FleetCardNone from "common/images/icons/elevade/Fleet-Card-None.svg";
import FleetCardNuisance from "common/images/icons/elevade/Fleet-Card-Nuisance.svg";
// import FilterSort from "../../../common/images/icons/elevade/Filter-sort.svg";
// import FilterSortAscActive from "../../../common/images/icons/elevade/Filter-sort-asc-active.svg";
// import FilterSortDescActive from "../../../common/images/icons/elevade/Filter-sort-desc-active.svg";
import "react-datepicker/dist/react-datepicker.css";
import "./AdeDatePicker.css";
import { formatAtaChapter, formatRepetitive, isMac } from "./Helpers";
import AdeDateRangePicker from "./DatePicker.js";
import Switch from "react-switch";
import { AdeButton } from "common/components/index.js";
// import FilterReport from "common/images/icons/elevade/Filter-report.svg";
// import FilterReportActive from "common/images/icons/elevade/Filter-report-active.svg";
import ListInactive from "../../../common/images/icons/elevade/ListInactive.svg";
import GridActive from "../../../common/images/icons/elevade/GridActive.svg";
import ListActive from "../../../common/images/icons/elevade/ListActive.svg";
import GridInactive from "../../../common/images/icons/elevade/GridInactive.svg";
import { subMinutes } from "date-fns";

const StyledCheckbox = styled(Checkbox)(() => ({
  [`.MuiCheckbox-root`]: {
    marginTop: "6px",
    border: "1px solid blue",
  },
  marginTop: "6px",
  color: "#94A3B8",
}));

const StyledRadiobox = styled(Radio)(() => ({
  [`.MuiCheckbox-root`]: {
    marginTop: "6px",
    border: "1px solid blue",
  },
  marginTop: "6px",
  color: "#94A3B8",
}));

const getFilterLabel = (data, col) => {
  const dataLength = data?.length;
  const dataLengthLabel = data?.length - 1;
  let dataLabel = "";
  let additionalLabel = "";
  if (dataLength > 0 && data !== "All") {
    dataLabel =
      col === "Sort By"
        ? data
        : col === "ATA Chapter"
        ? formatAtaChapter(data[0], "MAIN")
        : col === "15 Legs" || col.endsWith("Legs")
        ? data + " Legs"
        : col === "Fault Priority"
        ? data[0] === "Null" || data[0] === "Missing"
          ? "Undefined"
          : data[0]
        : col === "OperatorAnalytics"
        ? data
        : data[0];
    if (dataLength > 1 && col !== "OperatorAnalytics" && !col.includes("Legs"))
      additionalLabel = ` +${dataLengthLabel}`;
    return dataLabel + additionalLabel;
  }

  return col.replaceAll("Analytics", "");
};

const priorityIcon = (priority) => {
  switch (priority) {
    case "High":
      return <img src={FleetCardHigh} alt="High" className={styles.fleetIcon} />;
    case "Medium":
      return <img src={FleetCardMed} alt="Medium" className={styles.fleetIcon} />;
    case "Low":
      return <img src={FleetCardLow} alt="Low" className={styles.fleetIcon} />;
    case "OK":
      return <img src={FleetCardOk} alt="Ok" className={styles.fleetIcon} />;
    case "NO WARNING OR FAULT MESSAGE":
      return <img src={FleetCardOk} alt="Ok" className={styles.fleetIcon} />;
    case "Undefined":
      return <img src={FleetCardUndefined} alt="Undefined" className={styles.fleetIcon} />;
    case "None":
      return <img src={FleetCardNone} alt="None" className={styles.fleetIcon} />;
    case "Nuisance":
      return <img src={FleetCardNuisance} alt="Nuisance" className={styles.fleetIcon} />;
    default:
      return <img src={FleetCardUndefined} alt="None" className={styles.fleetIcon} />;
  }
};

const FilterModal = ({
  data,
  component,
  refs,
  title,
  col,
  isFilter,
  setIsFilter,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  isLegs,
  setIsLegs,
  open,
  setOpen,
  isSortBy,
  setIsSortBy,
  isSorting,
  setIsSorting,
  froms = "",
  localTime,
  setOpenFilterModal,
  isLast,
}) => {
  const [isChecked, setIsChecked] = useState([]);
  const [selectedValue, setSelectedValue] = useState(() => {
    if (col === "legs") {
      if (Number(isFilter) > 15) {
        return isLegs;
      } else {
        return isLegs;
      }
    }
  });
  const [checked, setChecked] = useState(false);
  // const [sortByAsc, setSortAsc] = useState("'ASC.'");
  // const [sortByDesc, setSortByDesc] = useState("");
  // const [isSortByVal, setIsSortByVal] = useState([]);
  // const [filteredList, setFilteredList] = useState();
  const [pace, setPace] = useState(true);

  useEffect(() => {
    if (isFilter === "All" || isFilter?.length === 0) {
      setIsChecked([]);
    }
  }, [isFilter, setIsFilter]);

  useEffect(() => {
    if (pace) {
      setSelectedValue(isLegs);
    }
  }, [isLegs, pace]);

  const handleRadioChange = (event) => {
    if (col === "OperatorAnalytics") setIsFilter(event.target.value);
    else setIsLegs(event.target.value);
    setSelectedValue(event.target.value);
    setPace(false);
    localStorage.removeItem("inboxMsg");
  };

  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
    if (nextChecked) {
      // setSortAsc("");
      // setSortByDesc("'DESC.'");
      setChecked(true);
      setIsSorting("desc");
    } else {
      // setSortAsc("'ASC.'");
      // setSortByDesc("");
      setChecked(false);
      setIsSorting("asc");
    }
  };

  function getLegsLabel(count) {
    if (count === "100") return "51 - 100 Previous Legs";
    else if (count === "150") return "101 - 150 Previous Legs";
    else if (count === "200") return "151 - 200 Previous Legs";
  }

  const Sorting = () => {
    return (
      <>
        <span style={{ marginRight: "10px", marginTop: "2px" }}>Sorting</span>{" "}
        <Switch
          onChange={handleChange}
          checked={checked}
          className="react-switch"
          height={22}
          width={64}
          offColor="#088FD1"
          onColor="#394458"
          handleDiameter={16}
          uncheckedIcon={
            <div
              style={{
                color: "#ffffff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                marginRight: "8px",
              }}
            >
              ASC.
            </div>
          }
          checkedIcon={
            <div
              style={{
                color: "#ffffff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                marginLeft: "15px",
              }}
            >
              DESC.
            </div>
          }
        />
      </>
    );
  };

  const checkCheckBox = (isChecked, item) => {
    if (froms === "aircraftStatus") {
      if (isChecked?.filter((e) => e === item).length > 0) {
        let getIsFilterItem = isFilter.length > 0 ? isFilter.filter((e) => e === item).length > 0 : false;
        if (getIsFilterItem === false) {
          setIsChecked(isChecked.filter((e) => e !== item));
        }
        return true;
      } else {
        return false;
      }
    } else {
      if (isChecked?.filter((e) => e === item).length > 0) {
        let getIsFilterItem =
          isFilter.length > 0 && isFilter !== "All" ? isFilter.filter((e) => e === item).length > 0 : true;
        if (getIsFilterItem === false) {
          setIsChecked(isChecked.filter((e) => e !== item));
          //   setTimeout(() => {setIsChecked(isChecked.filter((e) => e !== item))}, 0);
        }
        return true;
      } else {
        return false;
      }
    }
  };

  const DateRange = () => {
    const date = new Date().setHours(0, 0);
    const [_startDate, _setStartDate] = useState(startDate ?? new Date(date));
    const [_endDate, _setEndDate] = useState(endDate ?? new Date(date));
    const [error, setError] = useState(null);

    const handleSubmit = () => {
      if (_endDate < _startDate) {
        setError("End date/time cannot be less than start date/time");
        setTimeout(() => {
          setError();
        }, 2500);
      } else if (_endDate > new Date() || _startDate > new Date()) {
        setError("Selected date/time cannot be more than current date/time");
        setTimeout(() => {
          setError();
        }, 2500);
      } else if (!_endDate || !_startDate) {
        setError("Date and time cannot be empty ");
        setTimeout(() => {
          setError();
        }, 2500);
      } else {
        setError();
        setStartDate(_startDate);
        setEndDate(_endDate);
        setOpenFilterModal(false);
      }
    };
    const hours = new Date(_endDate) - new Date(_startDate);
    const isToday = new Date(_endDate).getDate() === new Date().getDate();
    const alsoToday = new Date(endDate).getDate() === new Date().getDate();
    const is24Hours = hours === 86400000 && (isToday || alsoToday);
    const is48Hours = hours === 172800000 && (isToday || alsoToday);
    const is72Hours = hours === 259200000 && (isToday || alsoToday);

    const handlePredefinedRange = (field) => {
      let start = new Date(new Date().getTime() - field * 60 * 60 * 1000);
      start = localTime ? start : subMinutes(new Date(start), -new Date(start).getTimezoneOffset());
      let end = localTime ? new Date() : subMinutes(new Date(), -new Date().getTimezoneOffset());

      _setStartDate(start);
      _setEndDate(end);
      setError();
    };

    const handleClickAway = () => {
      setOpenFilterModal(false);
    };

    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <div id="DateRange">
          <div style={{ fontFamily: "Gotham-Book", fontSize: "14px" }}>
            <Stack
              direction="column"
              divider={<Divider orientation="horizontal" flexItem />}
              spacing={3}
              sx={{ display: "flex", justifyContent: "space-evenly" }}
            >
              <AdeDateRangePicker
                startDate={_startDate}
                setStartDate={_setStartDate}
                endDate={_endDate}
                setEndDate={_setEndDate}
                error={error}
                setError={setError}
                localTime={localTime}
              />
              <Grid style={{ marginTop: "15px" }}>
                <span style={{ color: "#838383", fontSize: "12px" }}>Predefined Selection</span>
                <Stack direction="row" spacing={3} sx={{ display: "flex", justifyContent: "flex-end", mt: "10px" }}>
                  <AdeButton
                    onClick={() => {
                      handlePredefinedRange(24);
                    }}
                    sx={{
                      width: 1 / 3,
                      fontFamily: "Gotham-Book",
                      fontSize: 14,
                      backgroundColor: is24Hours ? "#f3f7fe" : "#FFFFFF",
                      color: is24Hours ? "#088FD1" : "#333333",
                      border: is24Hours ? "none" : "1px solid #B1B1B1",
                    }}
                  >
                    Last 24 Hours
                  </AdeButton>
                  <AdeButton
                    onClick={() => {
                      handlePredefinedRange(48);
                    }}
                    sx={{
                      width: 1 / 3,
                      fontFamily: "Gotham-Book",
                      fontSize: 14,
                      backgroundColor: is48Hours ? "#f3f7fe" : "#FFFFFF",
                      color: is48Hours ? "#088FD1" : "#333333",
                      border: is48Hours ? "none" : "1px solid #B1B1B1",
                    }}
                  >
                    Last 48 Hours
                  </AdeButton>
                  <AdeButton
                    onClick={() => {
                      handlePredefinedRange(72);
                    }}
                    sx={{
                      width: 1 / 3,
                      fontFamily: "Gotham-Book",
                      fontSize: 14,
                      backgroundColor: is72Hours ? "#f3f7fe" : "#FFFFFF",
                      color: is72Hours ? "#088FD1" : "#333333",
                      border: is72Hours ? "none" : "1px solid #B1B1B1",
                    }}
                  >
                    Last 72 Hours
                  </AdeButton>
                </Stack>
              </Grid>
            </Stack>
            <br />
            <Stack direction={"row"} spacing={1} alignItems="center" justifyContent="flex-end">
              <AdeButton
                onClick={() => {
                  setStartDate();
                  setEndDate();
                  _setStartDate(new Date(date));
                  _setEndDate(new Date(date));
                }}
                sx={{ color: "#088FD1", border: "none", width: 1 / 4, fontFamily: "Gotham-Book", fontSize: "14px" }}
              >
                Reset
              </AdeButton>
              <AdeButton
                onClick={() => {
                  handleSubmit();
                }}
                variant="contained"
                sx={{ width: 1 / 4, fontFamily: "Gotham-Book", fontSize: "14px" }}
                disabled={error}
              >
                Apply
              </AdeButton>
            </Stack>
          </div>
        </div>
      </ClickAwayListener>
    );
  };

  return (
    <>
      <div
        className={`${component ? styles.filterModalOpen_ : styles.filterModalClose} ${
          title === "Occurence Date Range" ? styles.filterModalOpenDateRange : ""
        } ${title === "Legs" ? styles.filterModalOpenLegs : ""} ${open ? styles.filterModalOpenDateRange_ : ""} ${
          col === "sort"
            ? styles.filterModalOpenSort
            : col === "departure_airport_format" || col === "destination_airport_format"
            ? styles.filterModalOpenAirport
            : ""
        } ${styles.macScrollbar} ${isLast ? styles.filterModalIsLast : ""}`}
        ref={refs}
      >
        <span style={{ color: "#838383", marginBottom: "0px" }}>
          <Grid container columns={2}>
            <Grid
              item
              xs={col === "sort" ? 1 : 2}
              sx={{ padding: title === "Occurence Date/Time Range" ? "10px 0 0 0px" : "10px 0 0 8px" }}
            >
              {title}
            </Grid>
            {col === "sort" && (
              <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-end", paddingTop: "8px" }}>
                <Sorting />
              </Grid>
            )}
          </Grid>
        </span>
        <ul className={`${styles.ul}`}>
          {title !== "Occurence Date Range" &&
            data?.map((item, index) => {
              let item_ = "";
              if (col === "Operator" || col === "OperatorAnalytics") item_ = item.operator;
              else if (col === "atachapter") item_ = item;
              else if (col === "priority") item_ = item.priority;
              else if (col === "faultPriority") item_ = item;
              else if (col === "legs" || col === "repetitive" || col === "legsAnalytics") item_ = item;
              else if (col === "status") item_ = item.msg_type;
              else if (col === "phase") item_ = item.phase;
              else if (col === "ac_reg") item_ = item.ac_reg;
              else if (col === "repetitive") item_ = item;
              else if (col === "aircraft_type") item_ = item;
              return (
                <>
                  {item_ !== null &&
                    // item_ !== "Null" &&
                    item_ !== "null" &&
                    item_ !== "" &&
                    // item_ !== "Missing" &&
                    item_ !== undefined && (
                      <li className={`${styles.li}`}>
                        <Grid container columns={3} key={index}>
                          <Grid item xs={2} sx={{ paddingTop: "10%" }}>
                            {col === "priority" || col === "faultPriority" ? priorityIcon(item_) : ""}
                            <span style={{ whiteSpace: "nowrap", paddingLeft: "6px" }}>
                              {col === "legs" && item <= 50
                                ? item_ + ` Previous Legs`
                                : col === "legs" && item > 50
                                ? getLegsLabel(item)
                                : col === "legsAnalytics"
                                ? item_ + ` Previous Legs`
                                : col === "atachapter"
                                ? formatAtaChapter(item_, "MAIN")
                                : col === "repetitive"
                                ? formatRepetitive(item_)
                                : item_}
                            </span>
                          </Grid>
                          <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-end" }}>
                            {col !== "legs" && col !== "OperatorAnalytics" && col !== "legsAnalytics" && (
                              <StyledCheckbox
                                value={item_}
                                checked={
                                  // isChecked?.filter((e) => e === item_).length > 0 ? true : false
                                  checkCheckBox(isChecked, item_)
                                }
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setIsChecked([...isChecked, e.target.value]);
                                    setIsFilter([...isChecked, e.target.value]);
                                  } else {
                                    setIsChecked(isChecked.filter((e) => e !== item_));
                                    setIsFilter(isChecked.filter((e) => e !== item_));
                                  }
                                }}
                              />
                            )}
                            {(col === "legs" || col === "OperatorAnalytics" || col === "legsAnalytics") && (
                              <StyledRadiobox
                                value={item_}
                                checked={col === "legs" ? selectedValue === item_ : isFilter === item_}
                                name="radio-buttons"
                                onChange={handleRadioChange}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </li>
                    )}
                </>
              );
            })}
          {title === "Occurence Date Range" ? <DateRange /> : ""}
        </ul>
      </div>
    </>
  );
};

export const SearchFault = ({
  listOperator,
  listFaultPriority,
  listRepetitive,
  listAtaChapter,
  listPhase,
  listACReg,
  listAircraftType,
  isSearchInput,
  setIsSearchInput,
  isOperator,
  setIsOperator,
  isAtaChapter,
  setIsAtaChapter,
  isFaultPriority,
  setIsFaultPriority,
  isRepetitive,
  setIsRepetitive,
  isLegs,
  setIsLegs,
  isDateRange,
  setIsDateRange,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  isPhase,
  setIsPhase,
  isACReg,
  setIsACReg,
  isSortBy,
  setIsSortBy,
  isSorting,
  setIsSorting,
  isAircraftType,
  setIsAircraftType,
  localTime,
  tab,
}) => {
  const [openOperator, setOpenOperator] = useState(false);
  const [openPhase, setOpenPhase] = useState(false);
  const [openACReg, setOpenACReg] = useState(false);
  const [openAtaChapter, setOpenAtaChapter] = useState(false);
  const [openFaultPriority, setOpenFaultPriority] = useState(false);
  const [openRepetitive, setOpenRepetitive] = useState(false);
  const [openAircraftType, setOpenAircraftType] = useState(false);
  // const [openLegs, setOpenLegs] = useState(false);
  const [openDateRange, setOpenDateRange] = useState(false);
  const refOperator = useRef(null);
  const refFaultPriority = useRef(null);
  const refRepetitive = useRef(null);
  const refLegs = useRef(null);
  const refDateRange = useRef(null);
  const refAtaChapter = useRef(null);
  const refPhase = useRef(null);
  const refACReg = useRef(null);
  const refSort = useRef(null);
  const refReportType = useRef(null);
  // const listLegs = ["15", "30", "50"];
  const dateRange = "";
  const [open, setOpen] = useState(false);
  // const [openSort, setOpenSort] = useState(false);

  // Function to detect mouse click outside a component
  function useOutsideClicker(ref, component) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (component === "operator") setOpenOperator(false);
          else if (component === "atachapter") setOpenAtaChapter(false);
          else if (component === "priority") setOpenFaultPriority(false);
          else if (component === "repetitive") setOpenRepetitive(false);
          // else if (component === "legs") setOpenLegs(false);
          else if (component === "dateRange") {
            const path = event.composedPath();
            const dateRange = path?.find((ele) => {
              const res = ele.className?.toString().includes("DateRange");
              return res ?? "";
            });
            // dateRange ? setOpenDateRange(true) : setOpenDateRange(false);
          } else if (component === "phase") setOpenPhase(false);
          else if (component === "ac_reg") setOpenACReg(false);
          // else if (component === "sort") setOpenSort(false);
          else if (component === "aircraft_type") setOpenAircraftType(false);
        }
        setOpen(false);
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, component]);
  }

  useOutsideClicker(refOperator, "operator");
  useOutsideClicker(refAtaChapter, "atachapter");
  useOutsideClicker(refFaultPriority, "priority");
  useOutsideClicker(refRepetitive, "repetitive");
  useOutsideClicker(refLegs, "legs");
  // useOutsideClicker(refDateRange, "dateRange");
  useOutsideClicker(refPhase, "phase");
  useOutsideClicker(refACReg, "ac_reg");
  useOutsideClicker(refSort, "sort");
  useOutsideClicker(refReportType, "aircraft_type");

  return (
    <div className={`${styles.main} ${styles.main_}`}>
      <Grid container columns={12}>
        <Grid item xs={12} sm={12} md={6} lg={2} xl={2} className={`${styles.searchInput}`}>
          <EleSearchBox placeholder="Search..." isSearchInput={isSearchInput} setIsSearchInput={setIsSearchInput} />
        </Grid>
        <Grid item xs={10} sm={12} md={6} lg={10} xl={10} className={`${styles.filterCol_}`}>
          <div className={`${styles.filterDivPosition}`}>
            <span
              className={`${styles.filterDiv} ${
                openOperator || (isOperator.length > 0 && isOperator !== "All") ? styles.active : ""
              }`}
              onClick={() => setOpenOperator(true)}
            >
              <img
                src={isOperator.length > 0 && isOperator !== "All" ? FilterOperatorActive : FilterOperator}
                height="20px"
                alt="airplane"
                style={{ marginRight: "8px" }}
              />
              <div style={{ marginTop: "2px" }}>{getFilterLabel(isOperator, "Operator")}</div>
            </span>
            <FilterModal
              data={listOperator}
              component={openOperator}
              refs={refOperator}
              title="Operator"
              col="Operator"
              isFilter={isOperator}
              setIsFilter={setIsOperator}
              key={"Operator"}
            />
          </div>
          <div className={`${styles.filterDivPosition}`}>
            <span
              className={`${styles.filterDiv} ${
                openACReg || (isACReg.length > 0 && isACReg !== "All") ? styles.active : ""
              }`}
              onClick={() => setOpenACReg(true)}
            >
              <img
                src={isACReg.length > 0 && isACReg !== "All" ? AirplaneActive : Airplane}
                height="20px"
                alt="airplane"
                style={{ marginRight: "8px" }}
              />
              <div style={{ marginTop: "2px" }}>{getFilterLabel(isACReg, "Aircraft Reg.")}</div>
            </span>
            <FilterModal
              data={listACReg}
              component={openACReg}
              refs={refACReg}
              title="Aircraft Reg."
              col="ac_reg"
              isFilter={isACReg}
              setIsFilter={setIsACReg}
              key={"ac_reg"}
            />
          </div>
          {tab === 1 && (
            <div className={`${styles.filterDivPosition}`}>
              <span
                className={`${styles.filterDiv} ${
                  openAircraftType || (isAircraftType.length > 0 && isAircraftType !== "All") ? styles.active : ""
                }`}
                onClick={() => setOpenAircraftType(true)}
              >
                <img
                  src={isAircraftType.length > 0 && isAircraftType !== "All" ? AirplaneTiltActive : AirplaneTilt}
                  height="20px"
                  alt="airplane"
                  style={{ marginRight: "8px" }}
                />
                <div style={{ marginTop: "2px" }}>{getFilterLabel(isAircraftType, "Aircraft Type")}</div>
              </span>
              <FilterModal
                data={listAircraftType}
                component={openAircraftType}
                refs={refReportType}
                title="Aircraft Type"
                col="aircraft_type"
                isFilter={isAircraftType}
                setIsFilter={setIsAircraftType}
                key={"aircraft_type"}
              />
            </div>
          )}
          {tab === 1 && (
            <div className={`${styles.filterDivPosition}`}>
              <span
                className={`${styles.filterDiv} ${
                  openPhase || (isPhase.length > 0 && isPhase !== "All") ? styles.active : ""
                }`}
                onClick={() => setOpenPhase(true)}
              >
                <img
                  src={isPhase.length > 0 && isPhase !== "All" ? PhaseActive : Phase}
                  height="20px"
                  alt="airplane"
                  style={{ marginRight: "8px" }}
                />
                <div style={{ marginTop: "2px" }}>{getFilterLabel(isPhase, "Phase")}</div>
              </span>
              <FilterModal
                data={listPhase}
                component={openPhase}
                refs={refPhase}
                title="Phase"
                col="phase"
                isFilter={isPhase}
                setIsFilter={setIsPhase}
                key={"phase"}
              />
            </div>
          )}
          {tab === 1 && (
            <div className={`${styles.filterDivPosition}`}>
              <span
                className={`${styles.filterDiv} ${
                  openAtaChapter || (isAtaChapter.length > 0 && isAtaChapter !== "All") ? styles.active : ""
                }`}
                onClick={() => setOpenAtaChapter(true)}
              >
                <img
                  src={isAtaChapter.length > 0 && isAtaChapter !== "All" ? BookOpenActive : BookOpen}
                  height="20px"
                  alt="airplane"
                  style={{ marginRight: "8px" }}
                />
                <div style={{ marginTop: "2px" }}>{getFilterLabel(isAtaChapter, "ATA Chapter")}</div>
              </span>
              <FilterModal
                data={listAtaChapter}
                component={openAtaChapter}
                refs={refAtaChapter}
                title="ATA Chapter"
                col="atachapter"
                isFilter={isAtaChapter}
                setIsFilter={setIsAtaChapter}
                key={"atachapter"}
              />
            </div>
          )}
          {tab === 1 && (
            <div className={`${styles.filterDivPosition}`}>
              <span
                className={`${styles.filterDiv} ${
                  openFaultPriority || (isFaultPriority.length > 0 && isFaultPriority !== "All") ? styles.active : ""
                }`}
                onClick={() => setOpenFaultPriority(true)}
              >
                <img
                  src={isFaultPriority.length > 0 && isFaultPriority !== "All" ? FilterFaultActive : FilterFault}
                  height="20px"
                  alt="airplane"
                  style={{ marginRight: "8px" }}
                />
                <div style={{ marginTop: "2px" }}>{getFilterLabel(isFaultPriority, "Fault Priority")}</div>
              </span>
              <FilterModal
                data={listFaultPriority}
                component={openFaultPriority}
                refs={refFaultPriority}
                title="Fault Priority"
                col="priority"
                isFilter={isFaultPriority}
                setIsFilter={setIsFaultPriority}
                key={"Fault Priority"}
              />
            </div>
          )}
          {tab === 1 && (
            <div className={`${styles.filterDivPosition}`}>
              <span
                className={`${styles.filterDiv} ${
                  openRepetitive || (isRepetitive.length > 0 && isRepetitive !== "All") ? styles.active : ""
                }`}
                onClick={() => setOpenRepetitive(true)}
              >
                <img
                  src={isRepetitive.length > 0 && isRepetitive !== "All" ? StackActive : StackInactive}
                  height="20px"
                  alt="airplane"
                  style={{ marginRight: "8px" }}
                />
                <div style={{ marginTop: "2px" }}>{getFilterLabel(isRepetitive, "Repetitive")}</div>
              </span>
              <FilterModal
                data={listRepetitive}
                component={openRepetitive}
                refs={refRepetitive}
                title="Repetitive"
                col="repetitive"
                isFilter={isRepetitive}
                setIsFilter={setIsRepetitive}
                key={"repetitive"}
              />
            </div>
          )}
          <div className={`${styles.filterDivPosition}`}>
            <span
              className={`${styles.filterDiv} ${
                startDate !== undefined && startDate !== null && endDate !== undefined && endDate !== null
                  ? styles.active
                  : ""
              }`}
              onClick={() => setOpenDateRange(true)}
            >
              <img
                src={
                  startDate !== undefined && startDate !== null && endDate !== undefined && endDate !== null
                    ? CalendarActive
                    : Calendar
                }
                height="20px"
                alt="airplane"
                style={{ marginRight: "8px" }}
              />
              <div style={{ marginTop: "2px" }}>{"Date Range"}</div>
            </span>
            <FilterModal
              data={dateRange}
              component={openDateRange}
              refs={refDateRange}
              title="Occurence Date Range"
              col="dateRange"
              isFilter={isDateRange}
              setIsFilter={setIsDateRange}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              open={open}
              setOpen={setOpen}
              localTime={localTime}
              key={"Date-Range"}
              setOpenFilterModal={setOpenDateRange}
            />
          </div>
          {/* <div className={`${styles.filterDivPosition}`}>
            <div
              className={`${styles.filterDiv} ${openSort || isSortBy.length > 0 ? styles.active : ""}`}
              onClick={() => setOpenSort(true)}
            >
              <img
                src={
                  isSortBy.length > 0 ? (isSorting === "asc" ? FilterSortAscActive : FilterSortDescActive) : FilterSort
                }
                height="20px"
                alt="sort"
                style={{ marginRight: "8px" }}
              />
              <div style={{ marginTop: "2px" }}>Sort By</div>
            </div>
            <FilterModal component={openSort} refs={refSort} title="Sort by" col="sort" />
          </div>
          <div className={`${styles.filterDivPosition}`}>
            <span className={`${styles.filterDiv} ${styles.active}`} onClick={() => setOpenLegs(true)}>
              <img src={AirplaneTiltActive} height="20px" alt="airplane" style={{ marginRight: "8px" }} />
              <div style={{ marginTop: "2px" }}>{getFilterLabel(isLegs, "15 Legs")}</div>
            </span>
            <FilterModal
              data={listLegs}
              component={openLegs}
              refs={refLegs}
              title="Legs"
              col="legs"
              isFilter={isLegs}
              setIsFilter={setIsLegs}
              isLegs={isLegs}
              setIsLegs={setIsLegs}
              isSortBy={isSortBy}
              setIsSortBy={setIsSortBy}
              isSorting={isSorting}
              setIsSorting={setIsSorting}
            />
          </div> */}
        </Grid>
      </Grid>
    </div>
  );
};

export const SearchOOOI = ({
  listOperator,
  listStatus,
  isSearchInput,
  setIsSearchInput,
  listACReg,
  isACReg,
  setIsACReg,
  isOperator,
  setIsOperator,
  isStatus,
  setIsStatus,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  localTime,
}) => {
  const [openACReg, setOpenACReg] = useState(false);
  const [openOperator, setOpenOperator] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [openDateRange, setOpenDateRange] = useState(false);
  const refOperator = useRef(null);
  const refStatus = useRef(null);
  const refDateRange = useRef(null);
  const refACReg = useRef(null);
  const dateRange = "";

  // Function to detect mouse click outside a component
  function useOutsideClicker(ref, component) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (component === "operator") setOpenOperator(false);
          else if (component === "status") setOpenStatus(false);
          else if (component === "dateRange") {
            const path = event.composedPath();
            const dateRange = path?.find((ele) => {
              const res = ele.className?.toString().includes("DateRange");
              return res ?? "";
            });
            dateRange ? setOpenDateRange(true) : setOpenDateRange(false);
          } else if (component === "ac_reg") setOpenACReg(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, component]);
  }

  useOutsideClicker(refACReg, "ac_reg");
  useOutsideClicker(refOperator, "operator");
  useOutsideClicker(refStatus, "status");
  useOutsideClicker(refDateRange, "dateRange");
  return (
    <div className={`${styles.main} ${styles.main_}`}>
      <Grid container columns={12}>
        <Grid item xs={12} sm={12} md={6} lg={2} xl={2} className={`${styles.searchInput}`}>
          <EleSearchBox placeholder="Search..." isSearchInput={isSearchInput} setIsSearchInput={setIsSearchInput} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={10} xl={10} className={`${styles.filterCol_}`}>
          <div className={`${styles.filterDivPosition}`}>
            <span
              className={`${styles.filterDiv} ${
                openOperator || (isOperator.length > 0 && isOperator !== "All") ? styles.active : ""
              }`}
              onClick={() => setOpenOperator(true)}
            >
              <img
                src={isOperator.length > 0 && isOperator !== "All" ? FilterOperatorActive : FilterOperator}
                height="20px"
                alt="airplane"
                style={{ marginRight: "8px" }}
              />
              <div style={{ marginTop: "2px" }}>{getFilterLabel(isOperator, "Operator")}</div>
            </span>
            <FilterModal
              data={listOperator}
              component={openOperator}
              refs={refOperator}
              title="Operator"
              col="Operator"
              isFilter={isOperator}
              setIsFilter={setIsOperator}
              key={"Operator"}
            />
          </div>
          <div className={`${styles.filterDivPosition}`}>
            <span
              className={`${styles.filterDiv} ${
                openACReg || (isACReg.length > 0 && isACReg !== "All") ? styles.active : ""
              }`}
              onClick={() => setOpenACReg(true)}
            >
              <img
                src={isACReg.length > 0 && isACReg !== "All" ? AirplaneActive : Airplane}
                height="20px"
                alt="airplane"
                style={{ marginRight: "8px" }}
              />
              <div style={{ marginTop: "2px" }}>{getFilterLabel(isACReg, "Aircraft Reg.")}</div>
            </span>
            <FilterModal
              data={listACReg}
              component={openACReg}
              refs={refACReg}
              title="Aircraft Reg."
              col="ac_reg"
              isFilter={isACReg}
              setIsFilter={setIsACReg}
              key={"ac_reg"}
            />
          </div>
          <div className={`${styles.filterDivPosition}`}>
            <span
              className={`${styles.filterDiv} ${
                openStatus || (isStatus.length > 0 && isStatus !== "All") ? styles.active : ""
              }`}
              onClick={() => setOpenStatus(true)}
            >
              <img
                src={isStatus.length > 0 && isStatus !== "All" ? StatusActive : Status}
                height="20px"
                alt="airplane"
                style={{ marginRight: "8px" }}
              />
              <div style={{ marginTop: "2px" }}>{getFilterLabel(isStatus, "Status")}</div>
            </span>
            <FilterModal
              data={listStatus}
              component={openStatus}
              refs={refStatus}
              title="Status"
              col="status"
              isFilter={isStatus}
              setIsFilter={setIsStatus}
              key={"status"}
            />
          </div>
          <div className={`${styles.filterDivPosition}`}>
            <span
              className={`${styles.filterDiv} ${
                startDate !== undefined && startDate !== null && endDate !== undefined && endDate !== null
                  ? styles.active
                  : ""
              }`}
              onClick={() => setOpenDateRange(!openDateRange)}
            >
              <img
                src={
                  startDate !== undefined && startDate !== null && endDate !== undefined && endDate !== null
                    ? CalendarActive
                    : Calendar
                }
                height="20px"
                alt="airplane"
                style={{ marginRight: "8px" }}
              />
              <div style={{ marginTop: "2px" }}>{"Date Range"}</div>
            </span>
            <FilterModal
              data={dateRange}
              component={openDateRange}
              refs={refDateRange}
              title="Occurence Date Range"
              col="dateRange"
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              localTime={localTime}
              key={"Date-Range"}
              setOpenFilterModal={setOpenDateRange}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export const SearchPositioning = ({
  listOperator,
  isSearchInput,
  setIsSearchInput,
  listACReg,
  isACReg,
  setIsACReg,
  isOperator,
  setIsOperator,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  localTime,
}) => {
  const [openACReg, setOpenACReg] = useState(false);
  const [openOperator, setOpenOperator] = useState(false);
  const [openDateRange, setOpenDateRange] = useState(false);
  const refOperator = useRef(null);
  const refDateRange = useRef(null);
  const refACReg = useRef(null);
  const dateRange = "";

  // Function to detect mouse click outside a component
  function useOutsideClicker(ref, component) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (component === "operator") setOpenOperator(false);
          else if (component === "dateRange") {
            const path = event.composedPath();
            const dateRange = path?.find((ele) => {
              const res = ele.className?.toString().includes("DateRange");
              return res ?? "";
            });
            dateRange ? setOpenDateRange(true) : setOpenDateRange(false);
          } else if (component === "ac_reg") setOpenACReg(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, component]);
  }

  useOutsideClicker(refACReg, "ac_reg");
  useOutsideClicker(refOperator, "operator");
  useOutsideClicker(refDateRange, "dateRange");
  return (
    <div className={`${styles.main} ${styles.main_}`}>
      <Grid container columns={12}>
        <Grid item xs={12} sm={12} md={6} lg={2} xl={2} className={`${styles.searchInput}`}>
          <EleSearchBox placeholder="Search..." isSearchInput={isSearchInput} setIsSearchInput={setIsSearchInput} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={10} xl={10} className={`${styles.filterCol_}`}>
          <div className={`${styles.filterDivPosition}`}>
            <span
              className={`${styles.filterDiv} ${
                openOperator || (isOperator.length > 0 && isOperator !== "All") ? styles.active : ""
              }`}
              onClick={() => setOpenOperator(true)}
            >
              <img
                src={isOperator.length > 0 && isOperator !== "All" ? FilterOperatorActive : FilterOperator}
                height="20px"
                alt="airplane"
                style={{ marginRight: "8px" }}
              />
              <div style={{ marginTop: "2px" }}>{getFilterLabel(isOperator, "Operator")}</div>
            </span>
            <FilterModal
              data={listOperator}
              component={openOperator}
              refs={refOperator}
              title="Operator"
              col="Operator"
              isFilter={isOperator}
              setIsFilter={setIsOperator}
              key={"Operator"}
            />
          </div>
          <div className={`${styles.filterDivPosition}`}>
            <span
              className={`${styles.filterDiv} ${
                openACReg || (isACReg.length > 0 && isACReg !== "All") ? styles.active : ""
              }`}
              onClick={() => setOpenACReg(true)}
            >
              <img
                src={isACReg.length > 0 && isACReg !== "All" ? AirplaneActive : Airplane}
                height="20px"
                alt="airplane"
                style={{ marginRight: "8px" }}
              />
              <div style={{ marginTop: "2px" }}>{getFilterLabel(isACReg, "Aircraft Reg.")}</div>
            </span>
            <FilterModal
              data={listACReg}
              component={openACReg}
              refs={refACReg}
              title="Aircraft Reg."
              col="ac_reg"
              isFilter={isACReg}
              setIsFilter={setIsACReg}
              key={"ac_reg"}
            />
          </div>
          <div className={`${styles.filterDivPosition}`}>
            <span
              className={`${styles.filterDiv} ${
                startDate !== undefined && startDate !== null && endDate !== undefined && endDate !== null
                  ? styles.active
                  : ""
              }`}
              onClick={() => setOpenDateRange(!openDateRange)}
            >
              <img
                src={
                  startDate !== undefined && startDate !== null && endDate !== undefined && endDate !== null
                    ? CalendarActive
                    : Calendar
                }
                height="20px"
                alt="airplane"
                style={{ marginRight: "8px" }}
              />
              <div style={{ marginTop: "2px" }}>{"Date Range"}</div>
            </span>
            <FilterModal
              data={dateRange}
              component={openDateRange}
              refs={refDateRange}
              title="Occurence Date Range"
              col="dateRange"
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              localTime={localTime}
              key={"Date-Range"}
              setOpenFilterModal={setOpenDateRange}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export const SearchAcms = ({
  listOperator,
  listFaultPriority,
  listACReg,
  isSearchInput,
  setIsSearchInput,
  isACReg,
  setIsACReg,
  isOperator,
  setIsOperator,
  isFaultPriority,
  setIsFaultPriority,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  vertical,
  setVertical,
  localTime,
}) => {
  const [openACReg, setOpenACReg] = useState(false);
  const [openOperator, setOpenOperator] = useState(false);
  // const [openFaultPriority, setOpenFaultPriority] = useState(false);
  const [openDateRange, setOpenDateRange] = useState(false);
  const refACReg = useRef(null);
  const refOperator = useRef(null);
  const refFaultPriority = useRef(null);
  const refDateRange = useRef(null);
  const dateRange = "";

  // Function to detect mouse click outside a component
  function useOutsideClicker(ref, component) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (component === "operator") setOpenOperator(false);
          else if (component === "ac_reg") setOpenACReg(false);
          // else if (component === "priority") setOpenFaultPriority(false);
          else if (component === "dateRange") {
            const path = event.composedPath();
            const dateRange = path?.find((ele) => {
              const res = ele.className?.toString().includes("DateRange");
              return res ?? "";
            });
            dateRange ? setOpenDateRange(true) : setOpenDateRange(false);
          }
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, component]);
  }

  useOutsideClicker(refACReg, "ac_reg");
  useOutsideClicker(refOperator, "operator");
  useOutsideClicker(refFaultPriority, "priority");
  useOutsideClicker(refDateRange, "dateRange");
  return (
    <div className={`${styles.main} ${styles.main_}`}>
      <Grid container columns={12}>
        <Grid item xs={12} sm={12} md={6} lg={2} xl={2} className={`${styles.searchInput}`}>
          <EleSearchBox placeholder="Search..." isSearchInput={isSearchInput} setIsSearchInput={setIsSearchInput} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={10} xl={10} className={`${styles.filterCol_}`}>
          <div className={`${styles.filterDivPosition}`}>
            <span
              className={`${styles.filterDiv} ${
                openOperator || (isOperator.length > 0 && isOperator !== "All") ? styles.active : ""
              }`}
              onClick={() => setOpenOperator(true)}
            >
              <img
                src={isOperator.length > 0 && isOperator !== "All" ? FilterOperatorActive : FilterOperator}
                height="20px"
                alt="airplane"
                style={{ marginRight: "8px" }}
              />
              <div style={{ marginTop: "2px" }}>{getFilterLabel(isOperator, "Operator")}</div>
            </span>
            <FilterModal
              data={listOperator}
              component={openOperator}
              refs={refOperator}
              title="Operator"
              col="Operator"
              isFilter={isOperator}
              setIsFilter={setIsOperator}
              key={"Operator"}
            />
          </div>
          <div className={`${styles.filterDivPosition}`}>
            <span
              className={`${styles.filterDiv} ${
                openACReg || (isACReg.length > 0 && isACReg !== "All") ? styles.active : ""
              }`}
              onClick={() => setOpenACReg(true)}
            >
              <img
                src={isACReg.length > 0 && isACReg !== "All" ? AirplaneActive : Airplane}
                height="20px"
                alt="airplane"
                style={{ marginRight: "8px" }}
              />
              <div style={{ marginTop: "2px" }}>{getFilterLabel(isACReg, "Aircraft Reg.")}</div>
            </span>
            <FilterModal
              data={listACReg}
              component={openACReg}
              refs={refACReg}
              title="Aircraft Reg."
              col="ac_reg"
              isFilter={isACReg}
              setIsFilter={setIsACReg}
              key={"ac_reg"}
            />
          </div>
          <div className={`${styles.filterDivPosition}`}>
            <span
              className={`${styles.filterDiv} ${
                startDate !== undefined && startDate !== null && endDate !== undefined && endDate !== null
                  ? styles.active
                  : ""
              }`}
              onClick={() => setOpenDateRange(!openDateRange)}
            >
              <img
                src={
                  startDate !== undefined && startDate !== null && endDate !== undefined && endDate !== null
                    ? CalendarActive
                    : Calendar
                }
                height="20px"
                alt="airplane"
                style={{ marginRight: "8px" }}
              />
              <div style={{ marginTop: "2px" }}>{"Date Range"}</div>
            </span>
            <FilterModal
              data={dateRange}
              component={openDateRange}
              refs={refDateRange}
              title="Occurence Date Range"
              col="dateRange"
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              localTime={localTime}
              key={"Date-Range"}
              setOpenFilterModal={setOpenDateRange}
            />
          </div>
          <div className={`${styles.filterDivPosition}`}>
            <span className={`${styles.filterDiv} `} onClick={() => setVertical(!vertical)}>
              <img src={vertical ? GridActive : GridInactive} height="20px" alt="" style={{ marginRight: "8px" }} />
              <img src={vertical ? ListInactive : ListActive} height="20px" alt="" style={{ marginRight: "8px" }} />
            </span>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export const SearchBarAircraftStatus = ({
  listFaultPriority,
  listRepetitive,
  isSearchInput,
  setIsSearchInput,
  isFaultPriority,
  setIsFaultPriority,
  isRepetitive,
  setIsRepetitive,
  isLegs,
  setIsLegs,
  froms,
}) => {
  const [openFaultPriority, setOpenFaultPriority] = useState(false);
  const [openRepetitive, setOpenRepetitive] = useState(false);
  const [openLegs, setOpenLegs] = useState(false);
  const listLegs = ["15", "30", "50", "100", "150", "200"];
  const refLegs = useRef(null);
  const refFaultPriority = useRef(null);
  const refRepetitive = useRef(null);

  // Function to detect mouse click outside a component
  function useOutsideClicker(ref, component) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (component === "faultPriority") setOpenFaultPriority(false);
          else if (component === "repetitive") setOpenRepetitive(false);
          else if (component === "legs") setOpenLegs(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, component]);
  }

  useOutsideClicker(refFaultPriority, "faultPriority");
  useOutsideClicker(refRepetitive, "repetitive");
  useOutsideClicker(refLegs, "legs");
  return (
    <div className={`${styles.main} ${styles.main_} ${styles.mainAicraftStatus}`}>
      <Grid container columns={12}>
        <Grid item xs={12} sm={12} md={4} lg={2} xl={2} className={`${styles.searchInput}`}>
          <EleSearchBox placeholder="Search..." isSearchInput={isSearchInput} setIsSearchInput={setIsSearchInput} />
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={10} xl={10} className={`${styles.filterCol_}`}>
          <div className={`${styles.filterDivPosition}`}>
            <span
              className={`${styles.filterDiv} ${
                openFaultPriority || (isFaultPriority.length > 0 && isFaultPriority !== "All") ? styles.active : ""
              }`}
              onClick={() => setOpenFaultPriority(true)}
            >
              <img
                src={isFaultPriority.length > 0 && isFaultPriority !== "All" ? FilterFaultActive : FilterFault}
                height="20px"
                alt="airplane"
                style={{ marginRight: "8px" }}
              />
              <div style={{ marginTop: "2px" }}>{getFilterLabel(isFaultPriority, "Faults Priority")}</div>
            </span>
            <FilterModal
              data={listFaultPriority}
              component={openFaultPriority}
              refs={refFaultPriority}
              title="Fault Priority"
              col="faultPriority"
              isFilter={isFaultPriority}
              setIsFilter={setIsFaultPriority}
              froms={froms}
              key={"Fault-Priority"}
            />
          </div>
          <div className={`${styles.filterDivPosition}`}>
            <span
              className={`${styles.filterDiv} ${
                openRepetitive || (isRepetitive.length > 0 && isRepetitive !== "All") ? styles.active : ""
              }`}
              onClick={() => setOpenRepetitive(true)}
            >
              <img
                src={isRepetitive.length > 0 && isRepetitive !== "All" ? StackActive : StackInactive}
                height="20px"
                alt="airplane"
                style={{ marginRight: "8px" }}
              />
              <div style={{ marginTop: "2px" }}>{getFilterLabel(isRepetitive, "Repetitive")}</div>
            </span>
            <FilterModal
              data={listRepetitive}
              component={openRepetitive}
              refs={refRepetitive}
              title="Repetitive"
              col="repetitive"
              isFilter={isRepetitive}
              setIsFilter={setIsRepetitive}
              froms={froms}
              key={"repetitive"}
            />
          </div>
          <div className={`${styles.filterDivPosition}`}>
            <span className={`${styles.filterDiv} ${styles.active}`} onClick={() => setOpenLegs(true)}>
              <img
                src={isLegs !== "null" ? AirplaneTiltActive : AirplaneTilt}
                height="20px"
                alt="airplane"
                style={{ marginRight: "8px" }}
              />
              <div style={{ marginTop: "2px" }}>
                {getFilterLabel(isLegs, Number(isLegs) > 15 ? `${isLegs.toString()} Legs` : "15 Legs")}
              </div>
            </span>
            <FilterModal
              data={listLegs}
              component={openLegs}
              refs={refLegs}
              title="Legs"
              col="legs"
              isFilter={isLegs}
              setIsFilter={setIsLegs}
              isLegs={isLegs}
              setIsLegs={setIsLegs}
              froms={froms}
              key={"legs"}
              isLast={true}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export const SearchBarAnalytics = ({
  listOperator,
  isOperator,
  setIsOperator,
  listACReg,
  isACReg,
  setIsACReg,
  isDateRange,
  setIsDateRange,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  localTime,
  isLegs,
  setIsLegs,
  froms,
}) => {
  const [openOperator, setOpenOperator] = useState(false);
  const [openACReg, setOpenACReg] = useState(false);
  const refOperator = useRef(null);
  const refACReg = useRef(null);
  const refDateRange = useRef(null);
  const refLegs = useRef(null);
  const dateRange = "";
  const [openDateRange, setOpenDateRange] = useState(false);
  const [open, setOpen] = useState(false);
  const [openLegs, setOpenLegs] = useState(false);
  const listLegs = ["15", "30", "50", "100", "150", "200"];

  // Function to detect mouse click outside a component
  function useOutsideClicker(ref, component) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (component === "operator") setOpenOperator(false);
          else if (component === "ac_reg") setOpenACReg(false);
          else if (component === "dateRange") {
            const path = event.composedPath();
            const dateRange = path?.find((ele) => {
              const res = ele.className?.toString().includes("DateRange");
              return res ?? "";
            });
            dateRange ? setOpenDateRange(true) : setOpenDateRange(false);
          } else if (component === "legs") setOpenLegs(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, component]);
  }

  useOutsideClicker(refOperator, "operator");
  useOutsideClicker(refACReg, "ac_reg");
  // useOutsideClicker(refDateRange, "dateRange");
  useOutsideClicker(refLegs, "legs");
  return (
    <div className={`${styles.main} ${styles.main_}`}>
      <Grid container columns={12}>
        <Grid item xs={12} sm={12} md={4} lg={2} xl={2} className={`${styles.searchInput}`}></Grid>
        <Grid item xs={12} sm={12} md={8} lg={10} xl={10} className={`${styles.filterCol_}`}>
          <div className={`${styles.filterDivPosition}`}>
            <span
              className={`${styles.filterDiv} ${
                openOperator || (isOperator?.length > 0 && isOperator !== "All") ? styles.active : ""
              }`}
              onClick={() => setOpenOperator(true)}
            >
              <img
                src={isOperator?.length > 0 && isOperator !== "All" ? FilterOperatorActive : FilterOperator}
                height="20px"
                alt="airplane"
                style={{ marginRight: "8px" }}
              />
              <div style={{ marginTop: "2px" }}>{getFilterLabel(isOperator, "OperatorAnalytics")}</div>
            </span>
            <FilterModal
              data={listOperator}
              component={openOperator}
              refs={refOperator}
              title="Operator"
              col="OperatorAnalytics"
              isFilter={isOperator}
              setIsFilter={setIsOperator}
              key={"OperatorAnalytics"}
            />
          </div>
          <div className={`${styles.filterDivPosition}`}>
            <span
              className={`${styles.filterDiv} ${
                openACReg || (isACReg?.length > 0 && isACReg !== "All") ? styles.active : ""
              }`}
              onClick={() => setOpenACReg(true)}
            >
              <img
                src={isACReg?.length > 0 && isACReg !== "All" ? AirplaneActive : Airplane}
                height="20px"
                alt="airplane"
                style={{ marginRight: "8px" }}
              />
              <div style={{ marginTop: "2px" }}>{getFilterLabel(isACReg, "Aircraft Reg.")}</div>
            </span>
            <FilterModal
              data={listACReg}
              component={openACReg}
              refs={refACReg}
              title="Aircraft Reg."
              col="ac_reg"
              isFilter={isACReg}
              setIsFilter={setIsACReg}
              key={"ac_reg"}
            />
          </div>
          <div className={`${styles.filterDivPosition}`}>
            <span
              className={`${styles.filterDiv} ${isLegs !== null && isLegs !== undefined ? styles.active : ""}`}
              onClick={() => setOpenLegs(true)}
            >
              <img
                src={isLegs !== null && isLegs !== undefined ? AirplaneTiltActive : AirplaneTilt}
                height="20px"
                alt="airplane"
                style={{ marginRight: "8px" }}
              />
              <div style={{ marginTop: "2px" }}>
                {getFilterLabel(isLegs, Number(isLegs) > 15 ? `${isLegs.toString()} Legs` : "15 Legs")}
              </div>
            </span>
            <FilterModal
              data={listLegs}
              component={openLegs}
              refs={refLegs}
              title="Legs"
              col="legsAnalytics"
              isFilter={isLegs}
              setIsFilter={setIsLegs}
              isLegs={isLegs}
              setIsLegs={setIsLegs}
              froms={froms}
              key={"legsAnalytics"}
            />
          </div>
          <div className={`${styles.filterDivPosition}`}>
            <span
              className={`${styles.filterDiv} ${
                startDate !== undefined && startDate !== null && endDate !== undefined && endDate !== null
                  ? styles.active
                  : ""
              }`}
              onClick={() => setOpenDateRange(true)}
            >
              <img
                src={
                  startDate !== undefined && startDate !== null && endDate !== undefined && endDate !== null
                    ? CalendarActive
                    : Calendar
                }
                height="20px"
                alt="airplane"
                style={{ marginRight: "8px" }}
              />
              <div style={{ marginTop: "2px" }}>{"Date Range"}</div>
            </span>
            <FilterModal
              data={dateRange}
              component={openDateRange}
              refs={refDateRange}
              title="Occurence Date Range"
              col="dateRange"
              isFilter={isDateRange}
              setIsFilter={setIsDateRange}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              open={open}
              setOpen={setOpen}
              localTime={localTime}
              key={"Date-Range"}
              setOpenFilterModal={setOpenDateRange}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
