import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: 16,
          fontFamily: "Gotham-Medium",
          borderBottom: "1px solid #d1d1d1",
          marginBottom: "16px",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          fontSize: 16,
          fontFamily: "Gotham-book",
        },
      },
    },
  },

  typography: {
    fontFamily: "Gotham-Book",
    pageTitle: {
      fontSize: 24,
      fontFamily: "Gotham-Medium",
    },
    tableHeader: {
      fontSize: 16,
      fontFamily: "Gotham-Medium",
    },
    button: {
      fontSize: 16,
      fontFamily: "Gotham-Medium",
    },
  },

  palette: {
    darkblue: {
      main: "#394458",
      dark: "#191e26",
      light: "#7c94bf",
      ligher: "#bfd6ff",
      contrastText: "#fff",
    },
    blue: {
      main: "#088fd1",
      dark: "#076fa3",
      light: "#a3e0ff",
      contrastText: "#fff",
    },
    hyperlink: {
      main: "#2f77ff",
    },
    green: {
      main: "#6ab47b",
    },
    yellow: {
      main: "#f5be59",
      dark: "#FFBC40",
      contrastText: "#fff",
    },
    red: {
      main: "#e95048",
    },
    outline: {
      main: "#d1d1d1",
    },
  },
});

export default theme;
