import { env_var_REACT_APP_API_URL } from "../../envVar/vars";
import useGetGQL from "../useGetGQL";
import useMutateGQL from "../useMutateGQL";
import { useAppSelector } from "../../app/hooks";

export function useGetAllFleetByCompanyCode() {
	const { companyProfile } = useAppSelector((state) => state.userEntity);
	const code = companyProfile?.code;

	const data = useGetGQL({
		gqlquery: code
			? `
        query allLAC{
            allFleets (
               aocCode: "${code}"
            ) {
              edges {
                node {
                  regNo
                  model
                  totalActive
                  totalLost
                  totalRemind
                  totalDefect
                  totalStatus
                  totalExpired
                  totalTagError
                }
              }
            }
          }
        `
			: "",
		querySign: "allFleets",
		url: env_var_REACT_APP_API_URL ?? "",
		defaultIsLoading: true,
		isEdges: true,
	});

	return data;
}

export function useGetAllFleetDistincted() {
	const data = useGetGQL({
		gqlquery: `
        query models{
            allFleets(distinctModel:true){
              edges{
                node{
					id
					model
					engineType
                }
              }
            }
          }
          
        `,
		querySign: "allFleets",
		url: env_var_REACT_APP_API_URL ?? "",
		defaultIsLoading: true,
	});

	return data;
}

export function useGetAllFleetDistinctedEngine() {
	const data = useGetGQL({
		gqlquery: `
        query models{
            allFleets(distinctEngineType:true){
              edges{
                node{
                  engineType
                }
              }
            }
          }
          
        `,
		querySign: "allFleets",
		url: env_var_REACT_APP_API_URL ?? "",
		defaultIsLoading: true,
	});

	return data;
}

export function useGetAllFleetByCompanyCodeSearchedByRegNo({ searchRegNo }) {
	const { companyProfile } = useAppSelector((state) => state.userEntity);
	const code = companyProfile?.code;

	const data = useGetGQL({
		gqlquery: `
        query list{
            allFleets(
            aocCode: "${code}",
            ${searchRegNo ? `icontainsRegNo:"${searchRegNo}"` : ""}
            ){
              edges{
                node{
                  id
                  regNo
                }
              }
            }
          }
        `,
		querySign: "allFleets",
		url: env_var_REACT_APP_API_URL ?? "",
		defaultIsLoading: true,
		isEdges: true,
	});

	return data;
}

export function useGetAllFleetSearchedByRegNo({ searchRegNo }) {
	const { companyProfile } = useAppSelector((state) => state.userEntity);
	const code = companyProfile?.code;

	const data = useGetGQL({
		gqlquery: `
        query list{
            allFleets
            ${searchRegNo ? `(icontainsRegNo:"${searchRegNo}")` : ""}
            {
              edges{
                node{
                  id
                  regNo
                }
              }
            }
          }
        `,
		querySign: "allFleets",
		url: env_var_REACT_APP_API_URL ?? "",
		defaultIsLoading: true,
		isEdges: true,
	});

	return data;
}

export function useGetAllFleetByCompanyCodeSearchedByModel({ inputModel }) {
	const { companyProfile } = useAppSelector((state) => state.userEntity);
	const code = companyProfile?.code;

	const allFleetsFilteredByModel = useGetGQL({
		gqlquery: `
        query list{
            allFleets(
              aocCode: "${code}"
              ${inputModel ? `model:"${inputModel}"` : ""}
              ){
                edges{
                node{
                    id
                    model
                    regNo
                }
                }
            }
        }
        `,
		querySign: "allFleets",
		url: env_var_REACT_APP_API_URL ?? "",
		defaultIsLoading: true,
	});

	return allFleetsFilteredByModel;
}

export function useGetWidgetData({ fleetId }) {
	const statusesForWidget = useGetGQL({
		url: env_var_REACT_APP_API_URL ?? "",
		gqlquery: `
        query fleetById {
            fleet(id:"${fleetId}"){
              id
              regNo
              model
              aocCode
              engineType
              latestLocation
              totalActive
              totalLost
              totalRemind
              totalDefect
              totalStatus
              totalExpired
              totalTagError
              totalUnutilisedSeat
            }
          }
        `,
		querySign: "fleet",
		isEdges: false,
	});

	return statusesForWidget;
}

export function getQueryAllFleetFiltered({
	code,
	search,
	filterModels,
	filterRegNos,
	filterEnType,
}) {
	return `
	query allFleet {
		allFleets
		(
			aocCode: "${code}"
		${
			search ||
			filterModels?.length > 0 ||
			filterRegNos?.length > 0 ||
			filterEnType?.length > 0
				? `
			${search ? `searchBar:"${search}"` : ""}
			${
				filterModels?.length > 0
					? `multipleModel:"${JSON.stringify(filterModels)
							.replaceAll('"', "")
							.replaceAll("[", "")
							.replaceAll("]", "")}"`
					: ""
			}
			${
				filterEnType?.length > 0
					? `multipleEngineType:"${JSON.stringify(filterEnType)
							.replaceAll('"', "")
							.replaceAll("[", "")
							.replaceAll("]", "")}"`
					: ""
			}
			${
				filterRegNos?.length > 0
					? `multipleRegNo:"${JSON.stringify(filterRegNos)
							.replaceAll('"', "")
							.replaceAll("[", "")
							.replaceAll("]", "")}"`
					: ""
			}
			
		`
				: ""
		}
		)
		 {
		  edges {
			node {
			  id
			  regNo
			  model
			  engineType
			  totalActive
			  totalLost
			  totalTagError
			  totalExpired
			  totalRemind
			  totalDefect
			  totalStatus
			  latestPic
			  latestOperationDate
			  latestSync
			}
		  }
		}
	  }
	`;
}
