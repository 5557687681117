
import React from 'react';
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import StyledTextField from "../../../components/inputs/text";
import StyledDropDown from "../../../components/inputs/dropdowns/StyledDropDown";
import LoadingInput from "../../../components/inputs/LoadingInput";
import { useNotification } from "../../../components/notifications/UniversalNotification";
// import useAddEqDatas, { replaceLocation } from "./useUpdateDeviceDatas";
import { validateInputStates } from "../../../components/inputs/useInputState";
import { useUpdateDeviceDatas } from "../../../../models/custom/device-model";
import useUpdateDeviceStates from "./useUpdateDeviceStates";
import {
	useUpdateScanner,
	useDeleteScanner,
} from "../../../../models/custom/scanner-model";

export default function UpdateEquipment({ node, refetch, onClose = () => {} }) {
	const openNotification = useNotification();
	// inputs
	const updateDeviceStates = useUpdateDeviceStates({ nodeToUpdate: node });
	const { allManufacturers, allDeviceModels, allDeviceStatuses } =
		useUpdateDeviceDatas({
			manufacturerID: updateDeviceStates?.manufacturerId?.value,
			node,
			status: updateDeviceStates?.status,
		});
	// ${updateDeviceStates.aocCode ? `aocCode:"${updateDeviceStates.aocCode}",` : ''}
	// ${updateDeviceStates.manufacturerId?.value ? `manufacturerId:${updateDeviceStates.manufacturerId.value},` : ''}
	const { mutate: update } = useUpdateScanner({
		id: node?.id,
		updateDeviceStates,
	});
	const { mutate: deleteMutation } = useDeleteScanner({ id: node?.id });
	const usingStatusesData = allDeviceStatuses.data?.map(({ node }) => {
		return { ...node };
	});
	const usingModelData = allDeviceModels.data?.map(({ node }) => {
		return { ...node };
	});
	const usingManufacturerData = allManufacturers.data?.map(({ node }) => {
		return { ...node };
	});
	// useEffect(() => {
	//     updateDeviceStates.deviceId.setValue(node?.id ?? "")
	//     updateDeviceStates.deviceName.setValue(node?.name ?? "")
	//     updateDeviceStates.manufacturerId.setValue(node?.model?.manufacturer?.id)
	//     updateDeviceStates.modelId.setValue(node?.model?.id)
	//     updateDeviceStates.status.setValue(node?.status?.id ?? usingStatusesData[0]?.id)
	// }, [node, usingStatusesData])
	return (
		<Box
			style={{
				width: "100%",
				borderRadius: "0px",
				height: "100%",
				position: "relative",
			}}
		>
			<Box
				style={{
					padding: "1em",
				}}
			>
				<Grid container spacing="1em">
					<GridItem
						title={"Device ID"}
						isRequired={updateDeviceStates.deviceId.isMandatory}
						inputComp={
							<StyledTextField
								inputRef={updateDeviceStates.deviceId.inputRef}
								onChange={(e) => {
									updateDeviceStates.deviceId.setValue(
										e.target.value
									);
								}}
								value={updateDeviceStates.deviceId.value}
								fullWidth
								error={updateDeviceStates.deviceId.isError}
								helperText={
									updateDeviceStates.deviceId.errorMessage
								}
							/>
						}
					/>
					<GridItem
						title={"Device Name"}
						isRequired={updateDeviceStates.deviceName.isMandatory}
						inputComp={
							<StyledTextField
								inputRef={
									updateDeviceStates.deviceName.inputRef
								}
								onChange={(e) => {
									updateDeviceStates.deviceName.setValue(
										e.target.value
									);
								}}
								value={updateDeviceStates.deviceName.value}
								fullWidth
								error={updateDeviceStates.deviceName.isError}
								helperText={
									updateDeviceStates.deviceName.errorMessage
								}
							/>
						}
					/>
					{updateDeviceStates?.manufacturerId?.value && (
						<GridItem
							title={"Manufacturer"}
							isRequired={
								updateDeviceStates.manufacturerId.isMandatory
							}
							inputComp={
								allManufacturers.isLoading ? (
									<LoadingInput />
								) : (
									<StyledDropDown
										inputRef={
											updateDeviceStates.manufacturerId
												.inputRef
										}
										value={
											updateDeviceStates?.manufacturerId
												?.value
										}
										handleChange={(e) => {
											updateDeviceStates.manufacturerId.setValue(
												e.target.value
											);
										}}
										options={usingManufacturerData.map(
											({ code, id }) => {
												return {
													label: code,
													value: id,
												};
											}
										)}
										error={
											updateDeviceStates.manufacturerId
												.isError
										}
										helperText={
											updateDeviceStates.manufacturerId
												.errorMessage
										}
									/>
								)
							}
						/>
					)}
					<GridItem
						title={"Model"}
						isRequired={updateDeviceStates.modelId.isMandatory}
						inputComp={
							allDeviceModels.isLoading ? (
								<LoadingInput />
							) : (
								<StyledDropDown
									inputRef={
										updateDeviceStates.modelId.inputRef
									}
									// defaultValue={itemTypeData[0]?.node?.id}
									value={updateDeviceStates.modelId.value}
									handleChange={(e) => {
										updateDeviceStates.modelId.setValue(
											e.target.value
										);
									}}
									options={usingModelData.map(
										({ name, id }) => {
											return {
												label: name,
												value: id,
											};
										}
									)}
									error={
										updateDeviceStates.manufacturerId
											.isError
									}
									helperText={
										updateDeviceStates.manufacturerId
											.errorMessage
									}
								/>
							)
						}
					/>
					{updateDeviceStates?.status?.value && (
						<GridItem
							title={"Status"}
							isRequired={updateDeviceStates.status.isMandatory}
							inputComp={
								allDeviceStatuses.isLoading ? (
									<LoadingInput />
								) : (
									<StyledDropDown
										inputRef={
											updateDeviceStates.status.inputRef
										}
										// defaultValue={statusesData[0]?.node?.id}
										value={updateDeviceStates.status.value}
										error={
											updateDeviceStates.status.isError
										}
										helperText={
											updateDeviceStates.status
												.errorMessage
										}
										handleChange={(e) => {
											updateDeviceStates.status.setValue(
												e.target.value
											);
										}}
										options={usingStatusesData.map(
											({ name, id, code }) => {
												let labelHolder = name;
												switch (code) {
													case "A":
														labelHolder = "Active";
														break;
													case "I":
														labelHolder =
															"Inactive";
														break;
													default:
														labelHolder = "Active";
														break;
												}
												return {
													label: labelHolder,
													value: id,
												};
											}
										)}
									/>
								)
							}
						/>
					)}

					<Box
						sx={{
							position: "absolute",
							bottom: "0px",
							width: "100%",
						}}
					>
						<Divider />
						<Grid container>
							<Grid
								item
								xs={4}
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<Button
									onClick={() => {
										onClose();
									}}
									sx={{
										height: "42px",
										margin: "1em",
										width: "100%",
										borderRadius: "8px",
									}}
								>
									Cancel
								</Button>
							</Grid>
							<Grid
								item
								xs={4}
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<Button
									variant="contained"
									color="primary"
									sx={{
										height: "42px",
										margin: "1em",
										width: "100%",
										borderRadius: "8px",
									}}
									onClick={() => {
										const { isOk } = validateInputStates(
											{
												deviceId:
													updateDeviceStates.deviceId,
												deviceName:
													updateDeviceStates.deviceName,
												manufacturerId:
													updateDeviceStates.manufacturerId,
												modelId:
													updateDeviceStates.modelId,
												status: updateDeviceStates.status,
											},
											true
										);
										if (isOk) {
											update().then((res) => {
												const {
													isError,
													errorMessage,
													data,
												} = res;
												const setClose =
													onClose ?? (() => {});
												if (!isError) {
													openNotification(
														"Device updated!",
														{
															variant: "info",
															onClose: () => {
																setClose();
																refetch();
															},
														}
													);
												} else {
													openNotification(
														"Update failed",
														{
															variant: "error",
															helperText: `There was an error saving your changes. ${errorMessage}`,
														}
													);
												}
											});
										} else {
											// openNotification('Please fill in all the required field(s)', {
											//     variant: 'warning',
											// })
											// do nothing instead
										}
									}}
								>
									Save
								</Button>
							</Grid>
							<Grid
								item
								xs={4}
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<Button
									onClick={() => {
										// onClose()
										openNotification("Are you sure?", {
											variant: "warning",
											onConfirm: () => {
												deleteMutation().then(() => {
													refetch();
												});
											},
										});
									}}
									sx={{
										height: "42px",
										margin: "1em",
										width: "100%",
										borderRadius: "8px",
										color: "white",
									}}
									variant="contained"
									color="warning"
								>
									Delete
								</Button>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Box>
		</Box>
	);
}
function GridItem({ title, inputComp, isRequired = true }) {
	return (
		<Grid item xs={6}>
			<Typography
				sx={{
					fontSize: "14px",
					marginBottom: "0.3em",
				}}
			>
				{title}
				{isRequired ? <span style={{ color: "red" }}>*</span> : ""}
			</Typography>
			{inputComp}
		</Grid>
	);
}
