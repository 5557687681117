import React, { useCallback } from "react";
import { Grid } from "@mui/material";
import styles from "./DDML.module.css";
import {
  InputField,
  RadioField,
  SelectField,
  InputFieldCalendar,
  NewFileUploadComponent,
  SelectFieldAutocomplete,
} from "pages/elevade/component/InputForm";
import DefermentExpiry from "./DefermentExpiry";
import DefermentExpiryStructure from "./DefermentExpiryStructure";

const NewDDMLForm = ({
  formData,
  setFormData,
  updateArrayColumn,
  setFormType,
  removeFromArray,
  formType,
  aircraftList,
  validateError,
  melCatType,
  setMelCatType,
  isExpiry,
  isBalance,
  limitation,
  setLimitation,
  ntc,
  setNtc,
  reInspection,
  setReInspection,
  file,
  setFile,
  departmentList,
}) => {
  const DDMLType = useCallback(() => {
    return (
      <>
        <Grid item xs={4} className={`${styles.rowTitle} ${styles.rowInputStart}`}>
          DDML Type<span className={styles.red}>*</span>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            borderBottom: "1px solid #E8E8E8",
            paddingBottom: "24px",
          }}
        >
          <SelectField
            placeholder="Select DDML Type"
            fullWidth
            options={[
              { code: "MEL CAT", name: "MEL CAT" },
              { code: "AMD", name: "AMD" },
              { code: "STR/FC/FH", name: "STR/FC/FH" },
              { code: "Hydraulic Sampling", name: "Hydraulic Sampling" },
              { code: "Fuel Sampling", name: "Fuel Sampling" },
            ]}
            formData={formData}
            col={"formType"}
            onChange={(e) => {
              setFormData(updateArrayColumn("formType", e.target.value));
              setFormType(e.target.value);
              removeFromArray("formType");
            }}
          />
        </Grid>
      </>
    );
  }, [formData]);

  return (
    <>
      <Grid container columns={4} id={"ddml-form"} sx={{ zIndex: 3, marginTop: "52px" }}>
        <DDMLType />
        {formData[0].formType !== undefined && (
          <>
            <Grid item xs={2} className={`${styles.rowTitle} ${styles.rowInputStart}`}>
              Aircraft Registration<span className={styles.red}>*</span>
            </Grid>
            <Grid item xs={2} className={`${styles.rowTitle} ${styles.rowInputEnd}`}>
              Station<span className={styles.red}>*</span>
            </Grid>
            <Grid item xs={2} className={`${styles.rowInputStart}`}>
              <SelectFieldAutocomplete
                placeholder="Select A/C"
                fullWidth
                options={aircraftList}
                formData={formData}
                col={"aircraftReg"}
                onChange={(e) => {
                  setFormData(updateArrayColumn("aircraftReg", e.target.textContent || e));
                  removeFromArray("aircraftReg");
                }}
                value={formData[0].aircraftReg}
                error={validateError("aircraftReg")}
              />
            </Grid>
            <Grid item xs={2} className={`${styles.rowInputEnd} ${styles.padTop12}`}>
              {formData[0].location}
              {/* <SelectFieldAutocomplete
                placeholder="Select Station"
                fullWidth
                options={[
                  { id: "BKI", name: "BKI" },
                  { id: "JHB", name: "JHB" },
                  { id: "KUL", name: "KUL" },
                  { id: "KCH", name: "KCH" },
                  { id: "PEN", name: "PEN" },
                ]}
                formData={formData}
                col={"location"}
                onChange={(e) => {
                  setFormData(updateArrayColumn("location", e.target.textContent));
                  removeFromArray("location");
                }}
                value={formData[0].location}
                error={validateError("location")}
              /> */}
            </Grid>
            <Grid item xs={4} className={`${styles.rowTitle} ${styles.rowInputStart}`}>
              DDML No.<span className={styles.red}>*</span>
            </Grid>
            <Grid item xs={4} className={``}>
              <InputField
                fullWidth
                placeholder={"Insert DDML number"}
                formData={formData}
                setFormData={setFormData}
                col={"ddmlNo"}
                updateArrayColumn={updateArrayColumn}
                error={validateError("ddmlNo")}
              />
            </Grid>
            <Grid item xs={4} className={`${styles.rowTitle} ${styles.rowInputStart}`}>
              Defect Description
            </Grid>
            <Grid item xs={4} className={``}>
              <InputField
                fullWidth
                placeholder={"Insert text"}
                formData={formData}
                setFormData={setFormData}
                col={"defectDesc"}
                updateArrayColumn={updateArrayColumn}
                error={validateError("defectDesc")}
                multiline
              />
            </Grid>
            {formData[0]?.formType === "MEL CAT" && (
              // ONLY FOR FORM TYPE = MEL CAT
              <>
                <Grid item xs={4} className={`${styles.rowTitle} ${styles.rowInputStart}`}>
                  MEL CAT Type
                </Grid>
                <Grid item xs={4}>
                  <RadioField
                    options={[
                      { code: "CAT A", name: "CAT A" },
                      { code: "CAT B", name: "CAT B" },
                      { code: "CAT C", name: "CAT C" },
                      { code: "CAT D", name: "CAT D" },
                    ]}
                    value={melCatType}
                    setValue={setMelCatType}
                    formData={formData}
                    setFormData={setFormData}
                    col={"melCatType"}
                    updateArrayColumn={updateArrayColumn}
                    error={validateError("melCatType")}
                  />
                </Grid>
                <Grid item xs={2} className={`${styles.rowTitle} ${styles.rowInputStart}`}>
                  Raised Date<span className={styles.red}>*</span>
                </Grid>
                <Grid item xs={2} className={`${styles.rowTitle} ${styles.rowInputEnd}`}>
                  Expiry Date<span className={styles.red}>*</span>
                </Grid>
                <Grid
                  item
                  xs={2}
                  className={`${styles.rowInputStart}`}
                  sx={{
                    borderBottom: "1px solid #E8E8E8",
                    paddingBottom: "24px",
                  }}
                >
                  <InputFieldCalendar
                    fullWidth
                    placeholder={"Select date"}
                    formData={formData}
                    setFormData={setFormData}
                    col={"raisedDate"}
                    error={validateError("raisedDate")}
                    disableFuture
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  className={`${styles.rowInputEnd}`}
                  sx={{
                    borderBottom: "1px solid #E8E8E8",
                    paddingBottom: "24px",
                  }}
                >
                  <InputFieldCalendar
                    fullWidth
                    placeholder={"Select date"}
                    formData={formData}
                    setFormData={setFormData}
                    col={"expiryDate"}
                    error={validateError("expiryDate")}
                    disableFuture={false}
                    startDate={formData[0].raisedDate}
                  />
                </Grid>
                {formData[0].melCatType === "CAT A" && (
                  <DefermentExpiry
                    formData={formData}
                    setFormData={setFormData}
                    updateArrayColumn={updateArrayColumn}
                    formType={formData[0]?.formType}
                    validateError={validateError}
                  />
                )}
              </>
            )}
            <Grid item xs={4} className={`${styles.rowTitle} ${styles.rowInputStart}`}>
              {formData[0]?.formType === "MEL CAT" ||
              formData[0]?.formType === "AMD" ||
              formData[0]?.formType === "Hydraulic Sampling" ||
              formData[0]?.formType === "Fuel Sampling"
                ? "MEL Reference"
                : "Reference"}
            </Grid>
            <Grid item xs={4} className={``}>
              <InputField
                fullWidth
                placeholder={
                  formData[0]?.formType === "MEL CAT" ||
                  formData[0]?.formType === "AMD" ||
                  formData[0]?.formType === "Hydraulic Sampling" ||
                  formData[0]?.formType === "Fuel Sampling"
                    ? "Insert MEL ref. number"
                    : "Insert ref. number"
                }
                formData={formData}
                setFormData={setFormData}
                col={"melRef"}
                updateArrayColumn={updateArrayColumn}
              />
            </Grid>
            {(formData[0]?.formType === "AMD" ||
              formData[0]?.formType === "STR/FC/FH" ||
              formData[0]?.formType === "Hydraulic Sampling" ||
              formData[0]?.formType === "Fuel Sampling") && (
              <>
                <Grid item xs={4} className={`${styles.rowTitle} ${styles.rowInputStart}`}>
                  Raised Date<span className={styles.red}>*</span>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    borderBottom: "1px solid #E8E8E8",
                    paddingBottom: "24px",
                  }}
                >
                  <InputFieldCalendar
                    fullWidth
                    placeholder={"Select date"}
                    formData={formData}
                    setFormData={setFormData}
                    col={"raisedDate"}
                    error={validateError("raisedDate")}
                    disableFuture
                  />
                </Grid>
                {(formData[0]?.formType === "AMD" ||
                  formData[0]?.formType === "Hydraulic Sampling" ||
                  formData[0]?.formType === "Fuel Sampling") && (
                  <>
                    <Grid item xs={4} className={`${styles.title}`} sx={{ paddingTop: "24px" }}>
                      Deferment Expiry
                    </Grid>
                    <Grid item xs={4} className={`${styles.title}`} sx={{ paddingTop: "24px" }}>
                      Calendar Days
                    </Grid>
                    <Grid item xs={4} sx={{ color: "#838383", fontSize: "14px" }}>
                      Set the interval to get deferment expiry
                    </Grid>
                    <Grid item xs={2} className={`${styles.rowTitle} ${styles.rowInputStart}`}>
                      Expiry
                    </Grid>
                    <Grid item xs={2} className={`${styles.rowTitle} ${styles.rowInputEnd}`}>
                      Balance
                    </Grid>
                    <Grid item xs={2} className={`${styles.rowTitle}`} sx={{ paddingTop: "0px" }}>
                      {isExpiry}
                    </Grid>
                    <Grid item xs={2} className={`${styles.rowTitle} ${styles.rowInputEnd}`} sx={{ paddingTop: "0px" }}>
                      {isBalance || "-"}
                    </Grid>
                    <Grid item xs={2} className={`${styles.rowTitle} ${styles.rowInputStart}`}>
                      Interval Days
                    </Grid>
                    <Grid item xs={2} className={`${styles.rowTitle} ${styles.rowInputEnd}`}>
                      Start Date
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      className={`${styles.rowInputStart}`}
                      sx={{
                        borderBottom: "1px solid #E8E8E8",
                        paddingBottom: "24px",
                      }}
                    >
                      <InputField
                        fullWidth
                        placeholder={""}
                        formData={formData}
                        setFormData={setFormData}
                        col={"calendarDaysInterval"}
                        updateArrayColumn={updateArrayColumn}
                        numbersonly
                      />
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      className={`${styles.rowInputEnd}`}
                      sx={{
                        borderBottom: "1px solid #E8E8E8",
                        paddingBottom: "24px",
                      }}
                    >
                      <InputFieldCalendar
                        fullWidth
                        placeholder={"Select date"}
                        formData={formData}
                        setFormData={setFormData}
                        col={"calendarDaysStartDate"}
                        error={validateError("calendarDaysStartDate")}
                        disableFuture={false}
                      />
                    </Grid>
                  </>
                )}
              </>
            )}
            {formData[0]?.formType === "STR/FC/FH" && (
              <DefermentExpiryStructure
                formData={formData}
                setFormData={setFormData}
                updateArrayColumn={updateArrayColumn}
                formType={formType}
              />
            )}
            <Grid item xs={4} className={`${styles.rowTitle} ${styles.rowInputStart}`}>
              Remarks (MOC Highlight)
            </Grid>
            <Grid item xs={4} className={``}>
              <InputField
                fullWidth
                placeholder={"Insert text"}
                formData={formData}
                setFormData={setFormData}
                col={"mocHighlight"}
                updateArrayColumn={updateArrayColumn}
                error={validateError("mocHighlight")}
                multiline
              />
            </Grid>
            <Grid item xs={4} className={`${styles.rowTitle} ${styles.rowInputStart}`}>
              Rectification Status
            </Grid>
            <Grid item xs={4} className={``}>
              <InputField
                fullWidth
                placeholder={"Insert text"}
                formData={formData}
                setFormData={setFormData}
                col={"actionBy145"}
                updateArrayColumn={updateArrayColumn}
                error={validateError("actionBy145")}
                multiline
                disabled={formData[0]._id ? true : false}
              />
            </Grid>
            {formData[0]?.formType === "MEL CAT" && (
              // ONLY FOR FORM TYPE = MEL CAT
              <>
                <Grid item xs={2} className={`${styles.rowTitle} ${styles.rowInputStart}`}>
                  Aircraft Limitation<span className={styles.red}>*</span>
                </Grid>
                <Grid item xs={2} className={`${styles.rowTitle} ${styles.rowInputEnd}`}>
                  Notice to Crew (NTC)<span className={styles.red}>*</span>
                </Grid>
                <Grid item xs={2} className={`${styles.rowInputStart}`}>
                  <RadioField
                    options={[
                      { code: false, name: "No" },
                      { code: true, name: "Yes" },
                    ]}
                    value={limitation}
                    setValue={setLimitation}
                    formData={formData}
                    setFormData={setFormData}
                    col={"limitations"}
                    updateArrayColumn={updateArrayColumn}
                    error={validateError("limitations")}
                  />
                </Grid>
                <Grid item xs={2} className={`${styles.rowInputEnd}`}>
                  <RadioField
                    options={[
                      { code: false, name: "No" },
                      { code: true, name: "Yes" },
                    ]}
                    value={ntc}
                    setValue={setNtc}
                    formData={formData}
                    setFormData={setFormData}
                    col={"noticeToCrew"}
                    updateArrayColumn={updateArrayColumn}
                    error={validateError("noticeToCrew")}
                  />
                </Grid>
                {(limitation || formData[0].limitations) && (
                  <>
                    <Grid item xs={4} className={`${styles.rowTitle}`}>
                      Limitation Description
                      <span className={styles.red}>*</span>
                    </Grid>
                    <Grid item xs={4} className={``}>
                      <InputField
                        fullWidth
                        placeholder={"Insert text"}
                        formData={formData}
                        setFormData={setFormData}
                        col={"limitationsDesc"}
                        updateArrayColumn={updateArrayColumn}
                        error={validateError("limitationsDesc")}
                      />
                    </Grid>
                  </>
                )}
              </>
            )}
            <Grid item xs={4} className={`${styles.rowTitle} ${styles.rowInputStart}`}>
              Require Reinspection<span className={styles.red}>*</span>
            </Grid>
            <Grid item xs={4} className={`${styles.rowInputStart}`}>
              <RadioField
                options={[
                  { code: false, name: "No" },
                  { code: true, name: "Yes" },
                ]}
                value={reInspection}
                setValue={setReInspection}
                formData={formData}
                setFormData={setFormData}
                col={"reInspection"}
                updateArrayColumn={updateArrayColumn}
                error={validateError("reInspection")}
              />
            </Grid>
            <Grid item xs={4} className={`${styles.rowTitle}`}>
              Assign To
            </Grid>
            <Grid item xs={4} className={``}>
              <SelectFieldAutocomplete
                placeholder="Select department"
                fullWidth
                options={departmentList}
                formData={formData}
                setFormData={setFormData}
                col={"assignedTo"}
                value={formData[0].assignedTo}
                onChange={(e) => setFormData(updateArrayColumn("assignedTo", e.target.textContent))}
              />
            </Grid>
            <Grid item xs={4} className={`${styles.rowTitle}`}>
              JML/DDML Upload<span className={styles.red}>*</span>
            </Grid>
            <Grid item xs={4} className={``}>
              <NewFileUploadComponent
                formData={formData}
                file={file}
                setFile={setFile}
                errorForm={validateError("attachment")}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default NewDDMLForm;
