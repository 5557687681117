import { subMinutes } from "date-fns";

// A function to set date range based on selected hours
export const handlePredefinedRange = (field, localTime, isStart) => {
  let start = new Date(new Date().getTime() - field * 60 * 60 * 1000);
  start = localTime ? start : subMinutes(new Date(start), -new Date(start).getTimezoneOffset());
  let end = localTime ? new Date() : subMinutes(new Date(), -new Date().getTimezoneOffset());

  return isStart ? start : end;
};
