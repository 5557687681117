import { env_var_REACT_APP_API_URL } from "../../envVar/vars";
import useGetGQL from "../useGetGQL";
import useMutateGQL from "../useMutateGQL";
import { useAppSelector } from "../../app/hooks";

export function useGetAllInventoryHistory({ id, search }) {
	const history = useGetGQL({
		gqlquery: `
        query allInventoryHistories {
            allInventoryHistories(inventories: "${id}", 
            ${
				search && search !== "" && search !== null
					? `, historyDetails:"${search}"`
					: ""
			}
            orderByCreatedAt:"-created_at") {
                edges {
                  node {
                    id
                    createdAt
                    createdBy
                    avatarCreated
                    historydetailsSet {
                      edges {
                        node {
                          description
                        }
                      }
                    }
                 }
               }
            }
        }
        `,
		querySign: "allInventoryHistories",
		url: env_var_REACT_APP_API_URL ?? "",
		isEdges: true,
	});

	return history;
}