import { app } from "../../firebase";
import axios from "axios";
import Cookies from 'js-cookie';

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

const instance = axios.create({
  baseURL: BASE_API_URL,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

const instanceEntity = axios.create({
  timeout: 50000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
})

const getHeaders = () => {
  const token = Cookies.get('elevadeToken');

  if (token) {
    instanceEntity.defaults.headers.common['Authorization'] = token;
  }
};

async function makeAPIRequest(method, endPoint, data = {}, params = null) {
  const user = app.auth().currentUser;

  if (user) {
    const token = await user.getIdToken(true);
    instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  return instance({
    method: method,
    url: endPoint,
    data: data,
    params: params,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

async function makeAPIRequestEntity(method, baseUrl, endPoint, data = {}, params = null) {
  getHeaders();

  return instanceEntity({
    baseURL: baseUrl ? baseUrl : process.env.REACT_APP_BASE_API_URL_2,
    method: method,
    url: endPoint,
    data: data,
    params: params
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    })
}

export const HttpGet = ({ url, params = null, body = {} }) => {
  return makeAPIRequest("GET", url, body, params);
};

export const HttpGetEntity = ({ url, params = null, baseUrl, body = {} }) => {
  return makeAPIRequestEntity("GET", baseUrl, url, body, params);
};

export const HttpPost = ({ url, body, params = null }) => {
  return makeAPIRequest("POST", url, body, params);
};

export const HttpPostEntity = ({ url, body, baseUrl = null, params = null }) => {
  return makeAPIRequestEntity("POST", baseUrl, url, body, params);
};

export const HttpPatch = ({ url, body, params = null }) => {
  return makeAPIRequest("PATCH", url, body, params);
};

export const HttpDelete = ({ url, params = null, body = null }) => {
  return makeAPIRequest("DELETE", url, (body = {}), params);
};
