import React, { useState, useEffect } from "react";
import { Grid, Typography, styled, Tooltip, tooltipClasses } from "@mui/material";
import styles from "../Dasr.module.css";
import { formatDateDasr, formatTimeDasr, formatTimeLocal } from "pages/elevade/component/Helpers";
import { makeStyles } from "@mui/styles";
import MUIPagination from "pages/elevade/component/Pagination";
import ElevadeDrawer from "../component/Drawer";
import DASRForm from "../component/DASRForm";
import ErrorAirBallonNoData from "../component/ErrorAirBalloonNoData";
import SearchBar from "./SearchBar";
import ActiveFilters from "./ActiveFilters";

const AircraftStatusMaintenance = ({ setNewForm, formType, ...props }) => {
  const [open, setOpen] = useState();
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [data, setData] = useState([]);
  const [id, setId] = useState([]);
  const [activeIndex, setActiveIndex] = useState("");
  const [openTitle, setOpenTitle] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const InitialFilters = { search: [], location: [], aircraftReg: [], assignedTo: [], raisedDate: [], expiryDate: [] };
  const [isFilters, setIsFilters] = useState(InitialFilters);
  const [filteredData, setFilteredData] = useState([]);

  let sliceStart = page > 0 ? perPage * (page - 1) : 0;
  let sliceEnd = parseInt(perPage) + sliceStart;

  // Custom styles
  const useStyles = makeStyles(() => ({
    ul: {
      "& .Mui-selected": {
        color: "#fff",
        backgroundColor: "#088FD1",
        border: "1px solid #088FD1",
        "&:hover": {
          backgroundColor: "#088FD1",
        },
      },
    },
    gridItem: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-box-orient": "vertical",
      "-webkit-line-clamp": 2, // Number of lines to show
      whiteSpace: "pre-line",
      fontSize: "14px",
    },
  }));

  const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#FFFEE8",
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: 11,
      },
      whiteSpace: "pre-line",
    })
  );

  const classes = useStyles();

  useEffect(() => {
    let totalRows = filteredData?.filter((e) => e.formType === formType)?.length;
    let totalPage = Math.floor(filteredData?.filter((e) => e.formType === formType)?.length / perPage);
    if (totalRows % perPage !== 0) totalPage = totalPage + 1;
    setTotalRows(totalRows);
    setTotalPage(totalPage);
  }, [filteredData, perPage, formType]);

  useEffect(() => {
    if (!open) setActiveIndex();
  }, [open]);

  // Applying filters
  useEffect(() => {
    let theData = props.dataDDML;
    let data_ = [];
    setFilteredData(theData);
    // Search input fields
    if (isFilters.search.length !== 0) {
      data_ = theData?.filter(
        (item) =>
          isFilters.search.some((searchTerm) => item.location?.toLowerCase()?.includes(searchTerm?.toLowerCase())) ||
          isFilters.search.some((searchTerm) => item.aircraftReg?.toLowerCase()?.includes(searchTerm?.toLowerCase())) ||
          isFilters.search.some((searchTerm) => item.defectDesc?.toLowerCase()?.includes(searchTerm?.toLowerCase()))
      );
      setFilteredData(data_);
    }
    // Dropdown selection
    if (isFilters.location.length > 0) setFilteredData(theData?.filter((e) => isFilters.location.includes(e.location)));
    if (isFilters.aircraftReg.length > 0)
      setFilteredData(theData?.filter((e) => isFilters.aircraftReg.includes(e.aircraftReg)));
    // Raised Date
    if (isFilters.raisedDate.length > 0)
      setFilteredData(theData?.filter((e) => e?.raisedDate?.startsWith(isFilters.raisedDate)));
    // Expiry Date
    if (isFilters.expiryDate.length > 0)
      setFilteredData(theData?.filter((e) => e?.expiryDate?.startsWith(isFilters.expiryDate)));
  }, [isFilters, props.dataDDML]);

  function getRectificationStatus(item) {
    // TODO
    // if (item?.pushToSummary === "") return undefined;
    // else {
    //   return item;
    // }
  }

  function getLimitations(aircraftReg) {
    let data = props.limitationData.filter((e) => e.aircraftReg === aircraftReg);
    if (data.length > 0) {
      return data[0].limitationsDesc;
    } else return "-";
  }

  const IconUnreadIndicator = ({ data }) => {
    let unreadLogs = data.unreadLogs;
    let count = unreadLogs?.materialTool.length + unreadLogs?.planningTech.length + unreadLogs?.rectification.length;

    return (
      count > 0 && (
        <div style={{ position: "absolute", right: "24px" }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
            <circle cx="5" cy="5" r="5" fill="#0ADDDD" />
          </svg>
        </div>
      )
    );
  };

  return props.dataDDML?.filter((e) => e.formType === formType).length > 0 ? (
    <>
      <div style={{ padding: "40px 0px 0px 24px" }}>
        <SearchBar
          open={openModal}
          setOpen={setOpenModal}
          data={props.dataDDML?.filter((e) => e.formType === formType)}
          isFilters={isFilters}
          setIsFilters={setIsFilters}
          openTitle={openTitle}
          setOpenTitle={setOpenTitle}
          tab={props.tab}
        />
        <ActiveFilters isFilters={isFilters} setIsFilters={setIsFilters} initialFilters={InitialFilters} />
      </div>
      <div className={styles.tableDiv} style={{ marginTop: "20px" }}>
        <Grid container columns={10}>
          {props.tab === "4" && (
            <>
              <Grid item xs={0.5} className={styles.tableHeader}>
                No.
              </Grid>
            </>
          )}
          <Grid item xs={props.tab === "4" ? 1 : 0.7} className={styles.tableHeader}>
            A/C Reg.
          </Grid>
          {props.tab !== "4" && (
            <>
              <Grid item xs={1} className={styles.tableHeader}>
                Start
              </Grid>
              <Grid item xs={1} className={styles.tableHeader}>
                Loc
              </Grid>
            </>
          )}
          <Grid
            item
            xs={props.tab === "4" ? 4.5 : 2.5}
            className={styles.tableHeader}
            sx={{ justifyContent: "flex-start" }}
          >
            Details
          </Grid>
          {props.tab !== "4" && (
            <>
              <Grid item xs={1} className={styles.tableHeader}>
                ETS Date
              </Grid>
              <Grid item xs={1} className={styles.tableHeader}>
                ETS Time
              </Grid>
            </>
          )}
          <Grid
            item
            xs={props.tab === "4" ? 4 : 2.8}
            className={styles.tableHeader}
            sx={{ justifyContent: "flex-start" }}
          >
            {props.tab !== "4" ? "Rectification Status" : "Limitation"}
          </Grid>
          {filteredData.filter((e) => e.formType === formType) &&
            filteredData
              .filter((e) => e.formType === formType)
              .slice(sliceStart, sliceEnd)
              .map((e, index) => {
                return (
                  <>
                    <Grid item xs={10} key={index}>
                      <Grid
                        container
                        columns={10}
                        className={`${styles.lineItems} ${activeIndex === index ? styles.selectedIndex : ""}`}
                        onClick={() => {
                          setOpen(true);
                          setData([e]);
                          setNewForm(false);
                          setId(e.id);
                          setActiveIndex(index);
                        }}
                      >
                        {props.tab === "4" && (
                          <>
                            <Grid item xs={0.5} className={styles.tableBody}>
                              {index + 1}.
                            </Grid>
                          </>
                        )}
                        <Grid
                          item
                          xs={props.tab === "4" ? 1 : 0.7}
                          className={`${styles.tableBody}`}
                          sx={{ alignItems: "center" }}
                        >
                          {e.aircraftReg}
                        </Grid>
                        {props.tab !== "4" && (
                          <>
                            <Grid item xs={1} className={`${styles.tableBody}`} sx={{ alignItems: "center" }}>
                              {formatDateDasr(e.inputDate)}
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              className={`${styles.tableBody} ${styles.toUpperCase}`}
                              sx={{ alignItems: "center" }}
                            >
                              {e.location || "-"}
                            </Grid>
                          </>
                        )}
                        <Grid
                          item
                          xs={props.tab === "4" ? 4.5 : 2.5}
                          className={`${styles.tableBody} ${styles.toUpperCase}`}
                          sx={{ alignItems: "center", justifyContent: "flex-start" }}
                        >
                          <LightTooltip title={e.defectDesc} followCursor placement="bottom-start">
                            <Typography className={classes.gridItem}>{e.defectDesc || "-"}</Typography>
                          </LightTooltip>
                        </Grid>
                        {props.tab !== "4" && (
                          <>
                            <Grid item xs={1} className={`${styles.tableBody}`} sx={{ alignItems: "center" }}>
                              {e.tba === true ? "TBA" : formatDateDasr(e.ets)}
                            </Grid>
                            <Grid item xs={1} className={`${styles.tableBody}`} sx={{ alignItems: "center" }}>
                              {e.tba === true ? "TBA" : formatTimeLocal(e.etsTime)}
                            </Grid>
                          </>
                        )}
                        <Grid
                          item
                          xs={props.tab === "4" ? 4 : 2.8}
                          className={`${styles.tableBody} ${styles.toUpperCase}`}
                          sx={{ alignItems: "center", justifyContent: "flex-start", position: "relative" }}
                        >
                          <LightTooltip
                            title={getRectificationStatus(e.statusLog) || e.rectificationStatus}
                            followCursor
                            placement="bottom-start"
                          >
                            <Typography className={classes.gridItem}>
                              {/* {getRectificationStatus(e.statusLog) || e.rectificationStatus || "-"} */}
                              {props.tab === "4"
                                ? getLimitations(e.aircraftReg)
                                : getRectificationStatus(e.statusLog) || e.rectificationStatus || "-"}
                            </Typography>
                          </LightTooltip>
                          {props.tab !== "4" && <IconUnreadIndicator data={e} />}
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                );
              })}
          <Grid item xs={10} className={`${styles.lineItems}`}>
            <MUIPagination
              page={parseInt(page)}
              setPage={setPage}
              setPerPage={setPerPage}
              totalPage={parseInt(totalPages)}
              classes={{ ul: classes.ul }}
              totalRows={parseInt(totalRows)}
              sliceStart={sliceStart}
              initialPerPage={perPage}
            />
          </Grid>
        </Grid>
      </div>
      <ElevadeDrawer
        open={open}
        setOpen={setOpen}
        data={data}
        Component={DASRForm}
        formType={formType}
        id={id}
        setTriggerApi={props.setTriggerApi}
        tab={props.tab}
        dataForm={data[0]}
        initialData={data[0]}
        dataList={props.dataDDML}
        // limitaionDesc={isLimitationDesc}
        {...props}
      />
    </>
  ) : (
    <ErrorAirBallonNoData />
  );
};

export default AircraftStatusMaintenance;
