
import React from 'react';
import { Box, Typography, Skeleton } from "@mui/material";
import { Fragment, useContext } from "react";
import { ACExtraDetails } from "./ACExtraDetails";
import { LifebouyIcon } from "../../icons/statusIcons";
import { CountContext } from "./Main";
import { gothamBold } from "../../../../constants/fonts";
export default function ActiveCountWidget() {
    const { active, defects, expired, isLoading, missing, remind, showDetail, tagerror, totalCount, totalIsLoading, } = useContext(CountContext);
    return (<Fragment>
            <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            paddingLeft: '2em',
            alignItems: 'center',
            justifyContent: 'left',
        }}>
                <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingRight: '1em',
        }}>
                    <LifebouyIcon />
                </Box>
                <Box sx={{
            padding: '1em',
            paddingLeft: '0px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
        }}>
                    <Box>
                        <Typography fontFamily={gothamBold} fontWeight='bold' fontSize='24px' sx={{ textAlign: "left" }}>
                            {isLoading && <Skeleton variant="text" animation="wave"/>}
                            {!isLoading && (active?.toLocaleString() ?? 0)}
                        </Typography>
                        <Typography color='#72767E' sx={{ textAlign: "left" }}>
                            Active
                        </Typography>
                    </Box>
                    <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: '2em',
            paddingRight: '1em',
        }}>
                        <Typography sx={{ textAlign: "left" }}>
                            {totalIsLoading && (<Skeleton variant="text" animation="wave" width='24px'/>)}
                            {!totalIsLoading && (totalCount?.toLocaleString() ?? 0)}
                        </Typography>
                        <Typography color='#72767E' sx={{ textAlign: "left" }}>
                            Total
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={{
            height: showDetail ? 'fit-content' : '0px',
            overflow: 'hidden',
            transition: 'height 0.5s ease-in-out',
        }}>
                <Box sx={{ padding: '1em', paddingRight: '3em' }}>
                    <ACExtraDetails missing={missing} tagerror={tagerror} expired={expired} remind={remind} defects={defects}/>
                </Box>
            </Box>
            {/* <Box sx={{
            height: showDetail ? 'fit-content' : '0px',
            opacity: showDetail ? 1 : 0,
            overflow: 'hidden',
            transition: 'opacity 0.5s ease-in-out',
        }}>
            <Typography sx={{ display: 'flex', justifyContent: 'left', paddingLeft: '1em' }}>
                Top 3 high risk route
            </Typography>
            TODO : query top 3 most missing item ??
            then use elevade api to get the latest routing ??
            <table style={{ width: "100%", padding: '1em' }}>
                <tr>
                    <td align="left">Route</td>
                    <td align="right">Missing Eq.</td>
                </tr>
                <tr>
                    <td align="left">CNX (Chiang Mai)</td>
                    <td align="right">12</td>
                </tr>
                <tr>
                    <td align="left">NST (Nakhon Si Thammarat)</td>
                    <td align="right">3</td>
                </tr>
                <tr>
                    <td align="left">UTH (Udon Thani)</td>
                    <td align="right">1</td>
                </tr>
                <tr>
                    <td align="left" colSpan={2}>*as of 08:36, Jan 21, 2022</td>
                </tr>
            </table>
        </Box> */}
        </Fragment>);
}
