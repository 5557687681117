import React from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import IconClose from "common/images/icons/elevade/Icon-Close.svg";
import IconExternalLink from "common/images/icons/elevade/externalLink.svg";
import styles from "./DDML.module.css";
import { useState, useRef, useEffect } from "react";
import DDMLForm from "./DDMLForm";

const DDML = ({
  aoc,
  open,
  setOpen,
  type,
  index,
  dataForm,
  edit,
  setEdit,
  openNotification,
  setOpenNotification,
  newForm,
  setNewForm,
  triggerApi,
  setTriggerApi,
  setSelectToMove,
  setOpenDialogMoveToAssignment,
  ...props
}) => {
  const { userPermission } = useSelector((state) => state.userEntity);
  const DDMLPermission = userPermission.filter(
    (perm) =>
      perm.code === "DASR-004" || perm.code === "DASR-005" || perm.code === "DASR-006" || perm.code === "DASR-007"
  );
  const [formError, setFormError] = useState([]);
  const tabRef = useRef(null);

  useEffect(() => {
    tabRef.current.focus();
  }, [open]);

  const DDMLTitle = () => {
    return (
      <>
        <span
          style={{
            border: "1px solid #C3C3C3",
            borderRadius: "6px",
            padding: "4px 8px",
            marginRight: "8px",
            whiteSpace: "nowrap",
          }}
        >
          {dataForm?.aircraftReg}
        </span>
        <span>{dataForm?.ddmlNo}</span>
      </>
    );
  };

  return (
    <>
      <Grid container columns={4}>
        <Grid item xs={3} sx={{ padding: "24px" }} className={styles.title} ref={tabRef}>
          {edit && "DDML Form"}
          {!edit && <DDMLTitle />}
        </Grid>
        <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-end", padding: "24px" }}>
          <img src={IconExternalLink} alt="icon-external-link" height={20} />
          <div
            style={{
              height: "20px",
              margin: "0 20px",
              width: "1px",
              backgroundColor: "#D1D1D1",
            }}
          ></div>
          <img
            src={IconClose}
            alt="icon-close"
            height={15}
            style={{ marginTop: "2px", cursor: "pointer" }}
            onClick={() => setOpen(false)}
          />
        </Grid>
      </Grid>
      {/* </div> */}
      {/* <div style={{ position: "fixed", top: "10%", width: "100%" }}> */}
      <div className={styles.container}>
        <Grid container columns={4}>
          <Grid item xs={4} sx={{ borderTop: "0px solid #C3C3C3" }}>
            <div
              style={{
                marginTop: "0px",
                position: "relative",
                overflowY: "hidden",
              }}
            >
              <DDMLForm
                aoc={aoc}
                type={type}
                open={open}
                setOpen={setOpen}
                index={index}
                dataForm={dataForm}
                edit={edit}
                setEdit={setEdit}
                openNotification={openNotification}
                setOpenNotification={setOpenNotification}
                newForm={newForm}
                setNewForm={setNewForm}
                formError={formError}
                setFormError={setFormError}
                triggerApi={triggerApi}
                setTriggerApi={setTriggerApi}
                setSelectToMove={setSelectToMove}
                setOpenDialogMoveToAssignment={setOpenDialogMoveToAssignment}
                DDMLPermission={DDMLPermission}
                {...props}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      {/* </div> */}
      {/* </Drawer>
      </ClickAwayListener> */}
    </>
  );
};

export default DDML;
