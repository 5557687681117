import axios from "axios";
import { useEffect, useState } from "react";
export default function useGetAPI({ url, defaultIsLoading = false, method, input }) {
    const [data, setData] = useState([]);
    const [isLoading, setIsloading] = useState(defaultIsLoading);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isError, setIsError] = useState(false);
    function fetch() {
        setIsloading(true);
        setErrorMessage(null);
        setIsError(false);
        axiosAPIRequest(url, method, input).then(({ data, isError, errorMessage }) => {
            setIsError(isError);
            setErrorMessage(errorMessage);
            setData(data ?? {});
        }).catch((err) => {
            setIsError(true);
            setErrorMessage(JSON.stringify({ err }));
        }).finally(() => {
            setIsloading(false);
        });
    }
    useEffect(() => {
        if (url && method) {
            fetch();
        }
    }, [method, url, input]);
    function refetch() {
        fetch();
    }
    // https://elevade-api-stg-vwrpyzdveq-as.a.run.app/acarsdata/list/72/TAA,MAA
    return {
        data,
        isLoading,
        errorMessage,
        isError,
        refetch,
    };
}
async function axiosAPIRequest(url, method, input) {
    try {
        const { data } = await axios({
            url,
            headers: {
                // authorization: options && options.jwtToken ? options.jwtToken : '',
                'Content-Type': 'application/json',
            },
            method,
            data: input,
        });
        if (data && data.errors && data.errors[0] && data.errors[0].message) {
            throw new Error(data.errors[0].message);
        }
        return {
            data,
            isError: false,
            errorMessage: null
        };
    }
    catch (err) {
        return {
            data: null,
            isError: true,
            errorMessage: err.message
        };
    }
}
