
import React from 'react';

export default function TailLeftToilet() {
	return (
		<g>
			<rect
				x="1185.5"
				y="380.5"
				width="29"
				height="46"
				rx="5.5"
				fill="white"
				stroke="#74C4E8"
			/>
			<path
				d="M1195.39 403.375C1195.39 403.589 1195.21 403.763 1195 403.763C1194.79 403.763 1194.61 403.589 1194.61 403.375C1194.61 403.161 1194.79 402.988 1195 402.988C1195.21 402.988 1195.39 403.161 1195.39 403.375Z"
				fill="black"
				stroke="#394458"
				stroke-width="1.1"
			/>
			<path
				d="M1195 409.625C1196.73 409.625 1198.12 406.827 1198.12 403.375C1198.12 399.923 1196.73 397.125 1195 397.125C1193.27 397.125 1191.88 399.923 1191.88 403.375C1191.88 406.827 1193.27 409.625 1195 409.625Z"
				stroke="#394458"
				stroke-width="1.1"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M1198.12 403.375V410.25C1198.12 410.416 1198.19 410.575 1198.31 410.692C1198.43 410.809 1198.58 410.875 1198.75 410.875H1207.5C1207.67 410.875 1207.82 410.809 1207.94 410.692C1208.06 410.575 1208.12 410.416 1208.12 410.25V403.375C1208.12 399.922 1206.73 397.125 1205 397.125H1195"
				stroke="#394458"
				stroke-width="1.1"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M1198.12 403.375H1200"
				stroke="#394458"
				stroke-width="1.1"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M1202.5 403.375H1203.75"
				stroke="#394458"
				stroke-width="1.1"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M1206.25 403.375H1208.12"
				stroke="#394458"
				stroke-width="1.1"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</g>
	);
}
