export const globalBorderRadius = '8px';
export const appheaderHeight = 70;
export const blackColor = '#333333';
/**
 * xxxs: '4px',
    xxs: '8px',
    xs: '10px',
    s: '12px',
    m: '14px',
    l: '18px',
    Xl: '24px',
 */
const commonSizes = {
    xxxs: '4px',
    xxs: '8px',
    xs: '10px',
    s: '12px',
    m: '14px',
    l: '16px',
    xl: '18px',
    xxl: '24px',
};
export const commonContainerPaddings = commonSizes;
export const commonFontSizes = commonSizes;
export const commonBorderRadius = commonSizes;
export const paragraphSpacing = '18px'; // css : lineHeight
// table
export const tableRowFontSize = '14px'; // general contents
export const tableHeaderBackgroundColor = '#F2F7FF';
// container
export const containerBorderColor = '#E8E8E8';
export const containerborderRadius = '12px';
export const containerBorder = `1px solid ${containerBorderColor}`;

export const boxShadowFleet = '0px 3px 10px rgba(57, 68, 88, 0.102)';