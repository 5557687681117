import React from "react";
import { Grid } from "@mui/material";
import styles from "../Analyticsv2.module.css";
import { GetMonthShort } from "../../component/Helpers";
import AddIcon from "common/images/icons/elevade/Add-Icon.svg";
import AddIconActive from "common/images/icons/elevade/Add-Icon-active.svg";
import { useState } from "react";

// A function to convert date to follow specific format - Month Day, Year
function ConvertDate(date) {
  const options = {
    month: "short",
    day: "numeric",
  };
  let DateUTC = date?.toLocaleString("en-US", options).split("T")[0];
  if (DateUTC !== "") {
    DateUTC =
      GetMonthShort(DateUTC?.split("-")[1]) +
      " " +
      DateUTC?.split("-")[2] +
      ", " +
      DateUTC?.split("-")[0].padStart(2, "0");
  }
  return DateUTC;
}

export const AnalyticsTableA01 = ({ title, data }) => {
  return (
    <>
      <div>
        <div className={`${styles.container}`} style={{ minHeight: "568px", maxHeight: "568px" }}>
          <div className={styles.title}>{title}</div>
          <div style={{ maxHeight: "524px", overflow: "auto" }}>
            <Grid container columns={7}>
              <Grid item xs={1} className={styles.tableHeader}>
                AC/Reg.
              </Grid>
              <Grid item xs={3} className={`${styles.tableHeader} ${styles.flexStart}`}>
                Message
              </Grid>
              <Grid item xs={1} className={styles.tableHeader}>
                Repetitive
              </Grid>
              <Grid item xs={1} className={styles.tableHeader}>
                Last Report
              </Grid>
              <Grid item xs={1} className={styles.tableHeader}>
                Triggered
              </Grid>
              {data.length > 0 &&
                data.map((item, index) => {
                  return (
                    <>
                      <Grid item xs={1} className={styles.tableBody} align="center" key={index}>
                        {item.ac_reg}
                      </Grid>
                      <Grid item xs={3} className={`${styles.tableBody} ${styles.flexStart}`}>
                        {item.text}
                      </Grid>
                      <Grid item xs={1} className={styles.tableBody} align="center">
                        {item.repetitive ? "True" : "False"}
                      </Grid>
                      <Grid item xs={1} className={styles.tableBody} align="center">
                        {ConvertDate(item.last_report?.value)}
                      </Grid>
                      <Grid item xs={1} className={`${styles.tableBody} ${styles.bold}`} align="center">
                        {item.occurrence}
                      </Grid>
                    </>
                  );
                })}
              {data.length === 0 && (
                <Grid item xs={7} sx={{ display: "flex", justifyContent: "center", paddingTop: "20%", color: "grey" }}>
                  No data available
                </Grid>
              )}
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};

export const AnalyticsTableA02 = ({ title, data }) => {
  return (
    <>
      <div>
        <div className={`${styles.container}`}>
          <div className={styles.title}>{title}</div>
          <div style={{ maxHeight: "326px", overflow: "auto" }} className={styles.macScrollbar}>
            <Grid container columns={5}>
              <Grid item xs={1} className={styles.tableHeader}>
                AC/Reg.
              </Grid>
              <Grid item xs={2} className={`${styles.tableHeader} ${styles.flexStart}`}>
                Message
              </Grid>
              <Grid item xs={1} className={styles.tableHeader}>
                Last Rep.
              </Grid>
              <Grid item xs={1} className={styles.tableHeader}>
                Trig
              </Grid>
              {data.length > 0 &&
                data.map((item, index) => {
                  return (
                    <>
                      <Grid item xs={1} className={styles.tableBody} align="center" key={index + 1}>
                        {item.ac_reg}
                      </Grid>
                      <Grid item xs={2} className={`${styles.tableBody} ${styles.flexStart}`} key={index + 2}>
                        {item.text}
                      </Grid>
                      <Grid item xs={1} className={styles.tableBody} align="center" key={index + 3}>
                        {ConvertDate(item.last_report?.value)}
                      </Grid>
                      <Grid item xs={1} className={`${styles.tableBody} ${styles.bold}`} align="center" key={index + 4}>
                        {item.occurrence}
                      </Grid>
                    </>
                  );
                })}
              {data.length === 0 && (
                <Grid
                  item
                  xs={7}
                  sx={{ display: "flex", justifyContent: "center", paddingTop: "20%", color: "#999999" }}
                >
                  No data available
                </Grid>
              )}
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};

export const AddMoreTable = () => {
  const [onHover, setOnHover] = useState(false);
  return (
    <>
      <div>
        <div
          className={`${styles.containerAddMore} ${styles.noAction}`}
          onMouseEnter={() => setOnHover(true)}
          onMouseLeave={() => setOnHover(false)}
        >
          <img src={onHover ? AddIconActive : AddIcon} alt="add-icon" width={23} height={23} />
          <span style={{ marginLeft: "8px" }}>Add More ATA Chapters</span>
        </div>
      </div>
    </>
  );
};
