
import React from 'react';

export default function Windshield() {
	return (
		<g>
			<path
				d="M92.5 339.5L111.5 308L125 313"
				stroke="#CAD2DB"
				stroke-width="0.3"
			/>
			<path
				d="M125.344 315.605L111.893 340.825C111.348 341.848 110.262 342.465 109.104 342.41L95.0471 341.74C92.7727 341.632 91.4433 339.129 92.6272 337.184L110.5 307.821C110.792 307.342 111.378 307.13 111.909 307.313L123.672 311.356C125.418 311.956 126.213 313.976 125.344 315.605Z"
				stroke="#CAD2DB"
			/>
			<path
				d="M129.248 308.583L118.605 305.035C116.489 304.33 115.925 301.72 117.716 300.391C123.606 296.021 132.95 290.76 139.685 287.218C141.659 286.179 144 287.624 144 289.854V299.735C144 300.824 143.409 301.828 142.457 302.357L131.654 308.359C130.919 308.767 130.046 308.849 129.248 308.583Z"
				stroke="#CAD2DB"
			/>
			<path
				d="M117 302C124 297.167 126.5 295 142 287"
				stroke="#CAD2DB"
				stroke-width="0.3"
			/>
			<path
				d="M148 286.425V296.207C148 298.298 150.085 299.747 152.045 299.019L164.751 294.3C165.242 294.117 165.676 293.81 166.011 293.408L169.805 288.856C170.254 288.317 170.5 287.637 170.5 286.935V279.761C170.5 278.21 169.305 276.958 167.779 277.238C162.845 278.144 154.787 281.437 149.728 283.725C148.669 284.204 148 285.263 148 286.425Z"
				stroke="#CAD2DB"
			/>
			<path
				d="M148.5 285.523C154.5 283.023 167.2 278.023 170 278.023"
				stroke="#CAD2DB"
				stroke-width="0.3"
			/>
			<path
				d="M92.5 352L111.5 383.5L125 378.5"
				stroke="#CAD2DB"
				stroke-width="0.3"
			/>
			<path
				d="M125.344 375.895L111.893 350.675C111.348 349.652 110.262 349.035 109.104 349.09L95.0471 349.76C92.7727 349.868 91.4433 352.371 92.6272 354.316L110.5 383.679C110.792 384.158 111.378 384.37 111.909 384.187L123.672 380.144C125.418 379.544 126.213 377.524 125.344 375.895Z"
				stroke="#CAD2DB"
			/>
			<path
				d="M129.248 382.917L118.605 386.465C116.489 387.17 115.925 389.78 117.716 391.109C123.606 395.479 132.95 400.74 139.685 404.282C141.659 405.321 144 403.876 144 401.646V391.765C144 390.676 143.409 389.672 142.457 389.143L131.654 383.141C130.919 382.733 130.046 382.651 129.248 382.917Z"
				stroke="#CAD2DB"
			/>
			<path
				d="M117 389.5C124 394.333 126.5 396.5 142 404.5"
				stroke="#CAD2DB"
				stroke-width="0.3"
			/>
			<path
				d="M148 405.075V395.293C148 393.202 150.085 391.753 152.045 392.481L164.751 397.2C165.242 397.383 165.676 397.69 166.011 398.092L169.805 402.644C170.254 403.183 170.5 403.863 170.5 404.565V411.739C170.5 413.29 169.305 414.542 167.779 414.262C162.845 413.356 154.787 410.063 149.728 407.775C148.669 407.296 148 406.237 148 405.075Z"
				stroke="#CAD2DB"
			/>
			<path
				d="M148.5 405.977C154.5 408.477 167.2 413.477 170 413.477"
				stroke="#CAD2DB"
				stroke-width="0.3"
			/>
		</g>
	);
}
