import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { ClickAwayListener } from "@mui/base";

import { minimizeFleetMenu } from "./SidebarIconsList";
import FleetSidebarIcon from "./FleetSidebarIcon";
import FleetSidebarIconText from "./FleetSidebarIconText";

import styles from "./Sidebar.module.css";

const FleetSidebar = () => {
  // const drawerWidth = 252;
  const drawerWidth = 290;
  const drawerWidthClose = 52;
  const { sidebarToggle } = useSelector((state) => state.sidebarToggle);
  const [openMenu, setOpenMenu] = useState(false);

  function checkMouseEnter() {
    if (sidebarToggle !== true) {
      setOpenMenu(true);
    }
  }

  function checkMouseLeave() {
    if (sidebarToggle !== true) {
      setOpenMenu(false);
    }
  }

  const handleClickAway = () => {
    if (sidebarToggle) {
      setOpenMenu(true);
    } else {
      setOpenMenu(false);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box
        /// mouse enter exit function causing the collapse to re-render making all states to default. need to revise?
        onMouseEnter={() => {
          checkMouseEnter();
          // setOpenMenu(true)
        }}
        onMouseLeave={() => {
          checkMouseLeave();
          // setOpenMenu(false)
        }}
        sx={{
          width: openMenu ? drawerWidth : drawerWidthClose,
          transition: "width 0.3s ease-in-out",
          // width: drawerWidthClose,
          // width: drawerWidth,
          backgroundColor: "#fff",
          // backgroundColor: "#caccd0",
          height: "95%",
          left: "32px",
          position: "fixed",
          padding: "24px 8px 24px 8px",
          marginBottom: "16px",
          overflowY: "hidden",
          border: "1px solid #F1F1F1",
          // boxShadow: openMenu ? "0px 3px 35px 0px rgba(57, 68, 88, 0.08)" : "",
          borderTop: "0px",
        }}
        className={`${styles.onScrollbar} ${styles.macScrollbar}`}
      >
        {openMenu ? <FleetSidebarIconText /> : <FleetSidebarIcon />}
        {/* <FleetSidebarIconText /> */}
      </Box>
    </ClickAwayListener>
  );
};

export default FleetSidebar;
