import React, { useEffect, useState } from "react";
import EChartss from "./component/ECharts";
import { Grid } from "@mui/material";
import AirplaneLoader from "../component/AirplaneLoader";
import Overlay from "../component/Overlay";
import { filterArray } from "./helpers/filterArray";
import { cloneDeep } from "lodash";
import styles from "./component/ECharts.module.css";
import { makeAPIRequestRun } from "common/helpers";
import { handlePredefinedRange } from "./helpers/predefinedDateRange";
import { formatDate } from "./helpers/formatDate";
import { format, subMinutes } from "date-fns";
import { dateFnsFormats } from "common/constant";
import IconPaperPlane from "common/images/icons/elevade/Icon-PaperPlane.svg";

// -- table_id    table_description
// -- 1           Top ECAM Messages
// -- 2           Top ATA Chapters
// -- 3           Top High Priority ECAM Messages
// -- 4           Top Repetitive ECAM Messages
// -- 5           Top Repetitive Fault Messages
// -- 6           Selected ATA Chapter Defect Data
// -- 7           DAR Defect Data
// -- 8           Full ECAM Data
// -- 9           Full Fault Data
// --10           Top ECAM Messages by Aircraft
// --11           Full ATA Data
// --12           Top High Priority ECAM Messages by Aircraft
// --13           Top ATA Chapters by Aircraft
// --15           Top Condition data
// --16           Full Condition data
export const EcamDispatch = (props) => {
  const {
    isLoading,
    setIsLoading,
    isLoadingLegs,
    setIsLoadingLegs,
    isOperator,
    data,
    tab,
    startDate,
    endDate,
    localTime,
    isACReg,
    lastModifiedDT,
  } = props;
  const [initialAOC, setInitialAOC] = useState([]);
  const [dataA01, setDataA01] = useState([]); // NOGO
  const [dataA02, setDataA02] = useState([]); // GOIF
  const [dataA03, setDataA03] = useState([]); // GO
  const [dataA04, setDataA04] = useState([]); // NOT APPLICABLE
  const [deep, setDeep] = useState(0);
  const [emailHistory, setEmailHistory] = useState([]);

  useEffect(() => {
    async function getAircraftData() {
      let from = startDate ?? handlePredefinedRange(72, localTime, true);
      let to = endDate ?? handlePredefinedRange(72, localTime, false);
      let acreg = isACReg.length > 0 ? isACReg.join(", ") : "null";
      let aoc = isOperator;

      const fetchData = makeAPIRequestRun(
        "get",
        "acarsdata/analyticsemail/" + formatDate(from) + "/" + formatDate(to) + "/" + aoc + "/" + acreg
      );
      fetchData
        .then((response) => {
          if (response !== "") {
            setEmailHistory(response?.rows[0]);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    getAircraftData();
    //eslint-disable-next-line
  }, [startDate, endDate, tab, isOperator, isACReg]);

  const topConditionData = data && data?.filter((item) => item.table_id === 15 && (item.aoc === isOperator ?? "MAA"));
  const fullConditionData =
    data &&
    data?.filter((item) => item.table_id === 16 && item.occurrence_order === 1 && (item.aoc === isOperator ?? "MAA"));

  const filterCondition = (condition, isTopAcReg) => {
    let data = isTopAcReg ? cloneDeep(fullConditionData) : cloneDeep(topConditionData);
    if (condition) {
      if (condition === "Not Applicable")
        return data.filter((item) => item.condition !== "NOGO" && item.condition !== "GOIF" && item.condition !== "GO");
      else return data.filter((item) => item.condition === condition);
    }
  };
  // Set data state for each of the charts or table
  useEffect(() => {
    if (data?.length > 0) {
      // Set data for A01: NOGO
      setDataA01(SetDataForCharts(filterCondition("NOGO", false), 1));

      // Set data for A02: GOIF
      setDataA02(SetDataForCharts(filterCondition("GOIF", false), 2));

      // Set data for A03: GO
      setDataA03(SetDataForCharts(filterCondition("GO", false), 3));

      // Set data for A04: Not Applicable
      setDataA04(SetDataForCharts(filterCondition("Not Applicable", false), 4));

      setIsLoading(false);
      setIsLoadingLegs(false);
    }
    // eslint-disable-next-line
  }, [data, initialAOC, isACReg]);

  // Set operator based on selected value from the filters
  useEffect(() => {
    if (isOperator?.length > 0) setInitialAOC(isOperator);
  }, [isOperator]);

  // A function to set data for chart drawing
  function SetDataForCharts(data, type, ata) {
    let data_ = data;
    let dataToArray = [["type", "text", "occurrence", "aoc", "ac_reg", "ata_head", "condition"]];
    let arrayHeader = ["type", "text", "occurrence", "aoc", "ac_reg", "ata_head", "condition"];
    data_
      .sort(function (a, b) {
        return a.occurrence - b.occurrence;
      })
      // eslint-disable-next-line
      .map((e) => {
        if (type === 1) dataToArray.push(["NOGO", e.text, e.occurrence, e.aoc, e.ac_reg, e.ata_head, e.condition]);
        else if (type === 2) dataToArray.push(["GOIF", e.text, e.occurrence, e.aoc, e.ac_reg, e.ata_head, e.condition]);
        else if (type === 3) dataToArray.push(["GO", e.text, e.occurrence, e.aoc, e.ac_reg, e.ata_head, e.condition]);
        else if (type === 4)
          dataToArray.push(["Not Applicable", e.text, e.occurrence, e.aoc, e.ac_reg, e.ata_head, e.condition]);
      });

    return type ? filterArray([arrayHeader, ...dataToArray]) : dataToArray;
  }

  return (
    <>
      {isLoadingLegs && !isLoading && <Overlay />}
      {isLoading && <AirplaneLoader />}
      {!isLoading && (
        <div
          style={{
            overflow: "hidden",
            margin: "12px -44px 18px 20px",
          }}
        >
          <Grid container columns={2}>
            <Grid item xs={3} sm={3} md={1.5} lg={1} xl={1} sx={{ padding: "12px", overflowY: "hidden" }}>
              <EChartss
                title="NOGO"
                data={dataA01}
                color={{ start: "rgb(233, 80, 72)", end: "rgb(233, 80, 72)" }}
                isLoading={isLoading}
                initialData={data}
                isACReg={isACReg}
                deep={deep}
                setDeep={setDeep}
                latestOccurence={true}
                tableId={15}
                acRegList={filterCondition("NOGO", true)}
                tab={tab}
              />
            </Grid>
            <Grid item xs={3} sm={3} md={1.5} lg={1} xl={1} sx={{ padding: "12px" }}>
              <div
                className={`${styles.container} ${styles.macScrollbar}`}
                style={{ height: "500px", overflow: "scroll", overflowX: "hidden" }}
              >
                <div
                  className={styles.title}
                  style={{ position: "sticky", top: 0, zIndex: 10, backgroundColor: "#FFFFFF" }}
                >
                  {"CRITICAL NO-GO ALERTS SENT TO EMAIL"}
                </div>
                <div className={`${styles.body}`}>
                  <>
                    {emailHistory.map((item) => {
                      return (
                        <>
                          <Grid container columns={3} style={{ margin: "25px 15px 25px 15px" }}>
                            <Grid
                              item
                              xs={0.4}
                              sx={{ border: "1px solid #E8E8E8", borderRadius: "10px", textAlign: "center" }}
                            >
                              <span style={{ fontFamily: "Gotham-Medium", fontSize: "12px" }}> {item.ac_reg}</span>
                            </Grid>
                            <Grid item xs={0.1} />
                            <Grid item xs={2.4} sx={{ marginLeft: "10px" }}>
                              <span style={{ fontSize: "12px" }}>
                                {format(new Date(item?.detected_at?.value), dateFnsFormats.DATE_TIME_3)}
                                &nbsp; - &nbsp;
                              </span>
                              <span style={{ fontFamily: "Gotham-Medium", fontSize: "12px" }}>{item.event}</span>
                            </Grid>
                          </Grid>
                        </>
                      );
                    })}
                    {emailHistory.length === 0 && (
                      <Grid container columns={1} style={{ margin: "111px 15px 25px 15px" }}>
                        <Grid item xs={1} sx={{ display: "flex", justifyContent: "center" }}>
                          <img src={IconPaperPlane} alt="icon-paper-plane" />
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            color: "#838383",
                            paddingTop: "16px",
                            fontFamily: "Gotham-Book",
                            fontSize: "14px",
                          }}
                        >
                          There are no records of any emails being sent.
                        </Grid>
                      </Grid>
                    )}
                  </>
                </div>
              </div>
            </Grid>
            <Grid item xs={3} sm={3} md={1.5} lg={1} xl={1} sx={{ padding: "12px" }}>
              {lastModifiedDT ? (
                <div
                  className="lastUpdated"
                  style={{
                    paddingBottom: "20px",
                    bottom: 0,
                    fontStyle: "italic",
                    fontSize: "12px",
                    color: "#838383",
                  }}
                >
                  <span>&#42;Data displayed as of </span>
                  {localTime ? (
                    <>
                      {format(
                        subMinutes(new Date(lastModifiedDT), -new Date(lastModifiedDT).getTimezoneOffset()),
                        dateFnsFormats.DATE_TIME_5
                      )}
                    </>
                  ) : (
                    <>{format(new Date(lastModifiedDT), dateFnsFormats.DATE_TIME_5)}</>
                  )}
                </div>
              ) : (
                <></>
              )}
              <EChartss
                title="GOIF"
                data={dataA02}
                color={{ start: "rgb(10, 221, 221)", end: "rgb(8, 143, 209)" }}
                isLoading={isLoading}
                initialData={data}
                isACReg={isACReg}
                deep={deep}
                setDeep={setDeep}
                latestOccurence={true}
                tableId={15}
                acRegList={filterCondition("GOIF", true)}
                tab={tab}
              />
            </Grid>
            <Grid item xs={3} sm={3} md={1.5} lg={1} xl={1} sx={{ padding: "50px 12px 12px 12px" }}>
              <EChartss
                title="GO"
                data={dataA03}
                color={{ start: "rgb(123, 201, 240)", end: "rgb(70, 81, 230)" }}
                isLoading={isLoading}
                initialData={data}
                isACReg={isACReg}
                chartIndex={3}
                deep={deep}
                setDeep={setDeep}
                latestOccurence={true}
                tableId={15}
                acRegList={filterCondition("GO", true)}
                tab={tab}
              />
            </Grid>
            <Grid item xs={3} sm={3} md={1.5} lg={1} xl={1} sx={{ padding: "12px" }}>
              <EChartss
                title="NOT APPLICABLE"
                data={dataA04}
                color={{ start: "rgba(47, 178, 241, 0.6)", end: "rgb(7, 117, 171)" }}
                isLoading={isLoading}
                initialData={data}
                isACReg={isACReg}
                chartIndex={3}
                deep={deep}
                setDeep={setDeep}
                latestOccurence={true}
                tableId={15}
                acRegList={filterCondition("Not Applicable", true)}
                tab={tab}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};
