import React from 'react';
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import styles from "./Inbox.module.css";
import { makeAPIRequestRun } from "common/helpers";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { formatDate, ConvertLocaleUTCDatetime, setAOCBasedOnPermission } from "../component/Helpers";
import MUIPagination from "../component/Pagination";
import DataUnavailable from "common/images/icons/elevade/Data-unavailable.svg";
import Sorting from "../component/Sorting";
import { subMinutes } from "date-fns";
import AirplaneLoader from "../component/AirplaneLoader";

const InboxOOOI = ({
  localTime,
  isSearchInput,
  isACReg,
  setIsACReg,
  isOperator,
  startDate,
  endDate,
  isLoading,
  setIsLoading,
}) => {
  const { profile: userProfile } = useSelector((state) => state.user);
  const { company: companyInfo } = useSelector((state) => state.company);
  const { companyAOC, userAOC, userPermission } = useSelector((state) => state.userEntity);
  // let AOC = companyInfo?.permission?.elevadeAOC;
  let AOC = companyAOC;
  // const UserAOC = userProfile?.permission?.elevadeAOC;
  // const UserAOC = userAOC;
  // if (UserAOC && UserAOC !== null && UserAOC !== undefined && UserAOC?.length > 0) AOC = UserAOC;
  const [checkAOC, setCheckAOC] = useState(setAOCBasedOnPermission(AOC, userPermission));
  AOC = checkAOC;
  const [Inbox, setInbox] = useState([]);
  const [isInbox, setIsInbox] = useState(Inbox);
  const [perPage, setPerPage] = useState(50);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPage] = useState(0);
  const [sortingColumn, setSortingColumn] = useState("event_datetime");
  const [sortingDirection, setSortingDirection] = useState("desc");

  let sliceStart = page > 0 ? perPage * (page - 1) : 0;
  if (sliceStart > totalRows) sliceStart = Math.floor(totalRows / perPage);

  let searchQuery = "null";
  if (isOperator !== "All" && isOperator.length > 0) searchQuery += `operator=${isOperator}|`;
  if (isSearchInput !== undefined && isSearchInput !== "") searchQuery += `search=${isSearchInput}|`;
  if (isACReg !== "All" && isACReg.length > 0) searchQuery += `ac_reg=${isACReg}|`;
  if (startDate !== undefined && endDate !== undefined) {
    const start = localTime ? subMinutes(new Date(startDate), -new Date(startDate).getTimezoneOffset()) : startDate;
    const end = localTime ? subMinutes(new Date(endDate), -new Date(endDate).getTimezoneOffset()) : endDate;
    searchQuery += `event_datetime=${ConvertLocaleUTCDatetime(start)}~${ConvertLocaleUTCDatetime(end, "end")}|`;
  }

  // useEffect(() => {
  //   setIsLoading(true);
  //   // eslint-disable-next-line
  // }, [searchQuery]);

  useEffect(() => {
    setPage(1);
  }, [isSearchInput, isOperator, isACReg, startDate, endDate, sortingColumn, sortingDirection]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [page]);

  useEffect(() => {
    async function fetchData() {
      const fetchInbox = makeAPIRequestRun(
        `get`,
        `acarsdata/acarsinbox/${AOC}/${perPage}/${sliceStart}/${encodeURIComponent(
          searchQuery
        )}/pos/${sortingColumn}/${sortingDirection}`
      );
      fetchInbox
        .then((response) => {
          setInbox(response);
          setIsInbox(response);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    async function fetchTotalRows() {
      const fetchData = makeAPIRequestRun(
        "get",
        `acarsdata/acarsinboxtotalrows/${AOC}/${perPage}/${sliceStart}/${encodeURIComponent(searchQuery)}/pos`
      );
      fetchData
        .then((response) => {
          setTotalRows(response[0]?.Total);
          setTotalPage(Math.floor(response[0]?.Total / perPage) + 1);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    setIsLoading(true);
    fetchData();
    fetchTotalRows();
    // eslint-disable-next-line
  }, [AOC, searchQuery, sliceStart, page, perPage, sortingColumn, sortingDirection]);

  // Custom styles
  const useStyles = makeStyles(() => ({
    ul: {
      "& .Mui-selected": {
        color: "#fff",
        backgroundColor: "#088FD1",
        border: "1px solid #088FD1",
        "&:hover": {
          backgroundColor: "#088FD1",
        },
      },
    },
  }));

  const classes = useStyles();

  const DisplayDataHeader = () => {
    return (
      <>
        <div className={`row ${styles.displayHeader}`}>
          <Grid container spacing={0} columns={9} className={`${styles.displayHeaderLabel}`}>
            {/* <Grid item xs={1} className={styles.nowrap}>
              <Sorting
                title={"Status"}
                col={"msg"}
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid> */}
            <Grid item xs={1} className={styles.nowrap}>
              <Sorting
                title={"Message"}
                col={"msg"}
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={1} className={styles.nowrap}>
              <Sorting
                title={"A/C Model"}
                col={"model"}
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={0.8} className={styles.nowrap}>
              <Sorting
                title={"A/C Reg."}
                col={"ac_reg"}
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={0.8} className={styles.nowrap}>
              <Sorting
                title={"Flight No."}
                col={"flight_no"}
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={1.4} className={styles.nowrap}>
              <Sorting
                title={"Longitude"}
                col={"longitude"}
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={1.4} className={styles.nowrap}>
              <Sorting
                title={"Latitude"}
                col={"latitude"}
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={0.8} className={styles.nowrap}>
              <Sorting
                title={"Altitude (ft)"}
                col={"altitude"}
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={0.8} className={styles.nowrap}>
              <Sorting
                title={"FOB (x100kg)"}
                col={"fob"}
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={1} className={styles.nowrap}>
              <Sorting
                title={"Occurrence"}
                col={"event_datetime"}
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
          </Grid>
        </div>
      </>
    );
  };

  const NoDataAvailable = () => {
    const [show, setShow] = useState(false);
    useEffect(() => {
      const timeoutId = setTimeout(() => {
        setShow(true);
      }, 1000);

      return () => clearTimeout(timeoutId);
    }, []);
    return show ? (
      <>
        <div style={{ marginTop: "5%", marginBottom: "10%", textAlign: "center" }}>
          <div style={{ marginBottom: "16px" }}>
            <img src={DataUnavailable} alt="data-unavailable" />
          </div>
          <div style={{ fontFamily: "Gotham-Medium", fontSize: "14px" }}>Data Unavailable</div>
          <div style={{ fontFamily: "Gotham-Book", fontSize: "12px" }}>
            There is no data found. Please try a different search term.
          </div>
        </div>
      </>
    ) : (
      ""
    );
  };

  const DisplayData = () => {
    return isInbox.length > 0 ? (
      isInbox?.map((item, index) => {
        return (
          <>
            <Link to="#" key={index}>
              <div className={`row ${styles.dataItems}`}>
                <Grid container spacing={0} columns={9}>
                  {/* <Grid item xs={1}>
                    {getOOOI(item.msg)}
                    <span style={{ fontFamily: "Gotham-Medium", marginLeft: "8px" }}>{item.msg}</span>
                  </Grid> */}
                  <Grid item xs={1} className={`${styles.dataItemsText}`}>
                    <div className={`${styles.tooltip} ${styles.dataItemsText}`}>
                      {item.msg}
                      <span data-html="true" className={`${styles.tooltiptext} ${styles.tooltiptextEllipsis}`}>
                        {item.msg}
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs={1} className={`${styles.dataItemsText}`}>
                    {item.model}
                  </Grid>
                  <Grid item xs={0.8} className={`${styles.dataItemsText}`}>
                    {item.ac_reg}
                  </Grid>
                  <Grid item xs={0.8} className={`${styles.dataItemsText}`}>
                    {item.flight_no}
                  </Grid>
                  <Grid item xs={1.4} className={`${styles.dataItemsText}`}>
                    {item.longitude_dir +
                      " " +
                      item.longitude_deg +
                      `° ` +
                      item.longitude_min +
                      "' " +
                      item.longitude_sec +
                      `"`}
                  </Grid>
                  <Grid item xs={1.4} className={`${styles.dataItemsText}`}>
                    {item.latitude_dir +
                      " " +
                      item.latitude_deg +
                      `° ` +
                      item.latitude_min +
                      "' " +
                      item.latitude_sec +
                      `"`}
                  </Grid>
                  <Grid item xs={0.8} className={`${styles.dataItemsText}`}>
                    {item.altitude}
                  </Grid>
                  <Grid item xs={0.8} className={`${styles.dataItemsText}`}>
                    {item?.fob?.toString().trim().padStart(4, "0")}
                  </Grid>
                  <Grid item xs={1} className={`${styles.dataItemsText}`}>
                    {formatDate(item.event_datetime?.value, "", localTime)}
                  </Grid>
                </Grid>
              </div>
            </Link>
          </>
        );
      })
    ) : !isLoading && isInbox?.length === 0 ? (
      <NoDataAvailable />
    ) : (
      ""
    );
  };

  return (
    <>
      <DisplayDataHeader />
      <DisplayData />
      {isInbox?.length !== 0 ? (
        <MUIPagination
          page={page}
          setPage={setPage}
          setPerPage={setPerPage}
          totalPage={totalPages}
          classes={{ ul: classes.ul }}
          totalRows={totalRows}
          sliceStart={sliceStart}
        />
      ) : (
        ""
      )}
      {isLoading && <AirplaneLoader />}
    </>
  );
};

export default InboxOOOI;
