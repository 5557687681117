import { axiosGraphqlRequest } from '../useGetGQL'
import { env_var_REACT_APP_API_URL } from "../../envVar/vars";

export async function getAllInspectionRecords({
	regNo,
	createdAtFrom,
	createdAtTo,
	search,
}) {

    const queryTimeStart = createdAtFrom ? `${new Date(createdAtFrom).toISOString().slice(0, -5)}+00:00` : '';
    const queryTimeEnd = createdAtTo ? `${new Date(createdAtTo).toISOString().slice(0, -5)}+00:00` : '';

	const { data, isError, errorMessage } = await axiosGraphqlRequest(
		env_var_REACT_APP_API_URL ?? "",
		`
                query inspectionrecord{
                    allInspectionRecords(
                        first:20 
                        orderByBatchNo:"-batch_no"
                        multipleAircraftRegno:"${regNo}" 
                        ${
							search !== undefined
								? ` searchRecord:"${search}" `
								: ""
						}
                        ${
							createdAtFrom !== undefined
								? ` createdAtFrom:"${queryTimeStart}" `
								: ""
						}
                        ${
							createdAtTo !== undefined
								? ` createdAtTo:"${queryTimeEnd}" `
								: ""
						}
                        ){
                      edges{
                        node{
                          batchNo
                            fleetId{
                                regNo
                                model
                                engineType
                            }
                          dateInspection
                          totalMissing
                          totalActive
                          scannedBy
                          locationCode
                          inspectionitemSet { 
                             edges {
                               node {
                                 id
                                 tagId
                               }          
                             }
                          }
                        }
                      }
                    }
                  }
                `,
		{ variables: {} }
	);

	return { data, isError, errorMessage }
}
