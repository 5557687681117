import useGetAPI from "../useGetAPI";

export function useGetAllFlight() {
	const data = useGetAPI({
		url: "https://elevade-api-stg-vwrpyzdveq-as.a.run.app/acarsdata/list/72/TAA,MAA",
		method: "GET",
		defaultIsLoading: true,
	});

	return data;
}
