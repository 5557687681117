
import React from 'react';

export default function RightWing() {
	return (
		<g>
			<line
				// weird top line
				x1="724.5"
				y1="32.5"
				x2="886.5"
				y2="32.5"
				stroke="#CAD2DB"
				stroke-linecap="round"
				stroke-dasharray="2 2"
			/>
			<g filter="url(#filter1_d_4_45)">
				<path
					d="M826.906 249.527H550L726.991 32.5701H884L826.906 249.527Z"
					fill="url(#paint1_linear_4_45)"
				/>
			</g>
			<path
				d="M793.221 249.527L806.923 199.156M852.598 32.5701L806.923 199.156M815.487 249.527L827.757 204.423M875.436 32.5701L827.757 204.423M806.923 199.156L827.757 204.423"
				stroke="#E6E6E6"
			/>
			<path
				d="M744.12 249.527L761.287 216.708C762.667 214.07 765.398 212.416 768.376 212.416H778.297C781.751 212.416 784.816 210.199 785.896 206.917L843.464 31.9992"
				stroke="#E6E6E6"
			/>
			<path
				d="M603.668 249.527L629.931 212.416H676.748L761.818 33.141"
				stroke="#E6E6E6"
			/>
		</g>
	);
}
