
import React from 'react';

export default function HeadDEF() {
	return (
		<g>
			<path
				d="M263.709 271H264.499V267.95H268.289V267.23H264.499V264.73H268.739V264H263.709V271Z"
				fill="#333333"
			/>
			<path
				d="M263.64 297H268.75V296.28H264.43V293.83H268.25V293.11H264.43V290.72H268.7V290H263.64V297Z"
				fill="#333333"
			/>
			<path
				d="M263.079 323H265.509C267.709 323 269.229 321.47 269.229 319.5V319.48C269.229 317.51 267.709 316 265.509 316H263.079V323ZM263.869 322.27V316.73H265.509C267.279 316.73 268.409 317.95 268.409 319.5V319.52C268.409 321.07 267.279 322.27 265.509 322.27H263.869Z"
				fill="#333333"
			/>
		</g>
	);
}
