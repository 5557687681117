
import React from 'react';
import { TextField } from "@mui/material";
export default function StyledTextFieldMain({ disabled, ...props }) {
	return (
		<TextField
			inputRef={props.inputRef}
			{...props}
			sx={{
				...props.sx,
				"& .MuiOutlinedInput-notchedOutline": {
					border: "1px solid #D1D1D1",
					borderRadius: "8px",
					height: "50px",
					backgroundColor: disabled ? "lightgray" : undefined,
				},
				"& .MuiInputBase-root": {
					height: "50px",
				},
				"& .MuiInputBase-input": {
					paddingTop: "7px",
					paddingBottom: "10px",
				},
			}}
		/>
	);
}
