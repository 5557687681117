
import React from 'react'
import  { useState, useEffect } from "react";
import axios from "axios";
export default function useGetGQLTable({ gqlquery, url, querySign, isEdges = true, defaultIsLoading = false, variables, cancelToken }) {
    const [data, setData] = useState([]);
    const [isLoading, setIsloading] = useState(defaultIsLoading);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isError, setIsError] = useState(false);
    const [totalCount, setTotalCount] = useState();
    let controller = new AbortController();
    function fetch() {
        setIsloading(true);
        setErrorMessage(null);
        setIsError(false);
        axiosGraphqlRequest(url, gqlquery, {
            variables,
            cancelToken,
            controller,
        })
            .then(({ data, isError, errorMessage }) => {
            setIsError(isError);
            setErrorMessage(errorMessage);
            const specficData = isEdges ? data?.data?.[querySign]?.edges : data?.data?.[querySign];
            setData(specficData ?? []);
            setTotalCount(data?.data?.[querySign]?.totalCount ?? specficData?.length ?? 0);
        })
            .catch((err) => {
            setIsError(true);
            setErrorMessage(JSON.stringify({ err }));
        }).finally(() => {
            setIsloading(false);
        });
    }
    // useEffect(() => {
    //   fetch();
    // }, [gqlquery, querySign, url]);
    useEffect(() => {
        fetch();
        return () => {
            controller.abort();
        };
    }, [gqlquery, querySign, url]);
    function refetch() {
        fetch();
    }
    return {
        data,
        isLoading,
        isError,
        errorMessage,
        refetch: fetch,
        totalCount: totalCount ?? 0,
    };
}
export async function axiosGraphqlRequest(url, gqlQuery, options) {
    const graphqlQuery = {
        query: gqlQuery,
        variables: options && options.variables ? options.variables : {},
    };
    try {
        const { data } = await axios({
            url,
            headers: {
                authorization: options && options.jwtToken ? options.jwtToken : "",
                "Content-Type": "application/json",
            },
            data: graphqlQuery,
            method: "POST",
            cancelToken: options?.cancelToken,
            signal: options?.controller?.signal,
        });
        if (data && data.errors && data.errors[0] && data.errors[0].message) {
            throw new Error(data.errors[0].message);
        }
        return {
            data,
            isError: false,
            errorMessage: null,
        };
    }
    catch (err) {
        return {
            data: null,
            isError: true,
            errorMessage: err.message,
        };
    }
}
