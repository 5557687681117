
import React from 'react';
import { useState } from "react";
import useInputState from "../../../components/inputs/useInputState";
export default function useUpdateDeviceStates({ nodeToUpdate }) {
    const deviceId = useInputState({ isMandatory: true, initialValue: nodeToUpdate?.deviceId });
    const deviceName = useInputState({ isMandatory: false, initialValue: nodeToUpdate?.name });
    const manufacturerId = useInputState({ isMandatory: true, initialValue: nodeToUpdate?.model?.manufacturer?.id });
    const modelId = useInputState({ isMandatory: true, initialValue: nodeToUpdate?.model?.id });
    const status = useInputState({ isMandatory: true, initialValue: nodeToUpdate?.status?.id });
    const [aocCode, setAocCode] = useState("TAA");
    return {
        deviceId,
        deviceName,
        manufacturerId,
        modelId,
        status,
        aocCode, setAocCode,
    };
}
