import React from "react";
import { Grid } from "@mui/material";
import styles from "./ActiveFilters.module.css";
import DeleteFilter from "common/images/icons/elevade/Delete-filter.svg";

const ActiveFilters = ({ isFilters, setIsFilters, key, value }) => {
  function checkAnyKeyHasValue(obj) {
    return Object.values(obj).some((value) => value.length > 0);
  }

  const result = checkAnyKeyHasValue(isFilters);
  const keys = Object.keys(isFilters);

  function handleRemoveFilter(value, key) {
    if (key === "paxCapacity") value = parseInt(value);
    const updatedChecked = isFilters[key].filter((item) => item !== value);
    const updatedFilters = { ...isFilters, [key]: updatedChecked };
    setIsFilters(updatedFilters);
  }

  function handleClearAllFilter() {
    setIsFilters({
      search: [],
      hub: [],
      aircraftReg: [],
      status: [],
      paxCapacity: [],
      wifi: [],
      edto: [],
      engineType: [],
      flightLinkStatus: [],
    });
  }

  function getKeyName(key) {
    if (key === "status") return "Status: ";
    else if (key === "wifi") return "WIFI: ";
    else if (key === "edto") return "EDTO: ";
    else if (key === "engineType") return "Engine Type: ";
    else if (key === "flightLinkStatus") return "Flight Link Status: ";
    else if (key === "paxCapacity") return "Pax Capacity: ";
    else if (key === "hub") return "Station: ";
    else if (key === "aircraftReg") return "A/C Reg: ";
  }

  return (
    result && (
      <>
        <div className={styles.container}>
          <Grid container columns={10.2}>
            <Grid item xs={1} sx={{ paddingTop: "8px" }}>
              Active Filter:
            </Grid>
            <Grid item xs={8.6} sx={{ display: "flex" }}>
              {keys &&
                keys.map((key, index) => {
                  let first = true;
                  return isFilters[key].map((value) => {
                    return (
                      <div key={index} className={styles.filterContainer}>
                        {first && <div className={styles.filterKeyName}>{getKeyName(key)}</div>}
                        {(first = false)}
                        <div className={styles.filterItem}>
                          {value}
                          <img
                            src={DeleteFilter}
                            alt=""
                            className={styles.deleteFilter}
                            onClick={() => handleRemoveFilter(value, key)}
                          />
                        </div>
                      </div>
                    );
                  });
                })}
            </Grid>
            <Grid
              item
              xs={0.6}
              sx={{
                fontFamily: "Gotham-Medium",
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "8px",
              }}
            >
              <span style={{ cursor: "pointer" }} onClick={() => handleClearAllFilter()}>
                Clear All
              </span>
            </Grid>
          </Grid>
        </div>
        <div style={{ fontFamily: "Gotham-Book", fontSize: "12px", color: "#747474" }}>
          *Data displayed based on active filter
        </div>
      </>
    )
  );
};

export default ActiveFilters;
