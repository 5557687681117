import { combineReducers } from "@reduxjs/toolkit";
import companyReducer from "./company";
import userReducer from "./user";
import authReducer from "./auth";
import memberReducer from "./members";
import filtersReducer from "./elevade/filters";
import authReducerEntity from "./elevade/auth/index";
import userReducerEntity from "./elevade/user/index"
import sidebarToggleReducer from "./elevade/sidebarToggle";
import aocToggleReducer from "./elevade/aocToggle";

const rootReducer = combineReducers({
  company: companyReducer,
  user: userReducer,
  auth: authReducer,
  member: memberReducer,
  filters: filtersReducer,
  authEntity: authReducerEntity,
  userEntity: userReducerEntity,
  sidebarToggle: sidebarToggleReducer,
  aocToggle: aocToggleReducer,
});

export default rootReducer;
