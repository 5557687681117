import React from "react";
import { getPopupColorBasedOnStatusCode } from "../../Popup";
import AirplaneBox from "../AirplaneBox";
import { getBoxDataFromInFleetLocations } from "../AirplaneBox/utils";
export default function BoxFwdGalleyAdv({
	invs,
	onClick,
	setClosePopup,
	setPopup,
}) {
	const data = getBoxDataFromInFleetLocations(invs);
	const boxX = 290;
	const boxY = 80;
	const popupXOffset = -217;
	const popupYOffset = -410;
	return (
		<g className="display-box">
			<Pointer />
			<AirplaneBox
				title="FWD Galley"
				x={boxX}
				y={boxY}
				data={data}
				onClick={onClick}
				minWidth={140}
				setClosePopup={setClosePopup}
				setPopup={(rowX, rowY, data) => {
					setPopup({
						status: data?.inventory?.status,
						data: data,
						x: rowX + boxX + popupXOffset,
						y: rowY + boxY + popupYOffset,
						// row: 'row',
						col: data.locationId.seatNo,
						color: getPopupColorBasedOnStatusCode(
							data?.inventory?.status?.code
						),
					});
				}}
			/>
		</g>
	);
}
function Pointer() {
	return (
		<g>
			<path
				className="path"
				d="M 213.5 286.5 V 213.5 C 213.5 200 220 200 235 200 H 290 C 294 200 297 197 297 193 V 90"
				stroke="#333333"
				stroke-width="0.5"
				stroke-linecap="round"
				stroke-dasharray="2 2"
			/>
			<circle
				className="pointer-1st"
				cx="214"
				cy="287"
				r="3"
				fill="#74C4E8"
			/>
			<circle
				className="pointer-2nd"
				opacity="0.4"
				cx="214"
				cy="287"
				r="6"
				fill="#74C4E8"
			/>
		</g>
	);
}
