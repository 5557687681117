import { env_var_REACT_APP_API_URL } from "../../envVar/vars";
import useGetGQL from "../useGetGQL";
import useMutateGQL from "../useMutateGQL";

export function useGetAllInventoryItems () {
	const allInventoryItems = useGetGQL({
		gqlquery: `
		query eqType {
			allInventoryItems {
			  edges {
				node {
				  id
				  code
				  name
				  description
				}
			  }
			}
		  }
		`,
		querySign: 'allInventoryItems',
		url: env_var_REACT_APP_API_URL ?? '',
		defaultIsLoading: true,
		isEdges: true,
	});
	return allInventoryItems
}

export function useGetSearchedInvItem({ searchEqType }) {
	const data = useGetGQL({
		gqlquery: `
        query allitems {
            allInventoryItems
            ${searchEqType ? ` (icontainsName:"${searchEqType}") ` : ""}
            {
              edges{
                node{
                  id
                  code
                  name
                }
              }
            }
          }
        `,
		querySign: "allInventoryItems",
		url: env_var_REACT_APP_API_URL ?? "",
		defaultIsLoading: true,
	});
	return data;
}