
import React from 'react';

export default function TailRightToilet() {
	return (
		<g>
			<rect
				x="1185.5"
				y="268.5"
				width="29"
				height="46"
				rx="5.5"
				fill="white"
				stroke="#74C4E8"
			/>
			<path
				d="M1195.39 291.375C1195.39 291.589 1195.21 291.763 1195 291.763C1194.79 291.763 1194.61 291.589 1194.61 291.375C1194.61 291.161 1194.79 290.988 1195 290.988C1195.21 290.988 1195.39 291.161 1195.39 291.375Z"
				fill="black"
				stroke="#394458"
				stroke-width="1.1"
			/>
			<path
				d="M1195 297.625C1196.73 297.625 1198.12 294.827 1198.12 291.375C1198.12 287.923 1196.73 285.125 1195 285.125C1193.27 285.125 1191.88 287.923 1191.88 291.375C1191.88 294.827 1193.27 297.625 1195 297.625Z"
				stroke="#394458"
				stroke-width="1.1"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M1198.12 291.375V298.25C1198.12 298.416 1198.19 298.575 1198.31 298.692C1198.43 298.809 1198.58 298.875 1198.75 298.875H1207.5C1207.67 298.875 1207.82 298.809 1207.94 298.692C1208.06 298.575 1208.12 298.416 1208.12 298.25V291.375C1208.12 287.922 1206.73 285.125 1205 285.125H1195"
				stroke="#394458"
				stroke-width="1.1"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M1198.12 291.375H1200"
				stroke="#394458"
				stroke-width="1.1"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M1202.5 291.375H1203.75"
				stroke="#394458"
				stroke-width="1.1"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M1206.25 291.375H1208.12"
				stroke="#394458"
				stroke-width="1.1"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</g>
	);
}
