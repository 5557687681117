
import React from 'react'
import  { useState } from "react";
import useInputState from "../../../components/inputs/useInputState";
export default function useAddDeviceStates() {
	const deviceId = useInputState({ isMandatory: true });
	const deviceName = useInputState({ isMandatory: false });
	const manufacturerId = useInputState({ isMandatory: true });
	const modelId = useInputState({ isMandatory: true });
	const status = useInputState({ isMandatory: true });
	const [aocCode, setAocCode] = useState("TAA");
	return {
		deviceId,
		deviceName,
		manufacturerId,
		modelId,
		status,
		aocCode,
		setAocCode,
	};
}
