import React from "react";
import { ExpandMore } from "@mui/icons-material";
import { Grid, Select, MenuItem, Typography } from "@mui/material";
import styles from "./TableList.module.css";
import MUIPagination from "pages/elevade/component/Pagination";
import { useEffect, useState } from "react";
import { makeStyles, styled } from "@mui/styles";
import { makeAPIRequestMDB } from "common/helpers/makeAPIRequestMDB";
import { useSelector } from "react-redux";
import ElevadeDrawer from "./Drawer";
import IconClose from "common/images/icons/elevade/Icon-Close.svg";
import { InputField, SelectField } from "pages/elevade/component/InputForm";
import { AdeButton } from "common/components";
import { showNotificationElevade } from "common/helpers";
import { formatDateDasr, formatTimeDasr } from "pages/elevade/component/Helpers";
import LightTooltip from "./Tooltip";
import Button from "pages/elevade/component/Button";

const TableList = ({ data, setTriggerUpdate, isFilters, aircraftListPermission }) => {
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const { userName } = useSelector((state) => state.userEntity);
  const [aircraftReg, setAircraftReg] = useState();
  const [status, setStatus] = useState();
  const [open, setOpen] = useState(false);
  const [data_, setData] = useState([]);
  const initialFormData = [
    {
      aircraftReg: data_?.aircraftReg,
      hub_daily: data_?.status?.hub,
      status: data_?.status?.status,
      remarks_daily: data_?.status?.remarks,
      username: userName,
    },
  ];
  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    setPage(1);
    // eslint-disable-next-line
  }, [perPage]);

  useEffect(() => {
    setTotalRows(data?.length);
    setTotalPage(Math.floor(data?.length / perPage) + 1);
    let sliceStart = page > 0 ? perPage * (page - 1) : 0;
    if (sliceStart >= totalRows) sliceStart = Math.floor(data?.length / perPage);
    if (!setTriggerUpdate) setPage(0);
    // eslint-disable-next-line
  }, [data, perPage]);

  useEffect(() => {
    // eslint-disable-next-line
  }, [aircraftReg, status]);

  useEffect(() => {
    setFormData([
      {
        aircraftReg: data_?.aircraftReg,
        hub_daily: data_?.status?.hub,
        status: data_?.status?.status,
        remarks_daily: data_?.status?.remarks,
        username: userName,
      },
    ]);
  }, [open, data_, userName]);

  useEffect(() => {
    setPage(1);
  }, [isFilters]);

  // Custom styles
  const useStyles = makeStyles(() => ({
    ul: {
      "& .Mui-selected": {
        color: "#fff",
        backgroundColor: "#088FD1",
        border: "1px solid #088FD1",
        "&:hover": {
          backgroundColor: "#088FD1",
        },
      },
    },
    gridItem: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 2, // Number of lines to show
      fontSize: "14px",
    },
  }));

  // Set initialFormData on data_ changes
  // useEffect(() => {
  //   setFormData(updateArrayColumn(formData, "location", data_[0]?.hub_daily));
  // }, [data_]);

  const CustomExpandMore = styled(ExpandMore)({
    cursor: "default",
    "&:hover": {
      cursor: "pointer",
    },
    position: "absolute",
    right: 0,
    top: "calc(50% - 12px)",
    pointerEvents: "none",
  });

  const classes = useStyles();

  let sliceStart = page > 0 ? perPage * (page - 1) : 0;
  let sliceEnd = parseInt(perPage) + sliceStart;

  const handleChange = (col, val, ac_reg, val2, val3, data) => {
    let value1 = "";
    let value2 = "";
    let value3 = "";
    if (col === "hub_daily") {
      value1 = val;
      value2 = val2;
      value3 = val3;
    } else if (col === "status") {
      value1 = val2;
      value2 = val;
      value3 = val3;
    } else if (col === "remarks_daily") {
      value1 = val2;
      value2 = val3;

      value3 = val;
    }
    setAircraftReg(ac_reg);
    setStatus(value2);
    // const params = `api/aircraft-status-update?aircraftReg=${ac_reg}&username=${userName}&location=${value1}&status=${value2}&remarks=${value3}`;
    // UpdateData(params);
    const body = {
      aircraftReg: ac_reg,
      aoc: data?.aoc,
      msn: data?.msn,
      remarks: value3,
      status: value2,
      hub: value1,
    };
    const params = `api/aircraft/daily-status`;
    UpdateData(params, body);

    // Checking if status is Maintenance, AOG or Standby
    const today = new Date();
    const formattedDate = today.toISOString();
    let body_ = {
      formType: value2,
      formStatus: "open",
      aircraftReg: ac_reg,
      aoc: data?.aoc,
      location: data?.status?.hub,
      defectDesc: "",
      inputDate: formattedDate,
      ets: null,
      limitationSummary: "",
      createdBy: userName,
      createdAt: formattedDate,
    };
    // if (value2 === "Maintenance" || value2 === "AOG" || value2 === "Standby") createAircraftStatus(body_);
    createAircraftStatus(body_);
  };

  async function UpdateData(params, body) {
    setTriggerUpdate(true);
    const fetch = makeAPIRequestMDB(`post`, `${params}`, body);
    fetch
      .then((response) => {
        if (response.detail === "Aircraft daily status data created successfully") {
          if (open)
            showNotificationElevade("success", "Record successfully updated!", ["You successfully saved an update"]);
          setOpen(false);
          setTriggerUpdate(true);
        } else showNotificationElevade("danger", "An error occured!", ["Please contact your system administrator"]);
      })
      .catch((error) => {
        console.log("error", error);
        showNotificationElevade("danger", "An error occured!", ["Please contact your system administrator"]);
      });
  }

  async function createAircraftStatus(body) {
    console.log("creating...");
    const params = `api/aircraft/updated-status`;
    const fetch = makeAPIRequestMDB(`post`, `${params}`, body);
    fetch
      .then((response) => {
        // setTriggerUpdate(true);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  const handleOpenDrawer = (e) => {
    setOpen(true);
    setData(e);
  };

  function handleUpdateArrayColumn(data, column, value) {
    updateArrayColumn(data, column, value);
  }

  function handleSaveChanges() {
    const body = {
      aircraftReg: formData[0].aircraftReg,
      aoc: data_?.aoc,
      msn: data_?.msn,
      remarks: formData[0].remarks_daily,
      status: formData[0].status,
      hub: formData[0].hub_daily,
    };
    const params = `api/aircraft/daily-status`;
    UpdateData(params, body);
  }

  function updateArrayColumn(data, column, value) {
    return data.map((item) => {
      if (item.hasOwnProperty(column)) {
        return { ...item, [column]: value }; // Create a new object with updated column
      } else return { ...item, [column]: value }; // If the specified column doesn't exist, return the original item unchanged
    });
  }

  function updateArrayData(column, value) {
    return formData.map((item) => {
      if (item.hasOwnProperty(column)) {
        return { ...item, [column]: value }; // Create a new object with updated column
      } else return { ...item, [column]: value }; // If the specified column doesn't exist, return the original item unchanged
    });
  }

  const FormContent = () => {
    return (
      <div className={styles.drawerContainer}>
        <Grid container columns={4}>
          <Grid item xs={4} sx={{ borderTop: "0px solid #C3C3C3" }}>
            <div
              style={{
                marginTop: "0px",
                position: "relative",
                overflowY: "hidden",
                padding: "24px 16px",
                marginBottom: "60px",
              }}
            >
              <Grid container columns={2}>
                <Grid item xs={1} sx={{ color: "#838383" }}>
                  Aircraft Registration
                </Grid>
                <Grid item xs={1}>
                  Station<span className={styles.red}>*</span>
                </Grid>
                <Grid item xs={1} sx={{ padding: "16px 0px" }}>
                  {data_.aircraftReg}
                </Grid>
                <Grid item xs={1} sx={{ padding: "16px 0px" }}>
                  <SelectField
                    placeholder="Select Station"
                    fullWidth
                    options={[
                      { code: "BKI", name: "BKI" },
                      { code: "JHB", name: "JHB" },
                      { code: "KUL", name: "KUL" },
                      { code: "KCH", name: "KCH" },
                      { code: "PEN", name: "PEN" },
                    ]}
                    formData={formData}
                    col={"hub_daily"}
                    onChange={(e) => {
                      setFormData(updateArrayColumn(formData, "hub_daily", e.target.value));
                    }}
                    disabled={aircraftListPermission.some((perm) => perm.canUpdate === true) ? false : true}
                    // error={validateError("location")}
                  />
                </Grid>
                <Grid item xs={1} sx={{ color: "#838383" }}>
                  Engine Type
                </Grid>
                <Grid item xs={1} sx={{ color: "#838383" }}>
                  Pax Cap.
                </Grid>
                <Grid item xs={1} sx={{ padding: "16px 0px" }}>
                  {data_.engineType}
                </Grid>
                <Grid item xs={1} sx={{ padding: "16px 0px" }}>
                  {data_.paxCapacity}
                </Grid>
                <Grid item xs={1} sx={{ color: "#838383" }}>
                  EDTO
                </Grid>
                <Grid item xs={1} sx={{ color: "#838383" }}>
                  WIFI
                </Grid>
                <Grid item xs={1} sx={{ padding: "16px 0px" }}>
                  {data_.edto ? "Yes" : "No"}
                </Grid>
                <Grid item xs={1} sx={{ padding: "16px 0px" }}>
                  {data_.wifi ? "Yes" : "No"}
                </Grid>
                <Grid item xs={2} sx={{ color: "#838383" }}>
                  Status
                </Grid>
                <Grid item xs={2} sx={{ padding: "16px 0px" }}>
                  <SelectField
                    placeholder="Select Status"
                    fullWidth
                    options={[
                      { code: "Scheduled", name: "Scheduled" },
                      { code: "Standby", name: "Standby" },
                      { code: "AOG", name: "AOG" },
                      { code: "Maintenance", name: "Maintenance" },
                      { code: "Utilised", name: "Utilised" },
                      { code: "Storage", name: "Storage" },
                    ]}
                    formData={formData}
                    col={"status"}
                    onChange={(e) => {
                      setFormData(updateArrayColumn(formData, "status", e.target.value));
                    }}
                    disabled={aircraftListPermission.some((perm) => perm.canUpdate === true) ? false : true}
                    // error={validateError("location")}
                  />
                </Grid>
                <Grid item xs={2} sx={{ color: "#838383" }}>
                  Remarks
                </Grid>
                <Grid item xs={2} sx={{ padding: "16px 0px" }}>
                  <InputField
                    fullWidth
                    placeholder={"Insert text"}
                    formData={formData}
                    setFormData={setFormData}
                    col={"remarks_daily"}
                    onChange={(e) => handleUpdateArrayColumn(formData, "remarks_daily", e.target.value)}
                    updateArrayColumn={updateArrayData}
                    // error={validateError("ddmlNo")}
                    disabled={aircraftListPermission.some((perm) => perm.canUpdate === true) ? false : true}
                  />
                </Grid>
                <Grid item xs={2} sx={{ color: "#838383" }}>
                  Flightlink Status
                </Grid>
                <Grid item xs={2} sx={{ padding: "16px 0px" }}>
                  {data_.flightLinkStatus || "-"}
                </Grid>
                <Grid item xs={2} sx={{ color: "#838383" }}>
                  Current Service
                </Grid>
                <Grid item xs={2} sx={{ padding: "16px 0px" }}>
                  {data_.currentService || "-"}
                </Grid>
                <Grid item xs={1}>
                  <div className={styles.titleSummary}>
                    Created {formatDateDasr(data_.createdAt)} at {formatTimeDasr(data_.createdAt)}
                    <br />
                    Created by {data_.createdBy}
                  </div>
                </Grid>
                <Grid item xs={1} sx={{ paddingLeft: "10px" }}>
                  <div className={styles.titleSummary}>
                    {data_.status?.updatedAt !== null && data_.status?.updatedAt !== undefined
                      ? `Updated ${formatDateDasr(data_.status?.updatedAt)} at ${formatTimeDasr(
                          data_.status?.updatedAt
                        )}`
                      : ""}
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };

  const Component = () => {
    return (
      <>
        <Grid container columns={4}>
          <Grid item xs={3} sx={{ padding: "24px" }} className={styles.title}>
            Aircraft Details
            <span
              style={{
                border: "1px solid #C3C3C3",
                borderRadius: "6px",
                padding: "4px 8px",
                marginLeft: "8px",
                whiteSpace: "nowrap",
                fontFamily: "Gotham-Medium",
              }}
            >
              {data_.aircraftReg}
            </span>
          </Grid>
          <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-end", padding: "24px" }}>
            <div
              style={{
                height: "20px",
                margin: "0 20px",
                width: "1px",
                backgroundColor: "#D1D1D1",
              }}
            ></div>
            <img
              src={IconClose}
              alt="icon-close"
              height={15}
              style={{ marginTop: "2px", cursor: "pointer" }}
              onClick={() => setOpen(false)}
            />
          </Grid>
          <Grid item xs={4} sx={{ borderBottom: "1px solid #c3c3c3" }}></Grid>
        </Grid>
        <FormContent />
        <div
          style={{
            position: "fixed",
            bottom: "0",
            width: "540px",
            borderTop: "1px solid #D1D1D1",
            backgroundColor: "#ffffff",
            padding: "20px 24px 14px 24px",
            display: "flex",
            justifyContent: "center",
            zIndex: 2,
          }}
        >
          <AdeButton
            fullWidth
            variant="outlined"
            sx={{
              borderRadius: "8px",
              border: "0px solid #e8e8e8",
              fontFamily: "Gotham-Medium",
              pl: 6,
              pr: 6,
              mr: 4,
              "&.MuiButton-root:hover": { border: "transparent" },
            }}
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </AdeButton>
          <Button
            title="Save Changes"
            icon={""}
            iconDisabled={""}
            variant={aircraftListPermission.some((perm) => perm.canUpdate === true) ? "fill" : "disabled"}
            onClick={() => handleSaveChanges()}
            fullWidth={true}
            marginTop="0px"
          />
        </div>
      </>
    );
  };

  return (
    <>
      <div className={styles.tableDiv} style={{ marginTop: "20px" }}>
        <Grid container columns={13}>
          <Grid item xs={0.5} className={styles.tableHeader}>
            No.
          </Grid>
          <Grid item xs={1} className={styles.tableHeader}>
            A/C Reg.
          </Grid>
          <Grid item xs={1} className={styles.tableHeader}>
            Fleet Type
          </Grid>
          <Grid item xs={0.8} className={styles.tableHeader}>
            Station
          </Grid>
          <Grid item xs={0.8} className={styles.tableHeader}>
            Eng. Type
          </Grid>
          <Grid item xs={0.8} className={styles.tableHeader}>
            Pax Cap.
          </Grid>
          <Grid item xs={0.8} className={styles.tableHeader}>
            EDTO
          </Grid>
          <Grid item xs={0.8} className={styles.tableHeader}>
            WIFI
          </Grid>
          <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} xl={1} className={styles.tableHeader}>
            Status
          </Grid>
          <Grid item xs={2.5} sm={2.5} md={2.5} lg={2.5} xl={3} className={styles.tableHeader}>
            Remarks
          </Grid>
          <Grid item xs={1.5} className={styles.tableHeader}>
            Flightlink Status
          </Grid>
          <Grid item xs={1} className={styles.tableHeader}>
            Current Serv.
          </Grid>
          <Grid item xs={13}>
            {data &&
              data.slice(sliceStart, sliceEnd).map((e, index) => {
                return (
                  <>
                    <Grid container columns={13} className={styles.bodyContainer}>
                      <Grid
                        item
                        xs={0.5}
                        className={`${styles.tableBody} ${e.status.status === "AOG" ? styles.red : ""}`}
                        sx={{ alignItems: "center", cursor: "pointer" }}
                        onClick={() => handleOpenDrawer(e)}
                      >
                        {index + sliceStart + 1 + "."}
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        className={`${styles.tableBody} ${e.status.status === "AOG" ? styles.red : ""}`}
                        sx={{ alignItems: "center", cursor: "pointer" }}
                        onClick={() => handleOpenDrawer(e)}
                      >
                        {e.aircraftReg}
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        className={`${styles.tableBody} ${e.status.status === "AOG" ? styles.red : ""}`}
                        sx={{ alignItems: "center", cursor: "pointer" }}
                      >
                        {e?.fleetType?.replaceAll("Aircraft", "") || "Passenger"}
                      </Grid>
                      <Grid
                        item
                        xs={0.8}
                        className={`${styles.tableBody} ${e.status.status === "AOG" ? styles.red : ""}`}
                        sx={{ alignItems: "center", cursor: "pointer" }}
                      >
                        <Select
                          IconComponent={() => <CustomExpandMore fontSize="small" />}
                          sx={{
                            boxShadow: "none",
                            ".MuiOutlinedInput-notchedOutline": {
                              border: "none",
                              margin: "10px 0",
                            },
                            marginTop: "-16px",
                            marginBottom: "-16px",
                            color: e.status.status === "AOG" ? "#E95048" : "",
                            fontSize: "14px",
                          }}
                          value={e.status.hub}
                          defaultValue={e.status.hub}
                          onChange={(event) =>
                            handleChange(
                              "hub_daily",
                              event.target.value,
                              e.aircraftReg,
                              e.status.status,
                              e.status.remarks,
                              e
                            )
                          }
                          disabled={aircraftListPermission.some((perm) => perm.canUpdate === true) ? false : true}
                        >
                          <MenuItem value={"KUL"}>KUL</MenuItem>
                          <MenuItem value={"BKI"}>BKI</MenuItem>
                          <MenuItem value={"JHB"}>JHB</MenuItem>
                          <MenuItem value={"KCH"}>KCH</MenuItem>
                          <MenuItem value={"PEN"}>PEN</MenuItem>
                        </Select>
                      </Grid>
                      <Grid
                        item
                        xs={0.8}
                        className={`${styles.tableBody} ${e.engineType === "NEO" ? styles.green : ""} ${
                          e.status.status === "AOG" ? styles.red : ""
                        }`}
                        sx={{ alignItems: "center", cursor: "pointer" }}
                        onClick={() => handleOpenDrawer(e)}
                      >
                        {e.engineType}
                      </Grid>
                      <Grid
                        item
                        xs={0.8}
                        className={`${styles.tableBody} ${e.paxCapacity === 186 ? styles.green : ""} ${
                          e.status.status === "AOG" ? styles.red : ""
                        }`}
                        sx={{ alignItems: "center", cursor: "pointer" }}
                        onClick={() => handleOpenDrawer(e)}
                      >
                        {e.paxCapacity}
                      </Grid>
                      <Grid
                        item
                        xs={0.8}
                        className={`${styles.tableBody} ${e.edto === true ? styles.green : ""} ${
                          e.status.status === "AOG" ? styles.red : ""
                        }`}
                        sx={{ alignItems: "center", cursor: "pointer" }}
                        onClick={() => handleOpenDrawer(e)}
                      >
                        {e.edto ? "YES" : "NO"}
                      </Grid>
                      <Grid
                        item
                        xs={0.8}
                        className={`${styles.tableBody} ${e.wifi === true ? styles.green : ""} ${
                          e.status.status === "AOG" ? styles.red : ""
                        }`}
                        sx={{ alignItems: "center", cursor: "pointer" }}
                        onClick={() => handleOpenDrawer(e)}
                      >
                        {e.wifi ? "YES" : "NO"}
                      </Grid>
                      <Grid
                        item
                        xs={1.5}
                        sm={1.5}
                        md={1.5}
                        lg={1.5}
                        xl={1}
                        className={`${styles.tableBody} ${e.status.status === "AOG" ? styles.red : ""}`}
                        sx={{ alignItems: "center", cursor: "pointer" }}
                      >
                        <Select
                          IconComponent={() => <CustomExpandMore fontSize="small" />}
                          sx={{
                            boxShadow: "none",
                            ".MuiOutlinedInput-notchedOutline": {
                              border: "none",
                              margin: "10px 0",
                            },
                            marginTop: "-16px",
                            marginBottom: "-16px",
                            color: e.status.status === "AOG" ? "#E95048" : "",
                            fontSize: "14px",
                          }}
                          displayEmpty
                          value={e.status.status}
                          defaultValue={e.status.status}
                          onChange={(event) =>
                            handleChange("status", event.target.value, e.aircraftReg, e.status.hub, e.status.remarks, e)
                          }
                          disabled={aircraftListPermission.some((perm) => perm.canUpdate === true) ? false : true}
                        >
                          <MenuItem value={"Scheduled"}>Scheduled</MenuItem>
                          <MenuItem value={"Standby"}>Standby</MenuItem>
                          <MenuItem value={"AOG"} sx={{ color: "#E95048" }}>
                            AOG
                          </MenuItem>
                          <MenuItem value={"Maintenance"}>Maintenance</MenuItem>
                          <MenuItem value={"Utilised"}>Utilised</MenuItem>
                          <MenuItem value={"Storage"}>Storage</MenuItem>
                        </Select>
                      </Grid>
                      <Grid
                        item
                        xs={2.5}
                        sm={2.5}
                        md={2.5}
                        lg={2.5}
                        xl={3}
                        className={`${styles.tableBody} ${e?.status?.remarks ? styles.flexStart : ""} ${
                          e.status.status === "AOG" ? styles.red : ""
                        }`}
                        onClick={() => handleOpenDrawer(e)}
                        sx={{ paddingLeft: "12px" }}
                      >
                        {/* <center> */}
                        <LightTooltip title={e?.status?.remarks?.toUpperCase()} followCursor placement="bottom-start">
                          <Typography className={classes.gridItem}>
                            {e?.status?.remarks?.toUpperCase() || "-"}
                          </Typography>
                        </LightTooltip>
                        {/* </center> */}
                      </Grid>
                      <Grid
                        item
                        xs={1.5}
                        className={`${styles.tableBody} ${
                          e.flightLinkStatus === "FLIGHTLINK ACTIVE" ? styles.green : ""
                        } ${e.status.status === "AOG" ? styles.red : ""}`}
                        sx={{ alignItems: "center", cursor: "pointer" }}
                        onClick={() => handleOpenDrawer(e)}
                      >
                        {e.flightLinkStatus}
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        className={`${styles.tableBody} ${e.currentService === "FLYHT" ? styles.green : ""} ${
                          e.status.status === "AOG" ? styles.red : ""
                        }`}
                        sx={{ alignItems: "center", cursor: "pointer" }}
                        onClick={() => handleOpenDrawer(e)}
                      >
                        {e.currentService}
                      </Grid>
                    </Grid>
                  </>
                );
              })}
          </Grid>
          <Grid item xs={13} className={`${styles.lineItems}`}>
            <MUIPagination
              page={parseInt(page)}
              setPage={setPage}
              setPerPage={setPerPage}
              totalPage={parseInt(totalPages)}
              classes={{ ul: classes.ul }}
              totalRows={parseInt(totalRows)}
              sliceStart={sliceStart}
              initialPerPage={perPage}
            />
          </Grid>
          <ElevadeDrawer open={open} setOpen={setOpen} data={data_} Component={Component} top={"6%"} height={"95%"} />
        </Grid>
      </div>
    </>
  );
};

export default TableList;
