import React from "react";
import { Grid, Switch } from "@mui/material";
import { makeStyles } from "@mui/styles";
import styles from "./DDML.module.css";
import { InputField, InputFieldCalendar } from "pages/elevade/component/InputForm";
import { calculateBalanceDay, formatDateDasr } from "pages/elevade/component/Helpers";

const DefermentExpiry = ({ formData, setFormData, updateArrayColumn, formType, validateError }) => {
  // Custom styles
  const useStyles = makeStyles({
    root: {
      width: "40px",
      maxWidth: "40px",
      maxHeight: "22px",
      padding: "0px",
    },
    switchBase: {
      color: "#818181",
      padding: "1px",
      "&$checked": {
        "& + $track": {
          backgroundColor: "#088FD1",
        },
      },
    },
    thumb: {
      color: "white",
      width: "18px",
      height: "18px",
      margin: "1px",
    },
    track: {
      borderRadius: "20px",
      backgroundColor: "#D9D9D9",
      opacity: "1 !important",
      "&:after": {
        content: ``,
        left: "8px",
      },
      "&:before": {
        content: ``,
        right: "8px",
      },
    },
    checked: {
      color: "#394458 !important",
      transform: "translateX(18px) !important",
    },
    labelPlacementStart: {
      marginRight: "12px",
      color: "#838383",
    },
  });

  const classes = useStyles();

  function handleSwitch(event, col) {
    setFormData(updateArrayColumn(col, event.target.checked));
  }

  // Function to calculate balance day
  function calculateCalendarBalance(raisedDate, interval) {
    if (raisedDate !== null && raisedDate !== "" && interval !== null && interval !== "") {
      var date1 = new Date(raisedDate);
      date1.setDate(date1.getDate() + parseInt(interval));

      return formatDateDasr(date1.toISOString());
    }
    return "-";
  }

  let balanceDay = calculateBalanceDay(new Date(), formData[0].expiryDate);
  if (balanceDay > 1) balanceDay = balanceDay + " Days";
  else balanceDay = balanceDay + " Day";

  return (
    <>
      <Grid item xs={4} className={`${styles.title}`} sx={{ paddingTop: "24px" }}>
        Deferment Expiry
      </Grid>
      <Grid item xs={4} sx={{ color: "#838383", fontSize: "14px" }}>
        Select the deferment below to be displayed in the Aircraft Reports table
      </Grid>
      {/* <Grid
        item
        xs={1}
        className={`${styles.title}`}
        sx={{
          paddingTop: "24px",
          paddingBottom: formData?.[0].flightHours ? "" : "24px",
          borderBottom: formData?.[0].flightHours ? "" : "1px solid #E8E8E8",
        }}
      >
        Flight Hours
      </Grid> */}
      {/* <Grid
        item
        xs={3}
        sx={{
          paddingTop: "21px",
          paddingBottom: formData?.[0].flightHours ? "" : "24px",
          borderBottom: formData?.[0].flightHours ? "" : "1px solid #E8E8E8",
        }}
      >
        <Switch
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
          }}
          checked={formData?.[0].flightHours}
          onChange={(event) => handleSwitch(event, "flightHours")}
        />
      </Grid> */}
      {formData?.[0].flightHours && (
        <>
          <Grid item xs={4} className={`${styles.rowTitle}`}>
            Interval FH
          </Grid>
          <Grid
            item
            xs={4}
            className={`${styles.rowInput}`}
            sx={{ borderBottom: "1px solid #E8E8E8", paddingBottom: "24px" }}
          >
            <InputField
              fullWidth
              placeholder={""}
              formData={formData}
              setFormData={setFormData}
              col={"flightHoursInterval"}
              updateArrayColumn={updateArrayColumn}
            />
          </Grid>
        </>
      )}
      {/* <Grid
        item
        xs={1}
        className={`${styles.title}`}
        sx={{
          paddingTop: "24px",
          paddingBottom: formData?.[0].flightCycle ? "" : "24px",
          borderBottom: formData?.[0].flightCycle ? "" : "1px solid #E8E8E8",
        }}
      >
        Flight Cycles
      </Grid>
      <Grid
        item
        xs={3}
        sx={{
          paddingTop: "21px",
          paddingBottom: formData?.[0].flightCycle ? "" : "24px",
          borderBottom: formData?.[0].flightCycle ? "" : "1px solid #E8E8E8",
        }}
      >
        <Switch
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
          }}
          checked={formData?.[0].flightCycle}
          onChange={(event) => handleSwitch(event, "flightCycle")}
        />
      </Grid> */}
      {formData?.[0].flightCycle && (
        <>
          <Grid item xs={4} className={`${styles.rowTitle}`}>
            Interval FC
          </Grid>
          <Grid
            item
            xs={4}
            className={`${styles.rowInput}`}
            sx={{ borderBottom: "1px solid #E8E8E8", paddingBottom: "24px" }}
          >
            <InputField
              fullWidth
              placeholder={""}
              formData={formData}
              setFormData={setFormData}
              col={"flightCycleInterval"}
              updateArrayColumn={updateArrayColumn}
            />
          </Grid>
        </>
      )}
      {formType !== "STR/FC/FH" && (
        <>
          <Grid
            item
            xs={1}
            className={`${styles.title}`}
            sx={{
              paddingTop: "24px",
              paddingBottom: formData?.[0].flightDays ? "" : "24px",
              borderBottom: formData?.[0].flightDays ? "" : "1px solid #E8E8E8",
            }}
          >
            Flight Days
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              paddingTop: "21px",
              paddingBottom: formData?.[0].flightDays ? "" : "24px",
              borderBottom: formData?.[0].flightDays ? "" : "1px solid #E8E8E8",
            }}
          >
            <Switch
              classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
              }}
              checked={formData?.[0].flightDays}
              onChange={(event) => handleSwitch(event, "flightDays")}
            />
          </Grid>
          {formData?.[0].flightDays && (
            <>
              <Grid item xs={2} className={`${styles.rowTitle}`}>
                Interval Days
              </Grid>
              <Grid item xs={2} className={`${styles.rowTitle} ${styles.rowInputEnd}`}>
                Start Date
              </Grid>
              <Grid item xs={2} className={`${styles.rowInputStart}`}>
                <InputField
                  fullWidth
                  placeholder={""}
                  formData={formData}
                  setFormData={setFormData}
                  col={"flightDaysInterval"}
                  updateArrayColumn={updateArrayColumn}
                  error={validateError("flightDaysInterval")}
                />
              </Grid>
              <Grid item xs={2} className={`${styles.rowInputEnd}`}>
                <InputFieldCalendar
                  fullWidth
                  placeholder={"Select date"}
                  formData={formData}
                  setFormData={setFormData}
                  col={"flightDaysStartDate"}
                  // disableFuture
                />
              </Grid>
              <Grid item xs={1} className={`${styles.rowTitle}`}>
                Expiry
              </Grid>
              <Grid item xs={1} className={`${styles.rowTitle}`}>
                Balance
              </Grid>
              <Grid item xs={2} className={`${styles.rowTitle}`}></Grid>
              <Grid
                item
                xs={1}
                className={`${styles.rowInputStart}`}
                sx={{
                  borderBottom: "1px solid #E8E8E8",
                  paddingBottom: "24px",
                  fontSize: "14px",
                  fontFamily: "Gotham-Medium",
                  paddingTop: "6px",
                }}
              >
                {formatDateDasr(formData[0].expiryDate).split(",")[0]}
              </Grid>
              <Grid
                item
                xs={1}
                className={`${styles.rowInputStart}`}
                sx={{
                  borderBottom: "1px solid #E8E8E8",
                  paddingBottom: "24px",
                  fontSize: "14px",
                  fontFamily: "Gotham-Medium",
                  paddingTop: "6px",
                }}
              >
                {balanceDay !== "NaN Day" ? balanceDay : "-"}
              </Grid>
              <Grid
                item
                xs={2}
                className={`${styles.rowInputStart}`}
                sx={{
                  borderBottom: "1px solid #E8E8E8",
                  paddingBottom: "24px",
                }}
              ></Grid>
            </>
          )}
        </>
      )}

      {/* <Grid
        item
        xs={1}
        className={`${styles.title}`}
        sx={{
          paddingTop: "24px",
          paddingBottom: formData?.[0].calendarDays ? "" : "24px",
          borderBottom: formData?.[0].calendarDays ? "" : "1px solid #E8E8E8",
        }}
      >
        Calendar Days
      </Grid>
      <Grid
        item
        xs={3}
        sx={{
          paddingTop: "21px",
          paddingBottom: formData?.[0].calendarDays ? "" : "24px",
          borderBottom: formData?.[0].calendarDays ? "" : "1px solid #E8E8E8",
        }}
      >
        <Switch
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
          }}
          checked={formData?.[0].calendarDays}
          onChange={(event) => handleSwitch(event, "calendarDays")}
        />
      </Grid> */}
      {/* {formData?.[0].calendarDays && (
        <>
          <Grid item xs={4} className={`${styles.rowTitle}`}>
            Expiry
          </Grid>
          <Grid item xs={4} className={`${styles.title}`}>
            {calculateCalendarBalance(formData[0].calendarDaysStartDate, formData[0].calendarDaysInterval)}
          </Grid>
          <Grid item xs={2} className={`${styles.rowTitle}`}>
            Interval Days<span className={styles.red}>*</span>
          </Grid>
          <Grid item xs={2} className={`${styles.rowTitle} ${styles.rowInputEnd}`}>
            Raise Date<span className={styles.red}>*</span>
          </Grid>
          <Grid
            item
            xs={2}
            className={`${styles.rowInputStart}`}
            sx={{ borderBottom: "1px solid #E8E8E8", paddingBottom: "24px" }}
          >
            <InputField
              fullWidth
              placeholder={""}
              formData={formData}
              setFormData={setFormData}
              col={"calendarDaysInterval"}
              updateArrayColumn={updateArrayColumn}
            />
          </Grid>
          <Grid
            item
            xs={2}
            className={`${styles.rowInputEnd}`}
            sx={{ borderBottom: "1px solid #E8E8E8", paddingBottom: "24px" }}
          >
            <InputFieldCalendar
              fullWidth
              placeholder={"Select date"}
              formData={formData}
              setFormData={setFormData}
              col={"calendarDaysStartDate"}
              disableFuture
            />
          </Grid>
        </>
      )} */}
    </>
  );
};

export default DefermentExpiry;
