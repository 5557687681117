import React from 'react';
import FunnelSimple from "common/images/icons/elevade/FunnelSimple.jpg";
import FileTextIcon from "common/images/icons/elevade/FileText.jpg";
import { Grid } from "@mui/material";
import { toShortDateTimeString } from "common/helpers";
import { isEmpty } from "lodash";

export const FaultJournalSummary = ({ aircraft, flightNo, departureTime, arrivalTime, route, filters }) => {
  return (
    <>
      {/* pdf header formatting */}
      <Grid>
        <Grid pt="5px">
          <Grid container columns={12}>
            <Grid item xs={0.2}>
              <img src={FileTextIcon} alt="File" style={{ width: "3px", height: "3px", marginTop: "0.5px" }} />
            </Grid>
            <Grid item xs={1}>
              <span style={{ fontWeight: "bold", marginRight: "3px" }}>Details</span>
            </Grid>
          </Grid>
          <Grid container columns={12} pt="3px">
            <Grid item xs={1.2}>
              <span style={{ fontWeight: "bold" }}> Print Time (UTC)</span> <br />
            </Grid>
            <Grid item xs={0.1}>
              <span>:</span>
            </Grid>
            <Grid item xs={1.5}>
              <span>{toShortDateTimeString(new Date())}</span>
            </Grid>
            <Grid item xs={1}>
              <span style={{ fontWeight: "bold" }}> Aircraft</span> <br />
            </Grid>
            <Grid item xs={0.1}>
              <span>:</span>
            </Grid>
            <Grid item xs={1.5}>
              <span>{aircraft ?? ""}</span>
            </Grid>
            <Grid item xs={1}>
              <span style={{ fontWeight: "bold" }}>Flight Number</span> <br />
            </Grid>
            <Grid item xs={0.1}>
              <span>:</span>
            </Grid>
            <Grid item xs={1.5}>
              <span>{flightNo ?? ""}</span>
            </Grid>
          </Grid>
          <Grid container columns={12} pt="3px">
            <Grid item xs={1.2}>
              <span style={{ fontWeight: "bold" }}> Departure Time </span> <br />
            </Grid>
            <Grid item xs={0.1}>
              <span>:</span>
            </Grid>
            <Grid item xs={1.5}>
              <span>{toShortDateTimeString(departureTime ?? "")}</span>
            </Grid>
            <Grid item xs={1}>
              <span style={{ fontWeight: "bold" }}> Arrival Time</span> <br />
            </Grid>
            <Grid item xs={0.1}>
              <span>:</span>
            </Grid>
            <Grid item xs={1.5}>
              <span>{toShortDateTimeString(arrivalTime ?? "")}</span>
            </Grid>
            <Grid item xs={1}>
              <span style={{ fontWeight: "bold" }}>Route</span> <br />
            </Grid>
            <Grid item xs={0.1}>
              <span>:</span>
            </Grid>
            <Grid item xs={1.5}>
              <span>{route ?? ""}</span>
            </Grid>
          </Grid>
        </Grid>

        <Grid pt="5px">
          <Grid container columns={12}>
            <Grid item xs={0.2}>
              <img src={FunnelSimple} alt="File" style={{ width: "2px", height: "2px", marginTop: "1px" }} />
            </Grid>
            <Grid item xs={1}>
              <span style={{ fontWeight: "bold", marginRight: "3px" }}>Filters</span>
            </Grid>
          </Grid>

          <Grid container columns={12} pt="3px">
            {!isEmpty(filters) &&
              filters?.map((item) => {
                const filterList = item.data.toString();
                return (
                  <>
                    <Grid item xs={1.2}>
                      <span style={{ fontWeight: "bold" }}> {item.label}</span> <br />
                    </Grid>
                    <Grid item xs={0.1}>
                      <span>:</span>
                    </Grid>
                    <Grid item xs={1.5}>
                      <span>{filterList}</span>
                    </Grid>
                  </>
                );
              })}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
