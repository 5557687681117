import { Grid, Checkbox, styled } from "@mui/material";
import styles from "./AdvanceFilter.module.css";
import DeleteFilter from "common/images/icons/elevade/Delete-filter.svg";
import { uniqueList } from "./Helpers";
import React, { useEffect, useState } from "react";
import FleetCardHigh from "common/images/icons/elevade/Fleet-Card-High.svg";
import FleetCardMed from "common/images/icons/elevade/Fleet-Card-Med.svg";
import FleetCardLow from "common/images/icons/elevade/Fleet-Card-Low.svg";
import FleetCardOk from "common/images/icons/elevade/Fleet-Card-Ok.svg";
import FleetCardUndefined from "common/images/icons/elevade/Fleet-Card-Undefined.svg";
import FleetCardNone from "common/images/icons/elevade/Fleet-Card-None.svg";
import FleetCardNuisance from "common/images/icons/elevade/Fleet-Card-Nuisance.svg";
import CaretDoubleRight from "common/images/icons/elevade/CaretDoubleRight.svg";

const AdvanceFilter = ({
  data,
  airportList,
  hours,
  setHours,
  isAdvanceFilter,
  setIsAdvanceFilter,
  isSearchInput,
  setIsSearchInput,
  isAircraftType,
  setIsAircraftType,
  isOperator,
  setIsOperator,
  isFaultPriority,
  setIsFaultPriority,
  isDepartureAirport,
  setIsDepartureAirport,
  isArrivalAirport,
  setIsArrivalAirport,
}) => {
  const [isOperatorList, setIsOperatorList] = useState();
  const [isOperatorListFiltered, setIsOperatorListFiltered] = useState();
  const [isFaultPriorityList, setIsFaultPriorityList] = useState();
  const [isFaultPriorityListFiltered, setIsFaultPriorityListFiltered] = useState();
  const [isDepartureAirportList, setIsDepartureAirportList] = useState();
  const [isDepartureAirportListFiltered, setIsDepartureAirportListFiltered] = useState();
  const [isArrivalAirportList, setIsArrivalAirportList] = useState();
  const [isArrivalAirportListFiltered, setIsArrivalAirportListFiltered] = useState();
  const [isShowMoreOperator, setIsShowMoreOperator] = useState(false);
  const [isShowMorePriority, setIsShowMorePriority] = useState(false);
  const [isShowMoreDepartureAirport, setIsShowMoreDepartureAirport] = useState(false);
  const [isShowMoreArrivalAirport, setIsShowMoreArrivalAirport] = useState(false);
  const [isDepartureAirportName, setIsDepartureAirportName] = useState([]);
  const [isArrivalAirportName, setIsArrivalAirportName] = useState([]);
  const [airportListFormated, setAirportListFormated] = useState([]);
  const [searchOperator, setSearchOperator] = useState();
  const [searchPriority, setSearchPriority] = useState();
  const [searchDepartureAirport, setSearchDepartureAirport] = useState();
  const [searchArrivalAirport, setSearchArrivalAirport] = useState();

  const StyledCheckbox = styled(Checkbox)(() => ({
    [`.MuiCheckbox-root`]: {
      border: "1px solid blue",
    },
    marginTop: "-2px",
    color: "#94A3B8",
  }));

  useEffect(() => {
    let list = [];
    let filtered = [];
    isDepartureAirport.map((item) => {
      filtered = isDepartureAirportName.filter((e) => e.includes(item));
      if (filtered.length > 0) list.push(filtered[0]);
    });
    setIsDepartureAirportName(list);
  }, [isDepartureAirport]);

  useEffect(() => {
    let list = [];
    let filtered = [];
    isArrivalAirport.map((item) => {
      filtered = isArrivalAirportName.filter((e) => e.includes(item));
      if (filtered.length > 0) list.push(filtered[0]);
    });
    setIsArrivalAirportName(list);
  }, [isArrivalAirport]);

  useEffect(() => {
    let result = [];
    // eslint-disable-next-line no-unused-expressions
    airportList?.map((item, index) => {
      var label = item.label.split(" - ")[1] + " (" + item.label.split(" - ")[0] + ")";
      var id = index;
      // eslint-disable-next-line no-unused-expressions
      result.push({ label, id });
    });
    setAirportListFormated(result);
  }, [airportList]);

  useEffect(() => {
    setIsOperatorList(uniqueList(data, "Operator"));
    setIsOperatorListFiltered(uniqueList(data, "Operator"));
    setIsFaultPriorityList(uniqueList(data, "priority"));
    let list = uniqueList(data, "priority");
    var index = list.indexOf("Missing");
    if (index !== -1) {
      list[index] = "Null";
    }
    list = [...new Set(list)];
    const priority = ["High", "Medium", "Low", "OK", "Nuisance", "None", "Undefined", "Null", "Missing"];
    const sorted = list.sort((a, b) => priority.indexOf(a) - priority.indexOf(b));
    if (sorted.length > 0) {
      let filteredFaultPriotiry = sorted.filter((item) => item !== "null" && item !== null);
      setIsFaultPriorityListFiltered(filteredFaultPriotiry);
    }
    // setIsFaultPriorityListFiltered(uniqueList(data, "priority"));
    setIsDepartureAirportList(uniqueList(data, "departure_airport_format", airportList, "advanceFilter"));
    setIsDepartureAirportListFiltered(uniqueList(data, "departure_airport_format", airportList, "advanceFilter"));
    setIsArrivalAirportList(uniqueList(data, "destination_airport_format", airportList, "advanceFilter"));
    setIsArrivalAirportListFiltered(uniqueList(data, "destination_airport_format", airportList, "advanceFilter"));
  }, [data, airportList, airportListFormated]);

  useEffect(() => {
    setIsOperatorListFiltered(isOperatorList);
    setIsFaultPriorityListFiltered(isFaultPriorityList);
    setIsDepartureAirportListFiltered(isDepartureAirportList);
    setIsArrivalAirportListFiltered(isArrivalAirportList);
    if (searchOperator !== "" && searchOperator !== undefined) {
      setIsOperatorListFiltered(
        isOperatorList?.filter((e) => e?.toLowerCase().includes(searchOperator?.toLowerCase()))
      );
    }
    if (searchPriority !== "" && searchPriority !== undefined) {
      setIsFaultPriorityListFiltered(
        isFaultPriorityList?.filter((e) => e?.toLowerCase().includes(searchPriority?.toLowerCase()) && e !== "Missing")
      );
    }
    if (searchDepartureAirport !== "" && searchDepartureAirport !== undefined) {
      setIsDepartureAirportListFiltered(
        isDepartureAirportList?.filter((e) => e?.toLowerCase().includes(searchDepartureAirport?.toLowerCase()))
      );
    }
    if (searchArrivalAirport !== "" && searchArrivalAirport !== undefined) {
      setIsArrivalAirportListFiltered(
        isArrivalAirportList?.filter((e) => e?.toLowerCase().includes(searchArrivalAirport?.toLowerCase()))
      );
    }
  }, [searchOperator, searchPriority, searchDepartureAirport, searchArrivalAirport]);

  const handleRemoveFilter = (item, filter) => {
    if (filter === "searchInput") setIsSearchInput(isSearchInput.filter((e) => e !== item));
    else if (filter === "aircraftType") setIsAircraftType(isAircraftType.filter((e) => e !== item));
    else if (filter === "operator") setIsOperator(isOperator.filter((e) => e !== item));
    else if (filter === "faultPriority") setIsFaultPriority(isFaultPriority.filter((e) => e !== item));
    else if (filter === "departureAirport") setIsDepartureAirport(isDepartureAirport.filter((e) => e !== item));
    else if (filter === "arrivalAirport") setIsArrivalAirport(isArrivalAirport.filter((e) => e !== item));
  };

  const handleOperatorChecked = (e) => {
    setIsOperator([...isOperator, e]);
  };

  const handleOperatorUnchecked = (e) => {
    setIsOperator(isOperator?.filter((item) => item !== e));
  };

  const priorityIcon = (priority) => {
    switch (priority) {
      case "High":
        return <img src={FleetCardHigh} alt="High" className={styles.fleetIcon} />;
      case "Medium":
        return <img src={FleetCardMed} alt="Medium" className={styles.fleetIcon} />;
      case "Low":
        return <img src={FleetCardLow} alt="Low" className={styles.fleetIcon} />;
      case "OK":
        return <img src={FleetCardOk} alt="Ok" className={styles.fleetIcon} />;
      case "NO WARNING OR FAULT MESSAGE":
        return <img src={FleetCardOk} alt="Ok" className={styles.fleetIcon} />;
      case "Undefined":
        return <img src={FleetCardUndefined} alt="Undefined" className={styles.fleetIcon} />;
      case "None":
        return <img src={FleetCardNone} alt="None" className={styles.fleetIcon} />;
      case "Nuisance":
        return <img src={FleetCardNuisance} alt="Nuisance" className={styles.fleetIcon} />;
      default:
        return <img src={FleetCardUndefined} alt="None" className={styles.fleetIcon} />;
    }
  };

  return (
    <>
      <div
        id="myAdvanceFilter"
        className={`${styles.advanceFilter}`}
        style={{ right: `${isAdvanceFilter ? "0px" : "-245px"}` }}
      >
        <Grid container columns={2} className={`${styles.items}`} id="item">
          <Grid item xs={1} sx={{ paddingBottom: "8px" }}>
            Advanced Filter
          </Grid>
          <Grid
            item
            xs={1}
            sx={{ paddingBottom: "8px", display: "flex", justifyContent: "flex-end", paddingRight: "20px" }}
          >
            <img
              src={CaretDoubleRight}
              alt=""
              className={styles.caretIcon}
              title="Close"
              onClick={() => setIsAdvanceFilter(false)}
            />
          </Grid>
        </Grid>
        <div className={`${styles.body}`} id="itemBody">
          <Grid container columns={2}>
            {(isAircraftType.length > 0 ||
              isOperator.length > 0 ||
              isFaultPriority.length > 0 ||
              isDepartureAirport.length > 0 ||
              isArrivalAirport.length > 0) && (
              <Grid
                item
                xs={2}
                sx={{ fontFamily: "Gotham-Medium", fontSize: "14px", paddingBottom: "10px", paddingTop: "10px" }}
              >
                Active Filters
              </Grid>
            )}
            <Grid item xs={2}>
              {isSearchInput.length > 0 && <div className={styles.filterActiveTitle}>Search Input</div>}
              {isSearchInput.length > 0 &&
                isSearchInput.map((item) => {
                  return (
                    <>
                      <div className={styles.filterItem}>
                        {item}{" "}
                        <img
                          src={DeleteFilter}
                          alt=""
                          className={styles.deleteFilter}
                          onClick={() => handleRemoveFilter(item, "searchInput")}
                        />
                      </div>
                    </>
                  );
                })}
            </Grid>
            <Grid item xs={2}>
              {isAircraftType.length > 0 && <div className={styles.filterActiveTitle}>Aircraft Type</div>}
              {isAircraftType.length > 0 &&
                isAircraftType.map((item) => {
                  return (
                    <>
                      <div className={styles.filterItem}>
                        {item}{" "}
                        <img
                          src={DeleteFilter}
                          alt=""
                          className={styles.deleteFilter}
                          onClick={() => handleRemoveFilter(item, "aircraftType")}
                        />
                      </div>
                    </>
                  );
                })}
            </Grid>
            <Grid item xs={2}>
              {isOperator.length > 0 && <div className={styles.filterActiveTitle}>Operator</div>}
              {isOperator.length > 0 &&
                isOperator.map((item) => {
                  return (
                    <>
                      <div className={styles.filterItem}>
                        {item}{" "}
                        <img
                          src={DeleteFilter}
                          alt=""
                          className={styles.deleteFilter}
                          onClick={() => handleRemoveFilter(item, "operator")}
                        />
                      </div>
                    </>
                  );
                })}
            </Grid>
            <Grid item xs={2}>
              {isFaultPriority.length > 0 && <div className={styles.filterActiveTitle}>Fault Priority</div>}
              {isFaultPriority.length > 0 &&
                isFaultPriority.map((item) => {
                  return (
                    <>
                      <div className={styles.filterItem}>
                        {item === "Null" || item === "Missing" ? "Undefined" : item}{" "}
                        <img
                          src={DeleteFilter}
                          alt=""
                          className={styles.deleteFilter}
                          onClick={() => handleRemoveFilter(item, "faultPriority")}
                        />
                      </div>
                    </>
                  );
                })}
            </Grid>
            <Grid item xs={2}>
              {isDepartureAirport.length > 0 && <div className={styles.filterActiveTitle}>Departure Airport</div>}
              {isDepartureAirport.length > 0 &&
                isDepartureAirport.map((item) => {
                  return (
                    <>
                      <div className={styles.filterItem}>
                        {item}{" "}
                        <img
                          src={DeleteFilter}
                          alt=""
                          className={styles.deleteFilter}
                          onClick={() => handleRemoveFilter(item, "departureAirport")}
                        />
                      </div>
                    </>
                  );
                })}
            </Grid>
            <Grid item xs={2}>
              {isArrivalAirport.length > 0 && <div className={styles.filterActiveTitle}>Arrival Airport</div>}
              {isArrivalAirport.length > 0 &&
                isArrivalAirport.map((item) => {
                  return (
                    <>
                      <div className={styles.filterItem}>
                        {item}{" "}
                        <img
                          src={DeleteFilter}
                          alt=""
                          className={styles.deleteFilter}
                          onClick={() => handleRemoveFilter(item, "arrivalAirport")}
                        />
                      </div>
                    </>
                  );
                })}
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                paddingTop:
                  isAircraftType.length > 0 &&
                  isOperator.length > 0 &&
                  isFaultPriority.length > 0 &&
                  isDepartureAirport.length > 0 &&
                  isArrivalAirport.length > 0
                    ? "16px"
                    : "0px",
              }}
            >
              <div className={styles.filterTitle}>Operator</div>
              <input
                type="text"
                className={styles.textField}
                placeholder="Search..."
                value={searchOperator}
                onChange={(e) => setSearchOperator(e.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <ul className={`${styles.ul}`}>
                <Grid container columns={2}>
                  {isOperatorListFiltered?.length > 0 &&
                    isOperatorListFiltered?.slice(0, 4).map((item) => {
                      return (
                        <>
                          <Grid item xs={1}>
                            <li className={`${styles.li}`}>{item}</li>
                          </Grid>
                          <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <StyledCheckbox
                              value={item}
                              checked={isOperator?.filter((e) => e === item).length > 0 ? true : false}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  handleOperatorChecked(e.target.value);
                                } else {
                                  handleOperatorUnchecked(e.target.value);
                                }
                              }}
                            />
                          </Grid>
                        </>
                      );
                    })}
                  {isOperatorListFiltered?.length > 0 &&
                    isShowMoreOperator &&
                    isOperatorListFiltered?.slice(4).map((item) => {
                      return (
                        <>
                          <Grid item xs={1}>
                            <li className={`${styles.li}`}>{item}</li>
                          </Grid>
                          <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <StyledCheckbox
                              value={item}
                              checked={isOperator?.filter((e) => e === item).length > 0 ? true : false}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  handleOperatorChecked(e.target.value);
                                } else {
                                  handleOperatorUnchecked(e.target.value);
                                }
                              }}
                            />
                          </Grid>
                        </>
                      );
                    })}
                  {isOperatorListFiltered?.length > 4 && (
                    <div className={styles.showMore} onClick={() => setIsShowMoreOperator(!isShowMoreOperator)}>
                      {!isShowMoreOperator ? "Show More" : "Show Less"}
                    </div>
                  )}
                </Grid>
              </ul>
            </Grid>
            <Grid item xs={2} className={styles.line}>
              <div className={styles.filterTitle}>Fault Priority</div>
              <input
                type="text"
                className={styles.textField}
                placeholder="Search..."
                value={searchPriority}
                onChange={(e) => setSearchPriority(e.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <ul className={`${styles.ul}`}>
                <Grid container columns={2}>
                  {isFaultPriorityListFiltered?.length > 0 &&
                    isFaultPriorityListFiltered?.slice(0, 4).map((item) => {
                      return (
                        // item !== "Missing" &&
                        item !== "null" &&
                        item !== null && (
                          <>
                            <Grid item xs={1.8}>
                              <li className={`${styles.li}`}>
                                {priorityIcon(item)}
                                <span style={{ whiteSpace: "nowrap", marginLeft: "8px" }}>
                                  {item === "Null" || item === "Missing" ? "Undefined" : item}
                                </span>
                              </li>
                            </Grid>
                            <Grid item xs={0.2} sx={{ display: "flex", justifyContent: "flex-end" }}>
                              <StyledCheckbox
                                value={item}
                                checked={isFaultPriority?.filter((e) => e === item).length > 0 ? true : false}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setIsFaultPriority([...isFaultPriority, e.target.value]);
                                  } else {
                                    setIsFaultPriority(isFaultPriority.filter((item) => item !== e.target.value));
                                  }
                                }}
                              />
                            </Grid>
                          </>
                        )
                      );
                    })}
                  {isFaultPriorityListFiltered?.length > 4 &&
                    isShowMorePriority &&
                    isFaultPriorityListFiltered?.slice(4).map((item) => {
                      return (
                        // item !== "Missing" &&
                        item !== "null" &&
                        item !== null && (
                          <>
                            <Grid item xs={1.8}>
                              <li className={`${styles.li}`}>
                                {priorityIcon(item)}
                                <span style={{ whiteSpace: "nowrap", marginLeft: "8px" }}>
                                  {item === "Null" || item === "Missing" ? "Undefined" : item}
                                </span>
                              </li>
                            </Grid>
                            <Grid item xs={0.2} sx={{ display: "flex", justifyContent: "flex-end" }}>
                              <StyledCheckbox
                                value={item}
                                checked={isFaultPriority?.filter((e) => e === item).length > 0 ? true : false}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setIsFaultPriority([...isFaultPriority, e.target.value]);
                                  } else {
                                    setIsFaultPriority(isFaultPriority.filter((item) => item !== e.target.value));
                                  }
                                }}
                              />
                            </Grid>
                          </>
                        )
                      );
                    })}
                  {isFaultPriorityListFiltered?.length > 4 && (
                    <div className={styles.showMore} onClick={() => setIsShowMorePriority(!isShowMorePriority)}>
                      {!isShowMorePriority ? "Show More" : "Show Less"}
                    </div>
                  )}
                </Grid>
              </ul>
            </Grid>
            <Grid item xs={2} className={styles.line}>
              <div className={styles.filterTitle}>Departure Airport</div>
              <input
                type="text"
                className={styles.textField}
                placeholder="Search..."
                value={searchDepartureAirport}
                onChange={(e) => setSearchDepartureAirport(e.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <ul className={`${styles.ul}`}>
                <Grid container columns={2}>
                  {isDepartureAirportListFiltered?.length > 0 &&
                    isDepartureAirportListFiltered?.slice(0, 4).map((item) => {
                      return (
                        <>
                          <Grid item xs={1.8} className={styles.liItem}>
                            {item}
                          </Grid>
                          <Grid item xs={0.2} sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <StyledCheckbox
                              value={item}
                              checked={
                                isDepartureAirport?.filter((e) => e === item?.split(" - ")[0]).length > 0 ? true : false
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setIsDepartureAirport([...isDepartureAirport, e.target.value.split(" - ")[0]]);
                                  setIsDepartureAirportName([...isDepartureAirportName, e.target.value]);
                                } else {
                                  setIsDepartureAirport(
                                    isDepartureAirport.filter((item) => item !== e.target.value.split(" - ")[0])
                                  );
                                  setIsDepartureAirportName(
                                    isDepartureAirportName.filter((item) => item !== e.target.value)
                                  );
                                }
                              }}
                            />
                          </Grid>
                        </>
                      );
                    })}
                  {isDepartureAirportListFiltered?.length > 4 &&
                    isShowMoreDepartureAirport &&
                    isDepartureAirportListFiltered?.slice(4).map((item) => {
                      return (
                        <>
                          <Grid item xs={1.8} className={styles.liItem}>
                            {item}
                          </Grid>
                          <Grid item xs={0.2} sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <StyledCheckbox
                              value={item}
                              checked={
                                isDepartureAirport?.filter((e) => e === item?.split(" - ")[0]).length > 0 ? true : false
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setIsDepartureAirport([...isDepartureAirport, e.target.value.split(" - ")[0]]);
                                  setIsDepartureAirportName([...isDepartureAirportName, e.target.value]);
                                } else {
                                  setIsDepartureAirport(
                                    isDepartureAirport.filter((item) => item !== e.target.value.split(" - ")[0])
                                  );
                                  setIsDepartureAirportName(
                                    isDepartureAirportName.filter((item) => item !== e.target.value)
                                  );
                                }
                              }}
                            />
                          </Grid>
                        </>
                      );
                    })}
                  {isDepartureAirportListFiltered?.length > 4 && (
                    <div
                      className={styles.showMore}
                      onClick={() => setIsShowMoreDepartureAirport(!isShowMoreDepartureAirport)}
                    >
                      {!isShowMoreDepartureAirport ? "Show More" : "Show Less"}
                    </div>
                  )}
                </Grid>
              </ul>
            </Grid>
            <Grid item xs={2} className={styles.line}>
              <div className={styles.filterTitle}>Arrival Airport</div>
              <input
                type="text"
                className={styles.textField}
                placeholder="Search..."
                value={searchArrivalAirport}
                onChange={(e) => setSearchArrivalAirport(e.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <ul className={`${styles.ul}`}>
                <Grid container columns={2}>
                  {isArrivalAirportListFiltered?.length > 0 &&
                    isArrivalAirportListFiltered?.slice(0, 4).map((item) => {
                      return (
                        <>
                          <Grid item xs={1.8} className={styles.liItem}>
                            {item}
                          </Grid>
                          <Grid item xs={0.2} sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <StyledCheckbox
                              value={item}
                              checked={
                                isArrivalAirport?.filter((e) => e === item?.split(" - ")[0]).length > 0 ? true : false
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setIsArrivalAirport([...isArrivalAirport, e.target.value.split(" - ")[0]]);
                                  setIsArrivalAirportName([...isArrivalAirportName, e.target.value]);
                                } else {
                                  setIsArrivalAirport(
                                    isArrivalAirport.filter((item) => item !== e.target.value.split(" - ")[0])
                                  );
                                  setIsArrivalAirportName(
                                    isArrivalAirportName.filter((item) => item !== e.target.value)
                                  );
                                }
                              }}
                            />
                          </Grid>
                        </>
                      );
                    })}
                  {isArrivalAirportListFiltered?.length > 4 &&
                    isShowMoreArrivalAirport &&
                    isArrivalAirportListFiltered?.slice(4).map((item) => {
                      return (
                        <>
                          <Grid item xs={1.8} className={styles.liItem}>
                            {item}
                          </Grid>
                          <Grid item xs={0.2} sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <StyledCheckbox
                              value={item}
                              checked={
                                isArrivalAirport?.filter((e) => e === item?.split(" - ")[0]).length > 0 ? true : false
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setIsArrivalAirport([...isArrivalAirport, e.target.value.split(" - ")[0]]);
                                  setIsArrivalAirportName([...isArrivalAirportName, e.target.value]);
                                } else {
                                  setIsArrivalAirport(
                                    isArrivalAirport.filter((item) => item !== e.target.value.split(" - ")[0])
                                  );
                                  setIsArrivalAirportName(
                                    isArrivalAirportName.filter((item) => item !== e.target.value)
                                  );
                                }
                              }}
                            />
                          </Grid>
                        </>
                      );
                    })}
                  {isArrivalAirportListFiltered?.length > 4 && (
                    <div
                      className={styles.showMore}
                      onClick={() => setIsShowMoreArrivalAirport(!isShowMoreArrivalAirport)}
                    >
                      {!isShowMoreArrivalAirport ? "Show More" : "Show Less"}
                    </div>
                  )}
                </Grid>
              </ul>
            </Grid>
            <Grid item xs={2} className={styles.line}>
              <div className={styles.filterTitle}>Last Active</div>
            </Grid>
            <Grid item xs={2}>
              <Grid container columns={2}>
                <Grid item xs={1.8} className={styles.liItem}>
                  24 Hours
                </Grid>
                <Grid item xs={0.2} sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <StyledCheckbox
                    value={"24"}
                    checked={hours === 24}
                    onChange={(e) => {
                      if (e.target.checked) setHours(24);
                      else setHours(72);
                    }}
                  />
                </Grid>
                <Grid item xs={1.8} className={styles.liItem}>
                  48 Hours
                </Grid>
                <Grid item xs={0.2} sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <StyledCheckbox
                    value={"48"}
                    checked={hours === 48}
                    onChange={(e) => {
                      if (e.target.checked) setHours(48);
                      else setHours(72);
                    }}
                  />
                </Grid>
                <Grid item xs={1.8} className={styles.liItem}>
                  72 Hours
                </Grid>
                <Grid item xs={0.2} sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <StyledCheckbox
                    value={"72"}
                    checked={hours === 72}
                    onChange={(e) => {
                      if (e.target.checked) setHours(72);
                      else setHours(72);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default AdvanceFilter;
