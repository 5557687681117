import React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { styled } from "@mui/system";
import Select, { components } from "react-select";

const StyledSelect = styled(Select)(({ error }) => ({
  "&& > div": {
    padding: "6px",
    border: error ? "1px solid red" : "",
  },
}));

const optionStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected ? "#A3E0FF" : "#fff",
      color: "black",
      disabled: isDisabled,
    };
  },
};

const Option = (props) => {
  return (
    <components.Option {...props}>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox disabled={props.isDisabled} defaultChecked={props.isSelected} />}
          label={props.label}
          key={props.label}
        />
      </FormGroup>
    </components.Option>
  );
};

export const AdeMultiSelect = ({ options, onChange, value, defaultValue = [], disabled, required, error }) => {
  return (
    <StyledSelect
      options={options}
      error={error}
      isMulti
      required={required}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      components={{
        Option,
      }}
      defaultValue={defaultValue}
      allowSelectAll={true}
      styles={optionStyles}
      onChange={onChange}
      isDisabled={disabled}
      value={value}
    />
  );
};
