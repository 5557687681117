import React, { useState } from "react";
import styles from "./Sorting.module.css";
import IconSortingDefault from "common/images/icons/elevade/Icon-SortingDasrDefault.svg";
import IconSortingAsc from "common/images/icons/elevade/Icon-SortingDasrAsc.svg";
import IconSortingDesc from "common/images/icons/elevade/Icon-SortingDasrDesc.svg";

const Sorting = ({ ...props }) => {
  let Icon = IconSortingDefault;
  if (props.sortingDirection === "asc" && props.col === props.sortingColumn) Icon = IconSortingAsc;
  else if (props.sortingDirection === "desc" && props.col === props.sortingColumn) Icon = IconSortingDesc;
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  function handleSortingColumn(col) {
    props.setSortingDirection(props.sortingDirection === "asc" && props.col === props.sortingColumn ? "desc" : "asc");
    props.setSortingColumn(col);
  }

  return (
    <div
      className={styles.container}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => handleSortingColumn(props.col)}
    >
      <div
        className={`${styles.title} ${isHover ? styles.truncate : ""} ${
          props.sortingColumn === props.col ? styles.truncate : ""
        } ${props.align ? styles.textAlignLeft : styles.center}`}
      >
        {props.title}
        <div className={`${styles.icon}`}>
          <img
            src={Icon}
            alt="icon-sorting"
            className={`${isHover ? styles.visible : styles.invisible} ${
              props.sortingColumn === props.col ? styles.visible : ""
            }`}
          />
        </div>
      </div>
    </div>
  );
};

export default Sorting;
