import { Box, Grid, Typography } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./Inbox.module.css";
import Cogwheel from "common/images/icons/elevade/Cogwheel.svg";
import FleetCardHigh from "common/images/icons/elevade/Fleet-Card-High.svg";
import FleetCardMed from "common/images/icons/elevade/Fleet-Card-Med.svg";
import FleetCardLow from "common/images/icons/elevade/Fleet-Card-Low.svg";
import FleetCardOk from "common/images/icons/elevade/Fleet-Card-Ok.svg";
import FleetCardUndefined from "common/images/icons/elevade/Fleet-Card-Undefined.svg";
import FleetCardNone from "common/images/icons/elevade/Fleet-Card-None.svg";
import FleetCardNuisance from "common/images/icons/elevade/Fleet-Card-Nuisance.svg";
// import { fontWeight } from "@mui/system";
import CloseIcon from "common/images/icons/elevade/Close-Icon.svg";
import { AdeButton } from "common/components";

const AircraftReport = () => {
  const Data = require("../assets/aircraftReport.json");
  const [inbox, setInbox] = useState(Data);
  const [openCogwheel, setOpenCogwheel] = useState(false);
  const wrapperRef = useRef(null);
  const pathname = window.location.pathname;
  const [open, setOpen] = useState(false);
  const [faultLeg, setFautlLeg] = useState();
  const [activeLeg, setActivelLeg] = useState();
  // const id = useParams().id;
  const [faultPriority, setFaultPriority] = useState();
  const [reportType, setReportType] = useState();

  // Function to detect mouse click outside a component
  function useOutsideClicker(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpenCogwheel(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideClicker(wrapperRef);

  function getActivePath(path) {
    if (pathname.includes(path)) {
      return styles.active;
    } else {
      return "";
    }
  }

  // Creating a dynamic options for select box based on the data collection
  const createOptions = (data, col) => {
    let options = [];
    let option = [
      ...new Set(
        data.map((item) => {
          return item[col];
        })
      ),
    ];
    // Sorting the options alphabetically
    const sorted = option.sort();
    sorted.forEach((item) => {
      options.push(
        <option key={item} value={item}>
          {item}
        </option>
      );
    });
    return options;
  };

  const handleSearch = (e, attr) => {
    const search = e.target.value;
    const filteredData = inbox.filter((item) => {
      return item[attr]?.toLowerCase().includes(search?.toLowerCase());
    });
    if (attr === "priority") setFaultPriority(search);
    if (attr === "report_type") setReportType(search);
    if (search === "All") setInbox(Data);
    else setInbox(filteredData);
  };

  function getFleetCard(card) {
    switch (card) {
      case "High":
        return <img src={FleetCardHigh} alt="High" className={styles.fleetIcon} />;
      case "Med":
        return <img src={FleetCardMed} alt="Medium" className={styles.fleetIcon} />;
      case "Low":
        return <img src={FleetCardLow} alt="Low" className={styles.fleetIcon} />;
      case "OK":
        return <img src={FleetCardOk} alt="Ok" className={styles.fleetIcon} />;
      case "Undefined":
        return <img src={FleetCardUndefined} alt="Undefined" className={styles.fleetIcon} />;
      case "None":
        return <img src={FleetCardNone} alt="None" className={styles.fleetIcon} />;
      case "Nuisance":
        return <img src={FleetCardNuisance} alt="Nuisance" className={styles.fleetIcon} />;
      default:
    }
  }

  const SearchSection = () => {
    return (
      <div>
        <div className={`row ${styles.searchSection}`}>
          <Grid container spacing={1} columns={24}>
            <Grid item xs={5} pr={1.5}>
              <span className={`${styles.searchLabel}`}>Search</span>
              <input
                // ref={inputElement}
                type="text"
                className={`form-control ${styles.inputSearch}`}
                placeholder="Search"
              />
            </Grid>
            <Grid item xs={3} pr={3}>
              <span className={`${styles.searchLabel}`}>Report Type</span>
              <select
                className={`form-control ${styles.selectSearch}`}
                onChange={(e) => handleSearch(e, "report_type")}
                value={reportType}
              >
                <option>All</option>
                {createOptions(inbox, "report_type")}
              </select>
            </Grid>
            <Grid item xs={3} pr={1.5}>
              <span className={`${styles.searchLabel}`}>ACMS Priority</span>
              <select
                className={`form-control ${styles.selectSearch}`}
                onChange={(e) => handleSearch(e, "priority")}
                value={faultPriority}
              >
                <option>All</option>
                {createOptions(inbox, "priority")}
              </select>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  };

  const DisplayDataHeader = () => {
    return (
      <>
        <div className={`row ${styles.displayHeader}`}>
          <Grid container spacing={0} columns={29} className={`${styles.displayHeaderLabel}`}>
            <Grid item xs={1}>
              Prio.
            </Grid>
            <Grid item xs={2}>
              Reg.
            </Grid>
            <Grid item xs={2}>
              Report No.
            </Grid>
            <Grid item xs={3}>
              Report
            </Grid>
            <Grid item xs={2}>
              Trig. Code
            </Grid>
            <Grid item xs={2}>
              Flight
            </Grid>
            <Grid item xs={2}>
              Dep. ICAO
            </Grid>
            <Grid item xs={2}>
              Arrv. ICAO
            </Grid>
            <Grid item xs={2}>
              Dep. IATA
            </Grid>
            <Grid item xs={2}>
              Arrv. IATA
            </Grid>
            <Grid item xs={2}>
              Rep. Type
            </Grid>
            <Grid item xs={3.5}>
              Sent Time
            </Grid>
            <Grid item xs={3}>
              Gen. Time
            </Grid>
            <Grid item xs={0.5} style={{ display: "flex", justifyContent: "flex-end" }}>
              <span className={`${styles.displayHeaderLabel}`}>
                <img
                  src={Cogwheel}
                  className={styles.cogWheelIcon}
                  onClick={() => setOpenCogwheel(!openCogwheel)}
                  alt="cog-wheel"
                />
              </span>
              <div
                className={`${styles.cogWheelMenu} ${openCogwheel ? styles.cogWheelMenuActive : ""}`}
                ref={wrapperRef}
              >
                <ul className={styles.ul}>
                  <li className={styles.li}>Column Display</li>
                  <li className={styles.li}>Table Filter</li>
                  <li className={styles.li}>Export in CSV.</li>
                </ul>
              </div>
            </Grid>
          </Grid>
        </div>
      </>
    );
  };

  const Raw = () => {
    return (
      <>
        <div className={`row ${styles.legsBodyContent}`}>No data</div>
      </>
    );
  };

  const PrinterLayout = ({ index }) => {
    return (
      <>
        <div className={`row ${styles.legsBodyContent} ${styles.centerBodyContent}`}>
          <Box>
            <Typography sx={{ fontFamily: "Gotham-Medium" }}>ACMS A320 Family</Typography>
            <Typography sx={{ fontFamily: "Gotham-Medium", marginBottom: "20px" }}>
              ENGINE TAKE OFF REPORT (04)
            </Typography>
            <Typography sx={{ fontFamily: "Gotham-Medium" }}>ACID DATE TIME FROM TO FLT</Typography>
            <Typography sx={{ fontFamily: "Gotham-Book", marginBottom: "20px" }}>
              <span style={{ fontFamily: "Gotham-Medium" }}>CC</span> 9M-AFE FEB22 091041 WMKK WMKC 6440
            </Typography>
            <Typography sx={{ fontFamily: "Gotham-Medium" }}>EXSWPN</Typography>
            <Typography sx={{ fontFamily: "Gotham-Book", marginBottom: "20px" }}>
              <span style={{ fontFamily: "Gotham-Medium" }}>C0</span> TCF05AAT160103
            </Typography>
            <Typography sx={{ fontFamily: "Gotham-Medium" }}>PH CNT CODE BLEED STATUS APU</Typography>
            <Typography sx={{ fontFamily: "Gotham-Book", marginBottom: "20px" }}>
              <span style={{ fontFamily: "Gotham-Medium" }}>C1</span> 05 11704 4000 05 0010 0 0000 11 0
            </Typography>
            <Typography sx={{ fontFamily: "Gotham-Medium" }}>TAT ALT CAS MN GW CG DMU/SW</Typography>
            <Typography sx={{ fontFamily: "Gotham-Book", marginBottom: "20px" }}>
              <span style={{ fontFamily: "Gotham-Medium" }}>CE</span> 0303 01451 149 231 5983 333 C32T16
            </Typography>
            <Typography sx={{ fontFamily: "Gotham-Medium" }}>ESN EHRS ERT ECYC AP</Typography>
            <span style={{ fontFamily: "Gotham-Medium" }}>EC</span> 577513 00208 01040 00147 23
            <Typography sx={{ fontFamily: "Gotham-Book", marginBottom: "20px" }}>
              <span style={{ fontFamily: "Gotham-Medium" }}>EE</span> 577514 00208 00975 00147 23
            </Typography>
            <Typography sx={{ fontFamily: "Gotham-Medium" }}>T/O DELTA N1 SUMMARY EGTM</Typography>
            <span style={{ fontFamily: "Gotham-Medium" }}>N1</span> 01 00 00 00 00 00 00 01 00 7415
            <Typography sx={{ fontFamily: "Gotham-Book", marginBottom: "20px" }}>
              <span style={{ fontFamily: "Gotham-Medium" }}>N2</span> 01 00 00 00 00 00 00 01 00 7615
            </Typography>
            <Typography sx={{ fontFamily: "Gotham-Medium" }}>N1 N1C N2 EGT FF P3 N1MX</Typography>
            <span style={{ fontFamily: "Gotham-Medium" }}>S1</span> 0850 0849 0978 7415 3306 2995 0939
            <Typography sx={{ fontFamily: "Gotham-Book", marginBottom: "20px" }}>
              <span style={{ fontFamily: "Gotham-Medium" }}>S2</span> 0850 0849 0976 7615 3357 2983 0939
            </Typography>
            <Typography sx={{ fontFamily: "Gotham-Medium" }}>T3 T25 T12 P0 T5 VSV VBV</Typography>
            <span style={{ fontFamily: "Gotham-Medium" }}>T1</span> 5230 1193 035 139-- 019018
            <Typography sx={{ fontFamily: "Gotham-Book", marginBottom: "20px" }}>
              <span style={{ fontFamily: "Gotham-Medium" }}>T2</span> 5270 1205 034 139-- 027026
            </Typography>
            <Typography sx={{ fontFamily: "Gotham-Medium" }}>VN VL PHA PHT VC VH EVM</Typography>
            <span style={{ fontFamily: "Gotham-Medium" }}>V1</span> 18 00 320 072 03 00 00000
            <Typography sx={{ fontFamily: "Gotham-Book", marginBottom: "20px" }}>
              <span style={{ fontFamily: "Gotham-Medium" }}>V2</span> 05 09 354 124 03 00 00000{" "}
            </Typography>
            <Typography sx={{ fontFamily: "Gotham-Medium" }}>OIP OIT O/F ECW1 ECW2 PSEL</Typography>
            <span style={{ fontFamily: "Gotham-Medium" }}>V3</span> 050 083 1 1 00081 00000 2220
            <Typography sx={{ fontFamily: "Gotham-Book", marginBottom: "20px" }}>
              <span style={{ fontFamily: "Gotham-Medium" }}>V4</span> 052 081 1 1 00061 00000 2220
            </Typography>
            <Typography sx={{ fontFamily: "Gotham-Medium" }}>PT2 FT HPT LPT RALT</Typography>
            <span style={{ fontFamily: "Gotham-Medium" }}>X1</span> 14508 025 100 039 1448
            <Typography sx={{ fontFamily: "Gotham-Book", marginBottom: "20px" }}>
              <span style={{ fontFamily: "Gotham-Medium" }}>X2</span> 14523 024 100 038 1429
            </Typography>
          </Box>
        </div>
      </>
    );
  };

  const DisplayDataDetails = ({ legId, open }) => {
    return open === legId ? (
      <div className={`${styles.displayLegsDetails}`}>
        <div className={`row ${styles.displayLegsDetailsHeader}`}>
          <div
            className={`col-lg-2 ${styles.legsDetailsHeaderText} ${getActivePath("printer-layout")} ${
              pathname.endsWith("/aircraft-reports") ? styles.active : ""
            }`}
          >
            <Link to={`/fleet/inbox/aircraft-reports/printer-layout`} className={`${styles.link}`}>
              Printer Layout
            </Link>
          </div>
          <div className={`col-lg-2 ${styles.legsDetailsHeaderText} ${getActivePath("converted-layout")}`}>
            <Link to={`/fleet/inbox/aircraft-reports/converted-layout`} className={`${styles.link}`}>
              Converted Layout
            </Link>
          </div>
          <div className={`col-lg-1 ${styles.legsDetailsHeaderText} ${getActivePath("raw")}`}>
            <Link to={`/fleet/inbox/aircraft-reports/raw`} className={`${styles.link}`}>
              Raw
            </Link>
          </div>
          <div className={`col-lg-1 offset-lg-6`} style={{ display: "flex", justifyContent: "flex-end" }}>
            <img
              src={CloseIcon}
              height="20px"
              style={{ marginRight: "-10px", cursor: "pointer", zIndex: "999" }}
              onClick={() => {
                setOpen(false);
                setFautlLeg(null);
                setActivelLeg(null);
              }}
              alt="icon-close"
            />
          </div>
        </div>
        {pathname.endsWith("/inbox/aircraft-reports") || pathname.endsWith("/printer-layout") ? (
          <PrinterLayout index={legId} />
        ) : (
          <Raw />
        )}
        <div className="row" style={{ marginBottom: "auto", marginTop: "auto" }}>
          <div
            className="col-lg-4 offset-lg-8"
            style={{ display: "flex", justifyContent: "flex-end", alignSelf: "flex-end" }}
          >
            <AdeButton
              variant="outlined"
              fullWidth={false}
              sx={{ ml: 0 }}
              style={{ marginTop: "-24px", marginRight: "16px", marginBottom: "20px" }}
            >
              Export
            </AdeButton>
          </div>
        </div>
      </div>
    ) : (
      ""
    );
  };

  const DisplayData = () => {
    return inbox.map((item, index) => {
      return (
        <>
          <div
            className={`row ${styles.dataItems} ${faultLeg === index ? styles.dataItemsActive : ""}`}
            onClick={() => {
              setOpen(index);
              setFautlLeg(index);
            }}
          >
            <Grid container spacing={0} columns={29}>
              <Grid item xs={1}>
                {getFleetCard(item.priority)}
              </Grid>
              <Grid item xs={2} className={`${styles.dataItemsText}`}>
                {item.aircraft_id}
              </Grid>
              <Grid item xs={2} className={`${styles.dataItemsText}`}>
                {item.report_no}
              </Grid>
              <Grid item xs={3}>
                <div className={`${styles.tooltip} ${styles.dataItemsText}`}>
                  {item.message}
                  <span data-html="true" className={`${styles.tooltiptext} ${styles.tooltiptextEllipsis}`}>
                    {item.message}
                  </span>
                </div>
              </Grid>
              <Grid item xs={2} className={`${styles.dataItemsText}`}>
                {item.trig_code}
              </Grid>
              <Grid item xs={2} className={`${styles.dataItemsText}`}>
                {item.flight}
              </Grid>
              <Grid item xs={2} className={`${styles.dataItemsText}`}>
                {item.departure_icao}
              </Grid>
              <Grid item xs={2} className={`${styles.dataItemsText}`}>
                {item.arrival_icao}
              </Grid>
              <Grid item xs={2} className={`${styles.dataItemsText}`}>
                {item.departure_iata}
              </Grid>
              <Grid item xs={2} className={`${styles.dataItemsText}`}>
                {item.arrival_iata}
              </Grid>
              <Grid item xs={2} className={`${styles.dataItemsText}`}>
                {item.report_type}
              </Grid>
              <Grid item xs={3.5} className={`${styles.dataItemsText}`}>
                {item.sent_time}
              </Grid>
              <Grid item xs={3.5} className={`${styles.dataItemsText}`}>
                {item.generated_time}
              </Grid>
            </Grid>
          </div>
          <DisplayDataDetails legId={index} open={open} />
        </>
      );
    });
  };

  return (
    <>
      <SearchSection />
      <DisplayDataHeader />
      <DisplayData />
    </>
  );
};

export default AircraftReport;
