import React from 'react';
import { Grid, Radio } from "@mui/material";
import { ClickAwayListener } from "@mui/base";
import { useState } from "react";
import styles from "./AdeSelect.module.css";
import IconDown from "common/images/icons/elevade/Icon-Down.svg";

const AdeSelect = ({ options, ...props }) => {
  const [open, setOpen] = useState(false);

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div>
        <div className={`${styles.dropDown} ${open ? styles.active : ""}`} onClick={() => setOpen(!open)}>
          <Grid container columns={3}>
            <Grid item xs={2.3} sx={{ borderRight: "1px solid #C3C3C3" }}>
              {!props.selected ? [options[0]?.value] : props.selected.value}
            </Grid>
            <Grid item xs={0.7} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <div className={`${styles.dropDownIcon} ${open ? styles.rotate : styles.dropDownIcon}`}>
                <img src={IconDown} alt="icon-down" height={8} />
              </div>
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            display: open ? "block" : "none",
          }}
          className={styles.dropDownList}
        >
          <Grid container columns={2}>
            <Grid item xs={2} sx={{ color: "#838383", fontSize: "12px", padding: "6px 0px" }}>
              {props.title}
            </Grid>
            {options?.map((e, index) => {
              return (
                <>
                  <Grid item xs={1.6} sx={{ padding: "10px 0px 6px 0px" }} key={index + 1}>
                    <div className={styles.listItem} onClick={() => props.handleChange(e.value)}>
                      {e.value}
                    </div>
                  </Grid>
                  <Grid item xs={0.4} key={index + 2}>
                    <Radio
                      value={e.value}
                      checked={props.selected ? e.value === props.selected.value : e.value === options[0].value}
                      onClick={() => props.handleChange(e.value)}
                    />
                  </Grid>
                </>
              );
            })}
          </Grid>
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default AdeSelect;
