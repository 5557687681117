import { Autocomplete, Stack, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import * as React from "react";

const StyledAutoComplete = styled(Autocomplete)(() => ({
  [`.MuiOutlinedInput-root`]: {
    backgroundColor: "#FFFFFF",
    font: `Gotham-Book, sans-serif`,
    maxHeight: "7rem",
    overflow: "hidden",
  },
}));

export function AutoCompSelect({ label, options, ...props }) {
  return (
    <Stack>
      <p style={{ marginBottom: 5 }}>{label}</p>
      <StyledAutoComplete
        {...props}
        id="checkboxes-tags-demo"
        isOptionEqualToValue={(option, value) => true}
        options={options}
        disableCloseOnSelect
        limitTags={2}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            placeholder="Select..."
            notched={undefined}
            label=""
            InputLabelProps={{ shrink: false }}
            variant="outlined"
          />
        )}
      />
    </Stack>
  );
}
