import React, { useCallback, useEffect } from "react";
import { Grid } from "@mui/material";
// import { useAppDispatch } from 'common/store/hooks';
import { useDispatch, useSelector } from "react-redux";
import { getToken, logOut } from "common/store/reducers/elevade/auth/thunks";
import { isEmpty } from "lodash";
import { useHistory } from "react-router-dom";
import { getUserDetailByEmail, clearUserDetail } from "common/store/reducers/elevade/user/thunks";
import Loading from "pages/elevade/component/Loading";
import Cookies from "js-cookie";
import { showNotificationElevade } from "common/helpers";
// import { CONSTANT } from 'common/constant';

function Callback() {
  // const dispatch = useAppDispatch();
  const dispatch = useDispatch();
  const history = useHistory();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const token = urlSearchParams.get("verifyToken");

  const handleVerifyToken = useCallback(async () => {
    const userToken = await dispatch(getToken({ token, history }));
    if (!isEmpty(userToken) && userToken?.payload?.token) {
      Cookies.set("elevadeToken", userToken.payload.token, {
        expires: 1,
      });

      const userDetailRequest = {
        userEmail: userToken?.payload.userName,
        history,
      };
      // const userEmail = userToken?.payload?.userName
      const userdetail = await dispatch(getUserDetailByEmail(userDetailRequest));
      if (!isEmpty(userdetail)) history.push("/fleet/home");
      else if (isEmpty(userdetail.payload || userdetail.payload === false)) {
        handleLogout();
        return;
      }
    } else {
      handleLogout();
      return;
    }
  }, [token]);

  const handleLogout = async () => {
    try {
      await dispatch(logOut());
      await dispatch(clearUserDetail());
      // Cookies.set('Elevade Token', '', { expires: 1 });
      Cookies.remove("elevadeToken");
      localStorage.removeItem("avatar");
      // history('/', { replace: true });
      showNotificationElevade("danger", "Error", ["User not found in system"]);
      history.push("/fleet");
    } catch (error) {
      console.error("Error during logout", error);
    }
  };

  useEffect(() => {
    handleVerifyToken();
  }, []);

  return (
    <>
      <div style={{ minHeight: "460px", marginTop: "260px" }}>
        <Grid container columns={1}>
          <Grid item xs={1} sx={{ display: "flex", justifyContent: "center" }}>
            <Loading style={{ width: "200px" }} />
          </Grid>
          <Grid
            item
            xs={1}
            sx={{ display: "flex", justifyContent: "center", fontSize: "18px", fontFamily: "Gotham-Medium" }}
          >
            Loading...
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default Callback;
