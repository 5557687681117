/* eslint-disable no-unused-expressions */
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from "@mui/material";
import { gothamNormal } from "../constants/fonts";
export const appTheme = createTheme({
    typography: {
        fontFamily: gothamNormal,
        // fontFamily: [
        //   'GothamBook',
        //   'sans-serif',
        // ].join(','), //gothamNormal,
    },
    palette: {
        mode: "light",
    },
    // components: {
    //   MuiCssBaseline: {
    //     styleOverrides: `
    //       @font-face {
    //         font-family: 'GothamBook';
    //         font-style: normal;
    //         font-display: swap;
    //         font-weight: 400;
    //         src: local('GothamBook'), url(${gothamNormal}) format('oft');
    //         unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    //       }
    //     `,
    //   },
    // },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
        @font-face {
          font-family: 'GothamBook';
          src: local('GothamBook'), url(${gothamNormal});
        }
      `,
        },
    },
});
export default function ThemeInterface({ children }) {
    return (<ThemeProvider theme={appTheme}>
            {children}
        </ThemeProvider>);
}
