import { env_var_REACT_APP_API_URL } from "../../envVar/vars";
import useGetGQL from "../useGetGQL";
import useMutateGQL from "../useMutateGQL";
import { useAppSelector } from "../../app/hooks";

export function useGetAllLocations({ fleetId, locationId }) {
	const data = useGetGQL({
		gqlquery: `
    query allLocationList{
      allLocationLists(
		fleetId:"${fleetId}",
	 	${locationId ? `locationId:"${locationId}"` : ""} 
	  ){
        edgeCount
		totalCount
		edges {
			node {
				id
				inventory {
				id
				tag
				serialNo
				expiryDate
				manufactureDate
				status {
					id
					code
					name
				}
				item {
					id
					code
					name
				}
				}
				locationId {
				id
				seatNo
				inventoryLocation {
					id
					name
					code
				}
				}
			}
		}
      }
    }    
        `,
		querySign: "allLocationLists",
		url: env_var_REACT_APP_API_URL ?? "",
		defaultIsLoading: true,
		isEdges: false,
	});

	return data;
}


export function useUpdateLocation ({
	inFleetLocationId,
	changedStatus,
}) {

	const { userProfile } = useAppSelector((state) => state.userEntity);
	const fullName = userProfile?.fullName;
	const photo = userProfile?.photo;

	const data = useMutateGQL({
        gqlquery: () => {
            // const dateFormattedmfgDate: string | undefined = mfgDate ? `${new Date(mfgDate).getFullYear()}-${getMonth2D(new Date(mfgDate))}-${getDate2D(new Date(mfgDate))}` : undefined
            // const dateFormattedexpDate: string | undefined = expDate ? `${new Date(expDate).getFullYear()}-${getMonth2D(new Date(expDate))}-${getDate2D(new Date(expDate))}` : undefined
            return `
              mutation updateEQ {
                updateLocationList (input:{
                  id:"${inFleetLocationId ?? ""}"
                  ${changedStatus ? `status:"${changedStatus}"` : ''}
                  ${photo ? `userAvatar:"${photo}"` : ''}
                  userName:"${fullName}"
                }){
                locationList {
                    id
                  }
                }
              }
            `;
        },
        url: env_var_REACT_APP_API_URL ?? "",
    });

	return data
}