
import React from 'react';
import { Typography } from "@mui/material";
import { CircleIcon, WifiIcon, CalendarExpiredIcon, CalendarNearIcon, WarningIcon } from "../../icons/statusIcons";
export function ACExtraDetails({ missing, tagerror, expired, remind, defects, }) {
    return (<table style={{ width: '100%' }}>
            <DetailRow Icon={() => <CircleIcon />} label={"Missing"} value={missing}/>
            <DetailRow Icon={() => <WifiIcon />} label={"Error"} value={tagerror}/>
            <DetailRow Icon={() => <CalendarExpiredIcon />} label={"Expired"} value={expired}/>
            <DetailRow Icon={() => <CalendarNearIcon />} label={"Near Expiry"} value={remind}/>
            <DetailRow Icon={() => <WarningIcon />} label={"Defects"} value={defects}/>
        </table>);
}
function DetailRow({ Icon, label, value }) {
    return (<tr style={{ height: '25px' }}>
            <td style={{
            maxWidth: '5px',
            minWidth: '20px'
        }}><Icon /></td>
            <td align="left" style={{ minWidth: '195px' }}>
                <Typography>
                    {label}
                </Typography>
            </td>
            <td align="right" style={{ minWidth: '20px' }}>
                <Typography>
                    {typeof (value) === 'number' ? value.toLocaleString() : value}
                </Typography>
            </td>
        </tr>);
}
