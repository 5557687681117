
import React from 'react';
import { useEffect, useRef, useState } from "react";
import { isEmpty } from "../../utils/checks";
import useFirstRender from "../../utils/useFirstRender";
const mandatoryDefaultNoti = 'Please fill in this field';
/**
 *
 * @default isMandatory = false
 */
/* eslint-disable no-unused-expressions */
export default function useInputState({ isMandatory = false, initialValue, options }) {
    const [value, setValue] = useState(initialValue);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    // set initial value
    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);
    const inputRef = useRef(null);
    const isFirstRender = useFirstRender();
    // @TODO need to check type date
    function checkMandatory() {
        if (isMandatory && isEmpty(value, {
            isNanIsEmpty: true,
            isEmptyObjectIsEmpty: true,
        })) {
            //@ts-ignore
            // eslint-disable-next-line
            inputRef?.current?.focus();
            setIsError(true);
            setErrorMessage(isEmpty(errorMessage) ? mandatoryDefaultNoti : errorMessage);
            return {
                isError: true,
                errorMessage: isEmpty(errorMessage) ? mandatoryDefaultNoti : errorMessage,
            };
        }
        else if (isError && !isEmpty(value, {
            isNanIsEmpty: true,
            isEmptyObjectIsEmpty: true,
        }) && isMandatory) {
            //@ts-ignore
            // eslint-disable-next-line
            inputRef?.current?.focus();
            setIsError(false);
            setErrorMessage("");
            return {
                isError: false,
                errorMessage: undefined,
            };
        }
        else {
            setIsError(false);
            setErrorMessage("");
            return {
                isError: false,
                errorMessage: undefined,
            };
        }
    }
    useEffect(() => {
        if (!isFirstRender) {
            checkMandatory();
        }
    }, [isMandatory, value]);
    return {
        value, setValue,
        isLoading, setIsLoading,
        isError, setIsError,
        errorMessage, setErrorMessage,
        options, inputRef, checkMandatory,
        isMandatory,
    };
}
export function validateInputStates(inputStates, isReturnDetails = false) {
    let isOk = true;
    const details = {};
    Object.keys(inputStates).forEach((key) => {
        // if (key === 'mfgDate') {
        //     console.log({ val: inputStates['mfgDate'].value })
        // }
        if (inputStates[key].isError) {
            isOk = false;
            inputStates[key].checkMandatory();
            details[key] = {
                isError: inputStates[key].isError,
                errorMessage: inputStates[key].errorMessage,
            };
        }
        else {
            const { isError, errorMessage, } = inputStates[key].checkMandatory();
            if (isError) {
                isOk = false;
            }
            details[key] = {
                isError,
                errorMessage,
            };
        }
    });
    if (isReturnDetails) {
        return {
            details,
            isOk,
        };
    }
    else {
        return isOk;
    }
}
