import React from 'react';
import { Grid, Stack, Divider, TextField } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import "./AdeDatePicker.css";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import { useState } from "react";

const AdeDateRangePicker = ({ startDate, setStartDate, endDate, setEndDate, error, setError }) => {
  const [openStartDate, setOpenStartDate] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);

  return (
    <Grid id="DateRange">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack direction="row" spacing={3} sx={{ display: "flex", justifyContent: "flex-start" }}>
          <Stack
            width="100%"
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            sx={{
              border: error ? "1px solid #FC3F3F" : "0px solid #B1B1B1",
              borderRadius: "4px",
              ".MuiDivider-root	": { height: 1 / 2, alignSelf: "center" },
            }}
          >
            {/* <DateTimePicker
              className="DateRange"
              maxDate={dayjs()}
              sx={{ width: 1 / 2 }}
              open={openStartDate}
              onClose={() => setOpenStartDate(false)}
              ampm={false}
              inputFormat="yyyy-MM-dd HH:mm"
              renderInput={(props) => (
                <TextField
                  className="DateRange"
                  {...props}
                  InputProps={{
                    sx: {
                      ".MuiOutlinedInput-notchedOutline": { border: "none" },
                      ".MuiOutlinedInput-input": { textAlign: "center" },
                      ".MuiInputBase-input": { textAlign: "center" },
                    },
                    placeholder: "Start Date | Time",
                  }}
                  onClick={(e) => setOpenStartDate(true)}
                  sx={{ width: "100%", height: "100%" }}
                />
              )}
              value={startDate}
              onChange={(newValue) => {
                if (newValue?.toString() === "Invalid Date") setError("Date/Time format has to be in yyyy-MM-dd HH:mm");
                else {
                  setStartDate(newValue);
                  setError();
                }
              }}
              PopperProps={{
                className: "DateRange",
              }}
              DialogProps={{
                className: "DateRange",
              }}
            /> */}
            <DateTimePicker
              label="Start Date | Time"
              placeholder={false}
              maxDate={dayjs()}
              format="YYYY-MM-DD HH:mm"
              slotProps={{
                textField: {
                  variant: "standard",
                  label: false,
                  InputProps: {
                    disableUnderline: true,
                    style: {
                      fontSize: "14px",
                    },
                  },
                  style: {
                    border: "1px solid #B1B1B1",
                    borderRadius: "4px",
                    padding: "0px 16px",
                    fontSize: "14px",
                    fontFamily: "Gotham-Book",
                    width: "100%",
                    height: "60px",
                  },
                },
              }}
              value={dayjs(startDate)}
              onChange={(newValue) => {
                if (newValue?.toString() === "Invalid Date") setError("Date/Time format has to be in yyyy-MM-dd HH:mm");
                else {
                  setStartDate(new Date(newValue));
                  setError();
                }
              }}
            />
          </Stack>
          <Grid
            item
            xs={0.2}
            sx={{
              display: "flex",
              justifyContent: "center",
              fontFamily: "Gotham-Book",
              fontSize: "14px",
              paddingTop: "18px",
            }}
          >
            to
          </Grid>
          <Stack
            width="100%"
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            sx={{
              border: error ? "1px solid #FC3F3F" : "0px solid #B1B1B1",
              borderRadius: "4px",
              ".MuiDivider-root	": { height: 1 / 2, alignSelf: "center" },
            }}
          >
            {/* <DateTimePicker
              className="DateRange"
              maxDate={dayjs()}
              sx={{ width: 1 / 2 }}
              open={openEndDate}
              onClose={() => setOpenEndDate(false)}
              ampm={false}
              inputFormat="yyyy-MM-dd HH:mm"
              renderInput={(props) => {
                return (
                  <TextField
                    className="DateRange"
                    {...props}
                    InputProps={{
                      className: "DateRange",
                      sx: {
                        ".MuiOutlinedInput-notchedOutline": { border: "none" },
                        ".MuiOutlinedInput-input": { textAlign: "center" },
                        ".MuiInputBase-input": { textAlign: "center" },
                      },
                      placeholder: "End Date | Time",
                    }}
                    onClick={(e) => setOpenEndDate(true)}
                    sx={{ width: "100%", height: "100%" }}
                  />
                );
              }}
              value={endDate}
              onChange={(newValue) => {
                if (newValue?.toString() === "Invalid Date") setError("Date/Time format has to be in yyyy-MM-dd HH:mm");
                else {
                  setEndDate(newValue);
                  setError();
                }
              }}
              PopperProps={{
                className: "DateRange",
              }}
              DialogProps={{
                className: "DateRange",
              }}
            /> */}
            <DateTimePicker
              label="End Date | Time"
              placeholder={false}
              maxDate={dayjs()}
              format="YYYY-MM-DD HH:mm"
              slotProps={{
                textField: {
                  variant: "standard",
                  label: false,
                  InputProps: {
                    disableUnderline: true,
                    style: {
                      fontSize: "14px",
                    },
                  },
                  style: {
                    border: "1px solid #B1B1B1",
                    borderRadius: "4px",
                    padding: "0px 16px",
                    fontSize: "14px",
                    fontFamily: "Gotham-Book",
                    width: "100%",
                    marginRight: "10px",
                    height: "60px",
                  },
                },
              }}
              value={dayjs(endDate)}
              onChange={(newValue) => {
                if (newValue?.toString() === "Invalid Date") setError("Date/Time format has to be in yyyy-MM-dd HH:mm");
                else {
                  setEndDate(new Date(newValue));
                  setError();
                }
              }}
            />
          </Stack>
        </Stack>
        <Grid> {error && <span style={{ color: "#FC3F3F" }}>{error}</span>}</Grid>
      </LocalizationProvider>
    </Grid>
  );
};

export default AdeDateRangePicker;
