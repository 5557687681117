import _ from "lodash";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import React, { Fragment, useState, useEffect, useRef, useCallback } from "react";
import { Box } from "@mui/material";
import ElevadeSidebar from "./ElevadeSidebar";
import FleetSidebar from "./FleetSidebar";

const Sidebar = () => {

  return (
    <>
			<Box
				sx={{
					width:"84px",
					// backgroundColor:"blue",
					position:"fixed",
					left:0,
					top:"60px",
					minHeight: "100%",
					display: "flex",
					zIndex: "1998",
					backgroundColor: '#fff'
				}}
			>
				<ElevadeSidebar />
				<FleetSidebar />
			</Box>
    </>
  )
}

export default Sidebar;