
import React from 'react';
import { Box, Skeleton } from "@mui/material";
export default function LoadingInput() {
	return (
		<Box
			sx={{
				border: "1px solid #D1D1D1",
				borderRadius: "8px",
				height: "50px",
			}}
		>
			<Skeleton
				variant="text"
				height="50px"
				sx={{ marginLeft: "1em", marginRight: "1em" }}
			/>
		</Box>
	);
}
