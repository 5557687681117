
import React from 'react';
import Close from "@mui/icons-material/Close";
import { Modal, Box, IconButton } from "@mui/material";
export default function CenteredModal(props) {
	const {
		open,
		handleClose,
		children,
		// enableClickAwayClose = false,
		disableBackdropClick = false,
		style = {},
		containerStyle = {},
		hideBackdrop = false,
		positioning = "center",
		enableBackgroundScroll = false,
	} = props;
	let post = "";
	switch (positioning) {
		case "left":
			post = "start";
			break;
		case "right":
			post = "end";
			break;
		case "center":
		default:
			post = "center";
			break;
	}
	return (
		<Modal
			sx={{
				zIndex:2050
			}}
			disableScrollLock={enableBackgroundScroll}
			hideBackdrop={hideBackdrop}
			open={open}
			onClose={
				disableBackdropClick
					? (event, reason) => {
							if (
								reason !== "backdropClick" &&
								reason !== "escapeKeyDown"
							) {
								// Set 'open' to false, however you would do that with your particular code.
								handleClose();
							}
					  }
					: handleClose
			}
		>
			<div
				style={{
					display: "flex",
					width: "100%",
					height: "100%",
					alignItems: "center",
					justifyContent: post,
				}}
			>
				{/* <ClickAwayListener onClickAway={() => {
            if (enableClickAwayClose) {
                handleClose();
            }
        }}> */}
				<Box
					sx={{
						position: "absolute",
						// top: '50%', left: '50%',
						// transform: 'translate(-50%, -50%)',
						bgcolor: "white",
						boxShadow: 24,
						pt: 2,
						px: 4,
						pb: 3,
						borderRadius: "0.5em",
						height: "75%",
						width: "50%",
						...containerStyle,
					}}
				>
					<IconButton
						style={{
							position: "absolute",
							right: "5px",
							top: "5px",
						}}
						color="error"
						component="span"
						onClick={() => {
							handleClose();
						}}
					>
						<Close />
					</IconButton>
					<div
						style={{
							height: "99%",
							overflow: "auto",
							...style,
						}}
					>
						{children}
					</div>
				</Box>
				{/* </ClickAwayListener> */}
			</div>
		</Modal>
	);
}
