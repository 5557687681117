import React, { useEffect, useState } from "react";
import styles from "./Assignee.module.css";
import IconSearchGrey from "common/images/icons/elevade/Icon-SearchGrey.svg";
import IconAddUserGrey from "common/images/icons/elevade/Icon-AddUserGrey.svg";
import { ClickAwayListener } from "@mui/base";
import MUIAvatar from "pages/elevade/component/Avatar";
import { Fade, styled, Tooltip, tooltipClasses } from "@mui/material";
import { showNotificationElevade } from "common/helpers";
import { makeAPIRequestMDB } from "common/helpers/makeAPIRequestMDB";
import { useSelector } from "react-redux";

const Assignee = ({ ...props }) => {
  const { userProfile: userEntity } = useSelector((state) => state.userEntity);
  const [isSearch, setIsSearch] = useState("");
  const [isData, setIsData] = useState(props.list);
  const [isFiltered, setIsFiltered] = useState(isData);

  // Filter based on search query
  useEffect(() => {
    setIsFiltered(isData.filter((e) => e.name.toLowerCase().includes(isSearch.toLowerCase())));
  }, [isSearch]);

  function handleOpenAssignee() {
    props.setIsOpen(!props.isOpen);
    props.setIsActiveLine(props.data._id);
  }

  const handleSearchAssignee = (event) => {
    setIsSearch(event.target.value);
  };

  function handleClick(e) {
    let body = { name: e.name, email: e.email, assignByName: userEntity.fullName };
    // then call api
    const params = `api/assignment/assignee/${props.data._id}`;
    async function postAPI() {
      const post = makeAPIRequestMDB(`put`, params, body);
      post
        .then((response) => {
          if (response.detail.includes("success")) {
            showNotificationElevade("success", `Assignment successfully assigned to ${e.name}`, [""]);
            props.setTriggerApi(true);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
      props.setIsOpen(false);
    }
    postAPI();
  }

  const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#FFFEE8",
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: 11,
        textTransform: "uppercase",
      },
    })
  );

  // TODO: Checking for selected assignee
  return (
    <>
      <div className={styles.containerAddUser}>
        {props.data.assignee === null && (
          <div className={styles.addUser} onClick={() => handleOpenAssignee()}>
            <img src={IconAddUserGrey} alt="icon-add-user" />
          </div>
        )}
        {props.data.assignee !== null && (
          <div className={styles.userListDisplay} onClick={() => handleOpenAssignee()}>
            <MUIAvatar name={props.data.assignee?.name} />
            <LightTooltip title={props.data.assignee?.name} followCursor placement="bottom-start">
              <span
                className={styles.userListName}
                style={{
                  fontSize: "14px",
                  width: "100px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {props.data.assignee?.name}
              </span>
            </LightTooltip>
          </div>
        )}
        <ClickAwayListener
          onClickAway={() => (props.isActiveLine === props.data._id && props.isOpen ? props.setIsOpen(false) : "")}
        >
          <div>
            <Fade in={props.isActiveLine === props.data._id && props.isOpen}>
              <div className={styles.containerAssignee}>
                <div className={styles.searchAssignee}>
                  <img src={IconSearchGrey} alt="icon-search-user" />
                  <input
                    type="text"
                    className={styles.searchAssigneeInput}
                    placeholder="Search"
                    // value={isSearch}
                    onChange={handleSearchAssignee}
                    onFocus={() => props.setIsOpen(true)}
                  />
                </div>
                <div className={styles.containerUserList}>
                  {isFiltered.slice(0, 4).map((e, index) => {
                    return (
                      <div className={styles.userList} key={index} onClick={() => handleClick(e)}>
                        <MUIAvatar name={e.name} />
                        <span className={styles.userListName}>{e.name}</span>
                      </div>
                    );
                  })}
                  {props.isLoading && <div className={styles.loading}>Loading...</div>}
                </div>
              </div>
            </Fade>
          </div>
        </ClickAwayListener>
      </div>
    </>
  );
};

export default Assignee;
