import React from 'react';
import styles from "./Overlay.module.css";
import AirplaneLoader from "./AirplaneLoader";
const Overlay = () => {
  return (
    <>
      <div className={styles.overlay}>
        <AirplaneLoader color={"#ffffff"} />
      </div>
    </>
  );
};

export default Overlay;
