import _ from "lodash";

export function getEnumValueByCode(enumObject, enumCode) {
  var code = parseInt(enumCode);
  var result = enumObject[Object.keys(enumObject).find((key) => enumObject[key].code === code)];
  return result;
}

export function listEnumValue(enumObject) {
  var result = Object.values(enumObject);
  return result;
}

export function mapEnumValue(enumObject, isSort = false) {
  let result = Object.values(enumObject).map(({ code, name }) => ({
    name: name,
    code: code,
  }));
  if (isSort === true) {
    result = _.orderBy(result, ["name"], ["asc"]);
  }
  return result;
}

export function getYesNoValueByCode(enumObject, enumCode) {
  var result = enumObject[Object.keys(enumObject).find((key) => enumObject[key].code === enumCode)];
  return result;
}
