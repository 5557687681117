export const dateFnsFormats = {
  STANDARD_DATE: "MMM dd, yyyy",
  STANDARD_TIME: "HH:mm:ss",
  STANDARD_TIME_HOURS_MINUTES: "hh:mm aa",
  DATE_TIME: "MMM DD, YYYY HH:mm:ss",
  DAY_MONTH: "E, MMM dd",
  DATE_TIME_2: "yyyy-MM-dd HH:mm",
  DATE_TIME_4: "yyyy-MM-dd hh:mm aa",
  DATE_TIME_3: "yyyy-MM-dd HH:mm",
  STANDARD_DATETIME: "MMM, dd yyyy HH:mm",
  DATE_TIME_5: "HH:mm:ss MMM dd, yyyy",
};

export const dayJsFormats = {
  DATE_FNS_STANDARD_DATE: "MMM DD, YYYY",
  STANDARD_TIME: "HH:mm:ss",
  DATE_TIME: "MMM DD, YYYY HH:mm:ss",
};
