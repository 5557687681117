import { storage } from "../../firebase";

export const fileUpload = async (data, userId, folder = "documents") => {
  // const uploadTask = await storage.ref(`/${userId}/${folder}/${data.name}`).put(data);
  const downloadUrl = await storage.ref(`/${userId}/${folder}`).child(data.name).getDownloadURL();
  return { name: data.name, url: downloadUrl };
};

export const getFiles = async (userId, folder = "documents") => {
  const storageRef = storage.ref();
  const listRef = storageRef.child(`${userId}/${folder}`);
  try {
    const files = await listRef.listAll();
    const fileURLS = [];
    files.items.forEach((fileRef) => {
      fileURLS.push(fileRef.getDownloadURL());
    });
    return await Promise.all(fileURLS);
  } catch (error) {
    throw error;
  }
};

export const getDownloadURL = async (userId, folder = "documents", file) => {
  const downloadUrl = await storage.ref(`/${userId}/${folder}`).child(file).getDownloadURL();
  return downloadUrl;
};
