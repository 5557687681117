import { styled } from '@mui/system';
import PropTypes from 'prop-types';

const AdeSpan = styled('span')(({ theme, color, fontWeight }) => ({
  color: color || theme.palette.primary.main,
  fontWeight: fontWeight || theme.palette.primary.main
}))

AdeSpan.propTypes = {
  color: PropTypes.string,
  fontWeight: PropTypes.string,
}

export default AdeSpan