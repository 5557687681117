
import { useGetAllLocationInventoryDetails, useGetAllInvLocations } from "../../../../models/custom/location-inventory-model";
import { useGetAllFleetDistincted, useGetAllFleetByCompanyCodeSearchedByModel } from "../../../../models/custom/fleet-model";
import { useGetAllInventoryItems } from "../../../../models/custom/inventory-item-model";
import { useGetAllItemStatuses } from "../../../../models/custom/ac-item-model";

export default function useAddEqDatas({ locationTypeId, inputModel, fleetId, searchSeat, }) {

    const locationInventoryDetails = useGetAllLocationInventoryDetails({
		fleetId,
		locationTypeId,
		searchSeat,
	});
    const allInventoryLocations = useGetAllInvLocations();

    const allFleetsWithDistinctModel = useGetAllFleetDistincted();

    const allFleetsFilteredByModel = useGetAllFleetByCompanyCodeSearchedByModel({inputModel});

    const allInventoryItems = useGetAllInventoryItems();

    const allItemStatuses = useGetAllItemStatuses();

    return {
        locationInventoryDetails,
        allInventoryLocations,
        allFleetsWithDistinctModel,
        allFleetsFilteredByModel,
        allInventoryItems,
        allItemStatuses,
    };
}
