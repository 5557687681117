
import React from 'react';
import { TextField, Paper } from "@mui/material";
import { LocalizationProvider, StaticDateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useState, useEffect, useRef } from "react";
import zIndex from "../../../../../constants/viewIndex";
import { isNumeric } from "../../../../utils/string";
export function DateTimePickerCustom({ openOverride, onOpen, value, onChange, error, helperText, onError, }) {
    const [valueState, setValueState] = useState();
    const [isOpen, setIsOpen] = useState(value);
    function handleOpen(open) {
        if (onOpen) {
            onOpen(open);
        }
        setIsOpen(open);
    }
    useEffect(() => {
        if (openOverride !== undefined) {
            handleOpen(openOverride);
        }
    }, [openOverride]);
    // useEffect(() => {
    //     if (onChange) {
    //     }
    // }, [valueState])
    function handleTextChange(val) {
        setValueState(val);
        // // 2023-08-04 15:11:00
        const oriVal = val;
        const year = oriVal.slice(0, 4);
        const ymdashed = oriVal.slice(4, 5);
        const month = oriVal.slice(5, 7);
        const mddashed = oriVal.slice(7, 8);
        const date = oriVal.slice(8, 10);
        const dtSpace = oriVal.slice(10, 11);
        const hour = oriVal.slice(11, 13);
        const hmDouble = oriVal.slice(13, 14);
        const minutes = oriVal.slice(14, 16);
        const msDouble = oriVal.slice(16, 17);
        const seconds = oriVal.slice(17, 19);
        if (!(isNumeric(year) &&
            isNumeric(month) &&
            isNumeric(date) &&
            isNumeric(hour) &&
            isNumeric(minutes) &&
            isNumeric(seconds) &&
            ymdashed === '-' &&
            mddashed === '-' &&
            dtSpace === ' ' &&
            hmDouble === ':' &&
            msDouble === ':')) {
            if (onError) {
                onError('Date/Time format has to be in yyyy-MM-dd HH:mm:ss');
            }
        }
        else {
            onChange(val);
        }
        // console.log({
        //     year,
        //     ymdashed,
        //     month,
        //     mddashed,
        //     date,
        //     dtSpace,
        //     hour,
        //     hmDouble,
        //     minutes,
        //     msDouble,
        //     seconds,
        //     oriVal,
        // })
        // if () {
        //     setErrorMessage('Date/Time format has to be in yyyy-MM-dd HH:mm')
        // }
    }
    const textFieldInputRef = useRef();
    useEffect(() => {
        if (value === undefined) {
            setValueState(undefined);
            //@ts-ignore
            textFieldInputRef.current.value = '';
        }
    }, [value]);
    return (<div style={{
            position: 'relative',
            minWidth: '46%',
        }}>
            <TextField placeholder="YYYY-MM-DD HH:MM:SS" value={valueState} onClick={() => {
            handleOpen(true);
        }} onChange={(e) => {
            handleTextChange(e.target.value);
        }} fullWidth inputRef={textFieldInputRef} error={error} helperText={helperText}/>
            <Paper style={{
            position: 'absolute',
            width: isOpen ? 'fit-content' : '0px',
            // transition: 'width 0.5s ease-in-out',
            overflow: 'hidden',
            background: 'white',
            zIndex: zIndex.menu + 99,
            border: isOpen ? '1px solid whitesmoke' : undefined,
            top: '54px',
            right: 0,
        }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <StaticDateTimePicker slotProps={{
            tabs: {},
            layout: {
                onCancel: () => {
                    handleOpen(false);
                },
                onAccept: () => {
                    handleOpen(false);
                }
            }
        }} sx={{
            '& .MuiPickersToolbar-content': { display: 'none', },
            '& .MuiTypography-overline': { display: 'none', },
            '& .MuiPickersToolbar-root': { display: 'none', },
            width: 'fit-content',
        }} displayStaticWrapperAs="mobile" onChange={(newValue) => {
            const dateStr = new Date(newValue).getDate();
            const monthStr = new Date(newValue).getMonth() + 1;
            const preFixMonth = monthStr < 10 ? '0' : '';
            const yearStr = new Date(newValue).getFullYear();
            const preFixDate = dateStr < 10 ? '0' : '';
            let localeTime = new Date(newValue).toLocaleTimeString('en-US', { hour12: false });
            const hour = localeTime.slice(0, 3).replace('24:', '00:');
            const minutesSeconds = localeTime.slice(3, 8);
            localeTime = `${hour}${minutesSeconds}`;
            const newDate = `${yearStr}-${preFixMonth}${monthStr}-${preFixDate}${dateStr} ${localeTime}`;
            setValueState(newDate);
            onChange(newDate);
        }} ampm={false}/>
                </LocalizationProvider>
            </Paper>
        </div>);
}
