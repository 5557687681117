export function formatArray(array, col) {
  let Array_ = [];
  if (array.length > 0) {
    // eslint-disable-next-line
    array.map((e) => {
      if (col === "operator") Array_.push({ operator: e });
      else if (col === "ac_reg") Array_.push({ ac_reg: e });
    });
  }
  // Return a value with an alphabetical orders
  return Array_.sort(function (a, b) {
    if (a.operator < b.operator) return -1;
    if (a.operator > b.operator) return 1;
    return 0;
  });
}
