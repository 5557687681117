
import React from 'react';
import NumberedBox from "./NumberedBox";
export function GroupedNumberedBoxes({
	numbers = [],
	x = 0,
	y = 0,
	onClick,
	setClosePopup,
	setPopup,
}) {
	return (
		<g
			// transform(x,y) x=0 left , y=0 top
			transform={`translate(${x},${y})`}
		>
			{numbers.map((num, indx) => {
				return (
					<g
						onMouseEnter={() => {
							if (setPopup) {
								setPopup(indx * 23, num.data);
							}
							// console.log(selfRef.current?.getBoundingClientRect())
						}}
						onMouseLeave={() => {
							setClosePopup();
						}}
					>
						<NumberedBox
							number={num.value}
							x={indx * 23}
							boxColor={num.color}
							data={num.data}
							onClick={onClick}
						/>
					</g>
				);
			})}
		</g>
	);
}
