import { ContentState, convertToRaw, EditorState, Modifier } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Box } from "@mui/material";

const RichTextInput = forwardRef(({ onChange, value, toolbar, error }, ref) => {
  // const [editorState, setEditorState] = useState(() => {
  //   const defaultValue = value || "";
  //   const blocksFromHtml = htmlToDraft(defaultValue);
  //   const contentState = ContentState.createFromBlockArray(
  //     blocksFromHtml.contentBlocks,
  //     blocksFromHtml.entityMap
  //   );
  //   return EditorState.createWithContent(contentState);
  // });
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    if (!updated) {
      const defaultValue = value || "";
      const blocksFromHtml = htmlToDraft(defaultValue);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHtml.contentBlocks,
        blocksFromHtml.entityMap
      );
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
    }
    //eslint-disable-next-line
  }, [value]);

  // const onEditorStateChange = (editorState) => {
  //   setUpdated(true);
  //   setEditorState(editorState);

  //   return onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  // };

  const resetEditorState = () => {
    setEditorState(EditorState.createEmpty()); // Reset editor state to empty
    setUpdated(false); // Reset the updated flag
  };

  useImperativeHandle(ref, () => ({
    resetEditorState: resetEditorState,
  }));

  const onEditorStateChange = (editorState) => {
    setUpdated(true);
    setEditorState(editorState);

    const currentContent = editorState.getCurrentContent();
    const contentIsEmpty =
      currentContent.getBlockMap().size === 1 &&
      currentContent.getFirstBlock().getText() === "";

    if (contentIsEmpty) {
      onChange("");
    } else {
      onChange(draftToHtml(convertToRaw(currentContent)));
    }
  };

  return (
    <Box
      sx={{
        border: "1px solid",
        borderColor: error ? "red" : "#D1D1D1",
        borderRadius: "4px",
        padding: "16px",
      }}
    >
      <div className="editor">
        <Editor
          spellCheck
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          toolbar={toolbar}
          editorRef={ref}
        />
      </div>
    </Box>
  );
});
export default RichTextInput;
