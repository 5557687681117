import React, { forwardRef } from 'react';
import { styled } from '@mui/system';
import { AdeButton } from 'common/components';

const Input = styled('input')({
  display: 'none',
});

/**
 *  File upload button
 */
const AdeFileUpload = forwardRef(({ name, disabled, notes, ...props }, ref) => {
  return (
    <div>
      <label htmlFor={name} style={{ marginRight: '18px' }}>
        <Input
          id={name}
          multiple
          type='file'
          disabled={disabled}
          {...props}
          ref={ref}
        />
        <AdeButton fullWidth={false} variant='contained' component='span'>
          Browse
        </AdeButton>
      </label>
      <span>{notes}</span>
    </div>
  );
});

export default AdeFileUpload;