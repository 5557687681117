import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid, styled, Tab, Tooltip, tooltipClasses } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import styles from "../Dasr.module.css";
import IconErrorAirBalloon from "common/images/icons/elevade/Icon-ErrorAirBalloon.svg";
import DDML from "../aircraftReport/DDML";
import SearchBar from "./SearchBar";
import ActiveFilters from "./ActiveFilters";
import {
  addingDate,
  calculateBalanceDay,
  calculateCalendarBalance,
  calculateCalendarExpiryDate,
  formatDateDasr,
} from "pages/elevade/component/Helpers";
import MUIPagination from "pages/elevade/component/Pagination";
import { makeStyles } from "@mui/styles";
import { makeAPIRequestMDB } from "common/helpers/makeAPIRequestMDB";
import Overlay from "../../component/Overlay";
import IconAirplaneLimitation from "common/images/icons/elevade/Icon-AirplaneLimitation.svg";
import IconCheckCircleWhite from "common/images/icons/elevade/Icon-CheckCircleWhite.svg";
import IconCheckCircleDisabled from "common/images/icons/elevade/Icon-DustbinDisabled.svg";
import IconReInspection from "common/images/icons/elevade/Icon-ReInspection.svg";
import ElevadeDrawer from "../component/Drawer";
import Button from "pages/elevade/component/Button";
import DateToggle from "../component/DateToggle";
import DialogPush145 from "./DialogPush145";
import DialogSubmitShiftReport from "./DialogSubmitShiftReport";
import AirplaneLoader from "pages/elevade/component/AirplaneLoader";
import { makeAPIRequestRun } from "common/helpers";
import Assignee from "pages/elevade/component/Assignee";
import AdditionalTaskForm from "./AdditionalTaskForm";
import AdditionalTaskSummary from "./AdditionalTaskSummary";
import IconFlag from "common/images/icons/elevade/Icon-Flag.svg";
import IconFlagActive from "common/images/icons/elevade/Icon-FlagActive.svg";

const TaskAssignment = ({ aoc, isLoading, setIsLoading }) => {
  const { userPermission } = useSelector((state) => state.userEntity);
  const submitShiftReportPermsission = userPermission.filter((perm) => perm.code === "DASR-008");
  const [subLoading, setSubLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);
  const [type, setType] = useState("1");
  const [value, setValue] = useState("1");
  const [open, setOpen] = useState(false);
  const [openTitle, setOpenTitle] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState([]);
  const [data_, setData_] = useState([]);
  const [dataForm, setDataForm] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [index, setIndex] = useState();
  const [edit, setEdit] = useState(true);
  const [openNotification, setOpenNotification] = useState(false);
  const [newForm, setNewForm] = useState(false);
  const [triggerApi, setTriggerApi] = useState(true);
  const [activeIndex, setActiveIndex] = useState("");
  const [selectToMove, setSelectToMove] = useState([]);
  const [openDialogSubmitShiftReport, setOpenDialogSubmitShiftReport] = useState(false);
  const [openDialogMoveToAssignment, setOpenDialogMoveToAssignment] = useState(false);
  const [isSearch, setIsSearch] = useState([]);
  const InitialFilters = {
    search: [],
    location: [],
    aircraftReg: [],
    assignedTo: [],
    raisedDate: [],
    expiryDate: [],
    assignee: [],
  };
  const [isFilters, setIsFilters] = useState(InitialFilters);
  const [filteredData, setFilteredData] = useState([]);
  const [today, setToday] = useState(new Date());
  const [date, setDate] = useState(new Date());
  const [tabbing, setTabbing] = useState(1);
  const [dataFHFC, setDataFHFC] = useState([]);
  const [isOpenAssignee, setIsOpenAssignee] = useState(false);
  const [isActiveLine, setIsActiveLine] = useState();
  const [checkAll, setCheckAll] = useState(false);
  const [isLoadingAssignee, setIsLoadingAssignee] = useState(true);
  const [isAssignee, setIsAssignee] = useState([]);

  const today_ = new Date();
  const formattedDate = today_.toISOString();

  let sliceStart = page > 0 ? perPage * (page - 1) : 0;
  let sliceEnd = parseInt(perPage) + sliceStart;

  useEffect(() => {
    setIsLoading(false);
    fetchAssignee();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTotalRows(filteredData?.length);
    setTotalPage(Math.floor(filteredData?.length / perPage) + 1);
  }, [filteredData, perPage]);

  useEffect(() => {
    fetch();
    // eslint-disable-next-line
  }, [date, value, triggerApi, aoc]);
  //date, value, triggerApi, today, aoc
  //openNotification, triggerApi, date, value

  useEffect(() => {
    setTableLoading(true);
    setIsFilters(InitialFilters);
  }, [value]);

  useEffect(() => {
    filterDataBasedOnTabbing();
  }, [tabbing, value, data]);

  useEffect(() => {
    if (!open) setActiveIndex();
  }, [open]);

  useEffect(() => {
    fetchFHFC();
  }, []);

  // Applying filters
  useEffect(() => {
    let data__ = [];
    let theData = data_;
    setFilteredData(theData);

    // Search input fields
    if (isFilters.search.length !== 0) {
      data__ = theData.filter(
        (item) =>
          isFilters.search.some((searchTerm) =>
            item.ddml.location?.toLowerCase()?.includes(searchTerm?.toLowerCase())
          ) ||
          isFilters.search.some((searchTerm) =>
            item.ddml.aircraftReg?.toLowerCase()?.includes(searchTerm?.toLowerCase())
          ) ||
          isFilters.search.some((searchTerm) => item.ddml.ddmlNo?.toLowerCase()?.includes(searchTerm?.toLowerCase())) ||
          isFilters.search.some((searchTerm) =>
            item.ddml.limitationsDesc?.toLowerCase()?.includes(searchTerm?.toLowerCase())
          ) ||
          isFilters.search.some((searchTerm) =>
            item.ddml.assignedTo?.toLowerCase()?.includes(searchTerm?.toLowerCase())
          )
      );
      setFilteredData(data__);
    }

    // Station (location)
    if (isFilters.location.length > 0)
      setFilteredData(theData.filter((e) => isFilters.location.includes(e.ddml.location)));
    // Aircraft Registration
    if (isFilters.aircraftReg.length > 0)
      setFilteredData(theData.filter((e) => isFilters.aircraftReg.includes(e.ddml.aircraftReg)));
    // Assigned To (Department)
    if (isFilters.assignedTo.length > 0)
      setFilteredData(theData.filter((e) => isFilters.assignedTo.includes(e.ddml.assignedTo)));
    // Raised Date
    if (isFilters.raisedDate.length > 0)
      setFilteredData(theData.filter((e) => e.ddml.raisedDate.startsWith(isFilters.raisedDate)));
    // Expiry Date
    if (isFilters.expiryDate.length > 0)
      setFilteredData(theData.filter((e) => e.ddml.expiryDate.startsWith(isFilters.expiryDate)));
    // Assignee
    if (isFilters.assignee.length > 0)
      setFilteredData(theData.filter((e) => isFilters.assignee.includes(e.assignee?.name)));
    // eslint-disable-next-line
  }, [isFilters]);

  // Put all record in selectToMove
  useEffect(() => {
    handleCheckAll();
    // eslint-disable-next-line
  }, [data]);

  // A function to fetch all
  async function fetch() {
    // setTableLoading(true);
    // TODO: To update API to return based on selected AOC
    const fetch = makeAPIRequestMDB(
      `get`,
      `api/assignment?aoc=${aoc}&shift=${value === "1" ? "day" : "night"}&date=${formatDate(date)}`
    );
    fetch
      .then((response) => {
        if (response.detail !== "Record not found" && response.detail !== "DDML not found.") {
          let data = response?.data?.filter((e) => e.status === "open"); // && e.ddml.formStatus !== "closed"
          setData(data);
          setData_(data);
        } else {
          setData([]);
          setData_([]);
          setFilteredData([]);
        }
        setSubLoading(false);
        setOpen(false);
        setOpenNotification(false);
        setTriggerApi(false);
        setTableLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setSubLoading(false);
        setTableLoading(false);
      });
  }

  async function fetchFHFC() {
    // setSubLoading(true);
    const fetch = makeAPIRequestRun(`get`, `acarsdata/fleetgetfcfh/null`);
    fetch
      .then((response) => {
        setDataFHFC(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  async function fetchAssignee() {
    const fetch = makeAPIRequestMDB(`get`, `api/entity/users/lae?aoc=ADE`);
    fetch
      .then((response) => {
        setIsAssignee(
          // TODO: Uncomment this before deployment
          // response.data.sort(function (a, b) {
          //   return a.name.localeCompare(b.name);
          // })
          [
            { name: "Khairulisam Ramli", email: "khairulisamramli@airasia.com" },
            { name: "Ainul Roslie", email: "ainulroslie@airasia.com" },
          ]
        );
        setIsLoadingAssignee(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function filterDataBasedOnTabbing() {
    setSubLoading(true);
    let formType = "ddml";
    let shiftType = "day";
    if (tabbing === 1) formType = "ddml";
    else if (tabbing === 2) formType = "rdd";
    else if (tabbing === 3) formType = "adhoc";

    if (value === "1") shiftType = "day";
    if (value === "2") shiftType = "night";

    let filtered = data?.length > 0 ? data.filter((e) => e.formType === formType && e.shiftType === shiftType) : [];

    // setData_(filtered);
    setFilteredData(filtered);
    setSubLoading(false);
  }

  function handleCheckAll() {
    let array = [];

    // TODO: recheck on this later
    let array_ = data
      ?.filter((e) => e.status === "open")
      .map((e) => {
        return array.push(e._id);
      });
    setSelectToMove(array);
  }

  function formatDate(dateString) {
    // Parse the input date string
    const dateObj = new Date(dateString);

    // Get the year, month, and day components
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");

    // Construct the formatted date string
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }

  function handleClick(e, index) {
    setOpen(true);
    setIndex(index);
    setDataForm(e);
    setEdit(false);
    setActiveIndex(index);
    setNewForm(false);
    setIsOpenAssignee(false);
  }

  function calcBalanceFH(interval, raisedAt, val) {
    let expiry = parseInt(interval) + parseInt(raisedAt);
    let current = val;
    let balance = parseInt(expiry) - parseInt(current);
    return parseInt(balance).toLocaleString() || "-";
  }

  function calcBalanceFC(interval, raisedAt, val) {
    let expiry = parseInt(interval) + parseInt(raisedAt);
    let current = val;
    let balance = parseInt(expiry) - parseInt(current);
    return parseInt(balance).toLocaleString() || "-";
  }

  // Function to calculate balance day
  function calculateCalendarBalanceDate(date, interval) {
    if (date !== null && date !== "" && interval !== null && interval !== "") {
      var date1 = new Date(date);
      date1.setDate(date1.getDate() + parseInt(interval));

      return formatDateDasr(date1.toISOString());
    }
    return "-";
  }

  function getRectificationStatus(item) {
    if (item !== undefined && item !== null) {
      if (item?.pushToSummary === "") return undefined;
      else {
        return item?.updateLogDesc;
      }
    }
  }

  function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string?.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        fontSize: "12px",
      },
      children: `${name?.substring(0, 3)}`,
    };
  }

  // Custom styles
  const useStyles = makeStyles(() => ({
    ul: {
      "& .Mui-selected": {
        color: "#fff",
        backgroundColor: "#088FD1",
        border: "1px solid #088FD1",
        "&:hover": {
          backgroundColor: "#088FD1",
        },
      },
    },
    gridItem: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-box-orient": "vertical",
      "-webkit-line-clamp": 2, // Number of lines to show
      fontSize: "14px",
      justifyContent: "flex-start",
    },
  }));

  const classes = useStyles();

  const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    // padding: 0,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    fontFamily: ["Gotham-Book"].join(","),
    "&:hover": {
      color: "#333333",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#333333",
      fontFamily: "Gotham-Medium",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  }));

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setType(newValue);
  };

  const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#FFFEE8",
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: 11,
        textTransform: "uppercase",
      },
    })
  );

  const ErrorAirBallon = () => {
    return (
      <>
        <div className={styles.errorAirBallon}>
          <center>
            <img src={IconErrorAirBalloon} alt="error-air-ballon" />
            <div className={styles.errorTitle}>No Task Assignment Available</div>
            <div className={styles.errorSubTitle}></div>
          </center>
        </div>
      </>
    );
  };

  const Tips = ({ val, unread }) => {
    return (
      <>
        <span
          style={{
            borderRadius: "30px",
            color: unread ? "#ffffff" : "#838383",
            border: unread ? "1px solid #E95048" : "1px solid #E8E8E8",
            backgroundColor: unread ? "#E95048" : "#F7F7F7",
            fontFamily: "Gotham-Book",
            fontSize: "12px",
            marginLeft: "8px",
            padding: "4px 8px 2px 8px",
          }}
        >
          {val || 0}
        </span>
      </>
    );
  };

  const handleSetTabbing = (e) => {
    setTabbing(e);
  };

  const TaskAssignmentList = ({ type }) => {
    return (
      <>
        <Grid container columns={4} sx={{ marginTop: "32px" }}>
          <Grid item xs={3} onClick={() => setOpenModal(false)}>
            <div
              style={{
                width: "fitContent",
                maxWidth: "540px",
                borderRadius: "4px",
                backgroundColor: "#F9FAFB",
                padding: "4px 2px",
                fontSize: "12px",
                display: "flex",
              }}
            >
              <span
                className={`${styles.tabbing} ${tabbing === 1 ? styles.tabbingActive : ""}`}
                onClick={() => handleSetTabbing(1)}
              >
                DDML List <Tips val={data?.filter((e) => e.formType === "ddml")?.length} unread={false} />
              </span>
              <span
                className={`${styles.tabbing} ${tabbing === 2 ? styles.tabbingActive : ""}`}
                onClick={() => handleSetTabbing(2)}
              >
                Repetitive Defect List <Tips val={data?.filter((e) => e.formType === "rdd")?.length} unread={false} />
              </span>
              <span
                className={`${styles.tabbing} ${tabbing === 3 ? styles.tabbingActive : ""}`}
                onClick={() => handleSetTabbing(3)}
              >
                Additional Task List <Tips val={data?.filter((e) => e.formType === "adhoc")?.length} unread={false} />
              </span>
            </div>
          </Grid>
          <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-end" }} onClick={() => setOpenModal(false)}>
            <Button
              title="Submit Shift Report"
              icon={IconCheckCircleWhite}
              iconWhite={IconCheckCircleWhite}
              iconDisabled={IconCheckCircleDisabled}
              // variant={data_.length > 0 ? "fill" : "disabled"}
              variant={
                submitShiftReportPermsission.some((perm) => perm.canUpdate === true)
                  ? filteredData?.length > 0
                    ? "fill"
                    : "disabled"
                  : "disabled"
              }
              onClick={() => setOpenDialogSubmitShiftReport(true)}
            />
          </Grid>
          {filteredData !== undefined && filteredData !== "" && filteredData?.length > 0 && (
            <>
              <Grid item xs={4} sx={{ paddingTop: "32px", paddingLeft: "16px" }}>
                <SearchBar
                  open={openModal}
                  setOpen={setOpenModal}
                  data={data_}
                  isSearch={isSearch}
                  setIsSearch={setIsSearch}
                  isFilters={isFilters}
                  setIsFilters={setIsFilters}
                  openTitle={openTitle}
                  setOpenTitle={setOpenTitle}
                  sub={true}
                />
                <ActiveFilters isFilters={isFilters} setIsFilters={setIsFilters} initialFilters={InitialFilters} />
              </Grid>
              <Grid item xs={4} sx={{ display: "flex", paddingTop: "32px" }}>
                {/* <Button
                  title="Delete"
                  icon={IconDustbin}
                  iconWhite={IconDustbinWhite}
                  iconDisabled={IconDustbinDisabled}
                  variant={selectToMove.length > 0 ? "outlined" : "disabled"}
                  onClick={() => setOpenDialogSubmitShiftReport(true)}
                />
                <Button
                  title="Push to 145"
                  icon={IconBriefcase}
                  iconWhite={IconBriefcaseWhite}
                  iconDisabled={IconBriefcaseDisabled}
                  variant={selectToMove.length > 0 ? "outlined" : "disabled"}
                  onClick={() => setOpenDialogMoveToAssignment(true)}
                /> */}
              </Grid>
              <Grid item xs={4} sx={{ paddingTop: "16px" }} onClick={() => setOpenModal(false)}>
                {subLoading ? <Overlay /> : <TableList type={type} />}
              </Grid>
            </>
          )}
          {(data?.length === 0 || filteredData?.length === 0) && !subLoading && !tableLoading && (
            <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }} onClick={() => setOpenModal(false)}>
              <ErrorAirBallon />
            </Grid>
          )}
          {tableLoading && (
            <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }} onClick={() => setOpenModal(false)}>
              <AirplaneLoader />
            </Grid>
          )}
        </Grid>
      </>
    );
  };

  function calcBalanceCD(calendarDaysStartDate, calendarDaysInterval) {
    let startDate = calendarDaysStartDate;
    let interval = calendarDaysInterval || 0;
    let expiry = addingDate(startDate, interval);
    let today = setToday();
    let balance = calculateBalanceDay(today?.split("T")[0], expiry?.split("T")[0]);
    return balance;
  }

  const IconUnreadIndicator = ({ data }) => {
    let unreadLogs = data.unreadLogs;
    let count = unreadLogs?.materialTool.length + unreadLogs?.planningTech.length + unreadLogs?.rectification.length;

    return (
      count > 0 && (
        <div style={{ position: "absolute", right: "24px" }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
            <circle cx="5" cy="5" r="5" fill="#0ADDDD" />
          </svg>
        </div>
      )
    );
  };

  const TableList = () => {
    return filteredData?.length > 0 && !tableLoading ? (
      <>
        <div
          style={{
            width: "100%",
            borderRadius: "12px",
            overflow: "visible",
            border: "1px solid #E8E8E8",
          }}
        >
          <Grid container columns={13}>
            <Grid item xs={0.5} className={styles.tableHeader} sx={{ borderRadius: "11px 0 0 0" }}></Grid>
            <Grid item xs={0.8} className={styles.tableHeader}>
              Station
            </Grid>
            <Grid item xs={0.9} className={styles.tableHeader}>
              A/C Reg.
            </Grid>
            {tabbing === 1 && (
              <>
                <Grid item xs={2.5} className={`${styles.tableHeader} ${styles.gridJustifyStart}`}>
                  Defect Description
                </Grid>
                <Grid item xs={1} className={styles.tableHeader}>
                  DDML No.
                </Grid>
                <Grid item xs={1} className={styles.tableHeader}>
                  Ref.
                </Grid>
                <Grid item xs={1} className={styles.tableHeader}>
                  Raised Date
                </Grid>
                <Grid item xs={1.3} className={styles.tableHeader}>
                  Assignee
                </Grid>
                <Grid item xs={1} className={styles.tableHeader}>
                  Expiry/Balance
                </Grid>
              </>
            )}
            {tabbing === 3 && (
              <>
                <Grid item xs={2.5} className={`${styles.tableHeader} ${styles.gridJustifyStart}`}>
                  Task Description
                </Grid>
                <Grid item xs={1} className={styles.tableHeader}>
                  Status/DDML
                </Grid>
                <Grid item xs={1} className={styles.tableHeader}>
                  Raised Date
                </Grid>
                <Grid item xs={1} className={styles.tableHeader}>
                  ETS
                </Grid>
                <Grid item xs={1.3} className={styles.tableHeader}>
                  Assignee
                </Grid>
                <Grid item xs={2} className={`${styles.tableHeader} ${styles.gridJustifyStart}`}>
                  Remarks
                </Grid>
              </>
            )}
            <Grid
              item
              xs={tabbing === 1 ? 3 : tabbing === 3 ? 2 : 3}
              className={`${styles.tableHeader} ${styles.gridJustifyStart}`}
              sx={{ borderRadius: "0 11px 0 0" }}
            >
              Rectification Status
            </Grid>
            {filteredData &&
              filteredData.slice(sliceStart, sliceEnd).map((e, index) => {
                let calendarDaysInterval = e.ddml.calendarDaysInterval || 0;
                let calendarDaysStartDate = e.ddml.calendarDaysStartDate || e.ddml.raisedDate;
                let expiry = addingDate(calendarDaysStartDate, calendarDaysInterval);
                let date1 = e.ddml.formType === "AMD" ? e.ddml.calendarDaysStartDate : formattedDate?.split("T")[0];
                let date2 = expiry;
                let balance = calculateBalanceDay(date1, date2);
                let showInTable = e.ddml.showInTable;
                let currentFlightHour = dataFHFC.filter((item) => item.AC_REG === e.ddml.aircraftReg)[0]?.flight_hour;
                let currentFlightCycle = dataFHFC.filter((item) => item.AC_REG === e.ddml.aircraftReg)[0]?.flight_cycle;
                if (e.ddml.calendarDays) balance = calcBalanceCD(calendarDaysStartDate, calendarDaysInterval);
                if (showInTable === "fh" && e.ddml.formType === "STR/FC/FH")
                  balance = calcBalanceFH(e.ddml.flightHoursInterval, e.ddml.flightHoursRaisedAt, currentFlightHour);
                else if (showInTable === "fc" && e.ddml.formType === "STR/FC/FH")
                  balance = calcBalanceFC(e.ddml.flightCycleInterval, e.ddml.flightCycleRaisedAt, currentFlightCycle);
                else if (showInTable === "" && e.ddml.formType === "STR/FC/FH")
                  balance = calcBalanceCD(calendarDaysStartDate, calendarDaysInterval);

                let fcfhIndicator = " Days";
                if (showInTable === "fh") fcfhIndicator = " (FH)";
                else if (showInTable === "fc") fcfhIndicator = " (FC)";

                if (
                  !e.ddml.flightCycle &&
                  !e.ddml.flightHours &&
                  !e.ddml.calendarDays &&
                  e.ddml.formType === "STR/FC/FH"
                )
                  balance = "-";
                if (e.ddml.formType === "AMD")
                  balance = calculateCalendarBalance(e.ddml.calendarDaysStartDate, e.ddml.calendarDaysInterval);
                if (e.ddml.formType === "Fuel Sampling" || e.ddml.formType === "Hydraulic Sampling")
                  balance = calculateCalendarBalance(e.ddml.calendarDaysStartDate, e.ddml.calendarDaysInterval);
                let expiryDate_ = formatDateDasr(e.ddml.expiryDate);
                if (e.ddml.formType !== "STR/FC/FH") {
                  if (e.ddml.formType === "MEL CAT" && e.ddml.flightDays)
                    // expiryDate_ = calculateCalendarBalanceDate(e.ddml.calendarDaysStartDate, e.ddml.calendarDaysInterval);
                    expiryDate_ = calculateCalendarExpiryDate(e.ddml.flightDaysStartDate, e.ddml.flightDaysInterval);
                  else if (e.ddml.formType !== "MEL CAT" && e.ddml.calendarDays)
                    expiryDate_ = calculateCalendarExpiryDate(
                      e.ddml.calendarDaysStartDate,
                      e.ddml.calendarDaysInterval
                    );
                }
                // Calculate the date difference
                var currentDate = new Date();
                var timeDifferenceMs = new Date(e.ddml.expiryDate) - currentDate;
                var remainingDays = Math.ceil(timeDifferenceMs / (1000 * 60 * 60 * 24));
                return (
                  <>
                    <Grid item xs={13}>
                      <Grid
                        container
                        columns={13}
                        className={`${styles.lineItems} ${activeIndex === index ? styles.selectedIndex : ""}`}
                        sx={{ zIndex: 1 }}
                      >
                        <Grid item xs={0.5} className={`${styles.tableBody} ${styles.divSpaceBetween}`}>
                          <LightTooltip title={"Priority"} followCursor placement="bottom-start">
                            <img
                              src={e.priority ? IconFlagActive : IconFlag}
                              alt="icon-flag"
                              height={18}
                              style={{ marginRight: "4px", marginTop: "1px" }}
                            />
                          </LightTooltip>
                          <LightTooltip
                            title={e.ddml.limitations ? "Limitation triggered" : ""}
                            followCursor
                            placement="bottom-start"
                          >
                            {e.ddml.limitations && (
                              <img src={IconAirplaneLimitation} alt={"warning-circle"} height={20} />
                            )}
                          </LightTooltip>
                          <LightTooltip
                            title={e.ddml.reInspection ? "Reinspection" : ""}
                            followCursor
                            placement="bottom-start"
                          >
                            {e.ddml.reInspection && (
                              <img
                                src={IconReInspection}
                                alt={"icon-reinspection"}
                                height={20}
                                style={{ marginLeft: "4px" }}
                              />
                            )}
                          </LightTooltip>
                        </Grid>
                        <Grid
                          item
                          xs={0.8}
                          className={`${styles.tableBody} ${styles.toUpperCase}`}
                          onClick={() => handleClick(e, index)}
                        >
                          {e.ddml.location}
                        </Grid>
                        <Grid
                          item
                          xs={0.9}
                          className={`${styles.tableBody} ${styles.toUpperCase}`}
                          onClick={() => handleClick(e, index)}
                        >
                          {e.ddml.aircraftReg}
                        </Grid>
                        {tabbing === 1 && (
                          <>
                            <Grid
                              item
                              xs={2.5}
                              className={`${styles.tableBody} ${styles.toUpperCase}`}
                              sx={{ display: "flex", justifyContent: "flex-start" }}
                              onClick={() => handleClick(e, index)}
                            >
                              <LightTooltip title={e.ddml.defectDesc} followCursor placement="bottom-start">
                                <div className={styles.ellipsis}>{e.ddml.defectDesc}</div>
                              </LightTooltip>
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              className={`${styles.tableBody} ${styles.toUpperCase}`}
                              onClick={() => handleClick(e, index)}
                            >
                              {e.ddml.ddmlNo}
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              className={`${styles.tableBody} ${styles.toUpperCase}`}
                              onClick={() => handleClick(e, index)}
                            >
                              {e.ddml.melRef && (
                                <center>
                                  {e.ddml.melRef}{" "}
                                  {e.ddml.formType === "MEL CAT" && (
                                    <>
                                      <br /> {"(" + e.ddml.melCatType + ")"}
                                    </>
                                  )}
                                </center>
                              )}
                            </Grid>
                            <Grid item xs={1} className={`${styles.tableBody}`} onClick={() => handleClick(e, index)}>
                              <center>{formatDateDasr(e.ddml.raisedDate)}</center>
                            </Grid>
                            <Grid
                              item
                              xs={1.3}
                              className={`${styles.tableBody} ${styles.toUpperCase} ${styles.flexStart}`}
                            >
                              <Assignee
                                data={e}
                                list={isAssignee}
                                isOpen={isOpenAssignee}
                                setIsOpen={setIsOpenAssignee}
                                isActiveLine={isActiveLine}
                                setIsActiveLine={setIsActiveLine}
                                setTriggerApi={setTriggerApi}
                                isLoading={isLoadingAssignee}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              className={`${styles.tableBody}`}
                              sx={{
                                color:
                                  e.formType === "MEL CAT"
                                    ? remainingDays <= 3
                                      ? "#E95048"
                                      : ""
                                    : e.formType === "STR/FC/FH"
                                    ? balance <= 20
                                      ? "#E95048"
                                      : ""
                                    : remainingDays <= 3
                                    ? "#E95048"
                                    : "",
                                display: "flex",
                                justifyContent: "center",
                              }}
                              onClick={() => handleClick(e, index)}
                            >
                              <center>
                                {e.formType === "STR/FC/FH"
                                  ? balance + fcfhIndicator
                                  : e.ddml.formType === "MEL CAT"
                                  ? formatDateDasr(e.ddml.expiryDate)
                                  : expiryDate_}
                              </center>
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              className={`${styles.tableBody} ${styles.toUpperCase}`}
                              sx={{ display: "flex", justifyContent: "flex-start", position: "relative" }}
                              onClick={() => handleClick(e, index)}
                            >
                              <LightTooltip
                                title={getRectificationStatus(e.ddml.statusLog) || e.ddml.actionBy145}
                                followCursor
                                placement="bottom-start"
                              >
                                <div className={classes.gridItem}>
                                  {getRectificationStatus(e.ddml.statusLog) || e.ddml.actionBy145}
                                </div>
                              </LightTooltip>
                              <IconUnreadIndicator data={e.ddml} />
                            </Grid>
                          </>
                        )}
                        {tabbing === 3 && (
                          <>
                            <Grid
                              item
                              xs={2.5}
                              className={`${styles.tableBody} ${styles.toUpperCase}`}
                              sx={{ display: "flex", justifyContent: "flex-start" }}
                              onClick={() => handleClick(e, index)}
                            >
                              <LightTooltip title={e.ddml.defectDesc} followCursor placement="bottom-start">
                                <div className={styles.ellipsis}>{e.ddml.defectDesc}</div>
                              </LightTooltip>
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              className={`${styles.tableBody} ${styles.toUpperCase}`}
                              onClick={() => handleClick(e, index)}
                            >
                              {e.ddml.status}
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              className={`${styles.tableBody} ${styles.toUpperCase}`}
                              onClick={() => handleClick(e, index)}
                            >
                              {formatDateDasr(e.ddml.raisedDate)}
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              className={`${styles.tableBody} ${styles.toUpperCase}`}
                              onClick={() => handleClick(e, index)}
                            >
                              {formatDateDasr(e.ddml.ets)}
                            </Grid>
                            <Grid
                              item
                              xs={1.3}
                              className={`${styles.tableBody} ${styles.toUpperCase} ${styles.flexStart}`}
                            >
                              <Assignee
                                data={e}
                                list={isAssignee}
                                isOpen={isOpenAssignee}
                                setIsOpen={setIsOpenAssignee}
                                isActiveLine={isActiveLine}
                                setIsActiveLine={setIsActiveLine}
                                setTriggerApi={setTriggerApi}
                                isLoading={isLoadingAssignee}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              className={`${styles.tableBody} ${styles.toUpperCase}`}
                              sx={{ justifyContent: "flex-start" }}
                              onClick={() => handleClick(e, index)}
                            >
                              <LightTooltip title={e.ddml.remarks} followCursor placement="bottom-start">
                                <div className={`${styles.truncateText}`}>{e.ddml.remarks}</div>
                              </LightTooltip>
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              className={`${styles.tableBody} ${styles.toUpperCase}`}
                              sx={{ justifyContent: "flex-start", position: "relative" }}
                              onClick={() => handleClick(e, index)}
                            >
                              <LightTooltip
                                title={getRectificationStatus(e.ddml.statusLog) || e.ddml.rectificationStatus}
                                followCursor
                                placement="bottom-start"
                              >
                                <div className={classes.gridItem}>
                                  {getRectificationStatus(e.ddml.statusLog) || e.ddml.rectificationStatus}
                                </div>
                              </LightTooltip>
                              <IconUnreadIndicator data={e.ddml} />
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </>
                );
              })}
            <Grid item xs={13} className={`${styles.tableBodyWhite}`} sx={{ borderRadius: "0 0 11px 11px" }}>
              <MUIPagination
                page={parseInt(page)}
                setPage={setPage}
                setPerPage={setPerPage}
                totalPage={parseInt(totalPages)}
                classes={{ ul: classes.ul }}
                totalRows={parseInt(totalRows)}
                sliceStart={sliceStart}
                initialPerPage={perPage}
              />
            </Grid>
          </Grid>
        </div>
      </>
    ) : tableLoading ? (
      <AirplaneLoader />
    ) : (
      <ErrorAirBallon />
    );
  };

  return !isLoading && !subLoading ? (
    <>
      <div id="main" className={`${styles.container}`}>
        <>
          <Grid container columns={8.2} className={styles.gridContainer}>
            <Grid item xs={4.2} className={styles.gridTitle} onClick={() => setOpenModal(false)}>
              Task Assignment List
            </Grid>
            <Grid item xs={4} className={styles.gridJustifyEnd} sx={{ fontSize: "14px", paddingBottom: "16px" }} onClick={() => setOpenModal(false)}>
              <DateToggle date={date} setDate={setDate} today={today} setToday={setToday} />
            </Grid>
            <Grid item xs={8.2} sx={{ paddingTop: "10px" }}>
              <TabContext value={value}>
                <TabList onChange={handleChange} aria-label="lab API tabs example" onClick={() => setOpenModal(false)}>
                  <AntTab label="Day Shift" value="1" />
                  <AntTab label="Night Shift" value="2" />
                </TabList>
                <div
                  style={{
                    width: "100%",
                    height: "2px",
                    backgroundColor: "#d1d1d1",
                    marginTop: "-2px",
                  }}
                ></div>
                <div
                  style={{
                    marginLeft: "-20px",
                    marginRight: "-20px",
                    marginTop: "-24px",
                  }}
                >
                  <TabPanel value="1">{<TaskAssignmentList type={"DDML"} />}</TabPanel>
                  <TabPanel value="2">{<TaskAssignmentList type={"Adhoc"} />}</TabPanel>
                </div>
              </TabContext>
            </Grid>
          </Grid>
        </>
      </div>
      <ElevadeDrawer
        open={open}
        setOpen={setOpen}
        data={data}
        Component={
          tabbing === 1
            ? DDML
            : tabbing === 2
            ? ""
            : tabbing === 3
            ? edit
              ? AdditionalTaskForm
              : AdditionalTaskSummary
            : ""
        }
        aoc={aoc}
        type={type}
        index={index}
        dataForm={tabbing === 3 ? dataForm.ddml : dataForm}
        edit={edit}
        setEdit={setEdit}
        openNotification={openNotification}
        setOpenNotification={setOpenNotification}
        newForm={newForm}
        setNewForm={setNewForm}
        triggerApi={triggerApi}
        setTriggerApi={setTriggerApi}
        setSelectToMove={setSelectToMove}
        setOpenDialogMoveToAssignment={setOpenDialogMoveToAssignment}
        date={date}
        initialData={tabbing === 3 ? dataForm : dataForm.ddml}
        dataList={data}
      />
      <DialogPush145
        aoc={aoc}
        open={openDialogMoveToAssignment}
        setOpen={setOpenDialogMoveToAssignment}
        data={selectToMove}
        setTriggerApi={setTriggerApi}
        setData={setSelectToMove}
        setCheckAll={setCheckAll}
        tab={value}
      />
      <DialogSubmitShiftReport
        open={openDialogSubmitShiftReport}
        setOpen={setOpenDialogSubmitShiftReport}
        data={selectToMove}
        setTriggerApi={setTriggerApi}
        setData={setSelectToMove}
      />
    </>
  ) : (
    <AirplaneLoader />
  );
};

export default TaskAssignment;
