import React from 'react';
import { forwardRef } from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";

const AdeTextField = styled(TextField)((props) => {
  let readOnlyStyle = {};

  if (props.disabled) {
    readOnlyStyle = {
      backgroundColor: "#C3C3C3",
      pointerEvents: "none",
    };
  }

  if (props.readOnly) {
    readOnlyStyle = {
      backgroundColor: "#C3C3C3",
      pointerEvents: "none",
    };
  }
  return {
    "&& legend": {
      width: `${props.label?.length + 1 || 0}ch`,
    },
    ...readOnlyStyle,
  };
});

const ErrorMessage = styled(Typography)((props) => ({ color: "#FC3F3F" }));

export const AdeInput = forwardRef(({ error, ...props }, ref) => {
  return (
    <>
      <AdeTextField ref={ref} {...props} error={!!error} />
      {!!error && <ErrorMessage>{typeof error === "string" ? error : "This field is required"}</ErrorMessage>}
    </>
  );
});

export default AdeTextField;
