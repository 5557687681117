import { createSlice } from "@reduxjs/toolkit";

const filtersInitialState = {
  filters: {},
  keyword: "",
  details: {},
};

const filters = createSlice({
  name: "filters",
  initialState: filtersInitialState,
  reducers: {
    setFilters(state, { payload }) {
      state.filters = payload;
    },
    setKeyword(state, { payload }) {
      state.keyword = payload;
    },
    setDetails(state, { payload }) {
      state.details = payload;
    },
  },
});

export const { setFilters, setKeyword, setDetails } = filters.actions;
export default filters.reducer;
