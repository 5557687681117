import React from "react";
import { Grid, useMediaQuery } from "@mui/material";
import MagnifyingGlass from "common/images/icons/elevade/MagnifyingGlass.svg";
import styles from "./SearchBar.module.css";
import FilterBox from "./FilterBox";
import { useState } from "react";

const SearchBar = ({ data, isSearch, setIsSearch, isFilters, setIsFilters, operatorList, operator, setOperator }) => {
  const isWeb = useMediaQuery("(min-width:900px)");
  const [openOperator, setOpenOperator] = useState(false);
  const [openAtaChapter, setOpenAtaChapter] = useState(false);
  const [openCondition, setOpenCondition] = useState(false);
  const [openFaultPriority, setOpenFaultPriority] = useState(false);

  const style = {
    input: {
      border: "0px",
      borderRight: isWeb ? "0px solid #c3c3c3" : "0px solid #c3c3c3",
      paddingLeft: "32px",
      width: "100%",
      maxWidth: "310px",
      marginTop: "10px",
      backgroundImage: `url(${MagnifyingGlass})`,
      backgroundRepeat: "no-repeat",
      backgroundPositionY: "5px",
      backgroundSize: "16px",
      backgroundColor: "transparent",
      fontFamily: "Gotham-Book",
      "&&:focus": {
        outline: "none",
      },
    },
  };

  const handleKeyDown = (event) => {
    setTimeout(() => {
      setIsSearch(event.target.value);
    }, 2000);
  };

  return (
    <>
      <Grid container columns={2}>
        <Grid item xs={0.5} sx={{ paddingLeft: "1px" }}>
          <input
            type="text"
            placeholder="Search"
            style={style.input}
            className={`${styles.input}`}
            onChange={handleKeyDown}
            defaultValue={isSearch}
            // value={search}
          />
        </Grid>
        <Grid item xs={1.5} className={styles.gridJustifyEnd}>
          <FilterBox
            open={openOperator}
            setOpen={setOpenOperator}
            data={operatorList.map((fleet) => ({ fleet: fleet }))}
            title={"Operator"}
            isFilters={operator}
            setIsFilters={setOperator}
            isLast={false}
          />
          <FilterBox
            open={openAtaChapter}
            setOpen={setOpenAtaChapter}
            data={data}
            title={"ATA Chapter"}
            isFilters={isFilters}
            setIsFilters={setIsFilters}
            isLast={false}
          />
          <FilterBox
            open={openCondition}
            setOpen={setOpenCondition}
            data={data}
            title={"Condition"}
            isFilters={isFilters}
            setIsFilters={setIsFilters}
            isLast={false}
          />
          <FilterBox
            open={openFaultPriority}
            setOpen={setOpenFaultPriority}
            data={data}
            title={"Fault Priority"}
            isFilters={isFilters}
            setIsFilters={setIsFilters}
            isLast={false}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SearchBar;
