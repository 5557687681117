import { HttpPost, HttpPostEntity } from "common/library/apiAxiosWrapper";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import _ from "lodash";

export const getToken = createAsyncThunk("auth/getToken", async ({ token, history }) => {
  try {
    const result = await HttpPostEntity({
      url: `/api/v1/token/verify`,
      body: { token: token },
      // baseUrl: 'https://auth-gs-mcpx75ifcq-as.a.run.app'
      baseUrl: process.env.REACT_APP_ENTITY_API_URL,
    });
    if (result.data.profile.avatar) {
      localStorage.setItem("avatar", result.data.profile.avatar);
      localStorage.setItem("displayName", result?.data?.profile?.name);
    }
    const avatar = localStorage.getItem("avatar");
    if (_.has(result.data, "tokens") && !_.isEmpty(result?.data?.tokens)) {
      const responseData = {
        userName: result?.data?.profile?.email,
        token: result?.data?.tokens?.token,
        avatar: result?.data?.profile?.avatar || avatar,
        displayName: result?.data?.profile?.name,
      };

      return responseData;
    } else {
      history.push("/fleet");
    }
  } catch (err) {
    throw new Error(
      err?.message ? err?.message : err?.response?.data?.message ? err?.response?.data?.message : "Login Failed"
    );
  }
});

export const logOut = createAsyncThunk("auth/logout", async () => {
  try {
    return true;
  } catch (err) {
    throw new Error("Logout failed");
  }
});
