
import React from 'react';

export function LocationIcon() {
	return (
		<svg
			width="32"
			height="33"
			viewBox="0 0 32 33"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect y="0.5" width="32" height="32" rx="16" fill="#F7F7F7" />
			<path
				d="M20.75 11.25H11.25C10.9739 11.25 10.75 11.4739 10.75 11.75V21.25C10.75 21.5261 10.9739 21.75 11.25 21.75H20.75C21.0261 21.75 21.25 21.5261 21.25 21.25V11.75C21.25 11.4739 21.0261 11.25 20.75 11.25Z"
				stroke="#394458"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M16 11.25V21.75"
				stroke="#394458"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M21.25 16.5H10.75"
				stroke="#394458"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
export function TagIdIcon() {
	return (
		<svg
			width="32"
			height="33"
			viewBox="0 0 32 33"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect y="0.5" width="32" height="32" rx="16" fill="#F7F7F7" />
			<path
				d="M15.6687 10.1187L10.6249 11.1249L9.61869 16.1687C9.60292 16.2492 9.60722 16.3324 9.6312 16.4109C9.65518 16.4894 9.69811 16.5607 9.75619 16.6187L16.2812 23.1437C16.3272 23.1907 16.3821 23.2281 16.4427 23.2537C16.5034 23.2792 16.5685 23.2923 16.6343 23.2923C16.7001 23.2923 16.7652 23.2792 16.8259 23.2537C16.8865 23.2281 16.9415 23.1907 16.9874 23.1437L22.6437 17.4874C22.6907 17.4415 22.7281 17.3865 22.7537 17.3259C22.7792 17.2652 22.7923 17.2001 22.7923 17.1343C22.7923 17.0685 22.7792 17.0034 22.7537 16.9427C22.7281 16.8821 22.6907 16.8272 22.6437 16.7812L16.1187 10.2562C16.0607 10.1981 15.9894 10.1552 15.9109 10.1312C15.8324 10.1072 15.7492 10.1029 15.6687 10.1187Z"
				stroke="#394458"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<mask id="path-3-inside-1_12924_131831" fill="white">
				<path d="M13.25 14.5C13.6642 14.5 14 14.1642 14 13.75C14 13.3358 13.6642 13 13.25 13C12.8358 13 12.5 13.3358 12.5 13.75C12.5 14.1642 12.8358 14.5 13.25 14.5Z" />
			</mask>
			<path
				d="M13.25 14.5C13.6642 14.5 14 14.1642 14 13.75C14 13.3358 13.6642 13 13.25 13C12.8358 13 12.5 13.3358 12.5 13.75C12.5 14.1642 12.8358 14.5 13.25 14.5Z"
				fill="#394458"
			/>
			<path
				d="M12.5 13.75C12.5 13.3358 12.8358 13 13.25 13V16C14.4926 16 15.5 14.9926 15.5 13.75H12.5ZM13.25 13C13.6642 13 14 13.3358 14 13.75H11C11 14.9926 12.0074 16 13.25 16V13ZM14 13.75C14 14.1642 13.6642 14.5 13.25 14.5V11.5C12.0074 11.5 11 12.5074 11 13.75H14ZM13.25 14.5C12.8358 14.5 12.5 14.1642 12.5 13.75H15.5C15.5 12.5074 14.4926 11.5 13.25 11.5V14.5Z"
				fill="#394458"
				mask="url(#path-3-inside-1_12924_131831)"
			/>
		</svg>
	);
}
export function SerialNoIcon() {
	return (
		<svg
			width="32"
			height="33"
			viewBox="0 0 32 33"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect y="0.5" width="32" height="32" rx="16" fill="#F7F7F7" />
			<path
				d="M10.7251 14.5H22.0001"
				stroke="#394458"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M19 11L17 22"
				stroke="#394458"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M15 11L13 22"
				stroke="#394458"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M10 18.5H21.275"
				stroke="#394458"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
export function ExpiryDateIcon() {
	return (
		<svg
			width="32"
			height="33"
			viewBox="0 0 32 33"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect y="0.5" width="32" height="32" rx="16" fill="#F7F7F7" />
			<path
				d="M21 11H11C10.7239 11 10.5 11.2239 10.5 11.5V21.5C10.5 21.7761 10.7239 22 11 22H21C21.2761 22 21.5 21.7761 21.5 21.5V11.5C21.5 11.2239 21.2761 11 21 11Z"
				stroke="#394458"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M19 10V12"
				stroke="#394458"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M13 10V12"
				stroke="#394458"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M10.5 14H21.5"
				stroke="#394458"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M17.5 16.5L14.5 19.5"
				stroke="#394458"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M17.5 19.5L14.5 16.5"
				stroke="#394458"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
export function MfgDateIcon() {
	return (
		<svg
			width="32"
			height="33"
			viewBox="0 0 32 33"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect y="0.5" width="32" height="32" rx="16" fill="#F7F7F7" />
			<path
				d="M21 11H11C10.7239 11 10.5 11.2239 10.5 11.5V21.5C10.5 21.7761 10.7239 22 11 22H21C21.2761 22 21.5 21.7761 21.5 21.5V11.5C21.5 11.2239 21.2761 11 21 11Z"
				stroke="#394458"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M19 10V12"
				stroke="#394458"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M13 10V12"
				stroke="#394458"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M10.5 14H21.5"
				stroke="#394458"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M17.75 18H14.25"
				stroke="#394458"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M16 16.25V19.75"
				stroke="#394458"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
