
import React from 'react';
import { useRef, useState } from "react";
export const seatColors = {
	red: "#E95048",
	blue: "#AA33FF",
	grey: "#3A32E0",
	orange: "#F5BE59",
	white: "#FFFFFF",
	// black: '#000000',
	black: "#D1D1D1",
	dot: "#FFFFFF",
};
export const strokes = {
	red: seatColors.red,
	blue: seatColors.blue,
	grey: seatColors.grey,
	orange: seatColors.orange,
	black: "#B1B1B1",
	dot: seatColors.grey,
	white: "#B1B1B1",
};
export default function SeatA1(props) {
	const {
		x = 0,
		y = 0,
		// stroke = "#B1B1B1",
		fill = "white",
		setPopup,
		setClosePopup,
		onClick,
		noLocation,
	} = props;
	const [isBig, setIsBig] = useState(false);
	const [isHovering, setIsHovering] = useState(false);
	const color = seatColors[fill];
	const stroke = strokes[fill];
	const selfRef = useRef(null);
	return (
		<g
			onClick={() => {
				if (onClick) {
					onClick();
				}
			}}
			ref={selfRef}
			transform={` translate(${x} ${y}) `}
			onMouseEnter={() => {
				setIsHovering(true);
				if (setPopup) {
					setPopup({
						x: x ?? undefined,
						y: y ?? undefined,
						data: "A1",
					});
				}
				// console.log(selfRef.current?.getBoundingClientRect())
			}}
			onMouseLeave={() => {
				setIsHovering(false);
				setClosePopup();
			}}
			style={{
				zIndex: isBig ? 100 : 0,
				cursor: onClick && !noLocation ? "pointer" : undefined,
			}}
		>
			{/* {isBig ? <SeatA1Big {...props} /> : ( */}
			<path
				filter={isHovering ? "url(#shadow)" : undefined}
				style={{
					boxShadow: "0px 3px 6px rgba(59, 68, 86, 0.2)",
				}}
				d="M292 412.5H300C301.933 412.5 303.5 414.067 303.5 416V428C303.5 429.933 301.933 431.5 300 431.5H292C287.858 431.5 284.5 428.142 284.5 424V420C284.5 415.858 287.858 412.5 292 412.5Z"
				stroke={stroke}
				fill={color}
			/>
			{fill === "dot" && (
				<circle cx="302.5" cy="415" r="4.5" fill="#E95048" />
			)}
			{/* )} */}
			<filter id="shadow" color-interpolation-filters="sRGB">
				<feDropShadow
					dx="-1.5"
					dy="1.5"
					stdDeviation="0.8"
					flood-opacity="0.1"
				/>
			</filter>
		</g>
	);
}
export function SeatA1Big({ x = 0, y = 0, fill = "white" }) {
	// in a group x~28 y~26
	// saparated by aisle x~ y~
	const color = seatColors[fill];
	const stroke = strokes[fill];
	const scale = 1.2;
	const offset = 10;
	const centerX = (1 - scale) * (284.5 + offset);
	const centerY = (1 - scale) * (412.5 + offset);
	return (
		<g transform={`translate(${centerX} ${centerY}) scale(${scale})`}>
			<path
				d="M292 412.5H300C301.933 412.5 303.5 414.067 303.5 416V428C303.5 429.933 301.933 431.5 300 431.5H292C287.858 431.5 284.5 428.142 284.5 424V420C284.5 415.858 287.858 412.5 292 412.5Z"
				stroke={stroke}
				fill={color}
			/>
		</g>
	);
}
