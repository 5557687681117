import * as React from "react";
import PropTypes from "prop-types";
import { Autocomplete, Stack, TextField, Popper } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledAutoComplete = styled(Autocomplete)(({ error }) => ({
  [`.MuiAutocomplete-root`]: {
    minHeight: "50px",
  },
  [`.MuiOutlinedInput-root`]: {
    backgroundColor: "#FFFFFF",
    font: `Gotham-Book, sans-serif`,
    maxHeight: "7rem",
    overflow: "hidden",
    minHeight: "50px",
    border: error && "1px solid red",
  },
  [`.MuiAutocomplete-tag`]: {
    borderRadius: "4px",
    border: "1px solid #707070",

    backgroundColor: "#F2F7FF",
  },
}));

const CustomPopper = ({ style, ...props }) => (
  <Popper
    {...props}
    style={{ ...style, height: 0 }} // width is passed in 'style' prop
  />
);

const AdeInputSelectGroup = ({
  label,
  options,
  limitTags,
  groupBy,
  ...props
}) => {
  return (
    <Stack>
      <p style={{ marginBottom: 5 }}>{label}</p>
      <StyledAutoComplete
        {...props}
        id="checkboxes-tags-demo"
        isOptionEqualToValue={(option, value) => {
          return option.value === value.value;
        }}
        options={options}
        limitTags={limitTags}
        PopperComponent={CustomPopper}
        getOptionLabel={(option) => option.label}
        groupBy={(option) => option[groupBy]}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            placeholder="Select / Search..."
            notched={undefined}
            label=""
            InputLabelProps={{ shrink: false }}
            variant="outlined"
            // style={{overflow: 'hidden', maxHeight: '3.3rem'}}
          />
        )}
      />
    </Stack>
  );
};

AdeInputSelectGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.any,
      value: PropTypes.any,
    })
  ),
  label: PropTypes.string,
  limitTags: PropTypes.number,
  groupBy: PropTypes.string,
};

export default AdeInputSelectGroup;
