import { env_var_REACT_APP_API_URL } from "../../envVar/vars";
import useGetGQL from "../useGetGQL";
import useMutateGQL from "../useMutateGQL";


export function useGetAllScannersStatus() {
	const data = useGetGQL({
		gqlquery: `
		query totalScannerStatus{
			totalScannerStatus {
				  totalActive
				  totalInactive
			}
		  }
		`,
		querySign: "totalScannerStatus",
		url: env_var_REACT_APP_API_URL ?? "",
		defaultIsLoading: true,
		isEdges: false,
	});

	return data;
}

export function useGetAllScannersTotalCount() {
	const data = useGetGQL({
		gqlquery: `
        query allitem {
          allScanners {
            totalCount
          }
        }
        `,
		url: env_var_REACT_APP_API_URL ?? "",
		querySign: "allScanners",
		isEdges: false,
	});

	return data;
}

export function useGetAllScanners({ offset }) {
	const allInventoryLists = useGetGQL({
		defaultIsLoading: true,
		gqlquery: `
        query allitem {
            allScanners 
			${offset ? `(offset:${offset})` : ""}
			{
            totalCount
            edges {
              node {
                id
                createdAt
                createdBy
                updatedAt
                deviceId
                name
                model {
                  id
                  code
                  name
                  manufacturer{
                    id
                    name
                    code
                  }
                }
                status {
                  id
                  code
                  name
                }
              }
            }
          }
        }
        `,
		querySign: "allScanners",
		url: env_var_REACT_APP_API_URL ?? "",
	});

	return allInventoryLists;
}


export function useCreateScanner ({addDeviceStates}) {

	const data = useMutateGQL({
        gqlquery: `
        mutation addEQ{
            createScanner(input:{
              deviceId:"${addDeviceStates.deviceId.value}"
              ${addDeviceStates?.deviceName?.value ? `name:"${addDeviceStates?.deviceName?.value}"` : ''}
              ${addDeviceStates?.status?.value ? `status:"${addDeviceStates?.status?.value}"` : ''}
              ${addDeviceStates?.modelId?.value ? `model:"${addDeviceStates?.modelId?.value}"` : ''}
              ${addDeviceStates?.aocCode ? `aocCode:"${addDeviceStates?.aocCode}"` : ''}
            }){
              scanner{
                id
                deviceId
                status {
                  code
                  name
                }
              }
            }
          }
        `,
        url: env_var_REACT_APP_API_URL ?? '',
    });

	return data
}

export function useUpdateScanner ({id,updateDeviceStates}) {

	const data = useMutateGQL({
		gqlquery: `
		mutation updateScanner{
			updateScanner(input:{
			  id:"${id}",
			  deviceId:"${updateDeviceStates?.deviceId?.value}",
			  ${updateDeviceStates?.deviceName?.value ? `name:"${updateDeviceStates?.deviceName?.value}",` : ''}
			  ${updateDeviceStates?.status?.value ? `status:"${updateDeviceStates?.status?.value}",` : ''}
			  ${updateDeviceStates?.modelId?.value ? `model:"${updateDeviceStates?.modelId?.value}",` : ''}
			}){
			  scanner{
				id
				deviceId
				status {
				  code
				  name
				}
			  }
			}
		  }
		`,
		url: env_var_REACT_APP_API_URL ?? '',
	});

	return data
}

export function useDeleteScanner ({id}) {
	
	const data = useMutateGQL({
		gqlquery: `
		mutation deleteScanner {
			deleteScanner(input:{id:"${id}"}){
			  success
			}
		  }
		`,
		url: env_var_REACT_APP_API_URL ?? '',
	});

	return data
}