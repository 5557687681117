import React from "react";
import { appRoutes, paths } from "./routes";
import { Switch, Route, useLocation } from "react-router-dom";

export default function CabinSwitchRoutedPages() {
	return (
		<div>
			<Switch>
				{appRoutes.map(
					({ routeProps, requiredPermissions = [] }, index) => {
						return (
							<Route
								exact
								path={`/fleet/cabin-monitoring${routeProps?.path}`}
							>
								{routeProps?.element}
							</Route>
						);
					}
				)}
			</Switch>
		</div>
	);
}
