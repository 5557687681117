import React from 'react';
import ReactEcharts from "echarts-for-react";
import { useState, useEffect } from "react";

export const PieChart = ({ data = [{ total: 0, data: {} }], hubData }) => {
  const [show, setShow] = useState(false);
  var options;
  var colorPalette = ["#394458", "#088FD1", "#A3E0FF", "#7C94BF", "#E95048", "#c4c4c4", "#827CF2", "#08D1AF"];
  const sum = data.total || 0;

  options = {
    tooltip: {
      extraCssText:
        "color: #ffffff; background: rgba(51, 51, 51, 0.90); border-radius: 8px; border: 0px solid red; font-size: 10px",
      formatter: function (params) {
        return params.data.name !== "HUB"
          ? `<div style={{color: "#ffffff"}}>${params.data.name} : <strong>${params.data.value}</strong></div>`
          : // TODO: To refactor this
            `<div>${params.data.name} : <strong>${params.data.value}</strong></div>${getHubList("BKI")} ${getHubList(
              "KCH"
            )} ${getHubList("JHB")} ${getHubList("PEN")}`;
      },
    },
    legend: {
      show: false,
    },
    series: [
      {
        name: "Aircraft Status",
        type: "pie",
        radius: ["65%", "90%"],
        color: colorPalette,
        avoidLabelOverlap: false,
        label: {
          show: true,
          color: "#333333",
          fontSize: "42",
          position: "center",
          formatter: `{a|${sum}\n}{b|Total Aircraft}`,
          rich: {
            a: {
              fontSize: 52,
              color: "#333333",
              fontFamily: "Gotham-Bold",
            },
            b: {
              fontSize: 18,
              color: "#838383",
              fontFamily: "Gotham-Book",
            },
          },
        },
        labelLine: {
          show: false,
        },
        data: data.data,
      },
    ],
  };

  function getHubList(hub) {
    let data_ = hubData.filter((e) => e.name === hub);
    return data_[0].value > 0 ? `${data_[0].name} : <strong>${data_[0].value}</strong><br />` : "";
  }

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 0);
  }, [data]);

  return !show ? (
    <>
      <div
        style={{
          fontFamily: "Gotham-Book",
          fontSize: "12px",
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          paddingTop: "36%",
        }}
      >
        Loading...
      </div>
    </>
  ) : (
    show && (
      <ReactEcharts
        option={options}
        style={{
          fontFamily: "Gotham-Book",
          height: "90%",
          paddingTop: "5%",
        }}
      ></ReactEcharts>
    )
  );
};
