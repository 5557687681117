
import React from 'react';

export default function FilterDefs() {
	return (
		<defs>
			<filter
				id="filter0_d_4_45"
				x="515"
				y="409"
				width="404"
				height="286.957"
				filterUnits="userSpaceOnUse"
				color-interpolation-filters="sRGB"
			>
				<feFlood flood-opacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="3" />
				<feGaussianBlur stdDeviation="17.5" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0.223529 0 0 0 0 0.266667 0 0 0 0 0.345098 0 0 0 0.08 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_4_45"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_4_45"
					result="shape"
				/>
			</filter>
			<filter
				id="filter1_d_4_45"
				x="515"
				y="0.570312"
				width="404"
				height="286.957"
				filterUnits="userSpaceOnUse"
				color-interpolation-filters="sRGB"
			>
				<feFlood flood-opacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="3" />
				<feGaussianBlur stdDeviation="17.5" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0.223529 0 0 0 0 0.266667 0 0 0 0 0.345098 0 0 0 0.08 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_4_45"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_4_45"
					result="shape"
				/>
			</filter>
			<filter
				id="filter2_d_4_45"
				x="0"
				y="218"
				width="1327"
				height="261"
				filterUnits="userSpaceOnUse"
				color-interpolation-filters="sRGB"
			>
				<feFlood flood-opacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="3" />
				<feGaussianBlur stdDeviation="17.5" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0.223529 0 0 0 0 0.266667 0 0 0 0 0.345098 0 0 0 0.08 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_4_45"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_4_45"
					result="shape"
				/>
			</filter>
			<filter
				id="filter3_b_4_45"
				x="764"
				y="175"
				width="100"
				height="44"
				filterUnits="userSpaceOnUse"
				color-interpolation-filters="sRGB"
			>
				<feFlood flood-opacity="0" result="BackgroundImageFix" />
				<feGaussianBlur in="BackgroundImageFix" stdDeviation="5" />
				<feComposite
					in2="SourceAlpha"
					operator="in"
					result="effect1_backgroundBlur_4_45"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_backgroundBlur_4_45"
					result="shape"
				/>
			</filter>
			<linearGradient
				id="paint0_linear_4_45"
				x1="558.564"
				y1="441"
				x2="891.993"
				y2="648.251"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#FEFEFE" />
				<stop offset="1" stop-color="#E4E5E8" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_4_45"
				x1="558.564"
				y1="249.527"
				x2="891.993"
				y2="42.276"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#FEFEFE" />
				<stop offset="0.276042" stop-color="#F7F7F8" />
				<stop offset="1" stop-color="#E4E5E8" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_4_45"
				x1="35"
				y1="346"
				x2="1292"
				y2="346"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="white" />
				<stop offset="1" stop-color="white" />
			</linearGradient>
			<linearGradient
				id="paint3_linear_4_45"
				x1="686.5"
				y1="2"
				x2="686.5"
				y2="224"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#FDFDFD" />
				<stop
					offset="0.3125"
					stop-color="#FDFDFD"
					stop-opacity="0.928751"
				/>
				<stop offset="1" stop-color="#FDFDFD" stop-opacity="0" />
			</linearGradient>
			<linearGradient
				id="paint4_linear_4_45"
				x1="2039.5"
				y1="689"
				x2="2039.5"
				y2="911"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#FDFDFD" />
				<stop
					offset="0.3125"
					stop-color="#FDFDFD"
					stop-opacity="0.928751"
				/>
				<stop offset="1" stop-color="#FDFDFD" stop-opacity="0" />
			</linearGradient>
			<clipPath id="clip0_4_45">
				<rect
					width="24"
					height="24"
					fill="white"
					transform="translate(790 165)"
				/>
			</clipPath>
			<clipPath id="clip1_4_45">
				<rect
					width="18"
					height="19.001"
					fill="white"
					transform="translate(837 165)"
				/>
			</clipPath>
		</defs>
	);
}
