import { env_var_REACT_APP_API_URL } from "../../envVar/vars";
import useGetGQL from "../useGetGQL";
import { useAppSelector } from "../../app/hooks";

export function useGetAllLocationInventoryDetails({
	fleetId,
	locationTypeId,
	searchSeat,
}) {
	const locationInventoryDetails = useGetGQL({
		gqlquery: `
		query location_seat{
			allLocationInventoryDetail (fleetId:"${fleetId}", inventoryLocation:"${locationTypeId}"
			${searchSeat ? `, icontainsSeatNo:"${searchSeat}"` : ""}
			) {
			  edges{
				node{
				  id
				  inventoryLocation{
					id
					name
				  }
				  occupied
				  seatNo
				}
			  }
			}
		  }
		`,
		querySign: "allLocationInventoryDetail",
		url: env_var_REACT_APP_API_URL ?? "",
		defaultIsLoading: true,
	});

	return locationInventoryDetails;
}

export function useGetAllInvLocations() {
	const allInventoryLocations = useGetGQL({
		gqlquery: `
		query location{
			allInventoryLocations{
			  edges{
				node{
				  id
				  name
				}
			  }
			}
		  }
		`,
		querySign: "allInventoryLocations",
		url: env_var_REACT_APP_API_URL ?? "",
		defaultIsLoading: true,
	});

	return allInventoryLocations;
}

export function useGetAllLocationInventoryDetailsFiltered({
	fleetId,
	inventoryLocId,
	searchSeatNo,
}) {
	// { data: locNoData, isLoading: locNoIsLoading, }
	const data = useGetGQL({
		gqlquery: `
		query location_seat{
			allLocationInventoryDetail (fleetId:"${
				fleetId
			}", inventoryLocation:"${inventoryLocId}"
			${searchSeatNo ? `, icontainsSeatNo:"${searchSeatNo}"` : ""}
			) {
			  edges{
				node{
				  id
				  inventoryLocation{
					id
					name
				  }
				  occupied
				  seatNo
				}
			  }
			}
		  }
		`,
		querySign: "allLocationInventoryDetail",
		url: env_var_REACT_APP_API_URL ?? "",
		defaultIsLoading: true,
	});
	return data;
}