import { useState } from "react";
import useInputState from "../../../components/inputs/useInputState";
export default function useUpdateEquipmentStates() {
    const fleetId = useInputState({ isMandatory: true }); // fleet ID
    const inputLocNo = useInputState({ isMandatory: true, }); // locationDetailID
    const tagId = useInputState({ isMandatory: true, });
    const serialNo = useInputState({ isMandatory: true, });
    const eqType = useInputState({ isMandatory: true, });
    const status = useInputState({ isMandatory: true, }); // statusID
    const [mfgDate, setMfgDate] = useState();
    const [expDate, setExpDate] = useState();
    const [inputModel, setInputModel] = useState(); // only to show regNo (not used in mutation)
    const [inputLocType, setInputLocType] = useState(); // only to show seatNo (not used in mutation)
    return {
        inputModel, setInputModel,
        inputLocType, setInputLocType,
        inputLocNo,
        fleetId,
        tagId,
        serialNo,
        eqType,
        status,
        mfgDate, setMfgDate,
        expDate, setExpDate,
    };
}
