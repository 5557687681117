import React, { useState, useEffect } from "react";

const IdleChecker = ({ idleTime, onIdle }) => {
  const [lastActivity, setLastActivity] = useState(Date.now());

  const handleActivity = () => {
    setLastActivity(Date.now());
  };

  useEffect(() => {
    // Attach event listeners for user activity
    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);

    // Set up a timer to check for idle time
    const timer = setInterval(() => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - lastActivity;

      if (elapsedTime >= idleTime) {
        // Trigger the onIdle function when idle time is reached
        onIdle();
      }
    }, 1000); // Check every second

    // Cleanup: remove event listeners and clear the timer on component unmount
    return () => {
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
      clearInterval(timer);
    };
  }, [idleTime, lastActivity, onIdle]);

  return "";
};

export default IdleChecker;
