import styles from "../EmailAlertManagement.module.css";
import {
  Box,
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableHead,
  tableCellClasses,
  TableCell,
  TableBody,
  Checkbox,
  Button,
  Modal,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState, useEffect } from "react";
import { cloneDeep, isEmpty } from "lodash";
import SortIconAscGray from "common/images/icons/elevade/SortIconAscGray.svg";
import SortIconDescGray from "common/images/icons/elevade/SortIconDescGray.svg";
import SortIconAscDescGray from "common/images/icons/elevade/SortIconAscDescGray.svg";
import IconDelete from "common/images/icons/elevade/Icon-Delete-Trash.svg";
import { makeAPIRequestRun, showNotificationElevade } from "common/helpers";
import IconErrorAirBalloon from "common/images/icons/elevade/Icon-ErrorAirBalloon.svg";

const buttonstyle = {
  textTransform: "none",
  fontFamily: "Gotham-Medium",
  fontSize: "14px",
  fontWeight: "350",
  lineHeight: "20px",
  letterSpacing: "0em",
  textAlign: "center",
  padding: "11px 30px 11px 30px",
  borderRadius: "8px",
  gap: "8px",
  height: "42px",
  border: "1px solid #C3C3C3",
  color: "#000",
  "&:hover": {
    borderColor: "#000",
    // backgroundColor: "#088FD1",
    // color: "#fff",
  },
};

const ButtonStyled = styled(Button)(({ theme }) => ({
  ...buttonstyle,
  backgroundColor: "#fff",
}));

const StyledTableCell = styled(TableCell)({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F2F7FF",
    color: "#333",
    fontSize: 16,
    fontFamily: "Gotham-Medium",
    position: "start",
    "&:hover": {
      cursor: "pointer",
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
});

const dialogstyle = {
  width: "540px",
  // height: "226px",
  top: "50%",
  left: "50%",
  padding: "24px",
  borderRadius: "12px",
  gap: "16px",
  zIndex: 100,
  position: "absolute",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#fff",
};

const DeleteDialog = ({
  openDelete,
  setOpenDelete,
  data,
  allData,
  setAllData,
  aoc,
  setCallApi,
  callApi,
  setLoading,
  alertData,
}) => {
  const namelist = data.map((user, index) => (index + 1 < data.length ? user.fullname + ", " : user.fullname));

  const handleClose = () => setOpenDelete(false);
  const handleDelete = async (data) => {
    const emails = data.map((user) => user.email);
    const body = {
      emails: emails,
      aoc: aoc,
    };

    const deleteFromRecipients = async () => {
      let arr = [...allData];
      await makeAPIRequestRun("post", "ecamalerts/recipients/delete", body);
      arr = arr.filter((item) => !emails.includes(item.email));
      setAllData(arr);
      setCallApi(!callApi);
      setLoading(false);
      showNotificationElevade("success", "Delete success!", ["Recipient deleted!"]);
    };

    try {
      setLoading(true);
      const deleteRecipients = async () => {
        try {
          let deleteFromDB = {};
          const filteredObjects = alertData.filter((item) =>
            emails.some((email) => {
              if (typeof item.recipients === "string") {
                return item.recipients.includes(email);
              }
              return false; // Handle cases where item.recipients is not a string
            })
          );
          filteredObjects.forEach((item) => {
            item.recipients = item.recipients
              .split(",")
              .filter((email) => !emails.includes(email.trim()))
              .join(",");
          });

          if (filteredObjects.length > 0 && filteredObjects) {
            deleteFromDB = makeAPIRequestRun("post", "ecamalerts/emailalerts/deleterecipients", filteredObjects);
            deleteFromDB.then(async (response) => {
              if (response.message === "Success") await deleteFromRecipients();
            });
          } else await deleteFromRecipients();
        } catch (error) {
          setLoading(false);
          showNotificationElevade("danger", "Delete failed!", ["Something went wrong, unable to delete from alerts!"]);
        }
      };
      await deleteRecipients();
    } catch (error) {
      setLoading(false);
      showNotificationElevade("danger", "Delete failed!", ["Something went wrong, unable to delete recipient!"]);
    }
  };
  return (
    <>
      <Modal open={openDelete} setOpenDelete={setOpenDelete} onClose={handleClose}>
        <Box sx={dialogstyle}>
          <div className={styles.textMedium}>Delete Recipients</div>
          <Divider orientation="horizontal" sx={{ mt: 1.5 }} />
          <div style={{ marginTop: "15px" }}>
            <span>
              Are you sure you want to delete <span className={styles.textSmallBold}>{namelist}</span> as recipients?
            </span>
          </div>
          <div className={`${styles.row} ${styles.alignEnd}`}>
            <ButtonStyled variant="outlined" color="primary" sx={{ mt: 4, mr: 1 }} onClick={() => handleClose()}>
              Cancel
            </ButtonStyled>
            <ButtonStyled
              variant="outlined"
              color="primary"
              sx={{
                mt: 4,
                backgroundColor: "#088FD1",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#088FD1",
                  color: "#fff",
                },
              }}
              onClick={() => {
                handleDelete(data);
                handleClose();
              }}
            >
              Proceed
            </ButtonStyled>
          </div>
        </Box>
      </Modal>
    </>
  );
};

const headCells = [
  {
    id: "checkbox",
    label: "Send Alert",
  },
  {
    id: "fullname",
    label: "Name",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "phoneNo",
    label: "Phone Number",
  },
  {
    id: "department",
    label: "Department",
  },
];

const AlertRecipients = ({
  loading,
  limit,
  data,
  setSort,
  setData,
  sliceStart,
  sliceEnd,
  operator,
  setCallApi,
  callApi,
  setLoading,
  alertData,
}) => {
  /* ------- states -------- */

  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  // const [mouseX, setMouseX] = useState();
  const [openDelete, setOpenDelete] = useState();
  const [disableButton, setDisableButton] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const [recipients, setRecipients] = useState(cloneDeep(data));

  /* ------- use effect ------- */
  useEffect(() => {
    setRecipients(() =>
      data.map((user) => {
        return { isChecked: false, ...user };
      })
    );
  }, [data]);

  useEffect(() => {
    window.scroll(0, 0);
  }, [sliceStart, sliceEnd]);
  /* ------- functions -------- */

  function handleSort(property) {
    if (property !== "addedBy") {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
      // setMouseX(document.getElementById("main-table").scrollLeft);
      setSort(`${property} ${isAsc ? "desc" : "asc"}`);
    }
  }

  function handleCheckbox(event, user) {
    const checklist =
      user === "all"
        ? recipients.map((item) => {
            if (event.target.checked) return { ...item, isChecked: true };
            else return { ...item, isChecked: false };
          })
        : recipients.map((item) => {
            if (item.fullname === user.fullname) {
              return {
                ...item,
                isChecked: !item.isChecked,
              };
            }
            return item;
          });

    setRecipients(checklist);
    if (user === "all") {
      setCheckAll(event.target.checked);
    }
    if (checklist.find((user) => user.isChecked)) {
      setDisableButton(false);
      setCheckAll(true);
    } else {
      setDisableButton(true);
      setCheckAll(false);
    }
  }

  /* ------- components -------- */

  const ErrorAirBallon = () => {
    return (
      <>
        <div className={styles.errorAirBallon}>
          <center>
            <img src={IconErrorAirBalloon} alt="error-air-ballon" />
            <div className={styles.errorTitle}>No data available</div>
            <div className={styles.errorSubTitle}>Add new recipients to be notified when this alert is triggered.</div>
          </center>
        </div>
      </>
    );
  };

  const TableHeads = () => {
    return (
      <TableHead
        sx={{
          "& .MuiTableCell-head": {
            backgroundColor: "#F2F7FF",
            width: "100%",
          },
        }}
      >
        <TableRow sx={{ backgroundColor: "#F2F7FF" }}>
          {headCells.map((head, index) => {
            return head.id === "checkbox" ? (
              <StyledTableCell key={index} style={{ width: "5%" }}>
                <div
                  className={`${styles.row} ${styles.tableHead}`}
                  onClick={(e) => {
                    // handleSort(head.id);
                    return;
                  }}
                >
                  <Checkbox
                    onChange={(e) => handleCheckbox(e, "all")}
                    checked={checkAll}
                    sx={{ "&.Mui-checked": { color: "#088FD1" } }}
                  />
                </div>
              </StyledTableCell>
            ) : (
              <StyledTableCell key={index} style={{ width: "auto" }}>
                <div
                  className={`${styles.row} ${styles.tableHead}`}
                  onClick={(e) => {
                    handleSort(head.id);
                  }}
                >
                  <span>{head.label}</span>
                  <div className={styles.sortIcon}>
                    {order === "asc" && orderBy === head.id ? (
                      <img src={SortIconAscGray} alt="" />
                    ) : order === "desc" && orderBy === head.id ? (
                      <img src={SortIconDescGray} alt="" />
                    ) : (
                      <img src={SortIconAscDescGray} alt="" />
                    )}
                  </div>
                </div>
              </StyledTableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  };

  const DisplayData = () => {
    return recipients.length > 0 ? (
      recipients.slice(sliceStart, sliceEnd).map((item, index) => {
        return (
          <React.Fragment key={index}>
            {isEmpty(item) ? (
              ""
            ) : (
              <TableBody>
                <TableRow hover>
                  {headCells.map((head, index) => {
                    return (
                      <StyledTableCell scope="row" key={index}>
                        {head.id === "checkbox" ? (
                          <Checkbox
                            onChange={(e) => handleCheckbox(e, item)}
                            checked={item.isChecked}
                            sx={{ "&.Mui-checked": { color: "#088FD1" } }}
                          />
                        ) : item[head.id] === "null" ||
                          item[head.id] === "nan" ||
                          isEmpty(item[head.id]) ||
                          item[head.id] === "None" ||
                          item[head.id] === "undefined" ? (
                          ""
                        ) : (
                          item[head.id]
                        )}
                      </StyledTableCell>
                    );
                  })}
                </TableRow>
              </TableBody>
            )}
          </React.Fragment>
        );
      })
    ) : (
      <>
        <ErrorAirBallon />
      </>
    );
  };
  return (
    <>
      <div className={styles.row}>
        <ButtonStyled
          variant="outlined"
          color="primary"
          sx={{ mt: 4, backgroundColor: disableButton ? "#E8E8E8" : "none" }}
          startIcon={
            <img
              src={IconDelete}
              alt="delete"
              style={{
                filter: disableButton ? "grayscale(1)" : "grayscale(0)",
              }}
            /> //TODO to change color no need another img
          }
          onClick={() => setOpenDelete(true)}
          disabled={disableButton}
        >
          Delete
        </ButtonStyled>
      </div>
      <TableContainer component={Paper} sx={{ maxHeight: undefined, marginTop: "10px" }} id="main-table">
        {!loading && isEmpty(data) && (
          <>
            <TableHeads />
            <Box
              sx={{
                width: "100%",
                marginLeft: "17px",
                paddingTop: "10px",
                paddingRight: "34px",
              }}
            >
              <ErrorAirBallon />
            </Box>
          </>
        )}
        {!isEmpty(data) && !loading && (
          <Table stickyHeader aria-label="sticky table">
            <TableHeads />
            <DisplayData />
          </Table>
        )}
      </TableContainer>
      <DeleteDialog
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        data={recipients.filter((item) => item.isChecked)}
        allData={data}
        setAllData={setData}
        aoc={operator}
        setCallApi={setCallApi}
        callApi={callApi}
        setLoading={setLoading}
        alertData={alertData}
      />
    </>
  );
};

export default AlertRecipients;
