import React from 'react';
import styles from "../Dasr.module.css";
import IconErrorAirBalloon from "common/images/icons/elevade/Icon-ErrorAirBalloon.svg";

const ErrorAirBallonNoData = () => {
  return (
    <>
      <div className={styles.errorAirBallon}>
        <center>
          <img src={IconErrorAirBalloon} alt="error-air-ballon" />
          <div className={styles.errorTitle}>No data found</div>
          <div className={styles.errorSubTitle}>
            Create or publish something
            <br />
            it shows up here
          </div>
        </center>
      </div>
    </>
  );
};

export default ErrorAirBallonNoData;