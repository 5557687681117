
import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import PageTitle from "../titles/PageTitle";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
export default function PageTemplate({
	title,
	subTitle,
	children,
	backAction,
	topRightActions,
}) {
	return (
		<Box>
			<Box
				sx={{
					display: "flex",
					width: "100%",
					flexDirection: "row",
					alignItems: "centered",
					marginBottom: subTitle ? "10px" : "1em",
					marginTop: "-15px",
				}}
			>
				{backAction && (
					<IconButton
						sx={{
							padding: "0px",
							marginRight: "8px",
							width: "30px",
						}}
						onClick={() => {
							backAction();
						}}
					>
						<KeyboardArrowLeftIcon />
					</IconButton>
				)}
				{title && <PageTitle title={title} />}
				<Box
					sx={{
						display: "flex",
						justifyContent: "right",
						alignItems: "center",
					}}
				>
					{topRightActions?.map(({ component }) => {
						return component;
					})}
				</Box>
			</Box>
			<Typography
				sx={{
					marginBottom: "1em",
					marginLeft: backAction ? "2.4em" : "0px",
				}}
			>
				{subTitle}
			</Typography>
			<Box>{children}</Box>
		</Box>
	);
}
