import React from 'react';
import { Grid } from "@mui/material";
import styles from "./AdeTabs.module.css";
import AddIcon from "common/images/icons/elevade/Add-Icon-No-Circle.svg";

export const AdeElevadeTabs = ({ tab, setTab, tabNames, ...props }) => {
  return (
    <>
      <Grid
        columns={12}
        sx={{
          pt: "20px",
          pl: "15px",
          right: 0,
          top: 0,
        }}
      >
        {tabNames?.map((item, index) => {
          return (
            <>
              <Grid
                className={`${styles.msgHeaderTab} ${
                  tab === index && !item.disabled ? styles.active : styles.inactive
                }`}
                onClick={() => {
                  !item.disabled ? setTab(index) : setTab(tab);
                }}
                sx={{ cursor: item.disabled ? "default" : "pointer" }}
                key={index}
              >
                {item?.name === "New Tab" ? (
                  <Grid style={{ color: "#C3C3C3" }} key={index + 1}>
                    <img src={AddIcon} alt="Add" /> New Tab
                  </Grid>
                ) : (
                  item.name
                )}
              </Grid>
            </>
          );
        })}
      </Grid>
    </>
  );
};
