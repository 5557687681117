
import React from 'react';
import { getPopupColorBasedOnStatusCode } from "../../Popup";
import AirplaneBox from "../AirplaneBox";
import { getBoxDataFromInFleetLocations } from "../AirplaneBox/utils";
export default function BoxBehindLastRowDEFAdv({ invs, onClick, setClosePopup, setPopup }) {
    const data = getBoxDataFromInFleetLocations(invs);
    const boxX = 750;
    const boxY = 120;
    const popupXOffset = -217;
    const popupYOffset = -410;
    return (<g className="display-box">
            <Pointer />
            <AirplaneBox title="Behind Last row 31DEF" x={boxX} y={boxY} data={data} onClick={onClick} minWidth={160} setClosePopup={setClosePopup} setPopup={(rowX, rowY, data) => {
            setPopup({
                status: data?.inventory?.status,
                data: data,
                x: rowX + boxX + popupXOffset,
                y: rowY + boxY + popupYOffset,
                // row: 'row',
                col: data.locationId.seatNo,
                color: getPopupColorBasedOnStatusCode(data?.inventory?.status?.code),
            });
        }}/>
        </g>);
}
function Pointer() {
    return (<g>
            <path className="path" d="M1174.5 294.5V243.5C1174.5 239.634 1171.37 236.5 1167.5 236.5H812C808.134 236.5 805 233.366 805 229.5V138.5" stroke="#333333" stroke-width="0.5" stroke-linecap="round" stroke-dasharray="2 2"/>
            <circle className="pointer-1st" cx="1175" cy="295" r="3" fill="#74C4E8"/>
            <circle className="pointer-2nd" opacity="0.4" cx="1175" cy="295" r="6" fill="#74C4E8"/>
        </g>);
}
