import { useMediaQuery } from "@mui/material";
import React, { useEffect, useRef } from "react";
import Apu_fluid_icon from "common/images/icons/elevade/Apu_fluid_icon.svg";
import styles from "../../predictiveMaintenance/PredictiveMaintenance.module.css";
// import TableauReport from 'tableau-viz-react';
const { tableau } = window;

const ApuDashboard = () => {
  const isMobile = useMediaQuery("(max-width:744px)");
  const isTablet = useMediaQuery("(min-width:744px)");
  var dashboardDevice = isMobile ? "phone" : isTablet ? "tablet" : "desktop";
  const url =
    "https://10az.online.tableau.com/t/airasia/views/APUReliabilityDashboard-ADE/APUReliabilityMonitoring-ADE";
  const ref = useRef(null);
  const options = {
    device: dashboardDevice,
    hideShareOptions: true,
    hideTabs: false,
    hideToolbar: true,
  };

  function initViz() {
    new tableau.Viz(ref.current, url, options);
  }

  useEffect(() => {
    initViz();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="pt-4">
      <img src={Apu_fluid_icon} alt="Apu_fluid_icon" />
      <h2 className={`mb-4  px-4 d-inline ${styles.predictiveMainTitle}`}>
        ATA 49: APU Reliability Monitoring
      </h2>

      <div ref={ref}></div>
    </div>
  );
};

export default ApuDashboard;
