
import React from 'react';
import { Grid, Box, Typography } from "@mui/material";
export default function ItemTemplate({ icon, title, value }) {
	const itemTitleStyle = {
		textAlign: "left",
		fontSize: "12px",
		color: "#838383",
		paddingLeft: "1em",
	};
	const itemValueStyle = {
		textAlign: "left",
		fontSize: "14px",
		color: "#333333",
		paddingLeft: "1em",
	};
	if (icon === null) {
		return (
			<Grid container>
				<Grid item xs={12}>
					<Typography sx={{ ...itemTitleStyle }}>{title}</Typography>
					<Typography sx={{ ...itemValueStyle }}>{value}</Typography>
				</Grid>
			</Grid>
		);
	}
	return (
		<Grid container>
			<Grid item xs={4}>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						width: "100%",
						height: "100%",
					}}
				>
					{icon}
				</Box>
			</Grid>
			<Grid item xs={8}>
				<Typography sx={{ ...itemTitleStyle }}>{title}</Typography>
				<Typography sx={{ ...itemValueStyle }}>{value}</Typography>
			</Grid>
		</Grid>
	);
}
