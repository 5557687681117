import { env_var_REACT_APP_API_URL } from "../../envVar/vars";
import useGetGQL from "../useGetGQL";

export function useGetAllItemStatuses () {

	const data = useGetGQL({
		gqlquery: `
		query statuses {
			allItemStatuses {
			  edges {
				node {
				  id
				  code
				  name
				}
			  }
			}
		  }
		`,
		querySign: 'allItemStatuses',
		url: env_var_REACT_APP_API_URL ?? "",
		defaultIsLoading: true,
	});

	return data
}
