export function isUppercase(word) {
    return /^[A-Z]+$/.test(word);
}
export function cleanUpConsecutiveWhiteSpaces(sentence) {
    const cleanedStr = sentence.replace(/\s+/g, ' ');
    return cleanedStr;
}
export function isSentenceCase(str) {
    // clean up consecutive white spaces
    const words = cleanUpConsecutiveWhiteSpaces(str).split(' ');
    for (let i = 0; i < words.length; i++) {
        const word = words[i];
        // Check if the first letter of the word is uppercase
        if (word[0] !== word[0].toUpperCase()) {
            return false;
        }
        // Check if the remaining characters are lowercase
        if (word.slice(1) !== word.slice(1).toLowerCase()) {
            return false;
        }
    }
    return true;
}
export const escWord = '#esc-me#';
export function camelCaseToSentenceCase(text) {
    if (text.includes(escWord)) {
        return text.replace(escWord, '');
    }
    if (text === '' || text === undefined) {
        return text;
    }
    if (isUppercase(text)) {
        return text;
    }
    if (isSentenceCase(text)) {
        return text;
    }
    const result = text.replace(/([A-Z])/g, ' $1');
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return cleanUpConsecutiveWhiteSpaces(finalResult);
}
/**
 *
 * @param str
 * @returns
 * @example "hello-world" becomes "helloWorld"
 * "foo_bar_baz" becomes "fooBarBaz"
 * "lorem ipsum dolor" becomes "loremIpsumDolor"
 * "camelCase" remains unchanged as "camelCase"
 */
export function toCamelCase(str, isIgnoreUpperCases) {
    if (str === undefined) {
        return str;
    }
    if (isIgnoreUpperCases && isUppercase(str)) {
        return str;
    }
    const words = str.split(/[-_\s]+/);
    const camelCaseWords = words.map((word, index) => {
        if (index === 0) {
            return word.toLowerCase();
        }
        else {
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        }
    });
    return camelCaseWords.join('');
}
export function displayNumberString(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export function capitalizeEachWordInSentence(str) {
    const mySentence = str;
    const words = mySentence.split(" ");
    for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0]?.toUpperCase() + words[i].substr(1);
    }
    return words.join(" ");
}
/**
 * @description check if all characters in a string are numbers
 * @param str
 * @returns
 */
export function isNumeric(str) {
    return !isNaN(parseFloat(str)) && isFinite(str);
}
