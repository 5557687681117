import styles from "../EmailAlertManagement.module.css";
import CloseIcon from "common/images/icons/elevade/CloseIconSmallBold.svg";
import PopoutIcon from "common/images/icons/elevade/PopoutIconBold.svg";
import { Autocomplete, Button, CircularProgress, Divider, FormControl, Grid, styled, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { isEmpty } from "lodash";
import { makeAPIRequestRun, showNotificationElevade } from "common/helpers";
import _ from "lodash";

const ButtonStyled = styled(Button)(({ theme }) => ({
  textTransform: "none",
  fontFamily: "Gotham-Medium",
  fontSize: "14px",
  fontWeight: "350",
  lineHeight: "20px",
  letterSpacing: "0em",
  textAlign: "center",
  padding: "11px 24px 11px 24px",
  borderRadius: "8px",
  gap: "8px",
  backgroundColor: "#088FD1",
}));

const formcontrolstyles = {
  display: "block",
  position: "static",
};

const NewRecipients = ({
  setOpenDrawer,
  openDrawer,
  graphQLEmail,
  setGraphQLEmail,
  setRecipientData,
  recipientData,
  setCallApi,
  callApi,
  operator,
}) => {
  const [loading, setLoading] = useState(false);
  const emailRegex = /^(.+)@(airasia\.com|rba\.com\.bn|ade\.aero)$/i;

  /* ---------- useform -------------- */

  const defaultValues = {
    fullname: "",
    email: "",
    phoneNo: "",
    department: "",
    operator: operator,
  };

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors },
    clearErrors,
  } = useForm({
    reValidateMode: "onBlur",
    defaultValues: defaultValues,
    mode: "all",
  });

  const watchemail = watch("email");
  const watchfullname = watch("fullname");
  const watchdept = watch("department");

  useEffect(() => {
    if (watchemail) {
      if (!isEmpty(graphQLEmail)) {
        const getUserDetails = graphQLEmail.find((item) => item?.node?.email === watchemail);
        setValue(
          "fullname",
          isEmpty(getUserDetails?.node?.fullName)
            ? ""
            : getUserDetails?.node?.fullName === "None"
            ? watchemail
            : getUserDetails.node.fullName
        );
        setValue("department", getUserDetails?.node?.userprofile?.departmentSection?.department?.name);
        setValue("phoneNo", getUserDetails?.node?.userprofile?.phoneNo);
        clearErrors();
      } else {
        setValue("email", watchemail);
      }
    } else reset();
    //eslint-disable-next-line
  }, [watchemail]);

  useEffect(() => {
    if (watchfullname) {
      if (!isEmpty(graphQLEmail) && watchemail !== watchfullname) {
        const getUserDetails = graphQLEmail.find((item) => item?.node?.fullName === watchfullname);
        setValue("email", getUserDetails?.node?.email);
        setValue("department", getUserDetails?.node?.userprofile?.departmentSection?.department?.name);
        setValue("phoneNo", getUserDetails?.node?.userprofile?.phoneNo);
        clearErrors();
      } else {
        setValue("fullname", watchfullname);
      }
    } else reset();
    //eslint-disable-next-line
  }, [watchfullname]);

  useEffect(() => {
    if (watchdept && (!watchemail || !watchfullname)) {
      getUserByEmailDebounce(watchemail, watchfullname, watchdept);
    }
    if (!watchdept && watchemail) {
      const getUserDetails = graphQLEmail.find((item) => item?.node?.email === watchemail);
      setValue("department", getUserDetails?.node?.userprofile?.departmentSection?.department?.name);
    } else if (watchdept) {
      setValue("department", watchdept);
    }
    //eslint-disable-next-line
  }, [watchdept]);

  const onSubmit = async (evt) => {
    setLoading(true);
    const submit = async () => {
      const body = {
        email: evt.email,
        fullname: evt.fullname,
        department: evt.department ?? "null",
        phoneNo: evt.phoneNo ?? "null",
        operator: operator,
        status: "ACTIVE",
      };
      const isExist = recipientData.find((item) => item.email === evt.email);
      if (isEmpty(errors) && !isExist)
        try {
          const arr = [...recipientData];
          const add = makeAPIRequestRun("post", `ecamalerts/recipients/add`, body);
          add.then((response) => {
            if (response.message === "Success") {
              showNotificationElevade("success", "Success!", ["New recipient added!"]);
              arr.push(body);
              setRecipientData(arr);
              setCallApi(!callApi);
              setLoading(false);
              setOpenDrawer(false);
            } else if (response.message === "Duplicate Recipient") {
              showNotificationElevade("danger", "Failed!", [response.message]);
              setLoading(false);
              setOpenDrawer(false);
            }
          });
        } catch (error) {
          showNotificationElevade("danger", "Failed!", [error]);
          setLoading(false);
          setOpenDrawer(false);
        }
      else if (isExist) {
        setLoading(false);
        setOpenDrawer(false);
        showNotificationElevade("danger", "Duplicate recipient!", ["Recipient already exists"]);
      }
    };
    await submit();
  };

  /* ---------- use state --------------- */

  const [nameList, setNameList] = useState([]);
  const [emailList, setEmailList] = useState([]);
  const [phoneNumberList, setPhoneNumberList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);

  /* ---------- use effect -------------- */

  useEffect(() => {
    if (openDrawer && graphQLEmail) {
      setNameList(getNameList());
      setDepartmentList(getDepartmentList());
      setEmailList(getEmailList());
      setPhoneNumberList(getPhoneNumberList());
    }
    if (!openDrawer) reset();
    //eslint-disable-next-line
  }, [openDrawer, graphQLEmail]);

  /* ---------- functions --------------- */

  const debouncedFetch = _.debounce(getUserByEmailDebounce, 500);
  async function getUserByEmailDebounce(email, name, department) {
    const departmentId = department
      ? departmentList.find((item) => item.name?.toLowerCase() === department?.toLowerCase())
      : "";
    const mane = name ? encodeURIComponent(name) : "null";
    const mail = email ? email : "null";
    const depts = departmentId?.id ? departmentId.id : "null";
    const result = await makeAPIRequestRun("get", `api/company/companyDetails/${operator}/${mane}/${mail}/${depts}`);

    result?.allUsers?.edges && setGraphQLEmail(result.allUsers.edges);
  }

  const onClickClose = () => {
    reset(defaultValues);
    setOpenDrawer(false);
  };

  const getDepartmentList = () => {
    const department = graphQLEmail?.map((item) => item?.node?.userprofile?.departmentSection?.department);
    return department.filter((item) => !isEmpty(item));
  };

  const getPhoneNumberList = () => {
    const phoneno = graphQLEmail?.map((item) => item?.node?.userprofile?.phoneNo);
    return phoneno.filter((item) => !isEmpty(item));
  };

  const getNameList = () => {
    const names = graphQLEmail?.map((item) => item?.node?.fullName);
    return names.filter((item) => item !== null && item !== "None");
  };
  const getEmailList = () => {
    const emails = graphQLEmail?.map((item) => item?.node?.email);
    return emails;
  };

  return (
    <>
      <div className={styles.newAlertMain}>
        <div className={styles.newAlertHeader}>
          <div className={`${styles.row} ${styles.newAlertHeaderContent}`}>
            <span className={`${styles.textExtraSmall} ${styles.textSmallBold}`}>Add New Recipients</span>
            <div className={styles.alignEnd}>
              <img src={PopoutIcon} alt="popout" className={styles.newAlertHeaderImg} style={{ marginRight: "30px" }} />
              <Divider orientation="vertical" sx={{ mr: 3 }} />
              <img src={CloseIcon} alt="close" className={styles.newAlertHeaderImg} onClick={() => onClickClose()} />
            </div>
          </div>
          <Divider orientation="horizontal" />
        </div>

        <FormControl sx={formcontrolstyles} component="form" onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.newAlertContent}>
            <div className={styles.newAlertFormDiv}>
              <span className={styles.textExtraSmall}>
                Email<span className={`${styles.asterisk}`}>*</span>
              </span>
              <div>
                <Controller
                  control={control}
                  name="email"
                  rules={{
                    required: "This field is required",
                    pattern: {
                      value: emailRegex,
                      message: "Please enter a valid email format (@airasia.com, @rba.com.bn, or @ade.aero)",
                    },
                  }}
                  render={({ field: { ref, onChange, ...field } }) => (
                    <Autocomplete
                      freeSolo
                      onInputChange={(e, value) => {
                        onChange(value);
                        debouncedFetch(value, watchfullname, watchdept);
                      }}
                      onChange={(e, data) => onChange(data)}
                      placeholder="Select email"
                      fullWidth
                      id="fixed-tags-demo"
                      options={emailList}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E8E8E8",
                        },
                        "&& legend": {
                          width: "0ch",
                        },
                      }}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField {...params} size={"medium"} fullWidth placeholder="Select email" />
                      )}
                      value={getValues("email")}
                    />
                  )}
                />
                {errors.email && <p style={{ color: "red" }}>{errors.email.message}</p>}
              </div>
            </div>
            <div className={styles.newAlertFormDiv}>
              <span className={styles.textExtraSmall}>
                Name<span className={`${styles.asterisk}`}>*</span>
              </span>
              <div>
                <Controller
                  control={control}
                  rules={{ required: "This field is required" }}
                  name="fullname"
                  render={({ field: { ref, onChange, ...field } }) => (
                    <Autocomplete
                      freeSolo
                      onInputChange={(e, value) => {
                        onChange(value);
                        debouncedFetch(watchemail, value, watchdept);
                      }}
                      placeholder="Select name"
                      fullWidth
                      onChange={(_, data) => onChange(data)}
                      id="fixed-tags-demo"
                      options={nameList}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E8E8E8",
                        },
                        "&& legend": {
                          width: "0ch",
                        },
                      }}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField {...params} size={"medium"} fullWidth placeholder="Select name" />
                      )}
                      value={getValues("fullname")}
                    />
                  )}
                  // onChange={([, data]) => data}
                />
                {errors.fullname && <p style={{ color: "red" }}>{errors.fullname.message}</p>}
              </div>
            </div>
            <div className={styles.newAlertFormDiv}>
              <span className={styles.textExtraSmall}>Department</span>
              <Controller
                control={control}
                name="department"
                render={({ field: { ref, onChange, ...field } }) => (
                  <Autocomplete
                    onInputChange={(e, value) => {
                      onChange(value);
                      debouncedFetch(watchemail, watchfullname, value);
                    }}
                    freeSolo
                    fullWidth
                    placeholder="Department"
                    onChange={(_, data) => onChange(data)}
                    id="fixed-tags-demo"
                    options={[...new Set(departmentList.map((item) => item.name))]}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#E8E8E8",
                      },
                      "&& legend": {
                        width: "0ch",
                      },
                    }}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField {...params} size={"medium"} fullWidth placeholder="Department" />
                    )}
                    value={getValues("department")}
                  />
                )}
              />
            </div>
            <div className={styles.newAlertFormDiv}>
              <span className={styles.textExtraSmall}>Phone Number</span>
              <div>
                <Controller
                  control={control}
                  name="phoneNo"
                  render={({ field: { ref, onChange, ...field } }) => (
                    <TextField
                      // onInputChange={//api}
                      fullWidth
                      placeholder="Enter phone number"
                      // onChange={(_, data) => onChange(data)}
                      onChange={(e) => {
                        // Remove non-numeric characters except for "-" and "+"
                        const sanitizedValue = e.target.value.replace(/[^0-9\-+]/g, "");
                        onChange(sanitizedValue);
                      }}
                      id="fixed-tags-demo"
                      options={phoneNumberList}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E8E8E8",
                        },
                        "&& legend": {
                          width: "0ch",
                        },
                      }}
                      getOptionLabel={(option) => option}
                      value={getValues("phoneNo")}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <Grid container columns={2} sx={{ px: 2, bottom: 0, position: "absolute" }}>
            <Grid item xs={1} sx={{ p: 2 }}>
              <ButtonStyled
                variant="outlined"
                color="primary"
                fullWidth
                sx={{
                  backgroundColor: "#fff",
                  border: "none",
                  "&:hover": {
                    border: "1px solid #000",
                  },
                }}
                onClick={() => onClickClose()}
              >
                Cancel
              </ButtonStyled>
            </Grid>
            <Grid item xs={1} sx={{ p: 2 }}>
              <ButtonStyled variant="contained" color="primary" fullWidth type="submit" disabled={loading}>
                {loading ? <CircularProgress size={20} /> : "Save"}
              </ButtonStyled>
            </Grid>
          </Grid>
        </FormControl>
      </div>
    </>
  );
};

export default NewRecipients;
