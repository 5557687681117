import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { app } from "../../../firebase";

export const logOut = createAsyncThunk("auth/logout", async () => {
  try {
    await app.auth().signOut();

    return true;
  } catch (err) {
    throw new Error("Logout failed");
  }
});

export const signUp = createAsyncThunk(
  "auth/signUp",
  async ({ email, password }) => {
    try {
      const result = await app
        .auth()
        .createUserWithEmailAndPassword(email, password);
      return result;
    } catch (err) {
      throw new Error(err);
    }
  }
);

const authInitialState = {
  currentUser: {
    email: null,
    displayName: "-",
    uid: null
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState: authInitialState,
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
  },
  extraReducers: (builders) => {
    builders.addCase(logOut.fulfilled, (state, action) => {
      state.currentUser = authInitialState.currentUser;
    });
  },
});

export const { setCurrentUser } = authSlice.actions;

export default authSlice.reducer;
