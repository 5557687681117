import React from 'react';
import { Grid } from "@mui/material";
import { AdeButton } from "common/components";
import { AdeElevadeTabs } from "common/components/adeTabs/AdeElevadeTabs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Overlay from "../component/Overlay";
import { SearchBarAnalytics } from "../component/SearchBarInbox";
import { RepetitiveDefect } from "./RepetitiveDefect";
import { formatArray } from "./helpers/formatArray";
import { handlePredefinedRange } from "./helpers/predefinedDateRange";
import { EcamDispatch } from "./EcamDispatch";
import { makeAPIRequestRun } from "common/helpers";
import { formatDate } from "./helpers/formatDate";
import { uniqueList, setAOCBasedOnPermission } from "../component/Helpers";
import { isEmpty } from "lodash";

export const AnalyticsMain = () => {
  // Get the AOC list based on company & user
  const { profile: userProfile } = useSelector((state) => state.user);
  const { company: companyInfo } = useSelector((state) => state.company);
  const { companyAOC, userAOC, userPermission } = useSelector((state) => state.userEntity);
  // let AOC = companyInfo?.permission?.elevadeAOC;
  let AOC = companyAOC;
  // const UserAOC = userProfile?.permission?.elevadeAOC;
  // const UserAOC = userAOC;
  // if (UserAOC && UserAOC !== null && UserAOC !== undefined && UserAOC?.length > 0) AOC = UserAOC;
  AOC = setAOCBasedOnPermission(AOC, userPermission);
  // Set initial state
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingLegs, setIsLoadingLegs] = useState(true);
  const [isOperator, setIsOperator] = useState([]);
  const [isListACReg, setIsListACReg] = useState([]);
  const [isACReg, setIsACReg] = useState([]);
  const [localTime] = useState(false);
  const [startDate, setStartDate] = useState(handlePredefinedRange(72, localTime, true));
  const [endDate, setEndDate] = useState(handlePredefinedRange(72, localTime, false));
  const [isLegs, setIsLegs] = useState();
  const [tab, setTab] = useState(0);
  const [initialData, setInitialData] = useState();
  const [data, setData] = useState([]);
  const [initialAOC, setInitialAOC] = useState([]);
  const [lastModifiedDT, setLastModifiedDT] = useState();

  // Set isLoading when user change legs counting
  useEffect(() => {
    setIsLoadingLegs(true);
    //eslint-disable-next-line
  }, [isLegs, startDate, endDate]);

  useEffect(() => {
    if (isLegs !== undefined) {
      setStartDate(null);
      setEndDate(null);
    }
    //eslint-disable-next-line
  }, [isLegs]);

  useEffect(() => {
    if (startDate !== null && endDate !== null) {
      setIsLegs();
    }
    //eslint-disable-next-line
  }, [startDate, endDate]);

  // Set Aircraft Reg. to null if switch Operator
  useEffect(() => {
    setIsACReg([]);
  }, [isOperator]);

  useEffect(() => {
    async function getAircraftData() {
      let from = startDate ?? handlePredefinedRange(72, localTime, true);
      let to = endDate ?? handlePredefinedRange(72, localTime, false);
      let legs = isLegs || null;
      let acreg = isACReg.length > 0 ? isACReg.join(", ") : "null";
      const fetchData = makeAPIRequestRun(
        "get",
        "acarsdata/analytics/" + formatDate(from) + "/" + formatDate(to) + "/" + legs + "/" + acreg
      );
      fetchData
        .then((response) => {
          if (response !== "") {
            setInitialData(response?.rows[0]);
            setData(response?.rows[0]);
            SetDataListACReg(response?.acreglist[0]);
            setLastModifiedDT(response?.last_modified_dt[0][0]?.last_modified_dt?.value);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    getAircraftData();
    //eslint-disable-next-line
  }, [startDate, endDate, isLegs, tab, isACReg]);

  // Set initial operator
  // MAA as priority view
  useEffect(() => {
    if (isEmpty(isOperator)) {
      if (AOC.includes("MAA")) {
        setInitialAOC("MAA");
        setIsOperator("MAA");
      } else {
        setInitialAOC(AOC[0]);
        setIsOperator(AOC[0]);
      }
    }
    //eslint-disable-next-line
  }, [AOC, isOperator]);

  function SetDataListACReg(data) {
    let data_ = [];
    let dataToArray = [];
    data_ = data.filter((e) => e.aoc === initialAOC);
    data_
      .sort(function (a, b) {
        if (a.ac_reg < b.ac_reg) return -1;
        if (a.ac_reg > b.ac_reg) return 1;
        return 0;
      })
      // eslint-disable-next-line
      .map((e) => {
        if (e.ac_reg !== "") dataToArray.push({ ac_reg: e.ac_reg });
      });
    setIsListACReg(uniqueList(dataToArray, "ac_reg"));
  }

  return (
    <>
      <div
        style={{
          overflow: "hidden",
          marginLeft: "17px",
          paddingRight: `74px`,
          paddingBottom: "32px",
          zIndex: 8888,
        }}
        id="main"
      >
        {isLoadingLegs && !isLoading && <Overlay />}
        <div style={{ marginRight: "-74px" }}>
          <SearchBarAnalytics
            listOperator={formatArray(AOC, "operator")}
            listACReg={formatArray(isListACReg, "ac_reg")}
            isOperator={isOperator}
            setIsOperator={setIsOperator}
            isACReg={isACReg}
            setIsACReg={setIsACReg}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            localTime={localTime}
            isLegs={isLegs}
            setIsLegs={setIsLegs}
          />
        </div>
        <div style={{ paddingRight: "0px" }}>{/* <ActiveFilters /> */}</div>
        <div
          style={{
            margin: "28px -32px 18px 20px",
            fontFamily: "Gotham-Medium",
            fontSize: "16px",
          }}
        >
          <Grid container columns={2}>
            <Grid item xs={1.5}>
              <AdeElevadeTabs
                tab={tab}
                setTab={setTab}
                tabNames={[
                  { name: "REPETITIVE DEFECT DASHBOARD" },
                  {
                    name: "ECAM DISPATCH ALERT",
                    disabled: !userPermission.some((permission) => permission.code === "ANA-002"),
                  },
                  { name: "New Tab", disabled: true },
                ]}
              />
            </Grid>
            <Grid item sx={{ display: "flex", justifyContent: "flex-end" }} xs={0.5}>
              <AdeButton
                variant="contained"
                fullWidth={false}
                disabled
                style={{ paddingTop: "10px", paddingBottom: "10px" }}
              >
                Create Custom Chart
              </AdeButton>
            </Grid>
          </Grid>
        </div>
        {tab === 0 && (
          <RepetitiveDefect
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            isLoadingLegs={isLoadingLegs}
            setIsLoadingLegs={setIsLoadingLegs}
            isOperator={isOperator}
            setIsOperator={setIsOperator}
            setIsListACReg={setIsListACReg}
            localTime={localTime}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            isLegs={isLegs}
            setIsLegs={setIsLegs}
            AOC={AOC}
            initialData={initialData}
            setInitialData={setInitialData}
            data={data}
            setData={setData}
            initialAOC={initialAOC}
            setInitialAOC={setInitialAOC}
            tab={tab}
            lastModifiedDT={lastModifiedDT}
          />
        )}
        {tab === 1 && (
          <EcamDispatch
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            isLoadingLegs={isLoadingLegs}
            setIsLoadingLegs={setIsLoadingLegs}
            isOperator={isOperator}
            setIsOperator={setIsOperator}
            setIsListACReg={setIsListACReg}
            localTime={localTime}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            isLegs={isLegs}
            setIsLegs={setIsLegs}
            AOC={AOC}
            initialData={initialData}
            setInitialData={setInitialData}
            data={data}
            setData={setData}
            initialAOC={initialAOC}
            setInitialAOC={setInitialAOC}
            tab={tab}
            isACReg={isACReg}
            lastModifiedDT={lastModifiedDT}
          />
        )}
      </div>
    </>
  );
};
