import { createSlice } from "@reduxjs/toolkit";
import { makeAPIRequest } from "common/helpers";
import { isImage } from "common/helpers/checkFileType";
import { showNotification } from "common/helpers";
import { fileUpload } from "common/library/storage";
import _ from "lodash";
import { v4 as uuid4 } from "uuid";

const companyInitialState = {
  company: {},
  companyByCode: {},
  logisticList: [],
  loading: true,
};

const company = createSlice({
  name: "company",
  initialState: companyInitialState,
  reducers: {
    setLoading(state, { payload }) {
      state.loading = payload;
    },
    setCompanyInfo(state, { payload }) {
      state.company = payload;
      state.loading = false;
    },
    setLogisticList(state, { payload }) {
      state.logisticList = payload;
    },
    setCompanyByCode(state, { payload }) {
      state.companyByCode = payload;
      state.loading = false;
    },
  },
});

export const { setLoading, setCompanyInfo, setLogisticList, setCompanyByCode } = company.actions;

export default company.reducer;

//  Get package status
export const getCompanyInfo = (companyId) => async (dispatch) => {
  try {
    const companyInfo = await makeAPIRequest("get", `company/get/${companyId}`);
    dispatch(setCompanyInfo(companyInfo));
    dispatch(setLogisticList(companyInfo.logisticPartners));
    return companyInfo;
  } catch (error) {
    showNotification("danger", "Get Company Info Error", [error]);
  }
};

export const getByCompanyCode = (companyCode) => async (dispatch) => {
  try {
    const companyByCode = await makeAPIRequest("get", `company/getByCompanyCode/${companyCode}`);
    dispatch(setCompanyByCode(companyByCode));
    return companyByCode;
  } catch (error) {
    showNotification("danger", "Get Company By Code Error", [error]);
  }
};

export const submitBankInfo =
  (companyId, { id, verificationDocs, ...bankDetails }, type) =>
  async (dispatch) => {
    try {
      const bankId = id || uuid4();
      const method = type === "update" ? "put" : type === "delete" ? "delete" : "post";
      const url = method === "delete" ? `company/${type}/${companyId}/bank/${id}` : `company/${type}/${companyId}/bank`;

      const verficationDocsTasks = _.map(verificationDocs, (file) => {
        // upload new files
        if (file instanceof File) {
          return fileUpload(file, companyId, `bank-docs/${bankId}`);
        }

        // return existing files
        return file;
      });

      const __verficationDocs = await Promise.all(verficationDocsTasks);

      const result = await makeAPIRequest(method, url, {
        ...bankDetails,
        bankId,
        verificationDocs: __verficationDocs,
      });

      if (!result.error) {
        dispatch(getCompanyInfo(companyId));
        showNotification("success", "", ["Bank Details Successfully Updated!"]);
      }
    } catch (err) {
      console.error(err);
      showNotification("danger", "Submit Bank Info Error", [err]);
    }
  };

export const submitCompanyForm =
  (companyId, { id, ...formData }, type, formName) =>
  async (dispatch) => {
    try {
      const regex = /address/gi;

      const fieldName = regex.test(formName) ? "addressId" : "partnerId";

      const endpoint = _.snakeCase(formName);
      const url = `company/${type}/${companyId}/${endpoint}`;
      const method = type === "update" ? "put" : "post";

      const result = await makeAPIRequest(method, url, {
        ...formData,
        [fieldName]: id,
      });

      if (!result.error) {
        dispatch(getCompanyInfo(companyId));
        showNotification("success", "", ["Details Successfully Updated!"]);
      }
    } catch (err) {
      showNotification("danger", `Submit ${formName} Details Error`, [err]);
    }
  };

export const submitCompanyProfile =
  (companyId, { localCert, registrationDocs, ...companyDetails }, type) =>
  async (dispatch) => {
    try {
      const url = `company/${type}/${companyId}/profile`;
      const method = type === "update" ? "put" : "post";

      const localCertTasks = Promise.all(
        _.map(localCert, async (file, index) => {
          // upload new files
          if (file instanceof File) {
            const data = await fileUpload(file, companyId, `localCert`);
            const result = { ...data, naaCode: localCert[index].naaCode };
            // return fileUpload(file, companyId, `localCert`);
            return result;
          }

          // return existing files
          return file;
        })
      );

      const registrationDocsTasks = Promise.all(
        _.map(registrationDocs, (file) => {
          // upload new files
          if (file instanceof File) {
            return fileUpload(file, companyId, `registrationDocs`);
          }

          // return existing files
          return file;
        })
      );

      const [__localCert, __registrationDocs] = await Promise.all([localCertTasks, registrationDocsTasks]);

      const result = await makeAPIRequest(method, url, {
        ...companyDetails,
        localCert: __localCert,
        registrationDocs: __registrationDocs,
      });
      if (!result.error) {
        dispatch(getCompanyInfo(companyId));
        showNotification("success", "", ["Company Details Successfully Updated!"]);
      }
      dispatch(getCompanyInfo(companyId));
    } catch (err) {
      showNotification("danger", "Submit Company Details Error", [err]);
    }
  };

export const uploadImage = (data, companyId, folder) => async (dispatch) => {
  try {
    if (!isImage(data.name)) {
      showNotification("warning", "File Type", ["Only File Type .jpg .jpeg .png .tiff .bmp can be upload."]);
      return;
    }

    const endpoint = folder.substring(folder.lastIndexOf("_") + 1);
    const url = `company/update/${companyId}/${endpoint}`;
    const downloadUrl = await fileUpload(data, companyId, folder);
    await makeAPIRequest("put", url, downloadUrl);

    await dispatch(getCompanyInfo(companyId));
    return downloadUrl;
  } catch (error) {
    console.error("Error during upload Avatar : ", error);
    showNotification("danger", "Submit Upload Image Error", [error]);
  }
};

export const deleteInfo =
  ({ companyId, id, endpoint }) =>
  async (dispatch) => {
    try {
      const url = `company/delete/${companyId}/${endpoint}/${id}`;
      const result = await makeAPIRequest("delete", url);

      if (!result.error) {
        await dispatch(getCompanyInfo(companyId));
        showNotification("success", "", [" Details Successfully Deleted!"]);
      }
    } catch (err) {
      showNotification("danger", "Delete info error", [err]);
    }
  };

export const becomeSeller = (companyId) => async (dispatch) => {
  try {
    await setLoading(true);
    const url = `company/become-a-seller/${companyId}`;
    await makeAPIRequest("post", url);

    await dispatch(getCompanyInfo(companyId));

    dispatch(setLoading(false));
  } catch (err) {
    showNotification("danger", "Become a seller error", [err]);
  }
};

export const submitAddressInfo =
  (companyId, { id, ...details }, type, address) =>
  async (dispatch) => {
    try {
      const addressId = id || uuid4();
      const partnerId = id || uuid4();

      const method = type === "update" ? "put" : type === "delete" ? "delete" : "post";
      const url =
        method === "delete" ? `company/${type}/${companyId}/${address}/${id}` : `company/${type}/${companyId}/${address}`;

      let result = "";
      if (address === "logistic_partner") {
        result = await makeAPIRequest(method, url, {
          ...details,
          partnerId,
        });
      } else {
        result = await makeAPIRequest(method, url, {
          ...details,
          addressId,
        });
      }

      if (!result.error) {
        dispatch(getCompanyInfo(companyId));
        showNotification("success", "", ["Details Successfully Updated!"]);
      }
    } catch (err) {
      console.error(err);
      showNotification("danger", "Update Failed", [err]);
    }
  };

export const updateInfo = (companyId, data, path) => async (dispatch) => {
  try {
    const url = `company/update/${companyId}/${path}`;
    const result = await makeAPIRequest("put", url, {
      ...data,
      companyId,
    });
    if (!result.error) {
      dispatch(getCompanyInfo(companyId));
      showNotification("success", "", ["Update Success!"]);
    }
  } catch (err) {
    console.error(err);
    showNotification("danger", "Update Failed", [err]);
  }
};

export const updateOrderUrgencyInfo = (companyId, data, path) => async (dispatch) => {
  try {
    const url = `company/update/${companyId}/${path}`;
    const result = await makeAPIRequest("put", url, {
      ...data,
      companyId,
    });
    if (!result.error) {
      dispatch(getCompanyInfo(companyId));
      // showNotification("success", "", ["Update Success!"]);
    }
  } catch (err) {
    console.error(err);
    showNotification("danger", "Update Failed", [err]);
  }
};
