import React from 'react';
import { useReducer } from "react";
import DashboardContext from "./dashboardContext";
import DashboardReducer from "./dashboardReducer";
import { 
  RESET_CONTEXT, 
  SET_LOADING, 
  SET_TABS, 
  SET_SUBTAB, 
  SET_MISC, 
  SET_AEROTRADE_OPEN, 
  SET_CASCADE_OPEN, 
  SET_ELEVADE_OPEN,
  SET_MODAL_OPEN,
  SET_FORM_DEFAULTS,
} from "../types";

const DashboardState = (props) => {
  const initialState = {
    tab: 'personal',
    subtab: '',
    misc: {},
    loading: false,
    openAerotrade: false,
    openCascade: false,
    openElevade: false,
    modal: {
      open: false,
      type: 'Add',
    },
    formDefaults: {}
  };

  const [dashboard, dispatch] = useReducer(DashboardReducer, initialState);

  const clear = () => {
    return dispatch({ type: RESET_CONTEXT, payload: initialState });
  }

  const setTabs = (tab='personal', subtab='') => {
    return dispatch({ type: SET_TABS, payload: {tab, subtab} });
  }

  const setSubtab = (subtab) => {
    return dispatch({ type: SET_SUBTAB, payload: subtab });
  }

  const setMisc = (misc) => {
    return dispatch({ type: SET_MISC, payload: misc });
  }

  //Set loading
  const setLoading = (status) => {
    return dispatch({ type: SET_LOADING, payload: status });
  };

  //Sidenav
  const setOpenAerotrade = (open) => {
    return dispatch({ type: SET_AEROTRADE_OPEN, payload: open });
  }

  const setOpenCascade = (open) => {
    return dispatch({ type: SET_CASCADE_OPEN, payload: open });
  }

  const setOpenElevade = (open) => {
    return dispatch({ type: SET_ELEVADE_OPEN, payload: open });
  }

  // modal
  const setModal = (open, title = '', formName = '', type = 'add') => {
    return dispatch({ type: SET_MODAL_OPEN, payload: {
      open,
      title,
      formName,
      type
    }})
  }

  const setFormDefaults = (formDefaults) => {
    return dispatch({ type: SET_FORM_DEFAULTS, payload: formDefaults })
  }

  return (
    <DashboardContext.Provider
      value={{
        ...dashboard,
        setTabs,
        setSubtab,
        setMisc,
        clear,
        setLoading,
        setOpenAerotrade,
        setOpenCascade,
        setOpenElevade,
        setModal,
        setFormDefaults,
      }}
    >
      {props.children}
    </DashboardContext.Provider>
  );
};

export default DashboardState;
