import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid, styled, Tab, Avatar, Tooltip, tooltipClasses } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import styles from "../Dasr.module.css";
import IconErrorAirBalloon from "common/images/icons/elevade/Icon-ErrorAirBalloon.svg";
import SearchBar from "./SearchBar";
import ActiveFilters from "./ActiveFilters";
import {
  addingDate,
  calculateBalanceDay,
  calculateCalendarBalance,
  formatDateDasr,
  calculateCalendarExpiryDate,
} from "pages/elevade/component/Helpers";
import MUIPagination from "pages/elevade/component/Pagination";
import { makeStyles } from "@mui/styles";
import { makeAPIRequestMDB } from "common/helpers/makeAPIRequestMDB";
import Overlay from "../../component/Overlay";
import NoData from "pages/elevade/component/DataUnavailable";
import IconAirplaneLimitation from "common/images/icons/elevade/Icon-AirplaneLimitation.svg";
import IconDustbin from "common/images/icons/elevade/Icon-Dustbin.svg";
import IconDustbinWhite from "common/images/icons/elevade/Icon-DustbinWhite.svg";
import IconDustbinDisabled from "common/images/icons/elevade/Icon-DustbinDisabled.svg";
import IconBriefcase from "common/images/icons/elevade/Icon-PushTo145.svg";
import IconBriefcaseWhite from "common/images/icons/elevade/Icon-PushTo145White.svg";
import IconBriefcaseDisabled from "common/images/icons/elevade/Icon-PushTo145Disabled.svg";
import IconPlusCircleWhite from "common/images/icons/elevade/Icon-PlusCircleWhite.svg";
import IconReInspection from "common/images/icons/elevade/Icon-ReInspection.svg";
import { CheckBoxField, CheckBoxFieldMock } from "pages/elevade/component/InputForm";
import ElevadeDrawer from "../component/Drawer";
import Button from "pages/elevade/component/Button";
import DialogDelete from "./DialogDelete";
import DialogPush145 from "./DialogPush145";
import AdditionalTaskForm from "./AdditionalTaskForm";
import AdditionalTaskSummary from "./AdditionalTaskSummary";
import DDML from "../aircraftReport/DDML";
import AirplaneLoader from "pages/elevade/component/AirplaneLoader";
import { makeAPIRequestRun, showNotificationElevade } from "common/helpers";
import IconFlag from "common/images/icons/elevade/Icon-Flag.svg";
import IconFlagActive from "common/images/icons/elevade/Icon-FlagActive.svg";

const Assignment = ({ aoc, isLoading, setIsLoading }) => {
  const { userPermission } = useSelector((state) => state.userEntity);
  const summaryPermission = userPermission.filter((perm) => perm.code === "DASR-004");
  const [subLoading, setSubLoading] = useState(true);
  const [type, setType] = useState("1");
  const [value, setValue] = useState("1");
  const [open, setOpen] = useState(false);
  const [openTitle, setOpenTitle] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState([]);
  const [data_, setData_] = useState([]);
  const [dataForm, setDataForm] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [index, setIndex] = useState();
  const [edit, setEdit] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [newForm, setNewForm] = useState(false);
  const [triggerApi, setTriggerApi] = useState(true);
  const [activeIndex, setActiveIndex] = useState("");
  const [selectToMove, setSelectToMove] = useState([]);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [openDialogMoveToAssignment, setOpenDialogMoveToAssignment] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [isSearch, setIsSearch] = useState([]);
  const InitialFilters = { search: [], location: [], aircraftReg: [], assignedTo: [], raisedDate: [], expiryDate: [] };
  const [isFilters, setIsFilters] = useState(InitialFilters);
  const [filteredData, setFilteredData] = useState([]);
  const [date, setDate] = useState(new Date());
  const [openCreateAdhoc, setOpenCreateAdhoc] = useState(false);
  const [openAdhoc, setOpenAdhoc] = useState(false);
  // const [aircraftList, setAircraftList] = useState([]);
  const [adhocList, setAdhocList] = useState([]);
  const [dataFHFC, setDataFHFC] = useState([]);
  const [aircraftList, setAircraftList] = useState([]);
  const [isCountDDML, setIsCountDDML] = useState(0);
  const [isCountRDD, setIsCountRDD] = useState(0);
  const [isCountAdhoc, setIsCountAdhoc] = useState(0);

  const today_ = new Date();
  const formattedDate = today_.toISOString();

  let sliceStart = page > 0 ? perPage * (page - 1) : 0;
  let sliceEnd = parseInt(perPage) + sliceStart;

  useEffect(() => {
    setIsLoading(false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!openAdhoc) setEdit(false);
  }, [openAdhoc]);

  useEffect(() => {
    setData_(data?.filter((e) => e.moveToAssignment === true && e.formStatus === "open" && e.push145 === false));
  }, [data, type, value]);

  useEffect(() => {
    let totalRows = value === "1" ? filteredData?.length : value === "3" ? adhocList?.length : 0;
    let totalPage = Math.floor(filteredData?.length / perPage);
    if (totalRows % perPage !== 0) totalPage = totalPage + 1;
    setTotalRows(totalRows);
    setTotalPage(totalPage);
  }, [data, data_, perPage, filteredData, value, adhocList]);

  useEffect(() => {
    fetch();
    // setSubLoading(true);
    // eslint-disable-next-line
  }, [openNotification, triggerApi, date, aoc]);

  useEffect(() => {
    if (!open && !openCreateAdhoc) setActiveIndex("");
  }, [open, openCreateAdhoc]);

  useEffect(() => {
    setSelectToMove([]);
    setCheckAll(false);
    setDataForm([]);
    setEdit(false);
  }, [value]);

  // Applying filters
  useEffect(() => {
    let data__ = [];
    let theData = data_;
    if (value === "1") theData = data_;
    else if (value === "3") theData = adhocList;
    else theData = [];
    setFilteredData(theData);

    // Search input fields
    if (isFilters.search.length !== 0) {
      data__ = theData.filter(
        (item) =>
          isFilters.search.some((searchTerm) => item?.location?.toLowerCase()?.includes(searchTerm?.toLowerCase())) ||
          isFilters.search.some((searchTerm) =>
            item?.aircraftReg?.toLowerCase()?.includes(searchTerm?.toLowerCase())
          ) ||
          isFilters.search.some((searchTerm) => item?.ddmlNo?.toLowerCase()?.includes(searchTerm?.toLowerCase())) ||
          isFilters.search.some((searchTerm) =>
            item?.limitationsDesc?.toLowerCase()?.includes(searchTerm?.toLowerCase())
          ) ||
          isFilters.search.some((searchTerm) => item?.assignedTo?.toLowerCase()?.includes(searchTerm?.toLowerCase())) ||
          isFilters.search.some((searchTerm) => item?.defectDesc?.toLowerCase()?.includes(searchTerm?.toLowerCase())) ||
          isFilters.search.some((searchTerm) => item?.melRef?.toLowerCase()?.includes(searchTerm?.toLowerCase()))
      );
      setFilteredData(data__);
    }

    // Station (location)
    if (isFilters.location.length > 0) setFilteredData(theData.filter((e) => isFilters.location.includes(e.location)));
    // Aircraft Registration
    if (isFilters.aircraftReg.length > 0)
      setFilteredData(theData.filter((e) => isFilters.aircraftReg.includes(e.aircraftReg)));
    // Assigned To (Department)
    if (isFilters.assignedTo.length > 0)
      setFilteredData(theData.filter((e) => isFilters.assignedTo.includes(e.assignedTo)));
    // Raised Date
    if (isFilters.raisedDate.length > 0)
      setFilteredData(theData.filter((e) => e.raisedDate.startsWith(isFilters.raisedDate)));
    // Expiry Date
    if (isFilters.expiryDate.length > 0)
      setFilteredData(theData.filter((e) => e.expiryDate.startsWith(isFilters.expiryDate)));
    // eslint-disable-next-line
  }, [isFilters, data, type, data_, value]);

  useEffect(() => {
    if (selectToMove.length > 0 && totalRows > 0 && selectToMove.length === totalRows) setCheckAll(true);
    if (selectToMove.length === 0) setCheckAll(false);
  }, [selectToMove, totalRows]);

  useEffect(() => {
    if (selectToMove.length === 0) setCheckAll(false);
  }, [selectToMove]);

  useEffect(() => {
    let countDDML = data_?.filter((e) => e.moveToAssignment === true && e.formStatus === "open").length;
    let countAdhoc = adhocList?.filter((e) => e.formStatus === "open").length;
    setIsCountDDML(countDDML);
    setIsCountAdhoc(countAdhoc);
  }, [filteredData, adhocList]);

  function formatDate(dateString) {
    // Parse the input date string
    const dateObj = new Date(dateString);

    // Get the year, month, and day components
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");

    // Construct the formatted date string
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }

  async function fetch() {
    // Fetch assignment from DDML
    const fetch = makeAPIRequestMDB(`get`, `api/ddml/assigned?aoc=${aoc}`);
    fetch
      .then((response) => {
        setData(
          response.data?.filter((e) => e.moveToAssignment === true && e.formStatus === "open" && e.push145 === false)
        );
        setSubLoading(false);
        setOpen(false);
        setOpenNotification(false);
        setTriggerApi(false);
      })
      .catch((error) => {
        console.log("error", error);
        setSubLoading(false);
      });

    var currentDate = new Date(date);
    var options = { month: "long", day: "2-digit", year: "numeric" };
    var formattedDate = currentDate.toLocaleDateString("en-US", options);
    currentDate = new Date(formattedDate);
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const isToday = `${year}-${month}-${day}`;

    // Fetch assignment from additional task
    // TODO: API to return _id of adhoc task
    const fetchAdditionalTask = makeAPIRequestMDB(`get`, `api/assignment/adhoc?aoc=${aoc}&move_to_assignment=true`);
    fetchAdditionalTask.then((response) => {
      setAdhocList(response.data.filter((e) => e.push145 === false));
    });

    const fetchList = makeAPIRequestMDB(`get`, `api/aircraft/daily-status?aoc=${aoc}&date=${isToday}`);
    fetchList
      .then((response) => {
        let theList = [];
        let list = response.data?.sort((a, b) => a.aircraftReg.localeCompare(b.aircraftReg));
        // TODO: to check on this later
        // eslint-disable-next-line no-unused-expressions
        list?.map((e) => {
          return theList.push({ code: e.aircraftReg, name: e.aircraftReg });
        });
        setAircraftList(theList);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  // A function to set priority
  async function setPriority(id) {
    const body = {
      formId: id,
      formType: value === "1" ? "ddml" : value === "2" ? "rdd" : "adhoc",
    };
    const update = makeAPIRequestMDB(`put`, `api/assignment/set-priority`, body);
    update.then((response) => {
      if (response.detail.includes("success")) {
        return true;
      } else {
        showNotificationElevade("danger", "An error occured!", ["Please contact your system administartor"]);
        return false;
      }
    });
  }

  async function fetchFHFC() {
    // setSubLoading(true);
    const fetch = makeAPIRequestRun(`get`, `acarsdata/fleetgetfcfh/null`);
    fetch
      .then((response) => {
        setDataFHFC(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useEffect(() => {
    fetchFHFC();
  }, []);

  function updateArrayColumn(column, value) {
    return dataForm.map((item) => {
      if (column === "attachment") {
        return {
          ...item,
          [column]: value,
        };
      } else if (column === "raisedDate") {
        return { ...item, [column]: value + "T00:00:00.000+00:00" };
      }
      // Check if the item has the specified column and update it if exists
      else if (item.hasOwnProperty(column)) {
        return { ...item, [column]: value }; // Create a new object with updated column
      } else return { ...item, [column]: value }; // If the specified column doesn't exist, return the original item unchanged
    });
  }

  // Custom styles
  const useStyles = makeStyles(() => ({
    ul: {
      "& .Mui-selected": {
        color: "#fff",
        backgroundColor: "#088FD1",
        border: "1px solid #088FD1",
        "&:hover": {
          backgroundColor: "#088FD1",
        },
      },
    },
    gridItem: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-box-orient": "vertical",
      "-webkit-line-clamp": 2, // Number of lines to show
      fontSize: "14px",
    },
  }));

  const classes = useStyles();

  const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    // padding: 0,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    fontFamily: ["Gotham-Book"].join(","),
    "&:hover": {
      color: "#333333",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#333333",
      fontFamily: "Gotham-Medium",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  }));

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setType(newValue);
  };

  const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#FFFEE8",
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: 11,
        textTransform: "uppercase",
      },
    })
  );

  const ErrorAirBallon = () => {
    return (
      <>
        <div className={styles.errorAirBallon}>
          <center>
            <img src={IconErrorAirBalloon} alt="error-air-ballon" />
            <div className={styles.errorTitle}>No Task Assignment Available</div>
            <div className={styles.errorSubTitle}></div>
          </center>
        </div>
      </>
    );
  };

  const Tips = ({ val, unread }) => {
    return (
      <>
        <span
          style={{
            borderRadius: "30px",
            color: unread ? "#ffffff" : "#838383",
            border: unread ? "1px solid #E95048" : "1px solid #E8E8E8",
            backgroundColor: unread ? "#E95048" : "#F7F7F7",
            fontFamily: "Gotham-Book",
            fontSize: "12px",
            marginLeft: "8px",
            padding: "4px 8px 2px 8px",
          }}
        >
          {val}
        </span>
      </>
    );
  };

  const AircraftReportList = ({ type }) => {
    let theData = data_;
    if (type === "DDML") theData = data_;
    else if (type === "Additional Task") theData = adhocList;
    else theData = [];
    return (
      <>
        <Grid container columns={4} sx={{ marginTop: "0px" }}>
          {/* <Grid item xs={4} className={styles.gridTitle}>
            {type} List
          </Grid> */}
          {/* {theData !== undefined && theData !== "" && theData?.length > 0 && ( */}
          <>
            <Grid item xs={4} sx={{ paddingTop: "32px", paddingLeft: "16px" }}>
              {theData.length > 0 && (
                <SearchBar
                  open={openModal}
                  setOpen={setOpenModal}
                  data={theData}
                  isSearch={isSearch}
                  setIsSearch={setIsSearch}
                  isFilters={isFilters}
                  setIsFilters={setIsFilters}
                  openTitle={openTitle}
                  setOpenTitle={setOpenTitle}
                  type={type}
                  sub={false}
                />
              )}
              <ActiveFilters isFilters={isFilters} setIsFilters={setIsFilters} initialFilters={InitialFilters} />
            </Grid>
            <Grid item xs={3} sx={{ display: "flex", paddingTop: "32px" }} onClick={() => setOpenModal(false)}>
              {theData.length > 0 && (
                <>
                  <Button
                    title="Delete"
                    icon={IconDustbin}
                    iconWhite={IconDustbinWhite}
                    iconDisabled={IconDustbinDisabled}
                    variant={selectToMove.length > 0 ? "outlined" : "disabled"}
                    onClick={() => setOpenDialogDelete(true)}
                  />
                  <Button
                    title="Push to 145"
                    icon={IconBriefcase}
                    iconWhite={IconBriefcaseWhite}
                    iconDisabled={IconBriefcaseDisabled}
                    variant={selectToMove.length > 0 ? "outlined" : "disabled"}
                    onClick={() => setOpenDialogMoveToAssignment(true)}
                  />
                </>
              )}
            </Grid>
            <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-end", paddingTop: "32px" }} onClick={() => setOpenModal(false)}>
              {value === "3" && (
                <Button
                  title="Add Task"
                  icon={IconPlusCircleWhite}
                  iconWhite={IconPlusCircleWhite}
                  iconDisabled={IconPlusCircleWhite}
                  variant={"fill"} // TODO: change to "fill" when ready
                  onClick={() => {
                    setOpenCreateAdhoc(true);
                    setNewForm(true);
                    setEdit(true);
                  }}
                  flexend
                />
              )}
            </Grid>
            <Grid item xs={4} sx={{ paddingTop: "16px" }} onClick={() => setOpenModal(false)}>
              {subLoading ? <Overlay /> : <TableList type={type} data={theData} />}
            </Grid>
          </>
          {/* )} */}
          {/* {(data?.length === 0 || theData?.length === 0) && !subLoading && (
            <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }}>
              <NoData />
            </Grid>
          )} */}
        </Grid>
      </>
    );
  };

  function handleClick(e, index) {
    if (value === "1") setOpen(true);
    else if (value === "3") setOpenCreateAdhoc(true);
    setIndex(index);
    setDataForm(e);
    setEdit(false);
    setActiveIndex(index);
    setNewForm(false);
  }

  function handleSelect() {
    if (selectToMove.length > 0 && selectToMove.length < totalRows) {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          onClick={() => setCheckAll(!checkAll)}
          style={{ cursor: "pointer" }}
        >
          <rect width="20" height="20" rx="4" fill="#088FD1" />
          <path d="M6 10L14 10" stroke="white" stroke-width="3" stroke-linecap="round" />
        </svg>
      );
    } else if (checkAll) return <CheckBoxFieldMock isCheckedAll={true} setIsCheckedAll={setCheckAll} />;
    else
      return summaryPermission.some((perm) => perm.canUpdate === true) ? (
        <CheckBoxFieldMock
          isCheckedAll={selectToMove.length > 0 && totalRows > 0 && selectToMove.length === totalRows}
          setIsCheckedAll={setCheckAll}
        />
      ) : (
        <CheckBoxField id={""} selected={selectToMove} setSelected={setSelectToMove} disabled={true} />
      );
  }

  useEffect(() => {
    if (checkAll) {
      handleCheckAll();
    } else handleUnCheckAll();
    // eslint-disable-next-line
  }, [checkAll]);

  useEffect(() => {
    if (selectToMove.length > 0 && totalRows > 0 && selectToMove.length === totalRows) setCheckAll(true);
  }, [selectToMove, totalRows]);

  function handleCheckAll() {
    let array = [];
    let theData = data_;
    if (value === "1") theData = data_;
    else if (value === "3") theData = adhocList;

    if (value === "1") {
      theData
        .filter((e) => e.moveToAssignment === true && e.formStatus === "open")
        .map((e) => {
          return array.push(e._id);
        });
    }
    if (value === "3") {
      theData.map((e) => {
        return array.push(e._id);
      });
    }
    setSelectToMove(array);
  }

  function handleUnCheckAll() {
    setSelectToMove([]);
  }

  function setToday() {
    // Create a new Date object
    const currentDate = new Date();

    // Get the individual components of the date
    const year = currentDate.getFullYear();
    // JavaScript months are 0-indexed, so we add 1 to get the correct month
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");

    // Create a string in the desired format
    const formattedDate = `${year}-${month}-${day}T00:00:00`;
    return formattedDate;
  }

  function calcBalanceFH(interval, raisedAt, val) {
    let expiry = parseInt(interval) + parseInt(raisedAt);
    let current = val;
    let balance = parseInt(expiry) - parseInt(current);
    return parseInt(balance).toLocaleString() || "-";
  }

  function calcBalanceFC(interval, raisedAt, val) {
    let expiry = parseInt(interval) + parseInt(raisedAt);
    let current = val;
    let balance = parseInt(expiry) - parseInt(current);
    return parseInt(balance).toLocaleString() || "-";
  }

  function calcBalanceCD(calendarDaysStartDate, calendarDaysInterval) {
    let startDate = calendarDaysStartDate;
    let interval = calendarDaysInterval || 0;
    let expiry = addingDate(startDate, interval);
    let today = setToday();
    let balance = calculateBalanceDay(today?.split("T")[0], expiry?.split("T")[0]);
    return balance;
  }

  function handleSetPriority(id) {
    // Call API to set priority in assignment collection
    setPriority(id);
  }

  const IconUnreadIndicator = ({ data }) => {
    let unreadLogs = data.unreadLogs;
    let count = unreadLogs?.materialTool.length + unreadLogs?.planningTech.length + unreadLogs?.rectification.length;

    return (
      count > 0 && (
        <div style={{ position: "absolute", right: "24px" }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
            <circle cx="5" cy="5" r="5" fill="#0ADDDD" />
          </svg>
        </div>
      )
    );
  };

  const TableList = ({ type, data }) => {
    return filteredData?.length > 0 ? (
      <>
        <div
          style={{
            width: "100%",
            borderRadius: "12px",
            overflow: "hidden",
            border: "1px solid #E8E8E8",
          }}
        >
          <Grid container columns={13}>
            <Grid item xs={0.4} className={styles.tableHeader}>
              {handleSelect()}
            </Grid>
            <Grid item xs={0.5} className={styles.tableHeader}></Grid>
            <Grid item xs={0.7} className={styles.tableHeader}>
              Station
            </Grid>
            <Grid item xs={0.9} className={styles.tableHeader}>
              A/C Reg.
            </Grid>
            {value !== "3" && (
              <>
                <Grid item xs={2.5} className={`${styles.tableHeader} ${styles.gridJustifyStart}`}>
                  Defect Description
                </Grid>
                <Grid item xs={1} className={styles.tableHeader}>
                  {value === "3" ? "Status/DDML" : "DDML No."}
                </Grid>
                <Grid item xs={1} className={styles.tableHeader}>
                  MEL Ref.
                </Grid>
                <Grid item xs={1} className={styles.tableHeader}>
                  Raised Date
                </Grid>
                <Grid item xs={1} className={styles.tableHeader}>
                  Expiry/Balance
                </Grid>
                <Grid item xs={1} className={styles.tableHeader}>
                  Department
                </Grid>
                <Grid item xs={3} className={`${styles.tableHeader} ${styles.gridJustifyStart}`}>
                  Rectification Status
                </Grid>
              </>
            )}
            {value === "3" && (
              <>
                <Grid item xs={3.5} className={`${styles.tableHeader} ${styles.gridJustifyStart}`}>
                  Task Description
                </Grid>
                <Grid item xs={1} className={styles.tableHeader}>
                  Status
                </Grid>
                <Grid item xs={1} className={styles.tableHeader}>
                  Raised Date
                </Grid>
                <Grid item xs={1} className={styles.tableHeader}>
                  ETS
                </Grid>
                <Grid item xs={2} className={`${styles.tableHeader} ${styles.gridJustifyStart}`}>
                  Task Updates
                </Grid>
                <Grid item xs={2} className={`${styles.tableHeader} ${styles.gridJustifyStart}`}>
                  Remarks
                </Grid>
              </>
            )}
            {filteredData &&
              filteredData.slice(sliceStart, sliceEnd)?.map((e, index) => {
                let calendarDaysInterval = e.calendarDaysInterval || 0;
                let calendarDaysStartDate = e.calendarDaysStartDate || e.raisedDate;
                let expiry = addingDate(calendarDaysStartDate, calendarDaysInterval);
                let date1 = e.formType === "AMD" ? e.calendarDaysStartDate : formattedDate?.split("T")[0];
                let date2 = expiry;
                let balance = calculateBalanceDay(date1, date2);
                let showInTable = e.showInTable;
                let currentFlightHour = dataFHFC.filter((item) => item.AC_REG === e.aircraftReg)[0]?.flight_hour;
                let currentFlightCycle = dataFHFC.filter((item) => item.AC_REG === e.aircraftReg)[0]?.flight_cycle;
                if (e.calendarDays) balance = calcBalanceCD(calendarDaysStartDate, calendarDaysInterval);
                if (showInTable === "fh" && e.flightHours && e.formType === "STR/FC/FH")
                  balance = calcBalanceFH(e.flightHoursInterval, e.flightHoursRaisedAt, currentFlightHour);
                else if (showInTable === "fc" && e.flightCycle && e.formType === "STR/FC/FH")
                  balance = calcBalanceFC(e.flightCycleInterval, e.flightCycleRaisedAt, currentFlightCycle);
                else if (showInTable === "" && e.formType === "STR/FC/FH")
                  balance = calcBalanceCD(calendarDaysStartDate, calendarDaysInterval);

                let fcfhIndicator = " Days";
                if (showInTable === "fh" && e.flightHours) fcfhIndicator = " (FH)";
                else if (showInTable === "fc" && e.flightCycle) fcfhIndicator = " (FC)";

                if (!e.flightCycle && !e.flightHours && !e.calendarDays && e.formType === "STR/FC/FH") balance = "-";
                if (e.formType === "AMD")
                  balance = calculateCalendarBalance(e.calendarDaysStartDate, e.calendarDaysInterval);
                if (e.formType === "Fuel Sampling" || e.formType === "Hydraulic Sampling")
                  balance = calculateCalendarBalance(e.calendarDaysStartDate, e.calendarDaysInterval);
                let expiryDate_ = formatDateDasr(e.expiryDate);
                if (e.formType !== "STR/FC/FH") {
                  if (e.formType === "MEL CAT" && e.flightDays)
                    // expiryDate_ = calculateCalendarBalanceDate(e.calendarDaysStartDate, e.calendarDaysInterval);
                    expiryDate_ = calculateCalendarExpiryDate(e.flightDaysStartDate, e.flightDaysInterval);
                  else if (e.formType !== "MEL CAT" && e.calendarDays)
                    expiryDate_ = calculateCalendarExpiryDate(e.calendarDaysStartDate, e.calendarDaysInterval);
                }
                // Calculate the date difference
                var currentDate = new Date();
                var timeDifferenceMs = new Date(e.expiryDate) - currentDate;
                var remainingDays = Math.ceil(timeDifferenceMs / (1000 * 60 * 60 * 24));

                // Setting up the red indicator based on balance type

                // let balanceSTR = balance;
                let balanceSTR = !isNaN(parseInt(balance)) && balance !== "-" ? balance + fcfhIndicator : "-";
                if (e.calendarDays) balanceSTR = balance + " Days";

                let color = "";

                if (type === "STR/FC/FH" && (e.flightHours || e.flightHours) && balance <= 20) color = "#E95048";
                else if (type === "STR/FC/FH" && e.calendarDays && balance <= 3) color = "#E95048";
                else if (type === "MEL CAT" && remainingDays <= 3) color = "#E95048";
                else if (type !== "MEL CAT" && type !== "STR/FC/FH" && remainingDays <= 3) color = "#E95048";

                return (
                  <>
                    <Grid item xs={13}>
                      <Grid
                        container
                        columns={13}
                        className={`${styles.lineItems} ${activeIndex === index ? styles.selectedIndex : ""}`}
                        sx={{ zIndex: 1 }}
                      >
                        <Grid item xs={0.4} className={`${styles.tableBody}`} sx={{ zIndex: 3, paddingTop: "0px" }}>
                          <CheckBoxField
                            id={e._id}
                            selected={selectToMove}
                            setSelected={setSelectToMove}
                            disabled={summaryPermission.some((perm) => perm.canUpdate === true) ? false : true}
                            marginTop="6px"
                          />
                        </Grid>
                        <Grid item xs={0.5} className={`${styles.tableBody}`}>
                          <LightTooltip title={"Set priority"} followCursor placement="bottom-start">
                            <img
                              src={e.priority ? IconFlagActive : IconFlag}
                              alt="icon-flag"
                              height={18}
                              style={{ marginRight: "4px", marginTop: "1px" }}
                              onClick={() => handleSetPriority(e._id)}
                            />
                          </LightTooltip>
                          <LightTooltip
                            title={e.limitations ? "Limitation triggered" : ""}
                            followCursor
                            placement="bottom-start"
                          >
                            {e.limitations && (
                              <img
                                src={IconAirplaneLimitation}
                                alt={"warning-circle"}
                                height={20}
                                style={{ marginTop: "-1px" }}
                              />
                            )}
                          </LightTooltip>
                          <LightTooltip
                            title={e.reInspection ? "Reinspection" : ""}
                            followCursor
                            placement="bottom-start"
                          >
                            {e.reInspection && (
                              <img
                                src={IconReInspection}
                                alt={"icon-reinspection"}
                                height={20}
                                style={{ marginLeft: "4px" }}
                              />
                            )}
                          </LightTooltip>
                        </Grid>
                        <Grid
                          item
                          xs={0.7}
                          className={`${styles.tableBody} ${styles.toUpperCase}`}
                          onClick={() => handleClick(e, index)}
                        >
                          {e.location || "-"}
                        </Grid>
                        <Grid
                          item
                          xs={0.9}
                          className={`${styles.tableBody} ${styles.toUpperCase}`}
                          onClick={() => handleClick(e, index)}
                        >
                          {e.aircraftReg || "-"}
                        </Grid>
                        {value !== "3" && (
                          <>
                            <Grid
                              item
                              xs={2.5}
                              className={`${styles.tableBody} ${styles.toUpperCase}`}
                              sx={{ display: "flex", justifyContent: "flex-start" }}
                              onClick={() => handleClick(e, index)}
                            >
                              <LightTooltip title={e.defectDesc} followCursor placement="bottom-start">
                                <span className={classes.gridItem}>{e.defectDesc || "-"}</span>
                              </LightTooltip>
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              className={`${styles.tableBody} ${styles.toUpperCase}`}
                              onClick={() => handleClick(e, index)}
                            >
                              {e.ddmlNo || "-"}
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              className={`${styles.tableBody} ${styles.toUpperCase}`}
                              onClick={() => handleClick(e, index)}
                            >
                              {e.melRef && (
                                <center>
                                  {e.melRef}{" "}
                                  {e.formType === "MEL CAT" && (
                                    <>
                                      <br /> {"(" + e.melCatType + ")"}
                                    </>
                                  )}
                                </center>
                              )}
                              {!e.melRef && "-"}
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              className={`${styles.tableBody}`}
                              sx={{ display: "flex", justifyContent: "center" }}
                              onClick={() => handleClick(e, index)}
                            >
                              {formatDateDasr(e.raisedDate)}
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              className={`${styles.tableBody}`}
                              sx={{
                                color: color,
                                display: "flex",
                                justifyContent: "center",
                              }}
                              onClick={() => handleClick(e, index)}
                            >
                              <center>
                                {e.formType === "STR/FC/FH"
                                  ? balance + fcfhIndicator
                                  : e.formType === "MEL CAT"
                                  ? formatDateDasr(e.expiryDate)
                                  : calculateCalendarExpiryDate(e.calendarDaysStartDate, e.calendarDaysInterval)}
                              </center>
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              className={`${styles.tableBody} ${styles.toUpperCase}`}
                              onClick={() => handleClick(e, index)}
                            >
                              {e.assignedTo !== "" && e.assignedTo !== null ? (
                                <Avatar {...stringAvatar(e.assignedTo)} />
                              ) : (
                                "-"
                              )}
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              className={`${styles.tableBody} ${styles.toUpperCase}`}
                              sx={{
                                display: "flex",
                                position: "relative",
                                justifyContent:
                                  getRectificationStatus(e.statusLog) || e.actionBy145 ? "flex-start" : "center",
                              }}
                              onClick={() => handleClick(e, index)}
                            >
                              <LightTooltip
                                title={getRectificationStatus(e.statusLog) || e.actionBy145}
                                followCursor
                                placement="bottom-start"
                              >
                                <span className={classes.gridItem}>
                                  {getRectificationStatus(e.statusLog) || e.actionBy145 || "-"}
                                </span>
                              </LightTooltip>
                              <IconUnreadIndicator data={e} />
                            </Grid>
                          </>
                        )}
                        {value === "3" && (
                          <>
                            <Grid
                              item
                              xs={3.5}
                              className={`${styles.tableBody} ${styles.toUpperCase}`}
                              sx={{ display: "flex", justifyContent: "flex-start" }}
                              onClick={() => handleClick(e, index)}
                            >
                              <LightTooltip title={e.defectDesc} followCursor placement="bottom-start">
                                <span className={classes.gridItem}>{e.defectDesc || "-"}</span>
                              </LightTooltip>
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              className={`${styles.tableBody} ${styles.toUpperCase}`}
                              onClick={() => handleClick(e, index)}
                            >
                              {e.status || "-"}
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              className={`${styles.tableBody}`}
                              sx={{ display: "flex", justifyContent: "center" }}
                              onClick={() => handleClick(e, index)}
                            >
                              {formatDateDasr(e.raisedDate)}
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              className={`${styles.tableBody}`}
                              sx={{ display: "flex", justifyContent: "center" }}
                              onClick={() => handleClick(e, index)}
                            >
                              {formatDateDasr(e.ets) || "-"}
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              className={`${styles.tableBody} ${styles.toUpperCase}`}
                              sx={{ display: "flex", justifyContent: "flex-start", position: "relative" }}
                              onClick={() => handleClick(e, index)}
                            >
                              <LightTooltip title={e.rectificationStatus} followCursor placement="bottom-start">
                                <span className={classes.gridItem}>{e.rectificationStatus}</span>
                              </LightTooltip>
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              className={`${styles.tableBody} ${styles.toUpperCase}`}
                              sx={{
                                display: "flex",
                                justifyContent: e.remarks ? "flex-start" : "-",
                                position: "relative",
                              }}
                              onClick={() => handleClick(e, index)}
                            >
                              <LightTooltip title={e.remarks} followCursor placement="bottom-start">
                                <span className={classes.gridItem}>{e.remarks || "-"}</span>
                              </LightTooltip>
                              <IconUnreadIndicator data={e} />
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </>
                );
              })}
            <Grid item xs={13} className={`${styles.tableBodyWhite}`}>
              <MUIPagination
                page={parseInt(page)}
                setPage={setPage}
                setPerPage={setPerPage}
                totalPage={parseInt(totalPages)}
                classes={{ ul: classes.ul }}
                totalRows={parseInt(totalRows)}
                sliceStart={sliceStart}
                initialPerPage={perPage}
              />
            </Grid>
          </Grid>
        </div>
      </>
    ) : data.length === 0 ? (
      <ErrorAirBallon />
    ) : (
      <NoData />
    );
  };

  function getRectificationStatus(item) {
    if (item !== undefined && item !== null) {
      if (item?.pushToSummary === "") return undefined;
      else {
        return item?.updateLogDesc;
      }
    }
  }

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string?.length; i += 1) {
      hash = string?.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return (
      name !== undefined &&
      name !== "" && {
        sx: {
          bgcolor: stringToColor(name),
          fontSize: "12px",
        },
        // children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        children: `${name?.substring(0, 3)}`,
      }
    );
  }

  function calcTipsCount(type) {
    let data_ = [];
    if (type === "DDML") data_ = filteredData?.filter((e) => e.moveToAssignment === true && e.formStatus === "open");
    if (type === "Additional Task") data_ = adhocList?.filter((e) => e.formStatus === "open");
    // let sum = 0;
    // eslint-disable-next-line
    // data_?.map((e) => {
    //   // Calculate the date difference
    //   var currentDate = new Date();
    //   var expiryDate = new Date(e.expiryDate);
    //   var timeDifferenceMs = expiryDate - currentDate;
    //   var remainingDays = Math.ceil(timeDifferenceMs / (1000 * 60 * 60 * 24));
    //   if (remainingDays <= 3) sum++;
    // });
    return data_?.length || 0;
  }

  return !isLoading && !subLoading ? (
    <>
      <div id="main" className={`${styles.container}`}>
        <>
          <Grid container columns={8.2} className={styles.gridContainer}>
            <Grid item xs={4.2} className={styles.gridTitle}>
              Assignment drafts
            </Grid>
            <Grid item xs={4} className={styles.gridJustifyEnd} sx={{ fontSize: "14px", paddingBottom: "16px" }}>
              {/* <DateToggle date={date} setDate={setDate} today={today} setToday={setToday} /> */}
            </Grid>
            <Grid item xs={8.2} sx={{ paddingTop: "10px" }}>
              <TabContext value={value}>
                <TabList onChange={handleChange} aria-label="lab API tabs example" onClick={() => setOpenModal(false)}>
                  <AntTab
                    label="DDML"
                    value="1"
                    icon={<Tips val={isCountDDML} unread={isCountDDML > 0 ? true : false} />}
                    iconPosition="end"
                  />
                  <AntTab
                    label="Repetitive Defect"
                    value="2"
                    icon={<Tips val={isCountRDD} unread={isCountRDD > 0 ? true : false} />}
                    iconPosition="end"
                  />
                  <AntTab
                    label="Additional Task"
                    value="3"
                    icon={<Tips val={isCountAdhoc} unread={isCountAdhoc > 0 ? true : false} />}
                    iconPosition="end"
                  />
                </TabList>
                <div
                  style={{
                    width: "100%",
                    height: "2px",
                    backgroundColor: "#d1d1d1",
                    marginTop: "-2px",
                  }}
                ></div>
                <div
                  style={{
                    marginLeft: "-20px",
                    marginRight: "-20px",
                    marginTop: "-24px",
                  }}
                >
                  <TabPanel value="1">
                    {data?.length > 0 ? <AircraftReportList type={"DDML"} /> : <ErrorAirBallon />}
                  </TabPanel>
                  <TabPanel value="2">
                    {data?.length > 0 ? <AircraftReportList type={"Repetitive Defect"} /> : <ErrorAirBallon />}
                  </TabPanel>
                  <TabPanel value="3">{<AircraftReportList type={"Additional Task"} />}</TabPanel>
                </div>
              </TabContext>
            </Grid>
          </Grid>
        </>
      </div>
      {value === "1" && (
        <ElevadeDrawer
          open={open}
          setOpen={setOpen}
          data={data}
          Component={DDML}
          aoc={aoc}
          type={type}
          index={index}
          dataForm={dataForm}
          edit={edit}
          setEdit={setEdit}
          openNotification={openNotification}
          setOpenNotification={setOpenNotification}
          newForm={newForm}
          setNewForm={setNewForm}
          triggerApi={triggerApi}
          setTriggerApi={setTriggerApi}
          setSelectToMove={setSelectToMove}
          setOpenDialogMoveToAssignment={setOpenDialogMoveToAssignment}
        />
      )}
      <DialogPush145
        aoc={aoc}
        open={openDialogMoveToAssignment}
        setOpen={setOpenDialogMoveToAssignment}
        data={selectToMove}
        setTriggerApi={setTriggerApi}
        setData={setSelectToMove}
        setCheckAll={setCheckAll}
        tab={value}
      />
      <DialogDelete
        open={openDialogDelete}
        setOpen={setOpenDialogDelete}
        data={selectToMove}
        setTriggerApi={setTriggerApi}
        setData={setSelectToMove}
        formType={value === "1" ? "ddml" : value === "2" ? "rdd" : value === "3" ? "adhoc" : ""}
      />
      {value === "3" && (
        <>
          <ElevadeDrawer
            open={openCreateAdhoc}
            setOpen={setOpenCreateAdhoc}
            data={dataForm}
            dataForm={dataForm}
            Component={edit ? AdditionalTaskForm : AdditionalTaskSummary}
            aoc={aoc}
            edit={edit}
            setEdit={setEdit}
            updateArrayColumn={updateArrayColumn}
            newForm={newForm}
            setTriggerApi={setTriggerApi}
            aircraftList={aircraftList}
            setOpenDialogMoveToAssignment={setOpenDialogMoveToAssignment}
            top={"60px"}
            dataList={adhocList}
          />
          {/* <ElevadeDrawer
            open={openAdhoc}
            setOpen={setOpenAdhoc}
            data={dataForm}
            Component={AdditionalTaskSummary}
            aoc={aoc}
            edit={edit}
            setEdit={setEdit}
            updateArrayColumn={updateArrayColumn}
            newForm={newForm}
            setTriggerApi={setTriggerApi}
            aircraftList={aircraftList}
            setOpenDialogMoveToAssignment={setOpenDialogMoveToAssignment}
            top={"60px"}
          /> */}
        </>
      )}
    </>
  ) : (
    <AirplaneLoader />
  );
};

export default Assignment;
