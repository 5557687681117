import React from "react";
import { Grid } from "@mui/material";
import styles from "../Dasr.module.css";

const DDMLSummary = ({ data, aoc }) => {
  const STN = ["KUL", "BKI", "KCH", "JHB", "PEN"];

  function calcByLocation(loc, category) {
    return category !== "TOTAL"
      ? data?.filter((e) => e.formType === category && e.formStatus === "open" && e.location === loc).length
      : data?.filter((e) => e.formStatus === "open" && e.location === loc).length;
  }

  function calcByCategory(category) {
    return category !== "TOTAL"
      ? data?.filter((e) => e.formType === category && e.formStatus === "open").length
      : data?.filter((e) => e.formStatus === "open").length;
  }

  return (
    <>
      <div className={styles.divCard}>
        <Grid container columns={2}>
          <Grid item xs={1} className={styles.gridTitle} style={{ paddingBottom: "24px" }}>
            DDML Summary
          </Grid>
          <Grid item xs={2}>
            {/* <Grid container columns={1} sx={{ paddingBottom: "16px" }}>
              <Grid item xs={1} sx={{ fontSize: "12px", fontStyle: "italic", color: "#818181" }}>
                No data found
              </Grid>
            </Grid> */}
            <div className={styles.tableDiv}>
              <Grid container columns={5}>
                <Grid item xs={1} className={styles.tableHeader}>
                  STN
                </Grid>
                <Grid item xs={1} className={styles.tableHeader}>
                  CAT
                </Grid>
                <Grid item xs={1} className={styles.tableHeader}>
                  AMD
                </Grid>
                <Grid item xs={1} className={styles.tableHeader}>
                  STR/FC/FH
                </Grid>
                <Grid item xs={1} className={styles.tableHeader}>
                  TOTAL
                </Grid>
                <Grid container columns={5}>
                  {STN.map((e, index) => {
                    return (
                      <>
                        <Grid
                          item
                          xs={1}
                          className={`${styles.tableBody}`}
                          sx={{ fontFamily: "Gotham-Medium" }}
                          key={index}
                        >
                          {e}
                        </Grid>
                        <Grid item xs={1} className={`${styles.tableBody}`} key={index + 1}>
                          {calcByLocation(e, "MEL CAT") || 0}
                        </Grid>
                        <Grid item xs={1} className={`${styles.tableBody}`} key={index + 2}>
                          {calcByLocation(e, "AMD") || 0}
                        </Grid>
                        <Grid item xs={1} className={`${styles.tableBody}`} key={index + 3}>
                          {calcByLocation(e, "STR/FC/FH") || 0}
                        </Grid>
                        <Grid item xs={1} className={`${styles.tableBody}`} key={index + 4}>
                          {calcByLocation(e, "TOTAL") || 0}
                        </Grid>
                      </>
                    );
                  })}
                  <Grid
                    item
                    xs={1}
                    className={`${styles.tableFooter}`}
                    sx={{ fontFamily: "Gotham-Medium", padding: "0px 10px" }}
                  >
                    TOTAL DDML ({`${aoc}`})
                  </Grid>
                  <Grid item xs={1} className={`${styles.tableFooter}`}>
                    {calcByCategory("MEL CAT") || 0}
                  </Grid>
                  <Grid item xs={1} className={`${styles.tableFooter}`}>
                    {calcByCategory("AMD") || 0}
                  </Grid>
                  <Grid item xs={1} className={`${styles.tableFooter}`}>
                    {calcByCategory("STR/FC/FH") || 0}
                  </Grid>
                  <Grid item xs={1} className={`${styles.tableFooter}`}>
                    {calcByCategory("TOTAL") || 0}
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default DDMLSummary;
