
import React from 'react';
import { Box, Typography, Skeleton } from "@mui/material";
import { gothamBold } from "../../../../constants/fonts";
export function ActiveWidget({
	active,
	total,
	isLoadingActive,
	isLoadingTotal,
}) {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				paddingLeft: "10px",
			}}
		>
			<Box
				sx={{
					paddingTop: "1.2em",
					paddingRight: "10px",
				}}
			>
				<svg
					width="31"
					height="30"
					viewBox="0 0 31 30"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M7.06931 0.5H23.2277C26.8605 0.5 29.797 3.41478 29.797 7V23C29.797 26.5852 26.8605 29.5 23.2277 29.5H7.06931C3.43652 29.5 0.5 26.5852 0.5 23V7C0.5 3.41478 3.43652 0.5 7.06931 0.5Z"
						fill="url(#paint0_linear_4_45)"
						stroke="#E8E8E8"
					/>
					<path
						d="M14.873 22.4648C19.0152 22.4648 22.373 19.107 22.373 14.9648C22.373 10.8227 19.0152 7.46484 14.873 7.46484C10.7309 7.46484 7.37305 10.8227 7.37305 14.9648C7.37305 19.107 10.7309 22.4648 14.873 22.4648Z"
						stroke="#394458"
						stroke-width="1.2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M14.873 18.0898C16.5989 18.0898 17.998 16.6907 17.998 14.9648C17.998 13.239 16.5989 11.8398 14.873 11.8398C13.1472 11.8398 11.748 13.239 11.748 14.9648C11.748 16.6907 13.1472 18.0898 14.873 18.0898Z"
						stroke="#394458"
						stroke-width="1.2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M12.6621 12.7539L9.56836 9.66016"
						stroke="#394458"
						stroke-width="1.2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M17.084 12.7539L20.1777 9.66016"
						stroke="#394458"
						stroke-width="1.2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M17.084 17.1758L20.1777 20.2695"
						stroke="#394458"
						stroke-width="1.2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M12.6621 17.1758L9.56836 20.2695"
						stroke="#394458"
						stroke-width="1.2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<defs>
						<linearGradient
							id="paint0_linear_4_45"
							x1="15.1485"
							y1="0"
							x2="15.1485"
							y2="30"
							gradientUnits="userSpaceOnUse"
						>
							<stop stop-color="#FCFFFD" />
							<stop offset="1" stop-color="#F2FAFC" />
						</linearGradient>
					</defs>
				</svg>
			</Box>
			<Box
				sx={{
					padding: "1em",
					paddingLeft: "0px",
					display: "flex",
					flexDirection: "row",
					alignItems: "flex-end",
				}}
			>
				<Box>
					<Typography
						fontFamily={gothamBold}
						fontWeight="bold"
						fontSize="16px"
						sx={{ textAlign: "left" }}
					>
						{isLoadingActive && (
							<Skeleton variant="text" animation="wave" />
						)}
						{!isLoadingActive && (active?.toLocaleString() ?? 0)}
					</Typography>
					<Typography
						color="#333333"
						fontSize="16px"
						sx={{ textAlign: "left" }}
					>
						Active
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						paddingLeft: "2em",
						paddingRight: "1em",
					}}
				>
					<Typography sx={{ textAlign: "left" }}>
						{isLoadingTotal && (
							<Skeleton
								variant="text"
								animation="wave"
								width="24px"
							/>
						)}
						{!isLoadingTotal && (total?.toLocaleString() ?? 0)}
					</Typography>
					<Typography
						color="#333333"
						fontSize="16px"
						sx={{ textAlign: "left" }}
					>
						Total
					</Typography>
				</Box>
			</Box>
		</Box>
	);
}
export function MissingWidget({ value, isLoading }) {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				gap: "1em",
			}}
		>
			<Box
				sx={{
					paddingTop: "0.3em",
				}}
			>
				<svg
					width="20"
					height="20"
					viewBox="0 0 20 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M15.3333 0H4.66667C2.08934 0 0 2.08934 0 4.66667V15.3333C0 17.9107 2.08934 20 4.66667 20H15.3333C17.9107 20 20 17.9107 20 15.3333V4.66667C20 2.08934 17.9107 0 15.3333 0Z"
						fill="#E95048"
					/>
					<path
						d="M4.86719 8.76302C5.09486 7.91654 5.54018 7.14441 6.15885 6.52344"
						stroke="white"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M6.15885 13.5964C5.53802 12.975 5.09243 12.2006 4.86719 11.3516"
						stroke="white"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M10.9928 14.8828C10.1455 15.1145 9.25159 15.1145 8.4043 14.8828"
						stroke="white"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M14.5241 11.3516C14.2964 12.198 13.8511 12.9702 13.2324 13.5911"
						stroke="white"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M13.2324 6.52344C13.8533 7.14482 14.2988 7.91922 14.5241 8.76823"
						stroke="white"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M8.39844 5.22848C9.24573 4.99676 10.1397 4.99676 10.987 5.22848"
						stroke="white"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
				}}
			>
				<Typography
					color="#333333"
					fontSize="16px"
					sx={{ textAlign: "left", marginRight: "12px" }}
				>
					Missing
				</Typography>
				<Typography
					fontFamily={gothamBold}
					fontWeight="bold"
					fontSize="16px"
					sx={{ textAlign: "left" }}
				>
					{isLoading && <Skeleton variant="text" animation="wave" />}
					{!isLoading &&
						((value?.toLocaleString && value?.toLocaleString()) ??
							0)}
				</Typography>
			</Box>
		</Box>
	);
}
export function ErrorWidget({ value, isLoading }) {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				gap: "1em",
			}}
		>
			<Box
				sx={{
					paddingTop: "0.3em",
				}}
			>
				<svg
					width="21"
					height="20"
					viewBox="0 0 21 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M15.5833 0H4.91667C2.33934 0 0.25 2.08934 0.25 4.66667V15.3333C0.25 17.9107 2.33934 20 4.91667 20H15.5833C18.1607 20 20.25 17.9107 20.25 15.3333V4.66667C20.25 2.08934 18.1607 0 15.5833 0Z"
						fill="#F5BE59"
					/>
					<path
						d="M10.2705 7.3648V10.0904M13.2085 13.8948L15.7308 11.2215C16.3007 10.6175 16.3007 9.67415 15.7308 9.07016L11.4113 4.49201C10.7923 3.836 9.7487 3.836 9.12977 4.49201L4.81021 9.07016C4.24035 9.67415 4.24035 10.6175 4.81021 11.2215L9.12975 15.7997C9.7487 16.4557 10.7923 16.4557 11.4112 15.7997L11.4206 15.7897"
						stroke="#333333"
						stroke-width="1.1"
						stroke-miterlimit="10"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<circle
						cx="10.2487"
						cy="11.9987"
						r="0.666667"
						fill="#333333"
					/>
				</svg>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
				}}
			>
				<Typography
					color="#333333"
					fontSize="16px"
					sx={{ textAlign: "left", marginRight: "12px" }}
				>
					Error
				</Typography>
				<Typography
					fontFamily={gothamBold}
					fontWeight="bold"
					fontSize="16px"
					sx={{ textAlign: "left" }}
				>
					{isLoading && <Skeleton variant="text" animation="wave" />}
					{!isLoading &&
						((value?.toLocaleString && value?.toLocaleString()) ??
							0)}
				</Typography>
			</Box>
		</Box>
	);
}
export function ExpiredWidget({ value, isLoading }) {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				gap: "1em",
			}}
		>
			<Box
				sx={{
					paddingTop: "0.3em",
				}}
			>
				<svg
					width="21"
					height="20"
					viewBox="0 0 21 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M15.8333 0H5.16667C2.58934 0 0.5 2.08934 0.5 4.66667V15.3333C0.5 17.9107 2.58934 20 5.16667 20H15.8333C18.4107 20 20.5 17.9107 20.5 15.3333V4.66667C20.5 2.08934 18.4107 0 15.8333 0Z"
						fill="#AA33FF"
					/>
					<path
						d="M14.6875 5.15625H6.35417C6.12405 5.15625 5.9375 5.3428 5.9375 5.57292V13.9062C5.9375 14.1364 6.12405 14.3229 6.35417 14.3229H14.6875C14.9176 14.3229 15.1042 14.1364 15.1042 13.9062V5.57292C15.1042 5.3428 14.9176 5.15625 14.6875 5.15625Z"
						stroke="white"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M13.0195 4.32031V5.98698"
						stroke="white"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M8.01953 4.32031V5.98698"
						stroke="white"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M5.9375 7.65625H15.1042"
						stroke="white"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M11.7695 9.73438L9.26953 12.2344"
						stroke="white"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M11.7695 12.2344L9.26953 9.73438"
						stroke="white"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
				}}
			>
				<Typography
					color="#333333"
					fontSize="16px"
					sx={{ textAlign: "left", marginRight: "12px" }}
				>
					Expired
				</Typography>
				<Typography
					fontFamily={gothamBold}
					fontWeight="bold"
					fontSize="16px"
					sx={{ textAlign: "left" }}
				>
					{isLoading && <Skeleton variant="text" animation="wave" />}
					{!isLoading &&
						((value?.toLocaleString && value?.toLocaleString()) ??
							0)}
				</Typography>
			</Box>
		</Box>
	);
}
export function NearExpiryWidget({ value, isLoading }) {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				gap: "1em",
			}}
		>
			<Box
				sx={{
					paddingTop: "0.3em",
				}}
			>
				<svg
					width="21"
					height="20"
					viewBox="0 0 21 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M16.0833 0H5.41667C2.83934 0 0.75 2.08934 0.75 4.66667V15.3333C0.75 17.9107 2.83934 20 5.41667 20H16.0833C18.6607 20 20.75 17.9107 20.75 15.3333V4.66667C20.75 2.08934 18.6607 0 16.0833 0Z"
						fill="#3A32E0"
					/>
					<path
						d="M15.2266 5.39844H6.89323C6.66311 5.39844 6.47656 5.58499 6.47656 5.8151V14.1484C6.47656 14.3786 6.66311 14.5651 6.89323 14.5651H15.2266C15.4567 14.5651 15.6432 14.3786 15.6432 14.1484V5.8151C15.6432 5.58499 15.4567 5.39844 15.2266 5.39844Z"
						stroke="white"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M13.5586 4.5625V6.22917"
						stroke="white"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M8.55859 4.5625V6.22917"
						stroke="white"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M6.47656 7.89844H15.6432"
						stroke="white"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M10.9492 9.33594L10.9492 11.3359"
						stroke="white"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M10.9492 12.6719L10.9492 13.0052"
						stroke="white"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
				}}
			>
				<Typography
					color="#333333"
					fontSize="16px"
					sx={{ textAlign: "left", marginRight: "12px" }}
				>
					Near Expiry
				</Typography>
				<Typography
					fontFamily={gothamBold}
					fontWeight="bold"
					fontSize="16px"
					sx={{ textAlign: "left" }}
				>
					{isLoading && <Skeleton variant="text" animation="wave" />}
					{!isLoading &&
						((value?.toLocaleString && value?.toLocaleString()) ??
							0)}
				</Typography>
			</Box>
		</Box>
	);
}
export function UnutilizedWidget({ value, isLoading }) {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				gap: "1em",
			}}
		>
			<Box
				sx={{
					paddingTop: "0.3em",
				}}
			>
				<svg
					width="20"
					height="20"
					viewBox="0 0 20 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M15.3333 0H4.66667C2.08934 0 0 2.08934 0 4.66667V15.3333C0 17.9107 2.08934 20 4.66667 20H15.3333C17.9107 20 20 17.9107 20 15.3333V4.66667C20 2.08934 17.9107 0 15.3333 0Z"
						fill="#D1D1D1"
					/>
					<g clip-path="url(#clip0_19548_84513)">
						<path
							d="M5.38578 5.42094L14.7548 14.79M5.69531 5.29297H14.4453C14.6869 5.29297 14.8828 5.48884 14.8828 5.73047V14.4805C14.8828 14.7221 14.6869 14.918 14.4453 14.918H5.69531C5.45369 14.918 5.25781 14.7221 5.25781 14.4805V5.73047C5.25781 5.48884 5.45369 5.29297 5.69531 5.29297Z"
							stroke="#333333"
							stroke-width="1.1"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</g>
					<defs>
						<clipPath id="clip0_19548_84513">
							<rect
								width="14"
								height="14"
								fill="white"
								transform="translate(3.07031 3.10547)"
							/>
						</clipPath>
					</defs>
				</svg>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
				}}
			>
				<Typography
					color="#333333"
					fontSize="16px"
					sx={{ textAlign: "left", marginRight: "12px" }}
				>
					Unutilised Seat
				</Typography>
				<Typography
					fontFamily={gothamBold}
					fontWeight="bold"
					fontSize="16px"
					sx={{ textAlign: "left" }}
				>
					{isLoading && <Skeleton variant="text" animation="wave" />}
					{!isLoading &&
						((value?.toLocaleString && value?.toLocaleString()) ??
							0)}
				</Typography>
			</Box>
		</Box>
	);
}
