
import React from 'react';
import { Typography } from "@mui/material";
export default function PageTitle({ title, style, startAction }) {
	return (
		<Typography
			variant="h5"
			sx={{
				fontWeight: "bold",
				flexGrow: 1,
				display: "flex",
				flexDirection: "row",
				// justifyContent:'center',
				alignItems: "center",
				fontSize: "16px",
				...style,
			}}
		>
			{startAction && startAction}
			{title}
		</Typography>
	);
}
