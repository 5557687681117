import { useForm } from "react-hook-form";
import { Modal, ModalBody } from "reactstrap";
import React, { Fragment, useEffect, useState } from "react";

import { makeAPIRequestByApiKey, showNotification } from "common/helpers";
import { AdeButton, AdeSelect } from "common/components";
import data from "common/resources/countries.json";

import Spinner from "layouts/Spinner";
import styles from "./Signup.module.css";
import Icon_Success from "common/images/aerotrade/Icon_Success.svg";
import { useMediaQuery } from "@mui/material";
import _ from "lodash";

const Signup = ({ isModalOpen, setModalOpen }) => {
  const defaultCompanyDetails = {
    category: "",
    companyName: "",
    country: "",
    createdBy: {
      userName: "",
    },
    email: "",
    phoneNumber: "",
  };

  const [companyDetails, setCompanyDetails] = useState(defaultCompanyDetails);
  const [isLoading, setIsLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [agree, setAgree] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({ mode: "all" });

  const showModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setCompanyDetails(defaultCompanyDetails);
    reset(defaultCompanyDetails);
    setAgree(false);
  };

  const closeSuccessModal = () => {
    setShowSuccessModal(false);
    closeModal();
  };

  const countryOptionsList = data.countries.map(({ country }) => ({
    code: country,
    name: country,
  }));

  const categoryOpts = [
    { code: "Agent", name: "Agent" },
    { code: "Distributor", name: "Distributor" },
    { code: "Stokist", name: "Stokist" },
    { code: "Operator", name: "Operator" },
    { code: "MRO", name: "MRO" },
    { code: "OEM", name: "OEM" },
  ];

  const onSubmitClick = async () => {
    try {
      setIsLoading(true);
      const currencyList = data.countries.find(({ country }) => country === companyDetails.country);
      const body = { ...companyDetails, currency: currencyList.currency };

      const result = await makeAPIRequestByApiKey("post", "company/new", body);
      if (!result.error) {
        setShowSuccessModal(true);
        closeModal();
        setIsLoading(false);
      } else {
        setAgree(false);
        setValue("agree", false);
        setIsLoading(false);
      }
    } catch (error) {
      setAgree(false);
      setIsLoading(false);
      showNotification("danger", "Send General Query", ["There was some issue in signing up. Please try again later."]);
      setValue("agree", false);
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      showModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  useEffect(() => {
    //disable submit button
    if (
      !companyDetails.createdBy.userName ||
      !companyDetails.companyName ||
      !companyDetails.email ||
      !companyDetails.category ||
      !companyDetails.phoneNumber ||
      !companyDetails.country ||
      !agree ||
      !_.isEmpty(errors)
    ) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }
  }, [companyDetails, agree, errors]);

  const isMobile = useMediaQuery("(max-width:991px)");

  return (
    <Fragment>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <React.Fragment>
        <Modal
          fade={true}
          isOpen={isModalOpen}
          autoFocus
          centered
          size="lg"
          backdrop={true}
          keyboard={false}
          onClosed={closeModal}
          toggle={closeModal}
          contentClassName={`${styles.adeSignupModal}`}
        >
          {isLoading ? (
            <div className={`card-body ${styles.spinnerCard}`}>
              <Spinner />
            </div>
          ) : (
            <div>
              <ModalBody className={`${styles.adeSignupModal}`} style={{ padding: "32px", overflow: "auto" }}>
                <div style={{ paddingBottom: "30px" }}>
                  <div className={`${styles.adeSignupHeader}`}>
                    Ready for liftoff? {isMobile ? <br /> : ""}Register now!
                  </div>
                  <div style={{ fontFamily: "Gotham-Book", fontSize: "14px" }}>
                    All fields marked with an asterisk (<span className={`${styles.asterisk}`}>*</span>) are mandatory
                  </div>
                </div>

                <form style={{ paddingBottom: "10px" }} onSubmit={handleSubmit(onSubmitClick)}>
                  <div className={`row form-group ${styles.form}`}>
                    <div className={`col-lg-3 gotham-medium ${styles.formLabel}`}>
                      <label htmlFor="nameLabel" className="col-form-label input-label text-black">
                        Full Name<span className={`${styles.asterisk}`}>*</span>
                      </label>
                    </div>
                    <input
                      {...register("createdBy.userName", {
                        required: true,
                        maxLength: 50,
                        pattern: /^[A-Za-z\d\-_\s]+$/i,
                      })}
                      className={`form-control col-lg-8 ml-lg-2 ${styles.formText} ${
                        errors?.createdBy?.userName ? styles.inputError : styles.formInput
                      }`}
                      placeholder="Full Name"
                      onChange={(e) => {
                        setCompanyDetails({
                          ...companyDetails,
                          createdBy: {
                            ...companyDetails.createdBy,
                            userName: e.target.value,
                          },
                        });
                      }}
                    />
                    <div className={`col-lg-7 offset-lg-3 ${styles.error}`}>
                      {errors?.createdBy?.userName?.type === "required" && <p>This field is required</p>}
                      {errors?.createdBy?.userName?.type === "maxLength" && (
                        <p>Full name cannot exceed 50 characters</p>
                      )}
                      {errors?.createdBy?.userName?.type === "pattern" && <p>Alphabetical characters only</p>}
                    </div>
                  </div>
                  <div className={`row form-group ${styles.form}`}>
                    <div className={`col-lg-3 gotham-medium ${styles.formLabel}`}>
                      <label htmlFor="companyLabel" className="col-form-label input-label text-black">
                        Company Name<span className={`${styles.asterisk}`}>*</span>
                      </label>
                    </div>
                    <input
                      {...register("companyName", {
                        required: true,
                        maxLength: 50,
                        //pattern: /^[A-Za-z.,'()&\d\-_\s]*$/i,
                        // eslint-disable-next-line
                        pattern: /^[a-zA-Z0-9 \.\,\'&()_\-]*$/i,
                      })}
                      className={`form-control col-lg-8 ml-lg-2 ${styles.formText} ${
                        errors?.companyName ? styles.inputError : styles.formInput
                      }`}
                      placeholder="Company Name"
                      onChange={(e) => {
                        setCompanyDetails({
                          ...companyDetails,
                          companyName: e.target.value,
                        });
                      }}
                    />
                    <div className={`col-lg-7 offset-lg-3 ${styles.error}`}>
                      {errors?.companyName?.type === "required" && <p>This field is required</p>}
                      {errors?.companyName?.type === "maxLength" && <p>Company name cannot exceed 50 characters</p>}
                      {errors?.companyName?.type === "pattern" && <p>Alphabetical characters only</p>}
                    </div>
                  </div>
                  <div className={`row form-group ${styles.form}`}>
                    <div className={`col-lg-3 gotham-medium ${styles.formLabel}`}>
                      <label htmlFor="email" className="col-form-label input-label text-black">
                        Company Email<span className={`${styles.asterisk}`}>*</span>
                      </label>
                    </div>
                    <input
                      {...register("email", {
                        required: true,
                        // eslint-disable-next-line
                        pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,8})+$/,
                      })}
                      className={`form-control col-lg-8 ml-lg-2 ${styles.formTextEmail} ${
                        errors?.email ? styles.inputError : styles.formInput
                      }`}
                      placeholder="example@email.com"
                      onChange={(e) => {
                        setCompanyDetails({
                          ...companyDetails,
                          email: e.target.value.toLowerCase(),
                        });
                      }}
                    />
                    <div className={`col-lg-7 offset-lg-3 ${styles.error}`}>
                      {errors?.email?.type === "required" && <p>This field is required</p>}
                      {errors?.email?.type === "pattern" && <p>Enter a valid email</p>}
                    </div>
                  </div>
                  <div className={`row form-group ${styles.form}`}>
                    <div className={`col-lg-3 gotham-medium ${styles.formLabel}`}>
                      <label htmlFor="catergoryLabel" className="col-form-label input-label text-gray">
                        Company Type<span className={`${styles.asterisk}`}>*</span>
                      </label>
                    </div>
                    <AdeSelect
                      {...register("category", {
                        required: true,
                      })}
                      className={`col-lg-8 ml-lg-2 ${styles.formInput}`}
                      options={categoryOpts}
                      name="category"
                      id="categoryLabel"
                      placeholder="Please Select"
                      value={companyDetails.category}
                      error={errors?.category?.type === "required"}
                      onChange={(e) => {
                        setCompanyDetails({
                          ...companyDetails,
                          category: e.target.value,
                        });
                      }}
                    />
                    <div className={`col-lg-7 offset-lg-3 ${styles.error}`}>
                      {errors?.category?.type === "required" && <p>This field is required</p>}
                    </div>
                  </div>
                  <div className={`row form-group ${styles.form}`}>
                    <div className={`col-lg-3 gotham-medium `}>
                      <label htmlFor="countryLabel" className="col-form-label input-label text-gray">
                        Country of Incorporation<span className={`${styles.asterisk}`}>*</span>
                      </label>
                    </div>
                    <AdeSelect
                      {...register("country", {
                        required: true,
                      })}
                      className={`col-lg-8 ml-lg-2 ${styles.formInput}`}
                      sx={{ borderColor: "red" }}
                      options={countryOptionsList}
                      name="country"
                      id="countryLabel"
                      placeholder="Country"
                      required={true}
                      value={companyDetails.country}
                      error={errors?.country?.type === "required"}
                      onChange={(e) => {
                        setCompanyDetails({
                          ...companyDetails,
                          country: e.target.value,
                        });
                      }}
                    />
                    <div className={`col-lg-7 offset-lg-3 ${styles.error}`}>
                      {errors?.country?.type === "required" && <p>This field is required</p>}
                    </div>
                  </div>
                  <div className={`row form-group ${styles.form}`} style={{ paddingBottom: "20px" }}>
                    <div className={`col-lg-3 gotham-medium ${styles.formLabel}`}>
                      <label htmlFor="phoneNumberLabel" className="col-form-label input-label text-black">
                        Contact Number<span className={`${styles.asterisk}`}>*</span>
                      </label>
                    </div>
                    <div className="col-lg-8 ml-lg-2">
                      <div className="row">
                        <input
                          {...register("phoneNumber", {
                            required: true,
                            minLength: 6,
                            maxLength: 15,
                            pattern: /^[0-9+]+$/i,
                          })}
                          className={`form-control ${styles.formText} ${
                            errors?.phoneNumber ? styles.inputError : styles.formInput
                          } `}
                          type="text"
                          placeholder="+601234567890"
                          onChange={(e) => {
                            setCompanyDetails({
                              ...companyDetails,
                              phoneNumber: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className={`row ${styles.error}`}>
                        <div className="col-12">
                          <span>
                            {errors?.phoneNumber?.type === "required" && <p>This field is required</p>}
                            {errors?.phoneNumber?.type === "maxLength" && <p>Contact number cannot exceed 15 digits</p>}
                            {errors?.phoneNumber?.type === "pattern" && <p>Numerical characters only</p>}
                            {errors?.phoneNumber?.type === "minLength" && (
                              <p>Contact number cannot be less than 6 digits</p>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{ marginTop: "15px" }}>
                    <div className="col-1" style={{ padding: "0px", textAlign: "center" }}>
                      <input
                        style={{ width: "60%", height: "60%", alignProperty: "center" }}
                        {...register("agree", {})}
                        type="checkbox"
                        onChange={(e) => {
                          if (e.currentTarget.checked) {
                            setAgree(true);
                            setValue("agree", true);
                          } else {
                            setAgree(false);
                            setValue("agree", false);
                          }
                        }}
                      ></input>
                    </div>
                    <div className="col-11" style={{ padding: "0px", marginLeft: "-10px" }}>
                      <label className={`${styles.tnc}`}>
                        &nbsp; I have read and agreed to the{" "}
                        <a style={{ color: "#007bff" }} type="link" href="/terms-and-conditions" target="_blank">
                          Terms and Conditions{" "}
                        </a>
                      </label>
                    </div>
                  </div>
                  {isMobile ? (
                    <div className="row">
                      <div className="col-12">
                        <AdeButton
                          fullWidth={true}
                          disabled={submitDisabled}
                          onClick={onSubmitClick}
                          variant="contained"
                        >
                          <i></i> Submit
                        </AdeButton>
                      </div>
                      <div className="col-12">
                        <AdeButton
                          style={{ marginTop: "15px" }}
                          fullWidth={true}
                          onClick={() => {
                            closeModal();
                          }}
                        >
                          <i></i> Cancel
                        </AdeButton>
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-end" style={{ marginTop: "1vw", marginRight: "1vw" }}>
                      <AdeButton
                        style={{ marginRight: "10px" }}
                        fullWidth={false}
                        onClick={() => {
                          closeModal();
                        }}
                      >
                        <i></i> Cancel
                      </AdeButton>
                      {"   "}
                      <AdeButton
                        style={{ marginLeft: "10px" }}
                        fullWidth={false}
                        disabled={submitDisabled}
                        onClick={onSubmitClick}
                        variant="contained"
                      >
                        <i></i> Submit
                      </AdeButton>
                    </div>
                  )}
                </form>
              </ModalBody>
            </div>
          )}
        </Modal>

        <Modal
          fade={true}
          isOpen={showSuccessModal}
          autoFocus
          centered
          size="lg"
          backdrop={true}
          keyboard={false}
          onClosed={closeSuccessModal}
          toggle={closeSuccessModal}
        >
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-12">
              <div className="row text-center mt-4" style={{ display: "flex" }}>
                <div className="col-12">
                  <img src={Icon_Success} alt="Success" />
                </div>
              </div>
              <div className="row text-center mt-4" style={{ display: "flex" }}>
                <div className="col-lg-12 col-sm-8 mx-4">
                  <span style={{ fontFamily: "Gotham-Bold", fontSize: "24" }}>
                    Your information has been submitted successfully.
                  </span>
                </div>
              </div>
              <div className="row text-center mt-1" style={{ display: "flex", paddingBottom: "20px" }}>
                <div className="col-lg-12 col-sm-8 mx-4">
                  <span>Thank you for your interest, our team will get in touch with you soon.</span>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <AdeButton fullWidth={false} onClick={closeSuccessModal}>
                  Return to Homepage
                </AdeButton>
              </div>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    </Fragment>
  );
};
export default Signup;
