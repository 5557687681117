import React from "react";
import { Avatar, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";

const MUIAvatar = ({ ...props }) => {
  const useStyles = makeStyles({
    // Styles for the card view's tooltip
    messageTootltip: {
      background: "#fffac3 0% 0% no-repeat padding-box",
      color: "#333333",
      boxShadow: "0px 3px 6px #00000040",
    },
  });

  const classes = useStyles();

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string?.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    let name_ = name?.replaceAll("(", "")?.replaceAll(")", "");
    return (
      name_ && {
        children: name_?.includes(" ")
          ? `${name_?.split(" ")[0][0]}${name_?.split(" ")[1][0]}`
          : `${name_?.split("")[0][0]}`,
      }
    );
  }

  return (
    <Tooltip title={props.name} placement="bottom-end" classes={{ tooltip: classes.messageTootltip }} followCursor>
      <Avatar
        {...stringAvatar(props.name)}
        src={props.img ? props.img : ""}
        sx={{
          justifyContent: "center",
          display: "flex",
          width: props.width || 24,
          height: props.height || 24,
          fontSize: props.fontsize || 10,
          backgroundColor: props.name && stringToColor(props.name),
          marginRight: props.marginright || "",
        }}
      />
    </Tooltip>
  );
};

export default MUIAvatar;
