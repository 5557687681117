import React from "react";
import "jspdf-autotable";
import { isEmpty } from "lodash";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { getAtaChapter } from "common/helpers";
import ElevadeLogo from "../../../common/images/icons/elevade/PdfHeaderNoBg.jpg";
import Rectangle from "../../../common/images/icons/elevade/Rectangle.jpg";
import PdfHeader from "../../../common/images/icons/elevade/PdfHeader.jpg";
import PdfFooter from "../../../common/images/icons/elevade/PdfFooter.jpg";
import { format, subMinutes } from "date-fns";
import { dateFnsFormats } from "common/constant";
import { formatDate } from "pages/elevade/component/Helpers";
import getTimezoneAbbreviation from "../component/TimeZoneAbbreviation";

// define a generatePDF function that accepts a data argument
export const generateInboxPDF = async (
  tableRows,
  title,
  filters,
  totalRecordsCount,
  tableHeader,
  orientation,
  fileName,
  isSearchInput
) => {
  let height = 10;
  let pdfHeader = [];
  let activeFilters = [];
  let filterRow = [];
  let recordsMessage = "";
  if (totalRecordsCount > 5000)
    recordsMessage = `Maximum of 5,000 / ${totalRecordsCount.toLocaleString()} records displayed`;
  else {
    recordsMessage = `${totalRecordsCount.toLocaleString()} records displayed`;
  }

  const filterHeader = filters?.map((filter) => filter.label);
  // TODO: to check back on this later
  // eslint-disable-next-line no-unused-expressions
  filters?.map((filter) => filter.data)?.forEach((data) => activeFilters?.push(data.toString()));
  // eslint-disable-next-line no-unused-expressions
  filterRow?.push(activeFilters);
  const pdfHeaderData = [new Date().toUTCString(), isSearchInput ?? "-", recordsMessage];
  // eslint-disable-next-line no-unused-expressions
  pdfHeader?.push(pdfHeaderData);
  const tableBody = (height) => {
    const body = {
      startY: height,
      styles: {
        fontSize: 6,
        fontStyle: "normal", // normal, bold, italic, bolditalic
        overflow: "linebreak", // visible, hidden, ellipsize or linebreak
        textColor: 20,
        halign: "left", // left, center, right
        valign: "middle", // top, middle, bottom
      },
      headStyles: { fontStyle: "bold", fillColor: [57, 68, 88], textColor: [255, 255, 255] },
      theme: "grid",
      bodyStyles: { overflow: "linebreak", cellWidth: "auto", valign: "top", halign: "left" },
      columnStyles: {
        2: { cellWidth: 35, overflow: "linebreak" },
        10: { cellWidth: 20, overflow: "linebreak" },
        11: { cellWidth: 25 },
        12: { cellWidth: 25 },
      },
    };
    return body;
  };

  const img = new Image();
  img.src = ElevadeLogo;
  const doc = new jsPDF(orientation);

  img.onload = () => {
    // Create a canvas with higher resolution
    const canvas = document.createElement("canvas");
    canvas.width = img.width * 2;
    canvas.height = img.height * 2;
    const ctx = canvas.getContext("2d");
    //set image quality
    ctx.webkitImageSmoothingEnabled = true;
    ctx.mozImageSmoothingEnabled = true;
    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = "high";

    // Draw the image onto the canvas
    ctx.drawImage(img, 0, 0, img.width * 2, img.height * 2);

    // Get the new image data in base64 format
    const imageData = canvas.toDataURL("image/jpeg", 1.0);

    // Add the image to the PDF document
    doc.addImage(imageData, "JPEG", 15, 10, 42, 6);

    // doc.addImage(ElevadeLogo, "JPEG", 10, 10, 42, 6);

    doc.setFontSize(6);
    doc.setFont(undefined, "bold");
    doc.setCharSpace(0.2);
    doc.text(title, 15, (height = 25));
    doc.autoTable(["Print Time (UTC)", "Keyword Search", "Displayed Record"], pdfHeader, {
      startY: height + 5,
      styles: {
        fontSize: 6,
        lineColor: 255,
        lineWidth: 1,
        fontStyle: "normal", // normal, bold, italic, bolditalic
        overflow: "linebreak", // visible, hidden, ellipsize or linebreak
        textColor: 20,
        halign: "left", // left, center, right
        valign: "middle", // top, middle, bottom
      },
      headStyles: { fontStyle: "bold", fillColor: [255, 255, 255] },
      theme: "grid",
      bodyStyles: { overflow: "linebreak", cellWidth: doc.internal.pageSize.width / 3, valign: "top", halign: "left" },
    });

    if (!isEmpty(filters)) {
      // Set the column width to distribute the available width evenly
      let columnWidth = doc.internal.pageSize.width / filterHeader[0].length;

      height = doc.lastAutoTable.finalY + 5;
      doc.text("ACTIVE FILTERS", 15, (height += 2));
      doc.autoTable(filterHeader, filterRow, {
        startY: (height += 2),
        styles: {
          fontSize: 6,
          lineColor: 255,
          lineWidth: 1,
          fontStyle: "normal", // normal, bold, italic, bolditalic
          overflow: "linebreak", // visible, hidden, ellipsize or linebreak
          textColor: 20,
          halign: "left", // left, center, right
          valign: "middle", // top, middle, bottom
        },
        headStyles: { fontStyle: "bold", fillColor: [255, 255, 255] },
        theme: "grid",
        bodyStyles: { overflow: "linebreak", cellWidth: columnWidth, valign: "top", halign: "left" },
      });
    }

    height = doc.lastAutoTable.finalY + 5;

    //print table
    doc.autoTable(tableHeader, tableRows, tableBody(height));

    //page number
    const pageCount = doc.internal.getNumberOfPages(); //Total Page Number
    for (let i = 0; i < pageCount; i++) {
      doc.setPage(i);
      let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber; //Current Page
      doc.setFontSize(6);
      const textWidth = doc.getTextDimensions("page: " + pageCurrent + "/" + pageCount);
      doc.text(
        "page " + pageCurrent + " of " + pageCount,
        doc.internal.pageSize.width - (textWidth.w + 15),
        doc.internal.pageSize.height - 10
      );
      doc.text(new Date().toUTCString(), 15, doc.internal.pageSize.height - 10);
    }

    //save doc
    doc.save(`${fileName + new Date().toUTCString()}.pdf`);
  };
};

export const generateAircraftStatusPDF = (head, body, imgWidth, imgHeight, aircraft) => {
  let height = 10;

  const tableBody = () => {
    const body = {
      startY: height,
      didDrawPage: function (data) {
        const pageCount = doc.internal.getNumberOfPages();
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

        for (var i = 1; i <= pageCount; i++) {
          // Go to page i
          doc.setPage(i);
          //header
          doc.addImage(ElevadeLogo, "JPEG", 10, 10, 42, 6);
          pdfHeaders();
          //footer
          doc.setFontSize(10);
          // doc.text("Page " + String(i) + " of " + String(pageCount), data.settings.margin.left, pageHeight - 10, {
          //   baseline: "bottom",
          // });
          doc.text("Page " + String(i) + " of " + String(pageCount), 10, pageHeight - 10, {
            baseline: "bottom",
          });
        }
      },
      margin: { top: 70 },
    };
    return body;
  };

  const pdfHeaders = () => {
    doc.setFontSize(14);
    doc.setCharSpace(0.2);
    doc.setTextColor(8, 143, 209);
    doc.text("Fault Journal Summary", 145, 15);
    doc.setDrawColor(232, 232, 232);
    doc.line(10, 20, 200, 20);
    doc.setDrawColor(0, 0, 0);
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(1);
    var getContent = "<div style='font-size:3px;  width:300px;'>" + head.innerHTML + "</div>";
    // var getContentBody = "<div style='font-size:3px;  width:300px;'>" + body.innerHTML + "</div>";

    doc.html(getContent, {
      x: 10,
      y: 25,
      callback: function (doc) {
        html2canvas(body, { scale: "2" }).then((canvas) => {
          const img = canvas.toDataURL("image/jpeg", 0.3);
          // var img = canvas.toDataURL({
          //   format: "jpeg",
          //   quality: 0.2,
          // });
          var pageWidth = doc.internal.pageSize.getWidth();
          var pageHeight = doc.internal.pageSize.getHeight();
          var pageRatio = pageWidth / pageHeight;
          var imgRatio = imgWidth / imgHeight;
          if (imgRatio >= 1) {
            const wc = imgWidth / pageWidth;
            if (imgRatio >= pageRatio) {
              doc.addImage(img, "JPEG", 7, 160 / 2, 195, imgHeight / wc, "FAST");
            } else {
              const pi = pageRatio / imgRatio;
              doc.addImage(img, "JPEG", 7, 160, 195 / pi, imgHeight / pi / wc, "FAST");
            }
          } else {
            const wc = imgWidth / pageHeight;
            if (1 / imgRatio > pageRatio) {
              const ip = 1 / imgRatio / pageRatio;
              // const margin = (pageHeight - imgHeight / ip / wc) / 4;
              doc.addImage(img, "JPEG", 7, 160, pageHeight / ip, imgHeight / ip / wc, -90, "FAST");
            } else {
              doc.addImage(img, "JPEG", 7, 160, pageHeight, imgHeight / wc, -90, "FAST");
            }
          }
          doc.save(`${`Fault-Journal-Summary-${aircraft}-${date}-${time}`}.pdf`);
        });
      },
    });
  };

  const doc = new jsPDF("p");
  doc.autoTable([], [], tableBody());
  const date = new Date().toLocaleDateString().replace(/\//g, "");
  const time = new Date().toLocaleTimeString().replace(/:/g, "");
};

export const flightReportPdf = (warningData, faultsData, filters, name, action, localTime) => {
  let warningRow = [];
  let faultsRow = [];
  let textWidth = "";
  let _faultsData = [];
  const width = 8;
  let margin = { top: 70, left: 7, right: 7, bottom: 15 };

  const warning = warningData?.filter(
    (res) => res.report_type === "WARNING" && (res.flight_report_type === "MPF" || res.flight_report_type === "PFR")
  );
  // eslint-disable-next-line no-unused-expressions
  warning?.forEach((res) => {
    const data = [
      res.timestamp_time_format?.value
        ? res.timestamp_time_format?.value.split("T")[1].substring(0, 5)
        : res.timestamp_datetime_format.split(" ")[1],
      res.flight_phase_id,
      getAtaChapter(res.ata_chapter, "WARNING"),
      res.text,
    ];
    // eslint-disable-next-line no-unused-expressions
    warningRow?.push(data);
  });

  _faultsData = faultsData?.filter(
    (res) => res.report_type === "FAULT" && (res.flight_report_type === "MPF" || res.flight_report_type === "PFR")
  );
  const faults = warningData?.filter(
    (res) => res.report_type === "FAULT" && (res.flight_report_type === "MPF" || res.flight_report_type === "PFR")
  );
  // eslint-disable-next-line no-unused-expressions
  _faultsData?.push(...faults);
  _faultsData = [...new Set(_faultsData)];

  // eslint-disable-next-line no-unused-expressions
  _faultsData?.forEach((res) => {
    let source = "";
    if (res.source) {
      source = res.source.replace(/,\s*/g, ",");
    }
    const data = [
      res.timestamp_time_format?.value
        ? res.timestamp_time_format?.value.split("T")[1].substring(0, 5)
        : res.timestamp_datetime_format.split(" ")[1],
      res.flight_phase_id,
      getAtaChapter(res.ata_chapter, "FAULT"),
      res.text,
      source,
    ];
    // eslint-disable-next-line no-unused-expressions
    faultsRow?.push(data);
  });

  const filterRow = [];
  // eslint-disable-next-line no-unused-expressions
  filters?.forEach((res) => {
    const data = [res.aircraftID, res.date, res.GMT, res.flightNo, res.departure, res.arrival];
    // eslint-disable-next-line no-unused-expressions
    filterRow?.push(data);
  });

  const faultsHeader = ["GMT", "Phase", "ATA", "Messages", "Source"];
  const filterHeader = ["A/C Reg.", "Date", "GMT", "Flight No.", "Departure", "Arrival"];
  const warningHeader = ["GMT", "Phase", "ATA", "Messages"];

  const tableBody = (width) => {
    const body = {
      startY: height,
      styles: {
        fontSize: 11.5,
        lineColor: 255,
        lineWidth: 1,
        fontStyle: "normal", // normal, bold, italic, bolditalic
        overflow: "linebreak", // visible, hidden, ellipsize or linebreak
        textColor: 20,
        halign: "left", // left, center, right
        valign: "middle", // top, middle, bottom
      },
      columnStyles: {
        0: { cellWidth: 25 },
        1: { cellWidth: 25 },
        2: { cellWidth: 22 },
        // 3: !isEmpty(width) ? { cellWidth: width } : "",
        // 4: !isEmpty(width) ? { cellWidth: 20 } : "",
        3: { cellWidth: "auto", minCellWidth: 50 }, // Set a minimum width (adjust 50 as needed)
        4: { cellWidth: "auto", minCellWidth: 40 }, // Another column with minimum width
      },
      headStyles: { fontStyle: "bold", fillColor: [255, 255, 255] },
      showHead: "everyPage",
      showFoot: "everyPage",
      theme: "grid",
      bodyStyles: { overflow: "linebreak", cellWidth: "auto", valign: "top", halign: "left" },
      didDrawPage: function (data) {
        const pageCount = doc.internal.getNumberOfPages();
        for (var i = 1; i <= pageCount; i++) {
          // Go to page i
          doc.setPage(i);
          pdfHeaders();
        }
      },
      margin: margin,
    };
    return body;
  };

  const doc = new jsPDF("p");
  // doc.addFont("Gotham-Medium-Regular.ttf", "GothamMedium", "standard", "StandardEncoding");
  // doc.setFont("GothamMedium", "standard");
  doc.setFontSize(12);
  doc.setFont(undefined, "bold");

  const pdfHeaders = () => {
    let height = 10;
    //header
    const pageWidth = doc.internal.pageSize.getWidth();
    let img = new Image();
    img.src = ElevadeLogo;
    const imgWidth = img.width;
    const imgHeight = img.height;
    const imgRatio = isNaN(imgWidth / imgHeight) ? 10 : imgWidth / imgHeight;
    doc.addImage(ElevadeLogo, "JPEG", 0, 0, pageWidth, (height = pageWidth / imgRatio));

    doc.setTextColor(0, 0, 0);
    doc.setFontSize(13);
    doc.setCharSpace(0.2);
    doc.text("MAINTENANCE POST FLIGHT REPORT", width, (height += 10));
    textWidth = doc.getTextDimensions("MAINTENANCE POST FLIGHT REPORT");
    doc.line(width, (height += 1), width + textWidth.w + 6, height);
    doc.setFontSize(12);
    doc.autoTable(filterHeader, filterRow, {
      // startY: 45,
      startY: (height += 10),
      styles: {
        fontSize: 11.5,
        lineColor: 255,
        lineWidth: 1,
        fontStyle: "normal", // normal, bold, italic, bolditalic
        overflow: "linebreak", // visible, hidden, ellipsize or linebreak
        textColor: 20,
        halign: "left", // left, center, right
        valign: "middle", // top, middle, bottom
      },
      columnStyles: {
        0: { cellWidth: 25 },
        1: { cellWidth: 25 },
        2: { cellWidth: 22 },
      },
      headStyles: { fontStyle: "bold", fillColor: [255, 255, 255] },
      theme: "grid",
      bodyStyles: { overflow: "linebreak", cellWidth: "auto", valign: "top", halign: "left" },
      margin: { left: 7 },
    });
  };

  let height = doc.lastAutoTable.finalY ? doc.lastAutoTable.finalY + 10 : 70;

  doc.setFontSize(12);
  doc.text("Warning / Maintenance Status Messages", width, height);
  textWidth = doc.getTextDimensions("Warning / Maintenance Status Messages");
  doc.line(width, (height += 1), width + textWidth.w, height);
  warningRow.length > 0
    ? doc.autoTable(warningHeader, warningRow, tableBody((height += 5), 35))
    : doc.autoTable([], [], tableBody());
  isEmpty(warningRow) && doc.setFont(undefined, "normal") && doc.text("No Warning Messages", width, (height += 10));
  doc.setFont(undefined, "bold");

  doc.text("Failure Messages", width, (height = isEmpty(warningRow) ? height + 20 : doc.lastAutoTable.finalY + 20));
  textWidth = doc.getTextDimensions("Failure Messages");
  doc.line(width, (height += 1), width + textWidth.w + 4, height);
  faultsRow.length > 0
    ? doc.autoTable(faultsHeader, faultsRow, tableBody((height += 5), 35))
    : doc.autoTable([], [], tableBody());
  isEmpty(faultsRow) && doc.setFont(undefined, "normal") && doc.text("No Failure Messages", width, (height += 10));
  doc.setFont(undefined, "bold");

  //page number
  const pageCount = doc.internal.getNumberOfPages(); //Total Page Number
  for (let i = 0; i < pageCount; i++) {
    doc.setPage(i);
    let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber; //Current Page
    doc.setFontSize(12);
    const textWidth = doc.getTextDimensions("page: " + pageCurrent + "/" + pageCount);
    doc.setFont(undefined, "normal");
    // doc.setTextColor(131, 131, 131);
    doc.text(
      "page " + pageCurrent + " of " + pageCount,
      doc.internal.pageSize.width - (textWidth.w + width + 5),
      doc.internal.pageSize.height - 10
    );
    if (pageCurrent === pageCount) {
      doc.setTextColor(131, 131, 131);
      doc.setFont(undefined, "italic");
      doc.text(
        `Printed on ${
          localTime
            ? format(new Date(), dateFnsFormats.STANDARD_DATETIME)
            : format(subMinutes(new Date(), -new Date().getTimezoneOffset()), dateFnsFormats.STANDARD_DATETIME)
        } ${localTime ? getTimezoneAbbreviation(new Date()) : "UTC"} by ${name}`,
        width,
        isEmpty(faultsRow) ? (height += 10) : doc.lastAutoTable.finalY + 10
      );
    }
  }

  const date = new Date().toLocaleDateString().replace(/\//g, "");
  const time = new Date().toLocaleTimeString().replace(/:/g, "");
  // Generate a data URI for the PDF
  const pdfDataUri = doc.output("datauristring");
  action === "save" &&
    doc.save(`${`PFR-${date}-${time}-${filters[0].flightNo}-${filters[0].departure}-${filters[0].arrival}`}.pdf`);
  if (action === "print") {
    // doc.autoPrint({ variant: "non-conform" });
    doc.autoPrint();
    doc.output(
      "dataurlnewwindow",
      `${`PFR-${date}-${time}-${filters[0].flightNo}-${filters[0].departure}-${filters[0].arrival}`}.pdf`
    );
  }

  return pdfDataUri;
};
export const flightReportPdfColoured = (warningData, faultsData, filters, name, action, localTime) => {
  let warningRow = [];
  let faultsRow = [];
  let _faultsData = [];
  const width = 8;
  let margin = { top: 85, left: 7, right: 7, bottom: 15 };

  const warning = warningData?.filter(
    (res) => res.report_type === "WARNING" && (res.flight_report_type === "MPF" || res.flight_report_type === "PFR")
  );

  // eslint-disable-next-line no-unused-expressions
  warning?.forEach((res) => {
    const data = [
      res.timestamp_time_format?.value
        ? res.timestamp_time_format?.value.split("T")[1].substring(0, 5)
        : res.timestamp_datetime_format.split(" ")[1],
      res.flight_phase_id,
      getAtaChapter(res.ata_chapter, "WARNING"),
      res.text,
    ];
    // eslint-disable-next-line no-unused-expressions
    warningRow?.push(data);
  });

  // eslint-disable-next-line no-unused-expressions
  _faultsData = faultsData?.filter(
    (res) => res.report_type === "FAULT" && (res.flight_report_type === "MPF" || res.flight_report_type === "PFR")
  );

  const faults = warningData?.filter(
    (res) => res.report_type === "FAULT" && (res.flight_report_type === "MPF" || res.flight_report_type === "PFR")
  );
  // eslint-disable-next-line no-unused-expressions
  _faultsData?.push(...faults);
  _faultsData = [...new Set(_faultsData)];

  // eslint-disable-next-line no-unused-expressions
  _faultsData?.forEach((res) => {
    let source = "";
    if (res.source) {
      source = res.source.replace(/,\s*/g, ",");
    }
    const data = [
      res.timestamp_time_format?.value
        ? res.timestamp_time_format?.value.split("T")[1].substring(0, 5)
        : res.timestamp_datetime_format.split(" ")[1],
      res.flight_phase_id,
      getAtaChapter(res.ata_chapter, "FAULT"),
      res.text,
      source,
    ];
    // eslint-disable-next-line no-unused-expressions
    faultsRow?.push(data);
  });

  let filterRow = [];
  // eslint-disable-next-line no-unused-expressions
  filters?.forEach((res) => {
    const data = [res.aircraftID, res.date, res.GMT, res.flightNo, res.departure, res.arrival];
    // eslint-disable-next-line no-unused-expressions
    filterRow?.push(data);
  });

  const faultsHeader = ["GMT", "Phase", "ATA", "Messages", "Source"];
  const filterHeader = ["A/C Reg.", "Date", "GMT", "Flight No.", "Departure", "Arrival"];
  const warningHeader = ["GMT", "Phase", "ATA", "Messages"];

  const tableBody = (heights, width, isFault) => {
    const body = {
      startY: height,
      styles: {
        fontSize: 11.5,
        lineColor: 255,
        lineWidth: 0,
        fontStyle: "normal", // normal, bold, italic, bolditalic
        overflow: "linebreak", // visible, hidden, ellipsize or linebreak
        textColor: 20,
        halign: "left", // left, center, right
        valign: "middle", // top, middle, bottom
      },
      columnStyles: {
        0: { cellWidth: 25 },
        1: { cellWidth: 25 },
        2: { cellWidth: 22 },
        // 3: !isEmpty(width) ? { cellWidth: width } : "",
        // 4: !isEmpty(width) ? { cellWidth: 20 } : "",
        3: { cellWidth: "auto", minCellWidth: 50 }, // Set a minimum width (adjust 50 as needed)
        4: { cellWidth: "auto", minCellWidth: 40 }, // Another column with minimum width
      },
      headStyles: {
        fontStyle: "bold",
        fillColor: [229, 246, 255],
        lineColor: [229, 246, 255],
        textColor: [8, 143, 209],
      },
      showHead: "everyPage",
      theme: "grid",
      bodyStyles: { overflow: "linebreak", cellWidth: "auto", valign: "top", halign: "left", lineColor: 255 },

      didDrawPage: function (data) {
        const pageCount = doc.internal.getNumberOfPages();
        for (var i = 1; i <= pageCount; i++) {
          // Go to page i
          doc.setPage(i);
          pdfHeaders();
        }
        // if (data.cursor.y > pageHeight - pageWidth / 5.008) {
        //   data.settings.margin.bottom = 40;
        // } else data.settings.margin = margin;
        // data.settings.margin = margin;
        // if (
        //   data.pageCount === doc.internal.getNumberOfPages() &&
        //   isFault &&
        //   data.cursor.y >= pageHeight - pageWidth / 5.008
        // ) {
        //   data.settings.margin = margin;
        // } else data.settings.margin.bottom = 15;
      },
      margin: margin,
    };
    return body;
  };

  const doc = new jsPDF("p");
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  doc.setTextColor(8, 143, 209);
  doc.setFontSize(12);
  doc.setFont(undefined, "bold");

  const pdfHeaders = () => {
    let height = 10;
    const pageWidth = doc.internal.pageSize.getWidth();
    let img = new Image();
    img.src = PdfHeader;
    const imgWidth = img.width;
    const imgHeight = img.height;
    const imgRatio = isNaN(imgWidth / imgHeight) ? 10 : imgWidth / imgHeight;
    doc.addImage(PdfHeader, "JPEG", 0, 0, pageWidth, (height = pageWidth / imgRatio));
    doc.setTextColor(8, 143, 209);
    doc.addImage(Rectangle, "JPEG", width, (height += 20), 195, 32);
    doc.setFontSize(11.5);
    doc.setCharSpace(0.2);
    doc.setTextColor(0, 0, 0);
    doc.setFont(undefined, "bold");
    doc.text("Report Information", 16, (height += 8));
    doc.setFontSize(12);
    doc.autoTable(filterHeader, filterRow, {
      startY: (height += 5),
      styles: {
        fontSize: 11.5,
        lineColor: 255,
        lineWidth: 1,
        fontStyle: "normal", // normal, bold, italic, bolditalic
        overflow: "linebreak", // visible, hidden, ellipsize or linebreak
        textColor: 20,
        halign: "left", // left, center, right
        valign: "middle", // top, middle, bottom
      },
      headStyles: { fontStyle: "bold", fillColor: [255, 255, 255] },
      theme: "grid",
      bodyStyles: { overflow: "linebreak", cellWidth: "auto", valign: "top", halign: "left" },
    });
  };

  let height = doc.lastAutoTable.finalY ? doc.lastAutoTable.finalY + 15 : 80;

  doc.text("Warning / Maintenance Status Messages", width, (height += 5));
  warningRow.length > 0
    ? doc.autoTable(warningHeader, warningRow, tableBody((height += 5), 35, false))
    : doc.autoTable([], [], tableBody(false));
  isEmpty(warningRow) &&
    doc.setTextColor(0, 0, 0) &&
    doc.setFont(undefined, "normal") &&
    doc.text("No Warning Messages", width, (height += 10));
  doc.setFont(undefined, "bold");
  doc.setTextColor(8, 143, 209);

  doc.text("Failure Messages", width, (height = isEmpty(warningRow) ? height + 10 : doc.lastAutoTable.finalY + 10));
  faultsRow.length > 0
    ? doc.autoTable(faultsHeader, faultsRow, tableBody((height += 5), 35, true))
    : doc.autoTable([], [], tableBody(true));
  isEmpty(faultsRow) &&
    doc.setTextColor(0, 0, 0) &&
    doc.setFont(undefined, "normal") &&
    doc.text("No Failure Messages", width, (height += 10));
  doc.setFont(undefined, "bold");
  doc.setTextColor(8, 143, 209);

  //footer
  const pageCount = doc.internal.getNumberOfPages(); //Total Page Number

  for (let i = 0; i < pageCount; i++) {
    let pageCurrent = i > 0 ? i : doc.internal.getCurrentPageInfo().pageNumber; //Current Page

    // Go to page i
    doc.setPage(i);
    //footer
    let img = new Image();
    img.src = PdfFooter;
    const imgWidth = img.width;
    const imgHeight = img.height;
    const imgRatio = isNaN(imgWidth / imgHeight) ? 5.008 : imgWidth / imgHeight;
    doc.setFont(undefined, "bold");
    doc.setTextColor(8, 143, 209);
    doc.text("Maintenance Post Flight Report", width, 32);
    doc.setFont(undefined, "normal");
    doc.setTextColor(131, 131, 131);
    const text = doc.getTextWidth(`Page: ${pageCurrent} + of + ${pageCount}`);
    doc.text("Page: " + pageCurrent + " of " + pageCount, doc.internal.pageSize.width - 5 - text, 32);
    if (pageCurrent === pageCount) {
      if (doc.lastAutoTable.finalY + 10 > pageHeight - pageWidth / imgRatio) {
        doc.addPage();
        pdfHeaders();
        doc.setTextColor(8, 143, 209);
        doc.text("Maintenance Post Flight Report", width, 32);
      }
      doc.setTextColor(131, 131, 131);
      doc.setFont(undefined, "italic");
      doc.text(
        `Printed on ${
          localTime
            ? format(new Date(), dateFnsFormats.STANDARD_DATETIME)
            : format(subMinutes(new Date(), -new Date().getTimezoneOffset()), dateFnsFormats.STANDARD_DATETIME)
        } ${localTime ? getTimezoneAbbreviation(new Date()) : "UTC"} by ${name}`,

        width,
        isEmpty(faultsRow) ? (height += 10) : doc.lastAutoTable.finalY + 10
      );
      doc.addImage(PdfFooter, "JPEG", 0, pageHeight - pageWidth / imgRatio, pageWidth, (height = pageWidth / imgRatio));
    }
  }

  const date = new Date().toLocaleDateString().replace(/\//g, "");
  const time = new Date().toLocaleTimeString().replace(/:/g, "");

  // Generate a data URI for the PDF
  const pdfDataUri = doc.output("datauristring");
  action === "save" &&
    doc.save(`${`PFR-${date}-${time}-${filters[0].flightNo}-${filters[0].departure}-${filters[0].arrival}`}.pdf`);
  if (action === "print") {
    // doc.autoPrint({ variant: "non-conform" });
    doc.autoPrint();
    doc.output(
      "dataurlnewwindow",
      `${`PFR-${date}-${time}-${filters[0].flightNo}-${filters[0].departure}-${filters[0].arrival}`}.pdf`
    );
  }

  return pdfDataUri;
};

export const generatePdfForPfr = (warningData, faultsData, filters) => {
  let warningRow = [];
  let faultsRow = [];
  let height = 10;
  let textWidth = "";
  let _faultsData = [];

  const warning = warningData?.filter((res) => res.report_type === "WARNING" && res.flight_report_type === "PFR");
  warning.forEach((res) => {
    const data = [
      res.timestamp_datetime_format.split(" ")[1],
      res.flight_phase_id,
      getAtaChapter(res.ata_chapter, "WARNING"),
      res.text,
    ];
    warningRow.push(data);
  });

  const faults = faultsData.filter((res) => res.report_type === "FAULT" && res.flight_report_type === "PFR");
  _faultsData.push(...faults);
  _faultsData.forEach((res) => {
    let source = "";
    if (res.source) {
      source = res.source.replace(/,\s*/g, ",");
    }
    const data = [
      res.timestamp_datetime_format.split(" ")[1],
      res.flight_phase_id,
      getAtaChapter(res.ata_chapter, "FAULT"),
      res.text,
      source,
    ];
    faultsRow.push(data);
  });

  let filterRow = [];
  filters.forEach((res) => {
    const data = [res.aircraftID, res.date, res.GMT, res.flightNo, res.departure, res.arrival];
    filterRow.push(data);
  });

  const faultsHeader = ["GMT", "Phase", "ATA", "Messages", "Source"];
  const filterHeader = ["A/C Reg.", "Date", "GMT", "Flight No.", "Departure", "Arrival"];
  const warningHeader = ["GMT", "Phase", "ATA", "Messages"];

  const tableBody = (width) => {
    const pageWidth = doc.internal.pageSize.getWidth();
    let heights = 10;
    let img = new Image();
    img.src = ElevadeLogo;
    const imgWidth = img.width;
    const imgHeight = img.height;
    const imgRatio = isNaN(imgWidth / imgHeight) ? 10 : imgWidth / imgHeight;
    const body = {
      startY: height,
      styles: {
        fontSize: 11.5,
        lineColor: 255,
        lineWidth: 1,
        fontStyle: "normal", // normal, bold, italic, bolditalic
        overflow: "linebreak", // visible, hidden, ellipsize or linebreak
        textColor: 20,
        halign: "left", // left, center, right
        valign: "middle", // top, middle, bottom
      },
      columnStyles: {
        0: { cellWidth: 25 },
        1: { cellWidth: 25 },
        2: { cellWidth: 22 },
        3: !isEmpty(width) ? { cellWidth: width } : "",
        4: !isEmpty(width) ? { cellWidth: 20 } : "",
      },
      headStyles: { fontStyle: "bold", fillColor: [255, 255, 255] },
      showHead: "everyPage",
      showFoot: "everyPage",
      theme: "grid",
      bodyStyles: { overflow: "linebreak", cellWidth: "auto", valign: "top", halign: "left" },
      didDrawPage: function (data) {
        const pageCount = doc.internal.getNumberOfPages();
        for (var i = 1; i <= pageCount; i++) {
          // Go to page i
          doc.setPage(i);
          //header
          doc.addImage(ElevadeLogo, "JPEG", 0, 0, pageWidth, (heights = pageWidth / imgRatio));
          pdfHeaders();
        }
      },
      margin: { top: 70 },
    };
    return body;
  };

  const doc = new jsPDF("p");
  // doc.addFont("Gotham-Medium-Regular.ttf", "GothamMedium", "standard", "StandardEncoding");
  // doc.setFont("GothamMedium", "standard");

  const pdfHeaders = () => {
    doc.setFontSize(14);
    doc.setCharSpace(0.2);
    doc.text("MAINTENANCE POST FLIGHT REPORT", 15, 35);
    textWidth = doc.getTextDimensions("MAINTENANCE POST FLIGHT REPORT");
    doc.line(15, 36, 15 + textWidth.w + 6, 36);
    doc.setFontSize(12);
    doc.autoTable(filterHeader, filterRow, {
      startY: 45,
      styles: {
        fontSize: 11.5,
        lineColor: 255,
        lineWidth: 1,
        fontStyle: "normal", // normal, bold, italic, bolditalic
        overflow: "linebreak", // visible, hidden, ellipsize or linebreak
        textColor: 20,
        halign: "left", // left, center, right
        valign: "middle", // top, middle, bottom
      },
      columnStyles: {
        0: { cellWidth: 25 },
        1: { cellWidth: 25 },
        2: { cellWidth: 22 },
      },
      headStyles: { fontStyle: "bold", fillColor: [255, 255, 255] },
      theme: "grid",
      bodyStyles: { overflow: "linebreak", cellWidth: "auto", valign: "top", halign: "left" },
    });
  };

  doc.setFontSize(12);
  height = doc.lastAutoTable.finalY ? doc.lastAutoTable.finalY + 15 : 80;

  doc.text("Warning / Maintenance Status Messages", 15, height);
  textWidth = doc.getTextDimensions("Warning / Maintenance Status Messages");
  doc.line(15, (height += 1), 15 + textWidth.w, height);
  warningRow.length > 0
    ? doc.autoTable(warningHeader, warningRow, tableBody((height += 10), 35))
    : doc.autoTable([], [], tableBody());
  isEmpty(warningRow) && doc.text("No Warning Messages", 15, (height += 10));

  doc.text("Failure Messages", 15, (height = isEmpty(warningRow) ? height + 20 : doc.lastAutoTable.finalY + 20));
  textWidth = doc.getTextDimensions("Failure Messages");
  doc.line(15, (height += 1), 15 + textWidth.w + 4, height);
  faultsRow.length > 0
    ? doc.autoTable(faultsHeader, faultsRow, tableBody((height += 10), 35))
    : doc.autoTable([], [], tableBody());
  isEmpty(faultsRow) && doc.text("No Failure Messages", 15, (height += 10));

  //page number
  const pageCount = doc.internal.getNumberOfPages(); //Total Page Number
  for (let i = 0; i < pageCount; i++) {
    doc.setPage(i);
    let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber; //Current Page
    doc.setFontSize(12);
    const textWidth = doc.getTextDimensions("page: " + pageCurrent + "/" + pageCount);
    doc.text(
      "page " + pageCurrent + " of " + pageCount,
      doc.internal.pageSize.width - (textWidth.w + 15),
      doc.internal.pageSize.height - 10
    );
    // doc.text(new Date().toUTCString(), 15, doc.internal.pageSize.height - 10);
  }

  const date = new Date().toLocaleDateString().replace(/\//g, "");
  const time = new Date().toLocaleTimeString().replace(/:/g, "");

  doc.save(`${`PFR-${date}-${time}-${filters[0].flightNo}-${filters[0].departure}-${filters[0].arrival}`}.pdf`);
};

export const analyticsPDF = async (data, action, boardName, username, tabName, message) => {
  let rows = [];
  let margin = { top: 40, left: 7, right: 7, bottom: 15 };
  const width = 8;

  const header = !message
    ? ["Message", "ATA", "A/C Reg.", "Flight No", "Phase", "Occurrence", "Telex Sent"]
    : ["A/C Reg.", "ATA", "Flight No", "Phase", "Occurrence", "Telex Sent"];

  data.forEach((res) => {
    const data = !message
      ? [
          res.text,
          res.ata_4d,
          res.ac_reg,
          res.flight_no,
          res.phase,
          formatDate(res.occurrence_timestamp?.value, "", "", true),
          formatDate(res.telex_sent?.value, "", "", true),
        ]
      : [
          res.ac_reg,
          res.ata_4d,
          res.flight_no,
          res.phase,
          formatDate(res.occurrence_timestamp?.value, "", "", true),
          formatDate(res.telex_sent?.value, "", "", true),
        ];
    rows.push(data);
  });

  const tableBody = () => {
    const body = {
      startY: (height += 5),
      styles: {
        fontSize: 11.5,
        lineColor: 255,
        lineWidth: 0,
        fontStyle: "normal", // normal, bold, italic, bolditalic
        overflow: "linebreak", // visible, hidden, ellipsize or linebreak
        textColor: 20,
        halign: "left", // left, center, right
        valign: "middle", // top, middle, bottom
      },
      columnStyles: !message
        ? {
            0: { cellWidth: 40 },
            1: { cellWidth: 20 },
            2: { cellWidth: 30 },
            3: { cellWidth: 30 },
            4: { cellWidth: 20 },
          }
        : {},
      headStyles: {
        fontStyle: "bold",
        fillColor: [229, 246, 255],
        lineColor: [229, 246, 255],
        textColor: [8, 143, 209],
      },
      showHead: "everyPage",
      theme: "grid",
      bodyStyles: { overflow: "linebreak", cellWidth: "auto", valign: "top", halign: "left", lineColor: 255 },

      didDrawPage: function (data) {
        const pageCount = doc.internal.getNumberOfPages();
        for (var i = 1; i <= pageCount; i++) {
          // Go to page i
          doc.setPage(i);
          pdfHeaders();
        }
      },

      margin: margin,
    };
    return body;
  };

  const doc = new jsPDF("p");
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  doc.setTextColor(8, 143, 209);
  doc.setFontSize(12);
  doc.setFont(undefined, "bold");

  const pdfHeaders = () => {
    const pageWidth = doc.internal.pageSize.getWidth();
    let img = new Image();
    img.src = PdfHeader;
    const imgWidth = img.width;
    const imgHeight = img.height;
    const imgRatio = isNaN(imgWidth / imgHeight) ? 10 : imgWidth / imgHeight;
    doc.addImage(PdfHeader, "JPEG", 0, 0, pageWidth, pageWidth / imgRatio);
    doc.setTextColor(8, 143, 209);
  };

  let height = 40;

  doc.text(message ? `Message: ${message}` : "Message List", width, (height += 5));
  rows.length > 0 ? doc.autoTable(header, rows, tableBody()) : doc.autoTable([], [], tableBody());
  isEmpty(rows) &&
    doc.setTextColor(0, 0, 0) &&
    doc.setFont(undefined, "normal") &&
    doc.text("No Messages", width, (height += 10));
  doc.setFont(undefined, "bold");
  doc.setTextColor(8, 143, 209);

  //footer
  const pageCount = doc.internal.getNumberOfPages(); //Total Page Number

  for (let i = 0; i < pageCount; i++) {
    let pageCurrent = i > 0 ? i : doc.internal.getCurrentPageInfo().pageNumber; //Current Page

    // Go to page i
    doc.setPage(i);
    //footer
    let img = new Image();
    img.src = PdfFooter;
    const imgWidth = img.width;
    const imgHeight = img.height;
    const imgRatio = isNaN(imgWidth / imgHeight) ? 5.008 : imgWidth / imgHeight;
    doc.setFont(undefined, "bold");
    doc.setTextColor(8, 143, 209);
    doc.text(`${tabName}:  ${boardName}`, width, 32);
    doc.setFont(undefined, "normal");
    doc.setTextColor(131, 131, 131);
    const text = doc.getTextWidth(`Page: ${pageCurrent} + of + ${pageCount}`);
    doc.text("Page: " + pageCurrent + " of " + pageCount, doc.internal.pageSize.width - 5 - text, 32);
    if (pageCurrent === pageCount) {
      if (doc.lastAutoTable.finalY + 10 > pageHeight - pageWidth / imgRatio) {
        doc.addPage();
        pdfHeaders();
        doc.setTextColor(8, 143, 209);
        doc.text(`${tabName}:  ${boardName}`, width, 32);
      }
      doc.setTextColor(131, 131, 131);
      doc.setFont(undefined, "italic");
      doc.text(
        `Printed on ${format(new Date(), dateFnsFormats.STANDARD_DATETIME)} by ${username}`,
        width,
        isEmpty(rows) ? (height += 10) : doc.lastAutoTable.finalY + 10
      );
      doc.addImage(PdfFooter, "JPEG", 0, pageHeight - pageWidth / imgRatio, pageWidth, pageWidth / imgRatio);
    }
  }
  const date = new Date().toLocaleDateString().replace(/\//g, "");
  const time = new Date().toLocaleTimeString().replace(/:/g, "");
  const title = `${boardName}-${message}`;
  action === "save" && doc.save(`${title}-${date}-${time}.pdf`);
  if (action === "print") {
    // doc.autoPrint({ variant: "non-conform" });
    doc.autoPrint();
    doc.output("dataurlnewwindow", `${title}-${date}-${time}.pdf`);
  }
};
