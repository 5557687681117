
import React from 'react';
export function LifebouyIcon() {
    return (<svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.06931 0.5H23.2277C26.8605 0.5 29.797 3.41478 29.797 7V23C29.797 26.5852 26.8605 29.5 23.2277 29.5H7.06931C3.43652 29.5 0.5 26.5852 0.5 23V7C0.5 3.41478 3.43652 0.5 7.06931 0.5Z" fill="url(#paint0_linear_4_45)" stroke="#E8E8E8"/>
            <path d="M14.873 22.4648C19.0152 22.4648 22.373 19.107 22.373 14.9648C22.373 10.8227 19.0152 7.46484 14.873 7.46484C10.7309 7.46484 7.37305 10.8227 7.37305 14.9648C7.37305 19.107 10.7309 22.4648 14.873 22.4648Z" stroke="#394458" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.873 18.0898C16.5989 18.0898 17.998 16.6907 17.998 14.9648C17.998 13.239 16.5989 11.8398 14.873 11.8398C13.1472 11.8398 11.748 13.239 11.748 14.9648C11.748 16.6907 13.1472 18.0898 14.873 18.0898Z" stroke="#394458" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.6621 12.7539L9.56836 9.66016" stroke="#394458" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.084 12.7539L20.1777 9.66016" stroke="#394458" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.084 17.1758L20.1777 20.2695" stroke="#394458" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.6621 17.1758L9.56836 20.2695" stroke="#394458" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            <defs>
                <linearGradient id="paint0_linear_4_45" x1="15.1485" y1="0" x2="15.1485" y2="30" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FCFFFD"/>
                    <stop offset="1" stop-color="#F2FAFC"/>
                </linearGradient>
            </defs>
        </svg>);
}
export function SquareMissingIcon() {
    return (<svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.7227 0H7.72266C3.85666 0 0.722656 3.13401 0.722656 7V23C0.722656 26.866 3.85666 30 7.72266 30H23.7227C27.5886 30 30.7227 26.866 30.7227 23V7C30.7227 3.13401 27.5886 0 23.7227 0Z" fill="#FC3F3F"/>
            <path d="M8.48047 13.0547C8.82198 11.785 9.48996 10.6268 10.418 9.69531" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.418 20.3047C9.48672 19.3726 8.81833 18.211 8.48047 16.9375" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.668 22.2422C16.397 22.5898 15.0561 22.5898 13.7852 22.2422" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M22.9648 16.9453C22.6233 18.215 21.9554 19.3732 21.0273 20.3047" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M21.0273 9.69531C21.9586 10.6274 22.627 11.789 22.9648 13.0625" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13.7773 7.76069C15.0483 7.4131 16.3892 7.4131 17.6602 7.76069" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>);
}
export function SquareErrorIcon() {
    return (<svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.3271 0H7.32715C3.46116 0 0.327148 3.13401 0.327148 7V23C0.327148 26.866 3.46116 30 7.32715 30H23.3271C27.1931 30 30.3271 26.866 30.3271 23V7C30.3271 3.13401 27.1931 0 23.3271 0Z" fill="#F5BE59"/>
            <g clip-path="url(#clip0_19548_101137)">
                <path d="M15.3589 11.0472V15.1356M19.7659 20.8422L23.5493 16.8323C24.4041 15.9263 24.4041 14.5112 23.5493 13.6052L17.07 6.73802C16.1416 5.75399 14.5762 5.75399 13.6478 6.73802L7.16845 13.6052C6.31364 14.5112 6.31364 15.9263 7.16845 16.8323L13.6477 23.6995C14.5762 24.6835 16.1416 24.6835 17.07 23.6995L17.084 23.6846" stroke="#333333" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <circle cx="15.3271" cy="18" r="1" fill="#333333"/>
            </g>
            <defs>
                <clipPath id="clip0_19548_101137">
                    <rect width="20" height="20" fill="white" transform="translate(5.32715 5)"/>
                </clipPath>
            </defs>
        </svg>);
}
export function SquareExpiredIcon() {
    return (<svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.2432 0H7.24316C3.37717 0 0.243164 3.13401 0.243164 7V23C0.243164 26.866 3.37717 30 7.24316 30H23.2432C27.1092 30 30.2432 26.866 30.2432 23V7C30.2432 3.13401 27.1092 0 23.2432 0Z" fill="#AA33FF"/>
            <path d="M19.0225 6.48047V8.98047M11.5225 6.48047V8.98047M8.39941 11.4844H22.1494M17.1475 14.6016L13.3975 18.3516M17.1475 18.3516L13.3975 14.6016M9.02441 7.73438H21.5244C21.8696 7.73438 22.1494 8.0142 22.1494 8.35938V20.8594C22.1494 21.2046 21.8696 21.4844 21.5244 21.4844H9.02441C8.67924 21.4844 8.39941 21.2046 8.39941 20.8594V8.35938C8.39941 8.0142 8.67924 7.73438 9.02441 7.73438Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>);
}
export function SquareRemindIcon() {
    return (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23 0H7C3.13401 0 0 3.13401 0 7V23C0 26.866 3.13401 30 7 30H23C26.866 30 30 26.866 30 23V7C30 3.13401 26.866 0 23 0Z" fill="#3A32E0"/>
            <path d="M19.2129 6.84375V9.34375M11.7129 6.84375V9.34375M8.58984 11.8477H22.3398M15.2988 14.0039V17.0039M15.2988 19.0078V19.5078M9.21484 8.09766H21.7148C22.06 8.09766 22.3398 8.37748 22.3398 8.72266V21.2227C22.3398 21.5678 22.06 21.8477 21.7148 21.8477H9.21484C8.86967 21.8477 8.58984 21.5678 8.58984 21.2227V8.72266C8.58984 8.37748 8.86967 8.09766 9.21484 8.09766Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>);
}
export function SquareDefectIcon() {
    return (<svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.80859 0.5H23.8086C27.3984 0.5 30.3086 3.41015 30.3086 7V23C30.3086 26.5899 27.3984 29.5 23.8086 29.5H7.80859C4.21874 29.5 1.30859 26.5899 1.30859 23V7C1.30859 3.41015 4.21874 0.5 7.80859 0.5Z" fill="white" stroke="#B1B1B1"/>
            <path d="M16.2695 13.3281V16.4531" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15.1909 8.32832L8.31591 20.2033C8.20638 20.393 8.14863 20.6082 8.14844 20.8272C8.14824 21.0463 8.20562 21.2615 8.3148 21.4514C8.42399 21.6413 8.58117 21.7992 8.77058 21.9092C8.95999 22.0192 9.17499 22.0776 9.39404 22.0783H23.144C23.3631 22.0776 23.5781 22.0192 23.7675 21.9092C23.9569 21.7992 24.1141 21.6413 24.2233 21.4514C24.3325 21.2615 24.3898 21.0463 24.3896 20.8272C24.3894 20.6082 24.3317 20.393 24.2222 20.2033L17.3472 8.32832C17.2385 8.13838 17.0815 7.98052 16.8922 7.87073C16.7029 7.76095 16.4879 7.70312 16.269 7.70312C16.0502 7.70312 15.8352 7.76095 15.6459 7.87073C15.4566 7.98052 15.2996 8.13838 15.1909 8.32832Z" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16.2695 20.2031C16.7873 20.2031 17.207 19.7834 17.207 19.2656C17.207 18.7479 16.7873 18.3281 16.2695 18.3281C15.7518 18.3281 15.332 18.7479 15.332 19.2656C15.332 19.7834 15.7518 20.2031 16.2695 20.2031Z" fill="#333333"/>
            <circle cx="20.7852" cy="10.5" r="3.5" fill="#E95048"/>
        </svg>);
}
export function CircleIcon() {
    return (<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.86719 5.76302C2.09486 4.91654 2.54018 4.14441 3.15885 3.52344" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M3.15885 10.5964C2.53802 9.97497 2.09243 9.20057 1.86719 8.35156" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.99284 11.8828C7.14555 12.1145 6.25159 12.1145 5.4043 11.8828" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11.5241 8.35156C11.2964 9.19804 10.8511 9.97017 10.2324 10.5911" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.2324 3.52344C10.8533 4.14482 11.2988 4.91922 11.5241 5.76823" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5.39844 2.22848C6.24573 1.99676 7.13969 1.99676 7.98698 2.22848" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>);
}
export function WifiIcon() {
    return (<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.38867 8.88358C5.88037 8.41184 6.5354 8.14844 7.2168 8.14844C7.8982 8.14844 8.55322 8.41184 9.04492 8.88358" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.2168 3.14844L9.7168 5.64844" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.2168 5.64844L9.7168 3.14844" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M1.85742 5.34638C3.28266 3.93343 5.20988 3.14306 7.2168 3.14847C7.49524 3.14836 7.77346 3.16401 8.05013 3.19534" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M3.62305 7.11204C4.58095 6.16915 5.8727 5.64309 7.2168 5.6485C7.496 5.64711 7.77481 5.66976 8.05013 5.71621" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.2168 11.2734C7.56197 11.2734 7.8418 10.9936 7.8418 10.6484C7.8418 10.3033 7.56197 10.0234 7.2168 10.0234C6.87162 10.0234 6.5918 10.3033 6.5918 10.6484C6.5918 10.9936 6.87162 11.2734 7.2168 11.2734Z" fill="#333333"/>
        </svg>);
}
export function CalendarExpiredIcon() {
    return (<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.1875 2.15625H2.85417C2.62405 2.15625 2.4375 2.3428 2.4375 2.57292V10.9062C2.4375 11.1364 2.62405 11.3229 2.85417 11.3229H11.1875C11.4176 11.3229 11.6042 11.1364 11.6042 10.9062V2.57292C11.6042 2.3428 11.4176 2.15625 11.1875 2.15625Z" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.51953 1.32031V2.98698" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4.51953 1.32031V2.98698" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2.4375 4.65625H11.6042" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.26953 6.73438L5.76953 9.23437" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.26953 9.23437L5.76953 6.73438" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>);
}
export function CalendarNearIcon() {
    return (<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.4766 2.39844H3.14323C2.91311 2.39844 2.72656 2.58499 2.72656 2.8151V11.1484C2.72656 11.3786 2.91311 11.5651 3.14323 11.5651H11.4766C11.7067 11.5651 11.8932 11.3786 11.8932 11.1484V2.8151C11.8932 2.58499 11.7067 2.39844 11.4766 2.39844Z" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.80859 1.5625V3.22917" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4.80859 1.5625V3.22917" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2.72656 4.89844H11.8932" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.19922 6.33594L7.19922 8.33594" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.19922 9.67188L7.19922 10.0052" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>);
}
export function WarningIcon() {
    return (<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.30664 5.88281V7.96615" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.58951 2.54961L2.00618 10.4663C1.93316 10.5927 1.89466 10.7362 1.89453 10.8822C1.8944 11.0282 1.93265 11.1717 2.00544 11.2983C2.07824 11.4249 2.18302 11.5302 2.30929 11.6035C2.43557 11.6769 2.5789 11.7158 2.72493 11.7163H11.8916C12.0376 11.7158 12.181 11.6769 12.3072 11.6035C12.4335 11.5302 12.5383 11.4249 12.6111 11.2983C12.6839 11.1717 12.7221 11.0282 12.722 10.8822C12.7219 10.7362 12.6834 10.5927 12.6103 10.4663L8.02701 2.54961C7.95454 2.42298 7.84991 2.31774 7.72369 2.24455C7.59748 2.17136 7.45416 2.13281 7.30826 2.13281C7.16236 2.13281 7.01905 2.17136 6.89283 2.24455C6.76662 2.31774 6.66198 2.42298 6.58951 2.54961Z" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
            <circle cx="10" cy="4" r="2" fill="#E95048"/>
            <path d="M7.30664 10.4688C7.65182 10.4688 7.93164 10.1889 7.93164 9.84375C7.93164 9.49857 7.65182 9.21875 7.30664 9.21875C6.96146 9.21875 6.68164 9.49857 6.68164 9.84375C6.68164 10.1889 6.96146 10.4688 7.30664 10.4688Z" fill="#333333"/>
        </svg>);
}
