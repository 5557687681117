import { sellerStatus } from "common/constant/SellerStatus";
import CompanyContext from "common/context/company/companyContext";
import React, { Fragment, useContext } from "react";
import { useSelector } from "react-redux";
import "./termsAndConditionMain.css";

const sidebarLinks = [
  {
    name: "ADE Terms of Use",
    url: "#adetermsofuse",
  },
  {
    name: "ADE Privacy Statement",
    url: "#adeprivacystatement",
  },
  

];

const mainListLinks = [
  {
    name: "ADE Terms of Use",
    urlName: "ADE Terms of Use",
    url: "/terms-of-use",
    id: "adetermsofuse",
  },
  {
    name: "ADE Privacy Statement",
    urlName: "ADE Privacy Statement",
    url: "/privacy-statement",
    id: "adeprivacystatement",
  },
];

const TermsAndConditionsMain = () => {
  const authState = useSelector((state) => state.auth);
  const companyContext = useContext(CompanyContext);

  const sellerInfo = companyContext.company.seller;
  const user = authState.currentUser;

  // Note: show the link only if pass this inline if
  const isSellerApproved =
    user &&
    sellerInfo?.isSeller &&
    sellerInfo?.status === sellerStatus.APPROVED;

  return (
    <Fragment>
      <div className="tnc">
        <div className="content mt-3">
          <h1>Terms and Conditions</h1>
          <div className="row gap" style={{ position: "relative" }}>
            <div className="col-lg-3 col-md-12 col-sm-12 sidebar">
              <div className="row sidebar">
                <div className="sidenav">
                  <div className="sidenav-menu border-bottom">
                    {sidebarLinks.map((eachLink, key) => {
                      // public link; show if there is no isAuth defined
                      return eachLink.isAuth === undefined ? (
                        <a key={key} href={eachLink.url}>
                          {eachLink.name}
                        </a>
                      ) : (
                        // inline if; show if the value is true
                        isSellerApproved && (
                          <a key={key} href={eachLink.url}>
                            {eachLink.name}
                          </a>
                        )
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-md-12 col-sm-12">
              <div className="row contentScroll">
                <div className="main">
                  {mainListLinks.map((eachLink, key) => {
                    // public link; show if there is no isAuth defined
                    return eachLink.isAuth === undefined ? (
                      <div key={key} className="row" id={eachLink.id}>
                        <h3
                          className="subheadline"
                          style={{ marginTop: "-20px" }}
                        >
                          {eachLink.name}
                        </h3>
                        <p className="mt-30">
                          <a
                            href={eachLink.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {eachLink.urlName}
                          </a>
                        </p>
                      </div>
                    ) : (
                      // another inline if; show if the value is true
                      isSellerApproved && (
                        <div key={key} className="row" id={eachLink.id}>
                          <h3
                            className="subheadline"
                            style={{ marginTop: "-20px" }}
                          >
                            {eachLink.name}
                          </h3>
                          <p className="mt-30">
                            <a
                              href={eachLink.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {eachLink.urlName}
                            </a>
                          </p>
                        </div>
                      )
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TermsAndConditionsMain;
