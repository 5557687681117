import React from 'react';
import DataUnavailable from "common/images/icons/elevade/Data-unavailable.svg";

const NoData = () => {
  return (
    // <Grid container>
    //   <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", marginTop: "10%", textAlign: "center" }}>
    <>
      <div style={{ marginTop: "5%", marginBottom: "10%", textAlign: "center" }}>
        <div style={{ marginBottom: "16px" }}>
          <img src={DataUnavailable} alt="data-unavailable" />
        </div>
        <div style={{ fontFamily: "Gotham-Medium", fontSize: "14px" }}>Data Unavailable</div>
        <div style={{ fontFamily: "Gotham-Book", fontSize: "12px" }}>
          There is no data found. Please try a different search term.
        </div>
      </div>
    </>
    //   </Grid>
    // </Grid>
  );
};

export default NoData;