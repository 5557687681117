/* eslint-disable no-dupe-keys */
import { styled } from "@mui/system";
import React, { forwardRef } from "react";

const StyledLabel = styled("label")(() => ({
  display: "-webkit-inline-box",
  display: "-webkit-inline-flex",
  display: "-ms-inline-flexbox",
  display: "inline-flex",
  "-webkit-align-items": " center",
  "-webkit-box-align": "center",
  "-ms-flex-align": "center",
  alignItems: "center",
  cursor: "pointer",
  verticalAlign: "middle",
  "-webkit-tap-highlight-color": "transparent",
  marginLeft: "-11px",
  marginRight: "16px",
}));
const StyledSpan = styled("span")(() => ({
  color: "#1976d2",
}));

const AdeRadioGroup = forwardRef(
  ({ label, name, defaultValue = "", options, disabled, ...props }, ref) => {
    return (
      <>
        <StyledLabel>
          <StyledSpan>
            <input type="radio" value={true} name={name} ref={ref} {...props} />
          </StyledSpan>
        </StyledLabel>
        <input type="radio" value={false} name={name} ref={ref} {...props} />
      </>
    );
  }
);

export default AdeRadioGroup;
