
import React from 'react';

export default function ACTip() {
	return (
		<g>
			<path
				d="M90 292C80.1899 308.108 66.4557 352.058 90 399"
				stroke="#CAD2DB"
				stroke-width="0.3"
			/>
			<path
				d="M49 370C42.2802 350.89 46.2001 329.371 49 321C45 325.083 37 335.7 37 345.5C37 355.3 45 365.917 49 370Z"
				fill="#CAD2DB"
			/>
		</g>
	);
}
