
import React from 'react';
import { Box, Typography } from "@mui/material";
import { createContext, useEffect, useState } from "react";
import DetailAllAC from "./DetailAllScanner";

import {
	useGetAllScannersStatus,
	useGetAllScannersTotalCount,
} from "../../../../models/custom/scanner-model";

export const CountContext = createContext({
	totalCount: undefined,
	totalActive: undefined,
	totalInactive: undefined,
	isLoading: undefined,
	totalIsLoading: undefined,
	showDetail: undefined,
});

export default function Main() {
	const [showAllDet, setShowAllDet] = useState(false);
	const [totalCount, setTotalCount] = useState(0);
	const [totalActive, setactive] = useState(0);
	const [totalInactive, setinactive] = useState(0);

	const {
		isLoading,
		data,
		// isError, errorMessage, refetch
	} = useGetAllScannersStatus();

	const {
		isLoading: totalIsLoading,
		data: totalData,
		// isError: totalIsError,
		// errorMessage: totalErrorMessage,
		// refetch: totalRefetch,
	} = useGetAllScannersTotalCount();

	useEffect(() => {
		if (data) {
			const { totalActive, totalInactive } = data;
			setactive(totalActive);
			setinactive(totalInactive);
		}
	}, [data]);

	useEffect(() => {
		if (totalData) {
			const { totalCount } = totalData;
			setTotalCount(totalCount);
		}
	}, [totalData]);

	return (
		<CountContext.Provider
			value={{
				totalCount,
				totalActive,
				totalInactive,
				isLoading,
				totalIsLoading,
				showDetail: showAllDet,
			}}
		>
			<Box
				sx={{
					width: "100%",
					gap: "1em",
					border: "1px solid #E8E8E8",
					borderRadius: "12px",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						paddingTop: "1em",
						paddingLeft: "2em",
						paddingRight: "2em",
					}}
				>
					<Typography sx={{ display: "flex", alignItems: "center" }}>
						All Devices
						{/* <ExpandMoreIcon fontSize="small" sx={{ marginLeft: '1em' }} /> */}
					</Typography>
					<Box
						sx={{
							flexGrow: "1",
							display: "flex",
							justifyContent: "right",
						}}
					>
						{/* <Button>Export</Button> */}
					</Box>
				</Box>
				<DetailAllAC />
			</Box>
		</CountContext.Provider>
	);
}
