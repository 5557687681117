import React from 'react';
import { Grid } from "@mui/material";
import styles from "../Dasr.module.css";
import IconHubTotalAircraft from "common/images/icons/elevade/Icon-HubTotalAircraft.svg";
import IconAirplaneTilt from "common/images/icons/elevade/Icon-AirplaneTilt.svg";

const HubAircraft = ({ data }) => {
  function formatACReg(e) {
    return <div className={styles.formatAcReg}>{e}</div>;
  }

  let hubList = [
    ...new Set(
      data &&
        data?.map((item) => {
          return item.status["hub"];
        })
    ),
  ];
  hubList = hubList.filter((e) => {
    return e !== null && e !== "KUL";
  });

  return (
    <>
      <div className={styles.divCard}>
        <Grid container columns={2}>
          <Grid item xs={2} className={styles.gridTitle} style={{ paddingBottom: "24px" }}>
            Hub Aircraft
          </Grid>
          <Grid item xs={2} className={`${styles.divCard} ${styles.divCardBlue} ${styles.divCardHubBlue}`}>
            <Grid container columns={5.5}>
              <Grid item xs={0.5} className={`${styles.gridJustifyCenter}`} style={{ paddingTop: "16px" }}>
                <img src={IconHubTotalAircraft} alt="hub-total-aircraft" height={18} />
              </Grid>
              <Grid item xs={1} className={`${styles.borderRight}`}>
                <div style={{ fontSize: "14px" }}>Total Aircraft</div>
                <div style={{ fontFamily: "Gotham-Medium", fontSize: "24px" }}>
                  {data?.filter((e) => e.status.hub !== "KUL" && e.status.hub !== "" && e.status.hub !== null).length}
                </div>
              </Grid>
              <Grid item xs={1} sx={{ paddingLeft: "44px" }}>
                <div style={{ fontSize: "14px" }}>BKI</div>
                <div style={{ fontFamily: "Gotham-Medium", fontSize: "24px" }}>
                  {(data?.length > 0 &&
                    data?.filter((e) => {
                      return e.status.hub === "BKI";
                    }).length) ||
                    0}
                </div>
              </Grid>
              <Grid item xs={1} sx={{ paddingLeft: "44px" }}>
                <div style={{ fontSize: "14px" }}>KCH</div>
                <div style={{ fontFamily: "Gotham-Medium", fontSize: "24px" }}>
                  {(data?.length > 0 &&
                    data?.filter((e) => {
                      return e.status.hub === "KCH";
                    }).length) ||
                    0}
                </div>
              </Grid>
              <Grid item xs={1} sx={{ paddingLeft: "44px" }}>
                <div style={{ fontSize: "14px" }}>JHB</div>
                <div style={{ fontFamily: "Gotham-Medium", fontSize: "24px" }}>
                  {(data?.length > 0 &&
                    data?.filter((e) => {
                      return e.status.hub === "JHB";
                    }).length) ||
                    0}
                </div>
              </Grid>
              <Grid item xs={1} sx={{ paddingLeft: "44px" }}>
                <div style={{ fontSize: "14px" }}>PEN</div>
                <div style={{ fontFamily: "Gotham-Medium", fontSize: "24px" }}>
                  {(data?.length > 0 &&
                    data?.filter((e) => {
                      return e.status.hub === "PEN";
                    }).length) ||
                    0}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2} sx={{ padding: "12px" }}></Grid>
          {hubList.map((e, index) => {
            return (
              <Grid item xs={1} sx={{ paddingRight: index % 2 ? "" : "16px" }} key={index}>
                <Grid container columns={2} className={`${styles.divCard} ${styles.divCardHub}`}>
                  <Grid item xs={1} className={styles.gridTitle}>
                    {e}
                  </Grid>
                  <Grid item xs={1} className={`${styles.gridTitle} ${styles.gridJustifyEnd}`}>
                    <img
                      src={IconAirplaneTilt}
                      alt="airplane-tilt"
                      height={16}
                      style={{ marginRight: "6px", marginTop: "2px" }}
                    />
                    {data.filter((item) => item.status.hub === e).length}
                  </Grid>
                  <Grid item xs={2} sx={{ paddingTop: "20px" }}>
                    <div
                      style={{ maxHeight: "200px", minHeight: "200px", paddingTop: "10px" }}
                      className={`${styles.hideScrollBar} ${styles.macScrollbar}`}
                    >
                      {data
                        .filter((item) => item.status.hub === e)
                        .map((item, indexes) => {
                          return (
                            <Grid container columns={2} sx={{ paddingBottom: "16px" }} key={indexes}>
                              <Grid item xs={0.1} sm={0.1} md={0.1} lg={0.1} xl={0.1}>
                                {indexes + 1 + `. `}
                              </Grid>
                              <Grid item xs={1} sm={1} md={1} lg={0.6} xl={0.6}>
                                {formatACReg(item.aircraftReg)}
                              </Grid>
                              <Grid item xl={1.3} className={`${styles.divCardRemarks} ${styles.ellipsis}`}>
                                {item.status.remarks || "-"}
                              </Grid>
                            </Grid>
                          );
                        })}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </>
  );
};

export default HubAircraft;
