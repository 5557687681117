import { ScrollToTopRestoration } from "common/helpers/scrollToTopRestoration";
import Breadcrumbs from "layouts/breadcrumbs/Breadcrumbs";
import Footer from "layouts/footer/FooterV2";
import Spinner from "layouts/Spinner";
import { ConfirmProvider } from "material-ui-confirm";
import React, { Fragment, useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Redirect, Route, Switch, useHistory } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import "./App.css";
import DashboardState from "./common/context/dashboard/DashboardState";
import { ReactComponent as ScrollTopIcon } from "./common/images/ScrollTop.svg";
import Signup from "./layouts/auth/Signup";
import Forgotpassword from "./layouts/auth/Forgotpassword";
import GuestRoute from "./layouts/auth/GuestRoute";
import Login from "./layouts/auth/Login";
import ProtectedRoute from "./layouts/auth/ProtectedRoute";
import ErrorNotFound from "./pages/ade/GenericNotFound/GenericNotFound";
import PrivacyStatement from "./pages/ade/PrivacyStatement/PrivacyStatement";
import RestrictedPage from "./pages/ade/RestrictedPage/RestrictedPage";
import TermsAndConditionsMain from "./pages/ade/TermsAndConditions/TermsAndConditionsMain";
import TermsOfUse from "./pages/ade/TermsOfUse/TermsOfUse";
import ApuDashboard from "./pages/elevade/predictiveMaintenance/tableauDashboard/ApuDashboard";
import HydraulicsDashboard from "./pages/elevade/predictiveMaintenance/tableauDashboard/HydraulicDashboard";
import Elevade from "./pages/elevade/Elevade";
import AircondDashboard from "pages/elevade/predictiveMaintenance/tableauDashboard/AirconditioningDashboard";
import ElevadeLandingPage from "pages/elevade/home/HomeV2";
import ElevadeFleetHeader from "layouts/header/ElevadeFleetHeaderV2";
import Callback from "pages/callback/Callback";
import Cookies from "js-cookie";
import { getToken } from "common/store/reducers/elevade/auth/thunks";
import { isEmpty } from "lodash";
import { getUserDetailByEmail } from "common/store/reducers/elevade/user/thunks";
import CabinMonitoring from "pages/elevade/cabinMonitoring/Home";

const env = process.env.REACT_APP_ENV;
const gtmId = process.env.REACT_APP_GMTID;

function App() {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const authEntity = useSelector((state) => state.authEntity);
  const [check, setCheck] = useState(false);
  const history = useHistory();
  const { sidebarToggle } = useSelector((state) => state.sidebarToggle);

  useEffect(() => {
    console.log(env);
    if (env === "production") {
      TagManager.initialize({
        gtmId: gtmId,
      });
    }
  }, []);

  const token = Cookies.get("elevadeToken");
  useEffect(() => {
    if (isEmpty(authEntity.currentUser.token) && check === false && token) {
      const fetchData = async () => {
        const userToken = await dispatch(getToken({ token, history }));
        const userDetailRequest = {
          userEmail: userToken?.payload?.userName,
          history,
        };
        await dispatch(getUserDetailByEmail(userDetailRequest));
        setIsLoading(false);
      };
      fetchData().catch(console.error);
      setCheck(true);
    } else if (!token) {
      setIsLoading(false);
    }
  }, [check, authEntity.currentUser.token, dispatch, history, token]);

  return (
    <div className="App no-printme">
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="App no-printme" style={{ backgroundColor: "#FAFAFE" }}>
          <Fragment>
            <ReactNotifications />
            <Router>
              <ScrollToTopRestoration />
              {/* <CompanyState>
                <UserState>
                  <AuthState> */}
              <ConfirmProvider>
                <ElevadeFleetHeader />
                <div
                  className={`container-fluid main d-flex flex-column ${
                    sidebarToggle ? "sidebarOpen" : "sidebarClose"
                  }`}
                >
                  <ScrollToTop smooth component={<ScrollTopIcon />} style={{ zIndex: "8" }} />
                  <Breadcrumbs />
                  <DashboardState>
                    <Switch>
                      <Route exact path="/fleet/callback" component={Callback} />
                      <Route exact path="/fleet" component={ElevadeLandingPage} product="elevade" />
                      <ProtectedRoute path="/fleet" component={Elevade} product="elevade" />
                      <Route path="/cabin-monitoring" component={CabinMonitoring} product="ade" />
                      <Route exact path="/terms-and-conditions" component={TermsAndConditionsMain} product="ade" />
                      <Route exact path="/privacy-statement" component={PrivacyStatement} product="ade" />
                      <Route exact path="/terms-of-use" component={TermsOfUse} product="ade" />
                      <Route
                        exact
                        path="/our-solutions/predictive-maintenance"
                        component={ElevadeLandingPage}
                        product="elevade"
                      />
                      <ProtectedRoute
                        exact
                        path="/our-solutions/predictive-maintenance/APU-reliability-monitoring"
                        product="elevade"
                        component={ApuDashboard}
                      />
                      <ProtectedRoute
                        exact
                        path="/our-solutions/predictive-maintenance/hydraulics-quality-monitoring"
                        product="elevade"
                        component={HydraulicsDashboard}
                      />
                      <ProtectedRoute path="/elevade" product="elevade" component={Elevade} />
                      <ProtectedRoute
                        exact
                        path="/our-solutions/predictive-maintenance/air-conditioning"
                        product="elevade"
                        component={AircondDashboard}
                      />
                      <GuestRoute exact path="/login" component={Login} product="ade" />
                      <GuestRoute exact path="/signup" component={Signup} product="ade" />
                      <GuestRoute exact path="/forgot-password" product="ade" component={Forgotpassword} />
                      <Route path="/error-404" component={ErrorNotFound} product="ade" />
                      <Route path="/error-403" component={RestrictedPage} product="ade" />
                      <Redirect to="/error-404" />
                    </Switch>
                  </DashboardState>
                </div>
                <Footer />
              </ConfirmProvider>
              {/* </AuthState>
                </UserState>
              </CompanyState> */}
            </Router>
          </Fragment>
        </div>
      )}
    </div>
  );
}

export default App;
