import { seatsA } from "./seatsA";
import { seatsB } from "./seatsB";
import { seatsC } from "./seatsC";
import { seatsD } from "./seatsD";
import { seatsE } from "./seatsE";
import { seatsF } from "./seatsF";
const seats = [
    ...seatsA,
    ...seatsB,
    ...seatsC,
    ...seatsD,
    ...seatsE,
    ...seatsF,
];
export default seats;
