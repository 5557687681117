
import React from 'react';
import { Tooltip, tooltipClasses } from "@mui/material";
import { commonBorderRadius, commonFontSizes } from "../../../constants/styles";
export default function CustomToolTip({ children, title, open, marginLeft }) {
	return (
		<Tooltip
			open={open}
			title={title}
			PopperProps={{
				sx: {
					[`& .${tooltipClasses.tooltip}`]: {
						marginTop: "0 !important",
						background: "#333333",
						marginLeft,
						borderRadius: commonBorderRadius.xxs,
						fontSize: commonFontSizes.xs,
					},
				},
			}}
		>
			<span>{children}</span>
		</Tooltip>
	);
}
