import { useState } from "react";
import useInputState from "../../../components/inputs/useInputState";
import { useAppSelector } from "../../../../app/hooks";
export default function useAddEqStates() {
    
	const { companyProfile } = useAppSelector((state) => state.userEntity);
	const code = companyProfile?.code;

    const tagId = useInputState({ isMandatory: true });
    const serialNo = useInputState({ isMandatory: true });
    const eqType = useInputState({ isMandatory: true });
    const status = useInputState({ isMandatory: true });
    const mfgDate = useInputState({ isMandatory: true });
    const expDate = useInputState({ isMandatory: true });
    const locationDetailsId = useInputState({ isMandatory: false });
    const locationTypeId = useInputState({ isMandatory: false });
    const inputModel = useInputState({ isMandatory: false });
    const inputRegNoId = useInputState({ isMandatory: false });
    const [aocCode, setAocCode] = useState(code);
    return {
        tagId,
        serialNo,
        eqType,
        status,
        mfgDate,
        expDate,
        locationDetailsId,
        locationTypeId,
        inputModel,
        inputRegNoId,
        aocCode, setAocCode,
    };
}
