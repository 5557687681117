import { 
  RESET_CONTEXT, 
  SET_LOADING, 
  SET_TABS, 
  SET_SUBTAB, 
  SET_MISC, 
  SET_AEROTRADE_OPEN, 
  SET_CASCADE_OPEN, 
  SET_ELEVADE_OPEN,
  SET_MODAL_OPEN,
  SET_FORM_DEFAULTS,
} from "../types";

const dashboardReducer = (state, action) => {
  switch (action.type) {
    case RESET_CONTEXT:
      return action.payload;
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_MISC:
      return {
        ...state,
        misc: action.payload
      };    
    case SET_TABS:
      return {
        ...state,
        tab: action.payload.tab,
        subtab: action.payload.subtab,
      };  
    case SET_SUBTAB:
      return {
        ...state,
        subtab: action.payload,
      };
    case SET_AEROTRADE_OPEN:
      return {
        ...state,
        openAerotrade: action.payload,
      };
    case SET_CASCADE_OPEN:
      return {
        ...state,
        openCascade: action.payload
      };    
    case SET_ELEVADE_OPEN:
      return {
        ...state,
        openElevade: action.payload,
      };  
    case SET_MODAL_OPEN:
      return {
        ...state,
        modal: action.payload,
      };  
    case SET_FORM_DEFAULTS:
      return {
        ...state,
        formDefaults: {
          ...action.payload
        },
      };  
    default:
      return state;
  }
};

export default dashboardReducer;