import { env_var_REACT_APP_API_URL } from "../../envVar/vars";
import useGetGQL from "../useGetGQL";
import useMutateGQL from "../useMutateGQL";
import { useAppSelector } from "../../app/hooks";

export function useGetLocationHistoryByIdWithHistorySearch ({inFleetLocationId, historySearch}) {

	const history = useGetGQL({
        gqlquery: `
        query allLocationHistories {
            allLocationHistories(locationListId: "${inFleetLocationId}", 
            ${(historySearch && historySearch !== "" && historySearch !== null) ? `, historyDetails:"${historySearch}"` : ''}
            orderByCreatedAt:"-created_at") {
                edges {
                  node {
                    id
                    createdAt
                    createdBy
                    avatarCreated
                    locationhistorydetailsSet {
                      edges {
                        node {
                          description
                        }
                      }
                    }
                 }
               }
            }
        }
        `,
        querySign: 'allLocationHistories',
        url: env_var_REACT_APP_API_URL ?? "",
        isEdges: true,
    });

	return history
}