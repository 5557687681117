
import { env_var_REACT_APP_API_URL } from "../../envVar/vars";
import useGetGQL from "../useGetGQL";
import useMutateGQL from "../useMutateGQL";
import { useAppSelector } from "../../app/hooks";

export function useGetAllFleetInspections ({
	search,
	filterRegNos,
	filterEnType,
	filterModels,
}) {
	const { companyProfile } = useAppSelector((state) => state.userEntity);
	const code = companyProfile?.code;

	const data = useGetGQL({
		gqlquery: `
		query inspectionsWithNodes {
			allFleetInspection(
			aocCode: "${code}"
			,${(search !== undefined ||
			filterRegNos.length > 0 ||
			filterEnType.length > 0 ||
			filterModels.length > 0) ? ` 
				${search !== undefined ? ` searchRecord:"${search}" ` : ''}
				${filterRegNos.length > 0 ? `multipleAircraftRegno:"${JSON.stringify(filterRegNos).replaceAll('"', '').replaceAll('[', '').replaceAll(']', '')}"` : ''}
				${filterModels.length > 0 ? `multipleAircraftModel:"${JSON.stringify(filterModels).replaceAll('"', '').replaceAll('[', '').replaceAll(']', '')}"` : ''}
				${filterEnType.length > 0 ? `multipleAircraftEngineType:"${JSON.stringify(filterEnType).replaceAll('"', '').replaceAll('[', '').replaceAll(']', '')}"` : ''}
			` : ''}
			){
				edges {
					node {
						fleetId{
							regNo
							model
							engineType
							latestSync
						}
						totalInspection
						dateInspection
					}
				}
			}
		}
		`,
		querySign: 'allFleetInspection',
		url: env_var_REACT_APP_API_URL ?? "",
		isEdges: true,
	});

	return data
}