import {
  Modal,
  Box,
  Grid,
  Fade,
  CircularProgress,
  Divider,
  Typography,
  FormHelperText,
  Autocomplete,
  TextField,
} from "@mui/material";
import { AdeButton, AdeSpan, AdeTab, AdeTabs, AdeTextField } from "common/components";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { makeAPIRequestRun, showNotificationElevade } from "common/helpers";
import { setAOCBasedOnPermission } from "pages/elevade/component/Helpers";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import { ChromePicker } from "react-color";
import { find, isEmpty } from "lodash";

const ChangeLabel = ({ message, ataChapter, openLabel, setOpenLabel, acType, label }) => {
  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState(0);
  const [colorSelection, setColorSelection] = useState("#000000");
  const [textColor, setTextColor] = useState("#000000");
  const [showColorSelection, setShowColorSelection] = useState(true);
  const [options, setOptions] = useState();
  const [abbrList, setAbbrList] = useState();
  const [labelList, setLabelList] = useState();
  const [trigger, setTrigger] = useState(true);
  const [labelMessage, setLabelMessage] = useState(label?.remarks);
  // const { profile: userProfile } = useSelector((state) => state.user);
  const { company: companyInfo } = useSelector((state) => state.company);

  const {
    companyAOC,
    userAOC,
    userProfile,
    userName: userEmail,
    userPermission,
  } = useSelector((state) => state.userEntity);
  // let AOC = companyInfo?.permission?.elevadeAOC;
  let AOC = companyAOC;
  AOC = setAOCBasedOnPermission(AOC, userPermission);
  // const UserAOC = userProfile?.permission?.elevadeAOC;
  // const UserAOC = userAOC;
  // if (UserAOC && UserAOC !== null && UserAOC !== undefined && UserAOC?.length > 0) AOC = UserAOC;
  let _aoc = AOC;

  if (typeof AOC === "object") {
    _aoc = "";
    AOC.map((x, index) => {
      return (_aoc += `${x}${index + 1 === AOC.length ? "" : " , "}`);
    });
  }

  const { register, setValue, formState, watch, handleSubmit, reset, setError, getValues } = useForm({ mode: "all" });
  const { errors } = formState;
  const watchBackground = watch("tagColour.colourSelection");
  const watchTextColor = watch("tagColour.textColour");
  const watchAbbreviation = watch("abbreviation");

  const handleClose = () => {
    setOpenLabel(false);
    setIndex(0);
    reset("");
    setLabelMessage(label?.remarks ?? "");
    setTextColor("#000000");
    setColorSelection("#000000");
  };
  const handleChange = (event, newValue) => {
    reset("");
    setLabelMessage(label?.remarks ?? "");
    setTextColor("#000000");
    setColorSelection("#000000");
    setIndex(newValue);
  };

  const handleColourChange = (color) => {
    if (showColorSelection) {
      setColorSelection(color.hex);
      return setValue("tagColour.colourSelection", color.hex);
    }

    setTextColor(color.hex);
    setValue("tagColour.textColour", color.hex);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const allyProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const style = {
    box: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 547,
      bgcolor: "background.paper",
      borderRadius: "8px",
      boxShadow: 24,
      p: 4,
      overflow: "scroll",
      paddingBottom: "0px",
    },
    loading: {
      top: "40%",
      left: "45%",
      position: "absolute",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };

  const ColorBox = styled("div")({
    width: "40px",
    height: "40px",
    borderRadius: "5px",
  });

  const TextPreview = styled("div")({
    textAlign: "center",
    borderRadius: "5px",
  });

  useEffect(() => {
    setValue("tagColour.colourSelection", colorSelection);
    setValue("tagColour.textColour", textColor);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (watchBackground?.length === 7 && watchBackground[0] === "#") {
      setColorSelection(watchBackground);
    }
    if (watchTextColor?.length === 7 && watchTextColor[0] === "#") {
      setTextColor(watchTextColor);
    }
  }, [watchBackground, watchTextColor]);

  useEffect(() => {
    getOptionsList();
    // eslint-disable-next-line
  }, [trigger]);

  function characterLimit(e) {
    // const regexpression = new RegExp(/^[0-9]{0,5}$/);
    // e.target.value = e.target.value.replace(regexpression, "").toUpperCase();
    if (e.target.value.length > 5) e.target.value = e.target.value.substring(0, 5);
  }

  const getOptionsList = async () => {
    const list = await makeAPIRequestRun("get", `acarsdata/aircraftstatus/label/get/${AOC}`);
    setLabelList(list);
    const labelList = list.map((res) => {
      return res.label;
    });
    setOptions(labelList);
    const abbrList = list.map((res) => {
      return res.abbreviation;
    });
    setAbbrList(abbrList);
  };

  const onSubmit = async (formData) => {
    setLoading(true);
    if (!isEmpty(formData.newLabel) && !isEmpty(formData.remarks)) {
      const data = {
        label: formData.newLabel,
        abbreviation: formData.abbreviation,
        remarks: formData.remarks,
        tagColour: formData.tagColour,
        email: userEmail,
        name: userProfile.fullName,
      };

      // Check if the label or abbreviation already exists in the existing data
      const labelExists = labelList.some((item) => item.label === label);
      const abbreviationExists = labelList.some((item) => item.abbreviation === data.abbreviation);

      // Return true if either the label or abbreviation exists, otherwise return false
      const isDuplicate = labelExists || abbreviationExists;

      if (!isDuplicate) {
        try {
          await makeAPIRequestRun("post", `acarsdata/aircraftstatus/label/add/${_aoc}`, data);
          setIndex(0);
          showNotificationElevade("success", "Success!", ["Label Successfully Added"]);
          setTrigger(!trigger);
        } catch (err) {
          handleClose();
          showNotificationElevade("danger", "Fail!", [[err]]);
          console.log(err);
        }
      } else {
        handleClose();
        showNotificationElevade("danger", "Fail!", ["Duplicate Label/Abbreviation"]);
      }
      reset();
    }

    if (!isEmpty(formData.label)) {
      const data = {
        aoc: _aoc,
        acType: acType,
        message: message,
        ataChapter: ataChapter,
        label: formData.label,
        email: userEmail,
        name: userProfile.fullName,
      };
      try {
        await makeAPIRequestRun("post", `acarsdata/aircraftstatus/label/update/`, data);
        handleClose();
        showNotificationElevade("success", "Success!", ["Label Successfully Updated"]);
        setOpenLabel(false);
      } catch (err) {
        showNotificationElevade("danger", "Fail!", [[err]]);
        console.log(err);
      }
    }
    setLoading(false);
  };

  function checkExisting(e, field, value) {
    let arr = [];
    if (field === "newLabel") {
      arr = options;
    } else arr = abbrList;
    const x = e.target.value.replace(" ", "")?.toLowerCase();
    arr.forEach((res) => {
      const y = res?.replace(" ", "")?.toLowerCase();
      if (x === y)
        setError(field, {
          type: "duplicate",
          message: `This ${value} already exists`,
        });
    });
  }

  const handleChangeLabel = (e, value) => {
    const msg = find(labelList, (res) => {
      return res.label === value;
    });
    setLabelMessage(msg?.remarks);
  };

  const SelectLabel = () => {
    return (
      <Grid style={{ padding: 0 }}>
        <Grid
          style={{
            fontFamily: "Gotham-Book",
            fontSize: "16px",
            fontWeight: "bold",
            marginBottom: 2,
          }}
        >
          Label
        </Grid>
        {/* <AdeSelect
          {...register("label")}
          placeholder="Select Label"
          fullWidth
          sx={{
            marginBottom: 3,
          }}
          options={opts}
          defaultValue={label?.label}
          onChange={(e) => {
            handleChangeLabel(e.target.value);
            setValue("label", e.target.value);
          }}
          value={getValues("label")}
        /> */}
        <Autocomplete
          disablePortal
          autoComplete
          placeholder="Select Label"
          fullWidth
          onChange={(e, label) => {
            handleChangeLabel(e.target.value, label);
            setValue("label", label);
          }}
          options={options}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: errors.ataChapter ? "red" : "#E8E8E8",
            },
            "&& legend": {
              width: "0ch",
            },
            "&.MuiAutocomplete-root": {
              boxShadow: "none" /* Remove any box shadow */,
            },
          }}
          getOptionLabel={(option) => option}
          renderInput={(params) => <TextField {...params} size={"medium"} fullWidth placeholder="Select Label" />}
          value={getValues("label") ?? label?.label}
        />
        <Grid
          style={{
            fontFamily: "Gotham-Book",
            fontSize: "16px",
            fontWeight: "bold",
            marginBottom: 2,
          }}
        >
          Remarks
        </Grid>
        <AdeTextField
          rows={5}
          sx={{ paddingBottom: 3 }}
          fullWidth
          multiline
          placeholder="Label Message"
          InputProps={{
            readOnly: true,
          }}
          value={labelMessage}
          defaultValue={label?.remarks}
        />
        <Grid container justifyContent="end">
          <AdeButton type="submit" fullWidth={false} onClick={handleSubmit(onSubmit)}>
            Submit
          </AdeButton>
        </Grid>
      </Grid>
    );
  };

  const AddLabel = () => {
    return (
      <Grid style={{ paddingBottom: "0px", border: "none" }}>
        <Grid
          style={{
            fontFamily: "Gotham-Book",
            fontSize: "16px",
            fontWeight: "bold",
            marginBottom: 2,
          }}
        >
          Label Title
        </Grid>
        <Grid>
          <AdeTextField
            {...register("newLabel", {
              required: "This field is required",
            })}
            placeholder="Enter a new label"
            fullWidth
            sx={{ marginBottom: 3 }}
            onBlur={(e) => {
              checkExisting(e, "newLabel", "title");
            }}
          ></AdeTextField>
          {errors?.newLabel && (
            <Typography sx={{ color: "#FC3F3F", marginTop: "-25px" }}>{errors?.newLabel.message}</Typography>
          )}
        </Grid>
        <Grid
          style={{
            fontFamily: "Gotham-Book",
            fontSize: "16px",
            fontWeight: "bold",
            marginBottom: 2,
          }}
        >
          Label Abbreviation
        </Grid>
        <Grid>
          <AdeTextField
            {...register("abbreviation", {
              required: "This field is required",
              maxLength: {
                value: 5,
                message: "Maximum 5 characters",
              },
            })}
            placeholder="Enter an abbreviation"
            fullWidth
            sx={{ marginBottom: 3 }}
            onChange={(e) => {
              characterLimit(e);
              checkExisting(e, "abbreviation", "abbreviation");
            }}
          ></AdeTextField>
          {errors?.abbreviation && (
            <Typography sx={{ color: "#FC3F3F", marginTop: "-25px" }}>{errors?.abbreviation.message}</Typography>
          )}
        </Grid>

        <Grid
          style={{
            fontFamily: "Gotham-Book",
            fontSize: "16px",
            fontWeight: "bold",
            marginBottom: 2,
          }}
        >
          Remarks
        </Grid>
        <Grid>
          <AdeTextField
            {...register("remarks", {
              required: "This field is required",
            })}
            rows={3}
            sx={{ paddingBottom: 3 }}
            fullWidth
            multiline
            placeholder="Please state label message"
          />
          {errors?.remarks && (
            <Typography sx={{ color: "#FC3F3F", marginTop: "-25px" }}>{errors?.remarks.message}</Typography>
          )}
        </Grid>
        <Divider sx={{ marginY: 2 }} orientation="horizontal" fullWidth />

        <Grid container spacing={1} sx={{ marginTop: 3 }}>
          <Grid container xs={8} sx={{ justifyContent: "flex-start" }}>
            <Grid
              item
              xs={5}
              sx={{
                textAlign: "right",
                alignSelf: "start",
                marginTop: "10px",
              }}
            >
              <strong>Stroke Colour</strong>
              <AdeSpan color="red">*</AdeSpan>
            </Grid>
            <Grid item xs={1} ml={1}>
              <ColorBox
                style={{
                  border: `2px solid ${colorSelection}`,
                  marginRight: 2,
                  // background: colorSelection,
                }}
              />
            </Grid>
            <Grid item xs={5}>
              <AdeTextField
                sx={{ marginLeft: 3, fontSize: "10px" }}
                size="small"
                placeholder="Color Selection"
                name="colorSelection"
                error={errors?.tagColour?.colourSelection?.message}
                {...register("tagColour.colourSelection", {
                  required: "This field is required",
                  maxLength: {
                    value: 7,
                    message: "Maximum 7 characters",
                  },
                  pattern: {
                    value: /^[#][A-Fa-f0-9]+$/,
                    message: "Input must be in hex format",
                  },
                })}
                onClick={() => setShowColorSelection(true)}
                InputProps={{
                  classes: {
                    input: { fontSize: 10 },
                  },
                }}
                value={colorSelection}
              />
              {errors.tagColour?.colourSelection && (
                <FormHelperText style={{ color: "#fc3f3f", fontSize: 16 }}>
                  {errors.tagColour?.colourSelection?.message}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={5} sx={{ textAlign: "right", alignSelf: "start", marginTop: "10px" }}>
              <strong>Text Colour</strong>
              <AdeSpan color="red">*</AdeSpan>
            </Grid>
            <Grid item xs={1} ml={1}>
              <ColorBox
                style={{
                  background: textColor,
                }}
              />
            </Grid>
            <Grid item xs={5}>
              <AdeTextField
                sx={{ marginLeft: 3, fontSize: "10px" }}
                size="small"
                placeholder="Text Color"
                name="textColor"
                error={errors.tagColour?.textColour?.message}
                {...register("tagColour.textColour", {
                  required: "This field is required",
                  maxLength: {
                    value: 7,
                    message: "The max length is 7",
                  },
                  pattern: {
                    value: /^[#][A-Fa-f0-9]+$/,
                    message: "Input must be in hex format",
                  },
                })}
                onClick={() => setShowColorSelection(false)}
                value={textColor}
              />
              {errors.tagColour?.textColour && (
                <FormHelperText style={{ color: "#fc3f3f", fontSize: 16 }}>
                  {errors.tagColour?.textColour?.message}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={5} sx={{ textAlign: "right", alignSelf: "start", marginTop: "2px" }}>
              <strong>Preview</strong>
            </Grid>
            <Grid style={{ marginLeft: 10 }} xs={5}>
              <TextPreview
                style={{
                  border: `1px solid ${colorSelection}`,
                  padding: "4px",
                  color: textColor,
                }}
              >
                {watchAbbreviation || "TEXT"}
              </TextPreview>
            </Grid>
          </Grid>
          <Grid container xs={4} sx={{ justifyContent: "flex-start" }}>
            <ChromePicker
              style={{ width: "100px", height: "100px" }}
              color={showColorSelection ? colorSelection : textColor}
              onChange={handleColourChange}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="end" sx={{ marginTop: 3 }}>
          <AdeButton type="submit" fullWidth={false} onClick={handleSubmit(onSubmit)} disabled={!isEmpty(errors)}>
            Create
          </AdeButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <Modal
      open={openLabel}
      onClose={handleClose}
      BackdropProps={{
        style: {
          boxShadow: "none",
        },
      }}
      sx={{
        padding: "24px",
        zIndex: 9000,
      }}
    >
      <>
        {loading && (
          <Box sx={style.box} minHeight={253}>
            <CircularProgress sx={style.loading} />
          </Box>
        )}
        {!loading && (
          <Fade in={openLabel}>
            <Box sx={style.box} maxHeight="550px">
              <>
                Add Label
                <Divider
                  //   sx={{ height: 1, marginTop: 2, backgroundColor: "#7C8BA6", width: "99%" }}
                  orientation="horizontal"
                  fullWidth
                />
                <AdeTabs
                  value={index}
                  onChange={handleChange}
                  centered
                  variant="fullWidth"
                  selectionFollowsFocus={true}
                  TabIndicatorProps={{
                    sx: {
                      height: "2px",
                    },
                  }}
                >
                  <AdeTab className="tabOne" label="Select Label" {...allyProps(0)} />
                  <AdeTab className="tabTwo" label="Create Label" {...allyProps(1)} />
                </AdeTabs>
                <TabPanel value={index} index={0}>
                  <SelectLabel />
                </TabPanel>
                <TabPanel value={index} index={1}>
                  <AddLabel />
                </TabPanel>
              </>
            </Box>
          </Fade>
        )}
      </>
    </Modal>
  );
};
export default ChangeLabel;
