import React from 'react';
import styles from "../aircraftMonitoring/AircraftStatus.module.css";
import OOOIOut from "common/images/icons/elevade/OOOI-OUT.svg";
import OOOIOff from "common/images/icons/elevade/OOOI-OFF.svg";
import OOOIOn from "common/images/icons/elevade/OOOI-ON.svg";
import OOOIIn from "common/images/icons/elevade/OOOI-IN.svg";
import OOOITng from "common/images/icons/elevade/OOOI-T&G.svg";
import OOOIDr from "common/images/icons/elevade/OOOI-DR.svg";
import OOOIMnt from "common/images/icons/elevade/OOOI-MNT.svg";

const GetOOOIStatus = ({ data }) => {
  let status = "";
  data?.in_datetime !== null
    ? (status = "IN")
    : data?.on_datetime !== null
    ? (status = "ON")
    : data?.off_datetime !== null
    ? (status = "OFF")
    : data?.out_datetime !== null
    ? (status = "OUT")
    : data?.tng_datetime !== null
    ? (status = "TNG")
    : data?.dr_datetime !== null
    ? (status = "DR")
    : data?.mnt_datetime !== null
    ? (status = "MNT")
    : (status = "N/A");
  switch (status) {
    case "OFF":
      return (
        <span>
          <img src={OOOIOff} alt="In" className={styles.oooiIcon} style={{ marginRight: "9px", marginBottom: "2px" }} />
          <span style={{ fontFamily: "Gotham-Medium", fontSize: "14px" }}>{status}</span>
        </span>
      );
    case "IN":
      return (
        <span>
          <img src={OOOIIn} alt="In" className={styles.oooiIcon} style={{ marginRight: "9px", marginBottom: "2px" }} />
          <span style={{ fontFamily: "Gotham-Medium", fontSize: "14px" }}>{status}</span>
        </span>
      );
    case "ON":
      return (
        <span>
          <img src={OOOIOn} alt="In" className={styles.oooiIcon} style={{ marginRight: "9px", marginBottom: "2px" }} />
          <span style={{ fontFamily: "Gotham-Medium", fontSize: "14px" }}>{status}</span>
        </span>
      );
    case "T&G":
      return (
        <span>
          <img src={OOOITng} alt="In" className={styles.oooiIcon} style={{ marginRight: "9px", marginBottom: "2px" }} />
          <span style={{ fontFamily: "Gotham-Medium", fontSize: "14px" }}>{status}</span>
        </span>
      );
    case "DR":
      return (
        <span>
          <img src={OOOIDr} alt="In" className={styles.oooiIcon} style={{ marginRight: "9px", marginBottom: "2px" }} />
          <span style={{ fontFamily: "Gotham-Medium", fontSize: "14px" }}>{status}</span>
        </span>
      );
    case "OUT":
      return (
        <span>
          <img src={OOOIOut} alt="In" className={styles.oooiIcon} style={{ marginRight: "9px", marginBottom: "2px" }} />
          <span style={{ fontFamily: "Gotham-Medium", fontSize: "14px" }}>{status}</span>
        </span>
      );
    case "MNT":
      return (
        <span>
          <img src={OOOIMnt} alt="In" className={styles.oooiIcon} style={{ marginRight: "9px", marginBottom: "2px" }} />
          <span style={{ fontFamily: "Gotham-Medium", fontSize: "14px" }}>{status}</span>
        </span>
      );
    default:
      return (
        <></>
      )
      // break;
  }
};

export default GetOOOIStatus;
