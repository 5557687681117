import React from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/system";

const StyledButton = styled(Button)(() => ({
  height: "50px",
  outline: "none",
  padding: "16px 24px",
  textTransform: "capitalize",
  "&&:focus": {
    outline: "none",
  },
  boxShadow: "none",
  border: "1px solid #D1D1D1",
}));

const AdeButton = ({ children, variant = "outlined", ...props }) => (
  <StyledButton fullWidth color={variant === "contained" ? "blue" : "darkblue"} variant={variant} {...props}>
    {children}
  </StyledButton>
);

export default AdeButton;
