import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { dateFnsFormats } from "common/constant";
import { format, fromUnixTime } from "date-fns";

dayjs.extend(utc);

export const unixToDateFormat = (unixTimestamp) => {
  let date = fromUnixTime(unixTimestamp);

  return {
    date: format(date, dateFnsFormats.STANDARD_DATE),
    time: format(date, dateFnsFormats.STANDARD_TIME),
  };
};

export const convertFirestoreDate = (firestoreDate) => {
  if (!firestoreDate) return "";

  let { _seconds, _nanoseconds } = firestoreDate;

  const fireBaseTime = new Date(_seconds * 1000 + _nanoseconds / 1000000);
  const dateTime = dayjs(fireBaseTime);

  return {
    date: dateTime.format(dateFnsFormats.STANDARD_DATE),
    time: dateTime.format(dateFnsFormats.STANDARD_TIME),
  };
};

// 2022-04-17T16:00:00.000Z to 17 April 2022
export const zDateToStringFormat = (zDate) => {
  if (zDate) {
    const [year, month, day] = zDate.substring(0, 10).split("-");

    switch (month) {
      case "01":
        return `Jan ${day}, ${year}`;
      case "02":
        return `Feb ${day}, ${year}`;
      case "03":
        return `Mar ${day}, ${year}`;
      case "04":
        return `Apr ${day}, ${year}`;
      case "05":
        return `May ${day}, ${year}`;
      case "06":
        return `Jun ${day}, ${year}`;
      case "07":
        return `Jul ${day}, ${year}`;
      case "08":
        return `Aug ${day}, ${year}`;
      case "09":
        return `Sep ${day}, ${year}`;
      case "10":
        return `Oct ${day}, ${year}`;
      case "11":
        return `Nov ${day}, ${year}`;
      case "12":
        return `Dec ${day}, ${year}`;
      default:
        return `${day}-${month}-${year}`;
    }
  }
};
