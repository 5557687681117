
import React from 'react';
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import StyledTextField from "../../../components/inputs/text";
import StyledDropDown from "../../../components/inputs/dropdowns/StyledDropDown";
import LoadingInput from "../../../components/inputs/LoadingInput";
import useMutateGQL from "../../../../models/useMutateGQL";
import { useNotification } from "../../../components/notifications/UniversalNotification";
import { useDialog } from "../../../components/dialog-box/DialogInterface";
import useAddDeviceStates from "./useAddDeviceStates";
import { validateInputStates } from "../../../components/inputs/useInputState";
import useAddDeviceDatas from "./useAddDeviceDatas";

import { useCreateScanner } from "../../../../models/custom/scanner-model";

export default function AddDeviceMain({ handleClose, refetch }) {
	const openNotification = useNotification();
	const openDialog = useDialog();
	// inputs
	const addDeviceStates = useAddDeviceStates();
	const { allManufacturers, allDeviceModels, allDeviceStatuses } =
		useAddDeviceDatas({
			manufacturerID: addDeviceStates.manufacturerId.value,
		});

	const { mutate: add } = useCreateScanner({ addDeviceStates });

	const usingStatusesData = allDeviceStatuses.data?.map(({ node }) => {
		return { ...node };
	});
	const usingModelData = allDeviceModels.data?.map(({ node }) => {
		return { ...node };
	});
	const usingManufacturerData = allManufacturers.data?.map(({ node }) => {
		return { ...node };
	});
	return (
		<Box
			style={{
				width: "100%",
				borderRadius: "0px",
				height: "100%",
				position: "relative",
			}}
		>
			<Box
				style={{
					padding: "1em",
				}}
			>
				<Grid container spacing="1em">
					{[
						{
							isRequired: addDeviceStates.deviceId.isMandatory,
							title: "Device ID",
							inputComp: (
								<StyledTextField
									inputRef={addDeviceStates.deviceId.inputRef}
									onChange={(e) => {
										addDeviceStates.deviceId.setValue(
											e.target.value
										);
									}}
									fullWidth
									error={addDeviceStates.deviceId.isError}
									helperText={
										addDeviceStates.deviceId.errorMessage
									}
								/>
							),
						},
						{
							isRequired: addDeviceStates.deviceName.isMandatory,
							title: "Device Name",
							inputComp: (
								<StyledTextField
									inputRef={
										addDeviceStates.deviceName.inputRef
									}
									onChange={(e) => {
										addDeviceStates.deviceName.setValue(
											e.target.value
										);
									}}
									fullWidth
									error={addDeviceStates.deviceName.isError}
									helperText={
										addDeviceStates.deviceName.errorMessage
									}
								/>
							),
						},
						{
							isRequired:
								addDeviceStates.manufacturerId.isMandatory,
							title: "Manufacturer",
							inputComp: allManufacturers.isLoading ? (
								<LoadingInput />
							) : (
								<StyledDropDown
									inputRef={
										addDeviceStates.manufacturerId.inputRef
									}
									// defaultValue={itemTypeData[0]?.node?.id}
									value={addDeviceStates.manufacturerId.value}
									handleChange={(e) => {
										addDeviceStates.manufacturerId.setValue(
											e.target.value
										);
									}}
									options={usingManufacturerData.map(
										({ code, id }) => {
											return {
												label: code,
												value: id,
											};
										}
									)}
									error={
										addDeviceStates.manufacturerId.isError
									}
									helperText={
										addDeviceStates.manufacturerId
											.errorMessage
									}
								/>
							),
						},
						{
							isRequired: addDeviceStates.modelId.isMandatory,
							title: "Model",
							inputComp: allDeviceModels.isLoading ? (
								<LoadingInput />
							) : (
								<StyledDropDown
									inputRef={addDeviceStates.modelId.inputRef}
									// defaultValue={itemTypeData[0]?.node?.id}
									value={addDeviceStates.modelId.value}
									handleChange={(e) => {
										addDeviceStates.modelId.setValue(
											e.target.value
										);
									}}
									options={usingModelData.map(
										({ name, id }) => {
											return {
												label: name,
												value: id,
											};
										}
									)}
									error={
										addDeviceStates.manufacturerId.isError
									}
									helperText={
										addDeviceStates.manufacturerId
											.errorMessage
									}
								/>
							),
						},
						{
							isRequired: addDeviceStates.status.isMandatory,
							title: "Status",
							inputComp: allDeviceStatuses.isLoading ? (
								<LoadingInput />
							) : (
								<StyledDropDown
									inputRef={addDeviceStates.status.inputRef}
									// defaultValue={statusesData[0]?.node?.id}
									value={addDeviceStates.status.value}
									error={addDeviceStates.status.isError}
									helperText={
										addDeviceStates.status.errorMessage
									}
									handleChange={(e) => {
										addDeviceStates.status.setValue(
											e.target.value
										);
									}}
									options={usingStatusesData.map(
										({ name, id, code }) => {
											let labelHolder = name;
											switch (code) {
												case "A":
													labelHolder = "Active";
													break;
												case "I":
													labelHolder = "Inactive";
													break;
												default:
													labelHolder = "Active";
													break;
											}
											return {
												label: labelHolder,
												value: id,
											};
										}
									)}
								/>
							),
						},
					].map(({ title, inputComp, isRequired }) => {
						return (
							<GridItem
								title={title}
								inputComp={inputComp}
								isRequired={isRequired}
							/>
						);
					})}
					{/* <GridItem
            title='A/C Model:'
            inputComp={(
                <StyledDropDown
                    inputRef={addDeviceStates.inputModel.inputRef}
                    value={addDeviceStates.inputModel.value}
                    handleChange={(e: any) => {
                        addDeviceStates.inputModel.setValue(e.target.value)
                    }}
                    options={(allFleetsWithDistinctModel && !allFleetsWithDistinctModel.isLoading) ? modelsOpts : []}
                    error={addDeviceStates.inputModel.isError}
                    helperText={addDeviceStates.inputModel.errorMessage}
                />
            )}
            isRequired={addDeviceStates.inputModel.isMandatory}
        />
        <GridItem
            title='A/C Reg'
            inputComp={(
                <StyledDropDown
                    inputRef={addDeviceStates.inputRegNoId.inputRef}
                    disabled={addDeviceStates.inputModel ? false : true}
                    value={addDeviceStates.inputRegNoId.value}
                    handleChange={(e: any) => {
                        addDeviceStates.inputRegNoId.setValue(e.target.value)
                    }}
                    options={(allFleetsFilteredByModel && !allFleetsFilteredByModel.isLoading) ? regNosOpts : []}
                    error={addDeviceStates.inputRegNoId.isError}
                    helperText={addDeviceStates.inputRegNoId.errorMessage}
                />
            )}
            isRequired={addDeviceStates.inputRegNoId.isMandatory}
        />
        <GridItem
            title='Location Type'
            inputComp={(
                <StyledDropDown
                    inputRef={addDeviceStates.locationTypeId.inputRef}
                    disabled={addDeviceStates.inputRegNoId ? false : true}
                    value={addDeviceStates.locationTypeId.value}
                    handleChange={(e: any) => {
                        addDeviceStates.locationTypeId.setValue(e.target.value)
                    }}
                    options={(allManufacturers && !allManufacturers.isLoading) ? manufacturer : []}
                    error={addDeviceStates.locationTypeId.isError}
                    helperText={addDeviceStates.locationTypeId.errorMessage}
                />
            )}
            isRequired={addDeviceStates.locationTypeId.isMandatory}
        />
        <GridItem
            title='Location Number'
            inputComp={(
                <StyledDropDown
                    inputRef={addDeviceStates.locationDetailsId.inputRef}
                    disabled={addDeviceStates.locationTypeId ? false : true}
                    value={addDeviceStates.locationDetailsId.value}
                    handleChange={(e: any) => {
                        addDeviceStates.locationDetailsId.setValue(e.target.value)
                    }}
                    options={(locationInventoryDetails.data && !locationInventoryDetails.isLoading) ? locNoOpts : []}
                    error={addDeviceStates.locationDetailsId.isError}
                    helperText={addDeviceStates.locationDetailsId.errorMessage}
                />
            )}
            isRequired={addDeviceStates.locationDetailsId.isMandatory}
        /> */}
					<Box
						sx={{
							position: "absolute",
							bottom: "0px",
							width: "100%",
						}}
					>
						<Divider />
						<Grid container>
							<Grid
								item
								xs={6}
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<Button
									onClick={() => {
										handleClose();
									}}
									sx={{
										height: "42px",
										margin: "1em",
										width: "100%",
										borderRadius: "8px",
									}}
								>
									Cancel
								</Button>
							</Grid>
							<Grid
								item
								xs={6}
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<Button
									variant="contained"
									color="primary"
									sx={{
										height: "42px",
										margin: "1em",
										width: "100%",
										borderRadius: "8px",
									}}
									onClick={() => {
										const { isOk } = validateInputStates(
											{
												// aocCode: addDeviceStates.aocCode,
												deviceId:
													addDeviceStates.deviceId,
												deviceName:
													addDeviceStates.deviceName,
												manufacturerId:
													addDeviceStates.manufacturerId,
												modelId:
													addDeviceStates.modelId,
												status: addDeviceStates.status,
											},
											true
										);
										if (isOk) {
											add().then((res) => {
												const {
													isError,
													errorMessage,
													data,
												} = res;
												if (!isError) {
													openNotification(
														"Device saved!",
														{
															variant: "info",
															onClose: () => {
																handleClose();
																refetch();
															},
														}
													);
												} else {
													openNotification(
														"Saving failed!",
														{
															variant: "error",
															helperText: `There was an error saving your changes. ${errorMessage}`,
														}
													);
												}
											});
										} else {
											// openNotification('Please fill in all the required field(s)', {
											//     variant: 'warning',
											// })
											// do nothing instead
										}
									}}
								>
									Save
								</Button>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Box>
		</Box>
	);
}
function GridItem({ title, inputComp, isRequired = true }) {
	return (
		<Grid item xs={6}>
			<Typography
				sx={{
					fontSize: "14px",
					marginBottom: "0.3em",
				}}
			>
				{title}
				{isRequired ? <span style={{ color: "red" }}>*</span> : ""}
			</Typography>
			{inputComp}
		</Grid>
	);
}
