import React, { forwardRef } from "react";
import { styled } from "@mui/system";
// import Select from 'react-select';

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import PropTypes from "prop-types";

const StyledSelect = styled(Select)((props) => ({
  "&& legend": {
    width: `${props.label?.length + 1 || 0}ch`,
  },
  input: {
    "&::placeholder": {
      color: "#9b9b9b",
    },
  },
}));
const optionStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected ? "#A3E0FF" : "#fff",
      color: "black",
      disabled: isDisabled,
    };
  },
};

const AdeSelect = forwardRef(({ options, placeholder, defaultValue = "", value, disabled, ...props }, ref) => {
  return (
    <StyledSelect
      defaultValue={defaultValue}
      value={value}
      disabled={disabled}
      ref={ref}
      styles={optionStyles}
      displayEmpty
      {...props}
      MenuProps={{
        style: {
          maxHeight: "300px",
          zIndex: 9999,
        },
      }}
    >
      {placeholder && (
        <MenuItem value="" disabled sx={{color: "#c3c3c3"}}>
          {placeholder}
        </MenuItem>
      )}
      {options.map(({ code, name }, index) => (
        <MenuItem value={code} key={index} selected={defaultValue === code}>
          {name}
        </MenuItem>
      ))}
    </StyledSelect>
  );
});

AdeSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.any,
    })
  ),
  placeholder: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default AdeSelect;
