import Bugsnag from "@bugsnag/js";
import { createSlice } from "@reduxjs/toolkit";
import firebase from "firebase";
import { makeAPIRequest } from "common/helpers";
import { showNotification } from "common/helpers";
import { fileUpload, getFiles } from "common/library/storage";
import { app, storage } from "../../../firebase";
import { updateCurrentUser } from "../../helpers/getCurrentUser";
// import { getExistingCartItems } from "./cart";
import { getCompanyInfo } from "./company";

const userInitialState = {
  profile: null,
  loading: true,
};

const user = createSlice({
  name: "user",
  initialState: userInitialState,
  reducers: {
    setLoading(state, { payload }) {
      state.loading = payload;
    },
    setProfile(state, { payload }) {
      state.profile = payload;
    },
  },
});

export const { setLoading, setProfile } = user.actions;

export default user.reducer;

export const uploadAvatar = (data, uid) => async () => {
  try {
    //Process to upload the image into the bucket
    await fileUpload(data, uid, "avatar");

    // gets the download url then sets the image from firebase as the value for the imgUrl key:
    const downloadUrl = await storage
      .ref(`${uid}/avatar`)
      .child(data.name)
      .getDownloadURL()
      .then((result) => {
        return result;
      });

    return downloadUrl;
  } catch (error) {
    console.error("Error during upload Avatar : ", error);
  }
};

export const uploadDocuments =
  (data, uid, folder = "documents") =>
  async () => {
    try {
      await fileUpload(data, uid, folder);
      const downloadUrl = await storage.ref(`${uid}/${folder}`).child(data.name).getDownloadURL();
      return { fileName: data.name, location: downloadUrl };
    } catch (error) {
      Bugsnag.notify(error, (event) => {
        event.context = "Bugsnag handling";
      });
    }
  };

// Get User Uploaded Files
export const getUserCertificates = async (uid) => {
  const files = await getFiles(uid, "certificates");
  return files;
};

//  To update user password in User Dashboard
export const updateUserPassword = (oldPassword, newPassword) => async () => {
  try {
    const currentUser = app.auth().currentUser;
    const credentials = firebase.auth.EmailAuthProvider.credential(currentUser.email, oldPassword);

    currentUser
      .reauthenticateWithCredential(credentials)
      .then(() => {
        currentUser
          .updatePassword(newPassword)
          .then(() => {
            showNotification("success", "Password Update", ["Successfully updated your profile password."]);
          })
          .catch((error) => {
            console.error("Error during Password Update", error);
          });
      })
      .catch((error) => {
        showNotification("danger", "Password Update", [
          `Failed to updated your profile password due to: ${error.message}`,
        ]);
      });
  } catch (e) {
    console.info("Error during password update", e);
  }
};

// NOTE: is user still being used??
// To update user profile in the user collection if there is any changes done in user dashboard
export const updateUserProfile = (data, uid) => async () => {
  try {
    const submitUpdateProfile = await makeAPIRequest("put", `users/${uid}`, data);
    await updateCurrentUser(); // => reload user data from firebase auth
    await updateUserState(); // => local state?

    if (!submitUpdateProfile.error) {
      showNotification("success", "User Profile Update", [submitUpdateProfile.msg]);
    }
  } catch (error) {
    Bugsnag.notify(error, (event) => {
      event.context = "Bugsnag handling";
    });
    return showNotification("danger", "User Profile Update", [error]);
  }
};

// To update member profile in the member collection if there is any changes done in user dashboard
export const updateMember = (data, uid) => async () => {
  try {
    const memberUpdateResult = await makeAPIRequest("put", `members/${uid}`, data);
    if (!memberUpdateResult.error) {
      showNotification("success", "User Profile Update", [memberUpdateResult.msg]);
    }
  } catch (error) {
    return showNotification("danger", "User Profile Update", [error]);
  }
};

// Updating the user profile information if any changes done.
export const updateUserState = () => async (dispatch) => {
  const currentUser = await updateCurrentUser(); // => reload current user data

  if (currentUser) {
    //  To get existing member data from members collections
    const memberData = await makeAPIRequest("get", `members/${currentUser.uid}`);
    const {
      uid,
      email,
      providerData: [provider],
    } = currentUser || {};

    if (!memberData.error) {
      const { displayName, phoneNumber, userPhotoURL, role, companyId, timezone, permission, cascadePermission } =
        memberData || {};

      const companyInfo = await dispatch(getCompanyInfo(companyId));
      // dispatch(getExistingCartItems(currentUser.uid));

      const user = {
        uid,
        name: displayName,
        email,
        photoURL: userPhotoURL,
        phoneNumber,
        companyId,
        isSeller: companyInfo?.seller?.isSeller,
        sellerStatus: companyInfo?.seller?.status,
        role,
        timezone,
        provider: provider.providerId,
        permission,
        cascadePermission,
      };

      dispatch(setProfile(user));
    }
  }
};

// NOTE: company info would handle seller info
// To submit seller information update / request
export const submitSellerRequest = (data, uid) => async () => {
  try {
    await makeAPIRequest("post", `sellers/${uid}`, data);
    showNotification("success", "Seller Profile Request", ["Seller request is successfully submitted."]);
    await updateCurrentUser();
    await updateUserState();
  } catch (error) {
    Bugsnag.notify(error, (event) => {
      event.context = "Bugsnag handling";
    });
  }
};

export const submitSellerProfileUpdate = (data, uid) => async () => {
  try {
    await makeAPIRequest("post", `sellers/update/${uid}`, data);
    showNotification("success", "Seller Profile Update", ["Seller Profile Update is successfully submitted"]);
    await updateCurrentUser();
    await updateUserState();
  } catch (error) {
    Bugsnag.notify(error, (event) => {
      event.context = "Bugsnag handling";
    });
  }
};

// NOTE: company info would handle credit term request
// To submit Credit Term Request
export const submitCreditTermApplication = (data, uid) => async () => {
  try {
    // const submissionResult = await makeAPIRequest("post", `creditTerm/${uid}`, data);
    await updateCurrentUser();
    await updateUserState();
  } catch (error) {
    Bugsnag.notify(error, (event) => {
      event.context = "Bugsnag handling";
    });
  }
};

export const submitPackageLogisticUpdate = (packageId, body) => async (dispatch) => {
  try {
    const submitLogisticsUpdate = await makeAPIRequest("put", `order/logistics/${packageId}`, body);
    return submitLogisticsUpdate;
  } catch (error) {
    Bugsnag.notify(error, (event) => {
      event.context = "Bugsnag handling";
    });
  }
};

export const submitPackageUpdate = (packageId, data) => async (dispatch) => {
  try {
    const submitPackageUpdate = await makeAPIRequest("put", `order/package/${packageId}`, data);
    return submitPackageUpdate;
  } catch (error) {
    Bugsnag.notify(error, (event) => {
      event.context = "Bugsnag handling";
    });
  }
};
