import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";
import LoadingAirplane from "../assets/airplane.json";

const Loading = ({ style }) => {
  const anime = useRef(null);
  useEffect(() => {
    lottie.loadAnimation({
      container: anime.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: LoadingAirplane,
    });
    return () => lottie.stop();
  }, []);
  return <div ref={anime} style={style}></div>;
};

export default Loading;
