import React from 'react';
import { Store } from "react-notifications-component";
import IconFailed from "common/images/icons/Icon-Failed.svg";
import IconTick from "common/images/icons/Icon-Tick.svg";
import IconClose from "common/images/icons/Close-Icon.svg";
import { curry } from "lodash/fp";

const ShowIconFailed = () => {
  return <img src={IconFailed} style={{ marginRight: 9 }} alt="" />;
};
const ShowIconTick = () => {
  return <img src={IconTick} style={{ marginRight: 9 }} alt="" />;
};
const ShowIconClose = () => {
  return <img src={IconClose} alt="" />;
};

export const showNotification = curry((type, title, contents) => {
  const message = contents.map((content, index) => {
    return (
      <span key={"content-" + index} style={{ fontFamily: "Gotham-Book" }}>
        {content}
        <br />
      </span>
    );
  });

  function NotificationContent() {
    let showBorderBottom = "9px solid #6AB47B";
    if (type === "danger") showBorderBottom = "9px solid #FC3F3F";

    return (
      <div
        style={{
          display: "flex",
          padding: "25px",
          width: "500px",
          backgroundColor: "#ffffff",
          color: "#333333",
          borderRadius: "0 0 4px 4px",
          boxShadow: "0px 3px 15px #39445833",
          borderBottom: showBorderBottom,
          zIndex: "999999",
        }}
      >
        {type === "danger" ? <ShowIconFailed /> : <ShowIconTick />}
        {message}
        <span
          style={{
            position: "absolute",
            top: "10px",
            right: "16px",
          }}
        >
          <ShowIconClose />
        </span>
      </div>
    );
  }

  Store.addNotification({
    // title: title,
    // message: <NotificationContent contents={contents} />,
    content: NotificationContent,
    type: type,
    insert: "top",
    isMobile: true,
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    display: "none",
    dismiss: {
      duration: 10,
      onScreen: false,
    },
  });
});

export const notificationSuccess = showNotification("success", "Success");
export const notificationDanger = showNotification("danger", "Error");
