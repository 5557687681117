
import React from 'react';
import { Fragment, useEffect, useState } from "react";
import useGetGQLTable from "./useGetGQLTable";
import { TableContainer, Paper, Table, } from "@mui/material";
import { GQLNODESLIMIT } from "./constants";
import CustomPagination from "./CustomPagination";
import CustomHead from "./CustomHead";
import CustomBody from "./CustomBody";
import { jsonToMap, rearrangeMapItems } from "../../../utils/jsonManipulation";
import { isEmpty } from "../../../utils/checks";
import axios from "axios";
/**
 * @description steps of data processed :
 * 1. Reconstruct
 * 2. Omit (based reconstruct)
 * 3. Rename (based on omitted)
 * 4. Sort (based on renamed)
 * 5. Sentences cased (based on sort)
 * 6. Adaptation - data converted to JS Map structure
 * @description to use getRefetch :
 * 1. create a state to hold it >> const [refetch, setrefetch]: any = useState({ funct: null })
 * 2. set the state value to the refetch function >> getRefetch={(refecth) => { setrefetch(refecth) }}
 * 3. use it in a component >> <button onClick={() => { if (refetch?.funct) { refetch.funct() }}}> refetch </button>
 * @param {PagedGqlTableMainProps} props props
 * @returns a table
 */
export default function PagedGqlTableMain({ querySign, apiUrl, queryStringInjectedOffset, reStructureDatas, getRefetch, omitKeys = [], renameKeys, actions, priorityArrangement = [], getAllDatas = () => { }, containerStyle = {}, autoScrollToTopPageEnabled = true, devMode = false, sortButton = {}, gqlEdgesLimit = GQLNODESLIMIT, onRowClick, }) {
    const [clickedPageNumber, setClickedPageNumber] = useState(false);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [curPage, setCurPage] = useState(1);
    const [fetchCount, setFetchCount] = useState(1);
    const offset = gqlEdgesLimit * (fetchCount - 1);
    const source = axios.CancelToken.source();
    // query datas
    const { isLoading, data, totalCount, refetch } = useGetGQLTable({
        gqlquery: queryStringInjectedOffset(offset),
        querySign,
        url: `${apiUrl}`,
        cancelToken: source.token,
    });
    // callback to get latest all datas
    useEffect(() => {
        getAllDatas(data);
        // set refetch method to the getRefetch callback
        if (getRefetch) {
            getRefetch({
                funct: refetch
            });
        }
        if (!clickedPageNumber) {
            setCurPage(1);
            setFetchCount(1);
        }
        setClickedPageNumber(false);
    }, [data]);
    // variables for re-fetching 
    const numOfPages = totalCount ? Math.ceil(totalCount / itemsPerPage) : 1;
    const upperThreshold = (gqlEdgesLimit / itemsPerPage) * fetchCount;
    const lowerThreshold = (gqlEdgesLimit / itemsPerPage) * (fetchCount - 1);
    const upperIndex = curPage * itemsPerPage - gqlEdgesLimit * (fetchCount - 1);
    const lowerIndex = (curPage - 1) * itemsPerPage - gqlEdgesLimit * (fetchCount - 1);
    // 1) reconstruct - replace object structure with structure defined by its instance AND remove omitted key-value
    const restructuredDatas = reStructureDatas ? reStructureDatas(data) : data;
    // 2) omit - omit based on ommited list from reconstructed
    const omitedDatas = restructuredDatas?.map((item) => {
        const newItem = {};
        if (!isEmpty(item, { isNanIsEmpty: true })) {
            Object.keys(item).map((key) => {
                if (!omitKeys.includes(key)) {
                    newItem[key] = item[key];
                }
            });
            return newItem;
        }
        else {
            return item;
        }
    }) ?? [];
    // 3) rename - rename based on omit
    const renamedDatas = omitedDatas.map((item) => {
        const newItem = {};
        if (!isEmpty(item, { isNanIsEmpty: true })) {
            Object.keys(item).map((key) => {
                const foundThisKeyIndex = renameKeys?.findIndex(({ from }) => {
                    return key === from;
                });
                if (foundThisKeyIndex !== -1 && foundThisKeyIndex !== undefined && renameKeys) { // exist
                    newItem[renameKeys[foundThisKeyIndex].to] = item[key];
                }
                else {
                    return newItem[key] = item[key];
                }
            });
            return newItem;
        }
        else {
            return item;
        }
    });
    // 4) sort - based on renamed keys
    const renamedDataKeys = renamedDatas[0] ? Object.keys(renamedDatas[0]) : [];
    const sortedKeys = [];
    priorityArrangement.map((priorityKey) => {
        if (renamedDataKeys.includes(priorityKey)) {
            sortedKeys.push(priorityKey);
        }
    });
    renamedDataKeys.map((renamedDataKey) => {
        if (!sortedKeys.includes(renamedDataKey)) {
            sortedKeys.push(renamedDataKey);
        }
    });
    // rearanged the json according to priorityArrangment
    const convertedToMaps = renamedDatas.map((item) => {
        return rearrangeMapItems(jsonToMap(item), sortedKeys);
    });
    // Scroll to the top of the page on component load
    useEffect(() => {
        if (autoScrollToTopPageEnabled) {
            window.scrollTo(0, 0);
        }
        // Optional cleanup function
        return () => {
            // Perform cleanup if needed
        };
    }, []);
    return (<Fragment>
            <TableContainer component={Paper} sx={{
            boxShadow: "none",
            borderRadius: "12px",
            border: "1px solid #E8E8E8",
            ...containerStyle,
        }}>
                <Table>
                    <CustomHead actions={actions} dataKeys={sortedKeys} renameKeys={renameKeys} sortButton={sortButton}/>
                    <CustomBody itemsPerPage={itemsPerPage} data={data} isLoading={isLoading} rawDatas={data} convertedToMaps={convertedToMaps} lowerIndex={lowerIndex} upperIndex={upperIndex} actions={actions} devMode={devMode} onRowClick={onRowClick}/>
                </Table>
            </TableContainer>
            <CustomPagination itemsPerPage={itemsPerPage} setItemsPerPage={setItemsPerPage} curPage={curPage} numOfPages={numOfPages} setCurPage={setCurPage} setFetchCount={setFetchCount} upperThreshold={upperThreshold} lowerThreshold={lowerThreshold} setClickedPageNumber={setClickedPageNumber}/>
        </Fragment>);
}
