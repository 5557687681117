import React from "react";
import { Grid, Pagination, Select, MenuItem, OutlinedInput, InputLabel } from "@mui/material";
import { useState } from "react";

const MUIPagination = ({ page, setPage, setPerPage, totalPage, classes, totalRows, sliceStart, initialPerPage }) => {
  const [value, setValue] = useState(initialPerPage ? initialPerPage : 50);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleChangeRowsPerPage = (event) => {
    setValue(String(event.target.value));
    setPerPage(String(event.target.value));
  };

  const pageCount = () => {
    let startPage = value * totalPage - value;
    let end = value * page;
    let start = end - value + 1;
    start = sliceStart + 1;
    if (totalRows < end) end = totalRows;
    if (start > totalRows) start = Math.floor(startPage) + 1;
    if (end - start > value) end = parseInt(start) + parseInt(value) - 1;
    if (end === 0) end = value;
    if (start < 0) start = 1;
    if (totalRows === 0) end = start = totalRows;
    return <>{start.toLocaleString() + ` - ` + end.toLocaleString()}</>;
  };
  return (
    <>
      <Grid container columns={8} sx={{ marginTop: "20px", marginBottom: "20px" }}>
        <Grid
          items
          xs={2}
          sx={{ display: "flex", justifyContent: "flex-start", paddingLeft: "20px", paddingTop: "8px" }}
        >
          {pageCount()}
        </Grid>
        <Grid
          items
          xs={6}
          sx={{ display: "flex", justifyContent: "flex-end", paddingRight: "14px", paddingTop: "0px" }}
        >
          <span style={{ marginTop: "8px" }}>Rows per page:</span>
          <Select
            value={value}
            input={
              <OutlinedInput
                notched={false}
                onChange={handleChangeRowsPerPage}
                style={{ marginLeft: 10, marginRight: "16px", maxHeight: "40px" }}
                size="small"
                label={<InputLabel shrink />}
              />
            }
            // sx={{ height: "32px" }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={30}>30</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
          <Pagination
            defaultPage={page}
            page={page}
            count={totalPage}
            variant="outlined"
            shape="rounded"
            size="large"
            siblingCount={1}
            onChange={handleChange}
            sx={{ zIndex: "9" }}
            classes={classes}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default MUIPagination;
