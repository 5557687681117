import { Grid, useMediaQuery } from "@mui/material";
import MagnifyingGlass from "common/images/icons/elevade/MagnifyingGlass.svg";
import styles from "./SearchBar.module.css";
import FilterBox from "./FilterBox";
import React, { useState } from "react";

const SearchBar = ({
  data,
  open,
  setOpen,
  isSearch,
  setIsSearch,
  isFilters,
  setIsFilters,
  openTitle,
  setOpenTitle,
}) => {
  const isWeb = useMediaQuery("(min-width:900px)");
  const [search, setSearch] = useState("");

  const style = {
    input: {
      border: "0px",
      borderRight: isWeb ? "0px solid #c3c3c3" : "0px solid #c3c3c3",
      paddingLeft: "32px",
      width: "100%",
      maxWidth: "310px",
      marginTop: "10px",
      backgroundImage: `url(${MagnifyingGlass})`,
      backgroundRepeat: "no-repeat",
      backgroundPositionY: "5px",
      backgroundSize: "16px",
      backgroundColor: "transparent",
      fontFamily: "Gotham-Book",
      "&&:focus": {
        outline: "none",
      },
    },
  };

  const handleKeyDown = (event) => {
    let key = "search";
    if (event.key === "Enter") {
      if (event.target.value !== "") {
        const updatedChecked = [...isFilters[key], event.target.value.toUpperCase()];
        const updatedFilters = { ...isFilters, [key]: updatedChecked };
        setIsFilters(updatedFilters);
      }
      event.currentTarget.value = "";
      setSearch("");
    }
  };

  const handleChange = (event) => {
    setSearch(event.target.value.toUpperCase());
  };

  return (
    <>
      <Grid container columns={2}>
        <Grid item xs={0.5} sx={{ paddingLeft: "1px" }}>
          <input
            type="text"
            placeholder="Search"
            style={style.input}
            className={`${styles.input}`}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            // defaultValue={isSearch}
            value={search}
          />
        </Grid>
        <Grid item xs={1.5} className={styles.gridJustifyEnd}>
          <FilterBox
            open={open}
            setOpen={setOpen}
            data={data}
            title={"Station"}
            isFilters={isFilters}
            setIsFilters={setIsFilters}
            isLast={false}
            openTitle={openTitle}
            setOpenTitle={setOpenTitle}
          />
          <FilterBox
            open={open}
            setOpen={setOpen}
            data={data}
            title={"Aircraft Reg"}
            isFilters={isFilters}
            setIsFilters={setIsFilters}
            isLast={false}
            openTitle={openTitle}
            setOpenTitle={setOpenTitle}
          />
          <FilterBox
            open={open}
            setOpen={setOpen}
            data={data}
            title={"Raised Date"}
            isFilters={isFilters}
            setIsFilters={setIsFilters}
            isLast={false}
            openTitle={openTitle}
            setOpenTitle={setOpenTitle}
            disableFuture={true}
          />
          <FilterBox
            open={open}
            setOpen={setOpen}
            data={data}
            title={"Expiry Date"}
            isFilters={isFilters}
            setIsFilters={setIsFilters}
            isLast={false}
            openTitle={openTitle}
            setOpenTitle={setOpenTitle}
            disableFuture={false}
          />
          <FilterBox
            open={open}
            setOpen={setOpen}
            data={data}
            title={"Department"}
            isFilters={isFilters}
            setIsFilters={setIsFilters}
            isLast={false}
            openTitle={openTitle}
            setOpenTitle={setOpenTitle}
          />
          <FilterBox
            open={open}
            setOpen={setOpen}
            data={data}
            title={"Summary Status"}
            isFilters={isFilters}
            setIsFilters={setIsFilters}
            isLast={false}
            openTitle={openTitle}
            setOpenTitle={setOpenTitle}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SearchBar;
