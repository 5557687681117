/* eslint-disable no-unused-expressions */
import React from 'react';
import { seatColors } from "../SeatA1";
import boxRowIcons from "./BoxRowIcons";
export function boxCategorizer(invs) {
	const cated = {};
	invs.forEach((inFleetLocation) => {
		const { inventory } = inFleetLocation;
		const { item } = inventory ?? {};
		const { code } = item ?? {};
		if (cated[code]) {
			cated[code].push(inFleetLocation);
		} else {
			cated[code] = [];
			cated[code].push(inFleetLocation);
		}
	});
	return cated;
}
export function boxColorBasedOnStatus(itemStatus) {
	let color = "white";
	switch (itemStatus?.code) {
		case "D":
			color = seatColors["dot"];
			break;
		case "E":
			color = seatColors["blue"];
			break;
		case "L":
			color = seatColors["red"];
			break;
		case "NA":
			color = seatColors["black"];
			break;
		case "R":
			color = seatColors["grey"];
			break;
		case "T":
			color = seatColors["orange"];
			break;
		case "A":
			color = seatColors["white"];
			break;
		default:
			color = "white";
			break;
	}
	return color;
}
export function getNumberFromSeatNo(inFleetLocation) {
	let num = Number(inFleetLocation.locationId.seatNo.slice(-2));
	if (Number.isNaN(num)) {
		return 0;
	} else {
		return Number(num);
	}
}
export function getBoxDataFromInFleetLocations(invs) {
	const categorized = boxCategorizer(invs);
	const data = {};
	Object.keys(categorized).map((key) => {
		if (data[key] ?? undefined) {
			categorized[key].map((inFleetLocation) => {
				const boxColor = boxColorBasedOnStatus(
					inFleetLocation?.inventory?.status
				);
				data[key]?.data?.push({
					color: boxColor,
					data: inFleetLocation,
					value: getNumberFromSeatNo(inFleetLocation),
				});
			});
		} else {
			data[key] = {
				data: [],
				Icon: boxRowIcons({ iconFor: key }),
			};
			categorized[key].map((inFleetLocation) => {
				const boxColor = boxColorBasedOnStatus(
					inFleetLocation?.inventory?.status
				);
				data[key]?.data?.push({
					color: boxColor,
					data: inFleetLocation,
					value: getNumberFromSeatNo(inFleetLocation),
				});
			});
		}
	});
	return data;
}
