import React from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";

export const AdeTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    backgroundColor: "#0aaeff",
  },
  "& .MuiButtonBase-root": { outline: "none" },
});

// add disableRipple
export const AdeTab = styled(Tab)({
  fontFamily: "gotham-book",
  textTransform: "none",
  color: "#333",
  fontSize: "16px",
  "&.Mui-selected": {
    fontFamily: "gotham-medium",
    color: "#333",
  },
});

const AdeTabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export default AdeTabPanel;

AdeTabPanel.propTypes = {
  value: PropTypes.number,
  index: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.elementType]),
};
