import React from "react";
import { Grid, styled, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import DDMLUpdateLogs from "./DDMLUpdateLogs";
import Summary from "./Summary";
import styles from "./DDML.module.css";

const SummaryDDMLForm = ({ value, handleChange, ...props }) => {
  const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    fontFamily: ["Gotham-Book"].join(","),
    fontSize: "14px",
    "&:hover": {
      color: "#333333",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#333333",
      fontFamily: "Gotham-Medium",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
      paddingBottom: "10px",
    },
    "&.MuiTab-root": {
      marginRight: "10px",
      marginTop: "-10px",
      marginBottom: "-10px",
    },
  }));

  let data = props.data;
  if (props.data.ddml) data = props.data.ddml;

  const Tips = ({ val }) => {
    return (
      <span
        style={{
          borderRadius: "30px",
          color: "#ffffff",
          backgroundColor: "#E95048",
          fontFamily: "Gotham-Book",
          fontSize: "12px",
          marginLeft: "8px",
          padding: "4px 8px 2px 8px",
        }}
      >
        {val}
      </span>
    );
  };

  return (
    <>
      <Grid container columns={4}>
        <TabContext value={value} sx={{ marginLeft: "-60px" }}>
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            variant="scrollable"
            scrollButtons={false}
            sx={{
              paddingTop: "10px",
              paddingRight: "10px",
              position: "fixed",
              top: "121px",
              transform: "translateX(-0%)",
              zIndex: "10",
              backgroundColor: "#ffffff",
              borderBottom: "2px solid #e8e8e8",
              borderTop: "0.5px solid #C3C3C3",
            }}
          >
            <AntTab label="Summary" value="1" />
            <AntTab
              label="Rectification Update"
              value="2"
              icon={
                (props.initialData?.unreadLogs?.rectification.length > 0 ||
                  props.initialData?.ddml?.unreadLogs?.rectification.length > 0) && (
                  <Tips
                    val={
                      props.initialData?.unreadLogs?.rectification.length ||
                      props.initialData?.ddml?.unreadLogs?.rectification.length
                    }
                  />
                )
              }
              iconPosition="end"
            />
            <AntTab
              label="Material/Tools Update"
              value="3"
              icon={
                (props.initialData?.unreadLogs?.materialTool.length > 0 ||
                  props.initialData?.ddml?.unreadLogs?.materialTool.length > 0) && (
                  <Tips
                    val={
                      props.initialData?.unreadLogs?.materialTool.length ||
                      props.initialData?.ddml?.unreadLogs?.materialTool.length
                    }
                  />
                )
              }
              iconPosition="end"
            />
            <AntTab
              label="Planning/Tech Service"
              value="4"
              icon={
                (props.initialData?.unreadLogs?.planningTech.length > 0 ||
                  props.initialData?.ddml?.unreadLogs?.planningTech.length > 0) && (
                  <Tips
                    val={
                      props.initialData?.unreadLogs?.planningTech.length ||
                      props.initialData?.ddml?.unreadLogs?.planningTech.length
                    }
                  />
                )
              }
              iconPosition="end"
            />
          </TabList>
          <div
            style={{
              width: "100%",
              height: "0px",
              backgroundColor: "#E8E8E8",
              marginTop: "100px",
            }}
          ></div>
          <div
            style={{
              marginLeft: "0px",
              marginRight: "-20px",
              paddingTop: "10px",
              width: "100%",
              overflow: "auto",
            }}
            className={`${styles.macScrollbar}`}
          >
            <TabPanel value="1" sx={{ padding: "0px" }}>
              <Summary
                dataForm={data}
                initialData={props.initialData}
                calculateCalendarExpiryDate={props.calculateCalendarExpiryDate}
                getSummary={props.getSummary}
                calculateCalendarBalance={props.calculateCalendarBalance}
                stringAvatar={props.stringAvatar}
                splitToChucks={props.splitToChucks}
                openPreview={props.openPreview}
                setOpenPreview={props.setOpenPreview}
                handleClose={props.handleClose}
                summaryPreviewUrl={props.summaryPreviewUrl}
                calculateExpiryDate={props.calculateExpiryDate}
                {...props}
              />
            </TabPanel>
            <TabPanel value="2" sx={{ padding: "0px", position: "relative" }}>
              <DDMLUpdateLogs
                tab={"Rectification Update"}
                DDMLLogs={props.DDMLLogs}
                handleCopy={props.handleCopy}
                handleOpenComment={props.handleOpenComment}
                ConvertDate={props.ConvertDate}
                openPreviewComment={props.openPreviewComment}
                handleCloseComment={props.handleCloseComment}
                style={props.style}
                commentFileLink={props.commentFileLink}
                uploadComment={props.uploadComment}
                setUploadComment={props.setUploadComment}
                ddmlStatus={data.ddmlStatus}
                unreadLogs={
                  props.initialData?.unreadLogs?.rectification || props.initialData?.ddml?.unreadLogs?.rectification
                }
                {...props}
              />
            </TabPanel>
            <TabPanel value="3" sx={{ padding: "0px" }}>
              <DDMLUpdateLogs
                tab={"Material/Tools Update"}
                DDMLLogs={props.DDMLLogs}
                handleCopy={props.handleCopy}
                handleOpenComment={props.handleOpenComment}
                ConvertDate={props.ConvertDate}
                openPreviewComment={props.openPreviewComment}
                handleCloseComment={props.handleCloseComment}
                style={props.style}
                commentFileLink={props.commentFileLink}
                uploadComment={props.uploadComment}
                setUploadComment={props.setUploadComment}
                unreadLogs={
                  props.initialData?.unreadLogs?.materialTool || props.initialData?.ddml?.unreadLogs?.materialTool
                }
                {...props}
              />
            </TabPanel>
            <TabPanel value="4" sx={{ padding: "0px" }}>
              <DDMLUpdateLogs
                tab={"PPC"}
                DDMLLogs={props.DDMLLogs}
                handleCopy={props.handleCopy}
                handleOpenComment={props.handleOpenComment}
                ConvertDate={props.ConvertDate}
                openPreviewComment={props.openPreviewComment}
                handleCloseComment={props.handleCloseComment}
                style={props.style}
                commentFileLink={props.commentFileLink}
                uploadComment={props.uploadComment}
                setUploadComment={props.setUploadComment}
                unreadLogs={
                  props.initialData?.unreadLogs?.planningTech || props.initialData?.ddml?.unreadLogs?.planningTech
                }
                {...props}
              />
            </TabPanel>
          </div>
        </TabContext>
      </Grid>
    </>
  );
};

export default SummaryDDMLForm;
