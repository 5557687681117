import React from "react";
import { Link } from "react-router-dom";
import Error404 from "common/images/Error-404.svg";
import styles from "./GenericNotFound.module.css";

const ErrorNotFound = () => {
  return (
    <div className="">
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12">
          <img src={Error404} className={styles.errorImage} alt="icon" />
        </div>
        <div className="rol-lg-6 col-md-6 col-sm-12" style={{ margin: "auto", textAlign: "center" }}>
          <p style={{ fontFamily: "Gotham-Bold", fontSize: "24px" }}>Opps! Page not found…</p>
          <p style={{ fontFamily: "Gotham-Book", fontSize: "16px" }}>
            Sorry, the page you requested could not be found. Please go back to the{" "}
            <Link to="/fleet" style={{ textDecoration: "underline", color: "#2F77FF" }}>
              homepage
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ErrorNotFound;
