import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Grid, styled, Tab, Avatar, Tooltip, tooltipClasses, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import styles from "../Dasr.module.css";
import IconErrorAirBalloon from "common/images/icons/elevade/Icon-ErrorAirBalloon.svg";
import DDML from "./DDML";
import SearchBar from "./SearchBar";
import ActiveFilters from "./ActiveFilters";
import {
  addingDate,
  calculateBalanceDay,
  calculateCalendarBalance,
  calculateCalendarExpiryDate,
  formatDateDasr,
} from "pages/elevade/component/Helpers";
import MUIPagination from "pages/elevade/component/Pagination";
import { makeStyles } from "@mui/styles";
import { makeAPIRequestMDB } from "common/helpers/makeAPIRequestMDB";
import Overlay from "../../component/Overlay";
import NoData from "pages/elevade/component/DataUnavailable";
import IconAirplaneLimitation from "common/images/icons/elevade/Icon-AirplaneLimitation.svg";
import IconDoubleTick from "common/images/icons/elevade/Icon-DoubleTick.svg";
import IconDoubleTickWhite from "common/images/icons/elevade/Icon-DoubleTickWhite.svg";
import IconDoubleTickDisabled from "common/images/icons/elevade/Icon-DoubleTickDisabled.svg";
import IconReInspection from "common/images/icons/elevade/Icon-ReInspection.svg";
import { CheckBoxField, CheckBoxFieldMock } from "pages/elevade/component/InputForm";
import ElevadeDrawer from "../component/Drawer";
import Button from "pages/elevade/component/Button";
import DialogApprove from "./DialogApprove";
import DateToggle from "../component/DateToggle";
import AirplaneLoader from "pages/elevade/component/AirplaneLoader";
import IconBackArrow from "common/images/icons/elevade/Icon-BackArrow.svg";
import { makeAPIRequestRun } from "common/helpers";

const ClosedAircraftReport = ({ aoc, isLoading, setIsLoading }) => {
  const { userPermission } = useSelector((state) => state.userEntity);
  const summaryPermission = userPermission.filter((perm) => perm.code === "DASR-004");
  const [subLoading, setSubLoading] = useState(true);
  const [type, setType] = useState("1");
  const [value, setValue] = useState("1");
  const [open, setOpen] = useState(false);
  const [openTitle, setOpenTitle] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState([]);
  const [data_, setData_] = useState([]);
  const [dataForm, setDataForm] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [index, setIndex] = useState();
  const [edit, setEdit] = useState(true);
  const [openNotification, setOpenNotification] = useState(false);
  const [newForm, setNewForm] = useState(false);
  const [triggerApi, setTriggerApi] = useState(true);
  const [activeIndex, setActiveIndex] = useState("");
  const [selectToMove, setSelectToMove] = useState([]);
  const [openDialogApprove, setOpenDialogApprove] = useState(false);
  const [openDialogMoveToAssignment, setOpenDialogMoveToAssignment] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [isSearch, setIsSearch] = useState([]);
  const InitialFilters = { search: [], location: [], aircraftReg: [], assignedTo: [], raisedDate: [], expiryDate: [] };
  const [isFilters, setIsFilters] = useState(InitialFilters);
  const [filteredData, setFilteredData] = useState([]);
  const [date, setDate] = useState(new Date());
  const [today, setToday] = useState(new Date());
  const [isDate, setIsDate] = useState(new Date());
  const [tableLoading, setTableLoading] = useState(false);
  const [dataFHFC, setDataFHFC] = useState([]);
  const [countPending, setCountPending] = useState(0);
  const [countApproved, setCountApproved] = useState(0);

  let sliceStart = page > 0 ? perPage * (page - 1) : 0;

  useEffect(() => {
    setIsLoading(false);
    // eslint-disable-next-line
  }, []);

  function setInitialDate() {
    var currentDate = new Date();
    var options = { month: "long", day: "2-digit", year: "numeric" };
    var formattedDate = currentDate.toLocaleDateString("en-US", options);
    setIsDate(formattedDate);
    setToday(formattedDate);
    // Set yesterday's date
    currentDate.setDate(currentDate.getDate() - 1);
    // setYesterday(currentDate.toLocaleDateString("en-US", options));
  }

  useEffect(() => {
    setInitialDate();
  }, []);

  useEffect(() => {
    setData_(data);
  }, [data, type]);

  useEffect(() => {
    let theTab = "";
    if (value === "1") theTab = "closed";
    else if (value === "2") theTab = "approved";
    setTotalRows(filteredData?.filter((e) => e.formStatus === theTab).length);
    setTotalPage(Math.floor(filteredData?.filter((e) => e.formStatus === theTab).length / perPage) + 1);
  }, [filteredData, perPage, value]);

  useEffect(() => {
    fetch();
    // TODO: to include "value" as dependencies when api ready
  }, [openNotification, triggerApi, aoc, date, value]);

  useEffect(() => {
    if (!open) setActiveIndex();
  }, [open]);

  useEffect(() => {
    setSelectToMove([]);
    setCheckAll(false);
  }, [value]);

  // Applying filters
  useEffect(() => {
    let data__ = [];
    let theData = data;
    setFilteredData(theData);

    // Search input fields
    if (isFilters.search.length !== 0) {
      data__ = theData.filter(
        (item) =>
          isFilters.search.some((searchTerm) => item.location?.toLowerCase()?.includes(searchTerm?.toLowerCase())) ||
          isFilters.search.some((searchTerm) => item.aircraftReg?.toLowerCase()?.includes(searchTerm?.toLowerCase())) ||
          isFilters.search.some((searchTerm) => item.ddmlNo?.toLowerCase()?.includes(searchTerm?.toLowerCase())) ||
          isFilters.search.some((searchTerm) =>
            item.limitationsDesc?.toLowerCase()?.includes(searchTerm?.toLowerCase())
          ) ||
          isFilters.search.some((searchTerm) => item.assignedTo?.toLowerCase()?.includes(searchTerm?.toLowerCase())) ||
          isFilters.search.some((searchTerm) => item.actionBy145?.toLowerCase()?.includes(searchTerm?.toLowerCase())) ||
          isFilters.search.some((searchTerm) =>
            item.statusLog?.updateLogDesc?.toLowerCase()?.includes(searchTerm?.toLowerCase())
          )
      );
      setFilteredData(data__);
    }

    // Station (location)
    if (isFilters.location.length > 0) setFilteredData(theData.filter((e) => isFilters.location.includes(e.location)));
    // Aircraft Registration
    if (isFilters.aircraftReg.length > 0)
      setFilteredData(theData.filter((e) => isFilters.aircraftReg.includes(e.aircraftReg)));
    // Assigned To (Department)
    if (isFilters.assignedTo.length > 0)
      setFilteredData(theData.filter((e) => isFilters.assignedTo.includes(e.assignedTo)));
    // Raised Date
    if (isFilters.raisedDate.length > 0)
      setFilteredData(theData.filter((e) => e.raisedDate.startsWith(isFilters.raisedDate)));
    // Expiry Date
    if (isFilters.expiryDate.length > 0)
      setFilteredData(theData.filter((e) => e.expiryDate.startsWith(isFilters.expiryDate)));
  }, [isFilters, data]);

  useEffect(() => {
    if (selectToMove.length === 0) setCheckAll(false);
  }, [selectToMove]);

  async function fetchFHFC() {
    // setSubLoading(true);
    const fetch = makeAPIRequestRun(`get`, `acarsdata/fleetgetfcfh/null`);
    fetch
      .then((response) => {
        setDataFHFC(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useEffect(() => {
    fetchFHFC();
  }, []);

  function formatDate(dateString) {
    // Parse the input date string
    const dateObj = new Date(dateString);

    // Get the year, month, and day components
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");

    // Construct the formatted date string
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }

  async function fetch(status) {
    const currentDate = new Date(isDate);
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const isToday = `${year}-${month}-${day}`;

    setTableLoading(true);

    let theTab = "";
    if (value === "1") theTab = "closed";
    else if (value === "2") theTab = "approved";
    let dateParams = ``;
    let dateColumn = ``;
    if (value === "2") {
      dateParams = `&date=${formatDate(date)}`;
      dateColumn = `&date_column=approvedAt`;
    }
    // if (theTab === "closed") dateParams = `&date=${isToday}`;
    // if (theTab === "closed") dateColumn = `&date_column=updatedAt`;
    let params = `api/ddml?aoc=${aoc}${dateParams}${dateColumn}`;
    if (isToday !== "NaN-NaN-NaN") {
      const fetch = makeAPIRequestMDB(`get`, params);
      fetch
        .then((response) => {
          setData(response.data);
          setFilteredData(response.data?.filter((e) => e.formStatus === theTab));
          setSubLoading(false);
          setOpen(false);
          setOpenNotification(false);
          setTriggerApi(false);
          setTableLoading(false);
        })
        .catch((error) => {
          console.log("error", error);
          setSubLoading(false);
        });

      let paramsCount = `api/ddml/form-status/count?form_status=approved&date=${formatDate(date)}`;
      const fetchCount = makeAPIRequestMDB("get", paramsCount);
      fetchCount
        .then((response) => {
          setCountApproved(response.data.count);
        })
        .catch((error) => {
          console.log("error", error);
          setSubLoading(false);
        });
    }
  }

  useEffect(() => {
    let theTab = "";
    if (value === "1") theTab = "closed";
    else if (value === "2") theTab = "approved";
    let filteredData = data?.filter((e) => e.formStatus === theTab);
    setFilteredData(filteredData);
    if (value === "1") setCountPending(filteredData.length);
  }, [value, data]);

  // Custom styles
  const useStyles = makeStyles(() => ({
    ul: {
      "& .Mui-selected": {
        color: "#fff",
        backgroundColor: "#088FD1",
        border: "1px solid #088FD1",
        "&:hover": {
          backgroundColor: "#088FD1",
        },
      },
    },
    gridItem: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-box-orient": "vertical",
      "-webkit-line-clamp": 2, // Number of lines to show
      fontSize: "14px",
    },
  }));

  const classes = useStyles();

  const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    // padding: 0,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    fontFamily: ["Gotham-Book"].join(","),
    "&:hover": {
      color: "#333333",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#333333",
      fontFamily: "Gotham-Medium",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  }));

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setType(newValue);
  };

  const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#FFFEE8",
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: 11,
      },
    })
  );

  const ErrorAirBallon = () => {
    return (
      <>
        <div className={styles.errorAirBallon}>
          <center>
            <img src={IconErrorAirBalloon} alt="error-air-ballon" />
            <div className={styles.errorTitle}>No Report Available</div>
            <div className={styles.errorSubTitle}></div>
          </center>
        </div>
      </>
    );
  };

  const Tips = ({ val, unread }) => {
    return (
      <>
        <span
          style={{
            borderRadius: "30px",
            color: unread ? "#ffffff" : "#838383",
            border: unread ? "1px solid #E95048" : "1px solid #E8E8E8",
            backgroundColor: unread ? "#E95048" : "#F7F7F7",
            fontFamily: "Gotham-Book",
            fontSize: "12px",
            marginLeft: "8px",
            padding: "4px 8px 2px 8px",
          }}
        >
          {val}
        </span>
      </>
    );
  };

  const AircraftReportList = ({ type }) => {
    return (
      <>
        <Grid container columns={4} sx={{ marginTop: "0px" }}>
          {/* <Grid item xs={4} className={styles.gridTitle}>
            {type} List
          </Grid> */}
          {data_?.length > 0 && (
            <>
              <Grid item xs={4} sx={{ paddingTop: "32px", paddingLeft: "16px" }}>
                <SearchBar
                  open={openModal}
                  setOpen={setOpenModal}
                  data={data_}
                  isSearch={isSearch}
                  setIsSearch={setIsSearch}
                  isFilters={isFilters}
                  setIsFilters={setIsFilters}
                  openTitle={openTitle}
                  setOpenTitle={setOpenTitle}
                />
                <ActiveFilters isFilters={isFilters} setIsFilters={setIsFilters} initialFilters={InitialFilters} />
              </Grid>
              <Grid item xs={4} sx={{ display: "flex", paddingTop: "32px" }}>
                {value === "1" && filteredData.length > 0 && (
                  <Button
                    title="Approve"
                    icon={IconDoubleTick}
                    iconWhite={IconDoubleTickWhite}
                    iconDisabled={IconDoubleTickDisabled}
                    variant={selectToMove.length > 0 ? "outlined" : "disabled"}
                    onClick={() => setOpenDialogApprove(true)}
                  />
                )}
              </Grid>
              <Grid item xs={4} sx={{ paddingTop: "16px" }}>
                {subLoading ? <Overlay /> : <TableList type={type} />}
              </Grid>
            </>
          )}
          {filteredData?.length === 0 && !subLoading && (
            <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }}>
              <NoData />
            </Grid>
          )}
          {tableLoading && (
            <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }}>
              <AirplaneLoader />
            </Grid>
          )}
        </Grid>
      </>
    );
  };

  function handleClick(e, index) {
    setOpen(true);
    setIndex(index);
    setDataForm(e);
    setEdit(false);
    setActiveIndex(index);
    setNewForm(false);
  }

  function handleSelect() {
    if (checkAll) return <CheckBoxFieldMock isCheckedAll={true} setIsCheckedAll={setCheckAll} />;
    else if (selectToMove.length > 0 && selectToMove.length < totalRows) {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          onClick={() => setCheckAll(!checkAll)}
          style={{ cursor: "pointer" }}
        >
          <rect width="20" height="20" rx="4" fill="#088FD1" />
          <path d="M6 10L14 10" stroke="white" stroke-width="3" stroke-linecap="round" />
        </svg>
      );
    } else {
      return summaryPermission.some((perm) => perm.canUpdate === true) ? (
        <CheckBoxFieldMock
          isCheckedAll={selectToMove.length > 0 && totalRows > 0 && selectToMove.length === totalRows}
          setIsCheckedAll={setCheckAll}
        />
      ) : (
        <CheckBoxField id={""} selected={selectToMove} setSelected={setSelectToMove} disabled={true} />
      );
    }
  }

  useEffect(() => {
    if (checkAll) {
      handleCheckAll();
    } else handleUnCheckAll();
    // eslint-disable-next-line
  }, [checkAll]);

  useEffect(() => {
    if (selectToMove.length > 0 && totalRows > 0 && selectToMove.length === totalRows) setCheckAll(true);
  }, [selectToMove, totalRows]);

  function handleCheckAll() {
    let array = [];

    filteredData.map((e) => {
      return array.push(e._id);
    });
    setSelectToMove(array);
  }

  function handleUnCheckAll() {
    setSelectToMove([]);
  }

  const formattedDate = today;

  function calcBalanceFH(interval, raisedAt, val) {
    let expiry = parseInt(interval) + parseInt(raisedAt);
    let current = val;
    let balance = parseInt(expiry) - parseInt(current);
    return parseInt(balance).toLocaleString() || "-";
  }

  function calcBalanceFC(interval, raisedAt, val) {
    let expiry = parseInt(interval) + parseInt(raisedAt);
    let current = val;
    let balance = parseInt(expiry) - parseInt(current);
    return parseInt(balance).toLocaleString() || "-";
  }

  function calcBalanceCD(calendarDaysStartDate, calendarDaysInterval) {
    let startDate = calendarDaysStartDate;
    let interval = calendarDaysInterval || 0;
    let expiry = addingDate(startDate, interval);
    // let today = setToday();
    let balance = calculateBalanceDay(today?.split("T")[0], expiry?.split("T")[0]);
    return balance;
  }

  const TableList = ({ type }) => {
    // const status = value === "1" ? "closed" : "approved";
    return (
      !tableLoading &&
      filteredData.length > 0 && (
        <>
          <div
            style={{
              width: "100%",
              borderRadius: "12px",
              overflow: "hidden",
              border: "1px solid #E8E8E8",
            }}
          >
            <Grid container columns={13}>
              <Grid item xs={0.5} className={styles.tableHeader}>
                {value === "1" && handleSelect()}
              </Grid>
              <Grid item xs={0.3} className={styles.tableHeader}></Grid>
              <Grid item xs={0.5} className={styles.tableHeader}>
                Station
              </Grid>
              <Grid item xs={1} className={styles.tableHeader}>
                A/C Reg.
              </Grid>
              <Grid item xs={2.7} className={`${styles.tableHeader} ${styles.gridJustifyStart}`}>
                Defect Description
              </Grid>
              <Grid item xs={1} className={styles.tableHeader}>
                DDML No.
              </Grid>
              <Grid item xs={1} className={styles.tableHeader}>
                Ref.
              </Grid>
              <Grid item xs={1} className={styles.tableHeader}>
                Raised Date
              </Grid>
              <Grid item xs={1} className={styles.tableHeader}>
                Expiry/Balance
              </Grid>
              <Grid item xs={1} className={styles.tableHeader}>
                Closed By
              </Grid>
              <Grid item xs={3} className={`${styles.tableHeader} ${styles.gridJustifyStart}`}>
                Rectification Status
              </Grid>
              {filteredData &&
                filteredData
                  // .filter((e) => e.formStatus === type)
                  .map((e, index) => {
                    let calendarDaysInterval = e.calendarDaysInterval || 0;
                    let calendarDaysStartDate = e.calendarDaysStartDate || e.raisedDate;
                    let expiry = addingDate(calendarDaysStartDate, calendarDaysInterval);
                    let date1 = e.formType === "AMD" ? e.calendarDaysStartDate : formattedDate?.split("T")[0];
                    let date2 = expiry;
                    let balance = calculateBalanceDay(date1, date2);
                    let showInTable = e.showInTable;
                    let currentFlightHour = dataFHFC.filter((item) => item.AC_REG === e.aircraftReg)[0]?.flight_hour;
                    let currentFlightCycle = dataFHFC.filter((item) => item.AC_REG === e.aircraftReg)[0]?.flight_cycle;
                    if (showInTable === "fh" && e.flightHours && e.formType === "STR/FC/FH")
                      balance = calcBalanceFH(e.flightHoursInterval, e.flightHoursRaisedAt, currentFlightHour);
                    else if (showInTable === "fc" && e.flightCycle && e.formType === "STR/FC/FH")
                      balance = calcBalanceFC(e.flightCycleInterval, e.flightCycleRaisedAt, currentFlightCycle);
                    else if (showInTable === "" && e.formType === "STR/FC/FH") balance = "-";

                    let fcfhIndicator = " (FC)";
                    if (showInTable === "fh" && e.flightHours) fcfhIndicator = " (FH)";
                    else if (showInTable === "fc" && e.flightCycle) fcfhIndicator = " (FC)";

                    if (!e.flightCycle && !e.flightHours && !e.calendarDays && e.formType === "STR/FC/FH")
                      balance = "-";
                    if (e.formType === "AMD")
                      balance = calculateCalendarBalance(e.calendarDaysStartDate, e.calendarDaysInterval);
                    if (e.formType === "Fuel Sampling" || e.formType === "Hydraulic Sampling")
                      balance = calculateCalendarBalance(e.calendarDaysStartDate, e.calendarDaysInterval);
                    let expiryDate_ = formatDateDasr(e.expiryDate);
                    if (e.formType !== "STR/FC/FH") {
                      if (e.formType === "MEL CAT" && e.calendarDays)
                        // expiryDate_ = calculateCalendarBalanceDate(e.calendarDaysStartDate, e.calendarDaysInterval);
                        expiryDate_ = calculateCalendarExpiryDate(e.calendarDaysStartDate, e.calendarDaysInterval);
                      else if (e.formType !== "MEL CAT")
                        expiryDate_ = calculateCalendarExpiryDate(e.calendarDaysStartDate, e.calendarDaysInterval);
                    }
                    if (e.calendarDays) balance = calcBalanceCD(calendarDaysStartDate, calendarDaysInterval);
                    // Calculate the date difference
                    var currentDate = new Date();
                    var timeDifferenceMs = new Date(e.expiryDate) - currentDate;
                    var remainingDays = Math.ceil(timeDifferenceMs / (1000 * 60 * 60 * 24));

                    // let balanceSTR = balance;
                    let balanceSTR = !isNaN(parseInt(balance)) && balance !== "-" ? balance + fcfhIndicator : "-";
                    if (e.calendarDays) balanceSTR = balance + " Days";

                    let color = "";

                    if (e.formType === "STR/FC/FH" && (e.flightHours || e.flightHours) && balance <= 20)
                      color = "#E95048";
                    else if (e.formType === "STR/FC/FH" && e.calendarDays && balance <= 3) color = "#E95048";
                    else if (e.formType === "MEL CAT" && remainingDays <= 3) color = "#E95048";
                    else if (remainingDays <= 3) color = "#E95048";

                    return (
                      <>
                        <Grid item xs={13}>
                          <Grid
                            container
                            columns={13}
                            className={`${styles.lineItems} ${activeIndex === index ? styles.selectedIndex : ""}`}
                            sx={{ zIndex: 1 }}
                          >
                            <Grid item xs={0.5} className={`${styles.tableBody}`} sx={{ zIndex: 3 }}>
                              {value === "1" && (
                                <CheckBoxField
                                  id={e._id}
                                  selected={selectToMove}
                                  setSelected={setSelectToMove}
                                  disabled={summaryPermission.some((perm) => perm.canUpdate === true) ? false : true}
                                  marginTop="-18px"
                                />
                              )}
                            </Grid>
                            <Grid item xs={0.3} className={`${styles.tableBody}`} onClick={() => handleClick(e, index)}>
                              <LightTooltip
                                title={e.limitations ? "Limitation triggered" : ""}
                                followCursor
                                placement="bottom-start"
                              >
                                {e.limitations && (
                                  <img
                                    src={IconAirplaneLimitation}
                                    alt={"warning-circle"}
                                    height={20}
                                    style={{ marginLeft: "4px" }}
                                  />
                                )}
                              </LightTooltip>
                              <LightTooltip
                                title={e.reInspection ? "Reinspection" : ""}
                                followCursor
                                placement="bottom-start"
                              >
                                {e.reInspection && (
                                  <img
                                    src={IconReInspection}
                                    alt={"icon-reinspection"}
                                    height={20}
                                    style={{ marginLeft: "4px" }}
                                  />
                                )}
                              </LightTooltip>
                            </Grid>
                            <Grid
                              item
                              xs={0.5}
                              className={`${styles.tableBody} ${styles.toUpperCase}`}
                              onClick={() => handleClick(e, index)}
                            >
                              {e.location}
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              className={`${styles.tableBody} ${styles.toUpperCase}`}
                              onClick={() => handleClick(e, index)}
                            >
                              {e.aircraftReg}
                            </Grid>
                            <Grid
                              item
                              xs={2.7}
                              className={`${styles.tableBody} ${styles.toUpperCase}`}
                              sx={{ display: "flex", justifyContent: "flex-start" }}
                              onClick={() => handleClick(e, index)}
                            >
                              <LightTooltip title={e.defectDesc} followCursor placement="bottom-start">
                                <Typography className={classes.gridItem}>{e.defectDesc}</Typography>
                              </LightTooltip>
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              className={`${styles.tableBody} ${styles.toUpperCase}`}
                              onClick={() => handleClick(e, index)}
                            >
                              {e.ddmlNo}
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              className={`${styles.tableBody} ${styles.toUpperCase}`}
                              onClick={() => handleClick(e, index)}
                            >
                              <center>{e.melRef}</center>
                            </Grid>
                            <Grid item xs={1} className={`${styles.tableBody}`} onClick={() => handleClick(e, index)}>
                              <center>{formatDateDasr(e.raisedDate)}</center>
                              {/* {e.raisedDate} */}
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              className={`${styles.tableBody}`}
                              sx={{
                                color: color,
                              }}
                              onClick={() => handleClick(e, index)}
                            >
                              <center>
                                {e.formType === "STR/FC/FH"
                                  ? balanceSTR
                                  : e.formType === "MEL CAT"
                                  ? formatDateDasr(e.expiryDate)
                                  : calculateCalendarExpiryDate(e.calendarDaysStartDate, e.calendarDaysInterval)}
                              </center>
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              className={`${styles.tableBody} ${styles.toCapitalize} ${styles.flexCenter}`}
                              onClick={() => handleClick(e, index)}
                            >
                              {e?.closedByFullname}
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              className={`${styles.tableBody} ${styles.toUpperCase} ${styles.flexStart}`}
                              onClick={() => handleClick(e, index)}
                            >
                              <LightTooltip
                                title={getRectificationStatus(e) || e.actionBy145}
                                followCursor
                                placement="bottom-start"
                              >
                                {getRectificationStatus(e) || e.actionBy145}
                              </LightTooltip>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    );
                  })}
              <Grid item xs={13} className={`${styles.tableBodyWhite}`}>
                <MUIPagination
                  page={parseInt(page)}
                  setPage={setPage}
                  setPerPage={setPerPage}
                  totalPage={parseInt(totalPages)}
                  classes={{ ul: classes.ul }}
                  totalRows={parseInt(totalRows)}
                  sliceStart={sliceStart}
                  initialPerPage={perPage}
                />
              </Grid>
            </Grid>
          </div>
        </>
      )
    );
  };

  function getRectificationStatus(item) {
    if (item.formStatus === "closed" || item.formStatus === "approved") {
      return item.ddmlStatus.rectificationCategory;
    } else if (item?.statusLog?.pushToSummary === "") return undefined;
    else {
      return item?.updateLogDesc;
    }
  }

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return (
      name && {
        sx: {
          bgcolor: stringToColor(name),
          fontSize: "12px",
        },
        // children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        children: `${name.substring(0, 3)}`,
      }
    );
  }

  function calcTipsCount(type) {
    if (type === "approved") return countApproved;
    return countPending;
  }

  return !isLoading && !subLoading ? (
    <>
      <div id="main" className={`${styles.container}`}>
        <>
          <Grid container columns={8.2} className={styles.gridContainer}>
            <Grid item xs={4.2} className={styles.gridTitle}>
              <Link to={"/fleet/dasr"}>
                <img src={IconBackArrow} alt="icon-back" style={{ marginRight: "14px", marginTop: "-4px" }} />
                Closed Aircraft Reports
              </Link>
            </Grid>
            <Grid
              item
              xs={4}
              className={styles.gridJustifyEnd}
              sx={{ fontSize: "14px", paddingBottom: "16px", minHeight: "48px" }}
            >
              {value === "2" && <DateToggle date={date} setDate={setDate} today={today} setToday={setToday} />}
            </Grid>
            <Grid item xs={8.2} sx={{ paddingTop: "10px" }}>
              <TabContext value={value}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  <AntTab
                    label="Pending Approval"
                    value="1"
                    icon={<Tips val={calcTipsCount("closed")} unread={false} />}
                    iconPosition="end"
                  />
                  <AntTab
                    label="Approved"
                    value="2"
                    icon={<Tips val={calcTipsCount("approved")} unread={false} />}
                    iconPosition="end"
                  />
                </TabList>
                <div
                  style={{
                    width: "100%",
                    height: "2px",
                    backgroundColor: "#d1d1d1",
                    marginTop: "-2px",
                  }}
                ></div>
                <div
                  style={{
                    marginLeft: "-20px",
                    marginRight: "-20px",
                    marginTop: "-24px",
                  }}
                >
                  <TabPanel value="1">
                    {tableLoading ? (
                      <AirplaneLoader />
                    ) : filteredData?.length > 0 ? (
                      <AircraftReportList type={"closed"} />
                    ) : (
                      <ErrorAirBallon />
                    )}
                  </TabPanel>
                  <TabPanel value="2">
                    {tableLoading ? (
                      <AirplaneLoader />
                    ) : filteredData?.length > 0 ? (
                      <AircraftReportList type={"approved"} />
                    ) : (
                      <ErrorAirBallon />
                    )}
                  </TabPanel>
                </div>
              </TabContext>
            </Grid>
          </Grid>
        </>
      </div>
      <ElevadeDrawer
        open={open}
        setOpen={setOpen}
        data={data}
        Component={DDML}
        aoc={aoc}
        type={type}
        index={index}
        dataForm={dataForm}
        edit={edit}
        setEdit={setEdit}
        openNotification={openNotification}
        setOpenNotification={setOpenNotification}
        newForm={newForm}
        setNewForm={setNewForm}
        triggerApi={triggerApi}
        setTriggerApi={setTriggerApi}
        setSelectToMove={setSelectToMove}
        setOpenDialogMoveToAssignment={setOpenDialogMoveToAssignment}
        hideButton={true}
      />
      <DialogApprove
        open={openDialogApprove}
        setOpen={setOpenDialogApprove}
        data={selectToMove}
        setTriggerApi={setTriggerApi}
        setData={setSelectToMove}
      />
    </>
  ) : (
    <AirplaneLoader />
  );
};

export default ClosedAircraftReport;
