import styles from "./Button.module.css";
import React, { useState } from "react";

const Button = ({ title, icon, iconWhite, iconDisabled, ...props }) => {
  const [hover, setHover] = useState(false);
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: props.fullWidth ? "100%" : "fit-content",
          height: "fit-content",
          marginTop: props.marginTop || "-14px",
        }}
        className={`${
          props.variant === "fill"
            ? styles.divButtonBlue
            : props.variant === "outlined"
            ? styles.divButtonWhite
            : props.variant === "disabled"
            ? styles.divButtonDisabled
            : styles.divButtonNone
        } 
          ${props.flexend ? styles.divButtonFlexEnd : ""}`}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        {...props}
      >
        {icon && (
          <img
            src={props.variant === "disabled" ? iconDisabled : hover && iconWhite ? iconWhite : icon}
            alt="icon-download"
            height={20}
            style={{ marginRight: "8px" }}
          />
        )}
        {title}
      </div>
    </>
  );
};

export default Button;
