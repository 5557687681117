import React, { Fragment } from "react";
import "./privacyStatement.css";

const PrivacyStatement = () => {
  return (
    <Fragment>
      <div className='privacyStatement'>
      <div className="space-top-2 space-top-lg-4 space-bottom-2 footer mt-1 mb-1">
        <div className='content mt-3'>
          <h1>ADE PRIVACY STATEMENT</h1>
          <p className='text-justify'>
            This page sets out the privacy statement (“Privacy Statement”) for using the www.ade.aero website application and any subsisting page within the airasia.com domain ( collectively known as "Application" ). The Application is owned and operated by ADE and/or its subsidiaries.
          </p>
          <div className='row gap' style={{ position: "relative"}}>
            <div className='col-lg-3 col-md-12 col-sm-12 sidebar'>
            <div className='row contentScroll'>
              <div className='row sidebar'>
                <div className='sidenav'>
                  <div className='sidenav-menu border-bottom'>
                    <a href='#psIntroduction'>Introduction</a>
                    <a href='#psInformationCollection'>Information Collection</a>
                    <a href='#psUseOfInfoCollected'>Use of Information Collected</a>
                    <a href='#psSharingofInfo'>Sharing of Information Collected</a>
                    <a href='#psCookies'>Cookies</a>
                    <a href='#psDataStorage'>Data Storage</a>
                    <a href='#psYourConsent'>Your Consent</a>
                    <a href='#psAccessofInfo'>Access to and Correction of Your Personal Information</a>
                    <a href='#psMinors'>Minors* Under Age of Majority</a>
                    <a href='#psExternalSites'>External and Third Party Sites</a>
                    <a href='#psContactUs'>Contact Us</a>
                    <a href='#psTermsOfUse'>Terms of Use</a>
                  </div>
                </div>
              </div>
            </div>
            </div>
            <div className='col-lg-9 col-md-12 col-sm-12'>
              <div className='row contentScroll'>
                <div className='main text-justify'>
                  <div className='row'>
                    <h3 className='subheadline' id='psIntroduction' style={{marginTop: '-20px'}}>Introduction</h3>
                    <p>
                      ADE is concerned about your right to privacy. Therefore, ADE together with the AirAsia Group of Companies (hereunder defined) pledge to be responsible when gathering your personal information and to protect your privacy in every possible way. Although this Privacy Statement is not a contract and does not create any legal rights, it serves as an expression of our commitment to protecting private personal information.
                    </p>
                    <p>
                      ADE and its subsidiaries (“ADE”, “we”, “us”, “our”) are Malaysian entities indirectly owned by AirAsia Group Berhad (“AAGB”) ADE is a wholly owned subsidiary AirAsia Group Berhad (“AAGB”). AAGB, ADE and its respective affiliates shall collectively be known as AirAsia Group of Companies. For avoidance of doubt, affiliates shall mean an entity, present and future, that directly or indirectly, through one or more intermediaries, controls, is controlled by, or is under common control with AirAsia. The term “control” (including the terms “controlled by” and “under common control with”) means the possession, directly or indirectly, of the power to direct or cause the direction of the management and policies of AirAsia, whether through the ownership of voting securities, by contract or otherwise
                    </p>
                    <p>
                      We reserve the right to amend, modify, add, delete and make corrections to this Privacy Statement at any time and will notify users of the same by updating the statement on the website and by including a "NEWLY UPDATED" label with the "PRIVACY STATEMENT" link on the websites for a period of 30 days. After the 30-days’ period, the updated version(s) of the Privacy Statement shall supersede all previous versions of the Privacy Statement with automatic effect, without any further action by us. You acknowledge and agree that all your Personal Information collected or processed by us (including all information already in our possession), shall be processed in accordance with the terms of the most recent version of the Privacy Statement found on the Application.
                    </p>
                    <p>
                      This Privacy Statement describes the information that we collect, how it is used, when it may be shared with others, and how we safeguard its confidentiality and security.
                    </p>
                    <p>
                      This Privacy Statement is applicable to all users of this Application and those who have provided their personal information to any third party providers of products and services.
                    </p>
                  </div>
                  <div className='row'>
                    <h3 className='subheadline' id='psInformationCollection'>Information Collection</h3>
                    <p className='mt-30'>
                      We collect and maintain information that you enter on our Application or give us in any other way (collectively, “Personal Information”). You can choose not to provide certain information, but you may not be able to enjoy all the features of our Application and services provided by us. Personal Information we collect from you helps us to personalise and continually improve your online experience. Here are the types of Personal Information that we collect:
                    </p>
                    <ul>
                      <li>Personally Identifiable information e.g. Name, photo, facial features, gender, date of birth, nationality, passport/identification card number, passport expiry date, passport issuing country and country of residence;</li>
                      <li>Contact information e.g. address, email address and phone numbers;</li>
                      <li>Payment information e.g. credit or debit card information, including the name of cardholder, card number, card issuing bank, card issuing country, card expiry date and banking account details;</li>
                      <li>Transaction information e.g. information relating to your purchase of products or services made on the Application</li>
                      <li>Technical information e.g. IP address and device ID</li>
                    </ul>
                    <p>
                      When you interact with applications created by us, we may collect information about your location and mobile device. The information may be used to provide you with location-based services such as search results and marketing content. You may decline to share your Personal Information with us, or disable location services on your mobile device at any time.
                    </p>
                  </div>
                  <div className='row'>
                    <h3 className='subheadline' id='psUseOfInfoCollected' style={{marginTop: '-20px'}}>Use of Information Collected</h3>
                    <p className='mt-30'>
                      We may use the Personal Information for the following purposes:
                    </p>
                    <ul>
                      <li>AirAsia Group of Companies who have access to this Personal Information with our permission and who need to know or have access to this Personal Information in order to: perform the service requested by you (including to make, administer, and manage reservations or handle payments, "single sign-on", and customer service); analyze how you use this Application and other websites belonging to AirAsia Group of Companies, improve and provide new and personalized offers, products and services, and marketing, for purposes of research, analytics, to develop and improve any existing and future products or services offered by us, to explore further potential initiatives, to optimise research, improve our forecasting abilities, and for other business purposes of AirAsia Group of Companies; detect, prevent, and investigate fraudulent transactions and/or activities, other illegal activities, and data breaches; internal (audit/compliance) investigations; or as otherwise required or permitted by applicable law.</li>
                      <li>Administrative Purpose e.g. processing, confirming, fulfilling and completing your transactions and requests for our products and services.</li>
                      <li>Third Party Suppliers of products and services such as accommodation properties and/or providers of products and services who fulfil your transaction. These suppliers may contact you as necessary to obtain additional information about you to facilitate and fulfill your transaction.</li>
                      <li>Our Service Providers who provide data processing services to us (for example web hosting), or who otherwise process personal information for purposes such as credit card and payment processing, business analytics, customer service, marketing, or distribution of surveys, to facilitate the delivery of online services and advertising tailored to your interests, and/or fraud prevention. Our service providers will only process information as needed to perform their functions. They are not permitted to share or use the information for any other purpose.</li>
                      <li>Business Partners with whom we may jointly offer products or services, or whose products or services may be offered on our Application. If you choose to access these optional services, we will on occasion share your Personal Information with those partners.</li>
                      <li>Where Required or Permitted by Law - such as to protect ourselves against liability, to respond to subpoenas, judicial processes, legitimate requests, warrants or equivalent by law enforcement officials or authorities, to investigate fraud or other wrongdoing or as otherwise required or necessary in order to comply with applicable law, protect our legitimate interests or to the purchasers in connection with any sale, assignment, or other transfer of all or a part of our business or company. We may also, in compliance with applicable law, disclose your Personal Information to enforce or apply the terms and conditions applicable to our services or to protect the rights, property, or safety of AirAsia, our users, or others.</li>
                      <li>Business Reorganization - such as part of any sale, assignment or other transfer of our business, or transition of service to another provider. We will ask for your consent if required by applicable law.</li>
                    </ul>
                    <p>
                      We provide appropriate protections for such sharing as required by applicable law to prohibit third parties from using your Personal Information for their own purposes, and to address the security and confidentiality of your Personal Information. Except as disclosed in this Privacy Policy or as required or permitted by applicable law, we will not disclose your Personal Information to third parties without your consent.
                    </p>
                    <p>
                      If you have elected to subscribe to the AirAsia Membership, the use of your personal information will be subjected to the AirAsia Membership Terms and Conditions.
                    </p>
                    <p>
                      In the employment scenario, we would use information that you voluntarily provide to compare against the job specification / criteria we are seeking / intending to employ.
                    </p>
                  </div>
                  <div className='row' >
                    <h3 className='subheadline' id='psSharingofInfo' style={{marginTop: '-20px'}} >Sharing of Information Collected</h3>
                    <p className='mt-30'>
                      We may share your Personal Information to:
                    </p>
                    <ul>
                      <li>AirAsia Group of Companies;</li>
                      <li>payment network operators and credit card verification providers, (including VISA, MasterCard, China Union Pay, where applicable), governmental agencies, regulatory authorities, if and when required;</li>
                      <li>Government authorities and agencies such as airport, customs, immigration, safety and security personnel;</li>
                      <li>data analytics, marketing agency, third party suppliers of products and services, business partners or service providers, parties which have business or contractual dealings with AirAsia and the AirAsia Group of Companies, and other third party who is able to demonstrate that you have explicitly consented to the disclosure of your Personal Information by us to such third party (collectively known as “Authorised Third Party”)</li>
                    </ul>
                    <p>Your Personal Information may also be shared or transferred to any of our actual and potential assignee, transferee or acquirer (including AirAsia Group of Companies), or in connection with any corporate restructuring or exercise including our restructuring to transfer the business, assets and/or liabilities.</p>
                    <p>As part of our effort to serve you better, we are constantly enhancing and expanding our products and services to fulfill your ever-growing needs. In order to realise this business aim, we will from time to time share your Personal Information with the AirAsia Group of Companies and the Authorised Third Party to make available promotions, offers, products or services which may or may not belong to us. However, to ensure that you will not receive unwanted communications, only information with regards to promotions, offers, products or services which are relevant to the transaction you have completed with us will be shared.</p>
                    <p>In the circumstances set out in this Privacy Statement where we share your Personal Information to a third party, we will ensure that the security measures that such party has in place in relation to the processing of your data are at least as stringent as those employed by us, if not better. This does not apply where we are required to pass your information to a certain third party by operation of the law.</p>
                    <p>In the event we go through a business transition, such as sale of part or all of our assets, merger or acquisition, your Personal Information will likely form part of this transition and be transferred.</p>
                    <p>We do not sell or rent any of your Personal Information to any other parties. We may share your anonymous and/or aggregated information about all our users with the AirAsia Group of Companies and/or third parties.</p>
                    <p>For any inquiries regarding the handling and management of Personal Information disclosed to, or shared with AirAsia Group of Companies and/or third parties hereunder, you can contact us in the manner described under the “Contact Us” section below.</p>
                  </div>
                  <div className='row' >
                    <h3 className='subheadline' id='psCookies' style={{marginTop: '-20px'}} >Cookies</h3>
                    <p className='mt-30'>
                      Cookies are small files containing information which are downloaded to your device when you visit our Application. We use cookies to recognise your preference information, keep track of your bookings and purchases and facilitate site administration. If you continue to browse our Application without changing your web browsers or device settings that control cookies, you agree to receive cookies when you use the services on our Application.
                    </p>
                    <p>Most web browsers automatically accept cookies, but, if you prefer, you may set your browser to prevent it from accepting cookies. The “help” portion of the toolbar on most browsers will tell you how to disable cookies.</p>
                    <p>It is important that you prevent unauthorised access to your password and your computer. You should always log out from your ADE membership account after using a shared device.</p>
                    <p>There are 4 types of cookies used on our Application: </p>
                    <ul>
                      <li>Functionality: These cookies enable you to use our Application. These cookies are essential to enable you to browse our Application and use certain features. Disabling them may prevent you from using certain parts of the Application. Without these cookies, features like login, booking and paying activity cannot be provided. These cookies also help keep our Application safe and secure.</li>
                      <li>Preference: These cookies store information such as your preferred country, language selection and website preferences. Without these cookies, our Application may not be able to remember certain choices you’ve previously made or personalise your browsing experience by providing you with relevant information.</li>
                      <li>Analytics: These cookies collect information about how you use our Application such as which pages you visit regularly. These cookies are used to provide you high-quality experience by doing things such as tracking page load, site response times, and error messages.</li>
                      <li>Content/Advertising: These cookies gather information about your use of our Application so we may improve your experience and provide you with more relevant content and advertising. They remember that you've visited our Application and help us understand usage of our Application. Some of these cookies are from third parties that collect information about users of our Application in order to provide advertising (on our services and elsewhere) based on users’ online activities (so-called "interest-based advertising") on our Application and elsewhere online. The third parties involved in interest-based advertising collect internet browsing information (e.g. websites visited, time of visit) across different websites and over time, and they may use the information they collect on our Application to provide you ads (from us and other companies) across the internet.</li>
                    </ul>
                  </div>
                  <div className='row' >
                    <h3 className='subheadline' id='psDataStorage' style={{marginTop: '-20px'}} >Data Storage</h3>
                    <p className='mt-30'>
                      The headquarters of AirAsia is located in Malaysia. However, we may transfer and store your Personal Information on our servers located overseas. Such transfers are necessary pursuant to our contract with you and/or our contract(s) with the relevant third parties.
                    </p>
                    <p>We will secure the storage in compliance with the minimum security measures prescribed under the Personal Data Protection Act 2010 of Malaysia, its regulation and standards, in the following manners:</p>
                    <ul>
                      <li>register those who have access to the storage;</li>
                      <li>control and limit access based on necessity;</li>
                      <li>maintain proper record of access and transfer of Personal Information;</li>
                      <li>ensure all employees of AirAsia AirAsia Group of Companies’ protect confidentiality;</li>
                      <li>conduct awareness programmes to educate employees on responsibility to protect Personal Information;</li>
                      <li>establish physical security procedures;</li>
                      <li>bind third parties involved in processing of Personal Information; and</li>
                      <li>do not use removable device and cloud computing service to transfer or store Personal Information without written consent from AirAsia’s senior management.</li>
                    </ul>
                  </div>
                  <div className='row'>
                    <h3 className='subheadline' id='psYourConsent' style={{marginTop: '-20px'}} >Your Consent</h3>
                    <p className='mt-30'>
                      AirAsia, AirAsia Group of Companies, third party suppliers of products and services, business partners or service providers engaged by us will obtain your consent to collect and use your Personal Information at the time of collection, subject to certain legally prescribed circumstances where your consent is not required. Where required by law, AirAsia will adopt an ‘opt-in' policy to obtain your express written consent when collecting your Personal Information. You may be asked, for example, to click next, continue, proceed, sign a form or tick a box.
                    </p>
                    <p>Generally, in using the Application, you consent to the collection and use of your Personal Information by us in the ways described above (which may change from time to time) unless and until you inform us to the contrary. You may submit a request to withdraw your consent at any time by contacting us. By providing us Personal Information of any third party individual(s), you represent and warrant that the individual(s) has been informed of and consents to the terms of this Privacy Statement, as may be amended from time to time.</p>
                    <p>However, we will provide you with an avenue to unsubscribe from receiving marketing, communications, promotional offers, newsletters or any other communications from us.</p>
                  </div>
                  <div className='row'>
                    <h3 className='subheadline' id='psAccessofInfo' style={{marginTop: '-20px'}} >Access to and Correction of Your Personal Information</h3>
                    <p className='mt-30'>
                      We will be able to provide you or make any correction to your stored Personal Information upon receiving a request via our e-form.
                    </p>
                  </div>
                  <div className='row'>
                    <h3 className='subheadline' id='psMinors' style={{marginTop: '-20px'}} >Minors* Under Age of Majority</h3>
                    <p className='mt-30'>
                      We cannot distinguish the age of visitors to our Application. If you are a parent or guardian of someone under the age of majority in the territory you’re residing in, who has provided us his/her Personal Information without your knowledge and consent, you may request that we remove such information by contacting us.
                    </p>
                  </div>
                  <div className='row'>
                    <h3 className='subheadline'  id='psExternalSites' style={{marginTop: '-20px'}} >External and Third Party Sites</h3>
                    <p className='mt-30'>
                      This Application may contain links to websites maintained by third parties (“External Sites”). These External Sites are provided to redirect you to another website to enable purchase of Products and Services, or for your reference and convenience only. AirAsia does not operate, control or endorse in any respect such External Sites or their content. You assume sole responsibility for use of these External Sites and are therefore, advised to examine the terms and conditions and privacy statement of those External Sites carefully.
                    </p>
                  </div>
                  <div className='row'>
                    <h3 className='subheadline'  id='psContactUs' style={{marginTop: '-20px'}} >Contact Us</h3>
                    <p className='mt-30'>
                      Should you have any queries, concerns or complaints in relation to our handling and management of your Personal Information or this Privacy Statement, please contact our Compliance Officer at airasia privacycompliance@airasia.com.
                    </p>
                  </div>
                  <div className='row'>
                    <h3 className='subheadline'  id='psTermsOfUse' style={{marginTop: '-20px'}} >Terms of Use</h3>
                    <p className='mt-30'>
                      With reference to the terms and conditions on the use of our Application, please refer to our Terms of Use for further information.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </Fragment>
  );
};

export default PrivacyStatement;
