
import React from 'react';
import {
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Skeleton,
	Pagination,
	MenuItem,
	Select,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Fragment, useEffect, useState } from "react";
// import { Active, Expired, Missing, NearExpiry, Error } from "./Icons";
import { gothamBold } from "../../../constants/fonts";
import RightDrawer from "../../components/modals/RightDrawer";
import AddDevice from "./AddDevice";
import UpdateDevice from "./UpdateDevice";
import { useGetAllInventoriesWithOffset } from "../../../models/custom/inventory-model";

const GQLNODESLIMIT = 300;
export default function DeviceListAll({ showAdd, setShowAdd }) {
	const [itemToUpdate, setItemToUpdate] = useState(undefined);
	const [clickedPageNumber, setClickedPageNumber] = useState(false);
	// searching & filters
	const [search, setSearch] = useState(undefined);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [curPage, setCurPage] = useState(1);
	const [fetchCount, setFetchCount] = useState(1);
	const offset = GQLNODESLIMIT * (fetchCount - 1);
	const upperThreshold = (GQLNODESLIMIT / itemsPerPage) * fetchCount;
	const lowerThreshold = (GQLNODESLIMIT / itemsPerPage) * (fetchCount - 1);
	const upperIndex =
		curPage * itemsPerPage - GQLNODESLIMIT * (fetchCount - 1);
	const lowerIndex =
		(curPage - 1) * itemsPerPage - GQLNODESLIMIT * (fetchCount - 1);
	const [tabPost, setTabPost] = useState(0);
	const handleChange = (event, newValue) => {
		setTabPost(newValue);
	};
	const allInventoryLists = useGetAllInventoriesWithOffset({
		offset,
	});
	const numOfPages = allInventoryLists.totalCount
		? Math.ceil(allInventoryLists.totalCount / itemsPerPage)
		: 1;
	useEffect(() => {
		// reset page back to 1 if data changed
		if (!clickedPageNumber) {
			setCurPage(1);
			setFetchCount(1);
		}
		setClickedPageNumber(false);
	}, [allInventoryLists.data]);
	return (
		<Box
			sx={{
				height: "100%",
			}}
		>
			<RightDrawer
				open={showAdd}
				setClose={() => {
					setShowAdd(false);
				}}
				title={
					<Typography
						sx={{
							paddingBottom: "1em",
							paddingLeft: "1em",
							paddingTop: "1em",
							fontFamily: gothamBold,
						}}
					>
						Add New Device
					</Typography>
				}
			>
				{showAdd && ( // force it to rerender to clear all inputs
					<AddDevice
						handleClose={() => {
							setShowAdd(false);
						}}
						refetch={allInventoryLists.refetch}
					/>
				)}
			</RightDrawer>
			<RightDrawer
				open={itemToUpdate === undefined ? false : true}
				setClose={() => {
					setItemToUpdate(undefined);
				}}
				title={
					<Typography
						sx={{
							paddingBottom: "1em",
							paddingLeft: "1em",
							paddingTop: "1em",
							fontFamily: gothamBold,
						}}
					>
						Update Device
					</Typography>
				}
			>
				<Box
					sx={{
						height: "94%",
						paddingTop: "0px",
					}}
				>
					<UpdateDevice
						node={itemToUpdate}
						refetch={allInventoryLists.refetch}
						onClose={() => {
							setItemToUpdate(undefined);
						}}
					/>
				</Box>
			</RightDrawer>

			{allInventoryLists.isLoading ? (
				<TableContainer
					component={Paper}
					sx={{
						boxShadow: "none",
						borderRadius: "12px",
						border: "1px solid #E8E8E8",
						"& .MuiTableRow-root:hover": {
							backgroundColor: "#F7F7F7",
						},
					}}
				>
					<Table>
						<TableBody sx={{ width: "100%" }}>
							<Fragment>
								{Array.apply(null, { length: itemsPerPage })
									?.map(Number.call, Number)
									?.map((item) => {
										return (
											<TableRow
												key={`pagedgqltablemain-tablerow-${item}`}
											>
												<TableCell
													sx={{
														paddingTop: "16px",
														paddingBottom: "16px",
													}}
													colSpan={100}
													component="th"
													scope="row"
												>
													<Skeleton
														sx={{ height: "20px" }}
														animation="wave"
														variant="text"
													/>
												</TableCell>
											</TableRow>
										);
									})}
							</Fragment>
						</TableBody>
					</Table>
				</TableContainer>
			) : (
				<Fragment>
					<TableContainer
						component={Paper}
						sx={{
							boxShadow: "none",
							borderRadius: "12px",
							border: "1px solid #E8E8E8",
							"& .MuiTableRow-root:hover": {
								backgroundColor: "#F7F7F7",
							},
						}}
					>
						<Table
							sx={
								{
									/* minWidth: 650 */
								}
							}
							aria-label="simple table"
						>
							<TableHead>
								<TableRow
									sx={{
										backgroundColor: "#F2F7FF",
									}}
								>
									<TableCell>Device ID</TableCell>
									<TableCell>Name</TableCell>
									<TableCell>Model</TableCell>
									<TableCell>Manufacturer</TableCell>
									<TableCell>Status</TableCell>
									{/* <TableCell align="center">Remind</TableCell> */}
								</TableRow>
							</TableHead>
							<TableBody>
								{allInventoryLists.data &&
									allInventoryLists.data.length < 1 &&
									!allInventoryLists.isLoading && (
										<TableRow>
											<TableCell
												colSpan={9}
												component="th"
												scope="row"
											>
												No record(s) found.
											</TableCell>
										</TableRow>
									)}
								{allInventoryLists.data &&
									!allInventoryLists.isLoading &&
									allInventoryLists.data.map(
										({ node }, index) => {
											const { id, deviceId, name } = node;
											const statuscode =
												node?.status?.name ?? "";
											const model =
												node?.model?.name ?? "";
											const manufacturer =
												node?.model?.manufacturer
													?.code ?? "";
											// if (index >= lowerIndex && index <= upperIndex) {
											if (
												index > lowerIndex - 1 &&
												index <= upperIndex - 1
											) {
												return (
													<TableRow
														sx={{
															cursor: "pointer",
															"&:last-child td, &:last-child th":
																{
																	border: 0,
																},
															"&:hover": {
																background:
																	"#D1D1D1",
															},
															// '&:hover .buttonmore': {
															//     display: "inline-block",
															// },
														}}
														onClick={() => {
															setItemToUpdate(
																node
															);
														}}
													>
														<TableCell
															component="th"
															scope="row"
															sx={{
																maxWidth:
																	"150px",
																minWidth:
																	"150px",
																wordBreak:
																	"break-all",
																position:
																	"relative",
															}}
														>
															{deviceId}
														</TableCell>
														<TableCell
															component="th"
															scope="row"
														>
															{name}
														</TableCell>
														<TableCell
															component="th"
															scope="row"
														>
															{model ?? "N/A"}
														</TableCell>
														<TableCell
															component="th"
															scope="row"
														>
															{manufacturer ??
																"N/A"}
														</TableCell>
														<TableCell
															component="th"
															scope="row"
														>
															{statuscode}
														</TableCell>
													</TableRow>
												);
											} else {
												return;
											}
										}
									)}
							</TableBody>
						</Table>
					</TableContainer>

					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "right",
							marginTop: "1em",
						}}
					>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								marginRight: "1em",
							}}
						>
							Rows Per Page
						</Box>
						<Select
							sx={{
								padding: "0px",
								"& .MuiSelect-select": {
									padding: "0px",
									paddingLeft: "1em",
								},
								marginRight: "1em",
							}}
							value={itemsPerPage}
							onChange={(e) => {
								setItemsPerPage(e.target.value);
								setCurPage(1);
								setFetchCount(1);
							}}
						>
							{["10", "20", "50", "100"].map((pageNumber) => (
								<MenuItem
									sx={{
										padding: "0px",
									}}
									key={pageNumber}
									value={pageNumber}
								>
									{pageNumber}
								</MenuItem>
							))}
						</Select>
						<Pagination
							page={curPage}
							count={numOfPages}
							onChange={(e, value) => {
								setClickedPageNumber(true);
								setCurPage(value);
								if (value > upperThreshold) {
									setFetchCount(
										Math.ceil(
											(value * itemsPerPage) /
												GQLNODESLIMIT
										)
									);
								} else if (value <= lowerThreshold) {
									setFetchCount(
										Math.ceil(
											(value * itemsPerPage) /
												GQLNODESLIMIT
										)
									);
								}
							}}
							variant="outlined"
							shape="rounded"
						/>
					</Box>
				</Fragment>
			)}
			<Box sx={{ width: "100%", height: "2em" }} />
		</Box>
	);
}
