import { TableCell, tableCellClasses, TableHead, TableRow, TableSortLabel, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F2F7FF",
    color: "#333",
    fontSize: 16,
    fontFamily: "Gotham-Book",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    fontFamily: "Gotham-Book",
  },
}));

export function useTable(records, headCells, filterFn, key = "table-head") {
  const pages = [10, 20, 30];
  const [page] = useState(0);
  const [rowsPerPage] = useState(pages[page + 1]);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();

  const TblHead = (props) => {
    const handleSortRequest = (cellId) => {
      const isAsc = orderBy === cellId && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(cellId);
    };

    return (
      <TableHead>
        <TableRow key={key}>
          {headCells.map((headCell) => (
            <StyledTableCell
              style={headCell.style}
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.disableSorting ? (
                <Typography variant="tableHeader">{headCell.label}</Typography>
              ) : (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={() => {
                    handleSortRequest(headCell.id);
                  }}
                >
                  <Typography variant="tableHeader">{headCell.label}</Typography>
                </TableSortLabel>
              )}
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const recordsAfterPagingAndSorting = () => {
    return stableSort(
      // filterFn.fn(records),
      records,
      getComparator(order, orderBy)
    ).slice(page * rowsPerPage, (page + 1) * rowsPerPage);
  };

  return {
    TblHead,
    // TblPagination,
    recordsAfterPagingAndSorting,
    order,
    orderBy,
  };
}
