import React, { useState } from "react";
import { Grid, Fade } from "@mui/material";
import styles from "./DialogBox.module.css";
import Backdrop from "@mui/material/Backdrop";
import { makeStyles } from "@mui/styles";
import { AdeButton } from "common/components";
import { useRef } from "react";
import { makeAPIRequestMDB } from "common/helpers/makeAPIRequestMDB";
import { useSelector } from "react-redux";
import { showNotificationElevade } from "common/helpers";

const DialogBox = ({ aoc, open = false, setOpen, val, setVal, setOpenNotification }) => {
  const [inputVal, setInputVal] = useState();
  const [errorMandatory, setErrorMandatory] = useState(false);
  const inputRef = useRef(null);
  // const { profile: userProfile } = useSelector((state) => state.user);
  const { userName: userEmail } = useSelector((state) => state.userEntity);

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      backgroundColor: "rgba(0, 0, 0, 0.6)", // Set the initial color
      backdropFilter: "blur(2px)", // Set the blur effect
    },
  }));

  const classes = useStyles();

  const handleChange = (event) => {
    setInputVal(event.target.value);
    setErrorMandatory(false);
  };

  const handleSave = () => {
    if (inputVal !== "" && inputVal !== undefined) {
      const body = {
        aoc: aoc,
        totalAircraft: inputVal
      }
      UpdateData(`api/aircraft/planned-schedule`, body);
      // setOpenNotification(true);
      setVal(inputVal);
      setOpen(false);
      showNotificationElevade("success", "Record successfully updated!", ["You successfully saved an update"]);
    }
    if (inputVal === undefined || inputVal === "") {
      setErrorMandatory(true);
    }
    //  else if (inputVal !== undefined) {
    //   setErrorMandatory(true);
    //   inputRef.current.focus();
    //   showNotificationElevade("error", "An error occured!", ["Please contact your system administartor"]);
    // }
  };

  async function UpdateData(params, body) {
    const fetch = makeAPIRequestMDB(`post`, `${params}`, body);
    fetch
      .then((response) => {
        // setTriggerUpdate(true);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  return (
    <>
      <Fade in={open}>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
          className={classes.backdrop}
        >
          <div className={`${styles.containerCenter}`}>
            <Grid container columns={2}>
              <Grid
                item
                xs={2}
                className={styles.title}
                sx={{ display: "flex", justifyContent: "center", fontSize: "16px" }}
              >
                Key-in Total Schedule Aircraft
              </Grid>
              <Grid
                item
                xs={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  color: "#838383",
                  fontSize: "14px",
                  paddingTop: "6px",
                }}
              >
                Please enter the total number of aircraft on schedule for today
              </Grid>
              <Grid item xs={2} sx={{ display: "flex", justifyContent: "center", padding: "32px 0" }}>
                <input
                  type="text"
                  className={`${styles.inputField} ${errorMandatory ? styles.errorMandatory : ""}`}
                  onKeyDown={function (event) {
                    const key = event.which || event.keyCode;
                    const char = String.fromCharCode(key);
                    // Allow only numbers and a few special characters (e.g., Backspace, Tab, Enter)
                    const allowedChars = /[0-9.,\s]/;
                    if (![8, 37, 39].includes(key) && !allowedChars.test(char)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={handleChange}
                  ref={inputRef}
                  defaultValue={val}
                />
              </Grid>
              <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-end", paddingRight: "16px" }}>
                <AdeButton
                  fullWidth={false}
                  variant="outlined"
                  sx={{
                    borderRadius: "8px",
                    border: "0px solid #e8e8e8",
                    fontFamily: "Gotham-Medium",
                    pl: 6,
                    pr: 6,
                    "&.MuiButton-root:hover": { border: "transparent" },
                  }}
                  onClick={() => {
                    setOpen(false);
                    setErrorMandatory(false);
                  }}
                >
                  Cancel
                </AdeButton>
              </Grid>
              <Grid
                item
                xs={1}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  paddingLeft: "16px",
                }}
              >
                <AdeButton
                  fullWidth={false}
                  variant="contained"
                  sx={{
                    borderRadius: "8px",
                    fontFamily: "Gotham-Medium",
                    pl: 6,
                    pr: 6,
                    "&.MuiButton-root": { border: "transparent" },
                  }}
                  onClick={() => {
                    handleSave();
                  }}
                >
                  Save
                </AdeButton>
              </Grid>
            </Grid>
          </div>
        </Backdrop>
      </Fade>
    </>
  );
};

export default DialogBox;
