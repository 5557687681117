import { HttpGet, HttpGetEntity } from "common/library/apiAxiosWrapper";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { NavigateFunction } from "react-router-dom";
// import { UserDetailRequest } from 'common/interfaces';

export const getUserDetailByEmail = createAsyncThunk("user/getUserDetailByEmail", async ({ userEmail, history }) => {
  try {
    const result = await HttpGetEntity({
      url: `/api/users/userDetailByEmail/${userEmail}`,
    });
    // if (history) {
    //   history.push("/fleet/home");
    // }
    return result.data;
  } catch (err) {
    throw new Error(err?.response?.data?.message ? err?.response?.data?.message : "Login Failed");
  }
});

export const clearUserDetail = createAsyncThunk("user/clearUserDetail", async () => {
  try {
    localStorage.removeItem("avatar");
    localStorage.removeItem("displayName");
    localStorage.removeItem("targetUrl");
    return true;
  } catch (err) {
    throw new Error("Logout failed");
  }
});
