import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Box, Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography, Avatar } from "@mui/material";
import { expandFleetMenu } from "./SidebarIconsList";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import styles from "./Sidebar.module.css";
import { AOCList } from "common/constant";

const FleetSidebarIconText = () => {
  const { companyProfile, userPermission } = useSelector((state) => state.userEntity);
  const location = useLocation();
  const [module, setModule] = useState();

  // check feature(s) permission available
  function featureCheck(feature) {
    let checkPermission = false;
    switch (feature) {
      case "isAircraftMonitoring":
        return userPermission.some((permission) => permission.code === "ACM-002");
      case "isInbox":
        return userPermission.some((permission) => permission.code === "ACM-003");
      case "isRepetitiveDefect":
        return userPermission.some((permission) => permission.code === "ACM-006");
      case "isAnalytics":
        return userPermission.some((permission) => permission.code === "TEMP_ANALYTICS");
      case "isScheduledCheck":
        return userPermission.some((permission) => permission.code === "TEMP_SCHEDULEDCHECK");
      case "isDASR":
        return userPermission.some((permission) => permission.code === "TEMP_DASR");
      case "isCabinMonitoring":
        return userPermission.some((permission) => permission.code === "CABIN-001");
      case "isModuleSettings":
        return userPermission.some((permission) => permission.code === "FLEET-002");
      case "isEmailSettings":
        return userPermission.some((permission) => permission.code === "FLEET-003");
      default:
        return checkPermission;
    }
  }

  const FourthLayer = ({ fourthLayer }) => {
    return (
      <List>
        {fourthLayer?.map((item, index) => {
          return (
            <Link
              key={index}
              to={item?.url}
              onClick={(e) => {
                if (item?.disable) {
                  e.preventDefault();
                }
                if (item?.dropdown) {
                  e.preventDefault();
                }
              }}
            >
              <ListItemButton
                // sx={{
                //   paddingLeft: "74px",
                //   cursor: "pointer"
                // }}
                className={`${
                  location?.pathname === item?.url ? styles.fourthLayerButtonActive : styles.fourthLayerButton
                }`}
              >
                <ListItemText
                  primary={item?.label}
                  disableTypography
                  sx={{
                    color: "#333",
                    fontSize: "14px",
                    fontFamily: "Gotham-Book",
                    // margin: 0
                  }}
                  className={styles.fourthLayerText}
                />
              </ListItemButton>
            </Link>
          );
        })}
      </List>
    );
  };

  const ThirdLayer = ({ thirdLayer, name }) => {
    const { userAOC } = useSelector((state) => state.userEntity);
    const [openModule, setOpenModule] = useState(true);

    return (
      <List>
        {thirdLayer?.map((item, index) => {
          if (location.pathname === item?.url) {
            setModule(item?.module);
          }
          let disable = !featureCheck(item?.feature);
          // TODO: To check back if this code is affecting other logic
          // if (item.label === "Email Management") {
          //   disable = userAOC.every((aoc) => !AOCList.some((item) => item.code === aoc));
          // }
          return (
            <>
              <Link
                key={index}
                to={item?.url}
                onClick={(e) => {
                  if (disable) {
                    e.preventDefault();
                  }
                  if (item?.dropdown) {
                    e.preventDefault();
                  }
                }}
              >
                <ListItemButton
                  onClick={(e) => {
                    if (disable) {
                      e.stopPropagation();
                      e.preventDefault();
                    } else {
                      setOpenModule(!openModule);
                    }
                  }}
                  // className={`${
                  //   location?.pathname === item?.url ? styles.thirdLayerButtonActive : styles.thirdLayerButton
                  // }`}
                  className={`
                    ${
                      disable
                        ? styles.thirdLayerButtonDisabled
                        : location.pathname === item?.url
                        ? styles.thirdLayerButtonActive
                        : styles.thirdLayerButton
                    }
                  `}
                >
                  <ListItemIcon sx={{ minWidth: "0", paddingRight: "8px" }} className={styles.thirdLayerIcon}>
                    <img src={item?.icon} alt={item?.label} className={`${styles.thirdLayerIcon}`} />
                  </ListItemIcon>
                  <ListItemText
                    primary={item?.label}
                    disableTypography
                    sx={{
                      color: "#333",
                      fontSize: "14px",
                      fontFamily: "Gotham-Book",
                    }}
                    className={styles.thirdLayerText}
                  />
                  {item.children ? openModule ? <ExpandLess /> : <ExpandMore /> : <></>}
                </ListItemButton>
              </Link>
              {item?.children ? (
                <Collapse in={openModule}>
                  <FourthLayer fourthLayer={item?.children} />
                </Collapse>
              ) : (
                <></>
              )}
            </>
          );
        })}
      </List>
    );
  };

  const SecondLayer = ({ secondLayer }) => {
    const [openModule, setOpenModule] = useState(true);
    if (location.pathname === secondLayer?.url) {
      setModule(secondLayer?.module);
    }
    let disable = !featureCheck(secondLayer?.feature);

    return (
      <List>
        <Link
          to={secondLayer?.url || "#"}
          onClick={(e) => {
            if (secondLayer.children ? secondLayer?.disable : disable) {
              e.preventDefault();
            }
            if (secondLayer?.dropdown) {
              e.preventDefault();
            }
          }}
        >
          <ListItemButton
            onClick={(e) => {
              if (secondLayer.children ? secondLayer?.disable : disable) {
                e.stopPropagation();
                e.preventDefault();
              } else {
                setOpenModule(!openModule);
              }
            }}
            sx={{
              paddingLeft: "16px",
              borderRadius: "12px",
            }}
            className={`
                ${
                  secondLayer.children
                    ? secondLayer?.disable
                    : disable
                    ? styles.secondLayerButtonDisabled
                    : location.pathname === secondLayer?.url
                    ? styles.secondLayerButtonActive
                    : styles.secondLayerButton
                }
              `}
          >
            <ListItemIcon sx={{ minWidth: "0", paddingRight: "8px" }} className={styles.secondLayerIcon}>
              <img
                src={secondLayer?.icon}
                alt={secondLayer?.label}
                // style={{width: "20px", height: "20px"}}
                className={`${styles.secondLayerIcon}`}
              />
            </ListItemIcon>
            <ListItemText
              primary={secondLayer?.label}
              disableTypography
              sx={{
                color: "#333",
                fontSize: "14px",
                fontFamily: "Gotham-Book",
                // margin: 0
              }}
              className={styles.secondLayerText}
            />
            {secondLayer.children ? openModule ? <ExpandLess /> : <ExpandMore /> : <></>}
          </ListItemButton>
        </Link>
        {secondLayer?.children ? (
          <Collapse in={secondLayer?.disable ? false : openModule}>
            <ThirdLayer thirdLayer={secondLayer?.children} name={secondLayer?.label} />
          </Collapse>
        ) : (
          <></>
        )}
      </List>
    );
  };

  const FirstLayer = ({ firstLayer }) => {
    const [openModule, setOpenModule] = useState(false);

    useEffect(() => {
      if (module === firstLayer?.label) {
        setOpenModule(true);
      }
    }, []);

    return firstLayer?.children ? (
      <>
        <ListItemButton
          onClick={() => setOpenModule(!openModule)}
          sx={{
            paddingLeft: "8px",
          }}
        >
          <ListItemText
            primary={firstLayer?.label}
            disableTypography
            sx={{
              color: "#838383 !important",
              fontSize: "12px !important",
              fontFamily: "Gotham-Medium !important",
            }}
            // className={styles.firstLayerText}
          />
          {openModule ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openModule}>
          {firstLayer?.children &&
            firstLayer?.children.map((item, index) => {
              if (item?.visible) {
                return <SecondLayer secondLayer={item} key={index} />;
              } else {
                return <></>;
              }
            })}
        </Collapse>
      </>
    ) : (
      <>
        <Link
          to={firstLayer?.url || "#"}
          onClick={(e) => {
            if (firstLayer?.disable) {
              e.preventDefault();
            }
          }}
        >
          <ListItemButton
            onClick={(e) => {
              if (firstLayer?.disable) {
                e.stopPropagation();
                e.preventDefault();
              } else {
                setOpenModule(!openModule);
              }
            }}
            sx={{
              paddingLeft: "16px",
              borderRadius: "12px",
              marginTop: "14px",
            }}
            className={`
                ${
                  firstLayer?.disable
                    ? styles.secondLayerButtonDisabled
                    : location.pathname === firstLayer?.url
                    ? styles.secondLayerButtonActive
                    : styles.secondLayerButton
                }
              `}
          >
            <ListItemIcon sx={{ minWidth: "0", paddingRight: "8px" }} className={styles.secondLayerIcon}>
              <img
                src={firstLayer?.icon}
                alt={firstLayer?.label}
                style={{ width: "15px", height: "15px", marginTop: "2px" }}
                className={`${styles.secondLayerIcon}`}
              />
            </ListItemIcon>
            <ListItemText
              primary={firstLayer?.label}
              disableTypography
              sx={{
                color: "#333",
                fontSize: "14px",
                fontFamily: "Gotham-Book",
                // margin: 0
              }}
              className={styles.secondLayerText}
            />
          </ListItemButton>
        </Link>
      </>
    );
  };

  return (
    <Box>
      <Box sx={{ display: "flex", marginTop: "6px", marginLeft: "-11px" }}>
        {companyProfile ? (
          <Avatar
            className="avatar-dashboard"
            style={{ marginLeft: "8px", border: "1px solid #E8E8E8" }}
            src={companyProfile.logo}
            size={36}
          ></Avatar>
        ) : (
          <Avatar className="avatar-dashboard" size={36}></Avatar>
        )}
        <Typography
          align="center"
          paddingTop="10px"
          sx={{
            color: "#394458",
            fontSize: "12px",
            fontWeight: "350",
            marginLeft: "8px",
            fontFamily: "Gotham-Medium",
            whiteSpace: "nowrap",
          }}
        >
          {companyProfile?.name}
        </Typography>
      </Box>
      {expandFleetMenu?.map((item, index) => {
        return (
          <List>
            <FirstLayer key={index} firstLayer={item} />
          </List>
        );
      })}
    </Box>
  );
};

export default FleetSidebarIconText;
