
import React from 'react';
import { Box } from "@mui/material";
export default function TabPanel(props) {
	const { children, value, index, ...other } = props;
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			style={{ height: "97%", position: "relative" }}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3, height: "92%", paddingTop: "20px" }}>
					<Box style={{ height: "100%" }}>{children}</Box>
				</Box>
			)}
		</div>
	);
}
