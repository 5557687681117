import React, { useState, useEffect } from "react";
import { Grid, Typography, styled, Tooltip, tooltipClasses } from "@mui/material";
import styles from "../Dasr.module.css";
import {
  addingDate,
  calculateBalanceDay,
  calculateCalendarBalance,
  formatDateDasr,
} from "pages/elevade/component/Helpers";
import { makeAPIRequestMDB } from "common/helpers/makeAPIRequestMDB";
import { makeStyles } from "@mui/styles";
import MUIPagination from "pages/elevade/component/Pagination";
import ElevadeDrawer from "../component/Drawer";
import AirplaneLoader from "pages/elevade/component/AirplaneLoader";
import SearchBar from "./SearchBar";
import ActiveFilters from "./ActiveFilters";
import DDML from "../aircraftReport/DDML";
import NoData from "pages/elevade/component/DataUnavailable";
import DialogMoveAssignment from "../aircraftReport/DialogMoveAssigment";
import { makeAPIRequestRun } from "common/helpers";

const Limitation = ({ aoc, ...props }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState();
  const [dataDDML, setDataDDML] = useState(props.data);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [data, setData] = useState([]);
  const [openTitle, setOpenTitle] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const InitialFilters = { search: [], location: [], aircraftReg: [], assignedTo: [], raisedDate: [], expiryDate: [] };
  const [isFilters, setIsFilters] = useState(InitialFilters);
  const [filteredData, setFilteredData] = useState([]);

  // TODO: For ddml form drawer
  const [type] = useState("1");
  const [index, setIndex] = useState();
  const [dataForm, setDataForm] = useState([]);
  const [edit, setEdit] = useState(true);
  const [openNotification, setOpenNotification] = useState(false);
  const [triggerApi, setTriggerApi] = useState(true);
  const [newForm, setNewForm] = useState(false);
  const [activeIndex, setActiveIndex] = useState("");
  const [selectToMove, setSelectToMove] = useState([]);
  const [openDialogMoveToAssignment, setOpenDialogMoveToAssignment] = useState(false);
  const [dataFHFC, setDataFHFC] = useState([]);

  let sliceStart = page > 0 ? perPage * (page - 1) : 0;
  let sliceEnd = parseInt(perPage) + sliceStart;

  const today = new Date();
  const formattedDate = today.toISOString();

  // Custom styles
  const useStyles = makeStyles(() => ({
    ul: {
      "& .Mui-selected": {
        color: "#fff",
        backgroundColor: "#088FD1",
        border: "1px solid #088FD1",
        "&:hover": {
          backgroundColor: "#088FD1",
        },
      },
    },
    gridItem: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-box-orient": "vertical",
      "-webkit-line-clamp": 2, // Number of lines to show
      fontSize: "14px",
    },
  }));

  const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#FFFEE8",
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: 11,
      },
    })
  );

  const classes = useStyles();

  useEffect(() => {
    // fetchDDML();
    // eslint-disable-next-line
  }, [triggerApi]);

  useEffect(() => {
    setTotalRows(filteredData?.length);
    setTotalPage(Math.floor(filteredData?.length / perPage) + 1);
  }, [filteredData, perPage]);

  // Applying filters
  useEffect(() => {
    let theData = dataDDML;
    let data_ = [];
    setFilteredData(theData);
    // Search input fields
    if (isFilters.search.length !== 0) {
      data_ = theData.filter(
        (item) =>
          isFilters.search.some((searchTerm) => item.location?.toLowerCase()?.includes(searchTerm?.toLowerCase())) ||
          isFilters.search.some((searchTerm) => item.aircraftReg?.toLowerCase()?.includes(searchTerm?.toLowerCase())) ||
          isFilters.search.some((searchTerm) => item.defectDesc?.toLowerCase()?.includes(searchTerm?.toLowerCase())) ||
          isFilters.search.some((searchTerm) => item.ddmlNo?.toLowerCase()?.includes(searchTerm?.toLowerCase())) ||
          isFilters.search.some((searchTerm) =>
            item.limitationsDesc?.toLowerCase()?.includes(searchTerm?.toLowerCase())
          ) ||
          isFilters.search.some((searchTerm) => item.assignedTo?.toLowerCase()?.includes(searchTerm?.toLowerCase()))
      );
      setFilteredData(data_);
    }
    // Station (location)
    if (isFilters.location.length > 0) setFilteredData(theData.filter((e) => isFilters.location.includes(e.location)));
    // Aircraft Registration
    if (isFilters.aircraftReg.length > 0)
      setFilteredData(theData.filter((e) => isFilters.aircraftReg.includes(e.aircraftReg)));
    // Assigned To (Department)
    if (isFilters.assignedTo.length > 0)
      setFilteredData(theData.filter((e) => isFilters.assignedTo.includes(e.assignedTo)));
    // Raised Date
    if (isFilters.raisedDate.length > 0)
      setFilteredData(theData.filter((e) => e.raisedDate.startsWith(isFilters.raisedDate)));
    // Expiry Date
    if (isFilters.expiryDate.length > 0)
      setFilteredData(theData.filter((e) => e.expiryDate.startsWith(isFilters.expiryDate)));
  }, [isFilters, dataDDML]);

  useEffect(() => {
    if (!open) setActiveIndex();
  }, [open]);

  useEffect(() => {
    setPage(1);
  }, [isFilters]);

  async function fetchFHFC() {
    // setSubLoading(true);
    const fetch = makeAPIRequestRun(`get`, `acarsdata/fleetgetfcfh/null`);
    fetch
      .then((response) => {
        setDataFHFC(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useEffect(() => {
    fetchFHFC();
  }, []);

  function handleClick(e, index) {
    setOpen(true);
    setData(e);
    setIndex(index);
    setDataForm(e);
    setEdit(false);
    setActiveIndex(index);
    setNewForm(false);
  }

  function calcBalanceFH(interval, raisedAt, val) {
    let expiry = parseInt(interval) + parseInt(raisedAt);
    let current = val;
    let balance = parseInt(expiry) - parseInt(current);
    return parseInt(balance).toLocaleString() || "-";
  }

  function calcBalanceFC(interval, raisedAt, val) {
    let expiry = parseInt(interval) + parseInt(raisedAt);
    let current = val;
    let balance = parseInt(expiry) - parseInt(current);
    return parseInt(balance).toLocaleString() || "-";
  }

  const IconUnreadIndicator = ({ data }) => {
    let unreadLogs = data.unreadLogs;
    let count = unreadLogs?.materialTool.length + unreadLogs?.planningTech.length + unreadLogs?.rectification.length;

    return (
      count > 0 && (
        <div style={{ position: "absolute", right: "24px" }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
            <circle cx="5" cy="5" r="5" fill="#0ADDDD" />
          </svg>
        </div>
      )
    );
  };

  return !isLoading ? (
    <>
      <div style={{ padding: "40px 0px 0px 24px" }}>
        {filteredData.length > 0 && (
          <SearchBar
            open={openModal}
            setOpen={setOpenModal}
            data={dataDDML}
            isFilters={isFilters}
            setIsFilters={setIsFilters}
            openTitle={openTitle}
            setOpenTitle={setOpenTitle}
            tab={props.tab}
          />
        )}
        <ActiveFilters isFilters={isFilters} setIsFilters={setIsFilters} initialFilters={InitialFilters} />
      </div>
      {filteredData?.length > 0 ? (
        <>
          <div className={styles.tableDiv} style={{ marginTop: "20px" }}>
            <Grid container columns={10}>
              <Grid item xs={0.5} className={styles.tableHeader}>
                Station
              </Grid>
              <Grid item xs={0.7} className={styles.tableHeader}>
                A/C Reg.
              </Grid>
              <Grid item xs={3} className={styles.tableHeader} sx={{ justifyContent: "flex-start" }}>
                Defect Description
              </Grid>
              <Grid item xs={1} className={styles.tableHeader}>
                DDML No.
              </Grid>
              <Grid item xs={1} className={styles.tableHeader}>
                Raised Date
              </Grid>
              <Grid item xs={1} className={styles.tableHeader}>
                Expiry/Balance
              </Grid>
              <Grid item xs={2.8} className={styles.tableHeader} sx={{ justifyContent: "flex-start" }}>
                Limitation Description
              </Grid>
              {filteredData &&
                filteredData.slice(sliceStart, sliceEnd).map((e, index) => {
                  // Calculate the date difference
                  var currentDate = new Date();
                  var expiryDate = new Date(e.expiryDate);
                  var timeDifferenceMs = expiryDate - currentDate;
                  var remainingDays = Math.ceil(timeDifferenceMs / (1000 * 60 * 60 * 24));
                  let calendarDaysInterval = e.calendarDaysInterval || 0;
                  let calendarDaysStartDate = e.calendarDaysStartDate || e.raisedDate;
                  let expiry = addingDate(calendarDaysStartDate, calendarDaysInterval);
                  let date1 = e.formType === "AMD" ? e.calendarDaysStartDate : formattedDate.split("T")[0];
                  let date2 = expiry;
                  let balance = calculateBalanceDay(date1, date2);
                  let showInTable = e.showInTable;
                  let currentFlightHour = dataFHFC.filter((item) => item.AC_REG === e.aircraftReg)[0]?.flight_hour;
                  let currentFlightCycle = dataFHFC.filter((item) => item.AC_REG === e.aircraftReg)[0]?.flight_cycle;
                  if (showInTable === "fh" && e.formType === "STR/FC/FH")
                    balance = calcBalanceFH(e.flightHoursInterval, e.flightHoursRaisedAt, currentFlightHour) + ` (FH)`;
                  else if (showInTable === "fc" && e.formType === "STR/FC/FH")
                    balance = calcBalanceFC(e.flightCycleInterval, e.flightCycleRaisedAt, currentFlightCycle) + `(FC)`;

                  if (!e.flightCycle && !e.flightHours && !e.calendarDays && e.formType === "STR/FC/FH") balance = "-";
                  if (e.formType === "AMD")
                    balance = calculateCalendarBalance(e.calendarDaysStartDate, e.calendarDaysInterval);
                  if (e.formType === "Fuel Sampling" || e.formType === "Hydraulic Sampling")
                    balance = calculateCalendarBalance(e.calendarDaysStartDate, e.calendarDaysInterval);

                  let dayIndicator = "Day";
                  if (balance > 1 || balance < 0) dayIndicator = "Days";
                  return (
                    <>
                      <Grid item xs={10}>
                        <Grid
                          container
                          columns={10}
                          className={`${styles.lineItems} ${activeIndex === index ? styles.selectedIndex : ""}`}
                          sx={{ zIndex: 2 }}
                          onClick={() => handleClick(e, index)}
                        >
                          <Grid
                            item
                            xs={0.5}
                            className={`${styles.tableBody} ${styles.toUpperCase}`}
                            sx={{ alignItems: "center" }}
                          >
                            {e.location}
                          </Grid>
                          <Grid
                            item
                            xs={0.7}
                            className={`${styles.tableBody} ${styles.toUpperCase}`}
                            sx={{ alignItems: "center" }}
                          >
                            {e.aircraftReg}
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            className={`${styles.tableBody} ${styles.toUpperCase}`}
                            sx={{ alignItems: "center", justifyContent: "flex-start" }}
                          >
                            <LightTooltip title={e.defectDesc} followCursor placement="bottom-start">
                              <Typography className={classes.gridItem}>{e.defectDesc}</Typography>
                            </LightTooltip>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            className={`${styles.tableBody} ${styles.toUpperCase}`}
                            sx={{ alignItems: "center" }}
                          >
                            {e.ddmlNo}
                          </Grid>
                          <Grid item xs={1} className={`${styles.tableBody}`} sx={{ alignItems: "center" }}>
                            {formatDateDasr(e.raisedDate)}
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            className={`${styles.tableBody}`}
                            sx={{ alignItems: "center", color: remainingDays <= 3 ? "#E95048" : "" }}
                          >
                            {e.formType === "MEL CAT" ? formatDateDasr(e.expiryDate) : balance + ` ` + dayIndicator}
                          </Grid>
                          <Grid
                            item
                            xs={2.8}
                            className={`${styles.tableBody} ${styles.gridJustifyStart} ${styles.toUpperCase}`}
                            sx={{ alignItems: "center", justifyContent: "flex-start", position: "relative" }}
                          >
                            <LightTooltip title={e.limitationsDesc.toUpperCase()} followCursor placement="bottom-start">
                              <Typography className={classes.gridItem}>{e.limitationsDesc}</Typography>
                            </LightTooltip>
                            <IconUnreadIndicator data={e} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  );
                })}
              <Grid item xs={10} className={`${styles.lineItems}`}>
                <MUIPagination
                  page={parseInt(page)}
                  setPage={setPage}
                  setPerPage={setPerPage}
                  totalPage={parseInt(totalPages)}
                  classes={{ ul: classes.ul }}
                  totalRows={parseInt(totalRows)}
                  sliceStart={sliceStart}
                  initialPerPage={perPage}
                />
              </Grid>
            </Grid>
          </div>
        </>
      ) : (
        <NoData />
      )}
      <ElevadeDrawer
        open={open}
        setOpen={setOpen}
        data={data}
        Component={DDML}
        aoc={aoc}
        type={type}
        index={index}
        dataForm={dataForm}
        edit={edit}
        setEdit={setEdit}
        openNotification={openNotification}
        setOpenNotification={setOpenNotification}
        newForm={newForm}
        setNewForm={setNewForm}
        triggerApi={triggerApi}
        setTriggerApi={setTriggerApi}
        setSelectToMove={setSelectToMove}
        setOpenDialogMoveToAssignment={setOpenDialogMoveToAssignment}
      />
      <DialogMoveAssignment
        open={openDialogMoveToAssignment}
        setOpen={setOpenDialogMoveToAssignment}
        data={selectToMove}
        setTriggerApi={setTriggerApi}
        setData={setSelectToMove}
      />
    </>
  ) : (
    <AirplaneLoader />
  );
};

export default Limitation;
