
import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
export default function StyledDateInput({ fullwidth = false, onChange, value, }) {
    return (<LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker 
    // format='DD-MM-YYYY'
    value={dayjs(new Date(value))} onChange={(e, date) => {
            onChange(e.$d);
        }} sx={{
            width: fullwidth ? '100%' : undefined,
            '& .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #D1D1D1',
                borderRadius: '8px',
                height: '50px',
            },
            '& .MuiInputBase-root': {
                height: '50px',
            },
            '& .MuiInputBase-input': {
                paddingTop: '12px',
                paddingbottom: '6px',
            }
        }}/>
        </LocalizationProvider>);
}
