var timezoneAbbreviation = function (timezoneString) {
  var timezoneAbbreviations = {
    "Afghanistan Time": "AFT",
    "Alaska Daylight Time": "AKDT",
    "Alaska Standard Time": "AKST",
    "Amazon Summer Time": "AMST",
    "Amazon Time": "AMT",
    "Arabia Standard Time": "AST",
    "Argentina Time": "ART",
    "Armenia Summer Time": "AMST",
    "Armenia Time": "AMT",
    "ASEAN Common Time": "ACT",
    "Atlantic Daylight Time": "ADT",
    "Atlantic Standard Time": "AST",
    "Australian Central Daylight Savings Time": "ACDT",
    "Australian Central Standard Time": "ACST",
    "Australian Eastern Daylight Savings Time": "AEDT",
    "Australian Eastern Standard Time": "AEST",
    "Australian Western Daylight Time": "AWDT",
    "Australian Western Standard Time": "AWST",
    "Azerbaijan Time": "AZT",
    "Azores Standard Time": "AZOST",
    "Baker Island Time": "BIT",
    "Bangladesh Standard Time": "BST",
    "Bhutan Time": "BTT",
    "Bolivia Time": "BOT",
    "Brasilia Time": "BRT",
    "British Indian Ocean Time": "BIOT",
    "British Summer Time": "BST",
    "Brunei Time": "BDT",
    "Cape Verde Time": "CVT",
    "Central Africa Time": "CAT",
    "Central Daylight Time": "CDT",
    "Central European Daylight Time": "CEDT",
    "Central European Summer Time": "CEST",
    "Central European Time": "CET",
    "Central Indonesia Time": "CIT",
    "Central Standard Time": "CST",
    "Central Summer Time": "CST",
    "Central Western Standard Time": "CWST",
    "Chamorro Standard Time": "ChST",
    "Chatham Daylight Time": "CHADT",
    "Chatham Standard Time": "CHAST",
    "Chile Standard Time": "CLT",
    "Chile Summer Time": "CLST",
    "China Standard Time": "CST",
    "China time": "CT",
    Choibalsan: "CHOT",
    "Christmas Island Time": "CXT",
    "Chuuk Time": "CHUT",
    "Clipperton Island Standard Time": "CIST",
    "Cocos Islands Time": "CCT",
    "Colombia Summer Time": "COST",
    "Colombia Time": "COT",
    "Cook Island Time": "CKT",
    "Coordinated Universal Time UTC": "UTC",
    "Cuba Daylight Time": "CDT",
    "Cuba Standard Time": "CST",
    "Davis Time": "DAVT",
    "Dumont d'Urville Time": "DDUT",
    "East Africa Time": "EAT",
    "Easter Island Standard Summer Time": "EASST",
    "Easter Island Standard Time": "EAST",
    "Eastern Caribbean Time": "ECT",
    "Eastern Daylight Time": "EDT",
    "Eastern European Daylight Time": "EEDT",
    "Eastern European Summer Time": "EEST",
    "Eastern European Time": "EET",
    "Eastern Greenland Summer Time": "EGST",
    "Eastern Greenland Time": "EGT",
    "Eastern Indonesian Time": "EIT",
    "Eastern Standard Time": "EST",
    "Ecuador Time": "ECT",
    "Falkland Islands Standard Time": "FKST",
    "Falkland Islands Summer Time": "FKST",
    "Falkland Islands Time": "FKT",
    "Fernando de Noronha Time": "FNT",
    "Fiji Time": "FJT",
    "French Guiana Time": "GFT",
    "Further-eastern European Time": "FET",
    "Galapagos Time": "GALT",
    "Gambier Island Time": "GIT",
    "Gambier Islands": "GAMT",
    "Georgia Standard Time": "GET",
    "Gilbert Island Time": "GILT",
    "Greenwich Mean Time UTC": "GMT",
    "Gulf Standard Time": "GST",
    "Guyana Time": "GYT",
    "Hawaii Standard Time": "HST",
    "Hawaii-Aleutian Daylight Time": "HADT",
    "Hawaii-Aleutian Standard Time": "HAST",
    "Heard and McDonald Islands Time": "HMT",
    "Heure Avancée d'Europe Centrale": "HAEC",
    "Hong Kong Time": "HKT",
    "Indian Ocean Time": "IOT",
    "Indian Standard Time": "IST",
    "Indian/Kerguelen": "TFT",
    "Indochina Time": "ICT",
    "Iran Daylight Time": "IRDT",
    "Iran Standard Time": "IRST",
    "Irish Standard Time": "IST",
    "Irkutsk Time": "IRKT",
    "Israel Daylight Time": "IDT",
    "Israel Standard Time": "IST",
    "Japan Standard Time": "JST",
    "Kaliningrad Time": "USZ1",
    "Kamchatka Time": "PETT",
    "Khovd Time": "HOVT",
    "Korea Standard Time": "KST",
    "Kosrae Time": "KOST",
    "Krasnoyarsk Time": "KRAT",
    "Kyrgyzstan time": "KGT",
    "Line Islands Time": "LINT",
    "Lord Howe Standard Time": "LHST",
    "Lord Howe Summer Time": "LHST",
    "Macquarie Island Station Time": "MIST",
    "Magadan Time": "MAGT",
    "Malaysia Standard Time": "MST",
    "Malaysia Time": "MYT",
    "Maldives Time": "MVT",
    "Marquesas Islands Time": "MART",
    "Marshall Islands": "MHT",
    "Mauritius Time": "MUT",
    "Mawson Station Time": "MAWT",
    "Middle European Saving Time": "MEST",
    "Middle European Time": "MET",
    "Moscow Time": "MSK",
    "Mountain Daylight Time": "MDT",
    "Mountain Standard Time": "MST",
    "Myanmar Standard Time": "MST",
    "Myanmar Time": "MMT",
    "Nepal Time": "NPT",
    "New Caledonia Time": "NCT",
    "New Zealand Daylight Time": "NZDT",
    "New Zealand Standard Time": "NZST",
    "Newfoundland Daylight Time": "NDT",
    "Newfoundland Standard Time": "NST",
    "Newfoundland Time": "NT",
    "Niue Time": "NUT",
    "Norfolk Time": "NFT",
    "Omsk Time": "OMST",
    "Oral Time": "ORAT",
    "Pacific Daylight Time": "PDT",
    "Pacific Standard Time": "PST",
    "Pakistan Standard Time": "PKT",
    "Papua New Guinea Time": "PGT",
    "Paraguay Summer Time": "PYST",
    "Paraguay Time": "PYT",
    "Peru Time": "PET",
    "Philippine Standard Time": "PST",
    "Phoenix Island Time": "PHOT",
    "Pohnpei Standard Time": "PONT",
    "Rothera Research Station Time": "ROTT",
    "Réunion Time": "RET",
    "Saint Pierre and Miquelon Daylight Time": "PMDT",
    "Saint Pierre and Miquelon Standard Time": "PMST",
    "Sakhalin Island time": "SAKT",
    "Samara Time": "SAMT",
    "Samoa Standard Time": "SST",
    "Seychelles Time": "SCT",
    "Showa Station Time": "SYOT",
    "Singapore Standard Time": "SST",
    "Singapore Time": "SGT",
    "Solomon Islands Time": "SBT",
    "South African Standard Time": "SAST",
    "South Georgia and the South Sandwich Islands": "GST",
    "Srednekolymsk Time": "SRET",
    "Sri Lanka Time": "SLST",
    "Suriname Time": "SRT",
    "Tahiti Time": "TAHT",
    "Tajikistan Time": "TJT",
    "Thailand Standard Time": "THA",
    "Timor Leste Time": "TLT",
    "Tokelau Time": "TKT",
    "Tonga Time": "TOT",
    "Turkmenistan Time": "TMT",
    "Tuvalu Time": "TVT",
    "Ulaanbaatar Time": "ULAT",
    "Uruguay Standard Time": "UYT",
    "Uruguay Summer Time": "UYST",
    "Uzbekistan Time": "UZT",
    "Vanuatu Time": "VUT",
    "Venezuelan Standard Time": "VET",
    "Vladivostok Time": "VLAT",
    "Volgograd Time": "VOLT",
    "Vostok Station Time": "VOST",
    "Wake Island Time": "WAKT",
    "West Africa Summer Time": "WAST",
    "West Africa Time": "WAT",
    "Western European Daylight Time": "WEDT",
    "Western European Summer Time": "WEST",
    "Western European Time UTC": "WET",
    "Western Indonesian Time": "WIT",
    "Western Standard Time": "WST",
    "Yakutsk Time": "YAKT",
    "Yekaterinburg Time": "YEKT",
    "Zulu Time": "Z",
  };
  return timezoneAbbreviations[timezoneString] || timezoneString;
};

var getTimezoneAbbreviation = function (date) {
  var match;
  date = date.toString();
  // Wed Feb 29 2012 00:00:00 GMT-0800 (Pacific Standard Time)
  // Wed Feb 29 2012 00:00:00 GMT-0800 (PST)
  if ((match = date.match(/\((.+)\)/))) {
    return timezoneAbbreviation(match[1].valueOf());
  }
  // Wed Feb 29 00:00:00 PST 2012
  else if ((match = date.match(/\d{2}:\d{2}:\d{2}\s(\w{1,5})\s/))) {
    return match[1].valueOf();
  }
};

export default getTimezoneAbbreviation;
