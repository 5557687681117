export const ataChapter = {
  ATA_00: { code: 0, name: "Technical Information Weight & Cg, Intro" },
  ATA_01: { code: 1, name: "Certification Documents" },
  ATA_02: { code: 2, name: "Certification-External Livery" },
  ATA_03: { code: 3, name: "Miscellaneous" },
  ATA_04: { code: 4, name: "In Service Flight Testing" },
  ATA_05: { code: 5, name: "Time Limits-Maintenance Checks" },
  ATA_06: { code: 6, name: "Dimensions & Areas" },
  ATA_07: { code: 7, name: "Lifting And Shoring" },
  ATA_08: { code: 8, name: "Leveling & Weighing" },
  ATA_09: { code: 9, name: "Towing & Taxiing" },
  ATA_10: { code: 10, name: "Parking,Mooring,Storage & Return To Service" },
  ATA_11: { code: 11, name: "Placards & Markings" },
  ATA_12: { code: 12, name: "Servicing" },
  ATA_13: { code: 13, name: "" },
  ATA_14: { code: 14, name: "Hardware" },
  ATA_15: { code: 15, name: "" },
  ATA_16: { code: 16, name: "" },
  ATA_17: { code: 17, name: "" },
  ATA_18: { code: 18, name: "" },
  ATA_19: { code: 19, name: "" },
  ATA_20: { code: 20, name: "Standard Practices - Airframe" },
  ATA_21: { code: 21, name: "Air Conditioning" },
  ATA_22: { code: 22, name: "Auto Flight" },
  ATA_23: { code: 23, name: "Communication" },
  ATA_24: { code: 24, name: "Electrical Power" },
  ATA_25: { code: 25, name: "Equipment / Furnishings" },
  ATA_26: { code: 26, name: "Fire Protection" },
  ATA_27: { code: 27, name: "Flight Controls" },
  ATA_28: { code: 28, name: "Fuel" },
  ATA_29: { code: 29, name: "Hydraulic Power" },
  ATA_30: { code: 30, name: "Ice And Rain Protection" },
  ATA_31: { code: 31, name: "Indicating / Record System" },
  ATA_32: { code: 32, name: "Landing Gear" },
  ATA_33: { code: 33, name: "Lights" },
  ATA_34: { code: 34, name: "Navigation" },
  ATA_35: { code: 35, name: "Oxygen" },
  ATA_36: { code: 36, name: "Pneumatic" },
  ATA_37: { code: 37, name: "Vacuum" },
  ATA_38: { code: 38, name: "Water / Waste" },
  ATA_39: {
    code: 39,
    name: "Electrical - Electronic Panels And Multipurpose Components",
  },
  ATA_40: { code: 40, name: "Multisystem" },
  ATA_41: { code: 41, name: "Water Ballast" },
  ATA_42: { code: 42, name: "Integrated Modular Avionics" },
  ATA_43: { code: 43, name: "" },
  ATA_44: { code: 44, name: "Cabin Systems" },
  ATA_45: { code: 45, name: "Onboard Maintenance Systems (OMS)" },
  ATA_46: { code: 46, name: "Information Systems" },
  ATA_47: { code: 47, name: "Inert Gas System" },
  ATA_48: { code: 48, name: "In Flight Fuel Dispensing" },
  ATA_49: { code: 49, name: "(Airborne) Auxillary Power Unit" },
  ATA_50: { code: 50, name: "Cargo And Accessory Compoartments" },
  ATA_51: { code: 51, name: "Standard" },
  ATA_52: { code: 52, name: "Doors" },
  ATA_53: { code: 53, name: "Fuselage" },
  ATA_54: { code: 54, name: "Nacelles" },
  ATA_55: { code: 55, name: "Stabilizers" },
  ATA_56: { code: 56, name: "Windows" },
  ATA_57: { code: 57, name: "Wings" },
  ATA_58: { code: 58, name: "" },
  ATA_59: { code: 59, name: "" },
  ATA_60: { code: 60, name: "Standard Practices - Prop./Rotor" },
  ATA_61: { code: 61, name: "Propellers / Propulsors" },
  ATA_62: { code: 62, name: "Main Rotor(S)" },
  ATA_63: { code: 63, name: "Main Rotor Drive(S)" },
  ATA_64: { code: 64, name: "Tail Rotor" },
  ATA_65: { code: 65, name: "Tail Rotor Drive'" },
  ATA_66: { code: 66, name: "Folding Blades/Pylon" },
  ATA_67: { code: 67, name: "Rotors FLight Control" },
  ATA_68: { code: 68, name: "" },
  ATA_69: { code: 69, name: "" },
  ATA_70: { code: 70, name: "Standard Practices - Engine" },
  ATA_71: { code: 71, name: "Power Plant" },
  ATA_72: { code: 72, name: "Turbine/Turboprop Engine" },
  ATA_72T: {
    code: 721,
    name: "Engine - Turbine / Turboprop, Ducted Fan/Unducted Fan",
  },
  ATA_72R: { code: 722, name: "Engine - Reciprocating" },
  ATA_73: { code: 73, name: "Engine - Fuel And Control" },
  ATA_74: { code: 74, name: "Ignition" },
  ATA_75: { code: 75, name: "Bleed Air" },
  ATA_76: { code: 76, name: "Engine Controls" },
  ATA_77: { code: 77, name: "Engine Indicating" },
  ATA_78: { code: 78, name: "Exhaust" },
  ATA_79: { code: 79, name: "Oil" },
  ATA_80: { code: 80, name: "Starting" },
  ATA_81: { code: 81, name: "Turbines (Reciprocating Engines)" },
  ATA_82: { code: 82, name: "Water Injection" },
  ATA_83: { code: 83, name: "Accessory Gear Box (Engine Driven)" },
  ATA_84: { code: 84, name: "Propulsion Augmentation" },
  ATA_85: { code: 85, name: "Fuel Cell Systems" },
  ATA_86: { code: 86, name: "" },
  ATA_87: { code: 87, name: "" },
  ATA_88: { code: 88, name: "" },
  ATA_89: { code: 89, name: "" },
  ATA_90: { code: 90, name: "Irregularity Reports" },
  ATA_91: { code: 91, name: "Charts" },
  ATA_92: { code: 91, name: "(1)Engine Data (2)Electric & Electronic Common Installation" },
  ATA_93: { code: 93, name: "(1)Vu Panels (2)Installation Dwgs-Piping" },
  ATA_94: { code: 94, name: "(1)Zoning (2)List Of Fictitious Circuits" },
  ATA_95: { code: 95, name: "Computer Drawings" },
  ATA_96: { code: 96, name: "Design Only Computerized Drawings-Assemblies" },
  ATA_97: { code: 97, name: "" },
  ATA_98: { code: 98, name: "Structural Test" },
};
