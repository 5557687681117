/* eslint-disable no-unused-expressions */
import React from 'react';
import { Box, Button, Divider, Grid, Tooltip, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import StyledTextField from "../../../components/inputs/text";
import StyledDropDown from "../../../components/inputs/dropdowns/StyledDropDown";
import LoadingInput from "../../../components/inputs/LoadingInput";
import StyledDateInput from "../../../components/inputs/dates/StyledDateInput";
import useMutateGQL from "../../../../models/useMutateGQL";
import { getMonth2D, getDate2D } from "../../../utils/dates";
import { useNotification } from "../../../components/notifications/UniversalNotification";
import { useDialog } from "../../../components/dialog-box/DialogInterface";
import useAddEqDatas from "./useAddEqDatas";
import useAddEqStates from "./useAddEqStates";
import { validateInputStates } from "../../../components/inputs/useInputState";
import { useAppSelector } from "../../../../app/hooks";
import { useCreateInventory } from '../../../../models/custom/inventory-model'
import { replaceLocation } from '../../../../models/custom/inventory-model'

export default function AddEquipmentMain({ handleClose, refetch }) {
    
	const { companyProfile, userProfile } = useAppSelector((state) => state.userEntity);
	const code = companyProfile?.code;
	const fullname = userProfile?.fullName;
	const photo = userProfile?.photo;

    const [searchSeat, setSearchSeat] = useState();
    const openNotification = useNotification();
    const openDialog = useDialog();

    // inputs 
    const addEqStates = useAddEqStates();
    const dateFormattedmfgDate = addEqStates.mfgDate.value ? `${new Date(addEqStates.mfgDate.value).getFullYear()}-${getMonth2D(new Date(addEqStates.mfgDate.value))}-${getDate2D(new Date(addEqStates.mfgDate.value))}` : undefined;
    const dateFormattedexpDate = addEqStates.expDate.value ? `${new Date(addEqStates.expDate.value).getFullYear()}-${getMonth2D(new Date(addEqStates.expDate.value))}-${getDate2D(new Date(addEqStates.expDate.value))}` : undefined;
    const { locationInventoryDetails, allInventoryLocations, allFleetsWithDistinctModel, allFleetsFilteredByModel, allInventoryItems, allItemStatuses, } = useAddEqDatas({
        inputModel: addEqStates.inputModel.value,
        locationTypeId: addEqStates.locationTypeId.value,
        fleetId: addEqStates.inputRegNoId.value,
        searchSeat,
    });

    const { mutate: add } = useCreateInventory({
		addEqStates,
		dateFormattedmfgDate,
		dateFormattedexpDate,
	});

    const usingStatusesData = allItemStatuses.data?.map(({ node }) => {
        return { ...node };
    });
    const usingItemTypeData = allInventoryItems.data?.map(({ node }) => {
        return { ...node };
    });
    const modelsOpts = [];
    allFleetsWithDistinctModel.data?.map(({ node }) => {
        if (node?.model) {
            modelsOpts.push({
                value: node?.model,
                label: node?.model,
            });
        }
    });
    const regNosOpts = [];
    allFleetsFilteredByModel.data?.map(({ node }) => {
        if (node?.regNo) {
            regNosOpts.push({
                value: node?.id,
                label: node?.regNo,
            });
        }
    });
    const locTypeOpts = [];
    allInventoryLocations.data?.map(({ node }) => {
        if (node?.id) {
            locTypeOpts.push({
                value: node?.id,
                label: node?.name,
            });
        }
    });
    const locNoOpts = [];
    locationInventoryDetails.data?.map(({ node }) => {
        locNoOpts.push({
            value: node?.id,
            label: (
            // <Typography>{node?.seatNo} {node?.occupied === true ? '' :
            //     <Tooltip title='Available'>
            //         <span style={{ cursor: 'pointer' }}>
            //             <CheckCircleIcon color="success" sx={{ marginLeft: "0.2em", fontSize: "15px" }} />
            //         </span>
            //     </Tooltip>
            // }</Typography>
            <Box sx={{ width: '100%' }}>
                    <Grid container>
                        <Grid xs={2}>
                            {node?.occupied === true ?
                    (<Tooltip title='Occupied'>
                                        <span style={{ cursor: 'pointer' }}>
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.875 9.625L7.75 11.5L12.125 7.125M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z" stroke="#6AB47B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </span>

                                    </Tooltip>) : (<Tooltip title='Available'>
                                        <span style={{ cursor: 'pointer' }}>
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#C3C3C3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </span>
                                    </Tooltip>)}
                        </Grid>
                        <Grid xs={10}>
                            <Typography>
                                {node?.seatNo}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>),
        });
    });
    return (<Box style={{
            width: '100%',
            borderRadius: '0px',
            height: '100%',
            position: 'relative',
        }}>

            <Box style={{
            padding: '1em',
        }}>
                <Grid container spacing='1em'>
                    {[
            {
                isRequired: addEqStates.tagId.isMandatory,
                title: 'Tag ID',
                inputComp: (<StyledTextField inputRef={addEqStates.tagId.inputRef} onChange={(e) => {
                        addEqStates.tagId.setValue(e.target.value);
                    }} fullWidth error={addEqStates.tagId.isError} helperText={addEqStates.tagId.errorMessage}/>)
            },
            {
                isRequired: addEqStates.serialNo.isMandatory,
                title: 'Serial No.',
                inputComp: (<StyledTextField inputRef={addEqStates.serialNo.inputRef} onChange={(e) => {
                        addEqStates.serialNo.setValue(e.target.value);
                    }} fullWidth error={addEqStates.serialNo.isError} helperText={addEqStates.serialNo.errorMessage}/>)
            },
            {
                isRequired: addEqStates.eqType.isMandatory,
                title: 'Equipment Type',
                inputComp: (allInventoryItems.isLoading ? <LoadingInput /> : <StyledDropDown inputRef={addEqStates.eqType.inputRef} 
                // defaultValue={itemTypeData[0]?.node?.id}
                value={addEqStates.eqType.value} handleChange={(e) => {
                        addEqStates.eqType.setValue(e.target.value);
                    }} options={usingItemTypeData.map(({ name, id }) => {
                        return {
                            label: name,
                            value: id
                        };
                    })} error={addEqStates.eqType.isError} helperText={addEqStates.eqType.errorMessage}/>)
            },
            {
                isRequired: addEqStates.status.isMandatory,
                title: 'Status',
                inputComp: (allItemStatuses.isLoading ? <LoadingInput /> : <StyledDropDown inputRef={addEqStates.status.inputRef} 
                // defaultValue={statusesData[0]?.node?.id}
                value={addEqStates.status.value} error={addEqStates.status.isError} helperText={addEqStates.status.errorMessage} handleChange={(e) => {
                        addEqStates.status.setValue(e.target.value);
                    }} options={usingStatusesData.map(({ name, id, code }) => {
                        let labelHolder = getLabelByCode(code, name);
                        return {
                            label: labelHolder,
                            value: id
                        };
                    })}/>)
            },
            {
                isRequired: addEqStates.mfgDate.isMandatory,
                title: 'MFG Date',
                inputComp: (<StyledDateInput value={addEqStates.mfgDate.value} onChange={(date) => {
                        addEqStates.mfgDate.setValue(date);
                    }} fullwidth/>)
            },
            {
                isRequired: addEqStates.expDate.isMandatory,
                title: 'Expiry Date',
                inputComp: (<Fragment>
                                    <StyledDateInput value={addEqStates.expDate.value} onChange={(date) => {
                        addEqStates.expDate.setValue(date);
                    }} fullwidth/>
                                </Fragment>)
            },
        ].map(({ title, inputComp, isRequired }) => {
            return (<GridItem title={title} inputComp={inputComp} isRequired={isRequired}/>);
        })}
                    <GridItem title='A/C Model:' inputComp={(<StyledDropDown inputRef={addEqStates.inputModel.inputRef} value={addEqStates.inputModel.value} handleChange={(e) => {
                addEqStates.inputModel.setValue(e.target.value);
            }} options={(allFleetsWithDistinctModel && !allFleetsWithDistinctModel.isLoading) ? modelsOpts : []} error={addEqStates.inputModel.isError} helperText={addEqStates.inputModel.errorMessage}/>)} isRequired={addEqStates.inputModel.isMandatory}/>
                    <GridItem title='A/C Reg' inputComp={(<StyledDropDown inputRef={addEqStates.inputRegNoId.inputRef} disabled={addEqStates.inputModel.value ? false : true} value={addEqStates.inputRegNoId.value} handleChange={(e) => {
                addEqStates.inputRegNoId.setValue(e.target.value);
            }} options={(allFleetsFilteredByModel && !allFleetsFilteredByModel.isLoading) ? regNosOpts : []} error={addEqStates.inputRegNoId.isError} helperText={addEqStates.inputRegNoId.errorMessage}/>)} isRequired={addEqStates.inputRegNoId.isMandatory}/>
                    <GridItem title='Location Type' inputComp={(<StyledDropDown inputRef={addEqStates.locationTypeId.inputRef} disabled={addEqStates.inputRegNoId.value ? false : true} value={addEqStates.locationTypeId.value} handleChange={(e) => {
                addEqStates.locationTypeId.setValue(e.target.value);
            }} options={(allInventoryLocations && !allInventoryLocations.isLoading) ? locTypeOpts : []} error={addEqStates.locationTypeId.isError} helperText={addEqStates.locationTypeId.errorMessage}/>)} isRequired={addEqStates.locationTypeId.isMandatory}/>
                    <GridItem title='Location Number' inputComp={(<StyledDropDown onSearch={(val) => {
                setSearchSeat(val);
            }} inputRef={addEqStates.locationDetailsId.inputRef} disabled={addEqStates.locationTypeId.value ? false : true} value={addEqStates.locationDetailsId.value} handleChange={(e) => {
                addEqStates.locationDetailsId.setValue(e.target.value);
                setSearchSeat(undefined);
            }} options={(locationInventoryDetails.data && !locationInventoryDetails.isLoading) ? locNoOpts : []} error={addEqStates.locationDetailsId.isError} helperText={addEqStates.locationDetailsId.errorMessage}/>)} isRequired={addEqStates.locationDetailsId.isMandatory}/>
                    <Box sx={{
            position: 'absolute',
            bottom: '0px',
            width: '100%',
        }}>
                        <Divider />
                        <Grid container>
                            <Grid item xs={6} sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
                                <Button onClick={() => {
            handleClose();
        }} sx={{ height: '42px', margin: '1em', width: '100%', borderRadius: '8px' }}>
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item xs={6} sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
                                <Button variant="contained" color="primary" sx={{ height: '42px', margin: '1em', width: '100%', borderRadius: '8px' }} onClick={() => {
            const { isOk } = validateInputStates({
                tagId: addEqStates.tagId,
                serialNo: addEqStates.serialNo,
                eqType: addEqStates.eqType,
                status: addEqStates.status,
                // mfgDate: addEqStates.mfgDate,
                // expDate: addEqStates.expDate,
                locationDetailsId: addEqStates.locationDetailsId,
                locationTypeId: addEqStates.locationTypeId,
                inputModel: addEqStates.inputModel,
                inputRegNoId: addEqStates.inputRegNoId,
            }, true);
            if (isOk) {
                add().then((res) => {
                    const { isError, errorMessage, data } = res;
                    if (!isError) {
                        openNotification('Equipment saved!', {
                            variant: 'info',
                            onClose: () => {
                                handleClose();
                                refetch();
                            }
                        });
                    }
                    else if (errorMessage.includes('inventory_inventories_tag_key') && errorMessage.includes('already exist')) {
                        openNotification('Saving failed!', {
                            variant: 'error',
                            helperText: 'Duplicated tag id, please use other value.'
                        });
                    }
                    else if (errorMessage.includes('created but the Location you try to assign the item already occupied')) {
                        openDialog('Location is occupied', <Typography>
                                                            Item has been <span style={{ color: 'blue', fontWeight: 'bolder' }}>created</span> but the location you are trying to assign is already occupied, would you like to <span style={{ color: 'red', fontWeight: 'bolder' }}>replace</span> the existing one?
                                                        </Typography>, {
                            onConfirm: () => {
                                openNotification(`Are you sure?`, {
                                    variant: 'warning',
                                    onConfirm: () => {
                                        replaceLocation(addEqStates.tagId.value, addEqStates.locationDetailsId.value, addEqStates.inputRegNoId.value, fullname, photo).then(({ isError, errorMessage, data }) => {
                                            if (!isError) {
                                                openNotification('Successfully replaced', {
                                                    variant: 'success',
                                                });
                                            }
                                            else {
                                                openNotification(`Error ${errorMessage}`, { variant: 'error' });
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    }
                    else {
                        openNotification('Saving failed!', {
                            variant: 'error',
                            helperText: `Error ${errorMessage}`
                        });
                    }
                });
            }
            else {
                // openNotification('Please fill in all the required field(s)', { variant: 'warning' })
                // do nothing instead
            }
        }}>
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Box>
        </Box>);
}
function GridItem({ title, inputComp, isRequired = true, }) {
    return (<Grid item xs={6}>
            <Typography sx={{
            fontSize: '14px',
            marginBottom: '0.3em',
        }}>{title}{isRequired ? <span style={{ color: 'red' }}>*</span> : ''}</Typography>
            {inputComp}
        </Grid>);
}
function getLabelByCode(code, defaultVal) {
    let label = defaultVal;
    switch (code) {
        case 'A':
            label = 'Active';
            break;
        case 'L':
            label = 'Missing';
            break;
        case 'R':
            label = 'Near Expiry';
            break;
        case 'E':
            label = 'Expired';
            break;
        case 'T':
            label = 'Error';
            break;
        case 'D':
            label = 'Defect';
            break;
        default:
            label = 'Active';
            break;
    }
    return label;
}
