import React from "react";
import Lottie from "lottie-react";
import loadingAP from "./loading_product.json";
const ProductLoading = () => {
	return (
		<Lottie
			animationData={loadingAP}
			loop={true}
			height={200}
			width={300}
		/>
	);
};
export default ProductLoading;
