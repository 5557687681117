
import { Button, IconButton, styled } from "@mui/material";
import React from 'react'
import  { Fragment } from "react";
import {
	SnackbarProvider,
	useSnackbar,
	MaterialDesignContent,
} from "notistack";
import { useAppDispatch } from "../../../../app/hooks";
import { gothamNormal } from "../../../../constants/fonts";
import { commonFontSizes } from "../../../../constants/styles";
import HelpIcon from "@mui/icons-material/Help";

function setIsNotificationOpenSyncDrawer() {}

export default function NotificationInterface({ children }) {
	const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
		borderRadius: "12px",
		backgroundColor: "#333333",
		// '&.notistack-MuiContent-success': {
		//     backgroundColor: '#333333',
		// },
		// '&.notistack-MuiContent-error': {
		//     backgroundColor: '#333333',
		// },
		// '&.notistack-MuiContent-warning': {
		//     backgroundColor: '#333333',
		// },
		// '&.notistack-MuiContent-info': {
		//     backgroundColor: '#333333',
		// },
		// '&.notistack-MuiContent-default': {
		//     backgroundColor: '#333333',
		// },
	}));
	return (
		<SnackbarProvider
			maxSnack={3} // Maximum number of notifications to display at a time
			autoHideDuration={3000} // Duration (in milliseconds) before automatically closing the notification
			anchorOrigin={{
				vertical: "top",
				horizontal: "center",
			}}
			iconVariant={{
				success: (
					<svg
						style={{ marginRight: "10.4px" }}
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M9.9999 19.5984C15.3018 19.5984 19.5999 15.3004 19.5999 9.99844C19.5999 4.6965 15.3018 0.398438 9.9999 0.398438C4.69797 0.398438 0.399902 4.6965 0.399902 9.99844C0.399902 15.3004 4.69797 19.5984 9.9999 19.5984ZM14.4484 8.44697C14.9171 7.97834 14.9171 7.21854 14.4484 6.74991C13.9798 6.28128 13.22 6.28128 12.7514 6.74991L8.7999 10.7014L7.24843 9.14991C6.7798 8.68128 6.02 8.68128 5.55137 9.14991C5.08275 9.61854 5.08275 10.3783 5.55137 10.847L7.95137 13.247C8.42 13.7156 9.1798 13.7156 9.64843 13.247L14.4484 8.44697Z"
							fill="#08D1AF"
						/>
					</svg>
				),
				error: (
					<svg
						style={{ marginRight: "10.4px" }}
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M9.9999 19.5984C15.3018 19.5984 19.5999 15.3004 19.5999 9.99844C19.5999 4.6965 15.3018 0.398438 9.9999 0.398438C4.69797 0.398438 0.399902 4.6965 0.399902 9.99844C0.399902 15.3004 4.69797 19.5984 9.9999 19.5984ZM8.44843 6.74991C7.9798 6.28128 7.22 6.28128 6.75137 6.74991C6.28275 7.21854 6.28275 7.97834 6.75137 8.44697L8.30285 9.99844L6.75137 11.5499C6.28275 12.0185 6.28275 12.7783 6.75137 13.247C7.22 13.7156 7.9798 13.7156 8.44843 13.247L9.9999 11.6955L11.5514 13.247C12.02 13.7156 12.7798 13.7156 13.2484 13.247C13.7171 12.7783 13.7171 12.0185 13.2484 11.5499L11.697 9.99844L13.2484 8.44697C13.7171 7.97834 13.7171 7.21854 13.2484 6.74991C12.7798 6.28128 12.02 6.28128 11.5514 6.74991L9.9999 8.30138L8.44843 6.74991Z"
							fill="#D15656"
						/>
					</svg>
				),
				warning: (
					<HelpIcon
						sx={{
							marginRight: "10.4px",
							fontSize: "19.2px",
							color: "#08D1AF",
						}}
					/>
				),
				info: (
					<svg
						style={{ marginRight: "10.4px" }}
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M9.9999 19.5984C15.3018 19.5984 19.5999 15.3004 19.5999 9.99844C19.5999 4.6965 15.3018 0.398438 9.9999 0.398438C4.69797 0.398438 0.399902 4.6965 0.399902 9.99844C0.399902 15.3004 4.69797 19.5984 9.9999 19.5984ZM14.4484 8.44697C14.9171 7.97834 14.9171 7.21854 14.4484 6.74991C13.9798 6.28128 13.22 6.28128 12.7514 6.74991L8.7999 10.7014L7.24843 9.14991C6.7798 8.68128 6.02 8.68128 5.55137 9.14991C5.08275 9.61854 5.08275 10.3783 5.55137 10.847L7.95137 13.247C8.42 13.7156 9.1798 13.7156 9.64843 13.247L14.4484 8.44697Z"
							fill="#08D1AF"
						/>
					</svg>
				),
			}}
			Components={{
				success: StyledMaterialDesignContent,
				error: StyledMaterialDesignContent,
				warning: StyledMaterialDesignContent,
				info: StyledMaterialDesignContent,
			}}
		>
			{children}
		</SnackbarProvider>
	);
}
function SnackbarConfirmButton({ snackbarKey, onConfirm }) {
	const { closeSnackbar } = useSnackbar();
	const dispatch = useAppDispatch();
	return (
		<Button
			style={{ textTransform: "none", color: "white" }}
			// variant='contained'
			size="small"
			onClick={() => {
				onConfirm();
				closeSnackbar(snackbarKey);
				dispatch(
					setIsNotificationOpenSyncDrawer({
						isOpen: false,
					})
				);
			}}
		>
			Confirm
		</Button>
	);
}
function SnackbarCloseButton({ snackbarKey, variant }) {
	const { closeSnackbar } = useSnackbar();
	const dispatch = useAppDispatch();
	return (
		<IconButton
			onClick={() => {
				closeSnackbar(snackbarKey);
				dispatch(
					setIsNotificationOpenSyncDrawer({
						isOpen: false,
					})
				);
			}}
		>
			<CloseIcon
				style={{
					color:
						variant === "success" ||
						variant === "error" ||
						variant === "info" ||
						variant === "warning"
							? "white"
							: "black",
				}}
			/>
		</IconButton>
	);
}
export function useNotification() {
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useAppDispatch();
	function openNotification(message, options) {
		dispatch(
			setIsNotificationOpenSyncDrawer({
				isOpen: true,
			})
		);
		enqueueSnackbar(
			options?.helperText ? (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<span
						style={{
							fontFamily: gothamNormal,
							fontSize: commonFontSizes.m,
						}}
					>
						{message}
					</span>
					<span
						style={{
							fontFamily: gothamNormal,
							fontSize: commonFontSizes.xs,
							color: "#D1D1D1",
						}}
					>
						{options?.helperText}
					</span>
				</div>
			) : (
				message
			),
			{
				...options,
				variant: options?.onConfirm ? "warning" : options?.variant,
				action: (snackbarKey) => {
					const onConfirm = options?.onConfirm;
					return (
						<Fragment>
							{onConfirm && (
								<SnackbarConfirmButton
									onConfirm={onConfirm}
									snackbarKey={snackbarKey}
								/>
							)}
							<SnackbarCloseButton
								snackbarKey={snackbarKey}
								variant={options?.variant ?? "info"}
							/>
						</Fragment>
					);
				},
			}
		);
	}
	return openNotification;
}
function CloseIcon({ style }) {
	const { color = "#F7F7F7" } = style;
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M3.43451 3.43353C3.74693 3.12111 4.25346 3.12111 4.56588 3.43353L8.0002 6.86785L11.4345 3.43353C11.7469 3.12111 12.2535 3.12111 12.5659 3.43353C12.8783 3.74595 12.8783 4.25248 12.5659 4.5649L9.13157 7.99922L12.5659 11.4335C12.8783 11.746 12.8783 12.2525 12.5659 12.5649C12.2535 12.8773 11.7469 12.8773 11.4345 12.5649L8.0002 9.13059L4.56588 12.5649C4.25346 12.8773 3.74693 12.8773 3.43451 12.5649C3.12209 12.2525 3.12209 11.746 3.43451 11.4335L6.86882 7.99922L3.43451 4.5649C3.12209 4.25248 3.12209 3.74595 3.43451 3.43353Z"
				fill={color}
			/>
		</svg>
	);
}
