import React from "react";
import { Box, Fade } from "@mui/material";
import IconClose from "common/images/icons/elevade/Icon-Close.svg";

const Preview = ({ open, setOpen, fileLink }) => {
  const handleClose = () => setOpen(false);

  const style = {
    position: "fixed",
    top: "53%",
    left: "34%",
    width: "50%",
    height: "80%",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 8px 19px 0px rgba(0, 0, 0, 0.20)",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    overflow: "auto",
    display: "flex",
    justifyContent: "center",
  };

  return (
    <>
      {open && (
        <Fade in={open}>
          <Box sx={style}>
            <div style={{ alignItems: "center" }}>
              <img src={fileLink} alt={`Preview`} style={{ objectFit: "contain", width: "100%", height: "100%" }} />
            </div>
            <img
              src={IconClose}
              alt={"icon-close"}
              style={{ position: "fixed", top: "0px", right: "0px", cursor: "pointer", zIndex: "9" }}
              onClick={handleClose}
            />
          </Box>
        </Fade>
      )}
    </>
  );
};

export default Preview;
