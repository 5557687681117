import React from 'react';
import styles from "../Dasr.module.css";

const NewReport = ({ title, icon, ...props }) => {
  return (
    <>
      <div
        className={`${styles.divDateToggle} ${props.disabled ? styles.divDisabled : styles.divButtonBlue}`}
        style={{ display: "flex", justifyContent: "center" }}
        {...props}
      >
        <img src={icon} alt="icon-download" height={20} style={{ marginRight: "8px" }} />
        {title}
      </div>
    </>
  );
};

export default NewReport;
