import React from 'react';
import IconAssignee from "common/images/icons/elevade/Filter-operator.svg";
import IconAssigneeActive from "common/images/icons/elevade/Filter-operator-active.svg";
import IconAtaChapter from "common/images/icons/elevade/BookOpen.svg";
import IconAtaChapterActive from "common/images/icons/elevade/BookOpen-active.svg";
import IconFaultPriority from "common/images/icons/elevade/Filter-fault.svg";
import IconFaultPriorityActive from "common/images/icons/elevade/Filter-fault-active.svg";
import IconCondition from "common/images/icons/elevade/Filter-flag.svg";
import IconConditionActive from "common/images/icons/elevade/Filter-flag-active.svg";
import styles from "./FilterBox.module.css";
import { Grid, Checkbox, styled, Radio } from "@mui/material";
import { ClickAwayListener } from "@mui/base";

const FilterBox = ({ open, setOpen, data, title, isFilters, setIsFilters, isLast }) => {
  const handleClickAway = () => {
    setOpen(false);
  };

  let key = "";
  if (title === "Operator") key = "fleet";
  else if (title === "ATA Chapter") key = "ata_head";
  else if (title === "Condition") key = "condition";
  else if (title === "Fault Priority") key = "priority";

  function getIcon(title, active) {
    if (title === "Operator") return active ? IconAssigneeActive : IconAssignee;
    else if (title === "ATA Chapter") return active ? IconAtaChapterActive : IconAtaChapter;
    else if (title === "Condition") return active ? IconConditionActive : IconCondition;
    else if (title === "Fault Priority") return active ? IconFaultPriorityActive : IconFaultPriority;
  }
  const getFilterLabel = (data, title) => {
    const dataLength = data[key]?.length;
    const dataLengthLabel = data[key]?.length - 1;
    let dataLabel = "";
    let additionalLabel = "";
    if (dataLength > 0) {
      dataLabel = data[key][0];
      if (dataLength > 1) additionalLabel = ` +${dataLengthLabel}`;
      return dataLabel + additionalLabel;
    }
    if (typeof data === "string") return data;
    return title;
  };

  function getFilterList(title) {
    let col = "";
    if (title === "Operator") col = "fleet";
    if (title === "ATA Chapter") col = "ata_head";
    if (title === "Condition") col = "condition";
    if (title === "Fault Priority") col = "priority";
    let options = [];
    let option = [
      ...new Set(
        data &&
          data?.map((item) => {
            return item[col];
          })
      ),
    ];
    let sorted = option.sort();
    sorted.forEach((item) => {
      if (item !== null && item !== "" && item !== " ") {
        options.push(item);
      }
    });
    return options;
  }

  function handleOpenFilter() {
    setOpen(!open);
  }

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div
          className={`${styles.filterDivPosition} ${isFilters[key] && isFilters[key]?.length > 0 ? styles.active : ""}`}
        >
          <span
            className={`${styles.filterDiv} ${title === "Operator" && isFilters ? styles.active : ""}`}
            onClick={() => handleOpenFilter()}
          >
            <img
              src={getIcon(title, typeof isFilters === "string" || isFilters[key].length > 0)}
              height="16px"
              alt="filter-icon"
              className={styles.filterIcon}
            />
            <div style={{ marginTop: "4px" }}>{getFilterLabel(isFilters, title)}</div>
          </span>
          <FilterModal
            open={open}
            title={title}
            list={getFilterList(title)}
            isFilters={isFilters}
            setIsFilters={setIsFilters}
            isLast={isLast}
          />
        </div>
      </ClickAwayListener>
    </>
  );
};

const FilterModal = ({ open, title, list, isFilters, setIsFilters, isLast }) => {
  let titleRef = title.replace(" ", "_") + "_dasr";

  let key = "";
  if (title === "Operator") key = "operator";
  else if (title === "ATA Chapter") key = "ata_head";
  else if (title === "Condition") key = "condition";
  else if (title === "Fault Priority") key = "priority";

  function handleChecked(value) {
    if (key === "paxCapacity") value = parseInt(value);
    const updatedChecked = [...isFilters[key], value];
    const updatedFilters = { ...isFilters, [key]: updatedChecked };
    setIsFilters(updatedFilters);
  }

  function handleUnChecked(value) {
    if (key === "paxCapacity") value = parseInt(value);
    const updatedChecked = isFilters[key].filter((item) => item !== value);
    const updatedFilters = { ...isFilters, [key]: updatedChecked };
    setIsFilters(updatedFilters);
  }

  const handleRadioChange = (event) => {
    if (key === "operator") setIsFilters(event.target.value);
  };

  const StyledCheckbox = styled(Checkbox)(() => ({
    [`.MuiCheckbox-root`]: {
      marginTop: "6px",
      border: "1px solid blue",
    },
    marginTop: "6px",
    color: "#94A3B8",
  }));

  const StyledRadiobox = styled(Radio)(() => ({
    [`.MuiCheckbox-root`]: {
      marginTop: "6px",
      border: "1px solid blue",
    },
    marginTop: "6px",
    color: "#94A3B8",
  }));

  return (
    open && (
      <>
        <div className={`${styles.filterModal} ${isLast ? styles.isLastModal : ""}`} id={titleRef}>
          <span style={{ color: "#838383", marginBottom: "0px" }} key="">
            <Grid container columns={2}>
              <Grid item xs={2} sx={{ padding: "10px 0 0 8px" }}>
                {title}
              </Grid>
            </Grid>
          </span>
          <ul className={`${styles.ul}`}>
            {list &&
              list?.map((item, index) => {
                if (key === "paxCapacity") item = parseInt(item);
                return (
                  <li className={`${styles.li}`} key={index}>
                    <Grid container columns={3}>
                      <Grid item xs={2} sx={{ paddingTop: "10%" }}>
                        <span
                          style={{
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            color: "#333333",
                          }}
                        >
                          {item}
                        </span>
                      </Grid>
                      <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-end" }}>
                        {title !== "Operator" ? (
                          <StyledCheckbox
                            value={item}
                            checked={isFilters[key]?.filter((e) => e === item).length > 0}
                            onChange={(e) => {
                              if (e.target.checked) {
                                handleChecked(e.target.value);
                              } else handleUnChecked(e.target.value);
                            }}
                          />
                        ) : (
                          <StyledRadiobox
                            value={item}
                            checked={isFilters === item}
                            name="radio-buttons"
                            onChange={handleRadioChange}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </li>
                );
              })}
          </ul>
        </div>
      </>
    )
  );
};

export default FilterBox;
