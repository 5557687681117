import React from "react";
import { useState } from "react";
import { Grid, useMediaQuery } from "@mui/material";
import FilterBox from "./FilterBox";
import styles from "./SearchBar.module.css";
import MagnifyingGlass from "common/images/icons/elevade/MagnifyingGlass.svg";

const SearchBar = ({ data, isSearch, setIsSearch, isFilters, setIsFilters, operator, setOperator }) => {
  const isWeb = useMediaQuery("(min-width:900px)");
  const [openOperator, setOpenOperator] = useState(false);

  const style = {
    input: {
      border: "0px",
      borderRight: isWeb ? "0px solid #c3c3c3" : "0px solid #c3c3c3",
      paddingLeft: "32px",
      width: "100%",
      maxWidth: "310px",
      marginTop: "10px",
      backgroundImage: `url(${MagnifyingGlass})`,
      backgroundRepeat: "no-repeat",
      backgroundPositionY: "5px",
      backgroundSize: "16px",
      backgroundColor: "transparent",
      fontFamily: "Gotham-Book",
      "&&:focus": {
        outline: "none",
      },
    },
  };

  const handleKeyDown = (event) => {
    let key = "search";
    if (event.key === "Enter") {
      if (event.target.value !== "") {
        const updatedChecked = [...isFilters[key], event.target.value.toUpperCase()];
        const updatedFilters = { ...isFilters, [key]: updatedChecked };
        setIsFilters(updatedFilters);
      }
      event.currentTarget.value = "";
    }
  };

  return (
    <>
      <Grid container columns={2}>
        <Grid item xs={0.5} sx={{ paddingLeft: "1px" }}>
           <input
            type="text"
            placeholder="Search"
            style={style.input}
            className={`${styles.input}`}
            value={isSearch}
            defaultValue={isSearch}
            onKeyDown={handleKeyDown}
            onChange={(e) => setIsSearch(e.target.value)}
          />
        </Grid>
        <Grid item xs={1.5} className={styles.gridJustifyEnd}>
          <FilterBox 
            open={openOperator} 
            setOpen={setOpenOperator} 
            data={data} 
            title={"Operator"} 
            isFilters={operator} 
            setIsFilters={setOperator} 
            isLast={false} />        
          {/* <FilterBox 
            open={openOperator} 
            setOpen={setOpenOperator} 
            data={data} 
            title={"ATA Chapter"}  
            isFilters={isFilters} 
            setIsFilters={setIsFilters} 
            isLast={false} /> */}
          {/* <FilterBox open={openAircraftReg} setOpen={setOpenAircraftReg} data={data} title={"Aircraft Reg."} isFilters={isFilters} setIsFilters={setIsFilters} isLast={false} /> */}
        </Grid>
      </Grid>
    </>
  )
}

export default SearchBar;
