import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
// get dateStr in seconds
export const toDateStringSeconds = (dateStr, offset) => {
  const format = "MMM DD YYYY";

  let date = dayjs.unix(dateStr).format(format);

  if (offset) {
    date = dayjs.unix(dateStr).utc().utcOffset(offset).format(format);
  }

  return date;
};
