
import React from 'react';
import { Box, Grid, Typography } from "@mui/material";
import { useContext } from "react";
import { ACExtraDetails } from "./ACExtraDetails";
import ActiveCountWidget from "./ActiveCountWidget";
import { CountContext } from "./Main";
import Widgets from "./Widgets";
import { gothamBold } from "../../../../constants/fonts";
import { LifebouyIcon } from "../../icons/statusIcons";
import { useGetAllScannersStatus } from '../../../../models/custom/scanner-model'
// need to showDetail and hide (using opacity or height) to apply css animation using 'transition'

export default function DetailAllScanner() {
    const { showDetail, } = useContext(CountContext);
    const { data, isLoading, } = useGetAllScannersStatus();
    // useEffect(() => {
    //     //@ts-ignore
    //     // console.log({ flightData: flightData.rows })
    // }, [flightData])
    // const usingData: [] = data.map(({ node }: {
    //     node: ACDetail
    // }) => { return node })
    return (<Box>
            <Grid container>
                <Grid item xs={12} md={3}>
                    <ActiveCountWidget />
                </Grid>
                <Grid item xs={12} md={9} sx={{ paddingRight: '1em' }}>
                    <Box sx={{
            width: "100%",
            display: 'flex',
            overflow: showDetail ? 'auto' : 'hidden',
            flexDirection: 'row',
            opacity: showDetail ? '1' : "0",
            height: showDetail ? '100%' : "0px",
            // transition: 'height 0.5s ease-in-out, opacity 0.5s ease 0.5s',
            transition: 'opacity 0.5s ease-in-out',
        }}>
                        {data && !isLoading && data.map && data.map(({ totalActive, totalStatus, totalInactive, }) => {
            return (<Box className='DetailWrapper' sx={{ height: 'fit-content', paddingBottom: '1em' }}>
                                    {/* {on_datetime}<br /> */}
                                    {/* {arrDate}<br/>{arrTime}<br/> */}
                                    <DetailBox active={totalActive} total={totalStatus} inactive={totalInactive}/>
                                </Box>);
        })}
                    </Box>
                    <Box sx={{
            width: "100%",
            display: 'flex',
            overflow: !showDetail ? 'auto' : 'hidden',
            flexDirection: 'row',
            opacity: !showDetail ? '1' : "0",
            height: !showDetail ? '100%' : "0px",
            // height: !showDetail ? '100%' : "0px",
            // transition: 'height 0.5s ease-in-out, opacity 0.5s ease 0.5s',
            transition: 'opacity 0.5s ease-in-out',
            paddingLeft: '2em',
        }}>
                        {/* <pre>
            {JSON.stringify(data, null, 4)}
        </pre> */}
                        <Widgets />
                    </Box>
                </Grid>
            </Grid>
        </Box>);
}
function DetailBox({ active, total, inactive, }) {
    return (<Box sx={{
            minWidth: '250px',
            paddingRight: '1em',
            paddingLeft: '1em',
            border: '1px solid #E8E8E8',
            borderRadius: '12px',
            marginLeft: '0.5em',
            paddingTop: '1em',
            paddingBottom: '1em',
            height: '100%'
        }}>
            {/* <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '1em', marginBottom: '1em' }}>
            <Typography fontSize='12px' >{(from && dest) ? `${from} -> ${dest}` : 'N/A'}</Typography>
        </Box> */}
            <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            paddingLeft: '2em',
            alignItems: 'center',
            justifyContent: 'left',
        }}>
                <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingRight: '1em',
        }}>
                    <LifebouyIcon />
                </Box>
                <Box sx={{
            padding: '1em',
            paddingLeft: '0px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
        }}>
                    <Box>
                        <Typography fontFamily={gothamBold} fontWeight='bold' fontSize='20px' sx={{ textAlign: "left" }}>
                            {active}
                        </Typography>
                        <Typography color='#72767E' sx={{ textAlign: "left" }}>
                            Active
                        </Typography>
                    </Box>
                    <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: '2em',
            paddingRight: '1em',
        }}>
                        <Typography sx={{ textAlign: "left" }}>
                            {total}
                        </Typography>
                        <Typography color='#72767E' sx={{ textAlign: "left" }}>
                            Total
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <ACExtraDetails inactive={inactive}/>
        </Box>);
}
