
import { Box, Button, TextField, Typography } from "@mui/material";
import React from 'react'
import  { useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import {
	HourglassIcon,
	SmallAcModleIcon,
	SmallAirplaneIcon,
	SmallLifebouyIcon,
} from "./Icons";
import DropDownCustom from "../../components/inputs/dropdowns/DropDownCustom/Main";
import FilterChipsMain from "../../components/inputs/FilterChips/FilterChipsMain";
import { useGetSearchedInvItem } from "../../../models/custom/inventory-item-model";
import { useGetAllFleetDistincted } from "../../../models/custom/fleet-model";
import { useGetAllItemStatuses } from "../../../models/custom/ac-item-model";
import { useGetAllFleetByCompanyCodeSearchedByRegNo } from "../../../models/custom/fleet-model";

export default function SearchAdv({
	filterEqTypeIds,
	setFilterEqTypeIds,
	search,
	filterRegnos,
	filterAcModels,
	filterStatusIds,
	filterLocationExist,
	setSearch,
	setFilterRegnos,
	setFilterAcModels,
	setFilterStatusIds,
	setFilterLocationExist,
}) {
	const [searchEqType, setSearchEqType] = useState();
	const [searchRegNo, setSearchRegNo] = useState(undefined);
	const [filterChipLabelList, setFilterChipListLabel] = useState([]);

	const {
		data: eqTypes,
		isError: eqTypesError,
		isLoading: eqTypesLoading,
	} = useGetSearchedInvItem({ searchEqType });

	const {
		data: models,
		isError: modelsError,
		isLoading: modelsLoading,
	} = useGetAllFleetDistincted();

	const {
		data: statuses,
		isError: statusesError,
		isLoading: statusesLoading,
	} = useGetAllItemStatuses();

	const {
		data: acRegNoList,
		isError: acRegNoListError,
		isLoading: acRegNoListLoading,
	} = useGetAllFleetByCompanyCodeSearchedByRegNo({ searchRegNo });

	const acRegNos = [];
	acRegNoList.map(({ node }) => {
		if (node?.regNo) {
			acRegNos.push(node.regNo);
		}
	});
	const acModels = [];
	models.map(({ node }) => {
		if (node?.model) {
			acModels.push(node?.model);
		}
	});
	const searchInputRef = useRef(null);
	const searchChip = search
		? [
				{
					value: search,
					label: search,
					onRemove: () => {
						setSearch(undefined);
						//@ts-ignore
						searchInputRef.current.value = "";
					},
				},
		  ]
		: [];
	return (
		<Box>
			<Box
				sx={{
					width: "100%",
					display: "flex",
					justifyContent: "left",
					paddingBottom: "1em",
				}}
			>
				<TextField
					// label="Search"
					inputRef={searchInputRef}
					placeholder="Search"
					variant="standard"
					size="small"
					sx={{
						width: "400px",
						borderRight: "1px solid #ccc",
						"& input::placeholder": {
							fontSize: "12px",
						},
					}}
					onBlur={(e) => {
						setSearch(e.target.value);
					}}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							setSearch(e.target.value);
						}
					}}
					InputProps={{
						disableUnderline: true,
						startAdornment: (
							<SearchIcon
								sx={{
									cursor: "pointer",
									padding: "0.3em",
									"&:hover": {
										background: "whitesmoke",
										borderRadius: "1.5em",
									},
								}}
							/>
						),
						// endAdornment: <ClearIcon
						//     onClick={() => {
						//         setSearch(null)
						//     }}
						//     sx={{
						//         cursor: "pointer",
						//         padding: "0.3em",
						//         color: '#E0E0E0',
						//         '&:hover': {
						//             background: "whitesmoke",
						//             borderRadius: "1.5em",
						//         }
						//     }}
						// />
					}}
				/>
				{(search ||
					filterRegnos.length > 0 ||
					filterAcModels.length > 0 ||
					filterStatusIds.length > 0 ||
					filterEqTypeIds.length > 0) && (
					<Button
						style={{
							textTransform: "none",
						}}
						onClick={() => {
							setSearch(undefined);
							setFilterRegnos([]);
							setFilterAcModels([]);
							setFilterStatusIds([]);
							setFilterEqTypeIds([]);
							//@ts-ignore
							searchInputRef.current.value = "";
						}}
					>
						<Typography
							sx={{ whiteSpace: "nowrap", marginLeft: "10px" }}
						>
							Clear all
						</Typography>
					</Button>
				)}
				<Box
					sx={{
						flexGrow: 1,
						display: "flex",
						justifyContent: "right",
						alignItems: "center",
					}}
				>
					{/* <DropDownCustom
        optList={[]}
        ButtonTrigger={(props: any) => (
            <Button
                sx={{
                    border: "none",
                    textTransform: "none",
                    color: "black",
                    "&:hover": {
                        border: "none"
                    }
                }}
                variant="outlined"
                startIcon={(
                    <SmallLifebouyIcon />
                )}
                {...props}
            >
                Eq. Type
            </Button>
        )}
    /> */}
					{/* <Box sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: "right",
            alignItems: "center"
        }}>

            <Switch
                onChange={() => {
                    setFilterLocationExist(!filterLocationExist)
                }}
                defaultChecked={true}
                size="small"
                sx={{ marginRight: "0.3em" }}
            />
            <Typography style={{ fontSize: '15px', fontFamily: gothamNormal, marginRight: "1em" }}>Location Available</Typography>
        </Box> */}
					<DropDownCustom
						optList={
							eqTypes && !eqTypesLoading
								? eqTypes.map(({ node }) => {
										return {
											value: node?.id,
											label: node?.name,
										};
								  })
								: []
						}
						values={filterEqTypeIds}
						onChange={(values) => {
							setFilterEqTypeIds(values);
						}}
						onSearch={(search) => {
							setSearchEqType(search);
						}}
						buttonProps={{
							sx: {
								border: "none",
								textTransform: "none",
								color: "black",
								"&:hover": {
									border: "none",
								},
							},
							variant: "outlined",
							startIcon: <SmallLifebouyIcon color="#394458" />,
							textStr: "Eq. Type",
						}}
					/>
					<DropDownCustom
						optList={models && !modelsLoading ? acModels : []}
						values={filterAcModels}
						onChange={(values) => {
							setFilterAcModels(values);
						}}
						buttonProps={{
							sx: {
								border: "none",
								textTransform: "none",
								color: "black",
								"&:hover": {
									border: "none",
								},
							},
							variant: "outlined",
							startIcon: <SmallAcModleIcon color="#394458" />,
							textStr: "Aircraft Model",
						}}
					/>
					<DropDownCustom
						optList={
							acRegNoList && !acRegNoListLoading ? acRegNos : []
						}
						onChange={(values) => {
							setFilterRegnos(values);
						}}
						onSearch={(search) => {
							setSearchRegNo(search);
						}}
						values={filterRegnos}
						buttonProps={{
							textStr: "Aircraft Reg.",
							sx: {
								border: "none",
								textTransform: "none",
								color: "black",
								"&:hover": {
									border: "none",
								},
							},
							variant: "outlined",
							startIcon: <SmallAirplaneIcon color="#394458" />,
						}}
					/>
					<DropDownCustom
						optList={
							statuses && !statusesLoading
								? statuses.map(({ node }) => {
										const nameHolder = getStatusName(
											node?.code,
											node?.name
										);
										return {
											value: node?.id,
											label: nameHolder,
										};
								  })
								: []
						}
						values={filterStatusIds}
						onChange={(values) => {
							setFilterStatusIds(values);
						}}
						buttonProps={{
							sx: {
								border: "none",
								textTransform: "none",
								color: "black",
								"&:hover": {
									border: "none",
								},
							},
							variant: "outlined",
							startIcon: <HourglassIcon color="#394458" />,
							textStr: "Status",
						}}
					/>
				</Box>
			</Box>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "left",
					marginBottom: "1em",
				}}
			>
				<FilterChipsMain
					chipList={[
						...generateFilterChipObject(
							filterChipLabelList,
							setFilterChipListLabel,
							filterEqTypeIds,
							setFilterEqTypeIds,
							eqTypes.map(({ node }) => {
								return {
									node: {
										value: node?.id,
										label: node?.name,
									},
								};
							})
						),
						...filterAcModels?.map((value) => {
							return {
								label: value,
								value,
								onRemove: (value) => {
									const newFilter = [...filterAcModels];
									const foundIndex =
										filterAcModels?.findIndex(
											({ value: innerVal }) => {
												return innerVal === value;
											}
										);
									newFilter.splice(foundIndex, 1);
									setFilterAcModels(newFilter);
								},
							};
						}),
						...filterRegnos?.map((value) => {
							return {
								label: value,
								value,
								onRemove: (value) => {
									const newFilter = [...filterRegnos];
									const foundIndex = filterRegnos?.findIndex(
										({ value: innerVal }) => {
											return innerVal === value;
										}
									);
									newFilter.splice(foundIndex, 1);
									setFilterRegnos(newFilter);
								},
							};
						}),
						...filterStatusIds?.map((value) => {
							const labelNode = statuses?.find(({ node }) => {
								return value === node?.id;
							})?.node;
							const label =
								getStatusName(
									labelNode?.code,
									labelNode?.name
								) ?? "";
							return {
								label,
								value,
								onRemove: (value) => {
									const newFilter = [...filterStatusIds];
									const foundIndex =
										filterStatusIds?.findIndex(
											({ value: innerVal }) => {
												return innerVal === value;
											}
										);
									newFilter.splice(foundIndex, 1);
									setFilterStatusIds(newFilter);
								},
							};
						}),
						...searchChip,
					]}
				/>
			</Box>
		</Box>
	);
}
function getStatusName(code, name) {
	let nameHolder = name;
	switch (code) {
		case "L":
			nameHolder = "Missing";
			break;
		case "T":
			nameHolder = "Error";
			break;
		case "T":
			nameHolder = "Error";
			break;
		case "D":
			nameHolder = "Defects";
			break;
		default:
			break;
	}
	return nameHolder;
}
function generateFilterChipObject(
	labelList,
	setLabelList,
	values,
	setValues,
	listFromApi
) {
	const filterObjects = values?.map((value) => {
		const foundInStored = labelList.find(({ label, value: innerVal }) => {
			return value === innerVal;
		});
		let label = "";
		if (foundInStored) {
			label = foundInStored.label;
		} else {
			const labelNode = listFromApi?.find(({ node }) => {
				return value === node?.value;
			})?.node;
			const newFilter = [...labelList];
			label = labelNode?.label ?? "";
			newFilter.push({
				label: label ?? "",
				value,
			});
			setLabelList(newFilter);
		}
		return {
			label,
			value,
			onRemove: (value) => {
				const newFilter = [...values];
				const foundIndex = values?.findIndex(({ value: innerVal }) => {
					return innerVal === value;
				});
				newFilter.splice(foundIndex, 1);
				setValues(newFilter);
			},
		};
	});
	return filterObjects;
}
