import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { showNotification } from "common/helpers";
// import { AuthState } from 'common/interfaces';
import { getToken, logOut } from './thunks';

const authInitialState = {
  currentUser: {
    userName: '',
    token: '',
    avatar: '',
    displayName: ''
  },
  loading: false
}

const auth = createSlice({
  name: "auth",
  initialState: authInitialState,
  reducers: {
    setCurrentUser: (state, { payload }) => {
      state.currentUser = payload
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(getToken.pending, (state) => {
      state.loading = true;
    })
    .addCase(getToken.fulfilled, (state, {payload}) => {
      state.loading = false;
      state.currentUser = payload;
    })
    .addCase(getToken.rejected, (state, action) => {
      state.loading = false;
      if(action.error?.message){
        showNotification("danger", "Error", [action.error.message])
      } else {
        showNotification("danger", "Error", ["Login Has FAILED!"])
      }
    })
  }
}) 

export const { setCurrentUser } = auth.actions;

export default auth.reducer