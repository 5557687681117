
import React from 'react';
import { Grid, Typography } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
export default function ItemTemplate({ value }) {
	const itemTitleStyle = {
		textAlign: "left",
		fontSize: "12px",
		color: "#838383",
		paddingLeft: "1em",
	};
	const itemValueStyle = {
		textAlign: "left",
		fontSize: "14px",
		color: "#333333",
		paddingLeft: "1em",
		wordBreak: "break-word",
	};
	const iconStyle = {
		fontSize: "6px",
		marginRight: "1em",
		verticalAlign: "middle",
	};
	return (
		<Grid container>
			<Grid item>
				<Typography sx={{ ...itemValueStyle }}>
					<FiberManualRecordIcon sx={iconStyle} />
					{value}
				</Typography>
			</Grid>
		</Grid>
	);
}
