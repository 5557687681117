
import React from 'react';

export default function HeadLeftToilet() {
	return (
		<g>
			<rect
				x="194.5"
				y="385.5"
				width="29"
				height="46"
				rx="5.5"
				fill="white"
				stroke="#74C4E8"
			/>
			<path
				d="M204.388 408.375C204.388 408.589 204.214 408.763 204 408.763C203.786 408.763 203.613 408.589 203.613 408.375C203.613 408.161 203.786 407.988 204 407.988C204.214 407.988 204.388 408.161 204.388 408.375Z"
				fill="black"
				stroke="#394458"
				stroke-width="1.1"
			/>
			<path
				d="M204 414.625C205.726 414.625 207.125 411.827 207.125 408.375C207.125 404.923 205.726 402.125 204 402.125C202.274 402.125 200.875 404.923 200.875 408.375C200.875 411.827 202.274 414.625 204 414.625Z"
				stroke="#394458"
				stroke-width="1.1"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M207.125 408.375V415.25C207.125 415.416 207.191 415.575 207.308 415.692C207.425 415.809 207.584 415.875 207.75 415.875H216.5C216.666 415.875 216.825 415.809 216.942 415.692C217.059 415.575 217.125 415.416 217.125 415.25V408.375C217.125 404.922 215.727 402.125 214 402.125H204"
				stroke="#394458"
				stroke-width="1.1"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M207.125 408.375H209"
				stroke="#394458"
				stroke-width="1.1"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M211.5 408.375H212.75"
				stroke="#394458"
				stroke-width="1.1"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M215.25 408.375H217.125"
				stroke="#394458"
				stroke-width="1.1"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</g>
	);
}
