
import React from 'react';
import { Box, Typography } from "@mui/material";
import PageTemplate from "../../components/templates/page-template";
import InspectionListAdv from "./InspectionListAdv";
export default function Main() {
	return (
		<PageTemplate title="Inspection Reports">
			<Typography
				marginTop="-15px"
				fontSize="12px"
				fontStyle="italic"
				color="#838383"
				width="100%"
				textAlign="left"
				marginBottom="24px"
			>
				*The data shown is for the most recent 20 inspections.
				{/*' To view more data, please export it.' */}
			</Typography>
			<Box>
				<InspectionListAdv />
				{/* <InspectionList /> */}
				<Box
					sx={{
						width: "100%",
						height: "2em",
					}}
				/>
			</Box>
		</PageTemplate>
	);
}
