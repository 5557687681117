
import React from 'react';
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import PageTemplate from "../../components/templates/page-template";
import WidgetItemsStatus from "../../components/common-component/WidgetItemsStatus";
import InventoryListAll from "./InventoryListAll";
export default function EqManagementPageMain() {
	const [showAdd, setShowAdd] = useState(false);
	return (
		<PageTemplate
			title="Equipment Management"
			topRightActions={[
				{
					component: (
						<Button
							onClick={() => {
								setShowAdd(true);
							}}
							sx={{
								margin: "1em",
								marginRight: "0px",
								textTransform: "none",
							}}
							variant="contained"
						>
							+ Add new Equipment
						</Button>
					),
				},
			]}
		>
			<Box sx={{ position: "relative" }}>
				<Box
					sx={{
						width: "100%",
						display: "flex",
						justifyContent: "right",
					}}
				></Box>
				<Box
					sx={{
						marginBottom: "1em",
					}}
				>
					<WidgetItemsStatus />
				</Box>
				<Box sx={{ marginBottom: "1em" }} />
				<InventoryListAll showAdd={showAdd} setShowAdd={setShowAdd} />
				<Box sx={{ width: "100%", height: "2em" }} />
			</Box>
		</PageTemplate>
	);
}
