import React from 'react'
import  { Fragment } from "react";
import PagedGqlTable from "../../components/table/PagedGqlTable";
import { Typography } from "@mui/material";
// import { ItemsStatus } from "./EqStatusPageMain";
import { getLocaleDate, getLocaleTime } from "../../utils/dates";
import { escWord } from "../../utils/string";
import { useAppSelector } from "../../../app/hooks";
import { env_var_REACT_APP_API_URL } from "../../../envVar/vars";
import { getQueryAllFleetFiltered } from "../../../models/custom/fleet-model";
export default function ListOfFleets({
	setShowLopaFleet,
	search,
	setSearch,
	filterRegNos,
	setFilterRegNos,
	filterModels,
	setFilterModels,
	filterEnType,
	setFilterEnType,
}) {
	const apiUrl = env_var_REACT_APP_API_URL;

	const { companyProfile } = useAppSelector((state) => state.userEntity);
	const code = companyProfile?.code;

	return (
		<Fragment>
			<PagedGqlTable
				apiUrl={apiUrl ?? ""}
				querySign="allFleets"
				queryStringInjectedOffset={() => {
					// fleetType { id type description}
					// @TODO need to add icontain search here
					return getQueryAllFleetFiltered({
						code,
						search,
						filterModels,
						filterRegNos,
						filterEnType,
					});
				}}
				renameKeys={[
					{
						from: "regNo",
						to: `${escWord}A/C Reg`,
					},
					{
						from: "model",
						to: `${escWord}A/C Model`,
					},
					{
						from: "totalActive",
						to: "Active",
					},
					{
						from: "totalLost",
						to: "Missing",
					},
					{
						from: "totalTagError",
						to: "Tag Error",
					},
					{
						from: "totalExpired",
						to: "Expired",
					},
					{
						from: "totalRemind",
						to: "Near Expiry",
					},
					{
						from: "totalDefect",
						to: "Defects",
					},
					{
						from: "totalStatus",
						to: "Total",
					},
					{
						from: "latestPic",
						to: "PIC",
					},
					{
						from: "latestOperationDate",
						to: "Operation Date",
					},
					{
						from: "latestSync",
						to: `${escWord}Latest Operation Date/Time`,
					},
				]}
				reStructureDatas={(items) => {
					return items.map(({ node }) => {
						// const timezone = `UTC${getUtcOffset(new Date(node?.latestSync))}`
						return {
							...node,
							latestSync: node?.latestSync
								? `${getLocaleDate(
										node?.latestSync
								  )} ${getLocaleTime(node?.latestSync)} `
								: "N/A",
							totalActive: (
								<Typography
									style={{
										overflow: "hidden",
										color: "var(--new-semantic-error, #33333)",
										fontWeight: "bold",
										fontSize: "14px",
									}}
								>
									{node?.totalActive}
								</Typography>
							),
							totalLost: (
								<Typography
									style={{
										overflow: "hidden",
										color: "var(--new-semantic-error, #E95048)",
										fontWeight: "bold",
										fontSize: "14px",
									}}
								>
									{node?.totalLost}
								</Typography>
							),
							totalTagError: (
								<Typography
									style={{
										overflow: "hidden",
										color: "var(--new-semantic-error, #DBAA50)",
										fontWeight: "bold",
										fontSize: "14px",
									}}
								>
									{node?.totalTagError}
								</Typography>
							),
							totalExpired: (
								<Typography
									style={{
										overflow: "hidden",
										color: "var(--new-semantic-error, #9500FF)",
										fontWeight: "bold",
										fontSize: "14px",
									}}
								>
									{node?.totalExpired}
								</Typography>
							),
							totalRemind: (
								<Typography
									style={{
										overflow: "hidden",
										color: "var(--new-semantic-error,	#4F48E0)",
										fontWeight: "bold",
										fontSize: "14px",
									}}
								>
									{node?.totalRemind}
								</Typography>
							),
							// totalDefect: <Typography style={{ overflow: 'hidden', color: 'var(--new-semantic-error, #3A32E0)', fontWeight: 'bold', fontSize: '14px' }} >{node?.totalDefect}</Typography>,
							totalStatus: (
								<Typography
									style={{
										overflow: "hidden",
										color: "var(--new-semantic-error, #33333)",
										fontWeight: "bold",
										fontSize: "14px",
									}}
								>
									{node?.totalStatus}
								</Typography>
							),
						};
					});
				}}
				onRowClick={(item) => {
					setShowLopaFleet(item?.node);
				}}
				// actions={[
				// 	{
				// 		triggerComp: (item) => <IconButton
				// 			onClick={() => {
				// 				setShowLopaFleet(item?.node)
				// 				setStatuses({
				// 					totalActive: item?.node?.totalActive,
				// 					totalCount: item?.node?.totalCount,
				// 					totalDefect: item?.node?.totalDefect,
				// 					totalExpired: item?.node?.totalExpired,
				// 					totalLost: item?.node?.totalLost,
				// 					totalRemind: item?.node?.totalRemind,
				// 					totalTagError: item?.node?.totalTagError,
				// 				})
				// 			}}
				// 		>
				// 			<svg width="24" height="25" viewBox="0 0 24 25" fill="none" transform="scale(1.2 1.2)" xmlns="http://www.w3.org/2000/svg">
				// 				<path d="M8.5 18H17.5" stroke="#08D1AF" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
				// 				<path d="M5.67619 9.5H6.75119L8.00119 11H10.0012L9.21994 8.65625C9.19523 8.58115 9.18869 8.50125 9.20084 8.42313C9.213 8.34501 9.24351 8.27088 9.28986 8.20683C9.33622 8.14279 9.39711 8.09065 9.46752 8.05469C9.53794 8.01874 9.61588 7.99999 9.69494 8H10.7512L13.5012 11H17.0012C17.5316 11 18.0403 11.2107 18.4154 11.5858C18.7905 11.9609 19.0012 12.4696 19.0012 13V14.5H7.98869C7.55915 14.4993 7.14123 14.3604 6.79679 14.1038C6.45235 13.8471 6.19968 13.4864 6.07619 13.075L5.19494 10.1438C5.17247 10.0689 5.16786 9.98982 5.18148 9.91286C5.1951 9.8359 5.22657 9.76321 5.27336 9.70062C5.32016 9.63802 5.38097 9.58727 5.45093 9.55243C5.52089 9.51759 5.59804 9.49963 5.67619 9.5Z" stroke="#333333" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
				// 			</svg>
				// 		</IconButton>
				// 	}
				// ]}
				omitKeys={["id", "latestOperationDate", "totalDefect"]}
			/>
		</Fragment>
	);
}
