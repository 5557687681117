
import React from 'react';
import { useState } from "react";
import { Button, ClickAwayListener, Paper, Typography } from "@mui/material";
import { commonContainerPaddings, commonFontSizes } from "../../../../../constants/styles";
import { DateTimePickerCustom } from "./DateTimePickerCustom";
export default function MainDateRangeCustom({ startVal, onChangeStartVal, endVal, onChangeEndVal, popupOffSet, }) {
    const [errorMessage, setErrorMessage] = useState();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isOpenStart, setIsOpenStart] = useState(false);
    const [isOpenEnd, setIsOpenEnd] = useState(false);
    return (<div style={{
            position: 'relative',
        }}>
            <Button startIcon={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13 2.5H3C2.72386 2.5 2.5 2.72386 2.5 3V13C2.5 13.2761 2.72386 13.5 3 13.5H13C13.2761 13.5 13.5 13.2761 13.5 13V3C13.5 2.72386 13.2761 2.5 13 2.5Z" stroke="#394458" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M11 1.5V3.5" stroke="#394458" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M5 1.5V3.5" stroke="#394458" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M2.5 5.5H13.5" stroke="#394458" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>} onClick={() => {
            if (!isMenuOpen === false) {
                setIsOpenStart(false);
                setIsOpenEnd(false);
            }
            setIsMenuOpen(!isMenuOpen);
        }}>
                <Typography style={{
            fontSize: commonFontSizes.s,
            color: '#394458',
            textTransform: 'none',
        }}>
                    Date Range
                </Typography>
            </Button>

            <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={() => {
            setIsOpenStart(false);
            setIsOpenEnd(false);
            setIsMenuOpen(false);
        }}>
                <Paper style={{
            position: 'absolute',
            width: isMenuOpen ? '450px' : '0px',
            // width: '450px',
            // transition: 'width 0.1s ease-in-out',
            border: isMenuOpen ? '1ps solid whitesmoke' : undefined,
            padding: isMenuOpen ? commonContainerPaddings.m : '0px',
            overflow: isMenuOpen ? undefined : 'hidden',
            zIndex: 99,
            top: '34px',
            right: 0,
        }}>
                    {errorMessage && (<Typography fontSize={commonFontSizes.s} color={'red'} sx={{
                width: '100%',
                textAlign: 'start',
                // paddingLeft: commonContainerPaddings.m,
                paddingBottom: commonContainerPaddings.xs,
            }}>{errorMessage}</Typography>)}
                    <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        }}>
                        <DateTimePickerCustom value={startVal} error={errorMessage ? true : false} onError={setErrorMessage} onChange={(val) => {
            try {
                if (val) {
                    if (endVal) {
                        const valMils = new Date(val).getTime();
                        const endValMils = new Date(endVal).getTime();
                        if (valMils > endValMils) {
                            // onChangeStartVal(val)
                            // setValueState(endVal)
                            setErrorMessage('From date should not be later than To date.');
                        }
                        else {
                            onChangeStartVal(val);
                            setErrorMessage(undefined);
                        }
                    }
                    else {
                        onChangeStartVal(val);
                        setErrorMessage(undefined);
                    }
                }
            }
            catch (err) {
                if (err.message.includes('invalid time value')) {
                    // do nothing
                }
                else {
                    throw err;
                }
            }
        }} openOverride={isOpenStart} onOpen={(open) => {
            if (open) {
                setIsOpenStart(true);
                setIsOpenEnd(false);
            }
        }}/>
                        <Typography sx={{
            marginLeft: '0.5em',
            marginRight: '0.5em',
        }}>
                            to
                        </Typography>
                        <DateTimePickerCustom value={endVal} onError={setErrorMessage} error={errorMessage ? true : false} onChange={(val) => {
            try {
                if (val) {
                    if (startVal) {
                        const valMils = new Date(val).getTime();
                        const startValMils = new Date(startVal).getTime();
                        if (startValMils > valMils) {
                            // onChangeStartVal(val)
                            // setValueState(endVal)
                            setErrorMessage('From date should not be later than To date.');
                        }
                        else {
                            onChangeEndVal(val);
                            setErrorMessage(undefined);
                        }
                    }
                    else {
                        onChangeEndVal(val);
                        setErrorMessage(undefined);
                    }
                }
            }
            catch (err) {
                if (err.message.includes('invalid time value')) {
                    // do nothing
                }
                else {
                    throw err;
                }
            }
        }} openOverride={isOpenEnd} onOpen={(open) => {
            if (open) {
                setIsOpenEnd(true);
                setIsOpenStart(false);
            }
        }}/>
                    </div>
                    <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'right',
            marginTop: commonContainerPaddings.m,
        }}>
                        <Button onClick={() => {
            setIsMenuOpen(false);
        }}>Cancel</Button>
                        <Button onClick={() => {
            setIsMenuOpen(false);
        }}>OK</Button>
                    </div>
                </Paper>
            </ClickAwayListener>

        </div>);
}
