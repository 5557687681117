
import React from 'react';

export default function TailLine() {
	return (
		<g>
			<TailDots />
			<line
				x1="1292.5"
				y1="264.5"
				x2="1292.5"
				y2="426.5"
				stroke="#CAD2DB"
				stroke-linecap="round"
				stroke-dasharray="2 2"
			/>
		</g>
	);
}
function TailDots() {
	return (
		<g>
			<circle cx="1192" cy="420" r="3" fill="#394458" />
			<circle cx="1278" cy="345" r="3" fill="#394458" />
			<circle cx="1220" cy="403" r="3" fill="#394458" />
		</g>
	);
}
