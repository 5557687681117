import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Box, Avatar } from "@mui/material";
import { shrinkFleetMenu } from "./SidebarIconsList";
import styles from "./Sidebar.module.css";

const FleetSidebarIcon = () => {
  const { companyProfile } = useSelector((state) => state.userEntity);
  const location = useLocation();


  function iconStatus(item) {
    if (location?.pathname === item?.url) {
      return item?.activeIcon;
    } else {
      return item?.icon;
    }
  }

  return (
    <>
      <Box
        sx={{
          textAlign: "center",
          marginBottom: "48px",
        }}
      >
        {companyProfile ? (
          <Avatar
            className="avatar-dashboard"
            style={{ marginTop: "6px", marginLeft: "-3px", border: "1px solid #E8E8E8" }}
            src={companyProfile.logo}
            size={36}
            // sx={{ width: 36, height: 36 }}
          ></Avatar>
        ) : (
          <Avatar className="avatar-dashboard" size={36}></Avatar>
        )}
      </Box>
      <ul style={{ padding: 0, textAlign: "center" }}>
        {shrinkFleetMenu.map((item, index) => {
          return item?.visible ? (
            <li style={{ marginBottom: "20px" }}>
              <img
                src={iconStatus(item)}
                alt={item?.label}
                className={`
                    ${
                      item?.disable
                        ? styles.sidebarIconDisable
                        : location.pathname === item?.url
                        ? styles.sidebarIconActive
                        : styles.sidebarIcon
                    }
                  `}
              />
            </li>
          ) : item?.label === "seperator" ? (
            <hr style={{ marginTop: "2rem", marginBottom: "2rem" }}></hr>
          ) : (
            <></>
          );
        })}
      </ul>
    </>
  );
};

export default FleetSidebarIcon;
