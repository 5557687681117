
import React from 'react';
import { Grid, Typography } from "@mui/material";
import { Fragment } from "react";
export default function GridItem({
	title,
	inputComp,
	isRequired = true,
	fullWidth = false,
}) {
	return (
		<Grid
			item
			xs={fullWidth ? 12 : 6}
			sx={{ paddingTop: "10px !important" }}
		>
			<Typography
				sx={{
					fontSize: "14px",
					marginBottom: "0.3em",
				}}
			>
				{title}
				{isRequired ? <span style={{ color: "red" }}>*</span> : ""}
			</Typography>
			{/* {inputComp}*/}
			<Input inputComp={inputComp} />
		</Grid>
	);
}
function Input({ inputComp }) {
	return <Fragment>{inputComp}</Fragment>;
}
