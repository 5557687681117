import React, { useState, useEffect, useRef } from "react";
import { Grid, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import _, { isEmpty } from "lodash";
import format from "date-fns/format";
import { ConvertUTCDatetime, changeFormatDate, formatDate } from "./Helpers";
import Live from "../aircraftMonitoring/Live";
import { calculatePercentage } from "./Helpers";

import PastFlightLine from "common/images/icons/elevade/PastFlightLine.svg";
import FutureFlightLine from "common/images/icons/elevade/FutureFlightLine.svg";
import GearIcon from "common/images/icons/elevade/GearIcon.svg";
import Calendar from "common/images/icons/elevade/CalendarBlank.svg";
import IconArrowLeft from "common/images/icons/elevade/Icon-ArrowLeft.svg";
import IconArrowRight from "common/images/icons/elevade/Icon-ArrowRight.svg";

import styles from "../aircraftMonitoring/AircraftStatus.module.css";

const FlightMaintenanceSchedule = ({
  aircraftScheduleTask,
  setShowSchedule,
  showSchedule,
  setThirtySixHourCheck,
  thirtySixHourCheck,
  setWeeklyCheck,
  weeklyCheck,
  localTime,
  data,
}) => {
  const [date, setDate] = useState();
  const [today, setToday] = useState();
  const [hourCheckLeg, setHourCheckLeg] = useState();
  const [previousArrival, setPreviousArrival] = useState();
  const elRef = useRef();

  let flightSchedule = _.groupBy(aircraftScheduleTask, "date.value");
  flightSchedule = _.mapValues(flightSchedule, (v) => _.sortBy(v, "leg"));
  let todayDate = new Date().toISOString().slice(0, 10);

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 8,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#6AB47B" : "#D1D1D1",
    },
  }));

  const myRef = useRef(null);
  const myRefTo = useRef();
  const scrollToElement = () => {
    // eslint-disable-next-line no-unused-expressions
    myRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      alignToTop: true,
      // inline: "start" // an issue
    });
  };

  useEffect(() => {
    // setTimeout(() => {
    //   scrollToElement()
    // }, 3000);
    setInitialDate();
  }, []);

  useEffect(() => {
    if (showSchedule) {
      setTimeout(() => {
        scrollToElement();
      }, 2000);
    }
  }, [showSchedule]);

  useEffect(() => {
    const el = elRef.current;
    if (el) {
      const onWheel = (e) => {
        if (e.deltaY === 0) return;
        e.preventDefault();
        el.scrollBy(e.deltaY, 0);
      };
      el.addEventListener("wheel", onWheel);
      return () => el.removeEventListener("wheel", onWheel);
    }
  }, []);

  function setInitialDate() {
    var currentDate = new Date();
    var options = { month: "long", day: "2-digit", year: "numeric" };
    var formattedDate = currentDate.toLocaleDateString("en-US", options);
    setDate(formattedDate);
    setToday(formattedDate);
    // Set yesterday's date
    currentDate.setDate(currentDate.getDate() - 1);
    // setYesterday(currentDate.toLocaleDateString("en-US", options));
  }

  function formattedDate(type, date) {
    const currentDate = date ? new Date(date) : new Date();
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const monthsOfYear = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const dayOfWeek = daysOfWeek[currentDate.getDay()];
    const month = monthsOfYear[currentDate.getMonth()];
    const day = currentDate.getDate();
    const year = currentDate.getFullYear();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();

    return type
      ? `${dayOfWeek}, ${month} ${day}, ${year} ${hours}:${minutes}`
      : `${dayOfWeek}, ${month} ${day}, ${year}`;
  }

  const DateToggle = () => {
    return (
      <>
        <div className={styles.divDateToggle}>
          <Grid container columns={3}>
            <Grid item xs={0.5} className={styles.gridJustifyStart}>
              <img
                src={IconArrowLeft}
                alt="icon-left"
                className={styles.dateArrow}
                // onClick={() => SetTheDate("previous", date)}
              />
            </Grid>
            <Grid
              item
              xs={2}
              className={`${styles.gridJustifyCenter} ${styles.today} ${date === today ? styles.disabled : ""}`}
              onClick={() => setInitialDate()}
              style={{ display: "flex", justifyContent: "center" }}
            >
              {/* {date === today ? "Today" : date === yesterday ? "Yesterday" : date} */}
              Today
            </Grid>
            <Grid item xs={0.5} className={styles.gridJustifyEnd}>
              <img
                // src={date === today ? IconArrowRightDisabled : IconArrowRight}
                src={IconArrowRight}
                alt="icon-right"
                className={`${styles.dateArrow} ${date === today ? styles.noAction : ""}`}
                // onClick={() => SetTheDate("next", date)}
              />
            </Grid>
          </Grid>
        </div>
      </>
    );
  };

  const maintenanceTemplate = (flight, type) => {
    return (
      <div
        className="maintenance"
        style={{
          height: "107px",
          width: "186px",
          border: flight?.date?.value === todayDate && flight?.leg === 0 ? "1px solid #07C2A2" : "1px solid #E7E7E7",
          borderRadius: "12px",
          marginRight: "12px",
          minWidth: "186px",
          minHeight: "107px",
          // padding: "16px",
          lineHeight: "normal",
          // backgroundColor: todayDate <= flight?.date?.value ? "#FFF" : "#F7F7F7",
          display: "flex",
          alignItems: "center",
          background: "repeating-linear-gradient( 135deg, #eceff7, #eceff7 25px, #f4f7f7 10px, #f4f7f7 35px )",
        }}
      >
        <Grid container spacing={2} className="mx-auto">
          <Grid item xs={3} sx={{ padding: "0" }}>
            <div
              style={{ borderRadius: "50%", backgroundColor: "#ffffff", width: "38px", height: "38px", padding: "7px" }}
            >
              <img src={GearIcon} alt="flight-line" style={{ width: "24px", height: "24px" }} />
            </div>
          </Grid>
          <Grid item xs={9} sx={{ padding: "0" }}>
            <div className="small-font gotham-medium" style={{ color: "#088FD1" }}>
              {type === "hour" ? <span>36 Hour Check</span> : <span>Weekly Check</span>}
            </div>
            <div className="xsmall-font">
              {type === "hour" ? (
                <>{formatDate(thirtySixHourCheck?.next_exp_time_utc?.value, "time", localTime, "", "maintenance")}</>
              ) : (
                <>{formatDate(weeklyCheck?.next_exp_time_utc?.value, "time", localTime, "", "maintenance")}</>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };

  function scheduleCard(flight) {
    return (
      <div
        style={{
          height: "107px",
          width: "186px",
          border:
            // flight?.date?.value === todayDate && flight?.leg === 0
            flight?.flight_no === data?.flight_no &&
            flight?.dep === data?.departure_airport &&
            todayDate === flight?.date?.value
              ? "1px solid #07C2A2"
              : "1px solid #E7E7E7",
          borderRadius: "12px",
          marginRight: "12px",
          minWidth: "186px",
          minHeight: "107px",
          padding: "16px",
          lineHeight: "normal",
          backgroundColor: todayDate <= flight?.date?.value ? "#FFF" : "#F7F7F7",
        }}
      >
        <div className="d-flex" style={{ width: "100%", marginBottom: "12px" }}>
          {flight?.flight_no === data?.flight_no &&
          flight?.dep === data?.departure_airport &&
          todayDate === flight?.date?.value ? (
            <Live
              style={{
                height: "18px",
                margin: "-3px 8px 0px -4px",
              }}
            />
          ) : (
            <></>
          )}
          <div className="mr-auto small-font gotham-medium">{flight?.flight_no}</div>
          <div className="xsmall-font" style={{ color: "#838383" }}>
            {flight?.outMonthDay}
          </div>
        </div>
        <div className="d-flex" style={{ width: "100%", marginBottom: "14px" }}>
          <div className="mr-auto small-font gotham-medium">{flight?.dep}</div>

          {flight?.flight_no === data?.flight_no &&
          flight?.dep === data?.departure_airport &&
          todayDate === flight?.date?.value ? (
            <div className="mx-auto progressBar" style={{ width: "50%", marginTop: "4px" }}>
              <BorderLinearProgress variant="determinate" value={calculatePercentage(data)} />
            </div>
          ) : todayDate === flight?.date?.value && flight?.leg < 0 ? (
            <div className="mx-auto">
              <img src={PastFlightLine} alt="flight-line" style={{ marginTop: "-5px" }} />
            </div>
          ) : todayDate <= flight?.date?.value && flight?.leg !== 0 ? (
            <div className="mx-auto">
              <img src={FutureFlightLine} alt="flight-line" style={{ marginTop: "-5px" }} />
            </div>
          ) : todayDate === flight?.date?.value && flight?.leg < 0 ? (
            <div className="mx-auto">
              <img src={PastFlightLine} alt="flight-line" style={{ marginTop: "-5px" }} />
            </div>
          ) : (
            <div className="mx-auto">
              <img src={PastFlightLine} alt="flight-line" style={{ marginTop: "-5px" }} />
            </div>
          )}

          <div className="small-font gotham-medium ml-auto">{flight?.arr}</div>
        </div>
        <div className="d-flex xsmall-font gotham-medium" style={{ width: "100%", color: "#838383" }}>
          <div className="mr-auto">
            {ConvertUTCDatetime(changeFormatDate(flight?.std?.value), localTime).split(" ")[1]}
          </div>
          <div>{ConvertUTCDatetime(changeFormatDate(flight?.sta?.value), localTime).split(" ")[1]}</div>
        </div>
      </div>
    );
  }

  function checkHour(flight, prevData, nextData, type) {
    if (thirtySixHourCheck?.next_exp_time_utc?.value?.split("T")[0] === flight?.date?.value) {
      if (
        thirtySixHourCheck?.next_exp_time_utc?.value > flight?.std?.value &&
        thirtySixHourCheck?.next_exp_time_utc?.value < flight?.sta?.value &&
        type === "before"
      ) {
        return <>{maintenanceTemplate(flight, "hour")}</>;
      } else if (
        thirtySixHourCheck?.next_exp_time_utc?.value <= flight?.std?.value &&
        isEmpty(prevData) &&
        type === "before"
      ) {
        return <>{maintenanceTemplate(flight, "hour")}</>;
      } else if (
        thirtySixHourCheck?.next_exp_time_utc?.value >= prevData?.sta?.value &&
        thirtySixHourCheck?.next_exp_time_utc?.value <= prevData?.std?.value &&
        type === "before"
      ) {
        return <>{maintenanceTemplate(flight, "hour")}</>;
      } else if (
        thirtySixHourCheck?.next_exp_time_utc?.value <= flight?.std?.value &&
        thirtySixHourCheck?.next_exp_time_utc?.value >= prevData?.sta?.value &&
        type === "before"
      ) {
        return <>{maintenanceTemplate(flight, "hour")}</>;
      } else if (
        thirtySixHourCheck?.next_exp_time_utc?.value >= flight?.sta?.value &&
        isEmpty(nextData) &&
        type === "after"
      ) {
        return <>{maintenanceTemplate(flight, "weekly")}</>;
      } else {
        return <></>;
      }
    } else {
      return <div></div>;
    }
  }

  function checkWeekly(flight, prevData, nextData, type) {
    if (weeklyCheck?.next_exp_time_utc?.value?.split("T")[0] === flight?.date?.value) {
      if (
        weeklyCheck?.next_exp_time_utc?.value > flight?.std?.value &&
        weeklyCheck?.next_exp_time_utc?.value < flight?.sta?.value &&
        type === "before"
      ) {
        return <>{maintenanceTemplate(flight, "weekly")}</>;
      } else if (
        weeklyCheck?.next_exp_time_utc?.value >= prevData?.sta?.value &&
        weeklyCheck?.next_exp_time_utc?.value <= prevData?.std?.value &&
        type === "before"
      ) {
        return <>{maintenanceTemplate(flight, "weekly")}</>;
      } else if (
        weeklyCheck?.next_exp_time_utc?.value <= flight?.std?.value &&
        weeklyCheck?.next_exp_time_utc?.value >= prevData?.sta?.value &&
        type === "before"
      ) {
        return <>{maintenanceTemplate(flight, "weekly")}</>;
      } else if (weeklyCheck?.next_exp_time_utc?.value >= flight?.sta?.value && isEmpty(nextData) && type === "after") {
        return <>{maintenanceTemplate(flight, "weekly")}</>;
      } else {
        return <></>;
      }
    } else {
      return <div></div>;
    }
  }

  const onWheel = (e) => {
    let elem = document.querySelector("#containera");

    elem.addEventListener("wheel", (event) => {
      // event.preventDefault();

      // elem.scrollBy({
      //   left: event.deltaY < 0 ? -100 : 100,
      //   behavior: "smooth"
      // });

      event.preventDefault();

      let [x, y] = [event.deltaX, event.deltaY];
      let magnitude;

      if (x === 0) {
        magnitude = y > 0 ? -200 : 200;
      } else {
        magnitude = x;
      }

      elem.scrollBy({
        left: magnitude,
        behavior: "smooth",
      });
    });
  };

  return (
    <>
      {showSchedule ? (
        <>
          <div className={`row ${styles.displayScheduleHeader} ${styles.mainBodyData}`} style={{ marginLeft: "16px" }}>
            <Grid container spacing={1}>
              <Grid item xs={6} sx={{ fontFamily: "Gotham-Medium" }}>
                <div className="small-font gotham-medium">Flight & Maintenance Schedule</div>
              </Grid>
              <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
                {/* <div style={{ cursor: "pointer" }} onClick={() => setShowSchedule(!showSchedule)}> */}
                {/* {formattedDate("", date)}
                  <DateToggle /> */}
                <div onClick={() => setShowSchedule(!showSchedule)} style={{ cursor: "pointer" }}>
                  <img
                    src={Calendar}
                    height="20px"
                    alt="calendar"
                    style={{ marginRight: "14px", marginLeft: "16px" }}
                  />
                  <span className="small-font gotham-medium">Hide Schedule</span>
                </div>
                {/* </div> */}
              </Grid>
            </Grid>
          </div>

          <Grid className={`${styles.flightScheduleSection}`} style={{ marginLeft: "16px" }} ref={elRef}>
            {!isEmpty(flightSchedule) ? (
              Object.values(flightSchedule)
                .reverse()
                .map((flights, index) => {
                  return (
                    <>
                      {flights.map((flight, index, arr) => {
                        let prevData = arr[index - 1];
                        let nextData = arr[index + 1];
                        return (
                          <div>
                            {/* Display Month Day Grouping */}
                            {index === 0 ? (
                              <div
                                ref={flight?.date?.value === todayDate ? myRef : myRefTo}
                                id={flight?.date?.value === todayDate ? "today" : "other"}
                                className="small-font gotham-medium"
                                style={{
                                  height: "22px",
                                  marginBottom: "16px",
                                  color: flight?.date?.value === todayDate ? "#08D1AF" : "#333",
                                }}
                              >
                                {flight.outMonthDay}
                              </div>
                            ) : (
                              <div style={{ height: "22px", marginBottom: "16px" }}></div>
                            )}
                            <div classname="schedule-cards" style={{ display: "flex" }}>
                              {checkHour(flight, prevData, nextData, "before")}
                              {checkWeekly(flight, prevData, nextData, "before")}
                              {scheduleCard(flight)}
                              {checkHour(flight, prevData, nextData, "after")}
                              {checkWeekly(flight, prevData, nextData, "after")}
                            </div>
                          </div>
                        );
                      })}
                    </>
                  );
                })
            ) : (
              <></>
            )}
          </Grid>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default FlightMaintenanceSchedule;
