import React from 'react'
import  { Fragment } from "react";
export default function TabPanel(props) {
	const { children, value, index, ...other } = props;
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			style={{
				height: "97%",
				position: "relative",
			}}
			{...other}
		>
			{value === index && <Fragment>{children}</Fragment>}
		</div>
	);
}
