import styles from "../EmailAlertManagement.module.css";
import CloseIcon from "common/images/icons/elevade/CloseIconSmallBold.svg";
import Help from "common/images/icons/elevade/Help-icon.png";
import {
  Autocomplete,
  Button,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  styled,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Switch from "react-switch";
import React, { useEffect, useState } from "react";
import RichTextInput from "../../component/RichTextInput";
import { useForm, Controller } from "react-hook-form";
import { isEmpty } from "lodash";
import { makeAPIRequestRun, showNotificationElevade } from "common/helpers";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useRef } from "react";
import { format } from "date-fns";
import { dateFnsFormats } from "common/constant";

/*import ReactDOM from "react-dom";
import { Editor, EditorState } from "draft-js";
import "draft-js/dist/Draft.css";*/

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "14px",
          color: "#333333 ",
          backgroundColor: "#FFFEE8",
          boxShadow: "0px 3px 6px #00000029",
          borderRadius: "4px",
          opacity: 1,
          fontFamily: '"Gotham-Book", sans-serif',
          maxHeight: 500,
          overflow: "scroll",
          minWidth: 700,
        },
      },
    },
  },
});

const ButtonStyled = styled(Button)(({ theme }) => ({
  textTransform: "none",
  fontFamily: "Gotham-Medium",
  fontSize: "14px",
  fontWeight: "350",
  lineHeight: "20px",
  letterSpacing: "0em",
  textAlign: "center",
  padding: "11px 24px 11px 24px",
  borderRadius: "8px",
  gap: "8px",
  backgroundColor: "#088FD1",
}));

const formcontrolstyles = {
  display: "block",
  position: "static",
};

const NewAlert = ({
  setOpenDrawer,
  data,
  openDrawer,
  userList,
  userAOC,
  action,
  email,
  existingData,
  setCallApi,
  callApi,
  setIsSelectOpen,
  ataEventList,
  operator,
}) => {
  const observationRef = useRef(null);
  const analysisRef = useRef(null);
  const contactpointRef = useRef(null);
  const rootcauseRef = useRef(null);
  const recommendationsRef = useRef(null);
  const [ataList, setAtaList] = useState([]);
  const [acFamilyList, setAcFamilyList] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [loading, setLoading] = useState(false);

  /* ---------- useform -------------- */

  const defaultValues = {
    ataChapter: data?.ATA ?? [],
    analysis: data?.analysis ?? "",
    sendEmail: data?.sendEmail ?? true,
    observation: data?.observation ?? "",
    recommendations: data?.recommendations ?? "",
    rootcause: data?.rootcause ?? "",
    event: data?.text ?? [],
    contactpoint: data?.contactpoint ?? "",
    recipients: data?.recipients?.split(",") ?? [],
    operator: operator,
    acFamily: data?.ac_family ?? [],
  };

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
    watch,
  } = useForm({
    reValidateMode: "onBlur",
    mode: "all",
    defaultValues: defaultValues,
  });
  const watchAta = watch("ataChapter");
  const watchEvent = watch("event");
  const watchAcFamily = watch("acFamily");

  const onSubmit = async (evt) => {
    setLoading(true);
    const submit = async () => {
      const body = {
        email: email,
        id: action === "update" ? data.id : "null",
        ...evt,
      };

      const isExist = existingData.find(
        (item) => item.text === evt.event && item.ATA === evt.ataChapter && item.ac_family === evt.acFamily
      );

      if (action === "add" && isEmpty(errors) && !isExist) {
        try {
          const add = makeAPIRequestRun("post", `ecamalerts/emailalerts/add`, body);
          add.then((response) => {
            if (response.message === "Success") {
              showNotificationElevade("success", "Success!", ["New alert added!"]);
              onClickClose();
              response && setCallApi(!callApi);
            } else if (response.message === "Duplicate Alert") {
              showNotificationElevade("danger", "Failed!", [response.message]);
              onClickClose();
            } else {
              showNotificationElevade("danger", "Failed!", ["Something went wrong, unable to add alert!"]);
              onClickClose();
            }
            setLoading(false);
          });
        } catch (err) {
          setLoading(false);
          showNotificationElevade("danger", "Failed!", [err]);
        }
      } else if (isExist && action === "add")
        showNotificationElevade("danger", "Duplicate alert!", [
          "Alert with the same event & ata chapter already exists",
        ]);

      if (action === "update" && isEmpty(errors)) {
        try {
          const update = makeAPIRequestRun("post", `ecamalerts/emailalerts/update`, body);
          update.then((response) => {
            onClickClose();
            response
              ? showNotificationElevade("success", "Success!", ["Alert updated!"])
              : showNotificationElevade("danger", "Failed!", ["Something went wrong, unable to update alert!"]);
            onClickClose();
            response && setCallApi(!callApi);
          });
        } catch (err) {
          setLoading(false);
          showNotificationElevade("danger", "Failed!", [err]);
        }
        setLoading(false);
      }
    };
    await submit();
  };

  /* ---------- use effects ---------- */

  useEffect(() => {
    reset(defaultValues);
    // resetRichTextInput();
    //eslint-disable-next-line
  }, [openDrawer, data]);

  useEffect(() => {
    if (!isEmpty(watchAta)) {
      getDropdownOptions(watchAta, "ata_head");
    } else defaultAtaEvent();

    //eslint-disable-next-line
  }, [watchAta]);

  useEffect(() => {
    if (!isEmpty(watchEvent)) {
      getDropdownOptions(watchEvent, "event");
    } else defaultAtaEvent();
    //eslint-disable-next-line
  }, [watchEvent]);

  useEffect(() => {
    if (!isEmpty(watchAcFamily)) {
      getDropdownOptions(watchAcFamily, "ac_family");
    } else defaultAtaEvent();
    //eslint-disable-next-line
  }, [watchAcFamily]);

  useEffect(() => {
    if (ataEventList) {
      defaultAtaEvent();
    } //eslint-disable-next-line
  }, [ataEventList]);

  /* ---------- functions ---------- */

  const getDropdownOptions = (prop, field) => {
    const res = ataEventList
      .filter((item) => item[field] === prop)
      .map((item) => ({
        ata_head: item.ata_head,
        event: item.event,
        ac_family: item.ac_family,
      }));
    field !== "ata_head" && setAtaList([...new Set(res.map((item) => item.ata_head))]);
    field !== "event" && setEventList([...new Set(res.map((item) => item.event))]);
    field !== "ac_family" && setAcFamilyList([...new Set(res.map((item) => item.ac_family))]);
  };

  const defaultAtaEvent = () => {
    setAtaList([...new Set(ataEventList.map((item) => item.ata_head))]);
    setEventList([...new Set(ataEventList.map((item) => item.event))]);
    setAcFamilyList([...new Set(ataEventList.map((item) => item.ac_family))]);
  };

  const onClickClose = () => {
    reset(defaultValues);
    setOpenDrawer(false);
  };
  const notificationGuide = `
        Guide to creating a notification

        Placeholders:
        1. <strong>{ECAM}</strong>: Substitute with the ECAM message received.
        2. <strong>{FLIGHTNO}</strong>: Substitute with the flight number.
        3. <strong>{AC_REG}</strong>: Substitute with the aircraft registration.
        4. <strong>{ATA}</strong>: Substitute with the ECAM ATA Chapter.
        5. <strong>{PHASE}</strong>: Substitute with the flight phase during which the ECAM occurred.
        6. <strong>{OCCURRENCE_DATETIME}</strong>: Substitute with the occurrence time of the ECAM.
        7. <strong>{DETECTED_DATETIME}</strong>: Substitute with the time the ECAM was sent.
        8. <strong>{OPERATOR}</strong>: Substitute with the name of the operator.
        9. <strong>{PRIORITY}</strong>: Substitute with the ECAM priority level.
        10. <strong>{CONDITION}</strong>: Substitute with the ECAM dispatch condition.
        11. <strong>{MESSAGES}</strong>: Substitute with concurrent ECAM/fault messages.

        Example input:

        Notification regarding the received message <strong>{ECAM}</strong> on flight number <strong>{FLIGHTNO}</strong>. The occurrence took place during the <strong>{PHASE}</strong>. Reported on <strong>{OCCURRENCE_DATETIME}</strong>, and sent at <strong>{DETECTED_DATETIME}</strong>. The aircraft, registered as <strong>{AC_REG}</strong> and operated by <strong>{OPERATOR}</strong>, experienced a <strong>{PRIORITY}</strong> issue requiring <strong>{CONDITION}</strong>. Concurrent messages include: <strong>{MESSAGES}</strong>.
        `;

  return (
    <>
      <div className={styles.newAlertMain}>
        <div className={styles.newAlertHeader}>
          <div className={`${styles.row} ${styles.newAlertHeaderContent}`}>
            <span className={`${styles.textExtraSmall} ${styles.textSmallBold}`}>Email Alert Details</span>
            <div className={styles.alignEnd}>
              <ThemeProvider theme={theme}>
                <Tooltip
                  title={
                    <Typography
                      variant="body2"
                      sx={{ whiteSpace: "pre-line" }}
                      dangerouslySetInnerHTML={{ __html: notificationGuide }}
                    >
                      {/* {notificationGuide} */}
                    </Typography>
                  }
                  placement="bottom"
                >
                  <img
                    // src={PopoutIcon}
                    src={Help}
                    // alt="popout"
                    alt="help"
                    className={styles.newAlertHeaderImg}
                    style={{ marginRight: "30px", maxHeight: "20px" }}
                  />
                </Tooltip>
              </ThemeProvider>
              <Divider orientation="vertical" sx={{ mr: 3 }} />
              <img src={CloseIcon} alt="close" className={styles.newAlertHeaderImg} onClick={() => onClickClose()} />
            </div>
          </div>
          <Divider orientation="horizontal" />
        </div>
        <FormControl sx={formcontrolstyles} component="form" onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.newAlertContent}>
            <div className={styles.newAlertFormDiv}>
              <span className={styles.textExtraSmall}>
                A/C Family<span className={`${styles.asterisk}`}>*</span>
              </span>
              <Grid container columns={2} sx={{ mt: 0.5 }}>
                <Grid item xs={0.9}>
                  <Controller
                    control={control}
                    name="acFamily"
                    rules={{ required: "This field is required" }}
                    render={({ field: { ref, onChange, ...field } }) => (
                      <Autocomplete
                        // onInputChange={//api}
                        placeholder="Select A/C Family"
                        fullWidth
                        onChange={(_, data) => onChange(data)}
                        options={acFamilyList}
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: errors.ataChapter ? "red" : "#E8E8E8",
                          },
                          "&& legend": {
                            width: "0ch",
                          },
                        }}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField {...params} size={"medium"} fullWidth placeholder="Select A/C Family" />
                        )}
                        value={getValues().acFamily}
                      />
                    )}
                  />
                  {errors.acFamily && <p style={{ color: "red" }}>{errors.acFamily.message}</p>}
                </Grid>
                <Grid
                  item
                  xs={1.1}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                  }}
                >
                  <div className={`${styles.row} ${styles.centerItem}`} style={{ marginLeft: "30px" }}>
                    <span className={styles.textSmall}>Send Email Alert</span>
                    <div style={{ marginLeft: "20px" }} className={`${styles.centerItem}`}>
                      <Controller
                        control={control}
                        name="sendEmail"
                        render={({ field: { ref, onChange, value, ...field } }) => (
                          <Switch
                            // {...field}
                            defaultChecked={true}
                            checked={value}
                            onChange={onChange}
                            height={22}
                            width={40}
                            onColor="#088FD1"
                            offColor="#D9D9D9"
                            handleDiameter={13}
                            uncheckedIcon={
                              <div
                                style={{
                                  color: "#ffffff",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "100%",
                                  marginRight: "8px",
                                  fontFamily: "Gotham-Book",
                                  fontSize: "12px",
                                }}
                              />
                            }
                            checkedIcon={
                              <div
                                style={{
                                  color: "#ffffff",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "100%",
                                  marginLeft: "15px",
                                  fontFamily: "Gotham-Book",
                                  fontSize: "12px",
                                }}
                              />
                            }
                          />
                        )}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className={styles.newAlertFormDiv}>
              <span className={styles.textExtraSmall}>
                ATA Chapter<span className={`${styles.asterisk}`}>*</span>
              </span>
              <Controller
                control={control}
                rules={{ required: "This field is required" }}
                name="ataChapter"
                render={({ field: { ref, onChange, ...field } }) => (
                  <Autocomplete
                    // onInputChange={//api}
                    placeholder="Select ATA Chapter"
                    fullWidth
                    onChange={(_, data) => onChange(data)}
                    options={ataList}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: errors.ataChapter ? "red" : "#E8E8E8",
                      },

                      "&& legend": {
                        width: "0ch",
                      },
                    }}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size={"medium"}
                        fullWidth
                        placeholder="Select ATA Chapter"
                        // onClick={(e) => e.stopPropagation()}
                      />
                    )}
                    // isOptionEqualToValue={(option, value) => {
                    //   return option.id === value.id;
                    // }}
                    value={getValues().ataChapter}
                  />
                )}
              />
              {errors.ataChapter && <p style={{ color: "red" }}>{errors.ataChapter.message}</p>}
            </div>
            <div className={styles.newAlertFormDiv}>
              <span className={styles.textExtraSmall}>
                Event / Warning Message
                <span className={`${styles.asterisk}`}>*</span>
              </span>
              <Controller
                rules={{ required: "This field is required" }}
                control={control}
                name="event"
                render={({ field: { ref, onChange, ...field } }) => (
                  <Autocomplete
                    // onInputChange={//api}
                    placeholder="Select event"
                    fullWidth
                    onChange={(_, data) => onChange(data)}
                    options={eventList}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: errors.event ? "red" : "#E8E8E8",
                      },
                      "&& legend": {
                        width: "0ch",
                      },
                    }}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size={"medium"}
                        fullWidth
                        placeholder="Select event"
                        // onClick={(e) => e.stopPropagation()}
                      />
                    )}
                    // isOptionEqualToValue={(option, value) =>
                    //   option.id === value.id
                    // }
                    value={getValues().event}
                  />
                )}
              />
              {errors.event && <p style={{ color: "red" }}>{errors.event.message}</p>}
            </div>
            <div className={styles.newAlertFormDiv}>
              <span className={styles.textExtraSmall}>
                Observation<span className={`${styles.asterisk}`}>*</span>
              </span>
              <div>
                <Controller
                  control={control}
                  name="observation"
                  rules={{ required: "This field is required" }}
                  render={({ field }) => (
                    <RichTextInput
                      {...field}
                      ref={(ref) => {
                        observationRef.current = ref; // Assign the ref here
                        field.ref(ref); // Make sure to pass the ref to the field
                      }}
                      toolbar={{
                        options: ["history", "colorPicker", "inline", "remove"],
                        inline: {
                          options: ["bold", "italic", "underline", "strikethrough"],
                        },
                      }}
                      error={errors.observation}
                      value={getValues().observation}
                    />
                  )}
                />
                {errors.observation && <p style={{ color: "red" }}>{errors.observation.message}</p>}
              </div>
            </div>
            <div className={styles.newAlertFormDiv}>
              <span className={styles.textExtraSmall}>
                Analysis<span className={`${styles.asterisk}`}>*</span>
              </span>
              <div>
                <Controller
                  control={control}
                  name="analysis"
                  rules={{ required: "This field is required" }}
                  render={({ field }) => (
                    <RichTextInput
                      {...field}
                      ref={(ref) => {
                        analysisRef.current = ref; // Assign the ref here
                        field.ref(ref); // Make sure to pass the ref to the field
                      }}
                      toolbar={{
                        options: ["history", "colorPicker", "inline", "remove"],
                        inline: {
                          options: ["bold", "italic", "underline", "strikethrough"],
                        },
                      }}
                      error={errors.analysis}
                      value={getValues().analysis}
                    />
                  )}
                />
                {errors.analysis && <p style={{ color: "red" }}>{errors.analysis.message}</p>}
              </div>
            </div>
            <div className={styles.newAlertFormDiv}>
              <span className={styles.textExtraSmall}>
                Recommendations<span className={`${styles.asterisk}`}>*</span>
              </span>
              <div>
                <Controller
                  control={control}
                  name="recommendations"
                  rules={{ required: "This field is required" }}
                  render={({ field }) => (
                    <RichTextInput
                      {...field}
                      ref={(ref) => {
                        recommendationsRef.current = ref; // Assign the ref here
                        field.ref(ref); // Make sure to pass the ref to the field
                      }}
                      toolbar={{
                        options: ["history", "colorPicker", "inline", "remove"],
                        inline: {
                          options: ["bold", "italic", "underline", "strikethrough"],
                        },
                      }}
                      error={errors.recommendations}
                      value={getValues().recommendations}
                    />
                  )}
                />
                {errors.recommendations && <p style={{ color: "red" }}>{errors.recommendations.message}</p>}
              </div>
            </div>
            <div className={styles.newAlertFormDiv}>
              <span className={styles.textExtraSmall}>
                Possible Root Cause
                <span className={`${styles.asterisk}`}>*</span>
              </span>
              <div>
                <Controller
                  rules={{ required: "This field is required" }}
                  control={control}
                  name="rootcause"
                  render={({ field }) => (
                    <RichTextInput
                      {...field}
                      ref={(ref) => {
                        rootcauseRef.current = ref; // Assign the ref here
                        field.ref(ref); // Make sure to pass the ref to the field
                      }}
                      toolbar={{
                        options: ["history", "colorPicker", "inline", "remove"],
                        inline: {
                          options: ["bold", "italic", "underline", "strikethrough"],
                        },
                      }}
                      error={errors.rootcause}
                      value={getValues().rootcause}
                    />
                  )}
                />
                {errors.rootcause && <p style={{ color: "red" }}>{errors.rootcause.message}</p>}
              </div>
            </div>
            <div className={styles.newAlertFormDiv}>
              <span className={styles.textExtraSmall}>
                Contact Point<span className={`${styles.asterisk}`}>*</span>
              </span>
              <div>
                <Controller
                  control={control}
                  rules={{ required: "This field is required" }}
                  name="contactpoint"
                  render={({ field }) => (
                    <RichTextInput
                      {...field}
                      ref={(ref) => {
                        contactpointRef.current = ref; // Assign the ref here
                        field.ref(ref); // Make sure to pass the ref to the field
                      }}
                      toolbar={{
                        options: ["history", "colorPicker", "inline", "remove"],
                        inline: {
                          options: ["bold", "italic", "underline", "strikethrough"],
                        },
                      }}
                      error={errors.contactpoint}
                      value={getValues().contactpoint}
                    />
                  )}
                />
                {errors.contactpoint && <p style={{ color: "red" }}>{errors.contactpoint.message}</p>}
              </div>
            </div>
            <div className={styles.newAlertFormDiv}>
              <span className={styles.textExtraSmall}>
                Recipients<span className={`${styles.asterisk}`}>*</span>
              </span>
              <Controller
                control={control}
                rules={{ required: "This field is required" }}
                name="recipients"
                render={({ field: { ref, onChange, ...field } }) => (
                  <Autocomplete
                    // onInputChange={//api}
                    multiple
                    placeholder="Select recipient"
                    fullWidth
                    onChange={(_, data) => onChange(data)}
                    id="fixed-tags-demo"
                    options={userList.map((user) => user.email)}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: errors.recipients ? "red" : "#E8E8E8",
                      },
                      "&& legend": {
                        width: "0ch",
                      },
                    }}
                    limitTags={2}
                    disableCloseOnSelect
                    renderTags={(tagValue, getTagProps) => {
                      return tagValue.map((option, index) => (
                        <Chip
                          label={option}
                          {...getTagProps({ index })}
                          style={{ background: "#F2F7FF" }}
                          key={index}
                        />
                      ));
                    }}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size={"medium"}
                        fullWidth
                        placeholder={getValues().recipients.length < 1 ? "Select recipient" : ""}
                        // onClick={(e) => e.stopPropagation()}
                      />
                    )}
                    // isOptionEqualToValue={(option, value) =>
                    //   option.id === value.id
                    // }
                    value={getValues().recipients}
                  />
                )}
              />
              {errors.recipients && <p style={{ color: "red" }}>{errors.recipients.message}</p>}
            </div>
            {action === "update" && (
              <>
                <br />
                <div className={styles.row}>
                  <div className={styles.textExtraExtraSmall}>
                    Created {format(new Date(data?.addedAt.value), dateFnsFormats.STANDARD_DATE)} at{" "}
                    {format(new Date(data?.addedAt.value), dateFnsFormats.STANDARD_TIME_HOURS_MINUTES)}
                  </div>
                  {data?.updatedAt && (
                    <div className={`${styles.alignEnd} ${styles.textExtraExtraSmall}`}>
                      Updated {format(new Date(data?.updatedAt.value), dateFnsFormats.STANDARD_DATE)} at{" "}
                      {format(new Date(data?.updatedAt.value), dateFnsFormats.STANDARD_TIME_HOURS_MINUTES)}{" "}
                    </div>
                  )}
                </div>
                <div className={styles.textExtraExtraSmall}>
                  Created by {data.addedBy && data.addedBy !== "undefined" ? data.addedBy : "System"}
                </div>
              </>
            )}
          </div>
          <Grid container columns={2} sx={{ px: 2 }}>
            <Grid item xs={1} sx={{ p: 2 }}>
              <ButtonStyled
                variant="outlined"
                color="primary"
                fullWidth
                sx={{
                  backgroundColor: "#fff",
                  border: "none",
                  "&:hover": {
                    border: "1px solid #000",
                  },
                }}
                onClick={() => onClickClose()}
              >
                Cancel
              </ButtonStyled>
            </Grid>
            <Grid item xs={1} sx={{ p: 2 }}>
              <ButtonStyled
                variant="contained"
                color="primary"
                fullWidth
                // onClick={() => onClickClose()}
                type="submit"
                disabled={loading}
              >
                {loading ? <CircularProgress size={20} /> : data ? "Update" : "Add"}
              </ButtonStyled>
            </Grid>
          </Grid>
        </FormControl>
      </div>
    </>
  );
};

export default NewAlert;
