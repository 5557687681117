import React from "react";
import { Grid } from "@mui/material";
import styles from "./PreviousLegs.module.css";

const PreviousLegs = ({ data, legs }) => {
  function GetRepetitive() {
    let rep = "";
    if (data?.repetitive === 1) rep = "C";
    else if (data?.multiple === 1) rep = "NC";
    return (
      rep !== "" && (
        <>
          <span className={styles.dataItemsRep} style={{ marginRight: "30px" }}>
            {rep}
          </span>
        </>
      )
    );
  }

  function findMatch(item, legNo) {
    if (item?.legs?.length > 0) {
      let item_ = item.legs.split(",");
      // let leg = legNo + 1;
      let match = item_.includes(legNo.toString());
      if (match) return 1;
      else return 0;
    }
  }

  function prevLegs(item) {
    let array = [];
    for (let i = 1; i <= legs; i++) {
      array.push(i);
    }
    return array.map((index, y) => {
      let match = findMatch(item, index);
      return <div className={`${match === 1 ? styles.legsAvailable : styles.legsUnavailable}`} key={y}></div>;
    });
  }

  return (
    <Grid container columns={24} spacing={0}>
      <Grid item xs={4}>
        {GetRepetitive()}
      </Grid>
      <Grid item xs={20} sx={{ display: "flex", justifyContent: "flex-start" }}>
        {prevLegs(data)}
      </Grid>
    </Grid>
  );
};

export default PreviousLegs;
