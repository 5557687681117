import { Box, Grid, Tooltip, tooltipClasses, CircularProgress, Modal, Fade } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState, useRef, useEffect } from "react";
import { Switch as SwitchDom, Link, Route } from "react-router-dom";
import styles from "./Inbox.module.css";
import Printer from "common/images/icons/elevade/Printer.svg";
import FleetCardHigh from "common/images/icons/elevade/Fleet-Card-High.svg";
import FleetCardMed from "common/images/icons/elevade/Fleet-Card-Med.svg";
import FleetCardLow from "common/images/icons/elevade/Fleet-Card-Low.svg";
import FleetCardOk from "common/images/icons/elevade/Fleet-Card-Ok.svg";
import FleetCardUndefined from "common/images/icons/elevade/Fleet-Card-Undefined.svg";
import FleetCardNone from "common/images/icons/elevade/Fleet-Card-None.svg";
import FleetCardNuisance from "common/images/icons/elevade/Fleet-Card-Nuisance.svg";
import CloseIcon from "common/images/icons/elevade/Close-Icon.svg";
import AircraftReport from "./AircraftReport";
import { makeAPIRequestRun, toShortDateTimeString } from "common/helpers";
import { useSelector } from "react-redux";
import { LinearProgressBar, formatDate, ConvertLocaleUTCDatetime, setAOCBasedOnPermission } from "../component/Helpers";
import MUIPagination from "../component/Pagination";
import getTimezoneAbbreviation from "../component/TimeZoneAbbreviation";
import { makeStyles } from "@mui/styles";
import PreviousLegs from "../component/PreviousLegs";
import Switch from "react-switch";
import { showNotification } from "common/helpers";
import { generateInboxPDF } from "./generatePdf";
import { SearchFault, SearchOOOI, SearchPositioning, SearchAcms } from "../component/SearchBarInbox";
import InboxOOOI from "./InboxOOOI";
import InboxPositioning from "./InboxPositioning";
import InboxAcms from "./InboxAcms";
import { isEmpty, uniqBy } from "lodash";
import { useDispatch } from "react-redux";
import { setFilters, setKeyword } from "common/store/reducers/elevade/filters";
import DataUnavailable from "common/images/icons/elevade/Data-unavailable.svg";
import ActiveFilters from "../component/ActiveFilters";
import { elevadeActiveFilter } from "common/constant";
import Sorting from "../component/Sorting";
import { subMinutes } from "date-fns";
import AirplaneLoader from "../component/AirplaneLoader";
import { ReportPreview } from "../component/ReportPreview";

const Inbox = () => {
  const [isInbox, setIsInbox] = useState([]);
  const pathname = window.location.pathname;
  const [openCogwheel, setOpenCogwheel] = useState(false);
  const [isNewFiltering, setIsNewFiltering] = useState(false);
  const wrapperRef = useRef(null);
  const dateRangeRef = useRef(null);
  const [activeTab, setActiveTab] = useState(1);
  const [isSearchInput, setIsSearchInput] = useState([]);
  const [isAtaChapter, setIsAtaChapter] = useState("All");
  const [isFaultPriority, setIsFaultPriority] = useState("All");
  const [isRepetitive, setIsRepetitive] = useState("All");
  const [isAircraftType, setIsAircraftType] = useState("All");
  const [isOperator, setIsOperator] = useState("All");
  const [isStatus, setIsStatus] = useState("All");
  const [isPhase, setIsPhase] = useState("All");
  const [isACReg, setIsACReg] = useState("All");
  const [isLegs, setIsLegs] = useState("15");
  const [isLoading, setIsLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [localTime, setLocalTime] = useState(false);
  const [timeZone, setTimeZone] = useState();
  const [timeZoneAfter, setTimeZoneAfter] = useState("'UTC'");
  const [isInboxForCSV, setIsInboxForCSV] = useState(isInbox);
  const [isOpenDateRange, setIsOpenDateRange] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState();
  const { profile: userProfile } = useSelector((state) => state.user);
  const { company: companyInfo } = useSelector((state) => state.company);
  const { companyAOC, userAOC, userName: userEmail, userPermission } = useSelector((state) => state.userEntity);
  // let AOC = companyInfo?.permission?.elevadeAOC;
  let AOC = companyAOC;
  const [checkAOC, setCheckAOC] = useState(setAOCBasedOnPermission(AOC, userPermission));
  AOC = checkAOC;
  const [listAtaChapter, setListAtaChapter] = useState();
  const [listFaultPriority, setListFaultPriority] = useState();
  const [listPhase, setListPhase] = useState();
  const [listACReg, setListACReg] = useState();
  const [listRepetitive, setListRepetitive] = useState(["Repetitive", "No Repetitive"]);
  const [listOperator, setListOperator] = useState();
  const [listStatus, setListStatus] = useState();
  const [listAircraftType, setListAircraftType] = useState(["A320", "A320N", "A321N", "A330"]);
  const [isGenerateCSV, setIsGenerateCSV] = useState(false);
  // const [labels, setLabels] = useState("Generating file");
  const [checked, setChecked] = useState(false);
  const [activeFilters, setActiveFilters] = useState([]);
  const [isSortBy, setIsSortBy] = useState([]);
  const [isSorting, setIsSorting] = useState("asc");
  const [sortingColumn, setSortingColumn] = useState("sent_datetime"); //orderby
  const [sortingDirection, setSortingDirection] = useState("desc"); //order
  const [vertical, setVertical] = useState(true); //pass to acms grid/list
  const [showRawDataModal, setShowRawDataModal] = useState(false);
  const [rawData, setRawData] = useState("-");
  const [tab, setTab] = useState(1);
  const [openReportPreview, setOpenReportPreview] = useState(false);
  const [reportData, setReportData] = useState({});
  const [allPfr, setAllPfr] = useState([]);
  const [acList, setAcList] = useState([]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 1080,
    minHeight: 216,
    bgcolor: "background.paper",
    p: 3,
    borderRadius: "12px",
  };

  const dispatch = useDispatch();

  let sliceStart = page > 0 ? perPage * (page - 1) : 0;
  if (sliceStart >= totalRows) sliceStart = Math.floor(totalRows / perPage);
  // let sliceEnd = page > 0 ? perPage * page : perPage;

  let searchQuery = "null";

  if (isAtaChapter !== "All" && isAtaChapter.length > 0) searchQuery += `ata_chapter=${isAtaChapter}|`;
  if (isFaultPriority !== "All" && isFaultPriority.length > 0) searchQuery += `priority=${isFaultPriority}|`;
  if (isRepetitive !== "All" && isRepetitive.length > 0) searchQuery += `repetitive=${isRepetitive}|`;
  if (isAircraftType !== "All" && isAircraftType.length > 0) searchQuery += `ac_type=${isAircraftType}|`;
  if (isOperator !== "All" && isOperator.length > 0) searchQuery += `operator=${isOperator}|`;
  if (isPhase !== "All" && isPhase.length > 0) searchQuery += `phase=${isPhase}|`;
  if (isACReg !== "All" && isACReg.length > 0) searchQuery += `ac_reg=${isACReg}|`;
  if (isSearchInput !== undefined && isSearchInput !== "") searchQuery += `search=${isSearchInput}|`;
  if (startDate !== undefined && endDate !== undefined) {
    const start = localTime ? subMinutes(new Date(startDate), -new Date(startDate).getTimezoneOffset()) : startDate;
    const end = localTime ? subMinutes(new Date(endDate), -new Date(endDate).getTimezoneOffset()) : endDate;
    searchQuery += `event_datetime=${ConvertLocaleUTCDatetime(start)}~${ConvertLocaleUTCDatetime(end, "end")}|`;
  }

  // set filter to All
  if (isACReg !== "All" && isACReg.length <= 0) setIsACReg("All");
  if (isOperator !== "All" && isOperator.length <= 0) setIsOperator("All");
  if (isAtaChapter !== "All" && isAtaChapter.length <= 0) setIsAtaChapter("All");
  if (isPhase !== "All" && isPhase.length <= 0) setIsPhase("All");
  if (isFaultPriority !== "All" && isFaultPriority.length <= 0) setIsFaultPriority("All");
  if (isRepetitive !== "All" && isRepetitive.length <= 0) setIsRepetitive("All");
  if (isAircraftType !== "All" && isAircraftType.length <= 0) setIsAircraftType("All");

  useEffect(() => {
    setStartDate();
    setEndDate();
  }, [localTime]);

  useEffect(() => {
    setPage(1);
  }, [
    isAtaChapter,
    isFaultPriority,
    isRepetitive,
    isAircraftType,
    isOperator,
    startDate,
    endDate,
    isSearchInput,
    isPhase,
    isACReg,
    sortingColumn,
    sortingDirection,
  ]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [page]);

  const StyledTooltip = styled(({ className, ...props }) => <Tooltip {...props} />)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {},
  }));

  // Get the Aircraft List from the masterdata
  async function fetchAircraftList() {
    const fetchData = makeAPIRequestRun(`get`, `acarsdata/acinitiallist`);
    fetchData
      .then((response) => {
        setAcList(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  // Set initial inbox data
  async function fetchDistinctValue(col, tbl, aoc_) {
    const fetchData = makeAPIRequestRun(
      "get",
      `acarsdata/getdistinctvalue/${AOC}/${encodeURIComponent(searchQuery)}/${col}/${tbl}/faultmsg`
    );
    fetchData
      .then((response) => {
        if (col === "ata_chapter") {
          let myArray = [];
          // eslint-disable-next-line
          response?.map((e) => {
            if (e?.ata_chapter !== "") myArray.push(e?.ata_chapter?.substring(0, 2));
          });

          setListAtaChapter([
            ...new Set(
              myArray &&
                myArray?.map((item) => {
                  return item;
                })
            ),
          ]);
        } else if (col === "priority") {
          const priority = ["High", "Medium", "Low", "OK", "Nuisance", "None", "Undefined"];
          const sorted = response.sort((a, b) => priority.indexOf(a.priority) - priority.indexOf(b.priority));
          setListFaultPriority(sorted);
        } else if (col === "operator") setListOperator(response);
        else if (col === "phase") setListPhase(response);
        else if (col === "ac_reg") setListACReg(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useEffect(() => {
    fetchAircraftList();
    fetchDistinctValue("ata_chapter", "acars_inbox");
    fetchDistinctValue("priority", "acars_inbox");
    fetchDistinctValue("report_type", "acars_inbox");
    fetchDistinctValue("operator", "acars_inbox");
    fetchDistinctValue("phase", "acars_inbox");
    fetchDistinctValue("ac_reg", "acars_inbox");
    // eslint-disable-next-line
  }, [AOC]);

  useEffect(() => {
    if (isOperator !== "All") {
      let array = filterByOperator(acList);

      let newArray = array.map((obj) => {
        let newObj = {};
        for (let key in obj) {
          newObj[key?.toLowerCase()] = obj[key];
        }
        return newObj;
      });

      setListACReg(newArray);
    } else fetchDistinctValue("ac_reg", "acars_inbox");
  }, [isOperator]);

  function filterByOperator(list) {
    if (isOperator !== "All" && isOperator.length > 0) {
      return list.filter((item) => isOperator.includes(item.Operator));
    }
    return list;
  }

  useEffect(() => {
    if (!isOpenDateRange) setIsLoading(true);
    setIsGenerateCSV(true);

    async function fetchData() {
      const fetchInbox = makeAPIRequestRun(
        "get",
        "acarsdata/acarsinbox/" +
          AOC +
          "/" +
          perPage +
          "/" +
          sliceStart +
          "/" +
          encodeURIComponent(searchQuery) +
          "/faultmsg/" +
          sortingColumn +
          "/" +
          sortingDirection
      );
      fetchInbox
        .then((response) => {
          // setInbox(response);
          setIsInbox(response);
          setIsNewFiltering(true);
          setIsGenerateCSV(false);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    async function fetchTotalRows() {
      const fetchData = makeAPIRequestRun(
        "get",
        "acarsdata/acarsinboxtotalrows/" +
          AOC +
          "/" +
          perPage +
          "/" +
          sliceStart +
          "/" +
          encodeURIComponent(searchQuery) +
          "/faultmsg"
      );
      fetchData
        .then((response) => {
          setTotalRows(response[0]?.Total);
          setTotalPage(Math.floor(response[0]?.Total / perPage) + 1);
          setIsGenerateCSV(false);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }

    // TODO: To rectify query error messages on the back end
    const fetchInbox = makeAPIRequestRun(
      "get",
      "acarsdata/acarsinboxall/" + AOC + "/" + encodeURIComponent(searchQuery) + "/faultmsg"
    );

    tab === 1 &&
      fetchInbox
        .then((response) => {
          setIsInboxForCSV(response);
          setIsGenerateCSV(false);
          setIsNewFiltering(true);
        })
        .catch((error) => {
          console.log("error", error);
        });

    async function fetchPfrData() {
      const fetchInbox = makeAPIRequestRun(
        "get",
        "acarsdata/acarsinbox/pfr/" +
          AOC +
          "/" +
          perPage +
          "/" +
          sliceStart +
          "/" +
          encodeURIComponent(searchQuery) +
          "/faultmsg/" +
          sortingColumn +
          "/" +
          sortingDirection
      );
      fetchInbox
        .then((response) => {
          const msg_id = response.map((item) => item.msg_id);
          const fetchData = makeAPIRequestRun("post", `acarsdata/acarsinbox/pfr`, { msg_id: msg_id });
          fetchData
            .then((res) => {
              setAllPfr(res);
            })

            .catch((error) => {
              console.log("error", error);
            });
          // setInbox(response);
          setIsInbox(response);
          setIsNewFiltering(true);
          setIsGenerateCSV(false);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    async function fetchPfrTotalRows() {
      const fetchData = makeAPIRequestRun(
        "get",
        "acarsdata/acarsinboxtotalrows/pfr/" +
          AOC +
          "/" +
          perPage +
          "/" +
          sliceStart +
          "/" +
          encodeURIComponent(searchQuery) +
          "/faultmsg"
      );
      fetchData
        .then((response) => {
          setTotalRows(response[0]?.f0_);
          setTotalPage(Math.floor(response[0]?.f0_ / perPage) + 1);
          setIsGenerateCSV(false);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }

    if (tab === 2) {
      fetchPfrData();
      fetchPfrTotalRows();
    } else {
      fetchData();
      fetchTotalRows();
    }
    // eslint-disable-next-line
  }, [
    AOC,
    perPage,
    page,
    isAtaChapter,
    isFaultPriority,
    isRepetitive,
    isAircraftType,
    isOperator,
    startDate,
    endDate,
    isSearchInput,
    isPhase,
    isACReg,
    sortingColumn,
    sortingDirection,
    tab,
  ]);

  useEffect(() => {
    async function fetchAllData() {
      setIsGenerateCSV(true);
      // GeneratingFile();
      const fetchInbox = makeAPIRequestRun(
        "get",
        "acarsdata/acarsinboxall/" + AOC + "/" + encodeURIComponent(searchQuery) + "/faultmsg"
      );

      fetchInbox
        .then((response) => {
          setIsInboxForCSV(response);
          setIsGenerateCSV(false);
          setIsNewFiltering(true);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    if (openCogwheel && isNewFiltering) {
      fetchAllData();
    }
    // eslint-disable-next-line
  }, [openCogwheel]);

  useEffect(() => {
    if (!isEmpty(isAtaChapter) && isAtaChapter !== "All") setFilter(isAtaChapter, "ATA Chapter");
    if (!isEmpty(isFaultPriority) && isFaultPriority !== "All") setFilter(isFaultPriority, "Priority");
    if (!isEmpty(isAircraftType) && isAircraftType !== "All") setFilter(isAircraftType, "Aircraft Type");
    if (!isEmpty(isOperator) && isOperator !== "All") setFilter(isOperator, "Operator");
    if (!isEmpty(isPhase) && isPhase !== "All") setFilter(isPhase, "Phase");
    if (!isEmpty(isACReg) && isACReg !== "All") setFilter(isACReg, "A/C Reg.");
    if (startDate || endDate)
      setFilter([`${toShortDateTimeString(startDate)} - ${toShortDateTimeString(endDate)}`], "Date Range");
  }, [
    isAtaChapter,
    isFaultPriority,
    isAircraftType,
    isOperator,
    isOpenDateRange,
    isPhase,
    isACReg,
    startDate,
    endDate,
  ]);

  const setFilter = (filters, name) => {
    setActiveFilters((prevState) => {
      const arr = { data: filters, label: name };
      const res = [...prevState, arr].reverse();
      const unique = uniqBy(res, "label");

      return unique;
    });
  };

  // Function to detect mouse click outside a component
  function useOutsideClicker(ref, component) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (component === "csv") {
            setOpenCogwheel(false);
          } else if (component === "dateRange") setIsOpenDateRange(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, component]);
  }

  useOutsideClicker(wrapperRef, "csv");
  useOutsideClicker(dateRangeRef, "dateRange");

  // Custom styles
  const useStyles = makeStyles(() => ({
    root: {
      width: "64px",
      maxWidth: "64px",
      maxHeight: "22px",
      padding: "0px",
    },
    switchBase: {
      color: "#818181",
      padding: "1px",
      "&$checked": {
        "& + $track": {
          backgroundColor: "#394458 !important",
        },
      },
    },
    thumb: {
      color: "white",
      width: "18px",
      height: "18px",
      margin: "1px",
    },
    ul: {
      "& .Mui-selected": {
        color: "#fff",
        backgroundColor: "#088FD1",
        border: "1px solid #088FD1",
        "&:hover": {
          backgroundColor: "#088FD1",
        },
      },
    },
    messageTootltip: {
      background: "#fffac3 0% 0% no-repeat padding-box",
      color: "#333333",
      boxShadow: "0px 3px 6px #00000040",
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    setTimeZone(getTimezoneAbbreviation(new Date()));
  }, [timeZoneAfter]);

  function getActivePath(path) {
    if (pathname.includes(path)) {
      return styles.active;
    } else {
      return "";
    }
  }

  // Set the active tab to the one clicked on
  function handleActiveTab(tab) {
    setActiveTab(tab);
    setIsSearchInput([]);
    setIsAtaChapter("All");
    setIsFaultPriority("All");
    setIsOperator("All");
    setStartDate();
    setEndDate();
    setIsAircraftType("All");
    setIsStatus("All");
    setIsPhase("All");
    setIsACReg("All");
  }

  useEffect(() => {
    if (!pathname.includes("/inbox/fault-messages")) {
      setActiveTab(0);
    }
    if (pathname.endsWith("/inbox")) {
      setActiveTab(1);
    }
  }, [pathname]);

  const NoDataAvailable = () => {
    const [show, setShow] = useState(false);
    useEffect(() => {
      const timeoutId = setTimeout(() => {
        setShow(true);
      }, 1000);

      return () => clearTimeout(timeoutId);
    }, []);
    return show ? (
      <>
        <div style={{ marginTop: "5%", marginBottom: "10%", textAlign: "center" }}>
          <div style={{ marginBottom: "16px" }}>
            <img src={DataUnavailable} alt="data-unavailable" />
          </div>
          <div style={{ fontFamily: "Gotham-Medium", fontSize: "14px" }}>Data Unavailable</div>
          <div style={{ fontFamily: "Gotham-Book", fontSize: "12px" }}>
            There is no data found. Please try a different search term.
          </div>
        </div>
      </>
    ) : (
      ""
    );
  };

  function getFleetCard(card) {
    switch (card) {
      case "High":
        return <img src={FleetCardHigh} alt="High" className={styles.fleetIcon} />;
      case "Medium":
        return <img src={FleetCardMed} alt="Medium" className={styles.fleetIcon} />;
      case "Low":
        return <img src={FleetCardLow} alt="Low" className={styles.fleetIcon} />;
      case "OK":
        return <img src={FleetCardOk} alt="Ok" className={styles.fleetIcon} />;
      case "Undefined":
        return <img src={FleetCardUndefined} alt="Undefined" className={styles.fleetIcon} />;
      case "None":
        return <img src={FleetCardNone} alt="None" className={styles.fleetIcon} />;
      case "Nuisance":
        return <img src={FleetCardNuisance} alt="Nuisance" className={styles.fleetIcon} />;
      default:
        return <img src={FleetCardNone} alt="None" className={styles.fleetIcon} />;
    }
  }

  // Function to split ATA chapter
  function getAtaChapter(ata, report_type) {
    if (ata && report_type === "FAULT")
      return ata.substring(0, 2) + "-" + ata.substring(2, 4) + "-" + ata.substring(4, 6);
    else if (ata && report_type === "WARNING") return ata.substring(0, 2) + "-" + ata.substring(2, 4);
    else if (ata) return ata.substring(0, 2) + "-" + ata.substring(2, 4) + "-" + ata.substring(4, 6);
  }

  function isNumeric(value) {
    return /^-?\d+$/.test(value);
  }

  function isCombineNumericLetters(value) {
    return /\d/.test(value);
  }

  const handleChangeTimeZone = (nextChecked) => {
    setChecked(nextChecked);
    if (nextChecked) {
      setLocalTime(true);
    } else {
      setLocalTime(false);
    }
  };

  const createSheet = async (prop) => {
    setIsGenerateCSV(true);
    try {
      const title = "ELEVADE - ACARS DATA: Warning & Fault Messages";
      const tableRows = [];

      // eslint-disable-next-line no-unused-expressions
      isInboxForCSV?.forEach((res, index) => {
        const repetitive =
          res.repetitive === 1 && res.multiple === 1 ? "C" : res.repetitive === 0 && res.multiple === 1 ? "NC" : "";
        const inboxData = [
          res.priority,
          res.msg,
          res.msg_type,
          res.report_type,
          res.ac_type,
          res.ac_reg,
          res.flight_no,
          res.phase,
          res.ata_chapter,
          res.source,
          formatDate(res.event_datetime?.value, "", localTime),
          formatDate(res.time?.value, "", localTime),
          repetitive,
        ];
        tableRows.push(inboxData);
      });
      const data = {
        sheetData: tableRows,
        title: title,
        activeFilters: activeFilters,
        keyword: isSearchInput,
        totalRecords: totalRows,
        // email: userEntity.userDetail.userName,
        email: userEmail,
      };
      const x = await makeAPIRequestRun("post", `acarsdata/acarsinbox/googleSheets`, data);
      x
        ? x.error
          ? showNotification("danger", "", [x.message])
          : window.open(`https://docs.google.com/spreadsheets/d/${x}`)
        : showNotification("danger", "", ["Something went wrong"]);
    } catch (err) {
      showNotification("danger", "", [err]);
    }
    setIsGenerateCSV(false);
  };

  const pdfPrep = async () => {
    setIsGenerateCSV(true);
    try {
      const tableHeader = [
        " ",
        "Priority",
        "Message",
        "Message Type",
        "Report",
        "A/C Model",
        "A/C Reg.",
        "Flight No",
        "Phase",
        "ATA",
        "Source",
        "Occurence",
        "Sent",
        "Repetitive",
      ];
      const fileName = "ELEVADE - ACARS DATA ";
      const title = "WARNING & FAULT MESSAGES";
      const tableRows = [];

      // for each res pass all its data into an array
      // eslint-disable-next-line no-unused-expressions
      isInboxForCSV?.forEach((res, index) => {
        const repetitive =
          res.repetitive === 1 && res.multiple === 1 ? "C" : res.repetitive === 0 && res.multiple === 1 ? "NC" : "";
        const inboxData = [
          index + 1,
          res.priority,
          res.msg,
          res.msg_type,
          res.report_type,
          res.ac_type,
          res.ac_reg,
          res.flight_no,
          res.phase,
          res.ata_chapter,
          res.source,
          formatDate(res.event_datetime?.value, "", localTime),
          formatDate(res.time?.value, "", localTime),
          // res.legs,
          repetitive,
        ];
        // push each inboxData's info into a row
        tableRows.push(inboxData);
      });
      await generateInboxPDF(
        tableRows,
        title,
        activeFilters,
        totalRows,
        tableHeader,
        "landscape",
        fileName,
        isSearchInput
      );
    } catch (err) {
      showNotification("danger", "", [err]);
    }
    setTimeout(() => {
      setIsGenerateCSV(false);
    }, 1500);
  };

  const handleFilters = (msg, msgId, flightNo, msgType, pathname, eventDate, telexTime) => {
    dispatch(setFilters(activeFilters));
    dispatch(setKeyword(isSearchInput));
    localStorage.setItem(
      "inboxMsg",
      JSON.stringify({
        msg: msg,
        msgId: msgId,
        flightNo: flightNo,
        msgType: msgType,
        eventDate: eventDate,
        telexTime: telexTime,
      })
    );
    localStorage.setItem("prevURLNewTab", JSON.stringify(pathname));
  };

  const Header = () => {
    return (
      <div>
        <div className={`${styles.header}`}>
          <div className={`${styles.headerText} ${activeTab === 1 ? styles.active : ""}`}>
            <Link to={`/fleet/inbox/fault-messages`} className={`${styles.link}`} onClick={() => handleActiveTab(1)}>
              Warning and Fault Messages
            </Link>
          </div>
          <div
            className={`${styles.headerText} ${getActivePath("oooi-reports")} ${activeTab === 2 ? styles.active : ""}`}
          >
            <Link to={`/fleet/inbox/oooi-reports`} className={`${styles.link}`} onClick={(e) => handleActiveTab(2)}>
              OOOI Reports
            </Link>
          </div>
          <div
            className={`${styles.headerText} ${getActivePath("positioning-reports")} ${
              activeTab === 3 ? styles.active : ""
            }`}
          >
            <Link
              to={`/fleet/inbox/positioning-reports`}
              className={`${styles.link}`}
              onClick={(e) => handleActiveTab(3)}
            >
              Position Reports
            </Link>
          </div>
          <div
            className={`${styles.headerText} ${getActivePath("acms-reports")} ${activeTab === 4 ? styles.active : ""}`}
          >
            <Link to={`/fleet/inbox/acms-reports`} className={`${styles.link}`} onClick={(e) => handleActiveTab(4)}>
              ACMS Reports
            </Link>
          </div>
          {/* <TimeZone setLocalTime={setLocalTime} /> */}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              paddingRight: "42px",
              paddingTop: "14px",
            }}
          >
            <span style={{ marginRight: "10px", marginTop: "0px" }}>Time Zone</span>{" "}
            <Switch
              onChange={handleChangeTimeZone}
              checked={checked}
              className="react-switch"
              height={22}
              width={64}
              offColor="#088FD1"
              onColor="#394458"
              handleDiameter={16}
              uncheckedIcon={
                <div
                  style={{
                    color: "#ffffff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    marginRight: "8px",
                    fontFamily: "Gotham-Book",
                    fontSize: "12px",
                  }}
                >
                  UTC
                </div>
              }
              checkedIcon={
                <div
                  style={{
                    color: "#ffffff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    marginLeft: "15px",
                    fontFamily: "Gotham-Book",
                    fontSize: "12px",
                  }}
                >
                  {timeZone}
                </div>
              }
            />
          </div>
        </div>
      </div>
    );
  };

  const downloadCSV = () => {
    let recordsMessage = "";
    if (totalRows > 5000) recordsMessage = `Maximum of 5,000 / ${totalRows} records displayed`;
    else {
      recordsMessage = `${totalRows} records displayed`;
    }
    const keyword = isEmpty(isSearchInput) ? "-" : isSearchInput;
    let isContentCSV = [];
    const csvInfo = isInboxForCSV?.map((item) => {
      const repetitive =
        item.repetitive === 1 && item.multiple === 1 ? "C" : item.repetitive === 0 && item.multiple === 1 ? "NC" : "";
      const source = item.source ?? "-";
      return `\n${item.priority},"${item.msg}",${item.msg_type},${item.report_type},${item.ac_type},${item.ac_reg},${item.flight_no},${item.phase},${item.ata_chapter},"${source}",${item.event_datetime_format},${item.time_datetime_format},${repetitive}`;
    });
    let filters = activeFilters?.map((item) => {
      return `\n${item.label},"${item.data}"`;
    });
    if (isEmpty(filters)) filters = "-";
    isContentCSV = `Title,ELEVADE - ACARS DATA: Warning & Fault Messages,\nPrinted Date & Time (UTC),"${new Date().toUTCString()}",\nDisplayed Records,"${recordsMessage}",\nKeyword Search,"${keyword}", \n\nActive Filters,${filters},\n\nPriority,Message,Message Type,Report,A/C Model,A/C Reg,Flight No,Phase,ATA,Source,Occurence,Sent,Repetitive,${csvInfo}`;
    const csvContent = `data:text/csv;charset=utf-8,${isContentCSV}`;
    const encodedURI = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedURI);
    link.setAttribute("download", `ELEVADE-INBOX WARNING & FAULT MESSAGES ` + new Date() + ".csv");
    link.click();
  };

  const IsGeneratingFiles = () => {
    return (
      <>
        {!isGenerateCSV && !isLoading ? (
          <Grid container columns={3} spacing={1}>
            <Grid
              item
              xs={2.5}
              onClick={async () => {
                downloadCSV();
              }}
              sx={{ cursor: "pointer" }}
            >
              Export in .CSV
            </Grid>
            <Grid
              item
              xs={2.5}
              onClick={async () => {
                createSheet();
              }}
              sx={{ cursor: "pointer" }}
            >
              Google Sheets
            </Grid>
            <Grid
              item
              xs={2.5}
              onClick={async () => {
                pdfPrep();
              }}
              sx={{ cursor: "pointer" }}
            >
              Export as PDF
            </Grid>
          </Grid>
        ) : (
          <Grid sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress size={25} />
          </Grid>
        )}
      </>
    );
  };

  // handle telex raw data modal
  const handleClose = () => {
    setShowRawDataModal(false);
  };

  const DisplayDataHeader = () => {
    return (
      <>
        <div className={`row ${styles.displayHeader}`}>
          <Grid container spacing={0} columns={28} className={`${styles.displayHeaderLabel}`}>
            <Grid item xs={1} className={styles.nowrap}>
              <Sorting
                title={"Prio."}
                col={"priority"}
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={2} className={styles.nowrap}>
              <Sorting
                title={"Msg Type"}
                col={"msg_type"}
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={3.4} className={styles.nowrap}>
              <Sorting
                title={"Message"}
                col={"msg"}
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={1.4} className={styles.nowrap}>
              <Sorting
                title={"Report"}
                col={"report_type"}
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={2} className={styles.nowrap}>
              <Sorting
                title={"A/C Model"}
                col={"model"}
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={2} className={styles.nowrap}>
              <Sorting
                title={"A/C Reg."}
                col={"ac_reg"}
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={2} className={styles.nowrap}>
              <Sorting
                title={"Flight No."}
                col={"flight_no"}
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={1.3} className={styles.nowrap}>
              <Sorting
                title={"Phase"}
                col={"phase"}
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={1.8} className={styles.nowrap}>
              <Sorting
                title={"ATA"}
                col={"ata_chapter"}
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={1.5} className={styles.nowrap}>
              <Sorting
                title={"Source"}
                col={"source"}
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={2.8} className={styles.nowrap}>
              <Sorting
                title={"Occurrence"}
                col={"event_datetime"}
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={2.8} className={styles.nowrap}>
              <Sorting
                title={"Telex Sent"}
                col={"time"}
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={3} className={styles.nowrap}>
              Rep.
            </Grid>

            <Grid item xs={1} style={{ display: "flex", justifyContent: "flex-end", position: "relative" }}>
              <span className={`${styles.displayHeaderLabel}`}>
                <img
                  src={Printer}
                  className={styles.cogWheelIcon}
                  onClick={() => setOpenCogwheel(!openCogwheel)}
                  alt=""
                />
              </span>
              {openCogwheel && (
                <div
                  className={`${styles.cogWheelMenu} ${openCogwheel ? styles.cogWheelMenuActive : ""}`}
                  ref={wrapperRef}
                >
                  <ul className={styles.ul}>{IsGeneratingFiles()}</ul>
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </>
    );
  };

  const DisplayDataFault = () => {
    return isInbox?.length > 0 ? (
      isInbox?.map((item, index) => {
        return (
          <>
            <Link
              to={`/fleet/aircraft-status/${item.ac_reg}/failure-messages`}
              target="_blank"
              key={index}
              onClick={() => {
                handleFilters();
                handleFilters(
                  item.msg,
                  item.msg_id,
                  item.flight_no,
                  item.msg_type,
                  pathname,
                  item.event_date_format,
                  item.event_datetime_format
                );
              }}
            >
              <div className={`row ${styles.dataItems}`}>
                <Grid container spacing={0} columns={28}>
                  <Grid item xs={1}>
                    {getFleetCard(item.priority)}
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    className={`${styles.dataItemsText} ${item.msg_type === "WARNING" ? styles.bold : ""}`}
                  >
                    {item.msg_type}
                  </Grid>
                  <Grid item xs={3.4} className={`${styles.dataItemsText}`}>
                    <StyledTooltip
                      title={item?.msg}
                      placement="bottom"
                      enterDelay={500}
                      followCursor={false}
                      classes={{ tooltip: classes.messageTootltip }}
                    >
                      <span>{item.msg}</span>
                    </StyledTooltip>
                  </Grid>
                  <Grid
                    item
                    xs={1.4}
                    className={`${styles.dataItemsText}`}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowRawDataModal(true);
                      setRawData(item.raw_msg);
                    }}
                    style={{ textDecorationLine: "underline", textDecorationColor: "#C3C3C3" }}
                  >
                    {item.report_type}
                  </Grid>
                  <Grid item xs={2} className={`${styles.dataItemsText}`}>
                    {item.model}
                  </Grid>
                  <Grid item xs={2} className={`${styles.dataItemsText}`}>
                    {item.ac_reg}
                  </Grid>
                  <Grid item xs={2} className={`${styles.dataItemsText}`}>
                    {item.flight_no}
                  </Grid>
                  <Grid item xs={1.3} className={`${styles.dataItemsText}`}>
                    {item.phase}
                  </Grid>
                  <Grid item xs={1.8} className={`${styles.dataItemsText}`}>
                    {item.ata_chapter === "UNIDENTIFIED"
                      ? isNumeric(item.ata_chapter_raw) || isCombineNumericLetters(item.ata_chapter_raw)
                        ? getAtaChapter(item.ata_chapter_raw)
                        : item.ata_chapter_raw
                      : getAtaChapter(item.ata_chapter)}
                  </Grid>
                  <Grid item xs={1.5} className={`${styles.dataItemsText}`}>
                    <StyledTooltip
                      title={item?.source}
                      placement="bottom"
                      enterDelay={500}
                      followCursor={false}
                      classes={{ tooltip: classes.messageTootltip }}
                    >
                      <span>{item.source}</span>
                    </StyledTooltip>
                  </Grid>
                  <Grid item xs={2.8} className={`${styles.dataItemsText}`}>
                    <StyledTooltip
                      title={formatDate(item?.event_datetime?.value, "", localTime)}
                      placement="bottom"
                      enterDelay={500}
                      followCursor={false}
                      classes={{ tooltip: classes.messageTootltip }}
                    >
                      <span>{formatDate(item.event_datetime?.value, "", localTime)}</span>
                    </StyledTooltip>
                  </Grid>
                  <Grid item xs={2.8} className={`${styles.dataItemsText}`}>
                    {formatDate(item.time?.value, "", localTime)}
                  </Grid>
                  <Grid item xs={4} style={{ display: "flex", justifyContent: "flex-end" }}>
                    <>
                      <PreviousLegs data={item} legs={isLegs} />
                    </>
                  </Grid>
                </Grid>
              </div>
            </Link>
          </>
        );
      })
    ) : !isLoading && isInbox?.length === 0 ? (
      <NoDataAvailable />
    ) : (
      ""
    );
  };

  const SwitchTabs = () => {
    return (
      <>
        <div className={`${styles.header} ${styles.headerSubTab}`}>
          <div className={`${styles.headerText} ${styles.headerTextSubTab} ${tab === 1 ? styles.active : ""}`}>
            <Link to="#" className={`${styles.link}`} onClick={() => setTab(1)}>
              Fault Messages
            </Link>
          </div>
          <div className={`${styles.headerText} ${styles.headerTextSubTab} ${tab === 2 ? styles.active : ""}`}>
            <Link to="#" className={`${styles.link}`} onClick={() => setTab(2)}>
              Post Flight Reports
            </Link>
          </div>
        </div>
      </>
    );
  };

  const DisplayPfrHeader = () => {
    return (
      <>
        <div className={`row ${styles.displayHeader}`}>
          <Grid container spacing={0} columns={6} className={`${styles.displayHeaderLabel}`}>
            <Grid item xs={1} className={styles.nowrap}>
              <Sorting
                title={"Msg Type"}
                col={"msg_type"}
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>

            <Grid item xs={1} className={styles.nowrap}>
              <Sorting
                title={"A/C Model"}
                col={"model"}
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={1} className={styles.nowrap}>
              <Sorting
                title={"A/C Reg."}
                col={"ac_reg"}
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={1} className={styles.nowrap}>
              <Sorting
                title={"Flight No."}
                col={"flight_no"}
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={1} className={styles.nowrap}>
              <Sorting
                title={"Occurrence"}
                col={"event_datetime"}
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
            <Grid item xs={1} className={styles.nowrap}>
              <Sorting
                title={"Sent"}
                col={"telex_sent"}
                sortingColumn={sortingColumn}
                setSortingColumn={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
              />
            </Grid>
          </Grid>
        </div>
      </>
    );
  };

  const handleInboxPfr = (id) => {
    const res = allPfr.filter((ele) => ele.msg_id === id);

    const warning = res?.filter((item) => item.report_type === "WARNING");
    const fault = res?.filter((item) => item.report_type === "FAULT");
    setReportData({ ...res[0], warnings: warning, faults: fault, name: userProfile?.name });

    setOpenReportPreview(true);
  };

  const DisplayPfrData = () => {
    return isInbox?.length > 0 ? (
      isInbox?.map((item, index) => {
        return (
          <>
            <div
              className={`row ${styles.dataItems}`}
              style={{ cursor: allPfr.length > 0 ? "pointer" : "default" }}
              onClick={() => {
                allPfr.length > 0 && handleInboxPfr(item.msg_id);
              }}
            >
              <Grid container spacing={0} columns={6}>
                <Grid item xs={1} className={styles.dataItemsText}>
                  {item.msg_type}
                </Grid>
                <Grid item xs={1} className={`${styles.dataItemsText}`}>
                  {item.Model}
                </Grid>
                <Grid item xs={1} className={`${styles.dataItemsText}`}>
                  {item.ac_reg}
                </Grid>
                <Grid item xs={1} className={`${styles.dataItemsText}`}>
                  {item.flight_no}
                </Grid>
                <Grid item xs={1} className={`${styles.dataItemsText}`}>
                  <StyledTooltip
                    title={formatDate(item?.event_datetime?.value, "", localTime)}
                    placement="bottom"
                    enterDelay={500}
                    followCursor={false}
                    classes={{ tooltip: classes.messageTootltip }}
                  >
                    <span>{formatDate(item.event_datetime?.value, "", localTime)}</span>
                  </StyledTooltip>
                </Grid>
                <Grid item xs={1} className={`${styles.dataItemsText}`}>
                  {formatDate(item.sent_datetime?.value, "", localTime)}
                </Grid>
              </Grid>
            </div>
          </>
        );
      })
    ) : !isLoading && isInbox?.length === 0 ? (
      <NoDataAvailable />
    ) : (
      ""
    );
  };

  return (
    <>
      {isLoading && <LinearProgressBar />}
      <div style={{ overflow: "hidden", padding: "0 29px", minHeight: "940px" }} id="main" className={`${styles.main}`}>
        <Header />
        <SwitchDom>
          <Route exact path="/fleet/inbox">
            <SearchFault
              listOperator={listOperator}
              listFaultPriority={listFaultPriority}
              listRepetitive={listRepetitive}
              listAtaChapter={listAtaChapter}
              listPhase={listPhase}
              listACReg={listACReg}
              listAircraftType={listAircraftType}
              isSearchInput={isSearchInput}
              setIsSearchInput={setIsSearchInput}
              isOperator={isOperator}
              setIsOperator={setIsOperator}
              isAtaChapter={isAtaChapter}
              setIsAtaChapter={setIsAtaChapter}
              isFaultPriority={isFaultPriority}
              setIsFaultPriority={setIsFaultPriority}
              isRepetitive={isRepetitive}
              setIsRepetitive={setIsRepetitive}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              isLegs={isLegs}
              setIsLegs={setIsLegs}
              isPhase={isPhase}
              setIsPhase={setIsPhase}
              isACReg={isACReg}
              setIsACReg={setIsACReg}
              isSortBy={isSortBy}
              setIsSortBy={setIsSortBy}
              localTime={localTime}
              isAircraftType={isAircraftType}
              setIsAircraftType={setIsAircraftType}
              tab={tab}
            />
            {(isSearchInput.length > 0 ||
              isACReg !== "All" ||
              isOperator !== "All" ||
              isPhase !== "All" ||
              isAtaChapter !== "All" ||
              isFaultPriority !== "All" ||
              isRepetitive !== "All" ||
              isAircraftType !== "All" ||
              (startDate !== undefined && endDate !== undefined)) && (
              <div style={{ paddingRight: "62px" }}>
                <ActiveFilters
                  isSearchInput={isSearchInput}
                  setIsSearchInput={setIsSearchInput}
                  isACReg={isACReg}
                  setIsACReg={setIsACReg}
                  isOperator={isOperator}
                  setIsOperator={setIsOperator}
                  isAircraftType={isAircraftType}
                  setIsAircraftType={setIsAircraftType}
                  isPhase={isPhase}
                  setIsPhase={setIsPhase}
                  isAtaChapter={isAtaChapter}
                  setIsAtaChapter={setIsAtaChapter}
                  isFaultPriority={isFaultPriority}
                  setIsFaultPriority={setIsFaultPriority}
                  isRepetitive={isRepetitive}
                  setIsRepetitive={setIsRepetitive}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  filter={elevadeActiveFilter.INBOX}
                />
              </div>
            )}
            <SwitchTabs />
            {!isLoading ? (
              <>
                {tab === 1 ? <DisplayDataHeader /> : <DisplayPfrHeader />}
                {tab === 1 ? <DisplayDataFault /> : <DisplayPfrData />}
                {isInbox?.length !== 0 ? (
                  <MUIPagination
                    page={page}
                    setPage={setPage}
                    setPerPage={setPerPage}
                    totalPage={totalPages}
                    classes={{ ul: classes.ul }}
                    totalRows={totalRows}
                    sliceStart={sliceStart}
                  />
                ) : (
                  ""
                )}
              </>
            ) : (
              <AirplaneLoader />
            )}
          </Route>
          <Route exact path="/fleet/inbox/fault-messages">
            <SearchFault
              listOperator={listOperator}
              listFaultPriority={listFaultPriority}
              listRepetitive={listRepetitive}
              listAtaChapter={listAtaChapter}
              listPhase={listPhase}
              listACReg={listACReg}
              listAircraftType={listAircraftType}
              isSearchInput={isSearchInput}
              setIsSearchInput={setIsSearchInput}
              isOperator={isOperator}
              setIsOperator={setIsOperator}
              isAtaChapter={isAtaChapter}
              setIsAtaChapter={setIsAtaChapter}
              isFaultPriority={isFaultPriority}
              setIsFaultPriority={setIsFaultPriority}
              isRepetitive={isRepetitive}
              setIsRepetitive={setIsRepetitive}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              isLegs={isLegs}
              setIsLegs={setIsLegs}
              isPhase={isPhase}
              setIsPhase={setIsPhase}
              isACReg={isACReg}
              setIsACReg={setIsACReg}
              isSortBy={isSortBy}
              setIsSortBy={setIsSortBy}
              isSorting={isSorting}
              setIsSorting={setIsSorting}
              localTime={localTime}
              isAircraftType={isAircraftType}
              setIsAircraftType={setIsAircraftType}
              tab={tab}
            />
            {(isSearchInput.length > 0 ||
              isACReg !== "All" ||
              isOperator !== "All" ||
              isPhase !== "All" ||
              isAtaChapter !== "All" ||
              isFaultPriority !== "All" ||
              isRepetitive !== "All" ||
              isAircraftType !== "All" ||
              (startDate !== undefined && endDate !== undefined)) && (
              <div style={{ paddingRight: "62px" }}>
                <ActiveFilters
                  isSearchInput={isSearchInput}
                  setIsSearchInput={setIsSearchInput}
                  isACReg={isACReg}
                  setIsACReg={setIsACReg}
                  isOperator={isOperator}
                  setIsOperator={setIsOperator}
                  isAircraftType={isAircraftType}
                  setIsAircraftType={setIsAircraftType}
                  isPhase={isPhase}
                  setIsPhase={setIsPhase}
                  isAtaChapter={isAtaChapter}
                  setIsAtaChapter={setIsAtaChapter}
                  isFaultPriority={isFaultPriority}
                  setIsFaultPriority={setIsFaultPriority}
                  isRepetitive={isRepetitive}
                  setIsRepetitive={setIsRepetitive}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  filter={elevadeActiveFilter.FAULTS_MESSAGES}
                />
              </div>
            )}
            <SwitchTabs />
            {!isLoading ? (
              <>
                {tab === 1 ? <DisplayDataHeader /> : <DisplayPfrHeader />}
                {tab === 1 ? <DisplayDataFault /> : <DisplayPfrData />}
                {isInbox?.length !== 0 ? (
                  <MUIPagination
                    page={page}
                    setPage={setPage}
                    setPerPage={setPerPage}
                    totalPage={totalPages}
                    classes={{ ul: classes.ul }}
                    totalRows={totalRows}
                    sliceStart={sliceStart}
                  />
                ) : (
                  ""
                )}
              </>
            ) : (
              <AirplaneLoader />
            )}
          </Route>
          <Route exact path="/fleet/inbox/oooi-reports">
            <SearchOOOI
              listOperator={listOperator}
              listStatus={listStatus}
              listACReg={listACReg}
              isACReg={isACReg}
              setIsACReg={setIsACReg}
              isSearchInput={isSearchInput}
              setIsSearchInput={setIsSearchInput}
              isOperator={isOperator}
              setIsOperator={setIsOperator}
              isStatus={isStatus}
              setIsStatus={setIsStatus}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              localTime={localTime}
            />
            {(isSearchInput.length > 0 ||
              isACReg !== "All" ||
              isOperator !== "All" ||
              isStatus !== "All" ||
              (startDate !== undefined && endDate !== undefined)) && (
              <div style={{ paddingRight: "62px" }}>
                <ActiveFilters
                  isSearchInput={isSearchInput}
                  setIsSearchInput={setIsSearchInput}
                  isACReg={isACReg}
                  setIsACReg={setIsACReg}
                  isOperator={isOperator}
                  setIsOperator={setIsOperator}
                  isStatus={isStatus}
                  setIsStatus={setIsStatus}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  filter={elevadeActiveFilter.OOOI_REPORTS}
                />
              </div>
            )}
            <InboxOOOI
              localTime={localTime}
              listStatus={listStatus}
              setListStatus={setListStatus}
              isSearchInput={isSearchInput}
              setIsSearchInput={setIsSearchInput}
              isACReg={isACReg}
              setIsACReg={setIsACReg}
              isOperator={isOperator}
              setIsOperator={setIsOperator}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              isStatus={isStatus}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </Route>
          <Route exact path="/fleet/inbox/positioning-reports">
            <SearchPositioning
              listOperator={listOperator}
              listACReg={listACReg}
              isACReg={isACReg}
              setIsACReg={setIsACReg}
              isSearchInput={isSearchInput}
              setIsSearchInput={setIsSearchInput}
              isOperator={isOperator}
              setIsOperator={setIsOperator}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              localTime={localTime}
            />
            {(isSearchInput.length > 0 ||
              isACReg !== "All" ||
              isOperator !== "All" ||
              (startDate !== undefined && endDate !== undefined)) && (
              <div style={{ paddingRight: "62px" }}>
                <ActiveFilters
                  isSearchInput={isSearchInput}
                  setIsSearchInput={setIsSearchInput}
                  isACReg={isACReg}
                  setIsACReg={setIsACReg}
                  isOperator={isOperator}
                  setIsOperator={setIsOperator}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  filter={elevadeActiveFilter.POSITIONING_REPORTS}
                />
              </div>
            )}
            <InboxPositioning
              localTime={localTime}
              listStatus={listStatus}
              setListStatus={setListStatus}
              isACReg={isACReg}
              setIsACReg={setIsACReg}
              isSearchInput={isSearchInput}
              setIsSearchInput={setIsSearchInput}
              isOperator={isOperator}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </Route>
          <Route exact path="/fleet/inbox/acms-reports">
            <SearchAcms
              listACReg={listACReg}
              listOperator={listOperator}
              listFaultPriority={listFaultPriority}
              isSearchInput={isSearchInput}
              setIsSearchInput={setIsSearchInput}
              isACReg={isACReg}
              setIsACReg={setIsACReg}
              isOperator={isOperator}
              setIsOperator={setIsOperator}
              isFaultPriority={isFaultPriority}
              setIsFaultPriority={setIsFaultPriority}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              localTime={localTime}
              setVertical={setVertical}
              vertical={vertical}
            />
            {(isSearchInput.length > 0 ||
              isACReg !== "All" ||
              isOperator !== "All" ||
              isFaultPriority !== "All" ||
              (startDate !== undefined && endDate !== undefined)) && (
              <div style={{ paddingRight: "62px" }}>
                <ActiveFilters
                  isSearchInput={isSearchInput}
                  setIsSearchInput={setIsSearchInput}
                  isACReg={isACReg}
                  setIsACReg={setIsACReg}
                  isOperator={isOperator}
                  setIsOperator={setIsOperator}
                  isFaultPriority={isFaultPriority}
                  setIsFaultPriority={setIsFaultPriority}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  filter={elevadeActiveFilter.ACMS_REPORTS}
                />
              </div>
            )}
            <InboxAcms
              localTime={localTime}
              listFaultPriority={listFaultPriority}
              setListFaultPriority={setListFaultPriority}
              isSearchInput={isSearchInput}
              setIsSearchInput={setIsSearchInput}
              isACReg={isACReg}
              setIsACReg={setIsACReg}
              isOperator={isOperator}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              isFaultPriority={isFaultPriority}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              setVertical={setVertical}
              vertical={vertical}
            />
          </Route>
          <Route path="/fleet/inbox/aircraft-reports">
            <AircraftReport />
          </Route>
        </SwitchDom>
      </div>
      {/* Modal For Display Inbox Telex Raw Data */}
      <Modal
        open={showRawDataModal}
        onClose={handleClose}
        BackdropProps={{
          style: {
            boxShadow: "none",
          },
        }}
        sx={{
          padding: "24px",
          zIndex: 9000,
        }}
      >
        <Fade in={showRawDataModal}>
          <Box sx={style}>
            <Grid container columns={12} style={{ marginBottom: "25px" }}>
              <Grid item xs={10}>
                <span
                  style={{
                    fontFamily: "Gotham-medium",
                    color: "#333333",
                    fontSize: "16px",
                  }}
                >
                  Telex Raw Data
                </span>
              </Grid>
              <Grid item xs={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <img
                  src={CloseIcon}
                  height="15px"
                  width="15px"
                  onClick={() => {
                    setShowRawDataModal(false);
                  }}
                  alt="close"
                  style={{
                    cursor: "pointer",
                    filter: "invert(60%) sepia(8%) saturate(23%) hue-rotate(21deg) brightness(84%) contrast(90%)",
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              columns={12}
              style={{ border: "1px solid #E8E8E8", padding: "16px", borderRadius: "4px", minHeight: "120px" }}
            >
              <span style={{ fontFamily: "Gotham-book" }}>{rawData}</span>
            </Grid>
          </Box>
        </Fade>
      </Modal>
      <ReportPreview
        data={reportData}
        openModal={openReportPreview}
        setOpenModal={setOpenReportPreview}
        reportType={"PFR"}
      />
    </>
  );
};

export default Inbox;
