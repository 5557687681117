
import React from 'react';
import { TableHead, TableRow, TableCell, Typography, Box, IconButton } from "@mui/material";
import { camelCaseToSentenceCase } from "../../../utils/string";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from "react";
import { tableRowFontSize, paragraphSpacing } from "../../../../constants/styles";
export default function CustomHead({ dataKeys, sortButton, actions }) {
    return (<TableHead>
            <TableRow sx={{
            backgroundColor: "#F2F7FF",
        }}>
                {/* {actions?.length > 0 && (
            <TableCell
                sx={{
                    height: '100%',
                    width: '10px',
                    paddingLeft: '15px',
                    paddingRight: '5px',
                    paddingBottom: '5px',
                    // display: 'flex',
                    textAlign: 'start'
                }}
            // action cell space
            ><MoreVertIcon /></TableCell>
        )} */}
                {dataKeys.map((key, index) => {
            return (<TableHeader keyx={key} index={index} sortButton={sortButton}/>);
        })}
                {/* <TableCell
        // action cell space
        ></TableCell> */}
            </TableRow>
        </TableHead>);
}
function TableHeader({ keyx, index, sortButton, }) {
    const [isAccend, setIsAccend] = useState(true);
    const sortFunction = sortButton[keyx];
    return (<TableCell key={`pagedgqltablemain-tablecell-header-${keyx}-${index}`}>
            <Box style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        }}>
                <Typography sx={{
            fontWeight: "bold",
            fontSize: tableRowFontSize,
            lineHeight: paragraphSpacing,
        }}>
                    {camelCaseToSentenceCase(keyx)}
                </Typography>
                {sortFunction && (<span>
                        {isAccend ? (<IconButton onClick={() => {
                    setIsAccend(false);
                    sortFunction(isAccend);
                }}>
                                <ExpandMoreIcon />
                            </IconButton>) : (<IconButton onClick={() => {
                    setIsAccend(true);
                    sortFunction(isAccend);
                }}>
                                <ExpandLessIcon />
                            </IconButton>)}
                    </span>)}
            </Box>
        </TableCell>);
}
