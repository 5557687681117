import React, { forwardRef, useState, useRef, useEffect } from "react";
import {
  styled,
  InputBase,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  Modal,
  Box,
  TextField,
  Checkbox,
  LinearProgress,
  linearProgressClasses,
  Typography,
  Autocomplete,
} from "@mui/material";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import PropTypes from "prop-types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid } from "@mui/material";
import IconDustbin from "common/images/icons/elevade/Icon-Dustbin.svg";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import firebase from "firebase/app";
import "firebase/storage";
import Preview from "../dasr/component/Preview";
import { makeStyles } from "@mui/styles";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  width: 250,
  marginTop: 30,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

// Custom styles
const useStyles = makeStyles(() => ({
  splitItems: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    // display: "-webkit-box",
    // "-webkit-box-orient": "vertical",
    // "-webkit-line-clamp": 2, // Number of lines to show
  },
}));

export const InputField = ({ placeholder, formData, setFormData, col, updateArrayColumn, error, ...props }) => {
  const inputRef = useRef();

  // A function to return only integer values
  function onlyAllowInteger(evt) {
    // Get the input value
    let inputValue = evt;

    // Remove any non-numeric characters using a regular expression
    let sanitizedInput = inputValue.replace(/[^0-9]/g, "");

    // Return the sanitized value
    return sanitizedInput;
  }

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      borderRadius: 8,
      position: "relative",
      backgroundColor: "#ffffff",
      border: "1px solid",
      borderColor: !error ? "#E0E3E7" : "#E95048",
      fontSize: 14,
      padding: !props.small ? "14px 16px" : "10px 16px",
      transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
      fontFamily: ["Gotham-Book"].join(","),
      "&:focus": {
        borderColor: theme.palette.primary.main,
      },
    },
  }));

  function onChangeInput(e) {
    const value = props.numbersonly ? onlyAllowInteger(e.target.value) : e.target.value;
    setFormData(updateArrayColumn(col, value));
  }

  return (
    <>
      <FormControl sx={{ width: "100%" }}>
        <BootstrapInput
          key={col}
          id="input-field"
          placeholder={placeholder}
          defaultValue={isNaN(formData?.[0]?.[col]) ? formData?.[0]?.[col]?.toUpperCase() : formData?.[0]?.[col]}
          onBlur={(e) => onChangeInput(e)}
          inputRef={inputRef}
          multiline={props.multiline || false}
          disabled={props.disabled}
          {...props}
        />
        {error && <span style={{ color: "#E95048", fontSize: "14px" }}>This field is mandatory!</span>}
      </FormControl>
    </>
  );
};

export const SelectField = forwardRef(
  ({ options, placeholder, defaultValue = "", value, disabled, error, ...props }, ref) => {
    const StyledSelect = styled(Select)((props) => ({
      "&& legend": {
        width: `${props.label?.length + 1 || 0}ch`,
      },
      input: {
        "&::placeholder": {
          color: "#c3c3c3",
        },
      },
      border: error ? "1px solid #E95048" : "",
      // IconComponent: {
      //   iconButton:<IconDropdownDown />,
      // }
    }));

    return (
      <>
        <StyledSelect
          key={props.col}
          // defaultValue={defaultValue}
          value={props.formData?.[0]?.[props.col]}
          disabled={disabled}
          ref={ref}
          style={{
            borderRadius: "8px",
            border: error ? "1px solid #E95048" : "",
            fontSize: "14px",
          }}
          displayEmpty
          MenuProps={{
            sx: {
              "&& .Mui-selected": {
                color: "red",
              },
            },
          }}
          IconComponent={ExpandMoreIcon}
          {...props}
        >
          {placeholder && (
            <MenuItem value="" disabled sx={{ color: "#c3c3c3" }}>
              {placeholder}
            </MenuItem>
          )}
          {options?.map(({ code, name }, index) => (
            <MenuItem
              value={code}
              key={index}
              selected={props.formData?.[0]?.[props.col] === code}
              sx={{ zIndex: "9999999" }}
            >
              {name}
            </MenuItem>
          ))}
        </StyledSelect>
        {error && <span style={{ color: "#E95048", fontSize: "14px" }}>This field is mandatory!</span>}
      </>
    );
  }
);

SelectField.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.any,
    })
  ),
  placeholder: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export const SelectFieldAutocomplete = forwardRef(
  ({ options, placeholder, defaultValue = "", value, disabled, error, ...props }, ref) => {
    const StyledAutocomplete = styled(Autocomplete)({
      "& .MuiAutocomplete-root": {
        border: "1px solid red",
      },
      "& .MuiAutocomplete-inputRoot": {
        color: "#333333",
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: !error ? "#E0E3E7" : "#E95048",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: !error ? "#1b4cbf" : "#E95048",
        },
      },
    });
    const StyledTextField = styled(TextField)({
      margin: 0,

      "& .MuiInputLabel-root:not(.MuiInputLabel-shrink)": {
        transform: "translate(14px, 8px) scale(1)",
      },
      "& .MuiInputLabel-root.Mui-focused": {
        color: "purple",
      },
      "& .MuiInputBase-root": {
        color: "#333333",
        fontSize: "14px",
        borderRadius: "8px",
        borderColor: "#E0E3E7",
        padding: "8px 16px",
        // border: error ? "1px solid #E95048" : "0px solid #E95048",
      },
    });

    const useStyles = makeStyles({
      paper: {
        boxShadow: "0px 6px 10px 0px #a6a6a2",
      },
    });

    const classes = useStyles();

    return (
      <>
        <StyledAutocomplete
          id={props.col}
          value={value || null}
          disableClearable
          clearOnEscape
          popupIcon={<ExpandMoreIcon />}
          options={options?.map((option) => option.name)}
          onChange={props.onChange}
          classes={{ paper: classes.paper }}
          renderInput={(params) => <StyledTextField {...params} placeholder={placeholder} />}
          disabled={disabled}
        />
        {error && <span style={{ color: "#E95048", fontSize: "14px" }}>This field is mandatory!</span>}
      </>
    );
  }
);

export const InputFieldCalendar = ({ placeholder, formData, setFormData, col, ...props }) => {
  const StyledDatePicker = styled(DatePicker)({
    "& .MuiInputBase-root": {
      padding: 0,
      borderRadius: "8px",
      color: "#333333",
      fontSize: "14px",
      border: props.error ? "1px solid red" : "",
      "& .MuiButtonBase-root": {
        borderRadius: "50%",
        marginRight: "8px",
      },
      "& .MuiInputBase-input": {
        padding: "16px 16px",
        borderRadius: "8px",
        fontSize: "14px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#E0E3E7",
      },
    },
  });

  function updateArrayColumn(array, column, value) {
    return array?.map((item) => {
      // Check if the item has the specified column and update it if exists
      if (item.hasOwnProperty(column)) {
        return { ...item, [column]: value + "T00:00:00.000+00:00" }; // Create a new object with updated column
      } else return { ...item, [column]: value + "T00:00:00.000+00:00" }; // If the specified column doesn't exist, return the original item unchanged
    });
  }

  function onChangeInput(newValue) {
    // Create a Date object from the given date string
    const date = new Date(newValue);

    // Get the components of the date
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const selectedDate = new Date(`${year}-${month}-${day}`);

    // Form the desired format (YYYY-MM-DD)
    const desiredFormat = formatDateToISOString(selectedDate, "fulldate");
    setFormData(updateArrayColumn(formData, col, desiredFormat));
  }

  function formatDateToISOString(givenDate, type) {
    // Get current time
    const currentTime = new Date(givenDate);

    const year = currentTime.getFullYear();
    const month = String(currentTime.getMonth() + 1).padStart(2, "0");
    const day = String(currentTime.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StyledDatePicker
          placeholder={placeholder}
          format="LL"
          disableFuture={props.disableFuture}
          value={dayjs(`${formData[0][col]}`)}
          defaultValue={dayjs(`${formData[0][col]}`)}
          onChange={(newValue) => {
            onChangeInput(newValue);
          }}
          // renderInput={(params) => <TextField {...params} error={false} />}
          slotProps={{
            textField: {
              fullWidth: props.fullWidth,
              variant: "outlined",
              placeholder: "hola",
            },
          }}
          minDate={
            col === "flightDaysStartDate"
              ? dayjs(`${formData[0].raisedDate}`)
              : props.startDate
              ? dayjs(`${props.startDate}`)
              : ""
          }
          disabled={props.disabled}
        />
      </LocalizationProvider>
      {props.error && <span style={{ color: "#E95048", fontSize: "14px" }}>This field is mandatory!</span>}
    </>
  );
};

export const BasicTimePicker = ({ ...props }) => {
  const [value, setValue] = React.useState(dayjs(props.formData[0]?.etsTime));

  const StyledTimePicker = styled(TimePicker)({
    "& .MuiInputBase-root": {
      padding: 0,
      borderRadius: "8px",
      color: "#333333",
      fontSize: "14px",
      marginTop: "-8px",
      border: props.error ? "1px solid red" : "",
      "& .MuiButtonBase-root": {
        borderRadius: "50%",
        marginRight: "8px",
      },
      "& .MuiInputBase-input": {
        padding: "16px 16px",
        // borderRadius: "8px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#E0E3E7",
      },
    },
  });

  function onChangeInput(timestamp) {
    let date = new Date(timestamp);
    let year = date.getFullYear();
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);
    let hours = ("0" + date.getHours()).slice(-2);
    let minutes = ("0" + date.getMinutes()).slice(-2);
    let seconds = ("0" + date.getSeconds()).slice(-2);
    let milliseconds = ("00" + date.getMilliseconds()).slice(-3);

    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
    props.setFormData(updateArrayColumn(props.formData, props.col, formattedDate));
  }

  function updateArrayColumn(array, column, value) {
    return array?.map((item) => {
      // Check if the item has the specified column and update it if exists
      if (item.hasOwnProperty(column)) {
        return { ...item, [column]: value }; // Create a new object with updated column
      } else return { ...item, [column]: value }; // If the specified column doesn't exist, return the original item unchanged
    });
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["TimePicker"]}>
          <StyledTimePicker
            value={dayjs(value) || null}
            // renderInput={(params) => <TextField {...params} />}
            slotProps={{
              textField: {
                fullWidth: true,
                error: props.error ? true : false,
              },
            }}
            onAccept={(newValue) => {
              onChangeInput(newValue);
              setValue(newValue);
            }}
            closeOnSelect={false}
            ampm={false}
            timeSteps={{ hours: 1, minutes: 15 }}
            disabled={props.disabled}
          />
        </DemoContainer>
      </LocalizationProvider>
      {props.error && <span style={{ color: "#E95048", fontSize: "14px" }}>This field is mandatory!</span>}
    </>
  );
};

export const RadioField = forwardRef(
  ({ options, value, setValue, formData, setFormData, col, updateArrayColumn, error }, ref) => {
    const handleChange = (event) => {
      setValue(
        event.target.value === "true" || event.target.value === "false"
          ? JSON.parse(event.target.value)
          : event.target.value
      );
      setFormData(
        updateArrayColumn(
          col,
          event.target.value === "true" || event.target.value === "false"
            ? JSON.parse(event.target.value)
            : event.target.value
        )
      );
    };

    return (
      <>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={handleChange}
          sx={{
            border: error ? "1px solid #E95048" : "0px solid #E95048",
            borderRadius: "8px",
          }}
        >
          {options.map(({ code, name }, index) => (
            <FormControlLabel
              checked={code === formData?.[0]?.[col] || false}
              value={code}
              control={<Radio sx={{ color: "#D1D1D1" }} />}
              label={name}
              sx={{ marginRight: "24px", marginLeft: "0px", marginTop: "10px" }}
            />
          ))}
        </RadioGroup>
        {error && <span style={{ color: "#E95048", fontSize: "14px" }}>This field is mandatory!</span>}
      </>
    );
  }
);

export const CheckBoxField = forwardRef(({ id, selected, setSelected, disabled, ...props }, ref) => {
  const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: 4,
    width: 20,
    height: 20,
    marginTop: props.marginTop ? props.marginTop : 0,
    boxShadow:
      theme.palette.mode === "dark"
        ? "0 0 0 1px rgb(16 22 26 / 40%)"
        : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
    backgroundImage:
      theme.palette.mode === "dark"
        ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
        : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    ".Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: theme.palette.mode === "dark" ? "rgba(57,75,89,.5)" : "rgba(206,217,224,.5)",
    },
  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: "#137cbd",
    backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 20,
      height: 20,
      border: "0px solid red",
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    boxShadow: "none",
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  });

  const handleChange = (event) => {
    if (event.target.checked) {
      setSelected([...selected, event.target.value]);
    } else setSelected(selected?.filter((e) => e !== id));
  };

  function BpCheckbox(props) {
    return (
      <Checkbox
        sx={{
          "&:hover": { bgcolor: "transparent" },
        }}
        value={id}
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        inputProps={{ "aria-label": "Checkbox demo" }}
        checked={selected.includes(id) ? true : false}
        onChange={handleChange}
        disabled={disabled}
        {...props}
      />
    );
  }

  return <BpCheckbox />;
});

export const CheckBoxFieldMock = forwardRef(({ checkedVal, setCheckedVal, isCheckedAll, setIsCheckedAll }, ref) => {
  const [checked, setChecked] = useState(isCheckedAll);
  const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: 4,
    width: 20,
    height: 20,
    boxShadow:
      theme.palette.mode === "dark"
        ? "0 0 0 1px rgb(16 22 26 / 40%)"
        : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
    backgroundImage:
      theme.palette.mode === "dark"
        ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
        : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    ".Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: theme.palette.mode === "dark" ? "rgba(57,75,89,.5)" : "rgba(206,217,224,.5)",
    },
  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: "#137cbd",
    backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 20,
      height: 20,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    boxShadow: "none",
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  });

  const handleChange = (event) => {
    if (event.target.checked) {
      setChecked(true);
      setIsCheckedAll(true);
    } else {
      setChecked(false);
      setIsCheckedAll(false);
    }
  };

  function BpCheckbox(props) {
    return (
      <Checkbox
        sx={{
          "&:hover": { bgcolor: "transparent" },
          marginTop: "-11px",
          marginBottom: "-10px",
        }}
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        inputProps={{ "aria-label": "Checkbox demo" }}
        checked={checked}
        onChange={handleChange}
        {...props}
      />
    );
  }

  return <BpCheckbox />;
});

export const BasicCheckbox = ({ ...props }) => {
  const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: 4,
    width: 20,
    height: 20,
    boxShadow:
      theme.palette.mode === "dark"
        ? "0 0 0 1px rgb(16 22 26 / 40%)"
        : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
    backgroundImage:
      theme.palette.mode === "dark"
        ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
        : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    ".Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: theme.palette.mode === "dark" ? "rgba(57,75,89,.5)" : "rgba(206,217,224,.5)",
    },
  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: "#137cbd",
    backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 20,
      height: 20,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    boxShadow: "none",
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  });

  const handleChange = (event) => {
    if (event.target.checked) {
      props.setCheck(true);
    } else {
      props.setCheck(false);
    }
  };

  function BpCheckbox() {
    return (
      <Checkbox
        sx={{
          "&:hover": { bgcolor: "transparent" },
          marginTop: "-11px",
          marginBottom: "-10px",
        }}
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        inputProps={{ "aria-label": "Checkbox demo" }}
        checked={props.checked}
        onChange={handleChange}
      />
    );
  }

  return <BpCheckbox />;
};

export const FileUploadComponent = ({ placeholder, setFile, formData, errorForm }) => {
  const [selectedFile, setSelectedFile] = useState(formData?.[0].attachment?.File.name);
  const [previewUrl, setPreviewUrl] = useState(formData?.[0].attachment?.Link);
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const storageRef = firebase.storage().ref();
  const [uploadProgress, setUploadProgress] = useState(0);

  const classes = useStyles();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  const acceptedFileTypes = ["image/jpeg", "image/png", "image/tiff"];
  const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (!file) {
      setSelectedFile(null);
      setPreviewUrl(null);
      return;
    }

    if (file.size <= maxFileSize && acceptedFileTypes.includes(file.type)) {
      setError(false);
      setSelectedFile(file);
      setPreviewUrl(URL.createObjectURL(file));
      UploadFile(file);
    } else {
      setError(true);
      // setSelectedFile(null);
      // setPreviewUrl(null);
    }
  };

  async function UploadFile(file) {
    if (file) {
      const uploadTask = storageRef.child(`dasr/uploads/${file.name}`).put(file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error("Error uploading file:", error);
        },
        async () => {
          const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
          setFile({ File: file, Link: downloadURL });
          // setFile(null);
          setUploadProgress(0);
        }
      );
    }
  }

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const file = event.dataTransfer.files[0];

    if (!file) {
      setSelectedFile(null);
      setPreviewUrl(null);
      return;
    }

    if (file.size <= maxFileSize && acceptedFileTypes.includes(file.type)) {
      setError(false);
      setSelectedFile(file);
      setPreviewUrl(URL.createObjectURL(file));
      setFile(file);
    } else {
      setError(true);
      setSelectedFile(null);
      setPreviewUrl(null);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDelete = () => {
    setSelectedFile(null);
    setPreviewUrl(null);
  };

  function splitToChucks(str) {
    // Split the string into chunks of 20 characters
    let chunkSize = 28;
    let chunks = [];
    for (let i = 0; i < str?.length; i += chunkSize) {
      chunks.push(str?.slice(i, i + chunkSize));
    }

    return (
      <div
        onClick={handleOpen}
        style={{
          cursor: "pointer",
          height: "100%",
          display: "flex",
          alignItems: "center",
          color: "#3F79F6",
        }}
      >
        {/* {firstChunk}
        <br />
        {otherChunks} */}
        <Typography className={classes.splitItems}>{str?.fileName}</Typography>
      </div>
    );
  }

  return (
    <div>
      <label
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        style={{
          maxWidth: "498px",
          border: !errorForm ? "1px solid #E8E8E8" : "1px solid #E95048",
          padding: "22px",
          borderRadius: "8px",
          color: "#C3C3C3",
          display: "flex",
          justifyContent: "center",
          cursor: "pointer",
        }}
      >
        <span style={{ marginTop: "12px" }}>{placeholder}</span>
        <input type="file" onChange={handleFileChange} style={{ display: "none" }} />
        <span
          style={{
            padding: "12px 16px",
            borderRadius: "8px",
            backgroundColor: "#088FD1",
            marginLeft: "8px",
            color: "#ffffff",
            fontFamily: "Gotham-Medium",
            fontSize: "12px",
          }}
        >
          Upload File
        </span>
      </label>
      {previewUrl?.length > 0 && (
        <div
          style={{
            maxWidth: "500px",
            border: "1px solid #E8E8E8",
            padding: "22px",
            borderRadius: "8px",
            color: "#3F79F6",
          }}
        >
          <Grid container columns={4} sx={{ height: "65px" }}>
            {/* {previewUrl.map((data, index) => (
              <>
                <Grid item xs={1} key={index}>
                  <div style={{ maxWidth: "100px", maxHeight: "65px", overflow: "hidden", borderRadius: "4px" }}>
                    <img src={data.url} alt={`Preview ${index + 1}`} style={{ width: "100px", height: "65px" }} />
                  </div>
                </Grid>
                <Grid item xs={2.5} key={index}>
                  {data.title}
                </Grid>
                <Grid item xs={0.5} key={index} sx={{paddingTop: "14px"}}>
                  <div onClick={() => handleDelete(index)} style={{padding: "8px 12px", backgroundColor: "#E8E8E8", textAlign: "center", borderRadius: "4px", cursor: "pointer"}}>
                    <img src={IconDustbin} alt="" />
                  </div>
                </Grid>
              </>
            ))} */}
            {selectedFile && (
              <>
                <Grid item xs={1}>
                  <div
                    style={{
                      maxWidth: "100px",
                      maxHeight: "65px",
                      overflow: "hidden",
                      borderRadius: "4px",
                    }}
                  >
                    <img
                      src={previewUrl}
                      alt={`Preview`}
                      style={{
                        width: "100px",
                        height: "65px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={2.5}>
                  {uploadProgress === 0 ? (
                    splitToChucks(selectedFile)
                  ) : (
                    <BorderLinearProgress variant="determinate" value={uploadProgress} />
                  )}
                  {/* {<BorderLinearProgress variant="determinate" value={uploadProgress} />} */}
                </Grid>
                <Grid item xs={0.5} sx={{ paddingTop: "14px" }}>
                  <div
                    onClick={handleDelete}
                    style={{
                      padding: "8px 12px",
                      backgroundColor: "#E8E8E8",
                      textAlign: "center",
                      borderRadius: "4px",
                      cursor: "pointer",
                    }}
                  >
                    <img src={IconDustbin} alt="" />
                  </div>
                </Grid>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <img
                      src={previewUrl}
                      alt={`Preview`}
                      style={{
                        width: "100%",
                        maxWidth: "1000px",
                        height: "100%",
                        maxHeight: "700px",
                      }}
                    />
                  </Box>
                </Modal>
              </>
            )}
          </Grid>
        </div>
      )}
      <span
        style={{
          marginTop: "12px",
          fontSize: "12px",
          color: !error ? "#C3C3C3" : "#E95048",
          fontStyle: "italic",
        }}
      >
        Only one file (.jpeg, .png, .tif) is allowed. Max 5MB per document
      </span>
      {errorForm && (
        <span style={{ color: "#E95048", fontSize: "14px" }}>
          <br />
          This field is mandatory!
        </span>
      )}
    </div>
  );
};

export const NewFileUploadComponent = ({ formData, file, setFile, errorForm, ...props }) => {
  const [selectedFiles, setSelectedFiles] = useState(formData?.[0].attachment);
  const [previewData, setPreviewData] = useState(formData?.[0].attachment);
  const [uploadedFile, setUploadedFile] = useState([]);
  const [open, setOpen] = useState(false);
  const [previewUrl, setPreviewUrl] = useState();
  const [error, setError] = useState(false);
  const [existingFileNames, setExistingFileNames] = useState([]);
  const storageRef = firebase.storage().ref();
  const [uploadProgress, setUploadProgress] = useState(0);

  const acceptedFileTypes = ["image/jpeg", "image/png", "image/tiff"];
  const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes
  let array = [file?.filter((obj) => Object.keys(obj).length !== 0)];

  useEffect(() => {
    if (uploadedFile.length > 0) {
      array.push(uploadedFile);
      // setFile(array.flat());
    }
    // eslint-disable-next-line
  }, [uploadedFile]);

  useEffect(() => {
    setSelectedFiles(formData?.[0]?.attachment?.filter((e) => e.fileName !== ""));
    setPreviewData(formData?.[0]?.attachment?.filter((e) => e.fileName !== ""));
  }, [formData]);

  useEffect(() => {
    // if (selectedFiles.length > 0) setSelectedFiles(selectedFiles?.filter((e) => e.fileName !== ""));
    // if (previewData.length > 0) setPreviewData(previewData?.filter((e) => e.fileName !== ""));
  }, [formData]);

  async function UploadFile(file) {
    if (file) {
      const uploadTask = storageRef.child(`dasr/uploads/${file.name}`).put(file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error("Error uploading file:", error);
        },
        async () => {
          const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
          setUploadedFile([
            // ...selectedFiles,
            // ...uploadedFile,
            { fileName: file.name, fileType: file.type, fileLink: downloadURL },
          ]);
          setFile([...selectedFiles, { fileName: file.name, fileType: file.type, fileLink: downloadURL }]);
          setUploadProgress(0);
        }
      );
    }
  }

  async function UploadRenamedFile(file) {
    if (file) {
      // Generate a timestamp string.
      const timestamp = new Date().toISOString().replace(/:/g, "-");

      // Append the timestamp to the original filename.
      // This assumes file.name is a string like 'example.txt'.
      const fileNameWithTimestamp = `${file.name.split(".").slice(0, -1).join(".")}_${timestamp}.${file.name
        .split(".")
        .pop()}`;

      // Start the upload process with the new filename.
      const uploadTask = storageRef.child(`dasr/uploads/${fileNameWithTimestamp}`).put(file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error("Error uploading file:", error);
        },
        async () => {
          const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
          setUploadedFile([
            // ...selectedFiles,
            // ...uploadedFile,
            { fileName: file.name, fileType: file.type, fileLink: downloadURL },
          ]);
          setFile([...selectedFiles, { fileName: file.name, fileType: file.type, fileLink: downloadURL }]);
          setUploadProgress(0);
        }
      );
    }
  }

  const handleFileChange = (event) => {
    let fileError = false;
    const files = event.target.files;
    for (let i = 0; i < event.target.files.length; i++) {
      let exist = existingFileNames.includes(event.target.files[i].name);
      if (
        acceptedFileTypes.includes(event.target.files[i].type) &&
        event.target.files[i].size <= maxFileSize &&
        !exist
      ) {
        setError(false);
        fileError = false;
        // setExistingFileNames([...existingFileNames, event.target.files[i].name]);
        setExistingFileNames([event.target.files[i].name]);
        UploadRenamedFile(event.target.files[i]);
      } else {
        fileError = true;
        setError(true);
      }
    }
    let newFiles = [];
    if (!fileError) {
      newFiles = Array.from(files).map((file) => ({
        file,
        title: file.name,
      }));
    }

    // Update selectedFiles state with new file objects
    setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, ...newFiles]);

    // Create preview URLs and data for the selected files and update previewData state
    const newPreviewData = newFiles.map((fileObj) => ({
      file: fileObj.file,
      title: fileObj.title,
      url: URL.createObjectURL(fileObj.file),
    }));

    setPreviewData((prevPreviewData) => [...prevPreviewData, ...newPreviewData]);
  };

  const handleDrop = (event) => {
    // const files = event.dataTransfer.files;
    event.preventDefault();
    event.stopPropagation();

    let fileError = false;
    const files = event.dataTransfer.files;
    for (let i = 0; i < event.dataTransfer.files.length; i++) {
      let exist = existingFileNames.includes(event.dataTransfer.files[i].name);
      if (
        acceptedFileTypes.includes(event.dataTransfer.files[i].type) &&
        event.dataTransfer.files[i].size <= maxFileSize &&
        !exist
      ) {
        setError(false);
        fileError = false;
        // setExistingFileNames([...existingFileNames, event.dataTransfer.files[i].name]);
        setExistingFileNames([event.dataTransfer.files[i].name]);
        UploadFile(event.dataTransfer.files[i]);
      } else {
        fileError = true;
        setError(true);
      }
    }

    let newFiles = [];
    if (!fileError) {
      newFiles = Array.from(files).map((file) => ({
        file,
        title: file.name,
      }));
    }

    // // Update selectedFiles state with new file objects
    setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, ...newFiles]);

    // // Create preview URLs and data for the dropped files and update previewData state
    const newPreviewData = newFiles.map((fileObj) => ({
      file: fileObj.file,
      title: fileObj.title,
      url: URL.createObjectURL(fileObj.file),
    }));

    setPreviewData((prevPreviewData) => [...prevPreviewData, ...newPreviewData]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDelete = (index, data) => {
    const updatedSelectedFiles = [...selectedFiles];
    const updatedPreviewData = [...previewData];

    updatedSelectedFiles.splice(index, 1);
    updatedPreviewData.splice(index, 1);
    existingFileNames.splice(index, 1);
    uploadedFile.splice(index, 1);

    setFile(updatedPreviewData);

    setSelectedFiles(updatedSelectedFiles);
    setPreviewData(updatedPreviewData);
    setError(false);
  };

  function splitToChucks(str) {
    let strChar = str.title || str.fileName;
    // Split the string into chunks of 20 characters
    let chunkSize = 26;
    let chunks = [];
    for (let i = 0; i < strChar?.length; i += chunkSize) {
      chunks.push(strChar.slice(i, i + chunkSize));
    }

    // Store the first chunk in one variable
    let firstChunk = chunks[0];

    // Store the remaining chunks in another variable
    let otherChunks = chunks.slice(1).join("");

    return (
      <div
        onClick={() => handleOpen(str.url || str.fileLink)}
        style={{
          cursor: "pointer",
          height: "100%",
          display: "flex",
          alignItems: "center",
          color: "#3F79F6",
          marginLeft: "6px",
        }}
      >
        {firstChunk}
        <br />
        {otherChunks}
      </div>
    );
  }

  function handleOpen(url) {
    setPreviewUrl(url);
    setOpen(true);
  }

  return (
    <div>
      <label
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        style={{
          maxWidth: "498px",
          border: !errorForm && !error ? "1px solid #E8E8E8" : "1px solid #E95048",
          padding: "22px",
          borderRadius: "8px",
          color: "#C3C3C3",
          display: "flex",
          justifyContent: "center",
          cursor: "pointer",
        }}
      >
        <span style={{ marginTop: "12px", fontSize: "16px" }}>Drag & drop files here or</span>
        <input
          type="file"
          onChange={handleFileChange}
          style={{ display: "none" }}
          disabled={uploadProgress > 0 ? true : false}
        />
        <span
          style={{
            padding: "12px 16px",
            borderRadius: "8px",
            backgroundColor: uploadProgress > 0 ? "#E8E8E8" : "#088FD1",
            marginLeft: "8px",
            color: uploadProgress > 0 ? "#C3C3C3" : "#ffffff",
            fontFamily: "Gotham-Medium",
            fontSize: "12px",
          }}
        >
          Upload File
        </span>
      </label>
      <span
        style={{
          marginTop: "12px",
          fontSize: "12px",
          color: !error ? "#C3C3C3" : "#E95048",
          fontStyle: "italic",
        }}
      >
        Only file (.jpeg, .png, .tif) is allowed. Max 5MB per document
      </span>
      {errorForm && (
        <span style={{ color: "#E95048", fontSize: "14px" }}>
          <br />
          This field is mandatory!
        </span>
      )}
      <div>
        {previewData?.length > 0 &&
          previewData[0]?.fileName !== "" &&
          previewData
            ?.filter((obj) => Object.keys(obj).length !== 0)
            ?.map((data, index) => (
              <div
                style={{
                  maxWidth: "500px",
                  border: "1px solid #E8E8E8",
                  padding: "22px",
                  borderRadius: "8px",
                  color: "#3F79F6",
                  margin: "4px 0",
                }}
                key={index}
              >
                <>
                  <Grid container columns={4} sx={{ height: "65px" }}>
                    <Grid item xs={1}>
                      <div
                        style={{
                          maxWidth: "100px",
                          maxHeight: "65px",
                          overflow: "hidden",
                          borderRadius: "4px",
                        }}
                      >
                        {data.fileType !== "application/pdf" ? (
                          <img
                            src={data.url || data.fileLink}
                            alt={`Preview ${index + 1}`}
                            style={{
                              width: "100px",
                              height: "65px",
                              objectFit: "cover",
                            }}
                          />
                        ) : (
                          "PDF"
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={2.5}>
                      {uploadProgress > 0 && existingFileNames[0] === data.title ? (
                        <BorderLinearProgress variant="determinate" value={uploadProgress} />
                      ) : (
                        splitToChucks(data)
                      )}
                    </Grid>
                    <Grid item xs={0.5} sx={{ paddingTop: "14px" }}>
                      <div
                        onClick={() => handleDelete(index, data)}
                        style={{
                          padding: "8px 12px",
                          backgroundColor: "#E8E8E8",
                          textAlign: "center",
                          borderRadius: "4px",
                          cursor: "pointer",
                        }}
                      >
                        <img src={IconDustbin} alt="" />
                      </div>
                    </Grid>
                  </Grid>
                </>
              </div>
            ))}
        {/* <Modal
          open={open}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={styles.modal}
          disableAutoFocus={true}
          hideBackdrop // Disable the backdrop color/image
          disableEnforceFocus // Let the user focus on elements outside the dialog
          style={{ position: 'absolute', transform: "translate(-50%, -50%)", top: "50%", left: "30%", width: "100%" }} // This was the key point, reset the position of the dialog, so the user can interact with other elements
          disableBackdropClick // Remove the backdrop click (just to be sure)
          // style={customStyles}
        >
          <Box sx={style}>
            <img
              src={IconClose}
              alt="icon-close"
              style={{ position: "absolute", right: "-15px", top: "-15px", cursor: "pointer" }}
              onClick={() => setOpen(false)}
            />
            <img
              src={previewUrl}
              alt={`Preview`}
              style={{
                width: "100%",
                maxWidth: "1000px",
                height: "100%",
                maxHeight: "700px",
              }}
            />
          </Box>
        </Modal> */}
        <Preview open={open} setOpen={setOpen} fileLink={previewUrl} />
      </div>
    </div>
  );
};

export default FileUploadComponent;
