
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography, Box, } from "@mui/material";
import { createContext, useContext, useState } from "react";
const DialogContext = createContext({ openDialog: () => { } });
export default function DialogInterface({ children }) {
    const [isOpen, setIsOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [fullWidth, setFullWidth] = useState(true);
    const [maxWidth, setMaxWidth] = useState('sm');
    const [message, setMessage] = useState('');
    const [options, setOptions] = useState();
    function openDialog(title, message, opts) {
        setIsOpen(true);
        setTitle(title);
        setMessage(message);
        setOptions(opts);
    }
    function closeDialog() {
        setIsOpen(false);
        if (options?.onClose) {
            options.onClose();
        }
    }
    return (<DialogContext.Provider value={{ openDialog }}>
        <Dialog fullWidth={fullWidth} maxWidth={maxWidth} open={isOpen} onClose={() => {
            closeDialog();
        }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            m: 'auto',
            marginTop: "1em",
            height: 80,
            width: 80,
        }}>
            <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M40 26.6667V40M40 53.3333H40.0333M70 40C70 56.5685 56.5685 70 40 70C23.4315 70 10 56.5685 10 40C10 23.4315 23.4315 10 40 10C56.5685 10 70 23.4315 70 40Z" stroke="#F5BE59" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            </Box>
          <DialogTitle sx={{
            display: 'flex',
            flexDirection: 'column',
            m: 'auto'
        }}>
            <Typography style={{ fontWeight: 'bold', }}>{title}</Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText sx={{
            display: 'flex',
            flexDirection: 'column',
            m: 'auto',
            textAlign: 'center'
        }}>
              {message}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{
            display: 'flex',
            flexDirection: 'row',
            m: 'auto',
            marginBottom: '1em'
        }}>
            <Button onClick={() => {
            closeDialog();
        }} autoFocus>
                <Typography style={{
            color: 'var(--light-mode-main-colours-main-dark-333333, #333)',
            textAlign: 'center',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '350',
            lineHeight: '20px',
            margin: '0.2em',
            textTransform: 'capitalize'
        }}>
                Cancel
                </Typography>
            </Button>
            {options?.onConfirm && (<Button style={{
                borderRadius: '8px',
                background: 'var(--light-mode-main-colours-main-blue-088-fd-1, #088FD1)',
                marginLeft: '2em'
            }} onClick={() => {
                closeDialog();
                if (options?.onConfirm) {
                    options.onConfirm();
                }
            }}>
                <Typography style={{
                color: 'var(--light-mode-main-colours-white-ffffff, #FFF)',
                textAlign: 'center',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '350',
                lineHeight: '20px',
                margin: '0.2em',
                textTransform: 'capitalize'
            }}>
                Replace Equipment  
                </Typography>  
            </Button>)}
          </DialogActions>
        </Dialog>
        {children}
      </DialogContext.Provider>);
}
export function useDialog() {
    const { openDialog } = useContext(DialogContext);
    return openDialog;
}
