import React from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styles from "./Analytics.module.css";
// import Iframe from "react-iframe";

const Analytics = () => {
  const [isAdvanceFilter, setIsAdvanceFilter] = useState(false);

  const location = useLocation();
  const paramsString = location.search;
  const searchParams = new URLSearchParams(paramsString);
  let openLegend = "false";
  if (searchParams.get("open")) openLegend = searchParams.get("open");

  useEffect(() => {
    if (searchParams.get("open")) setIsAdvanceFilter(false);
    // eslint-disable-next-line
  }, [openLegend]);

  let paddingRight = "6px";
  if (isAdvanceFilter && openLegend === "false") paddingRight = "304px";

  return (
    <>
      <div
        style={{ overflow: "hidden", marginLeft: "17px", paddingRight: `${paddingRight}` }}
        id="main"
        className={`${styles.container}`}
      >
        <iframe
          title="analytics"
          width="100%"
          height="1020px"
          src="https://datastudio.google.com/embed/reporting/f62002a4-1b2e-4335-8cd8-5ae0becaa402/page/gUjDC"
          frameborder="0"
          style={{ border: "0px" }}
          allowFullScreen
        ></iframe>
      </div>
    </>
  );
};

export default Analytics;
