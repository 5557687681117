import React, { forwardRef } from "react";
import { RadioGroup, FormControlLabel, Radio } from "@mui/material";

const AdeRadioGroup = forwardRef(
  ({ label, name, defaultValue = "", options, disabled, ...props }, ref) => {
    return (
      <RadioGroup
        row
        aria-label={label}
        name={name}
        // defaultValue={defaultValue || options[0].value}
        defaultValue={defaultValue}
        {...props}
      >
        {options.map(({ value, display }) => (
          <FormControlLabel
            disabled={disabled}
            key={value}
            value={value}
            control={<Radio ref={ref} />}
            label={display}
          />
        ))}
      </RadioGroup>
    );
  }
);

export default AdeRadioGroup;
