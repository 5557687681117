import React from 'react';
import { Grid } from "@mui/material";
// import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
// import { styled } from "@mui/material/styles";
import FleetCardHigh from "common/images/icons/elevade/Fleet-Card-High.svg";
import FleetCardMed from "common/images/icons/elevade/Fleet-Card-Med.svg";
import FleetCardLow from "common/images/icons/elevade/Fleet-Card-Low.svg";
import FleetCardOk from "common/images/icons/elevade/Fleet-Card-Ok.svg";
import FleetCardUndefined from "common/images/icons/elevade/Fleet-Card-Undefined.svg";
import FleetCardNuisance from "common/images/icons/elevade/Fleet-Card-Nuisance.svg";
import FleetCardNone from "common/images/icons/elevade/Fleet-Card-None.svg";
import OOOIOut from "common/images/icons/elevade/OOOI-OUT.svg";
import OOOIOff from "common/images/icons/elevade/OOOI-OFF.svg";
import OOOIOn from "common/images/icons/elevade/OOOI-ON.svg";
import OOOIIn from "common/images/icons/elevade/OOOI-IN.svg";
import OOOITng from "common/images/icons/elevade/OOOI-T&G.svg";
import OOOIDr from "common/images/icons/elevade/OOOI-DR.svg";
import OOOIMnt from "common/images/icons/elevade/OOOI-MNT.svg";
// import ACMSLow from "common/images/icons/elevade/ACMS-Low.svg";
// import ACMSHigh from "common/images/icons/elevade/ACMS-High.svg";
// import ACMSUnavailable from "common/images/icons/elevade/ACMS-Unavailable.svg";
// import PredictiveAvailable from "common/images/icons/elevade/Predictive-Available.svg";
// import PredictiveUnavailable from "common/images/icons/elevade/Predictive-Unavailable.svg";
// import UplinkAvailable from "common/images/icons/elevade/Uplink-Available.svg";
// import UplinkUnavailable from "common/images/icons/elevade/Uplink-Unavailable.svg";
// import WarningCircle from "common/images/icons/elevade/WarningCircle.svg";
import CaretDoubleRight from "common/images/icons/elevade/CaretDoubleRight.svg";
import styles from "./Legend.module.css";
// import Help from "common/images/icons/elevade/Help-icon.png";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useRef } from "react";

const Legend = ({ page, openLegend, setOpenLegend }) => {
  const location = useLocation();
  // const paramsString = location.search;
  // const searchParams = new URLSearchParams(paramsString);
  const legendRef = useRef(null);

  // const open = searchParams.get("open");
  function useOutsideClicker(ref, component) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (component === "legendRef") setOpenLegend(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, component]);
  }

  useOutsideClicker(legendRef, "legendRef");

  return (
    openLegend && (
      <>
        <div
          id="myLegend"
          className={`${styles.legend}`}
          style={{ right: `${openLegend ? "0px" : "-245px"}` }}
          ref={legendRef}
        >
          <Grid
            container
            columns={4}
            className={`${styles.legendItems}`}
            id="legendItem"
          >
            <Grid item xs={3} sx={{ paddingBottom: "8px" }}>
              Legend
            </Grid>
            <Grid item xs={1} onClick={() => setOpenLegend(false)}>
              <Link to={`${location.pathname}?open=false`}>
                <img
                  src={CaretDoubleRight}
                  alt=""
                  className={`${styles.caretIcon}`}
                />
              </Link>
            </Grid>
          </Grid>
          <div className={`${styles.legendBody}`} id="legendItemBody">
            <Grid container columns={4}>
              <Grid item xs={4} sx={{ fontSize: "16px", padding: "8px 0" }}>
                Fault Priority
              </Grid>
              <Grid
                item
                xs={4}
                sx={{ fontSize: "14px", color: "#838383", padding: "8px 0" }}
              >
                List of fault priority icon
              </Grid>
              <Grid item xs={1} sx={{ padding: "8px 0" }}>
                <img src={FleetCardHigh} alt="high" />
              </Grid>
              <Grid
                item
                xs={3}
                sx={{ padding: "8px 0" }}
                className={`${styles.description}`}
              >
                High
              </Grid>
              <Grid item xs={1} sx={{ padding: "8px 0" }}>
                <img src={FleetCardMed} alt="high" />
              </Grid>
              <Grid
                item
                xs={3}
                sx={{ padding: "8px 0" }}
                className={`${styles.description}`}
              >
                Medium
              </Grid>
              <Grid item xs={1} sx={{ padding: "8px 0" }}>
                <img src={FleetCardLow} alt="high" />
              </Grid>
              <Grid
                item
                xs={3}
                sx={{ padding: "8px 0" }}
                className={`${styles.description}`}
              >
                Low
              </Grid>
              <Grid item xs={1} sx={{ padding: "8px 0" }}>
                <img src={FleetCardOk} alt="high" />
              </Grid>
              <Grid
                item
                xs={3}
                sx={{ padding: "8px 0" }}
                className={`${styles.description}`}
              >
                Okay
              </Grid>
              <Grid item xs={1} sx={{ padding: "8px 0" }}>
                <img src={FleetCardNuisance} alt="high" />
              </Grid>
              <Grid
                item
                xs={3}
                sx={{ padding: "8px 0" }}
                className={`${styles.description}`}
              >
                Nuisance
              </Grid>
              <Grid item xs={1} sx={{ padding: "8px 0" }}>
                <img src={FleetCardNone} alt="high" />
              </Grid>
              <Grid
                item
                xs={3}
                sx={{ padding: "8px 0" }}
                className={`${styles.description}`}
              >
                None
              </Grid>
              <Grid item xs={1} sx={{ padding: "8px 0" }}>
                <img src={FleetCardUndefined} alt="high" />
              </Grid>
              <Grid
                item
                xs={3}
                sx={{ padding: "8px 0" }}
                className={`${styles.description}`}
              >
                Undefined
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  borderBottom: "1px solid #D1D1D1",
                  padding: "8px 0",
                  marginBottom: "16px",
                }}
              ></Grid>
              <Grid item xs={4} sx={{ fontSize: "16px", padding: "8px 0" }}>
                OOOI
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  fontSize: "14px",
                  color: "#838383",
                  padding: "12px 0 8px 0",
                }}
              >
                List of OOOI icon
              </Grid>
              <Grid
                item
                xs={1.5}
                sx={{
                  padding: "8px 0",
                  fontFamily: "Gotham-Medium",
                  fontSize: "14px",
                }}
              >
                <img src={OOOIOut} alt="high" /> OUT
              </Grid>
              <Grid
                item
                xs={2.5}
                sx={{ padding: "8px 0" }}
                className={`${styles.descriptionOOOI}`}
              >
                OUT report
              </Grid>
              <Grid
                item
                xs={1.5}
                sx={{
                  padding: "8px 0",
                  fontFamily: "Gotham-Medium",
                  fontSize: "14px",
                }}
              >
                <img src={OOOIOff} alt="high" /> OFF
              </Grid>
              <Grid
                item
                xs={2.5}
                sx={{ padding: "8px 0" }}
                className={`${styles.descriptionOOOI}`}
              >
                OFF report
              </Grid>
              <Grid
                item
                xs={1.5}
                sx={{
                  padding: "8px 0",
                  fontFamily: "Gotham-Medium",
                  fontSize: "14px",
                }}
              >
                <img src={OOOIOn} alt="high" /> ON
              </Grid>
              <Grid
                item
                xs={2.5}
                sx={{ padding: "8px 0" }}
                className={`${styles.descriptionOOOI}`}
              >
                ON report
              </Grid>
              <Grid
                item
                xs={1.5}
                sx={{
                  padding: "8px 0",
                  fontFamily: "Gotham-Medium",
                  fontSize: "14px",
                }}
              >
                <img src={OOOIIn} alt="high" /> IN
              </Grid>
              <Grid
                item
                xs={2.5}
                sx={{ padding: "8px 0" }}
                className={`${styles.descriptionOOOI}`}
              >
                IN report
              </Grid>
              <Grid
                item
                xs={1.5}
                sx={{
                  padding: "8px 0",
                  fontFamily: "Gotham-Medium",
                  fontSize: "14px",
                }}
              >
                <img src={OOOITng} alt="high" /> T&G
              </Grid>
              <Grid
                item
                xs={2.5}
                sx={{ padding: "8px 0" }}
                className={`${styles.description}`}
              >
                Touch-and-Go event report
              </Grid>
              <Grid
                item
                xs={1.5}
                sx={{
                  padding: "8px 0",
                  fontFamily: "Gotham-Medium",
                  fontSize: "14px",
                }}
              >
                <img src={OOOIDr} alt="high" /> DOOR
              </Grid>
              <Grid
                item
                xs={2.5}
                sx={{ padding: "8px 0" }}
                className={`${styles.description}`}
              >
                Door Status event report
              </Grid>
              <Grid
                item
                xs={1.5}
                sx={{
                  padding: "8px 0",
                  fontFamily: "Gotham-Medium",
                  fontSize: "14px",
                }}
              >
                <img src={OOOIMnt} alt="high" /> MNT.
              </Grid>
              <Grid
                item
                xs={2.5}
                sx={{ padding: "8px 0" }}
                className={`${styles.description}`}
              >
                Maintainance report
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  borderBottom: "1px solid #D1D1D1",
                  padding: "8px 0",
                  marginBottom: "16px",
                }}
              ></Grid>
              <Grid item xs={4} sx={{ fontSize: "16px", padding: "8px 0" }}>
                Repetitive Defect
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  fontSize: "14px",
                  color: "#838383",
                  padding: "12px 0 8px 0",
                }}
              >
                List of Repetitive Defect
              </Grid>
              <Grid item xs={0.7} sx={{ padding: "8px 0" }}>
                <span className={styles.repetitiveDefect}>C</span>
              </Grid>
              <Grid
                item
                xs={3.3}
                sx={{ padding: "8px 6px 8px 0" }}
                className={`${styles.description}`}
              >
                If an event (fault or warning) has consecutively occurred more
                than 4 times on the last 15 legs, this event will be considered
                as repetitive.
              </Grid>
              <Grid item xs={0.7} sx={{ padding: "8px 0" }}>
                <span className={styles.repetitiveDefect}>NC</span>
              </Grid>
              <Grid
                item
                xs={3.3}
                sx={{ padding: "8px 6px 8px 0" }}
                className={`${styles.description}`}
              >
                If an aircraft, its last 15 legs include a specific event more
                than 6 times but non-consecutively, this event will also be
                considered as repetitive.
              </Grid>
            </Grid>
          </div>
        </div>
      </>
    )
  );
};

export default Legend;
