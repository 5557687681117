import axios from "axios";
import { app } from "../../firebase";
import Cookies from "js-cookie";

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL_MONGODB;
export const makeAPIRequestMDB = async (
  method,
  endPoint,
  data = null,
  isExternalAPI = false,
  extraHeader = {},
  base_url = null
) => {
  let result = null;
  try {
    const elevadeToken = Cookies.get("elevadeToken");
    if (isExternalAPI) {
      switch (method) {
        case "get":
          result = await axios.get(`${endPoint}`);
          break;
        case "delete":
          result = await axios.delete(`${endPoint}`);
          break;
        case "post":
          result = await axios.post(`${endPoint}`, data);
          break;
        case "put":
          result = await axios.put(`${endPoint}`, data);
          break;
        default:
          result = { message: "Unknow method." };
      }
    } else {
      const user = await app.auth().currentUser;

      const reqHeader = {
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          "auth-gs-token": elevadeToken ? elevadeToken : "",
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: false,
        ...extraHeader,
      };

      if (user) {
        const token = await user.getIdToken(true);
        reqHeader.headers.Authorization = `Bearer ${token}`;
      }

      switch (method) {
        case "get":
          result = await axios.get(`${BASE_API_URL}/${endPoint}`, reqHeader);
          break;
        case "delete":
          result = await axios.delete(`${BASE_API_URL}/${endPoint}`, reqHeader);
          break;
        case "post":
          result = await axios.post(`${BASE_API_URL}/${endPoint}`, data, reqHeader);
          break;
        case "put":
          result = await axios.put(`${BASE_API_URL}/${endPoint}`, data, reqHeader);
          break;
        default:
          result = { message: "Unknow method." };
      }
    }

    return result.data;
  } catch (error) {
    const message = error.response?.data.message;
    if (message !== "Member does not exist") {
    }
    return { error: true, message };
  }
};

export const makeAPIRequestByApiKey = async (method, endPoint, data) => {
  let result = null;
  try {
    const reqHeader = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const token = "Ssic2l89pbl9cmsaB";
    reqHeader.headers.Authorization = `${token}`;

    switch (method) {
      case "get":
        result = await axios.get(`${BASE_API_URL}/${endPoint}`, reqHeader);
        break;
      case "delete":
        result = await axios.delete(`${BASE_API_URL}/${endPoint}`, reqHeader);
        break;
      case "post":
        result = await axios.post(`${BASE_API_URL}/${endPoint}`, data, reqHeader);
        break;
      case "put":
        result = await axios.put(`${BASE_API_URL}/${endPoint}`, data, reqHeader);
        break;
      default:
        result = { message: "Unknow method." };
    }

    return result.data;
  } catch (error) {
    const message = error.response?.data.message;
    if (message !== "Member does not exist") {
    }
    return { error: true, message };
  }
};
