import React, { useContext, useState } from "react";
// import { Link } from "react-router-dom";
import AuthContext from "common/context/auth/authContext";

const ForgotPassword = () => {
  const authState = useContext(AuthContext);

  const [email, setEmail] = useState("");

  const requestResetLink = (e) => {
    e.preventDefault();
    authState.requestResetPassword(email);
  };

  return (
    <div className="d-flex align-items-center justify-content-center mb-5" style={{ minHeight: "65vh" }}>
      <div className="w-100" style={{ maxWidth: "400px" }}>
        <div className="card">
          <div className="card-body">
            <h2 className="mb-4 text-center">Forgot password</h2>
            <form onSubmit={requestResetLink}>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  className="form-control"
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>

              <button className="btn btn-dark btn-block">Recover</button>
            </form>
          </div>
        </div>
        <div className="w-100 text-center mt-2">
          Head back to
          <a href="/forgot-password?login"> Login </a>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
