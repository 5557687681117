
import React from 'react';

export default function NumberedBox({
	number = 0,
	x = 0,
	y = 0,
	fontColor = "black",
	boxColor = "white",
	data,
	onClick,
}) {
	let codedBoxColor = boxColor; // '#D1D1D1';
	let codedFontColor =
		codedBoxColor === "white" || codedBoxColor === "#FFFFFF"
			? "black"
			: "white";
	// switch (boxColor) {
	//     case 'red':
	//         codedBoxColor = '#E95048';
	//         codedFontColor = 'white';
	//         break;
	//     case 'blue':
	//         codedBoxColor = '#9500FF';
	//         codedFontColor = 'white';
	//         break;
	//     case 'white':
	//         codedBoxColor = 'white';
	//         codedFontColor = 'black';
	//         break;
	//     default:
	//         break;
	// }
	return (
		<g
			// transform(x,y) x=0 left , y=0 top
			transform={`translate(${x},${y})`}
			onClick={() => {
				if (onClick) {
					onClick(number, data);
				}
			}}
			style={{
				cursor: "pointer",
			}}
		>
			<rect
				x="0"
				y="0"
				width="19"
				height="19"
				rx="4.5"
				fill={codedBoxColor}
			/>
			<rect
				// box border
				x="0" // 0 : left
				y="0" // 0 : top
				width="19"
				height="19"
				rx="4.5"
				stroke="#D1D1D1"
			/>
			<text
				x={number > 9 ? "2.5" : "5.5"}
				y="14"
				fontSize={"13px"}
				fill={codedFontColor}
				className="number-numberedbox"
			>
				{number}
			</text>
			{data?.inventory?.status?.code === "D" && (
				<circle cx="17" cy="2" r="4.5" fill="#E95048" />
			)}
		</g>
	);
}
