import React from 'react';
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const CloseButton = ({ isDrawerOpen, setIsDrawerOpen, setIsEdit, isEdit, setRemovedFlr }) => {
  const StyledButton = styled(Button)(() => ({
    height: "50px",
    padding: "24px",
    textTransform: "none",
    boxShadow: "none",
    borderRadius: "8px",
    width: "148px",
    "&:hover": {
      backgroundColor: "#C3C3C3",
      color: "#FFFFFF",
    },
    marginRight: "24px",
  }));
  return (
    <>
      <div
        onClick={() => {
          setIsDrawerOpen(false);
          setIsEdit(false);
          setRemovedFlr([]);
        }}
      >
        <StyledButton>Close</StyledButton>
      </div>
    </>
  );
};

export default CloseButton;
