import React from "react";
import InspReportPage from "../views/pages/InspReportPage";
import EqManagementPage from "../views/pages/EqManagementPage";
// import LopaPage from "../views/pages/LopaPage";
import OverviewPage from "../views/pages/OverviewPage";
import ComingSoonPage from "../views/pages/ComingSoon";
import DeviceSettingPage from "../views/pages/DeviceSettingPage";

// const loginPage = `${landingPage}/login`;
// const landingPage = "/cabin-monitoring";
// const comingSoonRT = "/coming-soon";
const overview = "/overview";
const inspectionReport = "/inspection-reports";
const equipmentManagement = "/equipment-management";
const deviceSetting = "/device-setting";

export const paths = {
	// landingPage,
	// default: eqStatusRT,
	// comingSoon: comingSoonRT,
	// loginPage,
	overview,
	inspectionReport,
	equipmentManagement,
	deviceSetting,
};

/**
 * @description E.g value (array of objects) :
 {
	routeProps: {
		path: paths.eqStatus,
		element: <OverviewPage />,
		title : 'Overview',
	},
	requiredPermissions: [],
 }
 */
export const appRoutes = [
	{
		routeProps: {
			path: paths.overview,
			element: <OverviewPage />,
			title : 'Overview',
		},
		requiredPermissions: [],
	},
	{
		routeProps: {
			path: paths.inspectionReport,
			element: <InspReportPage />,
			title : 'Inspection Reports',
		},
		requiredPermissions: [],
	},
	{
		routeProps: {
			path: paths.equipmentManagement,
			element: <EqManagementPage />,
			title : 'Equipment Management',
		},
		requiredPermissions: [],
	},
	{
		routeProps: {
			path: paths.deviceSetting,
			element: <DeviceSettingPage />,
			title : 'Device Settings',
		},
		requiredPermissions: [],
	},
	// {
	// 	routeProps: {
	// 		path: paths.comingSoon,
	// 		element: <ComingSoonPage />,
	// 		title : 'Coming Soon',
	// 	},
	// 	requiredPermissions: [],
	// },
];