import React from "react";
import { Box, Select, MenuItem, Pagination } from "@mui/material";
import { useRef, useEffect, Fragment } from "react";
import { GQLNODESLIMIT } from "./constants";
import { styled } from "@mui/material/styles";
export default function CustomPagination({
	itemsPerPage,
	setItemsPerPage,
	curPage,
	numOfPages,
	setCurPage,
	setFetchCount,
	upperThreshold,
	lowerThreshold,
	setClickedPageNumber,
}) {
	// scroll to pagination component instantly each time page change
	const paginationRef = useRef();
	useEffect(() => {
		// eslint-disable-next-line
		paginationRef?.current?.scrollIntoView({ behavior: "auto" });
	}, [curPage]);

	const CustomPagination = styled(Pagination)({
		// Add your custom styles here
		"& .Mui-selected": {
			background: "#0AAEFF",
			color: "#FFFFFF",
		},
		"& .Mui-selected:hover": {
			background: "#0AAEFF",
			color: "#FFFFFF",
		},
	});

	return (
		<Fragment>
			<Box
				sx={{
					width: "100%",
					display: "flex",
					flexDirection: "row",
					justifyContent: "right",
					marginTop: "1em",
				}}
			>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						marginRight: "1em",
					}}
				>
					Rows Per Page
				</Box>
				<Select
					sx={{
						padding: "0px",
						"& .MuiSelect-select": {
							padding: "0px",
							paddingLeft: "1em",
						},
						marginRight: "1em",
					}}
					value={itemsPerPage}
					onChange={(e) => {
						setItemsPerPage(e.target.value);
						setCurPage(1);
						setFetchCount(1);
					}}
				>
					{["10", "20", "50", "100"].map((pageNumber) => (
						<MenuItem
							sx={{
								padding: "0px",
							}}
							key={pageNumber}
							value={pageNumber}
						>
							{pageNumber}
						</MenuItem>
					))}
				</Select>
				<CustomPagination
					ref={paginationRef}
					page={curPage}
					count={numOfPages}
					onChange={(e, value) => {
						setClickedPageNumber(true);
						setCurPage(value);
						if (value > upperThreshold) {
							setFetchCount(
								Math.ceil(
									(value * itemsPerPage) / GQLNODESLIMIT
								)
							);
						} else if (value <= lowerThreshold) {
							setFetchCount(
								Math.ceil(
									(value * itemsPerPage) / GQLNODESLIMIT
								)
							);
						}
					}}
					variant="outlined"
					shape="rounded"
				/>
			</Box>
			<Box sx={{ width: "100%", height: "2em" }} />
		</Fragment>
	);
}
