
import React from 'react';
import {
	useGetAllManufacturers,
	useGetAllDeviceModels,
	useGetAllDeviceStatuses,
} from "../../../../models/custom/device-model";

export default function useAddDeviceDatas({ manufacturerID }) {
	const allManufacturers = useGetAllManufacturers();
	const allDeviceModels = useGetAllDeviceModels({ manufacturerID });
	const allDeviceStatuses = useGetAllDeviceStatuses();
	return {
		allManufacturers,
		allDeviceModels,
		allDeviceStatuses,
	};
}
