import React from "react";
import "./TermsOfUse.css";

const TermsOfUse = () => (
  <div style={{ width: "100%", height: "800px;", overflow: "hidden" }}>
    <iframe
      title="iframe"
      height={"800px"}
      style={{ width: "100%" }}
      src="https://storage.googleapis.com/airasia-ade-prd.appspot.com/AEROTRADE/TnC/ADE%20Terms%20of%20Use.pdf"
    ></iframe>
  </div>
  // <div className="termsofuse">
  //   <div className="content mt-3">
  //     <h1>Terms of Use</h1>
  //     <h2 className="subheadline text-justify">Acceptable Use Policy / Website Disclaimer</h2>
  //     <p>
  //       This page sets out the terms and conditions (“Terms of Use”) for using the
  //       www.ade.aero application (collectively known as “Website”).
  //       The Website is owned and operated by Aerotrade and/or its subsidiaries.
  //       <br />
  //       Please read these terms of use carefully before using this website.
  //       You should immediate stop and refrain from using this website if you do not agree to this terms of use.
  //     </p>
  //     <div className="flexed">
  //       <div className="nav-container">
  //         <div className="nav">
  //           <a href="#introduction">Introduction</a>
  //           <a href="#propertyright">Intellectual Property Right</a>
  //           <a href="#content">Content</a>
  //           <a href="#useofwebsite">Use of Website</a>
  //           <a href="#restriction">Restriction and Prohibition of Use of this Website</a>
  //           <a href="#external">External and Third Party Sites</a>
  //           <a href="#security">Security</a>
  //           <a href="#contactus">Contact Us</a>
  //           <a href="#privacy">Our Privacy Statement</a>
  //           <a href="#specificterm">Specific Terms and Conditions of Products and Services</a>
  //           <a href="#liability">Liability and Indemnity</a>
  //           <a href="#governing">Governing Law and Venue</a>
  //           <a href="#miscellaneous">Miscellaneous</a>
  //           <a href="#generalterm">General Terms</a>
  //         </div>
  //       </div>
  //       <div className="main text-justify">
  //         <h3 className="subheadline" id="introduction">Introduction</h3>
  //         <p>
  //           Aerotrade and its subsidiaries (“AirAsia, “we”, “us”, “our””),
  //           are Malaysian entities, directly owned by AirAsia Group Berhad (“AAGB”).
  //           Aerotrade is a wholly owned subsidiary of AirAsia Group Berhad (“AAGB”).
  //           AAGB, Aerotrade and its respective affiliates shall collectively be known as AirAsia Group of Companies.
  //           For avoidance of doubt, affiliates shall mean an entity, present and future, that directly or indirectly,
  //           through one or more intermediaries, controls, is controlled by, or is under common control with AirAsia.
  //           The term “control” (including the terms “controlled by” and “under common control with”) means the possession,
  //           directly or indirectly, of the power to direct or cause the direction of the management and policies of AirAsia,
  //           whether through the ownership of voting securities, by contract or otherwise.
  //         </p>
  //         <p>
  //           This Website is managed and operated out of Malaysia with the server located in Malaysia.
  //           Though this website may be accessible from anywhere in the world,
  //           the availability of the functions, products, and services discussed, mentioned, supplied,
  //           and provided through or on this website may vary by country or region.
  //           Should you choose to visit this website from countries or regions outside of Malaysia,
  //           you do so on your own volition and shall use our Website only as permitted by your local laws.
  //         </p>
  //         <p>
  //           Through the Website, we make available for sale a wide variety of aircraft components,
  //           spares well as services (collectively known as “Products and Services'').
  //           Should you wish to purchase these Products and Services,
  //           you may be directed to another External Site (hereunder defined) which may contain its own respective terms of use.
  //           If you wish to continue with the purchase of the Product and Service,
  //           you agree to be additionally bound by the specific terms of use applicable to the particular Product and Service.
  //         </p>
  //         <p>
  //           In the event of conflict between this Terms of Use and specific terms of use,
  //           the latter shall prevail. We reserve the right to amend, modify, add, delete
  //           and make corrections to this Terms of Use at any time and would advise you to review these
  //           Terms of Use and any specific terms of use periodically to be aware of such modifications.
  //           In any event, we will notify users of any modification by updating the terms on our website
  //           @www.airasia.com (“Website”) and by including a "NEWLY UPDATED" label for a period of 30 days.
  //           After the 30-days’ period, the updated version(s) of the Terms of Use shall supersede all previous
  //           versions of the Terms of Use with automatic effect, without any further action by us.
  //           You acknowledge and agree that you shall be bound by the terms of the most recent version of theTerms of Use found on the Website.
  //         </p>
  //         <h3 className="subheadline" id="propertyright">Intellectual Property Right</h3>
  //         <p>
  //           You acknowledge and agree that all copyright, database right, trademarks
  //           and all other intellectual property rights or other property rights relating to the Website,
  //           including but not limited to content, in whichever form, and lay out,
  //           shall remain at all times vested in us or their respective owner.
  //         </p>
  //         <p>
  //           The contents of our Website are intended for your personal non-commercial use only.
  //           Graphics and images on this Website are protected by copyright and may not be reproduced
  //           or appropriated in any manner without our written permission.
  //           Modification of any of the materials or use of the materials for any other purpose will be a violation of our copyright
  //           and other intellectual property rights and the copyright and intellectual property rights of the respective owners.
  //         </p>
  //         <p>
  //           This Website and the Products and Services, technology and processes contained may be the
  //           subject of other intellectual property rights owned by AirAsia Group of Companies or by third parties.
  //           No licence is granted in respect of such intellectual property rights other than as set out in this Terms of Use.
  //           Your use of this Website must not in any way infringe the intellectual property rights of any party.
  //         </p>
  //         <h3 className="subheadline" id="content">Content</h3>
  //         <p>
  //           Whilst every effort is made to update the information contained on this Website,
  //           neither AirAsia, AirAsia Group of Companies nor any third party or data or
  //           content owner/provider make any representations or warranties, whether express,
  //           implied in law or residual, as to the sequence, accuracy, completeness or reliability of information,
  //           opinions, any share price information, research information, data and/or content contained on the
  //           Website (including but not limited to any information which may be provided by any third party or data or content providers)
  //           (“Information”) and shall not be bound in any manner by any information contained on the Website.
  //         </p>
  //         <p>
  //           The Company reserves the right at any time to change or discontinue without notice,
  //           any aspect or feature of this Website. No information shall be construed as advice
  //           and the information contained in the Website is offered for information purposes
  //           only and is not intended for trading purposes. You rely on the information contained on
  //           this Website at your own discretion. If you find an error or omission at this site,
  //           you can let us know by contacting us in the manner described at the Contact Us section below.
  //         </p>
  //         <h3 className="subheadline" id="useofwebsite">Use of Website</h3>
  //         <h4 className="subheadline">By using this Website;</h4>
  //         <ul>
  //           <li>
  //             <p>
  //               You warrant that you have reached the age of majority and have capacity to enter into a
  //               legally binding contract in the territory in which you reside and that you will only use this
  //               Website to make legitimate purchases;
  //             </p>
  //           </li>
  //           <li>
  //             <p>
  //               It is your responsibility to ensure that any products, services or
  //               information available through the Website meet your specific requirements;
  //             </p>
  //           </li>
  //           <li>
  //             <p>
  //               You will be fully responsible for all matters arising from your use of this Website;
  //             </p>
  //           </li>
  //           <li>
  //             <p>
  //               You will not use this Website in any manner which breaches any applicable laws and regulations;
  //             </p>
  //           </li>
  //           <li>
  //             <p>
  //               You will not interfere or attempt to interfere with the operation or functionality of the Website and not to obtain or attempt to obtain unauthorised access, via whatever means, to our systems;
  //             </p>
  //           </li>
  //           <li>
  //             <p>
  //               You agree that we have the right to send you administrative and promotional emails.
  //               We may also send you information regarding your account activity and purchases,
  //               as well as updates about the Website, Products and Services,
  //               new products and/or services as well as any other promotional offers.
  //               You are able to opt-out of receiving our promotional e-mails or to unsubscribe such information at
  //               any time by clicking the unsubscribe link at the bottom of any of such e-mail correspondence; and
  //             </p>
  //           </li>
  //           <li>
  //             <p>
  //               You acknowledge that the Products and Services available on the
  //               Website may be provided by third parties and you accept that these third parties will have
  //               their own applicable terms and conditions in relation to the supply of Products and Services,
  //               in which you are further subjected to and shall be abide by those additional terms and conditions.
  //             </p>
  //           </li>
  //         </ul>
  //         <h3 className="subheadline" id="restriction">Restriction and Prohibition of Use of this Website</h3>
  //         <p>
  //           By using this Website including the posting of content and commentary in the Website’s
  //           community and chatting channel, or other public communication forums hosted on this Website,
  //           you agree that you will not upload, post or otherwise transmit or make available any
  //           content (including text links, communications, software, images, sounds, data or other information)
  //           that is not consistent with the permissible uses outlined in this Terms of Use including,
  //           but not limited to the following:
  //         </p>
  //         <ul>
  //           <li>
  //             False, inaccurate, misleading, unlawful, harmful, threatening, abusive, tortuous,
  //             harassing, defamatory, libelous, invasive of another’s privacy, vulgar, profane,
  //             sexually oriented/suggestive/explicit, obscene, racially or ethnically offensive or otherwise objectionable;
  //           </li>
  //           <li>
  //             Violates any laws, third party rights, or infringes on any patent, trademark, trade secret,
  //             copyright or other proprietary rights of any party;
  //           </li>
  //           <li>
  //             Attempt to use the Website with crawlers, robots, data mining, extraction tools or any other functionality;
  //           </li>
  //           <li>
  //             Contains software viruses, Trojan horses, worms, time bombs, cancelbots or
  //             any other computer code or files that are designed to disrupt,
  //             damage or limit the functioning of any software or hardware;
  //           </li>
  //           <li>
  //             Provides any non-public information about AirAsia, AirAsia Group of Companies or any other company
  //             or person without the proper authorization to do so. This includes providing the names,
  //             addresses and extension numbers of AirAsia employees and any of the Website’s users;
  //           </li>
  //           <li>
  //             Intentionally interferes with the operation of any of the Website and/or
  //             Products and Services included in this Website without limitation, mail or ‘post’ bombing,
  //             news bombing, other flooding techniques, deliberate attempts to overload a system and broadcast attacks;
  //           </li>
  //           <li>
  //             Impersonates any person or entity, including AirAsia employees or forges any TCP-IP packet header,
  //             email header or any part of a message header. This prohibition does not include the use of aliases
  //             or anonymous remailers;
  //           </li>
  //           <li>
  //             Linking to this Website without obtaining the prior written approval of AirAsia; and
  //           </li>
  //           <li>
  //             Use of any automated screen capture or screen scraping technologies to obtain information from
  //             this site without the prior written approval of AirAsia is an infringement of
  //             AirAsia’s intellectual property rights.
  //           </li>
  //         </ul>
  //         <h3 className="subheadline" id="external">External and Third Party Sites</h3>
  //         <p>
  //           This Website may contain links to websites maintained by third parties (“External Sites”).
  //           These External Sites are provided to redirect you to another website to enable purchase of Products and Services,
  //           or for your reference and convenience only. AirAsia does not operate, control or endorse in any respect such External Sites or their content.
  //           You assume sole responsibility for use of these External Sites and are therefore, advised to examine the terms and conditions of those External Sites carefully.
  //         </p>
  //         <h3 className="subheadline" id="security">Security</h3>
  //         <p>
  //           Secure Server Layer (SSL) encrypts the information you send through this Website.
  //           Although AirAsia would use its best endeavor to ensure security, AirAsia makes no warranty in respect of the strength
  //           or effectiveness of that encryption and AirAsia is not and shall not be held responsible for events arising
  //           from unauthorised access of the information you provide.
  //         </p>
  //         <h3 className="subheadline" id="contactus">Contact Us</h3>
  //         <p>
  //           Should you have any queries or complaints regarding this Website and this Terms of Use,
  //           please click here to submit an online form.
  //         </p>
  //         <h3 className="subheadline" id="privacy">Our Privacy Statement</h3>
  //         <p>
  //           We regard your privacy as important. For more detailed information on how we collect
  //           and process your personal information, please refer to our Privacy Statement.
  //           By using our Website, you consent to the processing of your personal information as described in our
  //           Privacy Statement as may be amended from time to time.
  //         </p>
  //         <h3 className="subheadline" id="specificterm">Specific Terms and Conditions of Products and Services</h3>
  //         <p>
  //           For further information regarding the Products and Services offered for sale on the Website,
  //           you can read the specific terms and conditions applicable to the relevant products or services here.
  //         </p>
  //         <h3 className="subheadline" id="liability">Liability and Indemnity</h3>
  //         <p>
  //           The content on this Website is provided on an “as is” and “as available” basis.
  //           You therefore agree that your use of this Website and the Products and
  //           Services offered for sale on this Website are provided at your sole risk.
  //           AirAsia, our officers, directors, employees, partners and suppliers disclaim:
  //           <ul>
  //             <li>
  //               all warranties or representations of any kind regarding this Website, its operations or the accuracy,
  //               reliability, completeness, usefulness, non-infringement of intellectual property rights,
  //               or quality of any information, content or material included herein;
  //             </li>
  //             <li>
  //               to the fullest extent permitted by applicable laws, all conditions and warranties,
  //               express and implied, including but not limited to implied warranties of merchantability or
  //               fitness for a particular purpose or non-infringement;
  //             </li>
  //             <li>
  //               all liability to you or anyone else for any direct, indirect, special, consequential or
  //               punitive loss or damage (including without limitation loss of revenue, loss of goodwill,
  //               loss of reputation and loss of, or damage to, data), incurred or suffered in connection with the use or
  //               inability to use this Website whether based in contract, tort or otherwise even if we are informed of their possibility; and
  //             </li>
  //             <li>
  //               <p>
  //                 all liability to you or anyone else if interference with or damage to your computer systems occurs in
  //                 connection with use of this Website or an external site.
  //                 You must take your own precautions to ensure that whatever you select for use from
  //                 this Website is free of viruses or anything else that may interfere with or damage the operation of your computer systems
  //               </p>
  //               <p>
  //                 In the event of any third-party request, loss, liability, proposition, or expense (including lawyer's fees) brought against AirAsia arising
  //                 from or relating to your usage of this Website and/or the Products and Services, you agree to defend, indemnify and hold us, our directors,
  //                 employees, agents, partners, affiliates and other licensed persons, harmless at all time.
  //               </p>
  //             </li>
  //           </ul>
  //         </p>
  //         <h3 className="subheadline" id="governing">Governing Law and Venue</h3>
  //         <p>
  //           This Website is governed by the laws of Malaysia and should there be any dispute arising from your use of this Website,
  //           you further agree to submit to the exclusive jurisdiction of the Malaysia courts.
  //         </p>
  //         <h3 className="subheadline" id="miscellaneous">Miscellaneous</h3>
  //         <p>
  //           You may not assign or transfer any of your rights or obligations under this Terms of Use,
  //           the specific terms and conditions or Privacy Statement.
  //         </p>
  //         <p>
  //           AirAsia does not guarantee it will take action against all breaches of the provisions of this Terms of Use.
  //           The failure to act with respect to a breach by you or others does not amount to waiver of
  //           our right to act with respect to subsequent or similar breaches.
  //           This Terms of Use set forth the entire understanding and agreement between you and AirAsia with respect to its subject matter.
  //         </p>
  //         <p>
  //           AirAsia may terminate your use of this Website or any of the Products and Services at any time for any reason or no reason,
  //           with or without notice. This Terms of Use and the specific terms and conditions,
  //           if applicable, shall continue to govern all use of this Website and the particular Products
  //           and Service notwithstanding any termination, suspension, or cancellation.
  //         </p>
  //         <h3 className="subheadline" id="generalterm">General Terms</h3>
  //         <p>
  //           Entire Agreement - The Terms and Conditions, the Terms of Use and the Privacy Policy constitute the
  //           sole record of the agreement between you and the Company in relation to your use of the Website.
  //           Neither you nor the Company shall be bound by any express, tacit or implied representation, warranty,
  //           promise or the like not recorded herein. Unless otherwise specifically stated these Website terms
  //           and conditions supersede and replace all prior commitments, undertakings or representations, whether written or oral,
  //           between you and the Company in respect of your use of the Website.
  //         </p>
  //         <p>
  //           Waiver - No indulgence or extension of time which either you or the Company may grant to the other will constitute
  //           a waiver of or, whether by estoppels or otherwise, limit any of the existing or future rights of the grantor
  //           in terms hereof, save in the event or to the extent that the grantor has signed a written document expressly waiving or limiting such rights.
  //         </p>
  //         <p>
  //           Cession - The Company shall be entitled to cede, assign and delegate all or any of its rights and obligations
  //           in terms of any relevant terms and conditions, policies and notices to any third-party.
  //         </p>
  //         <p>
  //           Severability - Headings are for reference purposes only and do not limit the scope or extent of such section.
  //           All provisions of any relevant terms and conditions, policies and notices are, notwithstanding the manner
  //           in which they have been grouped together or linked grammatically, severable from each other.
  //           Any provision of any relevant terms and conditions, policies and notices, which is or becomes unenforceable in any jurisdiction,
  //           whether due to voidness, invalidity, illegality, unlawfulness or for any reason whatever, shall,
  //           in such jurisdiction only and only to the extent that it is so unenforceable, be treated as pro non scripto and
  //           the remaining provisions of any relevant terms and conditions, policies and notices shall remain in full force and effect.
  //         </p>
  //       </div>
  //     </div>
  //   </div>
  // </div>
);

export default TermsOfUse;
