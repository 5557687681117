
import React from 'react';

export default function LeftWing() {
	return (
		<g>
			<g filter="url(#filter0_d_4_45)">
				<path
					d="M826.906 441H550L726.991 657.957H884L826.906 441Z"
					fill="url(#paint0_linear_4_45)"
				/>
			</g>
			<path
				d="M793.221 441L806.923 491.371M852.598 657.957L806.923 491.371M815.487 441L827.757 486.104M875.436 657.957L827.757 486.104M806.923 491.371L827.757 486.104"
				stroke="#E6E6E6"
			/>
			<path
				d="M744.12 441L761.287 473.819C762.667 476.457 765.398 478.111 768.376 478.111H778.297C781.751 478.111 784.816 480.329 785.896 483.61L843.464 658.528"
				stroke="#E6E6E6"
			/>
			<path
				d="M603.668 441L629.931 478.111H676.748L761.818 657.386"
				stroke="#E6E6E6"
			/>
		</g>
	);
}
