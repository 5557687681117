import React from "react";
import { getPopupColorBasedOnStatusCode } from "../../Popup";
import AirplaneBox from "../AirplaneBox";
import { getBoxDataFromInFleetLocations } from "../AirplaneBox/utils";
export default function BoxOHBRowABC({
	invs,
	onClick,
	setClosePopup,
	setPopup,
}) {
	const data = getBoxDataFromInFleetLocations(invs);
	const boxX = 305;
	const boxY = 520;
	const popupXOffset = -217;
	const popupYOffset = -500;
	return (
		<g className="display-box">
			<Pointer />
			<AirplaneBox
				title="OHB Row 01ABC"
				x={boxX}
				y={boxY}
				data={data}
				onClick={onClick}
				minWidth={150}
				setClosePopup={setClosePopup}
				setPopup={(rowX, rowY, data) => {
					setPopup({
						status: data?.inventory?.status,
						data: data,
						x: rowX + boxX + popupXOffset,
						y: rowY + boxY + popupYOffset,
						// row: 'row',
						col: data.locationId.seatNo,
						color: getPopupColorBasedOnStatusCode(
							data?.inventory?.status?.code
						),
					});
				}}
			/>
		</g>
	);
}
function Pointer() {
	return (
		<g>
			<path
				className="path"
				d="M277 397V493C277 496.866 280.134 500 284 500H322"
				stroke="#333333"
				stroke-width="0.5"
				stroke-linecap="round"
				stroke-dasharray="2 2"
			/>
			<circle
				className="pointer-1st"
				cx="277"
				cy="396"
				r="3"
				fill="#74C4E8"
			/>
			<circle
				className="pointer-2nd"
				opacity="0.4"
				cx="277"
				cy="396"
				r="6"
				fill="#74C4E8"
			/>
		</g>
	);
}
