
import React from 'react';
import { getPopupColorBasedOnStatusCode } from "../../Popup";
import AirplaneBox from "../AirplaneBox";
import { getBoxDataFromInFleetLocations } from "../AirplaneBox/utils";
export default function Box2lAttnStnAdv({
	invs,
	onClick,
	setClosePopup,
	setPopup,
}) {
	const data = getBoxDataFromInFleetLocations(invs);
	const boxX = 910;
	const boxY = 510;
	const popupXOffset = -503;
	const popupYOffset = -500;
	return (
		<g className="display-box">
			<Pointer />
			<AirplaneBox
				title="2L ATT. STN."
				x={boxX}
				y={boxY}
				data={data}
				onClick={onClick}
				minWidth={150}
				setClosePopup={setClosePopup}
				setPopup={(rowX, rowY, data) => {
					setPopup({
						status: data?.inventory?.status,
						data: data,
						x: rowX + boxX + popupXOffset,
						y: rowY + boxY + popupYOffset,
						// row: 'row',
						col: data.locationId.seatNo,
						color: getPopupColorBasedOnStatusCode(
							data?.inventory?.status?.code
						),
					});
				}}
			/>
		</g>
	);
}
function Pointer() {
	return (
		<g>
			<path
				className="path"
				d="M1201 421V451.327C1201 455.193 1197.87 458.327 1194 458.327H962C958.13 458.327 955 461.461 955 465.327V478"
				stroke="#333333"
				stroke-width="0.5"
				stroke-linecap="round"
				stroke-dasharray="2 2"
			/>
			<circle
				className="pointer-1st"
				cx="1201"
				cy="420"
				r="3"
				fill="#74C4E8"
			/>
			<circle
				className="pointer-2nd"
				opacity="0.4"
				cx="1201"
				cy="420"
				r="6"
				fill="#74C4E8"
			/>
		</g>
	);
}
