import { useState } from "react";
import axios from "axios";
export default function useMutateGQL({ gqlquery, url, }) {
    const [data, setData] = useState([]);
    const [isLoading, setIsloading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isError, setIsError] = useState(false);
    const queryStr = typeof (gqlquery) === 'string' ? gqlquery : gqlquery();
    async function mutate() {
        setIsloading(true);
        setErrorMessage(null);
        setIsError(false);
        return await axiosGraphqlRequest(url, queryStr).then((result) => {
            const { data, isError, errorMessage } = result;
            setIsError(isError);
            setErrorMessage(errorMessage);
            setData(data);
            return result;
        }).catch((err) => {
            setIsError(true);
            setErrorMessage(JSON.stringify({ err }));
        }).finally(() => {
            setIsloading(false);
        });
    }
    return {
        data,
        isLoading,
        isError,
        errorMessage,
        mutate,
    };
}
export async function axiosGraphqlRequest(url, gqlQuery, options) {
    const graphqlQuery = {
        query: gqlQuery,
        variables: options && options.variables ? options.variables : {}
    };
    try {
        const { data } = await axios({
            url,
            headers: {
                authorization: options && options.jwtToken ? options.jwtToken : '',
                'Content-Type': 'application/json',
            },
            data: graphqlQuery,
            method: 'POST'
        });
        if (data && data.errors && data.errors[0] && data.errors[0].message) {
            throw new Error(data.errors[0].message);
        }
        return {
            data,
            isError: false,
            errorMessage: null
        };
    }
    catch (err) {
        return {
            data: null,
            isError: true,
            errorMessage: err.message
        };
    }
}
