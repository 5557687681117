import React from 'react';
import { Grid } from "@mui/material";
import Loading from "./Loading";

function AirplaneLoader({ data, color, position }) {
  return (
    <>
      <div style={{ minHeight: "460px", marginTop: "100px", position: position ? position : "" }}>
        <Grid container columns={1}>
          <Grid item xs={1} sx={{ display: "flex", justifyContent: "center" }}>
            <Loading style={{ width: "200px" }} />
          </Grid>
          <Grid
            item
            xs={1}
            sx={{
              display: "flex",
              justifyContent: "center",
              fontSize: "18px",
              fontFamily: "Gotham-Medium",
              color: color ? color : "#383838",
            }}
          >
            Loading...
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default AirplaneLoader;
