import React from 'react';
import FleetCardHigh from "common/images/icons/elevade/Fleet-Card-High.svg";
import FleetCardMed from "common/images/icons/elevade/Fleet-Card-Med.svg";
import FleetCardLow from "common/images/icons/elevade/Fleet-Card-Low.svg";
import FleetCardOk from "common/images/icons/elevade/Fleet-Card-Ok.svg";
import FleetCardUndefined from "common/images/icons/elevade/Fleet-Card-Undefined.svg";
import FleetCardNone from "common/images/icons/elevade/Fleet-Card-None.svg";
import FleetCardNuisance from "common/images/icons/elevade/Fleet-Card-Nuisance.svg";
import styles from "../aircraftMonitoring/AircraftStatus.module.css";

const GetPriorityIcon = ({ priority, correlated }) => {
  switch (priority) {
    case "High":
      return (
        <img
          src={FleetCardHigh}
          alt="High"
          className={correlated === 1 ? styles.fleetIconChild : styles.fleetIcon}
          style={{ zIndex: "99999999999999" }}
        />
      );
    case "Medium":
      return (
        <img
          src={FleetCardMed}
          alt="Medium"
          className={correlated === 1 ? styles.fleetIconChild : styles.fleetIcon}
          style={{ zIndex: "999" }}
        />
      );
    case "Low":
      return (
        <img src={FleetCardLow} alt="Low" className={correlated === 1 ? styles.fleetIconChild : styles.fleetIcon} />
      );
    case "Ok":
      return <img src={FleetCardOk} alt="Ok" className={correlated === 1 ? styles.fleetIconChild : styles.fleetIcon} />;
    case "Undefined":
      return (
        <img
          src={FleetCardUndefined}
          alt="Undefined"
          className={correlated === 1 ? styles.fleetIconChild : styles.fleetIcon}
        />
      );
    case "None":
      return (
        <img src={FleetCardNone} alt="None" className={correlated === 1 ? styles.fleetIconChild : styles.fleetIcon} />
      );
    case "Nuisance":
      return (
        <img
          src={FleetCardNuisance}
          alt="Nuisance"
          className={correlated === 1 ? styles.fleetIconChild : styles.fleetIcon}
        />
      );
    default:
      return (
        <img src={FleetCardNone} alt="None" className={correlated === 1 ? styles.fleetIconChild : styles.fleetIcon} />
      );
  }
};

export default GetPriorityIcon;
