import React from 'react';
import { Grid } from "@mui/material";
import IconDataMissing from "common/images/icons/elevade/Icon-DataMissing.svg";

const DataMissing = () => {
  return (
    <>
      <Grid container columns={1} sx={{ width: "100%", fontFamily: "Gotham-Book", marginTop: "40px" }}>
        <Grid item xs={1} sx={{ display: "flex", justifyContent: "center", paddingBottom: "20px" }}>
          <img src={IconDataMissing} alt="data-missing" />
        </Grid>
        {/* <Grid
          item
          xs={1}
          sx={{
            display: "flex",
            justifyContent: "center",
            fontFamily: "Gotham-Medium",
            padding: "4px",
            fontSize: "14px",
          }}
        >
          Missing Data
        </Grid> */}
        <Grid item xs={1} sx={{ display: "flex", justifyContent: "center", padding: "4px", fontSize: "12px" }}>
          THE AIRCRAFT IS NOT SENDING ANY DATA. PLEASE PHYSICALLY CHECK THE PFR AT THE AIRCRAFT.
        </Grid>
      </Grid>
    </>
  );
};

export default DataMissing;
