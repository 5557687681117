import React from "react";
import { Link } from "react-router-dom";
import RestrictedImage from "common/images/Error-403.svg";
import styles from "./RestrictedPage.module.css";
// import Sidebar from "../../elevade/component/Sidebar";
import Sidebar from "layouts/sidebar/Sidebar";
import useMediaQuery from "@mui/material/useMediaQuery";

const ErrorNotFound = () => {
  const isWeb = useMediaQuery("(min-width:900px)");
  return (
    <>
      <Sidebar />
      <div className="" style={{paddingLeft: !isWeb ? "70px" : ""}}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <img src={RestrictedImage} className={styles.restrictedImage} alt="error-403" />
          </div>
          <div className="rol-lg-6 col-md-6 col-sm-12" style={{ margin: "auto", textAlign: "center" }}>
            <p style={{ fontFamily: "Gotham-Bold", fontSize: "24px" }}>
              You don’t have the permission to access this page
            </p>
            <p style={{ fontFamily: "Gotham-Book", fontSize: "16px" }}>
              Your account subscription does not allow access to this page. Please go back to the{" "}
              <Link to="/fleet/home" style={{ textDecoration: "underline", color: "#2F77FF" }}>
                homepage
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorNotFound;
