//No Day
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export const toShortDateTimeStringUTC = (dateStr, offset) => {

  const format = "MMM DD YYYY HH:mm (UTC Z)";

  let date = dayjs.unix(dateStr).format(format);

  if (offset) {
    date = dayjs.unix(dateStr).utc().utcOffset(offset).format(format);
  }

  return date;
};
