import { styled } from "@mui/system";
import PropTypes from "prop-types";
import React from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";

const AdeInputSelect = styled(Select)((props) => {
  const extraStyles = () => {
    if (props.disabled) {
      return {
        pointerEvents: "none",
        backgroundColor: "#D1D1D1",
        border: "#B1B1B1",
      };
    }

    if (props.error) {
      return {
        border: "1px solid red",
      };
    }
    return {};
  };

  return {
    "&& > div": {
      padding: "6px",
      ...extraStyles(),
    },
  };
});

// NOTE: currently not working properly
export const AdeControlledInputSelect = ({
  options,
  required,
  control,
  name,
  disabled,
  error,
  onChange: _onChange,
  variant = "outlined",
  readOnly,
}) => {
  // eslint-disable-next-line
  const renderProps = (_variant) => {
    if (_variant !== "outlined") {
      return {
        InputProps: {
          disableUnderline: true,
        },
      };
    }

    return {};
  };

  return (
    <Controller
      control={control}
      name={name}
      placeholder={"Select..."}
      rules={{ required, disabled }}
      render={({ field }) => {
        const { onChange } = field;
        return (
          <AdeInputSelect
            {...field}
            variant={variant}
            onChange={(e) => {
              onChange(e);

              if (_onChange) {
                _onChange(e);
              }
            }}
            options={options}
            error={error}
            disabled={disabled}
            readOnly={readOnly}
          />
        );
      }}
    />
  );
};

AdeControlledInputSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.any,
      value: PropTypes.any,
    })
  ),
  required: PropTypes.bool,
  control: PropTypes.any,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default AdeInputSelect;
