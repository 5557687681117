import React from "react";
import { Box } from "@mui/system";
import LinearProgress from "@mui/material/LinearProgress";
import { AOCList, AOCListV2 } from "common/constant";
// import styles from "./Helpers.module.css";

// Function to split ATA chapter
function getAtaChapter(ata, report_type) {
  if (ata && report_type === "FAULT")
    return (
      ata.substring(0, 2) +
      "-" +
      ata.substring(2, 4).trim().padStart(2, "0") +
      "-" +
      ata.substring(4, 6).trim().padStart(2, "0")
    );
  else if (ata && report_type === "WARNING")
    return ata.substring(0, 2).trim().padStart(2, "0") + "-" + ata.substring(2, 4).trim().padStart(2, "0");
  else if (ata && report_type === "MAIN") return ata.substring(0, 2).trim().padStart(2, "0");
  else if (ata)
    return (
      ata.substring(0, 2).trim().padStart(2, "0") +
      "-" +
      ata.substring(2, 4).trim().padStart(2, "0") +
      "-" +
      ata.substring(4, 6).trim().padStart(2, "0")
    );
}

// Function to get IATA name
function GetIATAIn(val, airportList) {
  let filtered = "";
  val && val !== "" && airportList !== "" && (filtered = airportList?.filter((item) => item.label.includes(val)));
  if (filtered !== "" && filtered?.length > 0 && filtered !== undefined) return filtered[0]?.label;
}

// Function to get IATA name
export function GetIATA(val, airportList) {
  let filtered = "";
  val && val !== "" && airportList !== "" && (filtered = airportList?.filter((item) => item.label.includes(val)));
  if (filtered !== "" && filtered?.length > 0 && filtered !== undefined) return filtered[0]?.label;
}

// Function to split ATA chapter
export function formatAtaChapter(ata, report_type) {
  if (ata && report_type === "FAULT")
    return (
      ata.substring(0, 2) +
      "-" +
      ata.substring(2, 4).trim().padStart(2, "0") +
      "-" +
      ata.substring(4, 6).trim().padStart(2, "0")
    );
  else if (ata && report_type === "WARNING")
    return ata.substring(0, 2).trim().padStart(2, "0") + "-" + ata.substring(2, 4).trim().padStart(2, "0");
  else if (ata && report_type === "MAIN") return ata.substring(0, 2).trim().padStart(2, "0");
  else if (ata)
    return (
      ata.substring(0, 2).trim().padStart(2, "0") +
      "-" +
      ata.substring(2, 4).trim().padStart(2, "0") +
      "-" +
      ata.substring(4, 6).trim().padStart(2, "0")
    );
}

// A function to get the query variable in URL
export function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] === variable) {
      return pair[1]?.split(",");
    }
  }
  return false;
}

export function getTooltip(type, status) {
  if (type === "acms") {
    if (status === "Low") {
      return "Low Priority \n ACMS Report";
    } else if (status === "High") {
      return "High Priority \n ACMS Report";
    } else if (status === "Unavailable") {
      return "ACMS Report \n data unavailable";
    } else return "ACMS Report \n data unavailable";
  } else if (type === "pm") {
    if (status === "0") {
      return "Predictive Maintenance \n data unavailable";
    } else if (status === "1") return "Predictive Maintenance \n data available";
    else return "Predictive Maintenance \n data unavailable";
  } else if (type === "ul") {
    if (status === "0") {
      return "Uplink data unavailable";
    } else if (status === "1") return "Uplink data available";
    else return "Uplink data unavailable";
  }
}

export function ConvertUTCDatetime(date, localTime) {
  let newDate = date;
  if (date !== null) {
    if (localTime) {
      // const options = {
      //   year: "numeric",
      //   month: "2-digit",
      //   day: "numeric",
      // };
      // const EtaUTC = new Date(date + "Z");
      // const DateUTC = EtaUTC.toLocaleString("en-US", options).split(",")[0];
      // const TimeUTC = EtaUTC.toLocaleString().split(",")[1];
      // newDate =
      //   DateUTC?.split("/")[2] +
      //   "-" +
      //   DateUTC?.split("/")[0].padStart(2, "0") +
      //   "-" +
      //   DateUTC?.split("/")[1].padStart(2, "0") +
      //   " " +
      //   TimeUTC?.split(":")[0] +
      //   ":" +
      //   TimeUTC?.split(":")[1];

      const utcDate = new Date(date + "Z");
      // Extract components using local time getters
      const year = utcDate.getFullYear();
      // getMonth() returns 0-11; adding 1 to match the human-readable month
      const month = (utcDate.getMonth() + 1).toString().padStart(2, "0");
      const day = utcDate.getDate().toString().padStart(2, "0");
      const hours = utcDate.getHours().toString().padStart(2, "0");
      const minutes = utcDate.getMinutes().toString().padStart(2, "0");
      const seconds = utcDate.getSeconds().toString().padStart(2, "0");

      // Assemble the formatted string
      newDate = `${year}-${month}-${day} ${hours}:${minutes}`;
    }
    return newDate;
  }
}

export function ConvertLocaleUTCDatetime(date, type) {
  var dateObj = new Date(date);
  var month = dateObj.getMonth() + 1; //months from 1-12
  var day = dateObj.getDate();
  var year = dateObj.getFullYear();
  var hours = dateObj.getHours();
  var minutes = dateObj.getMinutes();

  var time = "T" + hours + ":" + minutes + ":00";
  // var newTime = time ?? "T00:00:00";
  // if (type === "end" && isEmpty(time)) newTime = "T23:59:00";
  let newDate = year + "-" + month.toString().padStart(2, "0") + "-" + day.toString().padStart(2, "0") + time;

  return newDate;
}

// Creating a dynamic options for select box based on the data collection
export const createOptions = (data, col) => {
  let options = [];
  let option = [
    ...new Set(
      data &&
        data?.map((item) => {
          return item[col];
        })
    ),
  ];
  // Sorting the options alphabetically
  let sorted = option.sort();
  if (col === "priority") {
    const priority = ["High", "Medium", "Low", "OK", "Nuisance", "None", "Undefined"];
    sorted = option.sort((a, b) => priority.indexOf(a) - priority.indexOf(b));
  }
  sorted.forEach((item) => {
    if (item !== null && item !== "") {
      options.push(
        <option key={item} value={item}>
          {col === "ata_chapter" ? getAtaChapter(item) : item}
        </option>
      );
    }
  });
  return options;
};

// Creating a dynamic list based on the data collection
export const uniqueList = (data, col, airportList) => {
  let options = [];
  let option = [
    ...new Set(
      data &&
        data?.map((item) => {
          return item[col];
        })
    ),
  ];
  // Sorting the options alphabetically
  let sorted = option.sort();
  if (col === "priority") {
    const priority = ["High", "Medium", "Low", "OK", "Nuisance", "None", "Undefined", "Null", "Missing"];
    sorted = option.sort((a, b) => priority.indexOf(a) - priority.indexOf(b));
  }
  sorted.forEach((item) => {
    if (item !== null && item !== "" && item !== " ") {
      options.push(
        col === "ata_chapter"
          ? getAtaChapter(item)
          : col === "departure_airport_format" || col === "destination_airport_format"
          ? GetIATAIn(item, airportList)
          : item
        // col === "ata_chapter" ? getAtaChapter(item) : item
      );
    }
  });
  return options;
};

// Creating a dynamic list based on the data collection for multi select dropdown
export const uniqueListMultiSelect = (data, col) => {
  let options = [];
  let option = [
    ...new Set(
      data &&
        data?.map((item) => {
          return item[col];
        })
    ),
  ];
  // Sorting the options alphabetically
  let sorted = option.sort();
  if (col === "priority") {
    const priority = ["High", "Medium", "Low", "OK", "Nuisance", "None", "Undefined"];
    sorted = option.sort((a, b) => priority.indexOf(a) - priority.indexOf(b));
  }
  sorted.forEach((item) => {
    if (item !== null && item !== "" && item !== undefined) {
      options.push({ label: item, value: item });
    }
  });
  return options;
};

// Calculate percentage remaining between two dates from current date
export const calculatePercentage = (data) => {
  const OffUTC = new Date(data?.off_datetime_format + "Z");
  const EtaUTC = new Date(data?.eta_datetime_format + "Z");
  const CurrentUTC = new Date();
  const TotalUTC = EtaUTC - OffUTC;
  const RemainingUTC = EtaUTC - CurrentUTC;
  const Percentage = (RemainingUTC / TotalUTC) * 100;
  if (data?.flight_no === null || data?.flight_no === undefined || data?.flight_no === "") return 0;
  else if (data?.eta_datetime_format_view === null) {
    return 0;
  } else if (Percentage > 100 || Percentage < 0 || data?.on_datetime_format !== null) {
    return 100;
  } else return 100 - Percentage;
};

export const LinearProgressBar = () => {
  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgress />
    </Box>
  );
};

// Function to convert timestamp
export const formatDate = (dateString, type, localTime, separator, from) => {
  // If date is null, return n/a
  if (dateString === null || dateString === undefined || dateString === "") {
    return "n/a";
  }
  // Options for date formatting
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  let returnValue = "";
  let returnDate = "";
  let returnTime = "";
  let returnDatetime = "";
  let returnTimeShort = "";
  function setSeparator() {
    if (separator) return ",";
    else return " ";
  }
  if (localTime) {
    if (dateString.includes("Z") === false) dateString = dateString + ".000Z";
    const date = new Date(dateString).toLocaleDateString(undefined, options); // Convert date to local date
    const DateUTC = date.toLocaleString("en-US", options).split(",")[0]; // Split date to get UTC date
    const TimeUTC = date.toLocaleString().split(",")[1]; // Split date to get UTC time
    returnDatetime =
      DateUTC?.split("/")[2] +
      "-" +
      DateUTC?.split("/")[1]?.padStart(2, "0") +
      "-" +
      DateUTC?.split("/")[0]?.padStart(2, "0") +
      " " +
      TimeUTC?.split(":")[0] +
      ":" +
      TimeUTC?.split(":")[1];
    returnDate =
      DateUTC?.split("/")[2] +
      "-" +
      DateUTC?.split("/")[1]?.padStart(2, "0") +
      "-" +
      DateUTC?.split("/")[0]?.padStart(2, "0");
    if (from === "maintenance") {
      returnTime = TimeUTC?.split(":")[0] + ":" + TimeUTC?.split(":")[1];
    } else {
      returnTime = TimeUTC?.split(":")[0] + ":" + TimeUTC?.split(":")[1] + ":" + TimeUTC?.split(":")[2];
    }
    returnTimeShort = TimeUTC?.split(":")[0] + ":" + TimeUTC?.split(":")[1];
  } else {
    let timeOnly = dateString?.split("T")[1];
    returnDatetime =
      dateString?.split("T")[0] + setSeparator() + " " + timeOnly?.split(":")[0] + ":" + timeOnly?.split(":")[1];
    returnTime = timeOnly?.split(":")[0] + ":" + timeOnly?.split(":")[1];
    returnDate = dateString?.split("T")[0];
    returnValue = returnDatetime;
    returnTimeShort = returnTime;
  }
  type === "date"
    ? (returnValue = returnDate)
    : type === "time"
    ? (returnValue = returnTime)
    : type === "timeShort"
    ? (returnValue = returnTimeShort)
    : (returnValue = returnDatetime);
  return returnValue;
};

export const filterFaultsByLegs = () => {};

export const GetRepetitive = (ata_chapter, previousLegsWrn, previouslegs) => {
  // let repetitive = 0;
  // let repetitiveCount = 0;
  // let rep = "NC";
  // let concurence = false;
  // let repArray = [];
  // let count = 0;
  // previouslegs?.map((leg, index, element) => {
  //   let flight_no = leg?.flight_no;
  //   count = 0;
  //   previousLegsWrn?.map((wrn) => {
  //     if (wrn?.flight_no === leg?.flight_no && wrn?.ata_chapter === ata_chapter) count++;
  //   });
  //   repArray.push({ flight_no: flight_no, count: count });
  // });
  // repArray?.map((entry) => {
  //   if (entry.count > 0) {
  //     repetitive++;
  //     repetitiveCount++;
  //   } else repetitive--;
  //   if (repetitive >= 4) concurence = true;
  // });
  // if (repetitive >= 4 || concurence === true || repetitiveCount >= 6) rep = "C";
  // return rep;
  return "NC";
};

export const formatDateLocaleString = (date, separator) => {
  if (date !== null) {
    const dateStr = date.split(separator);
    const day = dateStr[0];
    const month = dateStr[1];
    const year = dateStr[2];
    switch (month) {
      case "01":
        return `${day} Jan ${year}`;
      case "02":
        return `${day} Feb ${year}`;
      case "03":
        return `${day} Mar ${year}`;
      case "04":
        return `${day} Apr ${year}`;
      case "05":
        return `${day} May ${year}`;
      case "06":
        return `${day} Jun ${year}`;
      case "07":
        return `${day} Jul ${year}`;
      case "08":
        return `${day} Aug ${year}`;
      case "09":
        return `${day} Sep ${year}`;
      case "10":
        return `${day} Oct ${year}`;
      case "11":
        return `${day} Nov ${year}`;
      case "12":
        return `${day} Dec ${year}`;
      default:
        return `${day}-${month}-${year}`;
    }
  }

  return "";
};

export const formatDateToBQFormat = (date) => {
  if (date !== undefined) {
    const datetimeStr = date.split(", ");
    const dateStr = datetimeStr[0].split("/");
    const day = dateStr[0];
    const month = dateStr[1];
    const year = dateStr[2];
    return year + "-" + month + "-" + day + "T";
    // return date;
  }
};

export const formatShortDate = (date) => {
  if (date !== undefined) {
    const dateStr = date.split("-");
    const day = dateStr[2];
    const month = dateStr[1];
    const year = dateStr[0];
    switch (month) {
      case "01":
        return `Jan, ${day} ${year}`;
      case "02":
        return `Feb, ${day} ${year}`;
      case "03":
        return `Mar, ${day} ${year}`;
      case "04":
        return `Apr, ${day} ${year}`;
      case "05":
        return `May, ${day} ${year}`;
      case "06":
        return `Jun, ${day} ${year}`;
      case "07":
        return `Jul, ${day} ${year}`;
      case "08":
        return `Aug, ${day} ${year}`;
      case "09":
        return `Sep, ${day} ${year}`;
      case "10":
        return `Oct, ${day} ${year}`;
      case "11":
        return `Nov, ${day} ${year}`;
      case "12":
        return `Dec, ${day} ${year}`;
      default:
        return `${day}-${month}-${year}`;
    }
  }
};

export const formatDateDasr = (inputDate) => {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  if (inputDate !== undefined && inputDate !== null && inputDate !== "") {
    if (inputDate.includes("T")) inputDate = inputDate.split("T")[0];
    const dateParts = inputDate?.split("-");
    const year = dateParts[0];
    const month = months[parseInt(dateParts[1]) - 1];
    const day = parseInt(dateParts[2]);

    return `${month} ${day}, ${year}`;
  }

  return "-";
};

// Function to adding days into the date
export const addingDate = (date, days) => {
  if (date && days) {
    var date1 = new Date(date);
    date1.setDate(date1.getDate() + parseInt(days));

    return date1?.toISOString();
  }
  return;
};

// A function to calculate remaining days from given date
export const calculateBalanceDay = (date1, date2) => {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

  const firstDate = new Date(date1);
  const secondDate = new Date(date2);

  let diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
  if (firstDate.getTime() > secondDate.getTime()) diffDays = Math.abs(diffDays) * -1;
  return diffDays;
};

export const formatTimeDasr = (inputDate) => {
  // Function to format the time as HH:MM AM/PM
  function formatTime(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    // var ampm = hours >= 12 ? "PM" : "AM";
    // hours = hours % 12;
    // hours = hours ? hours : 12;
    return hours.toString().padStart(2, "0") + ":" + (minutes < 10 ? "0" : "") + minutes;
  }

  if (!inputDate) return "-";
  const date = new Date(inputDate);

  var dateUtcPlus8 = new Date(date.getTime() + 8 * 60 * 60 * 1000);

  return formatTime(dateUtcPlus8);

  // const timeOptions = {
  //   hour: "numeric",
  //   minute: "numeric",
  //   hour12: true,
  // };

  // return date.toLocaleTimeString(undefined, timeOptions);
};

export const formatTimeLocal = (inputDate) => {
  if (inputDate !== undefined && inputDate !== null) {
    let time = inputDate.split("T")[1];
    let hours = time.split(":")[0];
    let minutes = time.split(":")[1];

    return hours.toString().padStart(2, "0") + ":" + minutes;
  }
  return;
};

export const GetMonthShort = (month) => {
  switch (month) {
    case "01":
      return "Jan";
    case "02":
      return "Feb";
    case "03":
      return "Mar";
    case "04":
      return "Apr";
    case "05":
      return "May";
    case "06":
      return "Jun";
    case "07":
      return "Jul";
    case "08":
      return "Aug";
    case "09":
      return "Sep";
    case "10":
      return "Oct";
    case "11":
      return "Nov";
    case "12":
      return "Dec";
    default:
      return "Undefined";
  }
};

export const formatRepetitive = (item) => {
  return (
    <>
      <span>{item}</span>
    </>
  );
};

export function createUTCdateForISO(dateString) {
  const offset = new Date().getTimezoneOffset();
  const myDate = Date.parse(dateString) - offset * 60 * 1000;
  const dateAsISO = new Date(myDate).toISOString();

  return dateAsISO;
}

export const isMac = () => {
  return /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
};

export function setAOCBasedOnPermission(AOC, userPermission) {
  let AllAOC = AOCList.map((a) => a.code);

  // get airaisa AOC only
  let airasiaAOC = AOCListV2?.find((item) => item.airline === "airasia");
  airasiaAOC = airasiaAOC?.AOC?.map((a) => a.code);

  if (Array.isArray(AOC)) {
    if (userPermission.some((permission) => permission.code === "FLEET-001")) {
      return airasiaAOC;
    } else {
      let _aoc = AOC.filter((a) => {
        return AOCList.some((b) => {
          return a === b.code;
        });
      });

      // temporary checking for multiple AOC based on firebase
      if (userPermission.some((permission) => permission.code.startsWith("AOC"))) {
        userPermission.some((permission) => {
          if (permission.code.startsWith("AOC")) {
            _aoc.push(permission.code.split("-").pop());
          }
        });
      }

      return _aoc;
    }
  }
}

// Function to calculate balance day
export function calculateCalendarBalance(calendarDate, interval) {
  if (calendarDate !== null && calendarDate !== "" && interval !== null && interval !== "") {
    let currentDate = new Date();
    let date = new Date(calendarDate);

    // Adding interval
    date.setDate(date.getDate() + parseInt(interval));

    // Calculate the time difference
    let timeDifference = date - currentDate;

    // Convert the time difference to days
    let daysDifference = timeDifference / (1000 * 60 * 60 * 24);

    return Math.round(daysDifference) - 1;
  }
  return "-";
}

// Function to calculate expiry date for "calendarDays" attribute
export function calculateCalendarExpiryDate(raisedDate, interval) {
  if (raisedDate !== undefined && raisedDate !== null && raisedDate !== "" && interval !== null && interval !== "") {
    let date = new Date(raisedDate);

    // Adding interval
    date.setDate(date.getDate() + parseInt(interval));
    // Return formatted date based on format "Month Day, Year"
    return formatDateDasr(date?.toISOString());
  }
  return "-";
}

// Function to calculate balance day
export function calculateCalendarBalanceDate(raisedDate, interval) {
  if (raisedDate !== null && raisedDate !== "" && interval !== null && interval !== "") {
    var date1 = new Date(raisedDate);
    date1?.setDate(date1?.getDate() + parseInt(interval));

    return formatDateDasr(date1?.toISOString());
  }
  return "-";
}

// Function to convert dat to 'YYYY-MM-DD HH:MM'
export function changeFormatDate(date) {
  if (date !== undefined) {
    let DateTime = date.split(".")[0]; // Remove last .000Z suffix
    let Date = DateTime.split("T")[0];
    let Time = DateTime.split("T")[1].split(":")[0] + ":" + DateTime.split("T")[1].split(":")[1];

    return Date + " " + Time;
  }
}
