//No Day
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export const toShortDateTimeString = (dateStr, offset) => {
  const format = "MMM DD YYYY HH:mm";

  let date = dayjs(dateStr).format(format);

  if (offset) {
    date = dayjs(dateStr).utc().utcOffset(offset).format(format);
  }

  return date;
};
