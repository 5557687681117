import { cond, constant, isEqual } from "lodash/fp";
export const lineMaintenanceLocation = {
  KUALA_LUMPUR: "Kuala Lumpur (HQ)",
  PENANG: "Penang",
  LANGKAWI: "Langkawi",
  JOHOR_BAHARU: "Johor Baharu",
  KUCHING: "Kuching",
};

export const toStationIni = cond([
  [isEqual(lineMaintenanceLocation.KUALA_LUMPUR), constant("KUL")],
  [isEqual(lineMaintenanceLocation.PENANG), constant("PNG")],
  [isEqual(lineMaintenanceLocation.LANGKAWI), constant("LGK")],
  [isEqual(lineMaintenanceLocation.JOHOR_BAHARU), constant("JBH")],
  [isEqual(lineMaintenanceLocation.KUCHING), constant("KCH")],
]);
