
import React from 'react';
import { Box, Button } from "@mui/material";
import PageTemplate from "../../components/templates/page-template";
import DeviceListAll from "./DeviceListAll";
import WidgetDeviceStatus from "../../components/common-component/WidgetDeviceStatus";
import { useState } from "react";
export default function Main() {
    const [showAdd, setShowAdd] = useState(false);
    return (<PageTemplate title="Device Setting" topRightActions={[
            {
                component: (<Button onClick={() => {
                        setShowAdd(true);
                    }} sx={{
                        margin: '1em',
                        marginRight: '0px',
                        textTransform: 'none',
                    }} variant='contained'>
                        + Add new Device
                    </Button>)
            }
        ]}>
            <Box sx={{ position: 'relative' }}>
                <Box sx={{
            marginBottom: "1em"
        }}>
                    <WidgetDeviceStatus />
                </Box>
                <Box sx={{ marginBottom: "1em" }}/>
                <DeviceListAll showAdd={showAdd} setShowAdd={setShowAdd}/>
                <Box sx={{ width: "100%", height: "2em", }}/>
            </Box>
        </PageTemplate>);
}
