/* eslint-disable no-unused-expressions */
import React from 'react';
import { Box, Grid, Typography, Divider, Button } from "@mui/material";
import LoadingInput from "../../../components/inputs/LoadingInput";
import StyledDateInput from "../../../components/inputs/dates/StyledDateInput";
import StyledDropDown from "../../../components/inputs/dropdowns/StyledDropDown";
import StyledTextField from "../../../components/inputs/text";
import { useEffect, useState } from "react";
import { useUpdateEquipmentDatas } from "./useUpdateEquipmentDatas";
import { useNotification } from "../../../components/notifications/UniversalNotification";
import GridItem from "./GridItem";
import useUpdateEquipmentStates from "./useUpdateEquipmentStates";
import { getStrDateTimeUtcBased } from "../../../utils/dates";
import { useDialog } from "../../../components/dialog-box/DialogInterface";

export default function UpdateEquipment({ node, refetch, onClose }) {
	const [searchSeatNo, setSearchSeatNo] = useState();
	const updateEqStates = useUpdateEquipmentStates();
	const openDialog = useDialog();
	const [isOccupied, setIsOccupied] = useState(false);
	const {
		locNoData,
		// locTypeData,
		// regNosData,
		modelsData,
		locNoIsLoading,
		locTypeIsLoading,
		modelsIsLoading,
		regNosIsLoading,
		locNoOpts,
		locTypeOpts,
		modelsOpts,
		regNosOpts,
		mutateUpdate,
		mutateDelete,
		itemTypeIsLoading,
		usingItemTypeData,
		statusesIsLoading,
		usingStatusesData,
		checkIsLocationOccupiedData,
		checkIsLocationOccupiedIsLoading,
	} = useUpdateEquipmentDatas({
		node,
		serialNo: updateEqStates?.serialNo,
		eqType: updateEqStates?.eqType,
		status: updateEqStates?.status,
		mfgDate: updateEqStates?.mfgDate,
		expDate: updateEqStates?.expDate,
		inputLocNo: updateEqStates?.inputLocNo,
		inputLocType: updateEqStates?.inputLocType,
		inputModel: updateEqStates?.inputModel,
		fleetId: updateEqStates?.fleetId,
		searchSeatNo,
	});
	useEffect(() => {
		updateEqStates?.tagId?.setValue(node?.tag);
		updateEqStates?.serialNo?.setValue(node?.serialNo);
		updateEqStates?.eqType?.setValue(node?.item?.id);
		updateEqStates?.fleetId?.setValue(
			node?.locationlistSet?.edges[0]?.node?.fleetId?.id
		);
		updateEqStates?.inputLocNo?.setValue(
			node?.locationlistSet?.edges[0]?.node?.locationId?.id
		);
		updateEqStates?.status?.setValue(node?.status?.id);
		updateEqStates?.setMfgDate(node?.manufactureDate);
		updateEqStates?.setExpDate(node?.expiryDate);
		updateEqStates?.setInputModel(
			node?.locationlistSet?.edges[0]?.node?.fleetId?.model
		);
		updateEqStates?.setInputLocType(
			node?.locationlistSet?.edges[0]?.node?.locationId?.inventoryLocation
				?.id
		);
	}, [node]);
	// const isLocationOccupied = isOccupisOccupiedChecked(updateEqStates.inputLocNo, node, checkIsLocationOccupiedData)
	const isLocationOccupied = isOccupied;
	const openNotification = useNotification();
	return (
		<Box
			style={{
				width: "100%",
				borderRadius: "0px",
				height: "100%",
				position: "relative",
			}}
		>
			<Box
				style={{
					padding: "1em",
					paddingTop: "0px",
				}}
			>
				<Grid container spacing="1em">
					<GridItem
						title="Tag ID"
						fullWidth={true}
						inputComp={
							<Typography
								sx={{
									border: "1px solid #D1D1D1",
									borderRadius: "8px",
									padding: "0.7em",
									backgroundColor: "lightgrey",
									wordWrap: "break-word",
									wordBreak: "break-all",
									textAlign: "start",
								}}
							>
								{/* {tagId ? tagId.slice(-12) : ''} */}
								{updateEqStates?.tagId?.value ?? ""}
							</Typography>
						}
					/>
					<GridItem
						title="Serial No."
						fullWidth={true}
						inputComp={
							<StyledTextField
								value={updateEqStates?.serialNo.value}
								onChange={(e) => {
									updateEqStates?.serialNo.setValue(
										e.target.value
									);
								}}
								fullWidth
								error={updateEqStates.serialNo?.isError}
								helperText={
									updateEqStates.serialNo?.errorMessage ??
									undefined
								}
							/>
						}
					/>
					<GridItem
						title="Equipment Type"
						fullWidth={false}
						inputComp={
							itemTypeIsLoading ? (
								<LoadingInput />
							) : (
								<StyledDropDown
									error={updateEqStates.eqType?.isError}
									helperText={
										updateEqStates.eqType?.errorMessage ??
										undefined
									}
									value={updateEqStates?.eqType?.value}
									handleChange={(e) => {
										updateEqStates?.eqType.setValue(
											e.target.value
										);
									}}
									options={usingItemTypeData.map(
										({ name, id }) => {
											return {
												label: name,
												value: id,
											};
										}
									)}
								/>
							)
						}
					/>
					<GridItem
						title="Status"
						fullWidth={false}
						isRequired={false}
						inputComp={
							!statusesIsLoading ? (
								<StyledDropDown
									error={updateEqStates.status?.isError}
									helperText={
										updateEqStates.status?.errorMessage ??
										undefined
									}
									value={updateEqStates?.status?.value}
									handleChange={(e) => {
										updateEqStates?.status?.setValue(
											e.target.value
										);
									}}
									options={usingStatusesData.map(
										({ name, id, code }) => {
											return {
												label: statusGetLabelFromCode(
													code
												),
												value: id,
											};
										}
									)}
								/>
							) : (
								<LoadingInput />
							)
						}
					/>
					<GridItem
						isRequired={false}
						title="MFG Date"
						fullWidth={false}
						inputComp={
							<StyledDateInput
								value={updateEqStates?.mfgDate}
								onChange={(date) => {
									updateEqStates?.setMfgDate(date);
								}}
								fullwidth
							/>
						}
					/>
					<GridItem
						isRequired={false}
						title="Expiry Date"
						fullWidth={false}
						inputComp={
							<StyledDateInput
								value={updateEqStates?.expDate}
								onChange={(date) => {
									updateEqStates?.setExpDate(date);
								}}
								fullwidth
							/>
						}
					/>
					<GridItem
						title="A/C Model:"
						inputComp={
							modelsIsLoading ? (
								<LoadingInput />
							) : (
								<StyledDropDown
									value={updateEqStates?.inputModel}
									handleChange={(e) => {
										updateEqStates?.setInputModel(
											e.target.value
										);
									}}
									options={
										modelsData && !modelsIsLoading
											? modelsOpts
											: []
									}
								/>
							)
						}
						isRequired={true}
					/>
					<GridItem
						title="A/C Reg"
						inputComp={
							regNosIsLoading ? (
								<LoadingInput />
							) : (
								<StyledDropDown
									disabled={
										updateEqStates?.inputModel
											? false
											: true
									}
									value={updateEqStates?.fleetId?.value}
									handleChange={(e) => {
										const fleetIdFromInput = e.target.value;
										updateEqStates?.fleetId?.setValue(
											fleetIdFromInput
										);
									}}
									options={regNosOpts}
								/>
							)
						}
						isRequired={true}
					/>
					<GridItem
						title="Location Type"
						inputComp={
							locTypeIsLoading ? (
								<LoadingInput />
							) : (
								<StyledDropDown
									disabled={
										updateEqStates?.fleetId?.value
											? false
											: true
									}
									value={updateEqStates?.inputLocType}
									handleChange={(e) => {
										updateEqStates?.setInputLocType(
											e.target.value
										);
									}}
									options={locTypeOpts}
								/>
							)
						}
						isRequired={true}
					/>
					<GridItem
						title="Location Number"
						inputComp={
							locNoIsLoading && searchSeatNo === undefined ? (
								<LoadingInput />
							) : (
								<StyledDropDown
									onSearch={(val) => {
										setSearchSeatNo(val);
									}}
									disabled={
										updateEqStates?.inputLocType
											? false
											: true
									}
									value={updateEqStates?.inputLocNo?.value}
									handleChange={(e) => {
										const foundNode = locNoData.find(
											({ node }) => {
												return (
													node?.id === e.target.value
												);
											}
										);
										if (
											node?.locationlistSet?.edges[0]
												?.node?.locationId?.id ===
											e.target.value
										) {
											setIsOccupied(false);
										} else if (
											foundNode?.node?.occupied === null
										) {
											setIsOccupied(false);
										} else {
											setIsOccupied(true);
										}
										updateEqStates?.inputLocNo.setValue(
											e.target?.value
										);
										setSearchSeatNo(undefined);
									}}
									options={locNoOpts}
								/>
							)
						}
						isRequired={true}
					/>
					<Grid
						item
						xs={6}
						sx={{
							marginBottom: "0.5em",
							paddingTop: "10px !important",
						}}
					>
						<Typography sx={{ fontSize: "12px", color: "#B1B1B1" }}>
							Created on{" "}
							{getStrDateTimeUtcBased(new Date(node?.createdAt))}{" "}
							<br />
							by {node?.createdBy}
						</Typography>
					</Grid>
					<Grid item xs={6} sx={{ paddingTop: "10px !important" }}>
						<Typography sx={{ fontSize: "12px", color: "#B1B1B1" }}>
							updated on{" "}
							{getStrDateTimeUtcBased(new Date(node?.updatedAt))}{" "}
							<br />
							by {node?.updatedBy}
						</Typography>
					</Grid>
				</Grid>

				<Box
					sx={{
						position: "relative",
						bottom: "0px",
						left: "0px",
						width: "100%",
					}}
				>
					<Divider sx={{ marginTop: "1em" }} />
					<Grid container>
						<Grid
							item
							xs={4}
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<Button
								onClick={() => {
									onClose();
								}}
								sx={{
									height: "37px",
									marginLeft: "1em",
									marginRight: "1em",
									marginTop: "1em",
									marginBottom: "0px",
									width: "100%",
									borderRadius: "8px",
								}}
							>
								Cancel
							</Button>
						</Grid>
						<Grid
							item
							xs={4}
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<Button
								variant="contained"
								color="primary"
								disabled={checkIsLocationOccupiedIsLoading}
								sx={{
									height: "37px",
									marginLeft: "1em",
									marginRight: "1em",
									marginTop: "1em",
									marginBottom: "0px",
									width: "100%",
									borderRadius: "8px",
								}}
								onClick={() => {
									if (
										updateEqStates?.tagId?.value &&
										updateEqStates?.serialNo?.value &&
										updateEqStates?.eqType.value
										// && mfgDate && expDate
									) {
										const updateItem = () => {
											openNotification(
												"Update this item?",
												{
													variant: "info",
													onConfirm: () => {
														mutateUpdate().then(
															({
																isError,
																errorMessage,
															}) => {
																if (!isError) {
																	openNotification(
																		"Equipment updated!",
																		{
																			variant:
																				"info",
																			onClose:
																				() => {
																					onClose();
																					refetch();
																				},
																		}
																	);
																} else {
																	openNotification(
																		"Saving failed!",
																		{
																			variant:
																				"error",
																			helperText: `There was an error saving you changes. Error ${errorMessage}`,
																		}
																	);
																}
															}
														);
													},
												}
											);
										};
										if (!isLocationOccupied) {
											updateItem();
										} else {
											openDialog(
												<Typography
													sx={{
														fontWeight: "bold",
														justifyContent:
															"center",
													}}
												>
													Equipment Replacement
												</Typography>,
												<Typography>
													The selected location is
													assigned with an equipment.
													Doing so will replace it.
													Are you sure you want to
													proceed?
												</Typography>,
												{
													onConfirm: () => {
														updateItem();
													},
												}
											);
										}
									} else {
										// openNotification('Please fill in all the required field(s)', { variant: 'warning' })
										// do nothing instead
									}
								}}
							>
								{checkIsLocationOccupiedIsLoading
									? "Loading..."
									: "Save"}
							</Button>
						</Grid>
						<Grid
							item
							xs={4}
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<Button
								variant="contained"
								color="warning"
								sx={{
									height: "37px",
									marginLeft: "1em",
									marginRight: "1em",
									marginTop: "1em",
									marginBottom: "0px",
									width: "100%",
									borderRadius: "8px",
								}}
								onClick={() => {
									openNotification(
										`Delete item with tag : ${node?.tag.slice(
											-12
										)} ?`,
										{
											variant: "warning",
											onConfirm: () => {
												mutateDelete().then(
													({
														isError,
														errorMessage,
													}) => {
														if (!isError) {
															openNotification(
																"Successfully deleted",
																{
																	variant:
																		"info",
																	onClose:
																		() => {
																			onClose();
																			refetch();
																		},
																}
															);
														} else {
															openNotification(
																`Error : ${errorMessage}`,
																{
																	variant:
																		"error",
																}
															);
														}
													}
												);
											},
										}
									);
								}}
							>
								Delete
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</Box>
	);
}
function statusGetLabelFromCode(code) {
	let labelHolder = "Active";
	switch (code) {
		case "A":
			labelHolder = "Active";
			break;
		case "L":
			labelHolder = "Missing";
			break;
		case "R":
			labelHolder = "Near Expiry";
			break;
		case "E":
			labelHolder = "Expired";
			break;
		case "T":
			labelHolder = "Error";
			break;
		case "D":
			labelHolder = "Defect";
			break;
		default:
			labelHolder = "Active";
			break;
	}
	return labelHolder;
}
// function isOccupiedCheck(inputLocNo: UseInputStateReturnProps, node: ToBeUpdateNode, checkIsLocationOccupiedData: any): boolean {
//     let isLocationOccupied = true;
//     if (inputLocNo?.value === node?.locationlistSet?.edges[0]?.node?.locationId?.id) {
//         isLocationOccupied = false
//     } else if (checkIsLocationOccupiedData?.edges?.length < 1) {
//         isLocationOccupied = false
//     } else if (checkIsLocationOccupiedData?.edges?.length > 0 && checkIsLocationOccupiedData?.edges[0]?.node?.inventory === null) {
//         isLocationOccupied = false
//     } else {
//         isLocationOccupied = true
//     }
//     return isLocationOccupied;
// }
