import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";

let config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

export const app = firebase.initializeApp(config);
export const analytics = firebase.analytics()
export const storage = firebase.storage()

// if (window.location.hostname  === "localhost") {
// if (process.env.REACT_APP_ENV === "localhost") {
//   app.functions().useEmulator("http://localhost:5001");
//   app.auth().useEmulator("http://localhost:9099");
//   app.firestore().settings({
//     host: "localhost:8080",
//     ssl: false,
//   });
// }

export const facebookProvider = new firebase.auth.FacebookAuthProvider();

facebookProvider.addScope("email");
facebookProvider.setCustomParameters({ display: "popup" });
