import React from "react";
import { Grid } from "@mui/material";
import styles from "./Sorting.module.css";
import SortAsc from "common/images/icons/elevade/SortAsc.svg";
import SortAscActive from "common/images/icons/elevade/SortAsc-active.svg";
import SortDesc from "common/images/icons/elevade/SortDesc.svg";
import SortDescActive from "common/images/icons/elevade/SortDesc-active.svg";

const Sorting = ({ title, col, sortingColumn, setSortingColumn, sortingDirection, setSortingDirection }) => {
  function handleSortingColumn(col) {
    setSortingDirection(sortingDirection === "asc" && col === sortingColumn ? "desc" : "asc");
    setSortingColumn(col);
  }

  return (
    <>
      <Grid container columns={1}>
        <Grid item xs={1} onClick={() => handleSortingColumn(col)}>
          <div className={`${styles.sortingColumn}`}>{title}</div>
          <div className={`${styles.sorting} ${sortingColumn === col ? styles.sortingActive : styles.sorting}`}>
            <Grid container columns={1}>
              <Grid item xs={1} sx={{ height: "8px", display: "flex", justifyContent: "center" }}>
                <img
                  src={sortingDirection === "asc" && sortingColumn === col ? SortAscActive : SortAsc}
                  alt="sort-asc"
                  height={6}
                  className={`${styles.sortingIcon}`}
                  // onClick={() => handleSorting(col, "asc")}
                />
              </Grid>
              <Grid item xs={1} sx={{ height: "8px", display: "flex", justifyContent: "center", paddingRight: "1px" }}>
                <img
                  src={sortingDirection === "desc" && sortingColumn === col ? SortDescActive : SortDesc}
                  alt="sort-desc"
                  height={6}
                  className={`${styles.sortingIcon}`}
                  // onClick={() => handleSorting(col, "desc")}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default Sorting;
