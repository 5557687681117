import { env_var_REACT_APP_API_URL } from "../../envVar/vars";
import useGetGQL from "../useGetGQL";
import useMutateGQL from "../useMutateGQL";

export function useGetAllManufacturers() {

	const allManufacturers = useGetGQL({
		gqlquery: `
		query eqType {
		  allManufacturers {
			  edges {
				node {
				  id
				  code
				  name
				}
			  }
			}
		  }
		`,
		querySign: 'allManufacturers',
		url: env_var_REACT_APP_API_URL ?? '',
		defaultIsLoading: true,
		isEdges: true,
	});
	
	return allManufacturers

}

export function useGetAllDeviceModels({manufacturerID}) {

	const allDeviceModels = useGetGQL({
		gqlquery: `
	  query allDeviceModels{
		   allDeviceModels (manufacturer:"${manufacturerID}") {
			edges{
			  node{
				id
				name
				code
			  }
			}
		  }
		}
	  `,
		querySign: 'allDeviceModels',
		url: env_var_REACT_APP_API_URL ?? '',
		defaultIsLoading: true,
	});

	return allDeviceModels
}

export function useGetAllDeviceStatuses() {
	const allDeviceStatuses = useGetGQL({
		gqlquery: `
		query allDeviceStatuses {
		  allDeviceStatuses {
			  edges{
				node{
				  id
				  code
				  name
				}
			  }
			}
		  }
		`,
		querySign: 'allDeviceStatuses',
		url: env_var_REACT_APP_API_URL ?? '',
		defaultIsLoading: true,
		isEdges: true,
	});

	return allDeviceStatuses
}

export function useUpdateDeviceDatas({ manufacturerID, node, status, }) {
    const allManufacturers = useGetGQL({
        gqlquery: `
        query eqType {
          allManufacturers {
              edges {
                node {
                  id
                  code
                  name
                }
              }
            }
          }
        `,
        querySign: 'allManufacturers',
        url: env_var_REACT_APP_API_URL ?? '',
        defaultIsLoading: true,
        isEdges: true,
    });
    const allDeviceModels = useGetGQL({
        gqlquery: `
      query allDeviceModels{
           allDeviceModels (manufacturer:"${manufacturerID}") {
            edges{
              node{
                id
                name
                code
              }
            }
          }
        }
      `,
        querySign: 'allDeviceModels',
        url: env_var_REACT_APP_API_URL ?? '',
        defaultIsLoading: true,
    });
    const allDeviceStatuses = useGetGQL({
        gqlquery: `
        query allDeviceStatuses {
          allDeviceStatuses {
              edges{
                node{
                  id
                  code
                  name
                }
              }
            }
          }
        `,
        querySign: 'allDeviceStatuses',
        url: env_var_REACT_APP_API_URL ?? '',
        defaultIsLoading: true,
        isEdges: true,
    });
    // const { mutate: mutateDelete } = useMutateGQL({
    //     gqlquery: `
    //       mutation deleteEQ {
    //           deleteInventories (input:{
    //             id:"${node?.id ?? ""}"
    //           }){success}
    //         }
    //       `,
    //     url: env_var_REACT_APP_API_URL ?? "",
    // });
    // const { mutate: mutateUpdate } = useMutateGQL({
    //     gqlquery: () => {
    //         return `
    //             mutation updateScanner {
    //               updateScanner (input:{
    //                 id:"${node?.id ?? ""}"
    //                 ${status?.value ? `status:"${status?.value}"` : ''}
    //               }){
    //                 scanner {
    //                   id
    //                   status {
    //                     name
    //                   }
    //                 }
    //               }
    //             }
    //           `;
    //     },
    //     url: env_var_REACT_APP_API_URL ?? ""
    // });
    return {
        allManufacturers,
        allDeviceModels,
        allDeviceStatuses
    };
}
