import React from "react";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { useAppSelector } from "../../../../app/hooks";
import {
	useGetAllLocationInventoryDetailsFiltered,
	useGetAllInvLocations,
} from "../../../../models/custom/location-inventory-model";
import {
	useGetAllFleetDistincted,
	useGetAllFleetByCompanyCodeSearchedByModel,
} from "../../../../models/custom/fleet-model";
import {
	useGetAllInventoryItems,
} from "../../../../models/custom/inventory-item-model";
import {
	useGetAllItemStatuses,
} from "../../../../models/custom/ac-item-model";
import {
	useDeleteInventory,
	useUpdateInventory,
} from "../../../../models/custom/inventory-model";
import {
	useGetAllLocations,
} from "../../../../models/custom/location-model";

export function useUpdateEquipmentDatas({ inputLocType, inputModel, serialNo, eqType, status, mfgDate, expDate, node, fleetId, inputLocNo, searchSeatNo, }) {

    const { data: locNoData, isLoading: locNoIsLoading, } = useGetAllLocationInventoryDetailsFiltered({
		fleetId : fleetId?.value,
		searchSeatNo,
		inventoryLocId: inputLocType
	});
    
	const { data: locTypeData, isLoading: locTypeIsLoading, } = useGetAllInvLocations();

    const { data: modelsData, isLoading: modelsIsLoading, } = useGetAllFleetDistincted();

    const { data: regNosData, isLoading: regNosIsLoading, } = useGetAllFleetByCompanyCodeSearchedByModel({inputModel});

    const modelsOpts = [];
    modelsData.map(({ node }) => {
        if (node?.model) {
            modelsOpts.push({
                value: node?.model,
                label: node?.model,
            });
        }
    });
    const regNosOpts = [];
    regNosData.map(({ node }) => {
        if (node?.regNo) {
            regNosOpts.push({
                value: node?.id,
                label: node?.regNo,
            });
        }
    });
    const locTypeOpts = [];
    locTypeData.map(({ node }) => {
        if (node?.id) {
            locTypeOpts.push({
                value: node?.id,
                label: node?.name,
            });
        }
    });
    const locNoOpts = [];
    locNoData.map(({ node: innerNode }) => {
        if (innerNode?.seatNo) {
            locNoOpts.push({
                value: innerNode?.id,
                label: innerNode?.id === node?.locationlistSet?.edges[0]?.node?.locationId?.id ? (<Box sx={{ width: '100%' }}>
              <Grid container>
                <Grid xs={2}>
                  {/* nothing here */}
                </Grid>
                <Grid xs={10}>
                  <Typography>
                    <span style={{
                        fontWeight: 'bolder',
                    }}>{innerNode?.seatNo}</span>
                    {/* <Chip label={'current'} size="small" color="info" sx={{ marginLeft: '0.5em' }} /> */}
                  </Typography>
                </Grid>
              </Grid>
            </Box>) : (<Box sx={{ width: '100%' }}>
              <Grid container>
                <Grid xs={2}>
                  {innerNode?.occupied === true ?
                        (<Tooltip title='Occupied'>
                        <span style={{ cursor: 'pointer' }}>
                          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.875 9.625L7.75 11.5L12.125 7.125M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z" stroke="#6AB47B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </span>
                      </Tooltip>) : (<Tooltip title='Available'>
                        <span style={{ cursor: 'pointer' }}>
                          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#C3C3C3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </span>
                      </Tooltip>)}
                </Grid>
                <Grid xs={10}>
                  <Typography>
                    {innerNode?.seatNo}
                  </Typography>
                </Grid>
              </Grid>
            </Box>),
            });
        }
    });

    const { data: itemTypeData, isLoading: itemTypeIsLoading, } = useGetAllInventoryItems();

    const { data: statusesData, isLoading: statusesIsLoading, } = useGetAllItemStatuses();

    const usingStatusesData = statusesData.map(({ node }) => {
        return { ...node };
    });
    const usingItemTypeData = itemTypeData.map(({ node }) => {
        return { ...node };
    });
    const { mutate: mutateDelete } = useDeleteInventory({ invId:node?.id});
	
    const { mutate: mutateUpdate } = useUpdateInventory({	
		invId: node?.id,
		serialNo,
		eqType,
		status,
		fleetId,
		inputLocNo,
		mfgDate,
		expDate,
	 });

    const { data: checkIsLocationOccupiedData, isLoading: checkIsLocationOccupiedIsLoading, } = useGetAllLocations({
		fleetId: fleetId?.value,
		locationId: inputLocNo?.value,
	});

    return {
        locNoData,
        locNoIsLoading,
        locTypeData,
        locTypeIsLoading,
        modelsData,
        modelsIsLoading,
        regNosData,
        regNosIsLoading,
        modelsOpts,
        regNosOpts,
        locTypeOpts,
        locNoOpts,
        mutateUpdate,
        mutateDelete,
        itemTypeIsLoading,
        usingItemTypeData,
        statusesIsLoading,
        usingStatusesData,
        checkIsLocationOccupiedData,
        checkIsLocationOccupiedIsLoading,
    };
}
