import React, { useEffect, useState } from "react";
import { Grid, styled, Tab, Avatar, Tooltip, tooltipClasses, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import styles from "../Dasr.module.css";
import IconErrorAirBalloon from "common/images/icons/elevade/Icon-ErrorAirBalloon.svg";
import DDML from "../aircraftReport/DDML";
import SearchBar from "./SearchBar";
import ActiveFilters from "./ActiveFilters";
import { formatDateDasr } from "pages/elevade/component/Helpers";
import MUIPagination from "pages/elevade/component/Pagination";
import { makeStyles } from "@mui/styles";
import { makeAPIRequestMDB } from "common/helpers/makeAPIRequestMDB";
import Overlay from "../../component/Overlay";
import NoData from "pages/elevade/component/DataUnavailable";
import IconAirplaneLimitation from "common/images/icons/elevade/Icon-AirplaneLimitation.svg";
import IconRefresh from "common/images/icons/elevade/Icon-Refresh.svg";
import IconReInspection from "common/images/icons/elevade/Icon-ReInspection.svg";
import ElevadeDrawer from "../component/Drawer";
import DateToggle from "../component/DateToggle";
import DialogPush145 from "./DialogPush145";
import DialogSubmitShiftReport from "./DialogSubmitShiftReport";
import { showNotificationElevade } from "common/helpers";
import AirplaneLoader from "pages/elevade/component/AirplaneLoader";

const TaskAssignment = ({ aoc, isLoading, setIsLoading }) => {
  const [subLoading, setSubLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);
  const [type, setType] = useState("1");
  const [value, setValue] = useState("1");
  const [open, setOpen] = useState(false);
  const [openTitle, setOpenTitle] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState([]);
  const [data_, setData_] = useState([]);
  const [dataForm, setDataForm] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [index, setIndex] = useState();
  const [edit, setEdit] = useState(true);
  const [openNotification, setOpenNotification] = useState(false);
  const [newForm, setNewForm] = useState(false);
  const [triggerApi, setTriggerApi] = useState(true);
  const [activeIndex, setActiveIndex] = useState("");
  const [selectToMove, setSelectToMove] = useState([]);
  const [openDialogSubmitShiftReport, setOpenDialogSubmitShiftReport] = useState(false);
  const [openDialogMoveToAssignment, setOpenDialogMoveToAssignment] = useState(false);
  const [isSearch, setIsSearch] = useState([]);
  const InitialFilters = { search: [], location: [], aircraftReg: [], assignedTo: [], raisedDate: [], expiryDate: [] };
  const [isFilters, setIsFilters] = useState(InitialFilters);
  const [filteredData, setFilteredData] = useState([]);
  const [today, setToday] = useState(new Date());
  const [date, setDate] = useState(new Date());
  const [tabbing, setTabbing] = useState(1);
  const [shiftType, setShiftType] = useState("day");

  let sliceStart = page > 0 ? perPage * (page - 1) : 0;
  let sliceEnd = parseInt(perPage) + sliceStart;

  useEffect(() => {
    setIsLoading(false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setData_(
      data?.filter(
        // TODO
        (e) => e.status === "closed"
        // (e) => e.moveToAssignment === true && e.formStatus === "closed" && e?.assignment?.shiftType === shiftType
      )
    );
  }, [data, type]);

  useEffect(() => {
    let totalRows = filteredData?.length;
    let totalPage = Math.floor(filteredData?.length / perPage);
    if (totalRows % perPage !== 0) totalPage = totalPage + 1;
    setTotalRows(totalRows);
    setTotalPage(totalPage);
  }, [filteredData, perPage]);

  useEffect(() => {
    fetch();
    setTableLoading(true);
    // eslint-disable-next-line
  }, [openNotification, triggerApi, date, shiftType, aoc]);

  useEffect(() => {
    setTableLoading(true);
  }, [value]);

  useEffect(() => {
    if (!open) setActiveIndex();
  }, [open]);

  useEffect(() => {
    if (value === "1") setShiftType("day");
    else if (value === "2") setShiftType("night");
  }, [value]);

  // Applying filters
  useEffect(() => {
    let data__ = [];
    let theData = data;
    setFilteredData(theData);

    // Search input fields
    if (isFilters.search.length !== 0) {
      data__ = theData.filter(
        (item) =>
          isFilters.search.some((searchTerm) =>
            item.ddml.location?.toLowerCase()?.includes(searchTerm?.toLowerCase())
          ) ||
          isFilters.search.some((searchTerm) =>
            item.ddml.aircraftReg?.toLowerCase()?.includes(searchTerm?.toLowerCase())
          ) ||
          isFilters.search.some((searchTerm) => item.ddml.ddmlNo?.toLowerCase()?.includes(searchTerm?.toLowerCase())) ||
          isFilters.search.some((searchTerm) =>
            item.ddml.limitationsDesc?.toLowerCase()?.includes(searchTerm?.toLowerCase())
          ) ||
          isFilters.search.some((searchTerm) =>
            item.ddml.assignedTo?.toLowerCase()?.includes(searchTerm?.toLowerCase())
          )
      );
      setFilteredData(data__);
    }

    // Station (location)
    if (isFilters.location.length > 0)
      setFilteredData(theData.filter((e) => isFilters.location.includes(e.ddml.location)));
    // Aircraft Registration
    if (isFilters.aircraftReg.length > 0)
      setFilteredData(theData.filter((e) => isFilters.aircraftReg.includes(e.ddml.aircraftReg)));
    // Assigned To (Department)
    if (isFilters.assignedTo.length > 0)
      setFilteredData(theData.filter((e) => isFilters.assignedTo.includes(e.ddml.assignedTo)));
    // Raised Date
    if (isFilters.raisedDate.length > 0)
      setFilteredData(theData.filter((e) => e.ddml.raisedDate.startsWith(isFilters.raisedDate)));
    // Expiry Date
    if (isFilters.expiryDate.length > 0)
      setFilteredData(theData.filter((e) => e.ddml.expiryDate.startsWith(isFilters.expiryDate)));
  }, [isFilters, data]);

  function formatDate(dateString) {
    // Parse the input date string
    const dateObj = new Date(dateString);

    // Get the year, month, and day components
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");

    // Construct the formatted date string
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }

  async function fetch() {
    // let shiftType = "day";
    // if (value === "2") shiftType = "night";
    // TODO: To update API to return based on selected AOC
    const fetch = makeAPIRequestMDB(`get`, `api/assignment?aoc=${aoc}&shift=${shiftType}&date=${formatDate(date)}`);
    // const fetch = makeAPIRequestMDB(`get`, `api/assignment-list/${formatDate(date)}`);
    fetch
      .then((response) => {
        setData(response.data);
        setFilteredData(response.data);
        setSubLoading(false);
        setOpen(false);
        setOpenNotification(false);
        setTriggerApi(false);
        setTableLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setSubLoading(false);
        setTableLoading(false);
      });
  }

  // Custom styles
  const useStyles = makeStyles(() => ({
    ul: {
      "& .Mui-selected": {
        color: "#fff",
        backgroundColor: "#088FD1",
        border: "1px solid #088FD1",
        "&:hover": {
          backgroundColor: "#088FD1",
        },
      },
    },
    gridItem: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-box-orient": "vertical",
      "-webkit-line-clamp": 2, // Number of lines to show
      fontSize: "14px",
    },
  }));

  const classes = useStyles();

  const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    // padding: 0,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    fontFamily: ["Gotham-Book"].join(","),
    "&:hover": {
      color: "#333333",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#333333",
      fontFamily: "Gotham-Medium",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  }));

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setType(newValue);
  };

  const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#FFFEE8",
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: 11,
      },
    })
  );

  const ErrorAirBallon = () => {
    return (
      <>
        <div className={styles.errorAirBallon}>
          <center>
            <img src={IconErrorAirBalloon} alt="error-air-ballon" />
            <div className={styles.errorTitle}>No Task Assignment Available</div>
            <div className={styles.errorSubTitle}></div>
          </center>
        </div>
      </>
    );
  };

  const Tips = ({ val, unread }) => {
    return (
      <>
        <span
          style={{
            borderRadius: "30px",
            color: unread ? "#ffffff" : "#838383",
            border: unread ? "1px solid #E95048" : "1px solid #E8E8E8",
            backgroundColor: unread ? "#E95048" : "#F7F7F7",
            fontFamily: "Gotham-Book",
            fontSize: "12px",
            marginLeft: "8px",
            padding: "4px 8px 2px 8px",
          }}
        >
          {val}
        </span>
      </>
    );
  };

  const handleSetTabbing = (e) => {
    setTabbing(e);
  };

  const handleRefresh = () => {
    setTriggerApi(true);
    showNotificationElevade("success", "Page refreshed!", [""]);
  };

  const TaskAssignmentList = ({ type }) => {
    return (
      <>
        <Grid container columns={4} sx={{ marginTop: "32px" }}>
          <Grid item xs={3} onClick={() => setOpenModal(false)}>
            <div
              style={{
                width: "fitContent",
                maxWidth: "540px",
                borderRadius: "4px",
                backgroundColor: "#F9FAFB",
                padding: "4px 2px",
                fontSize: "12px",
                display: "flex",
              }}
            >
              <div
                className={`${styles.tabbing} ${tabbing === 1 ? styles.tabbingActive : ""}`}
                onClick={() => handleSetTabbing(1)}
              >
                DDML List <Tips val={filteredData?.length} unread={false} />
              </div>
              <div
                className={`${styles.tabbing} ${tabbing === 2 ? styles.tabbingActive : ""}`}
                onClick={() => handleSetTabbing(2)}
              >
                Repetitive Defect List <Tips val={0} unread={false} />
              </div>
              <div
                className={`${styles.tabbing} ${tabbing === 3 ? styles.tabbingActive : ""}`}
                onClick={() => handleSetTabbing(3)}
              >
                Additional Task List <Tips val={0} unread={false} />
              </div>
            </div>
          </Grid>
          <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-end", fontSize: "12px" }} onClick={() => setOpenModal(false)}>
            <div style={{ cursor: "pointer" }} onClick={handleRefresh}>
              <img src={IconRefresh} alt="icon-refresh" style={{ marginRight: "8px" }} />
              Refresh
            </div>
          </Grid>
          {data_ !== undefined && data_ !== "" && data_?.length > 0 && (
            <>
              <Grid item xs={4} sx={{ paddingTop: "32px", paddingLeft: "16px" }}>
                <SearchBar
                  open={openModal}
                  setOpen={setOpenModal}
                  data={data_}
                  isSearch={isSearch}
                  setIsSearch={setIsSearch}
                  isFilters={isFilters}
                  setIsFilters={setIsFilters}
                  openTitle={openTitle}
                  setOpenTitle={setOpenTitle}
                  sub={true}
                />
                <ActiveFilters isFilters={isFilters} setIsFilters={setIsFilters} initialFilters={InitialFilters} />
              </Grid>
              <Grid item xs={4} sx={{ display: "flex", paddingTop: "32px" }}>
                {/* <Button
                  title="Delete"
                  icon={IconDustbin}
                  iconWhite={IconDustbinWhite}
                  iconDisabled={IconDustbinDisabled}
                  variant={selectToMove.length > 0 ? "outlined" : "disabled"}
                  onClick={() => setOpenDialogSubmitShiftReport(true)}
                />
                <Button
                  title="Push to 145"
                  icon={IconBriefcase}
                  iconWhite={IconBriefcaseWhite}
                  iconDisabled={IconBriefcaseDisabled}
                  variant={selectToMove.length > 0 ? "outlined" : "disabled"}
                  onClick={() => setOpenDialogMoveToAssignment(true)}
                /> */}
              </Grid>
              <Grid item xs={4} sx={{ paddingTop: "16px" }}>
                {subLoading ? <Overlay /> : <TableList type={type} />}
              </Grid>
            </>
          )}
          {(data?.length === 0 || data_?.length === 0) && !subLoading && (
            <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }} onClick={() => setOpenModal(false)}>
              <NoData />
            </Grid>
          )}
        </Grid>
      </>
    );
  };

  function handleClick(e, index) {
    setOpen(true);
    setIndex(index);
    setDataForm(e.ddml);
    setEdit(false);
    setActiveIndex(index);
    setNewForm(false);
  }

  const TableList = ({ type }) => {
    let data_ = filteredData;
    // TODO
    if (tabbing !== 1) data_ = [];
    return data_.length > 0 && !tableLoading ? (
      <>
        <div
          style={{
            width: "100%",
            borderRadius: "12px",
            overflow: "hidden",
            border: "1px solid #E8E8E8",
          }}
        >
          <Grid container columns={13} onClick={() => setOpenModal(false)}>
            <Grid item xs={0.6} className={styles.tableHeader}></Grid>
            <Grid item xs={0.9} className={styles.tableHeader}>
              Station
            </Grid>
            <Grid item xs={1} className={styles.tableHeader}>
              A/C Reg.
            </Grid>
            <Grid item xs={2} className={`${styles.tableHeader} ${styles.gridJustifyStart}`}>
              Defect Description
            </Grid>
            <Grid item xs={1.5} className={styles.tableHeader}>
              DDML No.
            </Grid>
            <Grid item xs={1} className={styles.tableHeader}>
              Ref.
            </Grid>
            <Grid item xs={1} className={styles.tableHeader}>
              Raised Date
            </Grid>
            <Grid item xs={1} className={styles.tableHeader}>
              {type === "DDML" ? "Expiry" : "Balance"}
            </Grid>
            <Grid item xs={1} className={styles.tableHeader}>
              Assignee
            </Grid>
            <Grid item xs={3} className={`${styles.tableHeader} ${styles.gridJustifyStart}`}>
              Rectification Status
            </Grid>
            {data_ &&
              data_.slice(sliceStart, sliceEnd).map((e, index) => {
                // Calculate the date difference
                var currentDate = new Date();
                var expiryDate = new Date(e.expiryDate);
                var timeDifferenceMs = expiryDate - currentDate;
                var remainingDays = Math.ceil(timeDifferenceMs / (1000 * 60 * 60 * 24));
                return (
                  <>
                    <Grid item xs={13}>
                      <Grid
                        container
                        columns={13}
                        className={`${styles.lineItems} ${activeIndex === index ? styles.selectedIndex : ""}`}
                        sx={{ zIndex: 1 }}
                      >
                        <Grid item xs={0.6} className={`${styles.tableBody}`} onClick={() => handleClick(e, index)}>
                          <LightTooltip
                            title={e.ddml.limitations ? "Limitation triggered" : ""}
                            followCursor
                            placement="bottom-start"
                          >
                            {e.ddml.limitations && (
                              <img src={IconAirplaneLimitation} alt={"warning-circle"} height={20} />
                            )}
                          </LightTooltip>
                          <LightTooltip
                            title={e.ddml.reInspection ? "Reinspection" : ""}
                            followCursor
                            placement="bottom-start"
                          >
                            {e.ddml.reInspection && (
                              <img
                                src={IconReInspection}
                                alt={"icon-reinspection"}
                                height={20}
                                style={{ marginLeft: "4px" }}
                              />
                            )}
                          </LightTooltip>
                        </Grid>
                        <Grid
                          item
                          xs={0.9}
                          className={`${styles.tableBody} ${styles.toUpperCase}`}
                          onClick={() => handleClick(e, index)}
                        >
                          {e.ddml.location}
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          className={`${styles.tableBody} ${styles.toUpperCase}`}
                          onClick={() => handleClick(e, index)}
                        >
                          {e.ddml.aircraftReg}
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          className={`${styles.tableBody} ${styles.toUpperCase}`}
                          sx={{ display: "flex", justifyContent: "flex-start" }}
                          onClick={() => handleClick(e, index)}
                        >
                          <LightTooltip title={e.ddml.defectDesc} followCursor placement="bottom-start">
                            <Typography className={classes.gridItem}>{e.ddml.defectDesc}</Typography>
                          </LightTooltip>
                        </Grid>
                        <Grid
                          item
                          xs={1.5}
                          className={`${styles.tableBody} ${styles.toUpperCase}`}
                          onClick={() => handleClick(e, index)}
                        >
                          {e.ddml.ddmlNo}
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          className={`${styles.tableBody} ${styles.toUpperCase}`}
                          onClick={() => handleClick(e, index)}
                        >
                          {e.ddml.melRef && (
                            <center>
                              {e.ddml.melRef}{" "}
                              {e.ddml.formType === "MEL CAT" && (
                                <>
                                  <br /> {"(" + e.ddml.melCatType + ")"}
                                </>
                              )}
                            </center>
                          )}
                        </Grid>
                        <Grid item xs={1} className={`${styles.tableBody}`} onClick={() => handleClick(e, index)}>
                          <center>{formatDateDasr(e.ddml.raisedDate)}</center>
                          {/* {e.raisedDate} */}
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          className={`${styles.tableBody}`}
                          sx={{
                            color: remainingDays <= 3 ? "#E95048" : "",
                          }}
                          onClick={() => handleClick(e, index)}
                        >
                          <center>{type === "DDML" ? formatDateDasr(e.ddml.expiryDate) : ""}</center>
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          className={`${styles.tableBody} ${styles.toUpperCase}`}
                          onClick={() => handleClick(e, index)}
                        >
                          {e.assignee?.name}
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          className={`${styles.tableBody} ${styles.toUpperCase}`}
                          sx={{ display: "flex", justifyContent: "flex-start" }}
                          onClick={() => handleClick(e, index)}
                        >
                          <LightTooltip
                            title={getRectificationStatus(e.ddml.statusLog) || e.ddml.actionBy145}
                            followCursor
                            placement="bottom-start"
                          >
                            <Typography className={classes.gridItem}>
                              {getRectificationStatus(e.ddml.statusLog) || e.ddml.actionBy145}
                            </Typography>
                          </LightTooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                );
              })}
            <Grid item xs={13} className={`${styles.tableBodyWhite}`}>
              <MUIPagination
                page={parseInt(page)}
                setPage={setPage}
                setPerPage={setPerPage}
                totalPage={parseInt(totalPages)}
                classes={{ ul: classes.ul }}
                totalRows={parseInt(totalRows)}
                sliceStart={sliceStart}
                initialPerPage={perPage}
              />
            </Grid>
          </Grid>
        </div>
      </>
    ) : tableLoading ? (
      <AirplaneLoader />
    ) : (
      <NoData />
    );
  };

  function getRectificationStatus(item) {
    if (item !== undefined && item !== null) {
      if (item?.pushToSummary === "") return undefined;
      else {
        return item?.updateLogDesc;
      }
    }
  }

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string?.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        fontSize: "12px",
      },
      // children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
      children: `${name?.substring(0, 3)}`,
    };
  }

  return !isLoading && !subLoading ? (
    <>
      <div id="main" className={`${styles.container}`}>
        <>
          <Grid container columns={8.2} className={styles.gridContainer}>
            <Grid item xs={4.2} className={styles.gridTitle} onClick={() => setOpenModal(false)}>
              Assignment Report
            </Grid>
            <Grid item xs={4} className={styles.gridJustifyEnd} sx={{ fontSize: "14px", paddingBottom: "16px" }} onClick={() => setOpenModal(false)}>
              <DateToggle date={date} setDate={setDate} today={today} setToday={setToday} />
            </Grid>
            <Grid item xs={8.2} sx={{ paddingTop: "10px" }}>
              <TabContext value={value}>
                <TabList onChange={handleChange} aria-label="lab API tabs example" onClick={() => setOpenModal(false)}>
                  <AntTab
                    label="Day Shift"
                    value="1"
                    // icon={<Tips val={calcTipsCount("DDML")} unread={calcTipsCount("DDML") > 0 ? true : false} />}
                    // iconPosition="end"
                  />
                  <AntTab
                    label="Night Shift"
                    value="2"
                    // icon={
                    //   <Tips
                    //     val={calcTipsCount("Repetitive Defect")}
                    //     unread={calcTipsCount("Repetitive Defect") > 0 ? true : false}
                    //   />
                    // }
                    // iconPosition="end"
                  />
                </TabList>
                <div
                  style={{
                    width: "100%",
                    height: "2px",
                    backgroundColor: "#d1d1d1",
                    marginTop: "-2px",
                  }}
                ></div>
                <div
                  style={{
                    marginLeft: "-20px",
                    marginRight: "-20px",
                    marginTop: "-24px",
                  }}
                >
                  <TabPanel value="1">
                    {data_?.length > 0 ? <TaskAssignmentList type={"DDML"} /> : <ErrorAirBallon />}
                  </TabPanel>
                  <TabPanel value="2">
                    {data_?.length > 0 ? <TaskAssignmentList type={"DDML"} /> : <ErrorAirBallon />}
                  </TabPanel>
                </div>
              </TabContext>
            </Grid>
          </Grid>
          {/* <DDML
            aoc={aoc}
            open={open}
            setOpen={setOpen}
            type={type}
            index={index}
            dataForm={dataForm}
            edit={edit}
            setEdit={setEdit}
            openNotification={openNotification}
            setOpenNotification={setOpenNotification}
            newForm={newForm}
            setNewForm={setNewForm}
            triggerApi={triggerApi}
            setTriggerApi={setTriggerApi}
          /> */}
        </>
      </div>
      <ElevadeDrawer
        open={open}
        setOpen={setOpen}
        data={data}
        Component={DDML}
        aoc={aoc}
        type={type}
        index={index}
        dataForm={dataForm}
        edit={edit}
        setEdit={setEdit}
        openNotification={openNotification}
        setOpenNotification={setOpenNotification}
        newForm={newForm}
        setNewForm={setNewForm}
        triggerApi={triggerApi}
        setTriggerApi={setTriggerApi}
        setSelectToMove={setSelectToMove}
        setOpenDialogMoveToAssignment={setOpenDialogMoveToAssignment}
        hideButton={true}
        date={date}
      />
      <DialogPush145
        aoc={aoc}
        open={openDialogMoveToAssignment}
        setOpen={setOpenDialogMoveToAssignment}
        data={selectToMove}
        setTriggerApi={setTriggerApi}
        setData={setSelectToMove}
        tab={value}
      />
      <DialogSubmitShiftReport
        open={openDialogSubmitShiftReport}
        setOpen={setOpenDialogSubmitShiftReport}
        data={selectToMove}
        setTriggerApi={setTriggerApi}
        setData={setSelectToMove}
      />
    </>
  ) : (
    <AirplaneLoader />
  );
};

export default TaskAssignment;
