const { createSlice } = require("@reduxjs/toolkit");

const authInitialState = {
  aocToggle: false,
};

const aocToggle = createSlice({
  name: "aocToggle",
  initialState: authInitialState,
  reducers: {
    setAocToggle: (state, { payload }) => {
      state.aocToggle = payload;
    },
  },
});

export const { setAocToggle } = aocToggle.actions;

export default aocToggle.reducer;
