
import React from 'react';

export default function TailDEF() {
	return (
		<g>
			<path
				d="M1162.5 373.12C1163.77 373.12 1164.57 372.63 1165.3 371.89L1164.78 371.38C1164.11 372.02 1163.48 372.39 1162.52 372.39C1160.98 372.39 1159.81 371.12 1159.81 369.5V369.48C1159.81 367.87 1160.97 366.61 1162.52 366.61C1163.47 366.61 1164.13 367.01 1164.72 367.57L1165.26 366.99C1164.56 366.33 1163.8 365.88 1162.53 365.88C1160.47 365.88 1158.99 367.52 1158.99 369.5V369.52C1158.99 371.53 1160.48 373.12 1162.5 373.12Z"
				fill="#333333"
			/>
			<path
				d="M1159.38 399H1162.49C1164.02 399 1165.03 398.29 1165.03 397.09V397.07C1165.03 396.11 1164.36 395.63 1163.53 395.38C1164.08 395.14 1164.64 394.67 1164.64 393.76V393.74C1164.64 393.29 1164.48 392.92 1164.19 392.62C1163.79 392.23 1163.16 392 1162.36 392H1159.38V399ZM1160.16 395.12V392.71H1162.29C1163.29 392.71 1163.84 393.16 1163.84 393.85V393.87C1163.84 394.69 1163.18 395.12 1162.23 395.12H1160.16ZM1160.16 398.29V395.82H1162.37C1163.59 395.82 1164.23 396.26 1164.23 397.02V397.04C1164.23 397.82 1163.57 398.29 1162.51 398.29H1160.16Z"
				fill="#333333"
			/>
			<path
				d="M1158.44 425H1159.25L1160.08 423.15H1163.89L1164.71 425H1165.56L1162.37 417.95H1161.63L1158.44 425ZM1160.39 422.44L1161.99 418.87L1163.58 422.44H1160.39Z"
				fill="#333333"
			/>
		</g>
	);
}
