
import React from 'react';
import { getPopupColorBasedOnStatusCode } from "../../Popup";
import AirplaneBox from "../AirplaneBox";
import { getBoxDataFromInFleetLocations } from "../AirplaneBox/utils";
export default function BoxAftGalleyAdv({ invs, onClick, setClosePopup, setPopup }) {
    const data = getBoxDataFromInFleetLocations(invs);
    const boxX = 1140;
    const boxY = 80;
    const popupXOffset = -503;
    const popupYOffset = -410;
    return (<g className="display-box">
            <Pointer />
            <AirplaneBox title="AFT Galley" x={boxX} y={boxY} data={data} onClick={onClick} minWidth={150} setClosePopup={setClosePopup} setPopup={(rowX, rowY, data) => {
            setPopup({
                status: data?.inventory?.status,
                data: data,
                x: rowX + boxX + popupXOffset,
                y: rowY + boxY + popupYOffset,
                // row: 'row',
                col: data.locationId.seatNo,
                color: getPopupColorBasedOnStatusCode(data?.inventory?.status?.code),
            });
        }}/>
        </g>);
}
function Pointer() {
    return (<g>
            <path className="path" d="M 1260.5 346.5 V 88 C 1260.5 84 1257 81 1254 81 H 1247" stroke="#333333" stroke-width="0.5" stroke-linecap="round" stroke-dasharray="2 2"/>
            <circle className="pointer-1st" cx="1260" cy="347" r="3" fill="#74C4E8"/>
            <circle className="pointer-2nd" opacity="0.4" cx="1260" cy="347" r="6" fill="#74C4E8"/>
        </g>);
}
