export function jsonToMap(json) {
    const map = new Map();
    Object.entries(json).forEach(([key, value]) => {
        map.set(key, value);
    });
    return map;
}
export function rearrangeMapItems(map, arrangement) {
    const rearrangedMap = new Map();
    // Add items to the rearrangedMap based on the specified arrangement
    arrangement.forEach((key) => {
        if (map.has(key)) {
            rearrangedMap.set(key, map.get(key));
        }
    });
    // Add remaining items from the original map
    map.forEach((value, key) => {
        if (!rearrangedMap.has(key)) {
            rearrangedMap.set(key, value);
        }
    });
    return rearrangedMap;
}
export function rearrangeArray(arr, arrangement) {
    const result = [];
    const remaining = [];
    // Create a Set of arr elements for faster lookup
    const arrSet = new Set(arr);
    // Iterate over each element in arrangement
    for (const item of arrangement) {
        // Check if the item exists in arr
        if (arrSet.has(item)) {
            // If found in arr, add it to the result array
            result.push(item);
        }
    }
    // Append the remaining elements from arr that are not present in arrangement
    for (const item of arr) {
        if (!arrangement.includes(item)) {
            remaining.push(item);
        }
    }
    // Append the remaining elements at the end of the result array
    result.push(...remaining);
    return result;
}
export function rearrangeArrayWithComparedTo(arr, arrangement) {
    const result = [];
    const remaining = [];
    // Create a Map of comparedTo values to actual values for faster lookup
    const comparedToMap = new Map(arr.map(({ actual, comparedTo }) => [comparedTo, actual]));
    // Iterate over each element in the arrangement
    for (const item of arrangement) {
        // Check if the item exists in the comparedTo values
        if (comparedToMap.has(item)) {
            // If found, add the corresponding actual value to the result array
            const actualValue = comparedToMap.get(item);
            result.push(actualValue);
        }
    }
    // Append the remaining actual values from arr that are not present in arrangement
    for (const { actual } of arr) {
        if (!result.includes(actual)) {
            remaining.push(actual);
        }
    }
    // Append the remaining actual values at the end of the result array
    result.push(...remaining);
    return result;
}
