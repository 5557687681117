import { Modal, Box, Grid, Fade, CircularProgress } from "@mui/material";
import { AdeButton } from "common/components";
import styles from "./ChangePriorityStatus.module.css";
import { formatAtaChapter } from "../component/Helpers";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { makeAPIRequestRun } from "common/helpers";
import { showNotification } from "common/helpers";
import { setAOCBasedOnPermission } from "pages/elevade/component/Helpers";

export const ConfirmChangeModal = ({
  setNewPriority,
  newPriority, //or condition
  priority, //or condition
  open,
  setOpen,
  eventMessage,
  ataChapter,
  column,
  acfamily,
  oldValue, //old priority/condition
  setOldValue,
  eventType,
  aoc,
}) => {
  const [loading, setLoading] = useState(false);
  const handleClose = () => setOpen(false);
  const { userProfile, userName: userEmail } = useSelector((state) => state.userEntity);
  let displayedPriority = oldValue ?? "Null";

  const handleBack = () => {
    setOpen(false);
    setNewPriority("null");
  };

  const handleClick = async () => {
    setLoading(true);
    const _priority = priority ?? "null";
    const _newPriority = newPriority ?? "null";
    const _ataChapter = ataChapter ?? "null";
    const _eventMessage = eventMessage ?? "null";

    try {
      const body = {
        eventMessage: _eventMessage,
        aoc: aoc,
        email: userEmail,
        name: userProfile.fullName,
        changedColumn: column,
        acfamily: acfamily,
        eventType: eventType,
      };
      await makeAPIRequestRun(
        "post",
        `acarsdata/warningmessages/update/${_priority}/${_newPriority}/${_ataChapter}/`,
        body
      );
      setOldValue(newPriority);
      showNotification("success", "", [`${column.charAt(0).toUpperCase() + column.slice(1)} updated succesfully!`]);
    } catch (err) {
      showNotification("danger", "", [err]);
      console.log(err);
    }
    setLoading(false);
    setNewPriority("null");
    setOpen(false);
  };

  const style = {
    box: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 547,
      bgcolor: "background.paper",
      borderRadius: "8px",
      boxShadow: 24,
      p: 4,
      zIndex: 1502,
    },
    loading: {
      top: "40%",
      left: "45%",
      position: "absolute",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        BackdropProps={{
          style: {
            // backgroundColor: "transparent",
            boxShadow: "none",
            zIndex: "1502",
          },
        }}
        sx={{ padding: "24px", zIndex: "1501" }}
      >
        <>
          {loading && (
            <Box sx={style.box} minHeight={253}>
              <CircularProgress sx={style.loading} />
            </Box>
          )}
          {!loading && (
            <Fade in={open}>
              <Box sx={style.box}>
                <div
                  style={{
                    fontFamily: "Gotham-Medium",
                    fontSize: "16px",
                    color: "#333333",
                    marginBottom: "24px",
                  }}
                >
                  You are about to change the {column ?? "priority"} status
                </div>
                <Grid container columns={2}>
                  <Grid item xs={2} sx={{ borderBottom: "1px solid #D1D1D1" }}></Grid>
                  <Grid
                    item
                    xs={2}
                    sx={{
                      fontFamily: "Gotham-Book",
                      fontSize: "14px",
                      paddingTop: "24px",
                    }}
                  >
                    You are going to change the {column ?? "priority"} status from{" "}
                    <span style={{ fontFamily: "Gotham-Medium" }}>{displayedPriority}</span> to{" "}
                    <span style={{ fontFamily: "Gotham-Medium" }}>{newPriority}</span>
                    <br />
                    Are you sure?
                  </Grid>
                </Grid>
                <div
                  style={{
                    margin: "24px 24px 0 24px",
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <AdeButton
                    variant="outlined"
                    fullWidth={false}
                    style={{ marginRight: "24px" }}
                    onClick={() => {
                      handleBack();
                    }}
                  >
                    Cancel
                  </AdeButton>
                  <AdeButton
                    variant="contained"
                    fullWidth={false}
                    style={{ marginRight: "24px" }}
                    onClick={() => handleClick()}
                  >
                    Change
                  </AdeButton>
                </div>
              </Box>
            </Fade>
          )}
        </>
      </Modal>
    </>
  );
};

const ChangePriorityStatus = ({ open, setOpen, eventMessage, ataChapter, priority, data }) => {
  const [newPriority, setNewPriority] = useState("null");
  const [error, setError] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [_priority, setPriority] = useState(priority);

  const handleChange = (e) => {
    setNewPriority(e.target.value);
    if (e.target.value === "null") setError(true);
    else setError(false);
  };

  useEffect(() => {
    if (_priority === newPriority) setOpen(false);
    // eslint-disable-next-line
  }, [_priority, newPriority]);

  useEffect(() => {
    setPriority(priority);
  }, [priority]);

  const style = {
    box: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 800,
      bgcolor: "background.paper",
      borderRadius: "8px",
      boxShadow: 24,
      p: 4,
      zIndex: 1501,
    },
  };

  const handleClose = () => {
    setOpen(false);
    setNewPriority("null");
    setError(false);
  };

  const handleChangeLabel = () => {
    if (!error && newPriority !== "null") setOpenModal(true);
  };

  const errorLabel = () => {
    return (
      <>
        <span style={{ marginTop: "6px", color: "red" }}>Please select New Priority</span>
      </>
    );
  };

  const arrayPriority = ["High", "Medium", "Low", "OK", "Nuisance", "None", "Undefined"];
  const selectPriority = arrayPriority.filter((item) => item !== priority);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      BackdropProps={{
        style: {
          // backgroundColor: "transparent",
          boxShadow: "none",
          zIndex: "1501",
        },
      }}
      sx={{ padding: "24px", zIndex: "1501" }}
    >
      <Fade in={open}>
        <Box sx={style.box}>
          <div
            style={{
              fontFamily: "Gotham-Medium",
              fontSize: "24px",
              color: "#333333",
              marginBottom: "24px",
            }}
          >
            Change Priority Status
          </div>
          <div
            style={{
              border: "0px solid #d1d1d1",
              borderRadius: "4px 4px 0 0",
              overflow: "hidden",
            }}
          >
            <Grid container columns={3}>
              <Grid
                items
                xs={3}
                sx={{
                  backgroundColor: "#394458",
                  color: "#FFFFFF",
                  fontFamily: "Gotham-Medium",
                  fontSize: "18px",
                  padding: "15px 24px",
                  overflow: "hidden",
                  borderTop: "",
                }}
              >
                Priority Details
              </Grid>
            </Grid>
          </div>
          <div
            style={{
              border: "1px solid #d1d1d1",
              borderRadius: "0 0 4px 4px",
              overflow: "hidden",
              padding: "0 24px",
            }}
          >
            <Grid container columns={3}>
              <Grid
                item
                xs={1}
                sx={{
                  fontFamily: "Gotham-Medium",
                  fontSize: "16px",
                  padding: "30px 0 15px 0",
                }}
              >
                Current Priority
              </Grid>
              <Grid
                item
                xs={2}
                sx={{
                  fontFamily: "Gotham-Book",
                  fontSize: "16px",
                  padding: "30px 0 15px 0",
                }}
              >
                {priority ?? "None"}
              </Grid>
              <Grid
                item
                xs={1}
                sx={{
                  fontFamily: "Gotham-Medium",
                  fontSize: "16px",
                  padding: "15px 0",
                }}
              >
                Event Message
              </Grid>
              <Grid
                item
                xs={2}
                sx={{
                  fontFamily: "Gotham-Book",
                  fontSize: "16px",
                  padding: "15px 0",
                }}
              >
                {eventMessage}
              </Grid>
              <Grid
                item
                xs={1}
                sx={{
                  fontFamily: "Gotham-Medium",
                  fontSize: "16px",
                  padding: "15px 0",
                }}
              >
                ATA Chapter
              </Grid>
              <Grid
                item
                xs={2}
                sx={{
                  fontFamily: "Gotham-Book",
                  fontSize: "16px",
                  padding: "15px 0",
                }}
              >
                {formatAtaChapter(ataChapter)}
              </Grid>
              <Grid item xs={3} sx={{ borderBottom: "1px solid #D1D1D1", paddingTop: "9px" }}></Grid>
              <Grid
                item
                xs={1}
                sx={{
                  fontFamily: "Gotham-Medium",
                  fontSize: "16px",
                  padding: "35px 0 15px 0",
                }}
              >
                New Priority<span style={{ color: "red" }}>*</span>
              </Grid>
              <Grid item xs={2} sx={{ padding: "25px 0" }}>
                <select
                  className={`form-control ${styles.selectSearch} ${error ? styles.red : ""}`}
                  onChange={(e) => handleChange(e)}
                  value={newPriority}
                  defaultValue={"Select New Priority"}
                >
                  <option value="null">Select New Priority</option>
                  {selectPriority?.map((item) => {
                    return (
                      <>
                        <option value={item}>{item}</option>
                      </>
                    );
                  })}
                </select>
                {error ? errorLabel() : ""}
              </Grid>
            </Grid>
          </div>
          <div
            style={{
              margin: "24px 24px 0 24px",
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <AdeButton
              variant="text"
              fullWidth={false}
              style={{ marginRight: "24px" }}
              onClick={() => {
                handleClose();
              }}
            >
              Back
            </AdeButton>
            <AdeButton
              variant="contained"
              fullWidth={false}
              style={{ marginRight: "24px" }}
              onClick={() => {
                handleChangeLabel();
              }}
            >
              Change Priority
            </AdeButton>
          </div>

          <ConfirmChangeModal
            setNewPriority={setNewPriority}
            newPriority={newPriority}
            priority={data?.condition}
            open={openModal}
            setOpen={setOpenModal}
            eventMessage={eventMessage}
            ataChapter={ataChapter}
            column="priority"
            acfamily={data?.ac_family}
            oldValue={_priority}
            setOldValue={setPriority}
            aoc={data?.Operator}
            eventType={data?.report_type}
          />
        </Box>
      </Fade>
    </Modal>
  );
};

export default ChangePriorityStatus;
