import React from 'react';
import _ from 'lodash';
export function isValidReactChild(value) {
    if (React.isValidElement(value)) {
        return true;
    }
    return (value !== null &&
        value !== undefined &&
        typeof value !== 'boolean' &&
        typeof value !== 'object');
}
/**
 * @description : check includes
 * is undefined
 * is null
 * is empty string ("")
 * emptySpace - optional
 * emptyArray - optional
 * emptyObject - optional
 * isNan - optional
 * @default options:
 * isEmptySpaceIsEmpty = true
 * isEmptyArrayIsEmpty = true
 * isNanIsEmpty = false
 * isEmptyObjectIsEmpty = false
 * @param val
 * @param options
 * @returns
 */
export function isEmpty(val, options) {
    return _.isEmpty(val);
    // const {
    //     isEmptySpaceIsEmpty = true,
    //     isEmptyArrayIsEmpty = true,
    //     isEmptyObjectIsEmpty = true,
    //     isNanIsEmpty = false,
    //     isEmptyStrPatterns = [],
    // } = options ?? {}
    // if (
    //     val === undefined
    //     || val === null
    //     || val === ""
    // ) {
    //     return true
    // }
    // if (isEmptyObjectIsEmpty && typeof val === 'object' && Object.keys(val).length < 1) {
    //     return true
    // }
    // if (isEmptySpaceIsEmpty && val === " ") {
    //     return true
    // }
    // if (isEmptyArrayIsEmpty && Array.isArray(val) && val.length === 0) {
    //     return true
    // }
    // if (isNanIsEmpty && Number.isNaN(val)) {
    //     return true
    // }
    // if (typeof (val) === 'string' && isEmptyStrPatterns.includes(val)) {
    //     return true
    // }
    // return false
}
