import { Grid, Checkbox, styled } from "@mui/material";
import styles from "./FilterBox.module.css";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React, { useState, useEffect } from "react";
import IconClose from "common/images/icons/elevade/Icon-Close.svg";

const FilterModal = ({ open, setOpen, title, list, isFilters, setIsFilters, isLast, openTitle, disableFuture }) => {
  const [selectDate, setSelectDate] = useState(false);
  let titleRef = title.replace(" ", "_") + "_dasr";

  let key = "";
  if (title === "Operator") key = "operator";
  else if (title === "Aircraft Reg") key = "aircraftReg";
  else if (title === "Station") key = "location";
  else if (title === "Department") key = "assignedTo";
  else if (title === "Assignee") key = "assignee";
  else if (title === "Raised Date") key = "raisedDate";
  else if (title === "Expiry Date") key = "expiryDate";

  useEffect(() => {
    if (title.includes("Date")) {
      // Create a Date object from the given date string
      const date = new Date(selectDate);
      // Get the components of the date
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      const selectedDate = `${year}-${month}-${day}`;
      if (selectedDate !== "1970-01-01") setFilters(selectedDate, key);
    }
  }, [selectDate, key, title]);

  function setFilters(value, key) {
    const theDate = { ...isFilters, [key]: [value] };
    setIsFilters(theDate);
  }

  function handleChecked(value) {
    const updatedChecked = [...isFilters[key], value];
    const updatedFilters = { ...isFilters, [key]: updatedChecked };
    setIsFilters(updatedFilters);
  }

  function handleUnChecked(value) {
    const updatedChecked = isFilters[key].filter((item) => item !== value);
    const updatedFilters = { ...isFilters, [key]: updatedChecked };
    setIsFilters(updatedFilters);
  }

  const StyledCheckbox = styled(Checkbox)(() => ({
    [`.MuiCheckbox-root`]: {
      marginTop: "6px",
      border: "1px solid blue",
    },
    marginTop: "6px",
    color: "#94A3B8",
  }));

  function onChangeInput(newValue) {
    // Create a Date object from the given date string
    const date = new Date(newValue);

    // Get the components of the date
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const selectedDate = new Date(`${year}-${month}-${day}`);

    const theDate = { ...isFilters, [key]: selectedDate };
    setIsFilters(theDate);
  }

  return (
    open &&
    title === openTitle && (
      <>
        <div
          className={`${styles.filterModal} ${isLast ? styles.isLastModal : ""} ${
            title.includes("Date") ? styles.filterModalDate : ""
          } ${styles.macScrollbar}`}
          id={titleRef}
        >
          <span style={{ color: "#838383", marginBottom: "0px" }} key="">
            <Grid container columns={2}>
              <Grid item xs={1.8} sx={{ padding: "10px 0 0 0px" }}>
                {title}
              </Grid>
              <Grid
                item
                xs={0.2}
                sx={{ display: "flex", justifyContent: "flex-end", paddingTop: "6px", cursor: "pointer" }}
              >
                <img src={IconClose} alt="icon-close" height={10} onClick={() => setOpen(false)} />
              </Grid>
            </Grid>
          </span>
          {title.includes("Date") ? (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={""}
                format="LL"
                disableFuture={disableFuture}
                value={isFilters[key].length === 0 ? undefined : dayjs(isFilters[key])}
                // defaultValue={date}
                onChange={(newValue) => {
                  setSelectDate(newValue);
                }}
              />
            </LocalizationProvider>
          ) : (
            <ul className={`${styles.ul}`}>
              {list &&
                list
                  ?.filter((element) => element !== undefined && element !== null)
                  .map((item, index) => {
                    if (key === "paxCapacity") item = parseInt(item);
                    return (
                      <li className={`${styles.li}`} key={index}>
                        <Grid container columns={3}>
                          <Grid
                            item
                            xs={2}
                            sx={{
                              paddingTop: "10%",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              color: "#333333",
                            }}
                          >
                            {item}
                          </Grid>
                          <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <StyledCheckbox
                              value={item}
                              checked={isFilters[key]?.filter((e) => e === item).length > 0}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  handleChecked(e.target.value);
                                } else handleUnChecked(e.target.value);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </li>
                    );
                  })}
            </ul>
          )}
        </div>
      </>
    )
  );
};

export default FilterModal;
