import React from 'react';
import Chip from "@mui/material/Chip";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { styled } from "@mui/system";

const theme = createTheme({
  palette: {
    info: { main: "#0AAEFF", contrastText: "#fff" },
    new: { main: "#0AAEFF", contrastText: "#fff" },
    active: { main: "#6AB47B", contrastText: "#333333" },
    productdeclined: { main: "#E94848", contrastText: "#fff" },
    productupdatedeclined: { main: "#E94848", contrastText: "#fff" },
    draft: { main: "#FFBC40", contrastText: "#fff" },
    pendingsellerreview: { main: "#BFD6FF", contrastText: "#333333" },
    pendingupdate: { main: "#D1D1D1", contrastText: "#333333" },
    pendingapproval: { main: "#f2f7ff", contrastText: "#333333" },
    orderplaced: { main: "#A3E0FF", contrastText: "#333333" },
    owned: { main: "#333333", contrastText: "#fff" },
    delisted: { main: "#FFFFFF", contrastText: "#333333" },
    soldout: { main: "#E8E8E8", contrastText: "#333333" },
    comingsoon: { main: "#0AAEFF", contrastText: "#fff" },
    beta: { main: "#394458", contrastText: "#ffffff" },
    pendingapproval_prospectlist: { main: "#F5BE59", contrastText: "#333333" },
    approved_prospectlist: { main: "#F2F7FF", contrastText: "#333333" },
    active_prospectlist: { main: "#6AB47B", contrastText: "#fff" },
    declined_prospectlist: { main: "#FE0000", contrastText: "#fff" },
    member_active: { main: "#6AB47B", contrastText: "#fff" },
    member_inactive: { main: "#B1B1B1", contrastText: "#fff" },
    blue: { main: "#088FD1", contrastText: "#fff" },
    grey: { main: "#D9D9D9", contrastText: "#fff" },
  },
});

export const AdeChip = styled(Chip)(() => ({
  borderRadius: "8px",
  boxShadow: "0px 3px 6px #3B445633",
  height: "28px",
  fontFamily: "Gotham-Medium",
}));
export const AdeChipDraftStatus = styled(Chip)(() => ({
  borderRadius: "8px",
  boxShadow: "0px 3px 6px #3B445633",
  height: "28px",
  fontFamily: "Gotham-Medium",
  cursor: "pointer",
}));

const AdeChipCondition = styled(Chip)(() => ({
  borderRadius: "4px",
  boxShadow: "0px 3px 6px #3B445633",
  padding: "0px",
  height: "20px",
  marginLeft: "6px",
  fontFamily: "Gotham-Medium",
  backgroundColor: "#7C94BF",
  color: "#fff",
  fontSize: "12px",
  marginBottom: "4px",
}));

const AdeChipTips = styled(Chip)(() => ({
  borderRadius: "4px",
  boxShadow: "0px 3px 6px #3B445633",
  height: "20px",
  fontFamily: "Gotham-Medium",
  fontSize: "10px",
  marginLeft: "4px",
  marginTop: "-18px",
  color: "#FFFFFF",
}));

const AdeChipTipsTopRight = styled(Chip)(() => ({
  borderRadius: "8px",
  boxShadow: "0px 3px 6px #3B445633",
  height: "30px",
  fontFamily: "Gotham-Medium",
  fontSize: "14px",
  marginLeft: "280px",
  marginTop: "30px",
  opacity: "1",
  zIndex: "100",
  position: "absolute",
}));

export const AdeChipStatus = ({ label, color, size, ...props }) => {
  return (
    <ThemeProvider theme={theme}>
      <AdeChip label={label} color={color} size={size} {...props}></AdeChip>
    </ThemeProvider>
  );
};

export const AdeChipComingSoon = styled(Chip)(() => ({
  borderRadius: "8px",
  boxShadow: "0px 3px 6px #3B445633",
  height: "30px",
  fontFamily: "Gotham-Medium",
  fontSize: "14px",
  marginLeft: "280px",
  marginTop: "30px",
  opacity: "1",
  zIndex: "100",
  position: "absolute",
}));

export const AdeChipBeta = styled(Chip)(() => ({
  borderRadius: "4px",
  boxShadow: "0px 3px 6px #3B445633",
  height: "20px",
  fontFamily: "Gotham-Medium",
  fontSize: "10px",
  color: "#FFFFFF",
  marginLeft: "350px",
  marginTop: "30px",
  opacity: "1",
  zIndex: "100",
  position: "absolute",
  backgroundColor: "black",
}));

export const AdeChipBeta2 = styled(Chip)(() => ({
  backgroundColor: "black",
  borderRadius: "4px",
  boxShadow: "0px 3px 6px #3B445633",
  height: "20px",
  fontFamily: "Gotham-Medium",
  fontSize: "10px",
  marginLeft: "4px",
  marginTop: "-18px",
  color: "#FFFFFF",
}));

export const AdeChipTag = ({ label, color, size, ...props }) => {
  return (
    <ThemeProvider theme={theme}>
      <AdeChip label={label} color={color} size={size} {...props}></AdeChip>
    </ThemeProvider>
  );
};

export const AdeChipSmallTag = ({ label, color, size, ...props }) => {
  return (
    <ThemeProvider theme={theme}>
      <AdeChipTips label={label} color={color} size={size} {...props}></AdeChipTips>
    </ThemeProvider>
  );
};

export const AdeChipTopRight = ({ label, color, size, ...props }) => {
  return (
    <ThemeProvider theme={theme}>
      <AdeChipTipsTopRight label={label} color={color} size={size} {...props}></AdeChipTipsTopRight>
    </ThemeProvider>
  );
};

export const AdeChipConditionCode = ({ label, ...props }) => {
  return <AdeChipCondition label={label} size="small" {...props}></AdeChipCondition>;
};

export const AdeChipDraft = ({ label, color, size, ...props }) => {
  return (
    <ThemeProvider theme={theme}>
      <AdeChipDraftStatus label={label} color={color} size={size} {...props}></AdeChipDraftStatus>
    </ThemeProvider>
  );
};
