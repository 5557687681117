
import React from 'react';
import { Select, MenuItem, FormHelperText, TextField, Box } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { Fragment, useEffect, useState } from "react";
import zIndex from "../../../../../constants/viewIndex";
export default function StyledDropDownMain({ value, defaultValue, handleChange, style = {}, options = [], disabled = false, enableUndefinedSelection = false, error, helperText, inputRef, onSearch, 
// onBlur,
...props }) {
    const [searchInput, setSearchInput] = useState();
    // delay on search for 300miliseconds
    useEffect(() => {
        if (onSearch) {
            const timeOutId = setTimeout(() => onSearch(searchInput), 300);
            return () => clearTimeout(timeOutId);
        }
    }, [searchInput]);
    return (<Fragment>
            <Select inputRef={inputRef} onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
        }} onKeyDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
        }} {...props} defaultValue={defaultValue} value={value} sx={{
            ...style,
            '& .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #D1D1D1',
                borderRadius: '8px',
                height: '50px',
                backgroundColor: disabled ? 'lightgray' : undefined,
            },
            '& .MuiInputBase-root': {
                height: '50px',
            },
            '& .MuiInputBase-input': {
                paddingTop: '12px',
                paddingbottom: '6px',
            },
        }} fullWidth disabled={disabled} onChange={handleChange} error={error} MenuProps={{
            PaperProps: {
                sx: {
                    "& .MuiMenuItem-root.Mui-selected": {
                        backgroundColor: "#F3F7FE",
                        // fontFamily: gothamMedium,
                    },
                    "& .MuiMenuItem-root:hover": {
                        backgroundColor: "#F7F7F7"
                    },
                    "& .MuiMenuItem-root.Mui-selected:hover": {
                        backgroundColor: "#F7F7F7"
                    }
                }
            }
        }}>
                {onSearch && (<Box onKeyDown={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }} onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}>
                        <TextField size='small' onKeyDown={(e) => {
                // e.preventDefault()
                e.stopPropagation();
            }} onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }} placeholder='Search' InputProps={{
                disableUnderline: true,
                startAdornment: <SearchIcon sx={{
                        cursor: "pointer",
                        padding: "0.3em",
                        color: '#C3C3C3',
                        // '&:hover': {
                        //     background: "whitesmoke",
                        //     borderRadius: "1.5em",
                        // }
                    }}/>,
            }} onChange={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setSearchInput(e.target.value);
            }} sx={{
                "& input::placeholder": {
                    fontSize: "12px"
                },
                background: 'white',
                width: '100%',
                zIndex: zIndex.header - 1,
                // width: '100%',
            }}/>
                    </Box>)}
                {options.map(({ value: innerVal, label }) => {
            return (<MenuItem selected={value === innerVal} value={innerVal}>{label}</MenuItem>);
        })}
            </Select>
            {helperText && (<FormHelperText style={{
                color: error ? '#d32f2f' : undefined,
                paddingLeft: '14px',
            }}>{helperText}</FormHelperText>)}
        </Fragment>);
}
