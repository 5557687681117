import { Grid } from "@mui/material";
import DeleteFilter from "common/images/icons/elevade/Delete-filter.svg";
import styles from "./ActiveFilters.module.css";
import { GetIATA } from "./Helpers";
import { elevadeActiveFilter } from "common/constant";
import React from "react";
import { dateFnsFormats } from "common/constant";
import { format } from "date-fns";

const ActiveFilters = (props = []) => {
  const handleRemoveFilter = (item, filter) => {
    if (filter === "searchInput") props.setIsSearchInput(props.isSearchInput.filter((e) => e !== item));
    else if (filter === "aircraftType") props.setIsAircraftType(props.isAircraftType.filter((e) => e !== item));
    else if (filter === "operator") props.setIsOperator(props.isOperator.filter((e) => e !== item));
    else if (filter === "faultPriority") props.setIsFaultPriority(props.isFaultPriority.filter((e) => e !== item));
    else if (filter === "departureAirport")
      props.setIsDepartureAirport(props.isDepartureAirport.filter((e) => e !== item));
    else if (filter === "arrivalAirport") props.setIsArrivalAirport(props.isArrivalAirport.filter((e) => e !== item));
    else if (filter === "sortBy") {
      props.setIsSortBy([]);
      props.setIsSorting("asc");
    }
    // Inbox filter
    else if (filter === "inboxAircraftReg") props.setIsACReg(props.isACReg.filter((e) => e !== item));
    else if (filter === "inboxOperator") props.setIsOperator(props.isOperator.filter((e) => e !== item));
    else if (filter === "inboxAircraftType") props.setIsAircraftType(props.isAircraftType.filter((e) => e !== item));
    else if (filter === "inboxPhase") props.setIsPhase(props.isPhase.filter((e) => e !== item));
    else if (filter === "inboxAtaChapter") props.setIsAtaChapter(props.isAtaChapter.filter((e) => e !== item));
    else if (filter === "inboxFaultPriority") props.setIsFaultPriority(props.isFaultPriority.filter((e) => e !== item));
    else if (filter === "inboxRepetitive") props.setIsRepetitive(props.isRepetitive.filter((e) => e !== item));
    else if (filter === "inboxDateRange") {
      props.setStartDate();
      props.setEndDate();
    }
    // Faults Messages filter
    else if (filter === "faultsMessagesAircraftReg") props.setIsACReg(props.isACReg.filter((e) => e !== item));
    else if (filter === "faultsMessagesOperator") props.setIsOperator(props.isOperator.filter((e) => e !== item));
    else if (filter === "faultsMessagesAircraftType")
      props.setIsAircraftType(props.isAircraftType.filter((e) => e !== item));
    else if (filter === "faultsMessagesPhase") props.setIsPhase(props.isPhase.filter((e) => e !== item));
    else if (filter === "faultsMessagesAtaChapter") props.setIsAtaChapter(props.isAtaChapter.filter((e) => e !== item));
    else if (filter === "faultsMessagesFaultPriority")
      props.setIsFaultPriority(props.isFaultPriority.filter((e) => e !== item));
    else if (filter === "faultsMessagesRepetitive") props.setIsRepetitive(props.isRepetitive.filter((e) => e !== item));
    else if (filter === "faultsMessagesDateRange") {
      props.setStartDate();
      props.setEndDate();
    }
    // OOOI Reports filter
    else if (filter === "oooiAircraftReg") props.setIsACReg(props.isACReg.filter((e) => e !== item));
    else if (filter === "oooiOperator") props.setIsOperator(props.isOperator.filter((e) => e !== item));
    else if (filter === "oooiStatus") props.setIsStatus(props.isStatus.filter((e) => e !== item));
    else if (filter === "oooiDateRange") {
      props.setStartDate();
      props.setEndDate();
    }
    // Positioning Reports filter
    else if (filter === "positioningAircraftReg") props.setIsACReg(props.isACReg.filter((e) => e !== item));
    else if (filter === "positioningOperator") props.setIsOperator(props.isOperator.filter((e) => e !== item));
    else if (filter === "positioningDateRange") {
      props.setStartDate();
      props.setEndDate();
    }
    // ACMS Reports filter
    else if (filter === "acmsAircraftReg") props.setIsACReg(props.isACReg.filter((e) => e !== item));
    else if (filter === "acmsOperator") props.setIsOperator(props.isOperator.filter((e) => e !== item));
    else if (filter === "acmsFaultPriority") props.setIsFaultPriority(props.isFaultPriority.filter((e) => e !== item));
    else if (filter === "acmsDateRange") {
      props.setStartDate();
      props.setEndDate();
    }
    // ACARS Reports filter
    else if (filter === "acarsFaultPriority") props.setIsFaultPriority(props.isFaultPriority.filter((e) => e !== item));
    else if (filter === "acarsRepetitive") props.setIsRepetitive(props.isRepetitive.filter((e) => e !== item));
  };

  function handleClearAllFilter() {
    if (props.filter === elevadeActiveFilter.AIRCRAFT_MONITORING) {
      props.setIsSearchInput([]);
      props.setIsAircraftType([]);
      props.setIsOperator([]);
      props.setIsFaultPriority([]);
      props.setIsDepartureAirport([]);
      props.setIsArrivalAirport([]);
      props.setIsSortBy([]);
    } else if (props.filter === elevadeActiveFilter.INBOX) {
      props.setIsSearchInput([]);
      props.setIsACReg("All");
      props.setIsOperator("All");
      props.setIsAircraftType("All");
      props.setIsPhase("All");
      props.setIsAtaChapter("All");
      props.setIsFaultPriority("All");
      props.setIsRepetitive("All");
      props.setStartDate();
      props.setEndDate();
    } else if (props.filter === elevadeActiveFilter.FAULTS_MESSAGES) {
      props.setIsSearchInput([]);
      props.setIsACReg("All");
      props.setIsOperator("All");
      props.setIsAircraftType("All");
      props.setIsPhase("All");
      props.setIsAtaChapter("All");
      props.setIsFaultPriority("All");
      props.setIsRepetitive("All");
      props.setStartDate();
      props.setEndDate();
    } else if (props.filter === elevadeActiveFilter.OOOI_REPORTS) {
      props.setIsSearchInput([]);
      props.setIsACReg("All");
      props.setIsOperator("All");
      props.setIsStatus("All");
      props.setStartDate();
      props.setEndDate();
    } else if (props.filter === elevadeActiveFilter.POSITIONING_REPORTS) {
      props.setIsSearchInput([]);
      props.setIsACReg("All");
      props.setIsOperator("All");
      props.setStartDate();
      props.setEndDate();
    } else if (props.filter === elevadeActiveFilter.ACMS_REPORTS) {
      props.setIsSearchInput([]);
      props.setIsACReg("All");
      props.setIsOperator("All");
      props.setIsFaultPriority("All");
      props.setStartDate();
      props.setEndDate();
    } else if (props.filter === elevadeActiveFilter.ACARS_REPORTS) {
      props.setIsSearchInput([]);
      props.setIsFaultPriority([]);
      props.setIsRepetitive([]);
    }
  }
  const getSortLabel = (item) => {
    let itemSort = "";
    if (item === "AC_REG") itemSort = "Aircraft Registration";
    else if (item === "destination_airport_format") itemSort = "Arrival Airport";
    else if (item === "departure_airport_format") itemSort = "Departure Airport";
    else if (item === "priority_level") itemSort = "Fault Priority";
    else if (item === "Operator") itemSort = "Operator";

    return itemSort;
  };

  const filterLayout = () => {
    if (props.filter === elevadeActiveFilter.AIRCRAFT_MONITORING) {
      return aircraftMonitoringFilter();
    } else if (props.filter === elevadeActiveFilter.INBOX) {
      return inboxFilter();
    } else if (props.filter === elevadeActiveFilter.FAULTS_MESSAGES) {
      return faultsMessagesFilter();
    } else if (props.filter === elevadeActiveFilter.OOOI_REPORTS) {
      return oooiFilter();
    } else if (props.filter === elevadeActiveFilter.POSITIONING_REPORTS) {
      return positioningFilter();
    } else if (props.filter === elevadeActiveFilter.ACMS_REPORTS) {
      return acmsFilter();
    } else if (props.filter === elevadeActiveFilter.ACARS_REPORTS) {
      return acarsFilter();
    }
  };

  const inboxFilter = () => {
    return (
      <React.Fragment>
        {props.isSearchInput.length > 0 &&
          props.isSearchInput.map((item, index) => {
            return (
              <div className={styles.filterItem} key={index}>
                {item}{" "}
                <img
                  src={DeleteFilter}
                  alt=""
                  className={styles.deleteFilter}
                  onClick={() => handleRemoveFilter(item, "searchInput")}
                />
              </div>
            );
          })}
        {props.isACReg !== "All" &&
          props.isACReg.map((item, index) => {
            return (
              <div className={styles.filterItem} key={index}>
                {item}{" "}
                <img
                  src={DeleteFilter}
                  alt=""
                  className={styles.deleteFilter}
                  onClick={() => handleRemoveFilter(item, "inboxAircraftReg")}
                />
              </div>
            );
          })}
        {props.isOperator !== "All" &&
          props.isOperator.map((item, index) => {
            return (
              <div className={styles.filterItem} key={index}>
                {item}{" "}
                <img
                  src={DeleteFilter}
                  alt=""
                  className={styles.deleteFilter}
                  onClick={() => handleRemoveFilter(item, "inboxOperator")}
                />
              </div>
            );
          })}
        {props.isAircraftType !== "All" &&
          props.isAircraftType.map((item, index) => {
            return (
              <div className={styles.filterItem} key={index}>
                {item}{" "}
                <img
                  src={DeleteFilter}
                  alt=""
                  className={styles.deleteFilter}
                  onClick={() => handleRemoveFilter(item, "inboxAircraftType")}
                />
              </div>
            );
          })}
        {props.isPhase !== "All" &&
          props.isPhase.map((item, index) => {
            return (
              <div className={styles.filterItem} key={index}>
                {item.trim().padStart(2, "0")}{" "}
                <img
                  src={DeleteFilter}
                  alt=""
                  className={styles.deleteFilter}
                  onClick={() => handleRemoveFilter(item, "inboxPhase")}
                />
              </div>
            );
          })}
        {props.isAtaChapter !== "All" &&
          props.isAtaChapter.map((item, index) => {
            return (
              <div className={styles.filterItem} key={index}>
                {item.trim().padStart(2, "0")}{" "}
                <img
                  src={DeleteFilter}
                  alt=""
                  className={styles.deleteFilter}
                  onClick={() => handleRemoveFilter(item, "inboxAtaChapter")}
                />
              </div>
            );
          })}
        {props.isFaultPriority !== "All" &&
          props.isFaultPriority.map((item, index) => {
            return (
              <div className={styles.filterItem} key={index}>
                {item}{" "}
                <img
                  src={DeleteFilter}
                  alt=""
                  className={styles.deleteFilter}
                  onClick={() => handleRemoveFilter(item, "inboxFaultPriority")}
                />
              </div>
            );
          })}
        {props.isRepetitive !== "All" &&
          props.isRepetitive.map((item, index) => {
            return (
              <div className={styles.filterItem} key={index}>
                {item}{" "}
                <img
                  src={DeleteFilter}
                  alt=""
                  className={styles.deleteFilter}
                  onClick={() => handleRemoveFilter(item, "inboxRepetitive")}
                />
              </div>
            );
          })}

        {displayDateRange("inboxDateRange")}
      </React.Fragment>
    );
  };

  const aircraftMonitoringFilter = () => {
    return (
      <React.Fragment>
        {props.isSearchInput.length > 0 &&
          props.isSearchInput.map((item, index) => {
            return (
              <div className={styles.filterItem} key={index}>
                {item}{" "}
                <img
                  src={DeleteFilter}
                  alt=""
                  className={styles.deleteFilter}
                  onClick={() => handleRemoveFilter(item, "searchInput")}
                />
              </div>
            );
          })}
        {props.isAircraftType.length > 0 &&
          props.isAircraftType.map((item, index) => {
            return (
              <div className={styles.filterItem} key={index}>
                {item}{" "}
                <img
                  src={DeleteFilter}
                  alt=""
                  className={styles.deleteFilter}
                  onClick={() => handleRemoveFilter(item, "aircraftType")}
                />
              </div>
            );
          })}
        {props.isOperator.length > 0 &&
          props.isOperator.map((item, index) => {
            return (
              <div className={styles.filterItem} key={index}>
                {item}{" "}
                <img
                  src={DeleteFilter}
                  alt=""
                  className={styles.deleteFilter}
                  onClick={() => handleRemoveFilter(item, "operator")}
                />
              </div>
            );
          })}
        {props.isFaultPriority.length > 0 &&
          props.isFaultPriority.map((item, index) => {
            return (
              <div className={styles.filterItem} key={index}>
                {item === "Null" || item === "Missing" ? "Undefined" : item}{" "}
                <img
                  src={DeleteFilter}
                  alt=""
                  className={styles.deleteFilter}
                  onClick={() => handleRemoveFilter(item, "faultPriority")}
                />
              </div>
            );
          })}
        {props.isDepartureAirport.length > 0 &&
          props.isDepartureAirport.map((item, index) => {
            return (
              <div className={styles.filterItem} key={index}>
                {GetIATA(item, props.airportList)}{" "}
                <img
                  src={DeleteFilter}
                  alt=""
                  className={styles.deleteFilter}
                  onClick={() => handleRemoveFilter(item, "departureAirport")}
                />
              </div>
            );
          })}
        {props.isArrivalAirport.length > 0 &&
          props.isArrivalAirport.map((item, index) => {
            return (
              <div className={styles.filterItem} key={index}>
                {GetIATA(item, props.airportList)}{" "}
                <img
                  src={DeleteFilter}
                  alt=""
                  className={styles.deleteFilter}
                  onClick={() => handleRemoveFilter(item, "arrivalAirport")}
                />
              </div>
            );
          })}
        {/* {
        isSortBy.length > 0 && (
          // isSortBy.map((item, index) => {
          //   return (
          <div className={styles.filterItem} key={index}>
            {getSortLabel(isSortBy)}{" "}
            <img src={DeleteFilter} alt="" className={styles.deleteFilter} onClick={() => setIsSortBy([])} />
          </div>
        )
        //   );
        // })}
      } */}
        {props.isSortBy.length > 0 &&
          props.isSortBy.map((item, index) => {
            return (
              <div className={styles.filterItem} key={index}>
                {getSortLabel(item, index)}{" "}
                <img
                  src={DeleteFilter}
                  alt=""
                  className={styles.deleteFilter}
                  onClick={() => handleRemoveFilter(item, "sortBy")}
                />
              </div>
            );
          })}
      </React.Fragment>
    );
  };

  const faultsMessagesFilter = () => {
    return (
      <React.Fragment>
        {props.isSearchInput.length > 0 &&
          props.isSearchInput.map((item, index) => {
            return (
              <div className={styles.filterItem} key={index}>
                {item}{" "}
                <img
                  src={DeleteFilter}
                  alt=""
                  className={styles.deleteFilter}
                  onClick={() => handleRemoveFilter(item, "searchInput")}
                />
              </div>
            );
          })}
        {props.isACReg !== "All" &&
          props.isACReg.map((item, index) => {
            return (
              <div className={styles.filterItem} key={index}>
                {item}{" "}
                <img
                  src={DeleteFilter}
                  alt=""
                  className={styles.deleteFilter}
                  onClick={() => handleRemoveFilter(item, "faultsMessagesAircraftReg")}
                />
              </div>
            );
          })}
        {props.isOperator !== "All" &&
          props.isOperator.map((item, index) => {
            return (
              <div className={styles.filterItem} key={index}>
                {item}{" "}
                <img
                  src={DeleteFilter}
                  alt=""
                  className={styles.deleteFilter}
                  onClick={() => handleRemoveFilter(item, "faultsMessagesOperator")}
                />
              </div>
            );
          })}
        {props.isReportType !== "All" &&
          props.isReportType.map((item, index) => {
            return (
              <div className={styles.filterItem} key={index}>
                {item}{" "}
                <img
                  src={DeleteFilter}
                  alt=""
                  className={styles.deleteFilter}
                  onClick={() => handleRemoveFilter(item, "faultsMessagesReportType")}
                />
              </div>
            );
          })}
        {props.isPhase !== "All" &&
          props.isPhase.map((item, index) => {
            return (
              <div className={styles.filterItem} key={index}>
                {item.trim().padStart(2, "0")}{" "}
                <img
                  src={DeleteFilter}
                  alt=""
                  className={styles.deleteFilter}
                  onClick={() => handleRemoveFilter(item, "faultsMessagesPhase")}
                />
              </div>
            );
          })}
        {props.isAtaChapter !== "All" &&
          props.isAtaChapter.map((item, index) => {
            return (
              <div className={styles.filterItem} key={index}>
                {item.trim().padStart(2, "0")}{" "}
                <img
                  src={DeleteFilter}
                  alt=""
                  className={styles.deleteFilter}
                  onClick={() => handleRemoveFilter(item, "faultsMessagesAtaChapter")}
                />
              </div>
            );
          })}
        {props.isFaultPriority !== "All" &&
          props.isFaultPriority.map((item, index) => {
            return (
              <div className={styles.filterItem} key={index}>
                {item}{" "}
                <img
                  src={DeleteFilter}
                  alt=""
                  className={styles.deleteFilter}
                  onClick={() => handleRemoveFilter(item, "faultsMessagesFaultPriority")}
                />
              </div>
            );
          })}
        {props.isRepetitive !== "All" &&
          props.isRepetitive.map((item, index) => {
            return (
              <div className={styles.filterItem} key={index}>
                {item}{" "}
                <img
                  src={DeleteFilter}
                  alt=""
                  className={styles.deleteFilter}
                  onClick={() => handleRemoveFilter(item, "faultsMessagesRepetitive")}
                />
              </div>
            );
          })}

        {displayDateRange("faultsMessagesDateRange")}
      </React.Fragment>
    );
  };

  const oooiFilter = () => {
    return (
      <React.Fragment>
        {props.isSearchInput.length > 0 &&
          props.isSearchInput.map((item, index) => {
            return (
              <div className={styles.filterItem} key={index}>
                {item}{" "}
                <img
                  src={DeleteFilter}
                  alt=""
                  className={styles.deleteFilter}
                  onClick={() => handleRemoveFilter(item, "searchInput")}
                />
              </div>
            );
          })}
        {props.isACReg !== "All" &&
          props.isACReg.map((item, index) => {
            return (
              <div className={styles.filterItem} key={index}>
                {item}{" "}
                <img
                  src={DeleteFilter}
                  alt=""
                  className={styles.deleteFilter}
                  onClick={() => handleRemoveFilter(item, "oooiAircraftReg")}
                />
              </div>
            );
          })}
        {props.isOperator !== "All" &&
          props.isOperator.map((item, index) => {
            return (
              <div className={styles.filterItem} key={index}>
                {item}{" "}
                <img
                  src={DeleteFilter}
                  alt=""
                  className={styles.deleteFilter}
                  onClick={() => handleRemoveFilter(item, "oooiOperator")}
                />
              </div>
            );
          })}
        {props.isStatus !== "All" &&
          props.isStatus.map((item, index) => {
            return (
              <div className={styles.filterItem} key={index}>
                {item}{" "}
                <img
                  src={DeleteFilter}
                  alt=""
                  className={styles.deleteFilter}
                  onClick={() => handleRemoveFilter(item, "oooiStatus")}
                />
              </div>
            );
          })}

        {displayDateRange("oooiDateRange")}
      </React.Fragment>
    );
  };

  const positioningFilter = () => {
    return (
      <React.Fragment>
        {props.isSearchInput.length > 0 &&
          props.isSearchInput.map((item, index) => {
            return (
              <div className={styles.filterItem} key={index}>
                {item}{" "}
                <img
                  src={DeleteFilter}
                  alt=""
                  className={styles.deleteFilter}
                  onClick={() => handleRemoveFilter(item, "searchInput")}
                />
              </div>
            );
          })}
        {props.isACReg !== "All" &&
          props.isACReg.map((item, index) => {
            return (
              <div className={styles.filterItem} key={index}>
                {item}{" "}
                <img
                  src={DeleteFilter}
                  alt=""
                  className={styles.deleteFilter}
                  onClick={() => handleRemoveFilter(item, "positioningAircraftReg")}
                />
              </div>
            );
          })}
        {props.isOperator !== "All" &&
          props.isOperator.map((item, index) => {
            return (
              <div className={styles.filterItem} key={index}>
                {item}{" "}
                <img
                  src={DeleteFilter}
                  alt=""
                  className={styles.deleteFilter}
                  onClick={() => handleRemoveFilter(item, "positioningOperator")}
                />
              </div>
            );
          })}
        {displayDateRange("positioningDateRange")}
      </React.Fragment>
    );
  };

  const acmsFilter = () => {
    return (
      <React.Fragment>
        {props.isSearchInput.length > 0 &&
          props.isSearchInput.map((item, index) => {
            return (
              <div className={styles.filterItem} key={index}>
                {item}{" "}
                <img
                  src={DeleteFilter}
                  alt=""
                  className={styles.deleteFilter}
                  onClick={() => handleRemoveFilter(item, "searchInput")}
                />
              </div>
            );
          })}
        {props.isACReg !== "All" &&
          props.isACReg.map((item, index) => {
            return (
              <div className={styles.filterItem} key={index}>
                {item}{" "}
                <img
                  src={DeleteFilter}
                  alt=""
                  className={styles.deleteFilter}
                  onClick={() => handleRemoveFilter(item, "acmsAircraftReg")}
                />
              </div>
            );
          })}
        {props.isOperator !== "All" &&
          props.isOperator.map((item, index) => {
            return (
              <div className={styles.filterItem} key={index}>
                {item}{" "}
                <img
                  src={DeleteFilter}
                  alt=""
                  className={styles.deleteFilter}
                  onClick={() => handleRemoveFilter(item, "acmsOperator")}
                />
              </div>
            );
          })}
        {props.isFaultPriority !== "All" &&
          props.isFaultPriority.map((item, index) => {
            return (
              <div className={styles.filterItem} key={index}>
                {item}{" "}
                <img
                  src={DeleteFilter}
                  alt=""
                  className={styles.deleteFilter}
                  onClick={() => handleRemoveFilter(item, "acmsFaultPriority")}
                />
              </div>
            );
          })}
        {displayDateRange("acmsDateRange")}
      </React.Fragment>
    );
  };

  const acarsFilter = () => {
    return (
      <React.Fragment>
        {props.isSearchInput.length > 0 &&
          props.isSearchInput.map((item, index) => {
            return (
              <div className={styles.filterItem} key={index}>
                {item}{" "}
                <img
                  src={DeleteFilter}
                  alt=""
                  className={styles.deleteFilter}
                  onClick={() => handleRemoveFilter(item, "searchInput")}
                />
              </div>
            );
          })}
        {props.isFaultPriority.length > 0 &&
          props.isFaultPriority.map((item, index) => {
            return (
              <div className={styles.filterItem} key={index}>
                {item === "Null" || item === "Missing" ? "Undefined" : item}{" "}
                <img
                  src={DeleteFilter}
                  alt=""
                  className={styles.deleteFilter}
                  onClick={() => handleRemoveFilter(item, "acarsFaultPriority")}
                />
              </div>
            );
          })}
        {props.isRepetitive.length > 0 &&
          props.isRepetitive.map((item, index) => {
            return (
              <div className={styles.filterItem} key={index}>
                {item}{" "}
                <img
                  src={DeleteFilter}
                  alt=""
                  className={styles.deleteFilter}
                  onClick={() => handleRemoveFilter(item, "acarsRepetitive")}
                />
              </div>
            );
          })}
      </React.Fragment>
    );
  };

  const displayDateRange = (dateTabFrom) => {
    if (isNaN(Date.parse(props.startDate)) === false && isNaN(Date.parse(props.endDate)) === false) {
      return (
        <div className={styles.filterItem}>
          {/* {formatDate(ConvertLocaleUTCDatetime(props.startDate), "date")} */}
          {format(props.startDate, dateFnsFormats.DATE_TIME_2)}
          {" - "}
          {/* {formatDate(ConvertLocaleUTCDatetime(props.endDate), "date")} */}
          {format(props.endDate, dateFnsFormats.DATE_TIME_2)}
          <img
            src={DeleteFilter}
            alt=""
            className={styles.deleteFilter}
            onClick={() => handleRemoveFilter(props.startDate, dateTabFrom)}
          />
        </div>
      );
    } else {
      return <></>;
    }
  };

  return (
    <React.Fragment>
      <Grid container columns={12} sx={{ marginLeft: "16px", marginTop: "24px" }}>
        <Grid
          item
          xs={3}
          sm={3}
          md={2}
          lg={1.5}
          xl={1}
          sx={{ fontFamily: "Gotham-Book", fontSize: "14px", paddingBottom: "12px", marginTop: "7px" }}
          className={styles.activeFilters}
        >
          Active Filter:
        </Grid>
        <Grid item xs={7} sm={7} md={9} lg={9.5} xl={10} sx={{ fontFamily: "Gotham-Book", fontSize: "12px" }}>
          <>{filterLayout()}</>
        </Grid>
        <Grid
          item
          xs={2}
          sm={2}
          md={1}
          lg={1}
          xl={1}
          sx={{
            fontFamily: "Gotham-Medium",
            fontSize: "14px",
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: "16px",
            paddingTop: "6px",
          }}
        >
          <span style={{ cursor: "pointer" }} onClick={() => handleClearAllFilter()}>
            Clear All
          </span>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ borderTop: "1px solid #D1D1D1", paddingTop: "16px", fontSize: "12px", color: "#747474" }}
          className={styles.activeFilters}
        >
          *Data displayed based on active filter
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ActiveFilters;
