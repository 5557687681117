import { createSlice } from "@reduxjs/toolkit";
import { makeAPIRequest } from "common/helpers";
import { showNotification } from "common/helpers";
// import { isCSV } from "common/helpers/checkFileType";
// import { fileUpload } from "common/library/storage";
import _ from "lodash";

const membersInitialState = {
  member: {}, // each member
  loading: true,
  tab: 0,
  modifiedMembersList: [],
};

const members = createSlice({
  name: "member",
  initialState: membersInitialState,
  reducers: {
    setLoading(state, { payload }) {
      state.loading = payload;
    },
    setMemberById(state, { payload }) {
      state.member = payload;
      state.loading = false;
    },
    setTab(state, { payload }) {
      state.tab = payload;
    },
    setMembersList(state, { payload }) {
      state.modifiedMembersList = payload;
    },
  },
});

export const { setLoading, setMembersList, setMemberById, setTab } = members.actions;

export default members.reducer;

export const addMember = (memberDetails, history) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const result = await makeAPIRequest("post", "members/new", { ...memberDetails });
    dispatch(setLoading(false));

    if (!result.error) {
      const details = await makeAPIRequest("get", `members/getByEmail/${memberDetails.email}`);
      dispatch(setList({ ...memberDetails, userId: details[0].userId }, "add"));
      dispatch(setTab(1));
      history.push("/settings/account-settings/company-info");
      showNotification("success", "", ["User Successfully Added!"]);
    }
  } catch (error) {
    showNotification("danger", "Adding Member Error", [error]);
    dispatch(setLoading(false));
  }
};

export const getMemberInfo = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const info = await makeAPIRequest("get", `members/${id}`);
    dispatch(setMemberById(info));
    dispatch(setLoading(false));

    return info;
  } catch (error) {
    showNotification("danger", "Get Member Info Error", [error]);
  }
};

export const updateMemberInfo = (details, history) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const result = await makeAPIRequest("put", `members/update/${details.userId}`, { ...details });
    dispatch(setLoading(false));
    if (!result.error) {
      dispatch(getMemberInfo(details.userId));
      dispatch(setTab(1));
      history.push("/settings/account-settings/company-info");
      showNotification("success", "", ["User Successfully Updated!"]);
      dispatch(setList(details, "update"));
    }
  } catch (error) {
    showNotification("danger", "Updating Member Info Error", [error]);
    dispatch(setLoading(false));
  }
};

// export const uploadImage = (data, companyId) => async () => {
//   try {
//     if (!isCSV(data.name)) {
//       showNotification("warning", "File Type", ["Only File Type .csv can be uploaded."]);
//       return;
//     }
//     await fileUpload(data, companyId, "user-management");
//   } catch (error) {
//     console.error("error ", error);
//     showNotification("danger", "File upload fail", [error]);
//   }
// };

export const setList = (modifiedMember, action) => async (dispatch, getState) => {
  try {
    const { member } = getState();
    let memberExists = false;
    let modifiedMembersListClone = _.cloneDeep(member.modifiedMembersList);

    if (!_.isEmpty(modifiedMembersListClone) || !_.isEmpty(modifiedMembersListClone[0])) {
      for (let i = 0; i < modifiedMembersListClone.length; i++) {
        if (modifiedMember.userId === modifiedMembersListClone[i].userId) {
          modifiedMembersListClone[i] = modifiedMember;
          memberExists = true;
        }
      }
      if (action === "add" || !memberExists) {
        modifiedMembersListClone.unshift(modifiedMember);
      }
    } else {
      modifiedMembersListClone = [modifiedMember];
    }

    return dispatch(setMembersList(modifiedMembersListClone));
  } catch (error) {
    console.log("Error | setCompanyStatus: ", error);
  }
};
